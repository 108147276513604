import Domain from 'lib/Config'
import axios from 'axios'
import * as Actions from './index'

export const GET_STORE_PRODUCTS = '[ADMIN] GET_STORE_PRODUCTS'
export const GET_STORE_PRODUCT = '[ADMIN] GET_STORE_PRODUCT'
export const GET_STORE_PRODUCT_ASSIGNMENTS =
  '[ADMIN] GET_STORE_PRODUCT_ASSIGNMENTS'
export const GET_STORE_CATEGORIES = '[ADMIN] GET_STORE_CATEGORIES'
export const STORE_ERROR = '[ADMIN] STORE_ERROR'
export const STORE_LOADING = '[ADMIN] STORE_LOADING'
export const STORE_STATE_LOADING = '[ADMIN] STORE_STATE_LOADING'
export const STORE_PRODUCT_POSTED = '[ADMIN] STORE_PRODUCT_POSTED'
export const STORE_CATEGORY_POSTED = '[ADMIN] STORE_CATEGORY_POSTED'
// export const DASHBOARD_LOAD =
//   "[ADMIN] DASHBOARD_QUESTION_STATE_LOADING";

export function getStoreProducts (data, vendor_id) {
  let page = 0
  if (data.page) {
    page = data.page - 1
  }
  console.log('Calling Get Store Products @@')
  const request = axios.get(`${Domain}/api/vendor/products`, {
    params: {
      ...data,
      page,
      vendor_id,
      limit: 10
    }
  })
  return dispatch =>
    request
      .then(response => {
        console.log(
          'Get Store Products Response ------',
          response.data.products
        )
        return dispatch({
          type: GET_STORE_PRODUCTS,
          payload: {
            ...response.data.products
          }
        })
      })
      .catch(error => {
        console.log(error.response)
        return dispatch({
          type: STORE_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get products'
        })
      })
}
export function getStoreProduct (product_id, vendor_id) {
  console.log('Calling Get Store Product @@')
  const request = axios.get(`${Domain}/api/vendor/product/${product_id}`)
  return dispatch =>
    request
      .then(response => {
        console.log('Get Store Product Response ------', response.data.product)
        return dispatch({
          type: GET_STORE_PRODUCT,
          payload: {
            ...response.data.product
          }
        })
      })
      .catch(error => {
        console.log(error.response)
        return dispatch({
          type: STORE_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get product'
        })
      })
}
export function getStoreProductAssignments (data, product_id) {
  let page = 0
  if (data.page) {
    page = data.page - 1
  }
  console.log(
    'offset: page == 0 ? 0 : 10 * page, --->',
    page == 0 ? 0 : 10 * page
  )
  console.log('Calling getStoreProductAssignments  @@')
  const request = axios.get(`${Domain}/api/vendor/assignedProjects`, {
    params: {
      ...data,
      page,
      product_id,
      limit: 10
    }
  })
  return dispatch =>
    request
      .then(response => {
        console.log('Get Store Products Response ------', response.data)
        return dispatch({
          type: GET_STORE_PRODUCT_ASSIGNMENTS,
          payload: {
            ...response.data.projects
          }
        })
      })
      .catch(error => {
        console.log(error.response)
        return dispatch({
          type: STORE_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get products'
        })
      })
}
export function unAssignStoreProductProject (id, product_id) {
  const request = axios.delete(`${Domain}/api/vendor/unAssignProject/${id}`)
  return dispatch =>
    request
      .then(response => {
        dispatch(
          Actions.apiResponseNotification({
            msgType: 'success',
            translate: true,
            msg: 'project_unAssigned_successfully'
          })
        )
        dispatch(getStoreProductAssignments({}, product_id))
      })
      .catch(error => {
        console.log(error.response)
        dispatch(setStoreLoading(false))
        let errMsg =
          error.response && error.response.data && error.response.data.msg
        dispatch(
          Actions.apiResponseNotification({
            msgType: 'error',
            translate: errMsg ? false : true,
            msg: errMsg ? errMsg : 'unable_to_unAssign_project'
          })
        )
      })
}

export function uploadStoreProductCsv (data, vendor_id, toggle) {
  console.log('Upload Store data received ---->', data)
  const request = axios.post(`${Domain}/api/vendor/productsByCsv`, data)
  return dispatch =>
    request
      .then(response => {
        console.log('Add store product csv --->', response)
        dispatch(
          Actions.apiResponseNotification({
            msgType: 'success',
            translate: true,
            msg: 'product_csv_added_successfully'
          })
        )
        // Closing csv modal
        if (toggle) {
          toggle()
        }
        dispatch(setStoreStateLoading(false))
        dispatch(setStoreLoading(true))
        dispatch(getStoreProducts({}, vendor_id))
      })
      .catch(error => {
        console.log('Product Add Error  --->', error)
        console.log(error.response)
        dispatch(setStoreStateLoading(false))
        let errMsg =
          error.response && error.response.data && error.response.data.msg
        dispatch(
          Actions.apiResponseNotification({
            msgType: 'error',
            translate: errMsg ? false : true,
            msg: errMsg ? errMsg : 'unable_to_add_csv'
          })
        )
      })
}
export function addStoreProduct (data) {
  console.log('Add Store data received ---->', data)
  const request = axios.post(`${Domain}/api/vendor/product`, data)
  return dispatch =>
    request
      .then(response => {
        console.log('Add store product  called --->', response)
        dispatch(
          Actions.apiResponseNotification({
            msgType: 'success',
            translate: true,
            msg: 'product_added_successfully'
          })
        )
        dispatch(setStoreStateLoading(false))
        dispatch(setStoreLoading(true))
        dispatch(getStoreProducts({}, data.vendor_id))
        dispatch({
          type: STORE_PRODUCT_POSTED,
          payload: true
        })
      })
      .catch(error => {
        console.log('Product Add Error  --->', error)
        console.log(error.response)
        dispatch(setStoreStateLoading(false))
        let errMsg =
          error.response && error.response.data && error.response.data.msg
        dispatch(
          Actions.apiResponseNotification({
            msgType: 'error',
            translate: errMsg ? false : true,
            msg: errMsg ? errMsg : 'unable_to_add_product'
          })
        )
      })
}
export function updateStoreProduct (data, product_id, vendor_id) {
  console.log('Update Store data received ---->', data)
  const request = axios.put(`${Domain}/api/vendor/product/${product_id}`, data)
  return dispatch =>
    request
      .then(response => {
        console.log('Update store product  called --->', response)
        dispatch(
          Actions.apiResponseNotification({
            msgType: 'success',
            translate: true,
            msg: 'product_updated_successfully'
          })
        )
        dispatch(setStoreStateLoading(false))
        dispatch(setStoreLoading(true))
        dispatch(getStoreProducts({}, vendor_id))
        dispatch({
          type: STORE_PRODUCT_POSTED,
          payload: true
        })
      })
      .catch(error => {
        console.log('Product Add Error  --->', error)
        console.log(error.response)
        dispatch(setStoreStateLoading(false))
        let errMsg =
          error.response && error.response.data && error.response.data.msg
        dispatch(
          Actions.apiResponseNotification({
            msgType: 'error',
            translate: errMsg ? false : true,
            msg: errMsg ? errMsg : 'unable_to_update_product'
          })
        )
      })
}
export function deleteStoreProduct (product_id, vendor_id) {
  const request = axios.delete(`${Domain}/api/vendor/product/${product_id}`)
  return dispatch =>
    request
      .then(response => {
        dispatch(
          Actions.apiResponseNotification({
            msgType: 'success',
            translate: true,
            msg: 'product_deleted_successfully'
          })
        )
        dispatch(getStoreProducts({}, vendor_id))
      })
      .catch(error => {
        console.log(error.response)
        dispatch(setStoreLoading(false))
        let errMsg =
          error.response && error.response.data && error.response.data.msg
        dispatch(
          Actions.apiResponseNotification({
            msgType: 'error',
            translate: errMsg ? false : true,
            msg: errMsg ? errMsg : 'unable_to_delete_product'
          })
        )
      })
}
export function assignStoreProductToProject (
  projects,
  product_id,
  vendor_id,
  toggle
) {
  const request = axios.post(`${Domain}/api/vendor/assignProduct`, {
    projects,
    product_id
  })
  return dispatch =>
    request
      .then(response => {
        dispatch(
          Actions.apiResponseNotification({
            msgType: 'success',
            translate: true,
            msg: 'project_assigned_successfully'
          })
        )
        if (toggle) {
          toggle()
        }
        dispatch(setStoreStateLoading(false))
        dispatch(setStoreLoading(true))
        dispatch(getStoreProducts({}, vendor_id))
        dispatch({
          type: STORE_PRODUCT_POSTED,
          payload: true
        })
      })
      .catch(error => {
        console.log('Product Add Error  --->', error)
        console.log(error.response)
        dispatch(setStoreStateLoading(false))
        let errMsg =
          error.response && error.response.data && error.response.data.msg
        dispatch(
          Actions.apiResponseNotification({
            msgType: 'error',
            translate: errMsg ? false : true,
            msg: errMsg ? errMsg : 'unable_to_assign_project'
          })
        )
      })
}

export function getStoreCategories (data, vendor_id) {
  let params = {}
  if (data) {
    params = { ...data }
    if (data.page) {
      params.page = params.page - 1
    }
  }

  console.log('Calling Get Store Categories @@')
  const request = axios.get(`${Domain}/api/vendor/categories`, {
    params: {
      vendor_id,
      ...params
    }
  })
  return dispatch =>
    request
      .then(response => {
        console.log(
          'Get Store categories Response ------',
          response.data.categories
        )
        return dispatch({
          type: GET_STORE_CATEGORIES,
          payload: {
            ...response.data.categories
          }
        })
      })
      .catch(error => {
        console.log(error.response)
        return dispatch({
          type: STORE_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get categories'
        })
      })
}
export function addStoreCategory (data) {
  console.log('Add Store data received ---->', data)
  const request = axios.post(`${Domain}/api/vendor/category`, data)
  return dispatch =>
    request
      .then(response => {
        console.log('Add store category  called --->', response)
        dispatch(
          Actions.apiResponseNotification({
            msgType: 'success',
            translate: true,
            msg: 'category_added_successfully'
          })
        )
        dispatch(setStoreStateLoading(false))
        dispatch(setStoreLoading(true))
        dispatch(getStoreCategories({}, data.vendor_id))
        dispatch({
          type: STORE_CATEGORY_POSTED,
          payload: true
        })
      })
      .catch(error => {
        console.log('Product Add Error  --->', error)
        console.log(error.response)
        dispatch(setStoreStateLoading(false))
        let errMsg =
          error.response && error.response.data && error.response.data.msg
        dispatch(
          Actions.apiResponseNotification({
            msgType: 'error',
            translate: errMsg ? false : true,
            msg: errMsg ? errMsg : 'unable_to_add_category'
          })
        )
      })
}
export function updateStoreCategory (data, category_id, vendor_id) {
  console.log('Update Store data received ---->', data)
  const request = axios.put(
    `${Domain}/api/vendor/category/${category_id}`,
    data
  )
  return dispatch =>
    request
      .then(response => {
        console.log('Update store category  called --->', response)
        dispatch(
          Actions.apiResponseNotification({
            msgType: 'success',
            translate: true,
            msg: 'category_updated_successfully'
          })
        )
        dispatch(setStoreStateLoading(false))
        dispatch(setStoreLoading(true))
        dispatch(getStoreCategories({}, vendor_id))
        dispatch({
          type: STORE_CATEGORY_POSTED,
          payload: true
        })
      })
      .catch(error => {
        console.log('Category Add Error  --->', error)
        console.log(error.response)
        dispatch(setStoreStateLoading(false))
        let errMsg =
          error.response && error.response.data && error.response.data.msg
        dispatch(
          Actions.apiResponseNotification({
            msgType: 'error',
            translate: errMsg ? false : true,
            msg: errMsg ? errMsg : 'unable_to_update_category'
          })
        )
      })
}
export function deleteStoreCategory (category_id, vendor_id) {
  const request = axios.delete(`${Domain}/api/vendor/category/${category_id}`)
  return dispatch =>
    request
      .then(response => {
        dispatch(
          Actions.apiResponseNotification({
            msgType: 'success',
            translate: true,
            msg: 'category_deleted_successfully'
          })
        )
        dispatch(getStoreCategories({}, vendor_id))
      })
      .catch(error => {
        console.log(error.response)
        dispatch(setStoreLoading(false))
        let errMsg =
          error.response && error.response.data && error.response.data.msg
        dispatch(
          Actions.apiResponseNotification({
            msgType: 'error',
            translate: errMsg ? false : true,
            msg: errMsg ? errMsg : 'unable_to_delete_category'
          })
        )
      })
}

export function setStoreStateLoading (val) {
  console.log('Store State Loading Called --->', val)
  return dispatch => {
    return dispatch({
      type: STORE_STATE_LOADING,
      payload: val
    })
  }
}

export function setStoreLoading (val) {
  return dispatch => {
    return dispatch({
      type: STORE_LOADING,
      payload: val
    })
  }
}
