import * as Actions from "../actions";
import moment from "moment";

const initialState = {
  loading: false,
  history: null,
  count: null,
  error: null,
  latestMsgDate: null,
  lastAvatarPosition: null,
};

const chatReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.CHAT_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case Actions.CHAT_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }
    case Actions.CHAT_HISTORY: {
      let finalChatArr = [];

      let currentDate = state.latestMsgDate;
      let lastAvatarPosition = state.lastAvatarPosition;

      if (action.payload && action.payload.length > 0) {
        let queryToInsert = [];
        let datesToInsert = [];
        let tempArrNew = [];

        let chatArray = [...action.payload];
        console.log("Action.payload ---->", action.payload);
        console.log("Chat Array  Initial--->", chatArray);
        //  Extracting MCQ answer from MCQ object
        for (let i = 0; i < chatArray.length; i++) {
          if (chatArray[i].type == "MCQ") {
            if (typeof chatArray[i].message === "string") {
              chatArray[i].message = JSON.parse(chatArray[i].message);
            }
            chatArray[i].message.TQOptions.map((item) => {
              if (item.value == true) {
                let addIndex = i - 1;
                queryToInsert[addIndex] = {
                  index: addIndex,
                  obj: {
                    createdAt: chatArray[i].date,
                    date: chatArray[i].date,
                    type: "QUERY",
                    position: "RIGHT",
                    message: item.option,
                    showUserAvatar: true,
                  },
                };
              }
            });
          }
          // Getting Date Object
          // If element date is before current date a new date object is to be inserted as date is changed.
          if (chatArray[i].date) {
            if (currentDate) {
              if (moment(chatArray[i].date).isBefore(currentDate, "day")) {
                datesToInsert[i] = {
                  index: i,
                  obj: {
                    position: "CENTER",
                    type: "DATE",
                    date: currentDate,
                  },
                };
              }
            }
            currentDate = chatArray[i].date;
          }
        }

        // Inserting the date object into final chat array.
        let keys = Object.keys(queryToInsert);
        let dateKeys = Object.keys(datesToInsert);
        for (let j = 0; j < chatArray.length; j++) {
          if (dateKeys.includes(j.toString())) {
            console.log(" j ---->", j);
            console.log("Inserting Date  ", datesToInsert[j].obj);
            tempArrNew.push(datesToInsert[j].obj);
          }
          if (!chatArray[j].type.includes("CurrentActivity")) {
            tempArrNew.push(chatArray[j]);
          }

          if (keys.includes(j.toString())) {
            tempArrNew.push(queryToInsert[j].obj);
          }
        }
        // Setting Position of Avatar Icon
        for (let j = 0; j < tempArrNew.length; j++) {
          if (tempArrNew[j].position != "CENTER") {
            if (
              j == 0 &&
              lastAvatarPosition &&
              tempArrNew[j].position != lastAvatarPosition
            ) {
              tempArrNew[j].showUserAvatar = true;
              tempArrNew[j].user = "User";
            } else if (tempArrNew[j].position != tempArrNew[j + 1]?.position) {
              tempArrNew[j].showUserAvatar = true;
              tempArrNew[j].user =
                tempArrNew[j].position == "RIGHT" ? "User" : "Victor";
            }
            lastAvatarPosition = tempArrNew[j].position;
          }

          finalChatArr.push(tempArrNew[j]);
        }
      } else if (state.latestMsgDate) {
        //   Inserting the oldest date on top of chat at the end when there are no further messages.
        finalChatArr.push({
          position: "CENTER",
          type: "DATE",
          date: state.latestMsgDate,
        });
        currentDate = null;
      }

      return {
        ...state,
        loading: false,
        error: null,
        history: finalChatArr,
        count: action.count,
        latestMsgDate: currentDate,
        lastAvatarPosition: lastAvatarPosition,
      };
    }
    case Actions.CHAT_NULL: {
      return {
        ...state,
        history: null,
        count: null,
        latestMsgDate: null,
        lastAvatarPosition: null,
      };
    }

    default: {
      return state;
    }
  }
};
export default chatReducer;
