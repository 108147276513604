import React, { useState, useEffect } from 'react'
import * as Actions from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import ErrorMessageComponents from 'components/ErrorHandlers/ErrorMessageComponents'
import CircularProgressComponent from 'components/ErrorHandlers/CircularProgressComponent'
import ProductDetailCard from './productCard'
import ArticleDetailCard from './articleCard'

const ProductDetailModal = props => {
  const { t } = useTranslation()
  const {
    toggle,
    id,
    modal,
    type,
    selectedQuesOptIndexes,
    questions,
    setQuestions
  } = props
  const [images, setImages] = useState(null)
  // console.log('recommendDetailModal --->', recommendDetailModal)
  console.log('type --->', type)
  console.log('id --->', id)
  console.log('modal --->', modal)

  const dispatch = useDispatch()

  const storeReducer = useSelector(({ store }) => store)
  const articleReducer = useSelector(({ articles }) => articles)
  console.log('articleReducer --->', articleReducer)
  useEffect(() => {
    if (id && modal) {
      console.log('Calling Get Store Product useEffect')
      if (type === 'product') {
        dispatch(Actions.setStoreLoading(true))
        dispatch(Actions.getStoreProduct(id))
      } else {
        console.log('Calling Article Detail id---->', id)
        dispatch(Actions.setArticleLoading(true))
        dispatch(Actions.getArticleDetail(id))
      }
    }
  }, [id, modal])

  useEffect(() => {
    if (storeReducer) {
      if (storeReducer && storeReducer.productDetail) {
        if (storeReducer.productDetail.productImages) {
          let tempArray = []
          storeReducer.productDetail.productImages.map(img => {
            tempArray.push({
              original: img.image,
              thumbnail: img.image
            })
          })
          setImages(tempArray)
        }
      }
    }
  }, [storeReducer])

  console.log('storeReducer --->', storeReducer)
  const getProductDetail = () => {
    if (storeReducer === null || storeReducer.loading) {
      return <CircularProgressComponent />
    } else if (storeReducer && storeReducer.error) {
      return <ErrorMessageComponents message={t('cannot_fetch_detail')} />
    } else {
      return (
        <ProductDetailCard
          productDetail={storeReducer.productDetail}
          images={images}
        />
      )
    }
  }
  const getArticle = () => {
    console.log('Get Article Called ')
    if (articleReducer === null || articleReducer.loading) {
      return <CircularProgressComponent />
    } else if (articleReducer && articleReducer.error) {
      return <ErrorMessageComponents message={t('cannot_fetch_detail')} />
    } else {
      return <ArticleDetailCard articleDetail={articleReducer.articleDetail} />
    }
  }

  // if (storeReducer && storeReducer.error) {
  //   return (
  //     <Modal isOpen={props.modal} toggle={props.toggle} size='lg'>
  //       <ModalHeader toggle={props.toggle}>{t('product_detail')}</ModalHeader>
  //       <ModalBody>
  //         <ErrorMessageComponents message={t('cannot_fetch_detail')} />
  //       </ModalBody>
  //     </Modal>
  //   )
  // }

  // if (storeReducer === null || storeReducer.loading) {
  //   return (
  //     <Modal isOpen={props.modal} toggle={props.toggle} size='lg'>
  //       <ModalHeader toggle={props.toggle}>{t('product_detail')}</ModalHeader>
  //       <ModalBody>
  //         <CircularProgressComponent />
  //       </ModalBody>
  //     </Modal>
  //   )
  // }

  let handleRemoveItem = type => {
    let qIndex = selectedQuesOptIndexes.qIndex
    let optIndex = selectedQuesOptIndexes.optIndex
    if (type === 'product') {
      delete questions[qIndex].options[optIndex].product
      // questions[qIndex].options[optIndex].product === null
    } else if (type === 'article') {
      delete questions[qIndex].options[optIndex].article
      delete questions[qIndex].options[optIndex].article_id
    }
    // questions[qIndex].options[optIndex] = {
    //   ...props.questions[qIndex].options[optIndex],
    //   product: null
    // }
    setQuestions([...questions])
    toggle()
  }

  return (
    <div>
      <Modal isOpen={props.modal} toggle={props.toggle} size='lg'>
        <ModalHeader toggle={props.toggle}>
          {type === 'product' ? t('product_detail') : t('article_detail')}
        </ModalHeader>
        <ModalBody>
          {type === 'product' ? getProductDetail() : getArticle()}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              handleRemoveItem(type)
            }}
            color='none'
            className='primary-background-button-table font-weight-bold mb-5 mr-4 p-2 px-4'
          >
            {t('remove')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default ProductDetailModal
