import * as Actions from "../actions";

const initialState = {
  loading: false,
  error: null,
  inventoryList: null,
  inventoryCount: null,
};

const inventoryReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.INVENTORY_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case Actions.INVENTORY_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.GET_PATIENT_INVENTORY: {
      return {
        ...state,
        loading: false,
        error: null,
        inventoryList: action.payload.rows,
        inventoryCount: action.payload.count,
      };
    }
    default: {
      return state;
    }
  }
};
export default inventoryReducer;
