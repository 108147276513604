import { CircularProgress, IconButton } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody, Label, Input } from "reactstrap";
import FileDrop from "react-dropzone";
import AddIcon from "@material-ui/icons/Add";
import PublishIcon from "@material-ui/icons/Publish";
import { v4 as uuidv4 } from "uuid";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import momentt from "moment-timezone";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import * as Actions from "store/actions";
import useRecorder from "lib/useRecorder";
import StopIcon from "@material-ui/icons/Stop";
import DeleteIcon from "@material-ui/icons/Delete";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import axios from "axios";
import Domain from "lib/Config";
import DatePicker from "components/DatePicker/DatePicker";
import { timeZoneList as timeZone } from "lib/timeZone";
import SelectProject from "./selectProjectModal";

const s3Cdn = axios.create({});
const weekDays = [
  { title: "Monday", value: 1 },
  { title: "Tuesday", value: 2 },
  { title: "Wednesday", value: 3 },
  { title: "Thursday", value: 4 },
  { title: "Friday", value: 5 },
  { title: "Saturday", value: 6 },
  { title: "Sunday", value: 0 },
];

const AddActivity = (props) => {
  console.log(props.match.params.status);
  const [images, setImages] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const [recordLoading, setRecordLoading] = useState(false);
  const [recordingFile, setRecordingFile] = useState(null);
  const [recordingUrl, setRecordingUrl] = useState(null);
  const [dateRange, setDateRange] = useState("");
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [actualDateRange, setActualDateRange] = useState(null);
  const [days, setDays] = useState("everyday");
  const [timeZoneStat, setTimeZoneStat] = useState("+10:00");
  const [when, setWhen] = useState([
    {
      id: 0,
      time: selectedDate,
      zoneTime: moment(selectedDate).utc().format("HH:mm"),
    },
  ]);
  const [daysArr, setDaysArr] = useState([]);

  let [audioURL, isRecording, startRecording, stopRecording, deleteRecording] =
    useRecorder();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [type, setType] = React.useState("medicine");

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  const activityReducer = useSelector(({ activity }) => activity);
  const medicineReducer = useSelector(({ medicine }) => medicine);
  const [submissionData, setSubmissionData] = useState(null);
  const [selectOpen, setSelectOpen] = useState(false);

  useEffect(() => {
    dispatch(Actions.setMedicineLoading(true));
    dispatch(Actions.getAllMedicines({}, Auth.vendor_id));
  }, []);

  useEffect(() => {
    if (audioURL !== "") {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", audioURL, true);
      xhr.responseType = "blob";
      xhr.onload = function (e) {
        if (this.status == 200) {
          var myBlob = this.response;
          console.log("myBlob --->", myBlob);
          var file = new File(
            [myBlob],
            `${Date.now()}-audio-file-activity.mp3`,
            {
              lastModified: Date.now(),
              type: "audio/mpeg",
            }
          );
          console.log("File --->", file);
          setRecordingFile(file);
          // myBlob is now the blob that the object URL pointed to.
        }
      };
      xhr.send();
    }
  }, [audioURL]);

  const handleWhenChange = (value) => {
    let num = value;
    let _num = 24 / num;
    let arr = [];
    console.log("selectedDate", selectedDate);

    var zone_name = momentt.tz.guess();
    // var timezone = momentt.tz(zone_name).zoneAbbr();
    var timezone = momentt().tz(momentt.tz.guess()).format("z");
    console.log(timezone);

    var clientTimezoneOffset = new Date().getTimezoneOffset() / 60;
    console.log(clientTimezoneOffset);
    // var timeDifference = moment().utcOffset();
    // console.log("diffff...........", moment().utcOffset());
    for (let i = 0; i < num; i++) {
      const time = moment(selectedDate)
        .add(_num * i, "h")
        .format("YYYY-MM-DD HH:mm");
      console.log("time.....................", time);

      var d = new Date(time);
      d.setHours(d.getHours() + clientTimezoneOffset);
      const zoneTime = moment(d).format("YYYY-MM-DD HH:mm");
      console.log("zoneTime.....................", zoneTime);
      arr.push({
        id: i,
        time,
        zoneTime,
      });
    }
    setWhen(arr);

    // let num = value;
    // let _num = 24 / num;
    // let arr = [];
    // console.log("selectedDate", selectedDate);

    // var zone_name = momentt.tz.guess();
    // // var timezone = momentt.tz(zone_name).zoneAbbr();
    // var timezone = momentt().tz(momentt.tz.guess()).format("z");
    // console.log(timezone);

    // var clientTimezoneOffset = new Date().getTimezoneOffset() / 60;
    // console.log(clientTimezoneOffset);
    // // var timeDifference = moment().utcOffset();
    // // console.log("diffff...........", moment().utcOffset());
    // for (let i = 0; i < num; i++) {
    //   const time = moment(selectedDate)
    //     .add(_num * i, "h")
    //     .format("YYYY-MM-DD HH:mm");
    //   console.log("time.....................", time);
    //   // var timestamp = new Date(time);
    //   // var inverseOffset = moment(timestamp).utcOffset() * -1;
    //   // timestamp = moment().utcOffset(inverseOffset);

    //   // console.log(moment(timestamp.toISOString()).format("hh:mm A"));
    //   // const momentDateTzUTC = momentt.tz(time, 'YYYY-MM-DD HH:mm', timezone).utc().format("HH:mm") // 2018-01-27T15:30:00Z
    //   // console.log(momentDateTzUTC);
    //   var d = new Date(time);
    //   d.setHours(d.getHours() + clientTimezoneOffset);
    //   const zoneTime = moment(d).format("YYYY-MM-DD HH:mm");
    //   console.log("zoneTime.....................", zoneTime);
    //   arr.push({
    //     id: i,
    //     time,
    //     zoneTime,
    //   });
    // }
    // setWhen(arr);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let obj = {};
    const formData = new FormData(e.target);
    for (let [key, value] of formData.entries()) {
      obj[key] = value;
    }
    obj.createdBy = "employee";
    obj.patient_id = props.match.params.id;
    obj.employee_id = Auth.id;
    const clientTimezoneOffset = new Date().getTimezoneOffset() / 60;
    let d = new Date(selectedDate);
    d.setHours(d.getHours() + clientTimezoneOffset);
    obj.time = moment(d).format("HH:mm");
    // obj.time = moment(selectedDate).utc().format("HH:mm");
    obj.status = "pending";
    obj.days = daysArr.length > 0 ? daysArr : [0, 1, 2, 3, 4, 5, 6];
    obj.schedule = [];
    let timeSelected = obj.time;
    let num = 24 / when.length;
    when &&
      when.map((item, idx) => {
        let o = {
          dose: type === "meeting" || type === "other" ? 0 : obj[`day-${idx}`],
          time: moment(d)
            .add(num * idx, "h")
            // .utc()
            .format("HH:mm"),
        };
        console.log(o);
        obj.schedule.push(o);
      });
    obj.files = [];
    if (recordingUrl) {
      obj.files.push({
        file: recordingUrl,
        type: "audio",
      });
    }
    if (images.length > 0) {
      images.map((item) => {
        obj.files.push({
          file: item.File,
          type: item.type,
        });
      });
    }
    console.log(actualDateRange);
    if (actualDateRange) {
      let startTime = obj.schedule[0].time;
      let endTime = obj.schedule[obj.schedule.length - 1].time;
      let rt = moment(endTime, "HH:mm").utc(true).local().format("HH:mm");

      // let endTime = moment(obj.schedule[obj.schedule.length - 1].time, "HH:mm")
      //   .utc(true)
      //   .local()
      //   .format("HH:mm");
      // convert this to utc and include start and end time of the activity withour utc
      console.log(`selected Date>>>>>>>>>>>>>>.  ${selectedDate}`);
      let time512 = moment(selectedDate).format("HH:mm:ss");
      console.log(`time512>>>>>>>>>>>>>>.  ${time512}`);
      let frmtD212 = moment(actualDateRange[0]).format("YYYY-MM-DD");
      console.log(`frmtd212>>>>>>>>>>>>>>.  ${frmtD212}`);
      let frmtoD919 = moment(actualDateRange[1]).format("YYYY-MM-DD");
      //////////////
      /////////////////
      var frommm = moment(
        `${frmtD212} ${time512}`,
        "YYYY-MM-DD HH:mm:ss"
      ).format(`YYYY-MM-DD HH:mm:ss`);
      let dd = new Date(frommm);
      dd.setHours(dd.getHours() + clientTimezoneOffset);
      var from = moment(dd).format("YYYY-MM-DD HH:mm:ss");
      // var from = moment(`${frmtD212} ${time512}`, "YYYY-MM-DD HH:mm:ss")
      //   .utc()
      //   .format(`YYYY-MM-DD HH:mm:ss`);
      console.log(`from>>>>>>>>>>>>>>.  ${from}`);
      var tooo = moment(`${frmtoD919} 23:59`, "YYYY-MM-DD HH:mm").format(
        `YYYY-MM-DD HH:mm:ss`
      );
      let ddd = new Date(tooo);
      ddd.setHours(ddd.getHours() + clientTimezoneOffset);
      var to = moment(ddd).format(`YYYY-MM-DD HH:mm:ss`);
      // var to = moment(`${frmtoD919} 23:59`, "YYYY-MM-DD HH:mm")
      //   .utc()
      //   .format(`YYYY-MM-DD HH:mm:ss`);
      console.log(from, to);
      // m.set({ h: 11, m: 11 });
      obj.from = from;
      obj.to = to;
      //////////////////////////////////////
      //////////////////////////////
      obj.fromTime = startTime;
      obj.toTime = endTime;
    }
    if (type === "meeting" || type === "other") {
      obj.takeIt = null;
      obj.medicine_id = null;
    }
    if (!obj.places) {
      obj.places = null;
    }

    console.log(props.match.params.projectId);
    obj.tz = timeZoneStat;
    obj.victor = e?.target?.victor?.checked ? e?.target?.victor?.checked : null;
    delete obj.daterange_from;
    delete obj.daterange_to;
    delete obj.day;
    delete obj.month;
    delete obj.year;

    if (props.match.params.projectId == "null") {
      setSelectOpen(true);
      console.log("Object ---->", obj);
      setSubmissionData(obj);
    } else {
      obj.project_id = props.match.params.projectId;
      console.log("Final Obj --->", obj);
      dispatch(Actions.setActivityLoading(true));
      dispatch(Actions.addActivity(obj));
    }
  };
  console.log("timeZoneStat --->", timeZoneStat);
  console.log("when --->", when);
  let submitWithProjectId = (projectId) => {
    submissionData.project_id = projectId;
    console.log("Final Submission Data --->", submissionData);
    dispatch(Actions.setActivityLoading(true));
    dispatch(Actions.addActivity(submissionData));
  };

  const handleDateChange = (date) => {
    console.log(date);
    setSelectedDate(date);
    handleWhenChange(when.length);
  };

  useEffect(() => {
    handleWhenChange(when.length);
  }, [selectedDate]);

  const onDrop = useCallback(
    (img) => {
      setImageLoading(true);
      handleFileUpload(img[0]);
    },
    [images]
  );

  const removeImg = (id) => {
    setImages(images.filter((img) => img.id !== id));
  };

  const handleFileUpload = (data) => {
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      },
      header: {
        "Content-Type": data.type,
      },
    };
    getSignedURL(data.name, data.type).then((rsp) => {
      const request = s3Cdn
        .put(rsp.data.result.postUrl, data, config)
        .then((res) => {
          let type = data.type;
          let _type = type.split("/")[0];
          if (_type == "audio") {
            setRecordingUrl(rsp.data.result.getUrl);
            setRecordLoading(false);
          } else {
            let image = {
              id: uuidv4(),
              File: rsp.data.result.getUrl,
              type: _type,
            };
            setImages([...images, image]);
            setImageLoading(false);
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    });
  };

  const getSignedURL = (key, type) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Domain}/api/vendor/s3getsignedurl?key=${key}&type=${type}`)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          console.log(err.response);
          reject(err);
        });
    });
  };

  const uploadRecording = () => {
    setRecordLoading(true);
    handleFileUpload(recordingFile);
  };

  const handleDateRange = (e) => {
    if (!e) {
      setDateRange("");
      return;
    }
    console.log(e);
    const formatedDate = [
      moment(e[0]).format("YYYY-MM-DD"),
      moment(e[1]).format("YYYY-MM-DD"),
    ];
    console.log(e);
    setActualDateRange(e);
    setDateRange(formatedDate);
  };

  const handleCheck = (e) => {
    if (e.target.checked) {
      setDaysArr([...daysArr, parseInt(e.target.value)]);
    } else {
      setDaysArr(daysArr.filter((item) => item !== parseInt(e.target.value)));
    }
  };

  return (
    <div className="container">
      <div>
        <div className="my-2 form-styles">
          <h2 className="form-heading d-flex">{t("add_activity")}</h2>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="row">
              <div className="col-md-6 mt-4">
                <Label className="d-flex">{t("title")}</Label>
                <Input type="text" name="title" required />
              </div>
              <div className="col-md-6 mt-4">
                <Label className="d-flex">{t("type")}</Label>
                <Input
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  type="select"
                  name="type"
                  required
                >
                  <option value="medicine">Medication</option>
                  <option value="supplements">Supplements</option>
                  {props.match.params.status === "true" && (
                    <option value="meeting">Doctor Meeting</option>
                  )}
                  <option value="face-to-face">
                    Doctor Meeting(Face-To-Face)
                  </option>
                  <option value="test">Test</option>
                  <option value="other">Other</option>
                </Input>
              </div>
            </div>
            {(type == "medicine" || type == "supplements") && (
              <div className="row">
                {type === "meeting" ||
                type === "other" ||
                type === "face-to-face" ? null : (
                  <div className="col-md-6 mt-4">
                    <Label className="d-flex">{t("medicine")}</Label>
                    <Input
                      type="select"
                      name="medicine_id"
                      required
                      disabled={medicineReducer.loading}
                    >
                      {medicineReducer.medicineList &&
                        medicineReducer.medicineList.map((item) => {
                          return (
                            <option id={item.id} key={item.id} value={item.id}>
                              {`${item.name} - ${item.unit}`}
                            </option>
                          );
                        })}
                    </Input>
                  </div>
                )}
                <div className="mt-4 col-md-6">
                  <Label className="d-flex">{t("days")}</Label>
                  <Input
                    type="select"
                    name="when"
                    required
                    value={days}
                    onChange={(e) => setDays(e.target.value)}
                  >
                    <option value="everyday">Everyday</option>
                    <option value="fixed-days">Set Days</option>
                  </Input>
                </div>
                {type === "meeting" ||
                type === "other" ||
                type === "face-to-face" ? null : (
                  <div className="mt-4 col-md-6">
                    <Label className="d-flex">{t("take_it")}</Label>
                    <Input type="select" name="takeIt" required>
                      <option value="before meal">Before Meal</option>
                      <option value="after meal">After Meal</option>
                      <option value="during meal">During Meal</option>
                      <option value="not important">Not Important</option>
                    </Input>
                  </div>
                )}
              </div>
            )}
            {days == "fixed-days" && (
              <div className="row">
                <div className="col-md-12 mt-4 ">
                  <Label className="d-flex">{t("select_days")}</Label>
                  <div className="d-flex justify-content-between">
                    {weekDays.map((item) => {
                      return (
                        <div className="d-flex align-items-center ml-4">
                          <Input
                            onChange={(e) => handleCheck(e)}
                            type="checkbox"
                            value={item.value}
                          />
                          <p
                            className="mb-0 mt-1"
                            style={{ fontSize: "0.8rem" }}
                          >
                            {item.title}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-6 mt-4 d-flex flex-column">
                <Label className="d-flex">{t("select_date_range")}</Label>
                <DatePicker
                  className="w-100"
                  value={dateRange}
                  handleChange={handleDateRange}
                  pastDate={false}
                  minDate={new Date()}
                />
              </div>
              {/* {type !== 'meeting' && */}
              <div className="col-md-6 mt-4">
                <Label className="d-flex">{t("when")}</Label>
                <Input
                  onChange={(e) => handleWhenChange(e.target.value)}
                  type="select"
                  name="when"
                >
                  <option value={1}>Once a day</option>
                  <option value={2}>Twice a day</option>
                  <option value={3}>Thrice a day</option>
                  <option value={4}>4 times a day</option>
                  <option value={5}>5 times a day</option>
                  <option value={6}>6 times a day</option>
                  <option value={7}>7 times a day</option>
                  <option value={8}>8 times a day</option>
                  <option value={9}>9 times a day</option>
                  <option value={10}>10 times a day</option>
                  <option value={11}>11 times a day</option>
                  <option value={12}>12 times a day</option>
                </Input>
              </div>
              {/* } */}
            </div>
            <div className="row">
              {type !== "meeting" &&
              type !== "other" &&
              type !== "face-to-face" &&
              type !== "test" ? (
                <>
                  {when.map((x, idx) => (
                    <div className="col-md-6 mt-4">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex justify-content-start align-items-center">
                          <p className="mr-2 mb-0 font-weight-bold">
                            {idx + 1}
                          </p>
                          <Input
                            required
                            min="1"
                            placeholder="Dose"
                            className="mr-2"
                            type="number"
                            name={`day-${idx}`}
                          />
                        </div>
                        <div className="d-flex justify-content-end align-items-center">
                          <p
                            className="mb-0 font-weight-bold font-12"
                            // style={{ width: "100px" }}
                          >
                            LocalTime: {moment(x.time).format("hh:mm A")}
                          </p>
                          <p
                            className="mb-0 font-weight-bold text-muted font-12 ml-5"
                            // style={{ width: "100px" }}
                          >
                            PatientTime:{" "}
                            {moment(x.zoneTime)
                              .add(timeZoneStat)
                              .format("hh:mm A")}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}{" "}
                </>
              ) : (
                <>
                  {when.map((x, idx) => (
                    <div className="col-md-4 mt-4">
                      <div className="d-flex align-items-center">
                        <p className="mr-2 mb-0 font-weight-bold">{idx + 1}</p>
                        <p
                          className="mb-0 font-weight-bold font-12"
                          // style={{ width: "100px" }}
                        >
                          LocalTime: {moment(x.time).format("hh:mm A")}
                        </p>
                        <p
                          className="mb-0 font-weight-bold text-muted font-12 ml-5"
                          // style={{ width: "100px" }}
                        >
                          PatientTime:{" "}
                          {moment(x.zoneTime)
                            .add(timeZoneStat)
                            .format("hh:mm A")}
                        </p>
                      </div>
                    </div>
                  ))}{" "}
                </>
              )}
            </div>
            <div className="row">
              <div className="mt-4 col-md-6">
                <Label className="d-flex">{t("time")}</Label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    className="w-100"
                    margin="normal"
                    id="time-picker"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div className="mt-4 col-md-6">
                <Label className="d-flex">{t("time_zone")}</Label>
                <Input
                  type="select"
                  name="tz"
                  onChange={(e) => setTimeZoneStat(e.target.value)}
                  required
                >
                  {timeZone.map((item) => {
                    return (
                      <option value={item.value}>
                        {item.label} (GMT{item.value})
                      </option>
                    );
                  })}
                </Input>
              </div>
              {type == "test" ? (
                <div className="col-md-6 mt-4 d-flex flex-column">
                  <Label className="d-flex">{t("place")}</Label>
                  <Input name="places" required className="mt-3"></Input>
                </div>
              ) : null}
            </div>

            <div className="row">
              <div className="col-md-12 mt-4">
                <Label className="d-flex">{t("information")}</Label>
                <Input type="textarea" name="info" />
              </div>
            </div>
            <div className="mt-4">
              <audio style={{ width: "100%" }} src={audioURL} controls />
            </div>
            <div className="mt-2 d-flex justify-content-center">
              <IconButton
                disabled={isRecording}
                onClick={startRecording}
                className="mr-2"
              >
                <FiberManualRecordIcon className="text-danger" />
              </IconButton>
              <IconButton
                disabled={!isRecording}
                onClick={stopRecording}
                className="mr-2"
              >
                <StopIcon />
              </IconButton>
              <IconButton
                disabled={isRecording}
                onClick={deleteRecording}
                className="mr-2"
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                disabled={isRecording || audioURL == ""}
                onClick={uploadRecording}
                className="mr-2"
              >
                <PublishIcon />
              </IconButton>
            </div>
            <div className="text-center" style={{ height: "20px" }}>
              {isRecording ? (
                <p>{t("recording")}...</p>
              ) : recordLoading ? (
                <p>{t("uploading")}...</p>
              ) : null}
            </div>
            {type == "medicine" || type == "test" ? (
              <div className="my-4">
                <input
                  type="checkbox"
                  id="victor"
                  name="victor"
                  style={{ transform: "scale(1.2)" }}
                />
                <label
                  for="victor"
                  className="ml-2 "
                  style={{ fontSize: "1rem" }}
                >
                  {t("allow_victor_call")}
                </label>
              </div>
            ) : null}

            <Label className="d-flex">{t("upload_files")}</Label>
            <div>
              <div className="row">
                {images &&
                  images.length > 0 &&
                  images.map((img) => (
                    <div
                      className="col-md-2 mb-2 animate__animated animate__fadeIn"
                      key={img.id}
                    >
                      <div className="img-div d-flex flex-column">
                        {img.type == "image" ? (
                          <img src={img.File} width="100%" alt="qwe" />
                        ) : img.type == "video" ? (
                          <video>
                            <source src={img.File} />
                          </video>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="del-img">
                        <IconButton
                          color="inherit"
                          mini="true"
                          className="p-0"
                          onClick={(e) => removeImg(img.id)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </div>
                    </div>
                  ))}
                <div className="col-md-2">
                  <FileDrop
                    disabled={imageLoading}
                    accept="image/png, image/gif, image/jpeg, image/bmp, video/mp4, video/mpeg, video/mpe, video/mpv, video/ogg, video/m4p, video/avi, video/wmv"
                    multiple
                    onDrop={onDrop}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()} className="img-div">
                        <IconButton
                          color="inherit"
                          mini="true"
                          aria-label="Menu"
                          className="p-0"
                        >
                          {imageLoading ? (
                            <CircularProgress
                              style={{ height: "10px", width: "10px" }}
                            />
                          ) : (
                            <AddIcon fontSize="small" />
                          )}
                        </IconButton>
                        <input {...getInputProps()} />
                      </div>
                    )}
                  </FileDrop>
                </div>
              </div>
            </div>
            <div className="mt-4 d-flex justify-content-end">
              <Button
                disabled={activityReducer.loading}
                type="submit"
                className="primary-background-button-table d-flex align-items-center"
                color="none"
              >
                <span className="font-weight-bold">{t("add_activity")}</span>
                {activityReducer.loading && (
                  <CircularProgress
                    className="ml-2"
                    style={{ height: "20px", width: "20px", color: "white" }}
                  />
                )}
              </Button>
            </div>
          </form>
          <SelectProject
            open={selectOpen}
            toggle={() => setSelectOpen(!selectOpen)}
            submitWithProjectId={submitWithProjectId}
            patientId={props.match.params.id}
          />
        </div>
      </div>
    </div>
  );
};
export default AddActivity;
