import { Avatar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Input, Table } from "reactstrap";
import Pagination from "@material-ui/lab/Pagination";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import { CircularProgress } from "@material-ui/core";
import TableLoader from "components/ErrorHandlers/TableLoader";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as Actions from "store/actions";

import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBack from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function PatientFilterModal(props) {
  const dispatch = useDispatch();

  const classes = useStyles();
  const [variables, setVariables] = useState({ page: 1 });
  const [patient, setPatient] = useState(null);

  const [formData, setFormData] = useState({
    patientId: null,
    projectId: null,
  });

  const { t } = useTranslation();

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  const patientReducer = useSelector(({ patient }) => patient);
  const engagementReducer = useSelector(({ engagement }) => engagement);

  useEffect(() => {
    let vendor_id = Auth.vendor_id;
    if (formData.projectId == null) {
      dispatch(Actions.setPatientLoading(true));
      dispatch(Actions.getAllPatients(variables, vendor_id));
    }
  }, [variables]);

  useEffect(() => {
    // If we have the patientId we get the patient projects for project select view
    if (formData.patientId && formData.projectId == null) {
      dispatch(Actions.setPatientDocumentLoading(true));
      dispatch(Actions.getPatientProjects(formData.patientId, variables));
    }
    // If we have both patient and projectId we assign the engagement
    if (formData.patientId && formData.projectId) {
      props.handleAssignToPatient(formData.patientId, formData.projectId);
    }
  }, [formData, variables]);

  useEffect(() => {
    setPatient(patientReducer);
  }, [patientReducer]);

  const handlePatientSelect = (id) => {
    setVariables({ _firstName: "", page: 1 });
    setFormData({ ...formData, patientId: id });
    props.setPatientModalType("project");
    document.getElementById("patient-searchh").value = "";
  };

  const handleProjectSelect = (id) => {
    setFormData({ ...formData, projectId: id });
  };

  const goToPatientView = () => {
    setVariables({ page: 1 });
    setFormData({ ...formData, patientId: null, projectId: null });
    document.getElementById("patient-searchh").value = "";
    props.setPatientModalType("patient");
  };

  const getPatientList =
    patient && patient.patientList && patient.patientList.length > 0 ? (
      patient.patientList.map((item) => {
        return (
          <tr>
            <td>
              <div
                className="ml-2 d-flex align-items-center"
                onClick={(e) => handlePatientSelect(item.id)}
              >
                <button
                  disabled={
                    patient.loading ||
                    patient.docLoading ||
                    engagementReducer.assignLoading
                      ? true
                      : false
                  }
                  className=" d-flex align-items-center btn btn-link"
                >
                  {t("pick")}
                </button>
              </div>
            </td>

            <td>
              <div className="ml-4 d-flex align-items-center">{item.id}</div>
            </td>
            <td>
              <div className="ml-4 d-flex align-items-center">
                <Avatar className="mr-3 medicine-pic" src={item.image} />
                <p className="mb-0 mr-4">
                  {item.firstName + " " + item.lastName}
                </p>
              </div>
            </td>
            <td>
              <div className="ml-4 d-flex align-items-center">
                <p className="mb-0 mr-4">{item.email}</p>
              </div>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colspan={5} className="text-center font-weight-bold">
          {t("nothing_to_display")}
        </td>
      </tr>
    );

  const getPatientProjectList =
    patient && patient.patientProject && patient.patientProject.length > 0 ? (
      patient.patientProject.map((item) => {
        return (
          <tr>
            <td>
              <div
                className="ml-2 d-flex align-items-center"
                onClick={(e) => handleProjectSelect(item?.project_id)}
              >
                <button
                  disabled={
                    patient.loading ||
                    patient.docLoading ||
                    engagementReducer.assignLoading
                      ? true
                      : false
                  }
                  className=" d-flex align-items-center btn btn-link"
                >
                  {t("pick")}
                </button>
              </div>
            </td>
            <td>
              <div className="ml-4 d-flex align-items-center">{item?.id}</div>
            </td>
            <td>
              <div className="ml-4 d-flex align-items-center">
                <Avatar
                  className="mr-3 medicine-pic"
                  src={item.patientproject?.imageUrl}
                />
                <p className="mb-0 mr-4">{item.patientproject?.name}</p>
              </div>
            </td>
            <td>
              <div className="ml-4 d-flex align-items-center">
                <p className="mb-0 mr-4">{item.patientproject?.country}</p>
              </div>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colspan={5} className="text-center font-weight-bold">
          {t("nothing_to_display")}
        </td>
      </tr>
    );

  const handlePageChange = (e, pagee) => {
    setVariables({
      ...variables,
      page: pagee,
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();

    let search = document.getElementById("patient-searchh").value;
    setVariables({
      ...variables,
      _firstName: search,
      page: 1,
    });
  };
  const handleChipDelete = () => {
    document.getElementById("patient-searchh").value = "";
    setVariables({
      ...variables,
      _firstName: "",
      page: 1,
    });
  };

  if (patient === null) {
    return <CircularProgressComponent />;
  }
  console.log("props.patientModalType--->", props.patientModalType);
  console.log("patient --->", patient);
  return (
    <div className="">
      <div>
        {props.patientModalType == "project" ? (
          <button
            onClick={goToPatientView}
            disabled={
              patient.loading ||
              patient.docLoading ||
              engagementReducer.assignLoading
                ? true
                : false
            }
            className="btn btn-link p-2"
          >
            <ArrowBack />
          </button>
        ) : null}
      </div>
      <div className="mt-4">
        {variables._firstName && (
          <Chip
            label={variables._firstName}
            onDelete={() => handleChipDelete()}
            className={classes.chip}
          />
        )}
        <div className="d-flex justify-content-between align-items-end table-top-bar">
          <div className="d-flex align-items-center filters">
            <Form className="mt-2 mt-sm-0" onSubmit={(e) => handleSearch(e)}>
              <FormGroup className="d-inline-block gift-search-input">
                <Input
                  type="text"
                  name="value"
                  id="patient-searchh"
                  maxLength={10}
                  placeholder={t("search")}
                />
                <button
                  disabled={
                    patient.loading ||
                    patient.docLoading ||
                    engagementReducer.assignLoading
                      ? true
                      : false
                  }
                  type="submit"
                  className="gift-search-icon btn btn-link"
                >
                  <i className="zmdi zmdi-search centrify-blue-color"></i>
                </button>
              </FormGroup>
            </Form>
          </div>
        </div>
        <div className="mt-4" style={{ position: "relative" }}>
          {patient.loading ||
          patient.docLoading ||
          engagementReducer.assignLoading == true ? (
            <TableLoader />
          ) : patient && patient.error ? (
            <div className="text-center my-40">
              <h2>{t("something_went_wrong")}</h2>
            </div>
          ) : props.patientModalType == "patient" ? (
            <>
              <Table responsive className="main-table">
                <thead className="main-table-header">
                  <tr>
                    <th>
                      <span className="ml-4 mb-0 d-flex"> {t("pick")}</span>
                    </th>
                    <th>
                      <span className="ml-4 mb-0 d-flex">{t("id")}</span>
                    </th>
                    <th>
                      <span className="ml-4 mb-0 d-flex">{t("name")}</span>
                    </th>
                    <th>
                      <span className="ml-4 mb-0 d-flex">{t("email")}</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="main-table-body">{getPatientList}</tbody>
              </Table>
              {props.patientModalType == "patient" &&
              patientReducer &&
              patientReducer.patientCount > 0 ? (
                <div className="mt-4 d-flex justify-content-center">
                  <Pagination
                    count={Math.ceil(patientReducer.patientCount / 10)}
                    page={variables.page}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                  />
                </div>
              ) : null}
            </>
          ) : (
            <>
              <Table responsive className="main-table">
                <thead className="main-table-header">
                  <tr>
                    <th>
                      <span className="ml-4 mb-0 d-flex"> {t("pick")}</span>
                    </th>
                    <th>
                      <span className="ml-4 mb-0 d-flex">{t("id")}</span>
                    </th>
                    <th>
                      <span className="ml-4 mb-0 d-flex">{t("name")}</span>
                    </th>
                    <th>
                      <span className="ml-4 mb-0 d-flex">{t("country")}</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="main-table-body">
                  {getPatientProjectList}
                </tbody>
              </Table>
              {patientReducer && patientReducer.patientProjectCount > 0 ? (
                <div className="mt-4 d-flex justify-content-center">
                  <Pagination
                    count={Math.ceil(patientReducer.patientProjectCount / 10)}
                    page={variables.page}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                  />
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
