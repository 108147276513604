export default {
  dashboard: ["admin", "manager"],
  profile: ["admin", "doctor", "nurse", "analyst", "manager"],
  projects: ["admin", "doctor", "nurse", "manager"],
  employees: ["admin", "manager"],
  patients: ["admin", "doctor", "nurse", "manager"],
  reports: ["admin", "doctor", "nurse", "manager"],
  patientProfile: ["admin", "doctor", "nurse", "manager"],
  employeeProfile: ["admin", "doctor", "nurse", "manager"],
  medicines: ["admin", "doctor", "manager"],
  orders: ["admin", "doctor", "manager"],
  questionaires: ["admin", "doctor", "manager"],
  treeQuestionnaires: ["admin", "doctor", "manager", "analyst"],
  engagements: ["admin", "doctor", "manager"],
  addQuestionaires: ["admin", "doctor", "manager"],
  vChat: ["admin", "manager", "doctor"],
  stats: ["admin", "analyst", "manager"],
  manageProjects: ["admin", "doctor", "nurse", "manager"],
  patientProject: ["admin", "doctor", "nurse", "manager"],
  notifications: ["admin", "manager"],
  addActivity: ["admin", "doctor", "nurse", "manager"],
  meeting: ["admin", "doctor", "manager"],
  stores: ["admin", "manager"],
  settings: ["admin", "manager"],

  pageError: ["admin", "doctor", "nurse", "analyst", "manager"],
};
