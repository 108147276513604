import React from 'react'
import { Avatar } from '@material-ui/core'
import { Input } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { AiOutlineLink } from 'react-icons/ai'
import { v4 as uuidv4 } from 'uuid'

const McqTypeQuestion = ({
  quesIndex,
  question,
  questions,
  setQuestions,
  handleRemoveOption,
  setSelectedQuesOptIndexes,
  recommendModalToggle,
  recommentDetailModalToggle
}) => {
  const { t } = useTranslation()
  const stringTruncate = (str, length) => {
    if (str && length) {
      const dots = str.length > length ? '...' : ''
      return `${str.substring(0, length)}${dots}`
    }
  }
  const getRecommendElements = index => {
    let optionRef = question.options[index]

    let recommendationExist =
      (optionRef.product && optionRef.product.length > 0) ||
      (optionRef.article && optionRef.article.length > 0)
    if (recommendationExist === true) {
      const type = question.options[index].article_id ? 'article' : 'product'
      const recommendationRef = question.options[index].article_id
        ? question.options[index].article[0]
        : question.options[index].product[0]
      return (
        <div className='d-flex align-items-center justify-content-between'>
          <Avatar
            src={recommendationRef.image ? recommendationRef?.image[0] : null}
            style={{ height: '24px', width: '24px' }}
            className='mr-1'
          />
          <p
            className='m-auto cursor-pointer'
            onClick={() => {
              setSelectedQuesOptIndexes({
                qIndex: quesIndex,
                optIndex: index
              })
              if (type === 'article') {
                console.log('recommendationRef --->', recommendationRef)
                recommentDetailModalToggle(
                  recommendationRef.id || recommendationRef.article_id,
                  'article'
                )
              } else {
                recommentDetailModalToggle(
                  recommendationRef.product_id,
                  'product'
                )
              }
            }}
          >
            {stringTruncate(recommendationRef.name, 10)}
          </p>
        </div>
      )
    } else {
      return (
        <div className='d-flex'>
          <AiOutlineLink
            className='m-auto cursor-pointer'
            onClick={() => {
              recommendModalToggle()
              setSelectedQuesOptIndexes({
                qIndex: quesIndex,
                optIndex: index
              })
            }}
          />
          <Input
            type='select'
            style={{ width: '0%', border: 'none' }}
            value={
              optionRef.recommendModalType
                ? optionRef.recommendModalType
                : 'product'
            }
            onChange={e => {
              optionRef.recommendModalType = e.target.value
              setQuestions([...questions])
            }}
          >
            <option value='product'>{t('recommend_product')}</option>
            <option value='article'>{t('recommend_article')}</option>
          </Input>
        </div>
      )
    }
  }
  return (
    <div className='w-100'>
      <div className='col-sm-12 w-100 mt-4 d-flex flex-column align-items-start '>
        {question.options.map((el, index) => (
          <div className=' row  w-100 justify-content-between  position-relative   mx-auto  mb-4'>
            <div className='col-sm-12 col-md-3 d-flex'>
              <div className='mt-1  align-self-center mr-4  '>
                <p>{index < 9 ? <>{`0${index + 1}`}</> : <>{index + 1}</>}</p>
              </div>
              <div className=' '>
                <input
                  type='text'
                  className='w-100 border-0  bg-transparent '
                  placeholder={`${t('option')} ${index + 1}`}
                  name='option'
                  id={`option${index}`}
                  value={`${el.option}`}
                  required
                  onChange={e => {
                    question.options[index].option = e.target.value
                    setQuestions([...questions])
                  }}
                />
              </div>
            </div>
            <div className='col-sm-12 col-md-2 d-flex'>
              <span className='p-0 ' id={`TooltipExample`}>
                <img
                  src={
                    question.options[index].important
                      ? require('assets/icons/filled-star.png')
                      : require('assets/icons/Unfilled-star.png')
                  }
                  onClick={e => {
                    question.options[index].important = !question.options[index]
                      .important
                    setQuestions([...questions])
                  }}
                  alt=''
                  className='ques-option-icon cursor-pointer p-0 '
                />
              </span>
              <span className='ml-2'>
                {index > 1 ? (
                  <img
                    src={require('assets/icons/close.png')}
                    onClick={() => handleRemoveOption(quesIndex, index)}
                    alt=''
                    className='ques-option-icon cursor-pointer pl-1'
                  />
                ) : (
                  <span></span>
                )}
              </span>
            </div>
            <div className='col-sm-12 col-md-3'>
              <input
                className='formAnswerInput p-2'
                placeholder={t('answer')}
                value={question.options[index].answer || null}
                onChange={e => {
                  question.options[index].answer = e.target.value
                  setQuestions([...questions])
                }}
              ></input>
            </div>
            <div className='col-sm-12 col-md-3 pr-0 '>
              <Input
                type='select'
                name='questionType'
                id={index}
                className='border-0'
                value={question.options[index].linkedTo || 'null'}
                onChange={e => {
                  let id = e.target.id
                  question.options[id].linkedTo = e.target.value
                  setQuestions([...questions])
                }}
              >
                <option value={'null'} selected>
                  {t('link_to_no_question')}
                </option>
                {questions && questions.length > 0
                  ? questions.map((question, index) => {
                      if (index > quesIndex) {
                        return (
                          <option id={index} value={question.UUID}>
                            {t('link_to_question')} {index + 1}{' '}
                          </option>
                        )
                      }
                    })
                  : null}
              </Input>
            </div>
            <div className='col-md-1 pr-0'>{getRecommendElements(index)}</div>
          </div>
        ))}
        <div
          className='text-underlined mt-3 link-button cursor-pointer'
          onClick={() => {
            question.options = [
              ...question.options,
              {
                option: '',
                value: false,
                important: false,
                answerUniqueId: uuidv4()
              }
            ]
            setQuestions([...questions])
          }}
        >
          <u>{t('add_another_option')}</u>
        </div>
      </div>
    </div>
  )
}

export default McqTypeQuestion
