import firebase from 'firebase/app';
import 'firebase/messaging';
import moment from 'moment';
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "store/actions";

const config = {
    apiKey: "AIzaSyDSLOeD5JUONOpk9sOwqpSt2EBEz1vnEhk",
    authDomain: "centrefy-test.firebaseapp.com",
    projectId: "centrefy-test",
    storageBucket: "centrefy-test.appspot.com",
    messagingSenderId: "287326419302",
    appId: "1:287326419302:web:4325b1f06f9af4c2f1f0a7"
};


firebase.initializeApp(config);
export const messaging = firebase.messaging();

// next block of code goes here

export const requestFirebaseNotificationPermission = () =>
    new Promise((resolve, reject) => {
        messaging
            .requestPermission()
            .then(() => messaging.getToken())
            .then((firebaseToken) => {
                resolve(firebaseToken);
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });


export const onMessageListener = () =>
    new Promise((resolve) => {
        const dispatch = useDispatch();
        const Auth = useSelector(({ auth }) => {
            return auth.login.success ? auth.user : false;
        });
        messaging.onMessage((payload) => {
            console.log(payload);
            if (payload.data.event === "meeting") {
                console.log("inside eeting payload push noti");
                let frmt2 = "YYYY-MM-DD HH:mm:ss";
                let dateN = new Date();
                let utcNow = moment(dateN).utc().format(frmt2);
                const variabless = {
                    dateRange: [moment().format(`YYYY-MM-DD`), moment().format("YYYY-MM-DD")],
                    dateToday: utcNow,
                };
                dispatch(Actions.getUpcomingMeetingActivitesForAlert(variabless, Auth.id));
            } else {
                NotificationManager.success(`${payload.notification.body}`);
                dispatch(Actions.setNotificationLoading(true));
                dispatch(Actions.addToNotifications({ body: `${payload.notification.body}`, detailPage: '/' }));
            }
            resolve(payload);
        });
    });
