import MedicineFilterModal from "components/FilterModals/MedicineFilterModal";
import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const MedicineModal = (props) => {
  const pickItem = (id, name) => {
    props.itemPicked(id, name);
  };
  const pickAll = () => {
    props.allPicked();
  };
  return (
    <div>
      <Modal isOpen={props.modal} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle}>Pick Medicine</ModalHeader>
        <ModalBody>
          <MedicineFilterModal
            pickItem={pickItem}
            pickAll={pickAll}
            {...props}
            statsScreen={true}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default MedicineModal;
