import { Avatar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Input, Table } from "reactstrap";
import Pagination from "@material-ui/lab/Pagination";
// import ConfrimDialogue from "components/Dialogue/ConfrimDialogue";
// import History from "@history";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import { CircularProgress } from "@material-ui/core";
import TableLoader from "components/ErrorHandlers/TableLoader";
import { useDispatch, useSelector } from "react-redux";
import qs from "query-string";
import { useTranslation } from "react-i18next";
import * as Actions from "store/actions";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";

export default function PatientFilterModal(props) {
  const dispatch = useDispatch();
  let querystr = qs.parse(props.location.search);
  const [variables, setVariables] = useState({ page: 1 });
  const [patient, setPatient] = useState(null);
  const { t } = useTranslation();

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  const patientReducer = useSelector(({ patient }) => patient);

  useEffect(() => {
    let vendor_id = Auth.vendor_id;
    dispatch(Actions.setPatientLoading(true));
    dispatch(Actions.getAllPatients(variables, vendor_id));
  }, [variables]);

  useEffect(() => {
    setPatient(patientReducer);
  }, [patientReducer]);

  // const handleAssignToPatient = () => {
  //   // console.log(patients);
  //   dispatch(Actions.setAssignQuestionairesLoading(true));
  //   dispatch(Actions.assignQuestionaireToPatient(questionnaireId, patients, Auth.vendor_id));
  // };

  const handleSelectPatient = (e, id) => {
    console.log(e.target.value);
    const findIndex = props.patientsList.indexOf(id);
    if (findIndex > -1) {
      props.patientsList.splice(findIndex, 1);
    } else {
      props.patientsList.push(id);
    }
    props.setPatientsList([...props.patientsList]);
  };

  if (patient && patient.error) {
    return <ErrorMessageComponents message="Cannot Fetch Employees" />;
  }

  if (patient === null || !patient.patientList) {
    return <CircularProgressComponent />;
  }

  const getPatientList =
    patient.patientList && patient.patientList.length > 0 ? (
      patient.patientList.map((item) => {
        return (
          <tr>
            <td>
              <input
                type="checkbox"
                style={{ transform: "scale(1.5)" }}
                id={item.id}
                onChange={(e) => handleSelectPatient(e, item.id)}
                defaultChecked={
                  (props.selectedPatients &&
                    props.selectedPatients.includes(item.id)) ||
                  (props.patientsList && props.patientsList.includes(item.id))
                }
                checked={
                  (props.selectedPatients &&
                    props.selectedPatients.includes(item.id)) ||
                  (props.patientsList && props.patientsList.includes(item.id))
                }
                disabled={
                  props.selectedPatients &&
                  props.selectedPatients.includes(item.id)
                }
              />
            </td>
            <td>
              <div className="ml-4 d-flex align-items-center">{item.id}</div>
            </td>
            <td>
              <div className="ml-4 d-flex align-items-center">
                <Avatar
                  className="mr-3 medicine-pic"
                  src={item.image ? item.image : null}
                />{" "}
                <p className="mb-0 mr-4">
                  {item.firstName + " " + item.lastName}
                </p>
              </div>
            </td>
            <td>
              <div className="ml-4 d-flex align-items-center">
                <p className="mb-0 mr-4">{item.email}</p>
              </div>
            </td>
            {/* {patients.includes(item.id) ?
              <td>
                <a className="text-muted">
                  Added
              </a>
              </td> : <td onClick={() => setPatients([...patients, item.id])}>
                <a className="centrify-blue-color cursor-pointer">
                  Add To List
              </a>
              </td>
            } */}
          </tr>
        );
      })
    ) : (
      <tr>
        <td colspan={5} className="text-center font-weight-bold">
          {t("nothing_to_display")}
        </td>
      </tr>
    );

  const handlePageChange = (e, pagee) => {
    setVariables({
      ...variables,
      page: pagee,
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let search = document.getElementById("patient-searchh").value;
    setVariables({
      ...variables,
      _firstName: search,
      page: 1,
    });
  };
  return (
    <div className="">
      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-end table-top-bar">
          <div className="d-flex align-items-center filters">
            <Form className="mt-2 mt-sm-0" onSubmit={(e) => handleSearch(e)}>
              <FormGroup className="d-inline-block gift-search-input">
                <Input
                  type="text"
                  name="value"
                  id="patient-searchh"
                  maxLength={10}
                  placeholder="search query..."
                />
                <button type="submit" className="gift-search-icon">
                  <i className="zmdi zmdi-search centrify-blue-color"></i>
                </button>
              </FormGroup>
            </Form>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              color="none"
              onClick={() => props.handleAssignToPatient()}
              type="submit"
              className="primary-background-button-table font-weight-bold d-inline "
            >
              {" "}
              {t("assign")}
            </Button>
          </div>
        </div>
        <div className="mt-4" style={{ position: "relative" }}>
          {patient.loading && <TableLoader />}
          <Table responsive className="main-table">
            <thead className="main-table-header">
              <tr>
                <th>
                  <span className="ml-4 mb-0 d-flex">{t("id")}</span>
                </th>
                <th>
                  <span className="ml-4 mb-0 d-flex">{t("name")}</span>
                </th>
                <th>
                  <span className="ml-4 mb-0 d-flex">{t("email")}</span>
                </th>
                {/* <th></th> */}
              </tr>
            </thead>
            <tbody className="main-table-body">{getPatientList}</tbody>
          </Table>
        </div>
      </div>

      {patientReducer && patientReducer.patientCount > 0 ? (
        <div className="mt-4 d-flex justify-content-center">
          <Pagination
            count={Math.ceil(patientReducer.patientCount / 10)}
            page={variables.page + 1}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
          />
        </div>
      ) : null}
    </div>
  );
}
