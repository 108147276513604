import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "store/actions";
import moment from "moment";
import { TaskTimer } from "tasktimer";
import NotificationBox from "./NotificationBox";
import "reactjs-crontab/dist/index.css";

export const AlertScheduler = () => {
  const dispatch = useDispatch();
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  let frmt2 = "YYYY-MM-DD HH:mm:ss";
  // let dateN = new Date();
  // let utcNow = moment(dateN).utc().format(frmt2);

  // const [variables, setVariables] = useState({
  //   dateRange: [moment().format(`YYYY-MM-DD`), moment().format("YYYY-MM-DD")],
  //   dateToday: utcNow,
  // });
  const activityReducer = useSelector(({ activity }) => activity);

  const vendor_id = Auth.id;

  useEffect(() => {
    dispatch(Actions.getUpcomingMeetingActivitesForNotificatins(vendor_id));
  }, []);

  const timer = new TaskTimer();
  const [notification, setNotification] = React.useState({
    open: false,
    name: "",
    title: "",
    time: "",
  });

  function get_Notification_After_24_hours() {
    const notificationDate = localStorage.getItem("notidicationDate");
    let newDate = new Date();
    let toDayDate = moment(newDate).utc().format("DD");
    let meetDate = moment(notificationDate).utc().format("DD");
    if (toDayDate !== meetDate) {
      dispatch(Actions.getUpcomingMeetingActivitesForNotificatins(vendor_id));
      console.log("reducer api call");
    }
  }
  setInterval(get_Notification_After_24_hours, 60 * 5000);

  useEffect(() => {
    let timingsArray = [];
    let timingsArray1 = [];
    let getLocalData = JSON.parse(localStorage.getItem("notiArray"));
    if (getLocalData?.length) {
      timingsArray = getLocalData;
    }

    for (let i = 0; i < timingsArray.length; i++) {
      clearTimeout(timingsArray[i]);
    }

    localStorage.setItem("notiArray", JSON.stringify([]));
    if (activityReducer.getActivityForNotifications.length > 0) {
      activityReducer.getActivityForNotifications.map((el, index) => {
        const fullDate = moment.utc(el.date).local().format(frmt2);
        const finalDate = new Date(fullDate);
        const minutes = finalDate.getMinutes();
        const hour = finalDate.getHours();
        // timer
        let start = new Date();
        let end = finalDate;
        let secondsBetween = (end - start) / 1000;
        timingsArray1.push(
          setTimeout(() => {
            setNotification({
              open: true,
              time: `${hour}: ${minutes}`,
              title: el.title,
              name: "",
            });
          }, secondsBetween * 1000)
        );
      });
    }

    localStorage.setItem("notiArray", JSON.stringify(timingsArray1));
    console.log(timingsArray1, "called");
  }, [activityReducer.getActivityForNotifications]);

  const handleClose = () => {
    setNotification({
      open: false,
      time: "",
      title: "",
      name: "",
    });
  };

  return (
    <div>
      {notification.open && (
        <NotificationBox
          handleClose={handleClose}
          notification={notification}
          setNotification={setNotification}
        />
      )}
    </div>
  );
};
