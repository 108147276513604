import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "./lib/reactifyCss";
import Layout from "components/layout";
import routerService from "services/_routeServices";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "store/actions";
import History from "@history";
import AdminLogin from "components/loginpage/AdminLogin";
import AdminSignup from "components/loginpage/AdminSignup";
import ResetEmail from "components/forgetPassword/resetEmail";
import ResetPassword from "components/forgetPassword/forgetPass";
import Projects from "routes/projects";
import { AlertScheduler } from "components/NotificationAlert/schedulerComp";

import {
  requestFirebaseNotificationPermission,
  onMessageListener,
  messaging,
} from "./firebaseInit";
import "./firebaseInit";

function App(props) {
  const dispatch = useDispatch();
  let authCheck = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });

  onMessageListener()
    .then((payload) => {
      console.log(payload);
    })
    .catch((err) => console.log("failed: ", err));

  console.log(History.location.pathname);

  useEffect(() => {
    if (authCheck !== false) {
      // fcm notification
      requestFirebaseNotificationPermission()
        .then((firebaseToken) => {
          console.log("inside firebase resolve");
          // eslint-disable-next-line no-console
          let data = {
            id: authCheck.id,
            fcmToken: firebaseToken,
          };
          dispatch(Actions.updateProfileFcmToken(data));
          console.log(data);
        })
        .catch((err) => {
          console.log("inside firebase reject");
          console.log(err);
          return err;
        });
    }
  }, []);

  useEffect(() => {
    if (
      authCheck === false &&
      History.location.pathname !== "/forget-password" &&
      History.location.pathname !== "/reset-password"
    ) {
      History.push({
        pathname: "/login",
      });
    } else {
      // if (History.location.pathname === '/') {
      //   History.push({
      //     pathname: "/projects",
      //   });
      // }
    }
  }, [authCheck]);
  const roleRendering = (route) => {
    return route.includes(authCheck.role) ? true : false;
  };

  const getUrl = (pathname) => {
    return pathname === "/login" ? true : false;
  };
  const getSignupUrl = (pathname) => {
    return pathname === "/signup" ? true : false;
  };

  const getResetPassUrl = (pathname) => {
    return pathname === "/reset-password" ? true : false;
  };

  const resetPassEmail = (pathname) => {
    return pathname === "/forget-password" ? true : false;
  };

  const { location } = props;

  return (
    <div className="App">
      <NotificationContainer />
      {authCheck && <AlertScheduler />}
      <Switch>
        {getUrl(location.pathname) ? (
          <Route
            exact
            path="/login"
            render={(props) => <AdminLogin {...props} />}
          />
        ) : getSignupUrl(location.pathname) ? (
          <Route
            exact
            path="/signup"
            render={(props) => <AdminSignup {...props} />}
          />
        ) : getResetPassUrl(location.pathname) ? (
          <Route
            exact
            path="/reset-password"
            render={(props) => <ResetPassword {...props} />}
          />
        ) : resetPassEmail(location.pathname) ? (
          <Route
            exact
            path="/forget-password"
            render={(props) => <ResetEmail {...props} />}
          />
        ) : (
          <>
            <Layout>
              <Switch>
                {routerService &&
                  routerService.map((route, key) =>
                    roleRendering(route.auth) ? (
                      <Route
                        key={key}
                        path={`/${route.path}`}
                        component={route.component}
                      />
                    ) : null
                  )}
                <Route key={0} path={`/`} component={Projects} />
                <Redirect to="/page-error" />
              </Switch>
            </Layout>
            {/* <Route component={PageNotFound} /> */}
          </>
        )}
      </Switch>
    </div>
  );
}

export default App;
