import { Avatar, FormGroup } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Table } from 'reactstrap'
import { Link } from 'react-router-dom'
import Pagination from '@material-ui/lab/Pagination'
import AddProject from './addProject'
import ConfrimDialogue from 'components/Dialogue/ConfrimDialogue'
import History from '@history'
import ErrorMessageComponents from 'components/ErrorHandlers/ErrorMessageComponents'
import TableLoader from 'components/ErrorHandlers/TableLoader'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import qs from 'query-string'
import CircularProgressComponent from 'components/ErrorHandlers/CircularProgressComponent'
import * as Actions from 'store/actions'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5)
  }
}))

export default function Projects (props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  let querystr = qs.parse(props.location.search)
  let page = querystr.page ? JSON.parse(querystr.page) : 1

  const [variables, setVariables] = useState({ ...querystr })
  const [projects, setProjects] = useState(null)
  const [open, setOpen] = useState(false)
  const [data, setData] = useState(false)
  const [dialogue, setDialogue] = useState(false)

  const projectReducer = useSelector(({ project }) => project)

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false
  })

  useEffect(() => {
    const q_str = qs.stringify(variables)
    History.push({ search: q_str })
    let vendor_id = Auth.vendor_id
    dispatch(Actions.setProjectLoading(true))
    dispatch(Actions.getAllProjects(variables, vendor_id))
  }, [dispatch, variables])

  useEffect(() => {
    setProjects(projectReducer)
    if (projectReducer.posted) {
      setOpen(false)
    }
  }, [projectReducer])

  const getProjectList =
    projects && projects.projectList && projects.projectList.length > 0 ? (
      projects.projectList.map(item => {
        return (
          <tr>
            <td>
              <Link to={`/project/${item.name}/${item.id}/0`}>
                <div className='ml-4 d-flex align-items-center'>
                  <Avatar
                    className='mr-3 medicine-pic'
                    src={item.imageUrl !== null ? item.imageUrl : null}
                  />{' '}
                  <p className='mb-0 mr-2 prominent'>{item.name}</p>
                </div>
              </Link>
            </td>
            <td>{item.country}</td>
            <td>{item.region}</td>
            <td>{item.type}</td>
            <td
              className='centrify-blue-color cursor-pointer'
              onClick={() => History.push(`/project/${item.name}/${item.id}/0`)}
            >
              {item.patientproject.length}
            </td>
            <td> {moment(item.createdAt).format('DD-MM-YYYY')}</td>
            {props.statsScreen ? (
              <td onClick={() => props.pickItem(item.id, item.name)}>
                <a className='centrify-blue-color'>{t('pick')}</a>
              </td>
            ) : (
              <td
                onClick={() => {
                  setDialogue(!dialogue)
                  setData(item)
                }}
              >
                <img
                  src={require('assets/icons/remove.png')}
                  alt=''
                  className='centrify-table-icons cursor-pointer'
                />
              </td>
            )}
          </tr>
        )
      })
    ) : (
      <tr>
        <td colspan={6} className='text-center font-weight-bold'>
          {t('nothing_to_display')}
        </td>
      </tr>
    )

  const handlePageChange = (e, page) => {
    setVariables({
      ...variables,
      page: page
    })
  }

  const handleSearch = e => {
    e.preventDefault()
    let search = document.getElementById('project-search').value
    setVariables({
      ...variables,
      _name: search,
      page: 1
    })
    document.getElementById('project-search').value = ''
  }

  const handleDelete = () => {
    document.getElementById('project-search').value = ''
    setVariables({
      ...variables,
      _name: '',
      page: 1
    })
  }
  const handleProjectDelete = () => {
    dispatch(Actions.setProjectLoading(true))
    dispatch(Actions.deleteProject(data.id, Auth.id, Auth.vendor_id))
    setDialogue(!dialogue)
    setData(null)
  }

  const deleteEmployeeFiler = () => {
    setVariables({
      ...variables,
      employee_id: null,
      employee_name: null,
      filter_by: null,
      page: 1
    })
  }

  const deletePatientFiler = () => {
    setVariables({
      ...variables,
      patient_id: null,
      patient_name: null,
      filter_by: null,
      page: 1
    })
  }

  if (projects && projects.error) {
    return <ErrorMessageComponents message={t('cannot_fetch_projects')} />
  }

  if (!projects || projects === null || !projects.projectList) {
    return <CircularProgressComponent />
  }

  return (
    <div>
      <div className='mt-4'>
        <div>
          {variables._name && (
            <Chip
              label={variables._name}
              onDelete={() => handleDelete()}
              className={classes.chip}
            />
          )}
        </div>
        <div className='d-flex justify-content-between align-items-end table-top-bar'>
          <div className='d-flex align-items-center filters'>
            <Form className='mt-2 mt-sm-0 mr-2' onSubmit={e => handleSearch(e)}>
              <FormGroup className='d-inline-block gift-search-input'>
                <Input
                  type='text'
                  name='value'
                  id='project-search'
                  maxLength={10}
                  placeholder={t('search')}
                  style={{ paddingRight: '40px' }}
                />
                <button type='submit' className='gift-search-icon'>
                  <i className='zmdi zmdi-search centrify-blue-color'></i>
                </button>
              </FormGroup>
            </Form>
            {variables.employee_id && (
              <Chip
                onDelete={() => deleteEmployeeFiler()}
                label={
                  variables.filter_by == 'employee' ? (
                    <span>
                      <span className='font-weight-bold'>
                        {t('filtered_by_employee')}:
                      </span>
                      {variables.employee_name}
                    </span>
                  ) : null
                }
              />
            )}
            {variables.patient_id && (
              <Chip
                onDelete={() => deletePatientFiler()}
                label={
                  variables.filter_by == 'patient' ? (
                    <span>
                      <span className='font-weight-bold'>
                        {t('filtered_by_patient')}:
                      </span>{' '}
                      {variables.patient_name}{' '}
                    </span>
                  ) : null
                }
              />
            )}
          </div>
          {!props.statsScreen && (
            <Button
              onClick={() => setOpen(!open)}
              color='none'
              className='primary-background-button-table font-weight-bold'
            >
              {t('create_project')}
            </Button>
          )}
        </div>
        <div className='mt-4' style={{ position: 'relative' }}>
          {projects.loading && <TableLoader />}
          <Table responsive className='main-table'>
            <thead className='main-table-header'>
              <tr>
                <th>
                  <span className='ml-4 mb-0 d-flex'>{t('name')}</span>
                </th>
                <th>{t('country')}</th>
                <th>{t('region')}</th>
                <th>{t('type')}</th>
                <th>{t('patients')}</th>
                <th>{t('date')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody className='main-table-body'>{getProjectList}</tbody>
          </Table>
        </div>
        {projects.projectCount > 0 ? (
          <div className='mt-4 d-flex justify-content-center'>
            <Pagination
              count={Math.ceil(projects.projectCount / 10)}
              page={page}
              onChange={handlePageChange}
              variant='outlined'
              shape='rounded'
            />
          </div>
        ) : null}
      </div>
      <AddProject open={open} toggle={() => setOpen(!open)} />
      <ConfrimDialogue
        open={dialogue}
        handleClose={() => setDialogue(false)}
        action={() => handleProjectDelete()}
        msg={t('are_you_sure_you_want_to_delete') + '?'}
      />
    </div>
  )
}
