import React from "react";
import { useDispatch } from "react-redux";
import * as Actions from "store/actions";
import EmployeeFilterModal from "./employeeFilterModal";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";

const EmployeeModal = (props) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const pickItem = (id, name) => {
    props.itemPicked(id, name);
  };
  const pickAll = () => {
    props.allPicked();
  };

  const handleAddEmployee = () => {
    if (props.selectedEmployees && props.projectId) {
      let obj = {
        employees: props.selectedEmployees,
        project_id: props.projectId,
      };
      dispatch(Actions.setProjectAssignLoading(true));
      dispatch(Actions.assignEmployeeToProject(props.projectId, obj));
    }
  };

  let closeModal = () => {
    dispatch(Actions.setEngagementAssignLoading(false));
    props.toggle();
  };
  return (
    <div>
      <Modal isOpen={props.modal} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>{t("assign_employee")}</ModalHeader>
        <ModalBody>
          <EmployeeFilterModal
            selectedEmployees={props.selectedEmployees}
            setSelectedEmployees={props.setSelectedEmployees}
            handleAddEmployee={handleAddEmployee}
            pickItem={pickItem}
            pickAll={pickAll}
            engagementId={props.engagementId}
            {...props}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeModal}>
            {t("cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EmployeeModal;
