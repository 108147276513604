import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "store/actions";
import { CircularProgress } from "@material-ui/core";
import { Button, Modal, Label, ModalBody } from "reactstrap";
import { useTranslation } from "react-i18next";

const ChatQuestionModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const projectReducer = useSelector(({ project }) => project);
  const [chatQForm, setChatQForm] = useState({});
  let sampleArray = [1, 2, 3];
  console.log("Sample Array --->", sampleArray);
  let newSampleArray = [...sampleArray];
  newSampleArray.push("4");
  console.log("newSampleArray Array Updated", newSampleArray);
  console.log("Original Sample array --->", sampleArray);
  let handleSubmit = (e) => {
    e.preventDefault();
    let obj = {
      question: chatQForm.question,
      answer: chatQForm.answer,
      project_id: props.projectId,
    };

    dispatch(Actions.setProjectAssignLoading(true));
    if (props.chatQModalType == "add") {
      dispatch(Actions.addProjectChatQuestion(props.projectId, obj));
    } else {
      let questionId = props.chatQuestion.id;
      dispatch(
        Actions.updateProjectChatQuestion(props.projectId, obj, questionId)
      );
    }
    setChatQForm({ question: null, answer: null });
  };
  useEffect(() => {
    if (
      (props.chatQuestion && props.chatQModalType == "update") ||
      props.chatQModalType == "view"
    ) {
      setChatQForm({
        question: props.chatQuestion?.question,
        answer: props.chatQuestion?.answer,
      });
    } else {
      setChatQForm({ question: null, answer: null });
    }
  }, [props.chatQModalType, props.chatQuestion]);
  let getChatQuestionHeader = (type) => {
    let obj = {
      add: t("create_new_question"),
      update: t("update_question"),
      view: t("question_detail"),
    };
    return obj[type];
  };

  return (
    <div>
      <Modal
        isOpen={props.modal}
        toggle={props.toggle}
        className="modal-background"
      >
        <ModalBody>
          <div className="p-4">
            <div className="d-flex justify-content-end">
              <img
                onClick={() => props.toggle()}
                className="centrify-icons cursor-pointer"
                src={require("assets/icons/close.png")}
              />
            </div>
            <div className="mt-2 form-styles">
              <h2 className="form-heading d-flex mb-">
                {getChatQuestionHeader(props.chatQModalType)}
              </h2>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="mt-4">
                  <Label className="d-flex mb-3">{t("question")}</Label>
                  <textarea
                    type="text"
                    name="question"
                    required
                    disabled={props.chatQModalType == "view" ? true : false}
                    value={chatQForm?.question}
                    onChange={(e) => {
                      setChatQForm({
                        ...chatQForm,
                        question: e.target.value,
                      });
                    }}
                    rows={2}
                    style={{
                      width: "100%",
                      resize: "none",
                      border: "1px solid #d3d8dd",
                      borderRadius: "7px",
                      padding: "10px",
                    }}
                  />
                </div>
                <div className="mt-4">
                  <Label className="d-flex mb-3">{t("answer")}</Label>
                  <textarea
                    type="text"
                    name="answer"
                    required
                    value={chatQForm?.answer}
                    onChange={(e) => {
                      setChatQForm({
                        ...chatQForm,
                        answer: e.target.value,
                      });
                    }}
                    disabled={props.chatQModalType == "view" ? true : false}
                    rows={2}
                    style={{
                      width: "100%",
                      resize: "none",
                      border: "1px solid #d3d8dd",
                      borderRadius: "7px",
                      padding: "10px",
                    }}
                  />
                </div>
                {props.chatQModalType == "view" ? null : (
                  <div className="mt-4 d-flex justify-content-end">
                    {projectReducer.assignLoading ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        className="primary-background-button-table"
                        color="none"
                      >
                        {props.chatQModalType == "add"
                          ? t("create")
                          : t("update")}
                      </Button>
                    )}
                  </div>
                )}
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ChatQuestionModal;
