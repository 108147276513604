import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Form, FormGroup, Input, Table, Button } from 'reactstrap'
import VisibilityIcon from '@material-ui/icons/Visibility'
import AssignmentIcon from '@material-ui/icons/Assignment'
import Pagination from '@material-ui/lab/Pagination'
import ConfrimDialogue from 'components/Dialogue/ConfrimDialogue'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

import momentt from 'moment-timezone'
import History from '@history'
import ErrorMessageComponents from 'components/ErrorHandlers/ErrorMessageComponents'
import TableLoader from 'components/ErrorHandlers/TableLoader'
import { useDispatch, useSelector } from 'react-redux'
import qs from 'query-string'
import moment from 'moment'
import CircularProgressComponent from 'components/ErrorHandlers/CircularProgressComponent'
import { useTranslation } from 'react-i18next'
import * as Actions from 'store/actions'
// import AssignToProjectModal from './projectModal/projectModal'
import CategoryModal from './categoryModal'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'
// import CsvModal from './csvModal'

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5)
  }
}))

export default function Categories (props) {
  const dispatch = useDispatch()
  const classes = useStyles()
  let querystr = qs.parse(props.location.search)
  let page = querystr.page ? JSON.parse(querystr.page) : 1
  const [variables, setVariables] = useState({ ...querystr })
  const [data, setData] = useState(null)
  const [categoryId, setCategoryId] = useState(null)
  const [categoryModalType, setCategoryModalType] = useState('add')
  const [categoryModalState, setCategoryModalState] = useState(false)
  const [csvModalState, setCsvModalState] = useState(false)

  const [category, setCategory] = useState(null)
  const [categories, setCategories] = useState(null)

  const [dialogue, setDialogue] = useState(false)
  const { t } = useTranslation()

  console.log('variables --->', variables)
  const stringTruncate = (str, length) => {
    if (str) {
      const dots = str.length > length ? '...' : ''
      return `${str.substring(0, length)}${dots}`
    } else {
      return null
    }
  }
  console.log('category ---->', category)
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false
  })
  const storeReducer = useSelector(({ store }) => store)

  useEffect(() => {
    const q_str = qs.stringify(variables)
    History.push({ search: q_str })
    dispatch(Actions.setStoreLoading(true))
    dispatch(Actions.getStoreCategories(variables, Auth.vendor_id))
  }, [variables])
  console.log('storeReducer ---.', storeReducer)
  console.log('categories --->', categories)
  useEffect(() => {
    if (storeReducer && storeReducer.category) {
      setCategory(storeReducer.category)
      if (storeReducer.category?.posted) {
        setCategoryModalState(false)
      }
    }
  }, [storeReducer])

  useEffect(() => {
    if (storeReducer && storeReducer.category && storeReducer.category.rows) {
      setCategories(storeReducer.category.rows)
    }
  }, [storeReducer])

  const getCategoryList =
    category && category.rows && category.rows.length > 0 ? (
      category.rows.map(item => {
        return (
          <tr>
            <td>
              <Link to={`/category/${item.title}/${item.id}/1`}>
                <img
                  className='ml-4 mb-0 prominent cursor-pointer'
                  src={item?.image}
                  style={{
                    width: '50px',
                    maxHeight: '75px',
                    objectFit: 'cover'
                  }}
                />
              </Link>
            </td>
            <td>
              <Link to={`/category/${item.title}/${item.id}/1`}>
                <p className=' mb-0 prominent cursor-pointer'>
                  {stringTruncate(item.title, 50)}
                </p>
              </Link>
            </td>

            <td className=''> {stringTruncate(item.description, 50)}</td>
            <td>{item?.id}</td>

            <td>
              <div className='d-flex   '>
                <EditIcon
                  onClick={() => {
                    setCategoryModalType('update')
                    setData(item)
                    setCategoryModalState(true)
                  }}
                  className='centrify-grey-color cursor-pointer mr-2'
                />
                <DeleteIcon
                  onClick={() => {
                    setData(item)
                    setDialogue(!dialogue)
                  }}
                  className={`cursor-pointer  mr-2 centrify-grey-color `}
                />
              </div>
            </td>
          </tr>
        )
      })
    ) : (
      <tr>
        <td colspan={7} className='text-center font-weight-bold'>
          {t('nothing_to_display')}
        </td>
      </tr>
    )
  const handlePageChange = (e, page) => {
    setVariables({
      ...variables,
      page: page
    })
  }

  const handleSearch = e => {
    e.preventDefault()
    let search = document.getElementById('category-search').value
    setVariables({
      ...variables,
      title: search,
      page: 1
    })
  }
  const handleChipDelete = () => {
    document.getElementById('category-search').value = ''
    setVariables({
      ...variables,
      title: '',
      page: 1
    })
  }

  const handleDelete = () => {
    if (data) {
      dispatch(Actions.setStoreLoading(true))
      dispatch(Actions.deleteStoreCategory(data.id, Auth.vendor_id))
      setDialogue(!dialogue)
      setData(null)
    }
  }
  if (category && category.error) {
    return <ErrorMessageComponents message={t('cannot_get_categories')} />
  }

  if (storeReducer === null || (storeReducer && !storeReducer.category)) {
    return <CircularProgressComponent />
  }

  return (
    <div>
      <div className='mt-4'>
        {variables.title && (
          <Chip
            label={variables.title}
            onDelete={() => handleChipDelete()}
            className={classes.chip}
          />
        )}
        <div className='d-flex justify-content-between align-items-end table-top-bar'>
          <div className='d-flex align-items-center filters'>
            <Form className='mt-2 mt-sm-0' onSubmit={e => handleSearch(e)}>
              <FormGroup className='d-inline-block gift-search-input mr-4'>
                <Input
                  type='text'
                  name='value'
                  id='category-search'
                  maxLength={20}
                  placeholder={t('search')}
                  style={{ paddingRight: '40px' }}
                />
                <button type='submit' className='gift-search-icon'>
                  <i className='zmdi zmdi-search centrify-blue-color'></i>
                </button>
              </FormGroup>
            </Form>
          </div>
          <div>
            <Button
              color='none'
              className='primary-background-button-table font-weight-bold mr-4'
              onClick={() => {
                setCategoryModalType('add')
                setData(null)
                setCategoryModalState(true)
              }}
            >
              {t('create_category')}
            </Button>
          </div>
        </div>

        <div className='mt-4' style={{ position: 'relative' }}>
          {storeReducer.loading && <TableLoader />}
          <Table responsive className='main-table'>
            <thead className='main-table-header '>
              <tr>
                <th>
                  <span className='ml-4 mb-0 d-flex'>{t('image')}</span>
                </th>
                <th>{t('title')}</th>
                <th>{t('description')}</th>
                <th>{t('ID')}</th>
                <th>{t('actions')}</th>
              </tr>
            </thead>
            <tbody className='main-table-body'>{getCategoryList}</tbody>
          </Table>
        </div>

        {storeReducer?.category?.count > 0 ? (
          <div className='mt-4 d-flex justify-content-center'>
            <Pagination
              count={Math.ceil(storeReducer?.category?.count / 10)}
              page={page}
              onChange={handlePageChange}
              variant='outlined'
              shape='rounded'
            />
          </div>
        ) : null}
      </div>

      <ConfrimDialogue
        open={dialogue}
        handleClose={() => setDialogue(false)}
        action={() => handleDelete()}
        msg={t('are_you_sure_you_want_to_delete') + '?'}
      />
      <CategoryModal
        {...props}
        modal={categoryModalState}
        categories={categories}
        categoryModalType={categoryModalType}
        category={data}
        toggle={() => {
          setCategoryModalState(!categoryModalState)
        }}
      />
      {/* <CsvModal
        {...props}
        modal={csvModalState}
        toggle={() => {
          setCsvModalState(!csvModalState)
        }}
      /> */}
    </div>
  )
}
