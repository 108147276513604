import BreadCrumbComponent from "components/Breadcrumbs";
import React, { useEffect, useState } from "react";
import Questionnaire from "./questionnaire/questionnaire";
import Activities from "./activities/activities";
import Projects from "./projects";
import Patients from "./patients";
import EngagementChart from "./chart";
import Stats from "./stats/stats";
import CommingSoon from "components/pages/CommingSoon";
import { useTranslation } from "react-i18next";
import * as Actions from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import History from "@history";

export default function EngagementDetail(props) {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [engagement, setEngagement] = useState(null);
  const { t } = useTranslation();
  const engagementReducer = useSelector(({ engagement }) => engagement);

  useEffect(() => {
    if (props.match.params.id) {
      dispatch(Actions.setEngagementLoading(true));
      dispatch(Actions.getEngagementDetail(props.match.params.id));
    }
  }, [props.match.params.id]);

  useEffect(() => {
    if (engagementReducer.engagement) {
      setEngagement(engagementReducer.engagement);
    }
  }, [engagementReducer.engagement]);
  useEffect(() => {
    if (props.match.params.tab) {
      setValue(props.match.params.tab);
    }
  }, [props.match.params.tab]);

  if (engagementReducer.error) {
    return (
      <ErrorMessageComponents message={t("cannot_get_engagement_detail")} />
    );
  }

  if (engagementReducer === null || engagementReducer.loading) {
    return <CircularProgressComponent />;
  }

  let tabs = [
    t("questionnaire"),
    t("activities"),
    t("projects"),
    t("patients"),
    t("engagementChart"),
  ];

  return (
    <div>
      <div className="">
        <BreadCrumbComponent
          active={engagementReducer?.engagement?.title}
          previous={[{ name: t("engagements"), link: "/engagements" }]}
        />
      </div>
      <div className="d-flex justify-content-end">
        <p>
          Engagement Duration :{" "}
          <span className="fw-bold">{engagement?.duration}</span>{" "}
        </p>
      </div>
      <div className=" d-flex flex-wrap align-items-center tabs">
        {tabs.map((item, index) => {
          return (
            <p
              onClick={(e) => {
                History.push(`/engagement/${props.match.params.id}/${index}`);
              }}
              className={`${
                index == value ? "active-tab" : ""
              } mb-0 margin-tabs mt-2 cursor-pointer`}
            >
              {item}
            </p>
          );
        })}
      </div>
      <div className="mt-4">
        {value == 0 ? (
          <div className="">
            <Questionnaire engagement={engagement} {...props} />
          </div>
        ) : value == 1 ? (
          <div className="">
            <Activities engagement={engagement} {...props} />
          </div>
        ) : value == 2 ? (
          <div className="">
            <Projects engagement={engagement} {...props} />
          </div>
        ) : value == 3 ? (
          <div className="">
            <Patients engagement={engagement} {...props} />
          </div>
        ) : (
          <div>
            <EngagementChart engagement={engagement} {...props} />
          </div>
        )}
      </div>
    </div>
  );
}
