import React, { useState, useEffect } from 'react'
import * as Actions from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import ErrorMessageComponents from 'components/ErrorHandlers/ErrorMessageComponents'
import CircularProgressComponent from 'components/ErrorHandlers/CircularProgressComponent'

import ImageGallery from 'react-image-gallery'

const ProductCard = ({ productDetail, images }) => {
  const { t } = useTranslation()
  console.log('Product detail received --->', productDetail)
  return (
    <div>
      <div className=' d-flex'>
        <div className=' ' style={{ width: '40%' }}>
          <ImageGallery
            items={images || []}
            lazyLoad={true}
            showPlayButton={false}
            showFullscreenButton={false}
          />
        </div>
        <div
          className=' p-4 '
          style={{ width: '60%', overflowWrap: 'break-word' }}
        >
          <h1 className='my-2 font-2dot5rem  '>{productDetail?.title}</h1>
          <h3 className='my-2'>{productDetail?.unit}</h3>
          <h1 className='my-4 font-1dot5rem '>{productDetail?.price}</h1>
          <div className='my-4'>
            <h2 className='mb-3 font-1dot5rem pb-2 border-2  border-dark border-bottom  d-inline-block'>
              Description
            </h2>
            <p className='font-1rem'>{productDetail?.description}</p>
          </div>
          <div className='my-4'>
            <h2 className='mb-3 font-1dot5rem pb-2 border-2 border-dark border-bottom d-inline-block'>
              Product Web Store Link
            </h2>
            <a
              href={productDetail?.link}
              className='font-1rem w-100'
              style={{ overflowWrap: 'break-word', textOverflow: 'wrap' }}
            >
              {productDetail?.link}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductCard
