import { CircularProgress, IconButton } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody, Label, Input } from "reactstrap";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import { v4 as uuidv4 } from "uuid";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import momentt from "moment-timezone";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import * as Actions from "store/actions";
import { NotificationManager } from "react-notifications";
import { AiOutlineDown } from "react-icons/ai";

import DatePicker from "components/DatePicker/DatePicker";
import { timeZoneList as timeZone } from "lib/timeZone";
import SelectQuestionnaireModal from "./selectEngQuestionnaireModal/engagmentQuestionnaireModal";

function SelectEngagementQuestionnarie(props) {
  const { t } = useTranslation();
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });

  const engagementReducer = useSelector(({ engagement }) => engagement);
  let [questionnaire, setQuestionnaire] = useState(null);
  let [inputValues, setInputValues] = useState({
    intervals: null,
    endDay: null,
    startDay: null,
    validTill: null,
    when: 1,
  });
  let [selectQModalState, setSelectQModalState] = useState(false);
  let [pickedQuestionaire, setPickedQuestionnaire] = useState(null);
  const [selectedTime, setSelectedTime] = useState(new Date());
  const [when, setWhen] = useState([
    {
      id: 0,
      time: selectedTime,
      zoneTime: moment(selectedTime).utc().format("HH:mm"),
    },
  ]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Actions.setEngagementLoading(true));
    dispatch(Actions.getEngagementDetail(props.match.params.eId));
    dispatch(Actions.getEngagementQuestionnaire(props.match.params.qId));
  }, []);

  let onQPick = (value) => {
    setPickedQuestionnaire(value);
  };
  const handleWhenChange = (value, timeGiven) => {
    let num = value;
    let _num = 24 / num;
    let arr = [];
    var clientTimezoneOffset = new Date().getTimezoneOffset() / 60;
    for (let i = 0; i < num; i++) {
      const time = moment(timeGiven || selectedTime)
        .add(_num * i, "h")
        .format("YYYY-MM-DD HH:mm");
      var d = new Date(time);
      d.setHours(d.getHours() + clientTimezoneOffset);
      const zoneTime = moment(d).format("YYYY-MM-DD HH:mm");
      arr.push({
        id: i,
        time,
        zoneTime,
      });
    }
    setWhen(arr);
  };
  useEffect(() => {
    if (engagementReducer.tabContentDetail && engagementReducer.engagement) {
      let questionnaireDetail = engagementReducer.tabContentDetail;
      setQuestionnaire(questionnaireDetail);
      let dateArray = questionnaireDetail.time.split(":");
      let date = new Date();
      const clientTimezoneOffset = new Date().getTimezoneOffset() / 60;
      date.setHours(dateArray[0], dateArray[1], dateArray[2]);
      date.setHours(date.getHours() - clientTimezoneOffset);

      setSelectedTime(date);
      setPickedQuestionnaire(questionnaireDetail.treeQuestionnaire);
      setInputValues({
        startDay: questionnaireDetail?.startDay,
        endDay: questionnaireDetail?.endDay,
        intervals: questionnaireDetail?.intervals,
        when: questionnaireDetail?.when,
        validTill: questionnaireDetail?.validTill,
      });
      if (questionnaireDetail.when && date) {
        handleWhenChange(questionnaireDetail?.when, date);
      }
    }
    if (engagementReducer.error) {
      setQuestionnaire(null);
    }
  }, [engagementReducer]);
  let getDaysBlocks = () => {
    if (inputValues.startDay <= inputValues.endDay) {
      let dayBlocks = [];
      for (let i = 0; i <= inputValues.endDay - inputValues.startDay; i++) {
        dayBlocks.push(
          <div
            className={`col p-2 m-1 dayBlock   ${
              i == 0 ||
              Number(inputValues.intervals) == 0 ||
              i % (Number(inputValues.intervals) + 1) == 0
                ? "primary-background-button-table color-white"
                : "dayBlock-bordered"
            }`}
          >
            Day {i + Number(inputValues.startDay)}
          </div>
        );
      }
      return dayBlocks;
    }
    return null;
  };
  let handleSubmit = (e) => {
    e.preventDefault();
    if (pickedQuestionaire) {
      let obj = {};
      const formData = new FormData(e.target);
      for (let [key, value] of formData.entries()) {
        obj[key] = value;
      }
      if (Number(engagementReducer.engagement.duration) < Number(obj.endDay)) {
        NotificationManager.error(
          "Ending day cannot be bigger than engagement duration"
        );
      } else if (
        Number(engagementReducer.engagement.duration) < Number(obj.startDay)
      ) {
        NotificationManager.error(
          "Starting day cannot be bigger than engagement duration"
        );
      } else if (Number(obj.startDay) > Number(obj.endDay)) {
        NotificationManager.error(
          "Starting day cannot be bigger than ending day"
        );
      } else {
        const clientTimezoneOffset = new Date().getTimezoneOffset() / 60;
        var d = new Date(selectedTime);
        d.setHours(d.getHours() + clientTimezoneOffset);
        const zoneTime = moment(d).format("HH:mm");
        obj.when = obj.when || 1;
        obj.schedule = [];
        let num = 24 / when.length;
        when &&
          when.map((item, idx) => {
            let o = {
              dose: null,
              time: moment(d)
                .add(num * idx, "h")
                .format("HH:mm"),
            };

            obj.schedule.push(o);
          });

        obj.time = zoneTime;
        obj.TreeQuestionnaire_id =
          (pickedQuestionaire && pickedQuestionaire.id) ||
          questionnaire.TreeQuestionnaire_id;
        obj.engagement_id = props.match.params.eId;
        // Getting TimeZone
        var offset = new Date().getTimezoneOffset();

        var o = Math.abs(offset);
        let timeZone =
          (offset < 0 ? "+" : "-") +
          ("00" + Math.floor(o / 60)).slice(-2) +
          ":" +
          ("00" + (o % 60)).slice(-2);
        obj.tz = timeZone;
        console.log("Final Obj ---->", obj);
        dispatch(Actions.setEngagementLoading(true));
        dispatch(
          Actions.updateEngagementQuestionnaire(obj, props.match.params.qId)
        );
      }
    } else {
      NotificationManager.error("Please select a questionnaire");
    }
  };
  if (engagementReducer === null || engagementReducer.loading) {
    return <CircularProgressComponent />;
  }
  if (engagementReducer.error) {
    return <ErrorMessageComponents message={t("something_went_wrong")} />;
  }
  return (
    <div>
      <div className="container">
        <div>
          <div className="my-2 form-styles">
            <h2 className="form-heading d-flex">{t("update_questionnaire")}</h2>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="row mb-2">
                <div className="mt-4 col-md-6">
                  <Label className="d-flex">{t("select_questionnaire")}</Label>
                  <div
                    class="input-group mb-2"
                    onClick={() => {
                      setSelectQModalState(true);
                    }}
                  >
                    <input
                      class="form-control border-end-0 "
                      type="search"
                      value={pickedQuestionaire && pickedQuestionaire.title}
                      id="example-search-input"
                      disabled
                    />
                    <span class="input-group-append">
                      <button
                        className="  ms-n4"
                        style={{
                          background: "transparent",
                          border: "0",
                          borderBottom: "1px solid #d3d8dd",
                        }}
                        type="button"
                      >
                        <AiOutlineDown />
                      </button>
                    </span>
                  </div>
                  {/* <div
                    onClick={() => {
                      setSelectQModalState(true);
                    }}
                  >
                    <Input
                      type="text"
                      disabled
                      value={pickedQuestionaire && pickedQuestionaire.title}
                      required
                    ></Input>
                  </div> */}
                </div>
                <div className="mt-4 col-md-6">
                  <Label className="d-flex">{t("time")}</Label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      className="w-100"
                      margin="normal"
                      id="time-picker"
                      value={selectedTime}
                      onChange={setSelectedTime}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className="row mb-2">
                <div className="mt-4 col-md-6">
                  <Label className="d-flex">{t("starting_day")}</Label>
                  <Input
                    value={inputValues?.startDay}
                    onChange={(e) => {
                      if (
                        (e.target.value < 366 && e.target.value >= 0) ||
                        e.target.value.length == 0
                      ) {
                        setInputValues({
                          ...inputValues,
                          startDay: e.target.value
                            ? parseInt(e.target.value, 10)
                            : null,
                        });
                      }
                    }}
                    name="startDay"
                    min="1"
                    max="365"
                    type="number"
                    required
                  ></Input>
                </div>
                <div className="mt-4 col-md-6">
                  <Label className="d-flex">{t("ending_day")}</Label>
                  <Input
                    value={inputValues?.endDay}
                    onChange={(e) => {
                      if (
                        (e.target.value < 366 && e.target.value >= 0) ||
                        e.target.value.length == 0
                      ) {
                        setInputValues({
                          ...inputValues,
                          endDay: e.target.value
                            ? parseInt(e.target.value, 10)
                            : null,
                        });
                      }
                    }}
                    min="1"
                    max="365"
                    name="endDay"
                    type="number"
                    required
                  ></Input>
                </div>
              </div>
              {inputValues.startDay != null &&
              inputValues.endDay != null &&
              inputValues.intervals != null ? (
                <div className="row justify-content-center mt-4 mb-2 p-2 ">
                  {getDaysBlocks()}
                </div>
              ) : null}
              <div className="row mb-2">
                <div className="mt-4 col-md-6">
                  <Label className="d-flex">{t("interval")}</Label>
                  <Input
                    value={inputValues?.intervals}
                    onChange={(e) => {
                      if (e.target.value >= 0 || e.target.value.length == 0) {
                        setInputValues({
                          ...inputValues,
                          intervals: e.target.value
                            ? parseInt(e.target.value, 10)
                            : null,
                        });
                      }
                    }}
                    min="0"
                    step="1"
                    name="intervals"
                    type="number"
                    required
                  ></Input>
                </div>
                <div className="mt-4 col-md-6">
                  <Label className="d-flex">{t("when")}</Label>
                  <Input
                    type="select"
                    name="when"
                    required
                    defaultValue={questionnaire?.when}
                    value={when.length}
                    onChange={(e) => {
                      handleWhenChange(e.target.value);
                    }}
                  >
                    <option value={1}>Once a day</option>
                    <option value={2}>Twice a day</option>
                    <option value={3}>Thrice a day</option>
                    <option value={4}>4 times a day</option>
                    <option value={5}>5 times a day</option>
                    <option value={6}>6 times a day</option>
                    <option value={7}>7 times a day</option>
                    <option value={8}>8 times a day</option>
                    <option value={9}>9 times a day</option>
                    <option value={10}>10 times a day</option>
                    <option value={11}>11 times a day</option>
                    <option value={12}>12 times a day</option>
                  </Input>
                </div>
              </div>
              <div className="row mb-2">
                <div className="mt-4 col-md-6">
                  <Label className="d-flex">{t("valid_till")}</Label>
                  <Input
                    min="1"
                    max="365"
                    step="1"
                    name="validTill"
                    type="number"
                    value={inputValues.validTill}
                    onChange={(e) => {
                      if (
                        (e.target.value < 366 && e.target.value >= 0) ||
                        e.target.value.length == 0
                      ) {
                        setInputValues({
                          ...inputValues,
                          validTill: e.target.value
                            ? parseInt(e.target.value, 10)
                            : null,
                        });
                      }
                    }}
                    required
                  ></Input>
                </div>
              </div>

              <div className="mt-4 d-flex justify-content-end">
                <Button
                  disabled={engagementReducer.loading}
                  type="submit"
                  className="primary-background-button-table d-flex align-items-center"
                  color="none"
                >
                  <span className="font-weight-bold">{t("update")}</span>
                  {engagementReducer.loading && (
                    <CircularProgress
                      className="ml-2"
                      style={{ height: "20px", width: "20px", color: "white" }}
                    />
                  )}
                </Button>
                <SelectQuestionnaireModal
                  {...props}
                  modal={selectQModalState}
                  onQPick={onQPick}
                  toggle={() => {
                    setSelectQModalState(!selectQModalState);
                  }}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectEngagementQuestionnarie;
