import * as Actions from "../actions";

const initialState = {
  loading: false,
  assignLoading: false,
  stateloading: false,
  patStatsLoading: false,
  otherLoading: false,
  details: null,
  error: null,
  posted: false,
  projectList: null,
  projectCount: null,
  projectPatients: null,
  projectPatientCount: null,
  projectStats: null,
  projectAdditionalStats: null,
  otherStats: null,
  patStats: null,
  projectDoseStats: null,
  doses_taken: null,
  tabContentListCount: 0,
  tabContentList: 0,
  tabContentType: null,
};

const employeeReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.PROJECT_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case Actions.PAT_STATS_LOADING: {
      return {
        ...state,
        patStatsLoading: action.payload,
      };
    }
    case Actions.PROJECT_ASSIGN_LOADING: {
      return {
        ...state,
        assignLoading: action.payload,
      };
    }
    case Actions.PROJECT_STATE_LOADING: {
      return {
        ...state,
        stateloading: action.payload,
      };
    }
    case Actions.PROJECT_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.PROJECT_POSTED: {
      return {
        ...state,
        posted: action.payload,
      };
    }
    case Actions.GET_ALL_PROJECTS: {
      return {
        ...state,
        loading: false,
        error: null,
        projectCount: action.count,
        projectList: action.payload,
      };
    }
    case Actions.GET_PROJECT_PATIENTS: {
      return {
        ...state,
        loading: false,
        error: null,
        projectPatientCount: action.payload.count,
        projectPatients: action.payload.rows,
      };
    }
    case Actions.GET_PROJECT_TAB_DATA: {
      return {
        ...state,
        loading: false,
        stateloading: false,
        tabError: null,
        error: null,
        tabContentList: action.payload.rows,
        tabContentListCount: action.payload.count,
        tabContentType: action.payload.tabContentType,
      };
    }
    case Actions.ENGAGEMENT_TAB_ERROR: {
      return {
        ...state,
        tabError: action.payload,
        stateloading: false,
        loading: false,
        tabContentList: null,
        tabContentList: null,
      };
    }
    case Actions.GET_PROJECT_STATS: {
      let doses_taken = {
        totalCount: 0,
        totalDone: 0,
      };
      if (action.payload) {
        let data = action.payload;
        var totalDone = data.reduce(function (prev, cur) {
          return prev + cur.stats.totalDone;
        }, 0);
        var totalCount = data.reduce(function (prev, cur) {
          return prev + cur.stats.totalCount;
        }, 0);
        doses_taken = {
          totalCount,
          totalDone,
        };
      }
      return {
        ...state,
        loading: false,
        error: null,
        projectStats: action.payload,
        projectDoseStats: doses_taken,
      };
    }
    case Actions.GET_PROJECT_STATS_ADDITIONAL: {
      return {
        ...state,
        projectAdditionalStats: action.payload,
      };
    }
    case Actions.GET_STATS: {
      let doses_taken = {
        totalCount: 0,
        totalDone: 0,
      };
      if (action.payload) {
        let data = action.payload;
        var totalDone = data.reduce(function (prev, cur) {
          return prev + cur.stats.totalDone;
        }, 0);
        var totalCount = data.reduce(function (prev, cur) {
          return prev + cur.stats.totalCount;
        }, 0);
        doses_taken = {
          totalCount,
          totalDone,
        };
      }
      return {
        ...state,
        loading: false,
        error: null,
        otherStats: action.payload,
        otherDoseTaken: doses_taken,
      };
    }
    case Actions.GET_PAT_STATS: {
      return {
        ...state,
        patStats: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
export default employeeReducer;
