import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import * as Actions from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'
import ConfrimDialogue from 'components/Dialogue/ConfrimDialogue'
import { Avatar } from '@material-ui/core'
import ErrorMessageComponents from 'components/ErrorHandlers/ErrorMessageComponents'
import CircularProgressComponent from 'components/ErrorHandlers/CircularProgressComponent'
import { Form, FormGroup, Input, Table } from 'reactstrap'
import TableLoader from 'components/ErrorHandlers/TableLoader'
import Pagination from '@material-ui/lab/Pagination'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import momentt from 'moment-timezone'

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5)
  }
}))

export default function ProductAssignments (props) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false
  })

  const [data, setData] = useState(null)
  const [dialogue, setDialogue] = useState(false)

  const [assignments, setAssignments] = useState([])
  const [assignmentCount, setAssignmentCount] = useState(0)

  const storeReducer = useSelector(({ store }) => store)

  const [variables, setVariables] = useState({ page: 1 })
  const { t } = useTranslation()
  console.log(' storeReducer --->', storeReducer)
  useEffect(() => {
    if (props.match.params.productId) {
      dispatch(Actions.setStoreStateLoading(true))
      dispatch(
        Actions.getStoreProductAssignments(
          variables,
          props.match.params.productId
        )
      )
    }
  }, [props.match.params.productId, variables])

  useEffect(() => {
    if (storeReducer) {
      if (storeReducer && storeReducer.productAssignments) {
        setAssignments(storeReducer.productAssignments)
        setAssignmentCount(storeReducer.productAssignmentCount)
      }
      if (storeReducer.error) {
        setAssignments([])
        setAssignmentCount(0)
      }
    }
  }, [storeReducer])

  const getProductAssignments = () => {
    if (assignments && assignments.length > 0) {
      return assignments.map(item => {
        return (
          <tr>
            <td>{item.project.id}</td>
            <td>
              <div className=' d-flex align-items-center'>
                <Avatar
                  className='mr-3 medicine-pic'
                  src={
                    item.project.imageUrl !== null
                      ? item.project.imageUrl
                      : null
                  }
                />{' '}
                <p className='mb-0 mr-2 prominent'>{item.project.name}</p>
              </div>
            </td>
            <td>{item?.project.region}</td>
            <td>{item?.project.country}</td>

            <td
              className={`cursor-pointer centrify-blue-color   `}
              onClick={() => {
                setData(item)
                setDialogue(true)
              }}
            >
              {t('unassign')}
            </td>
          </tr>
        )
      })
    } else {
      return (
        <tr>
          <td colspan={7} className='text-center font-weight-bold'>
            {t('nothing_to_display')}
          </td>
        </tr>
      )
    }
  }

  const handlePageChange = (e, page) => {
    setVariables({
      ...variables,
      page: page
    })
  }
  const handleUnAssign = () => {
    if (props.match.params.productId && data) {
      dispatch(Actions.setStoreStateLoading(true))
      dispatch(
        Actions.unAssignStoreProductProject(
          data.id,
          props.match.params.productId
        )
      )
      setDialogue(!dialogue)
      setData(null)
    }
  }

  const handleSearch = e => {
    e.preventDefault()
    let search = document.getElementById('project-search').value
    setVariables({
      ...variables,
      name: search,
      page: 1
    })
  }
  const handleChipDelete = () => {
    document.getElementById('project-search').value = ''
    setVariables({
      ...variables,
      name: '',
      page: 1
    })
  }

  if (storeReducer.error) {
    return (
      <ErrorMessageComponents message={t('cannot_fetch_product_assignments')} />
    )
  }

  if (storeReducer === null) {
    return <CircularProgressComponent />
  }

  return (
    <div>
      <div className='mt-4'>
        <div className='rct-block mg-top-40'>
          <div className='d-flex justify-content-between align-items-center border-bottom-centrify p-4'>
            <div className='d-flex flex-column w-100'>
              <div className='mb-2'>
                {variables.name && (
                  <Chip
                    label={variables.name}
                    onDelete={() => handleChipDelete()}
                    className={classes.chip}
                  />
                )}
              </div>
              <div className='d-flex justify-content-between align-items-end table-top-bar '>
                <div className='d-flex align-items-center filters'>
                  <Form
                    className='mt-2 mt-sm-0'
                    onSubmit={e => handleSearch(e)}
                  >
                    <FormGroup className='d-inline-block gift-search-input'>
                      <Input
                        type='text'
                        name='value'
                        id='project-search'
                        maxLength={10}
                        placeholder={t('search')}
                        style={{ paddingRight: '40px' }}
                      />
                      <button type='submit' className='gift-search-icon'>
                        <i className='zmdi zmdi-search centrify-blue-color'></i>
                      </button>
                    </FormGroup>
                  </Form>
                </div>
              </div>

              <div className='mt-4' style={{ position: 'relative' }}>
                {storeReducer.stateLoading && <TableLoader />}
                <Table responsive className='main-table'>
                  <thead className='main-table-header'>
                    <tr>
                      <th>
                        <span className=' mb-0 d-flex'>{t('id')}</span>
                      </th>
                      <th>{t('project')}</th>
                      <th>{t('region')}</th>
                      <th>{t('country')}</th>
                      <th>{t('actions')}</th>
                    </tr>
                  </thead>
                  <tbody className='main-table-body'>
                    {getProductAssignments()}
                  </tbody>
                </Table>
              </div>

              {assignmentCount > 0 ? (
                <div className='mt-4 d-flex justify-content-center'>
                  <Pagination
                    count={Math.ceil(assignmentCount / 10)}
                    page={variables.page}
                    onChange={handlePageChange}
                    variant='outlined'
                    shape='rounded'
                  />
                </div>
              ) : null}

              <ConfrimDialogue
                open={dialogue}
                handleClose={() => setDialogue(false)}
                action={() => handleUnAssign()}
                msg={t('are_you_sure_you_want_to_cancel') + '?'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
