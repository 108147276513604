import Domain from 'lib/Config'
import axios from 'axios'

import History from '@history'
import { NotificationManager } from 'react-notifications'
import moment from 'moment'
import momentt from 'moment-timezone'

export const GET_ALL_QUESTIONAIRES = '[ADMIN] GET_ALL_QUESTIONAIRES'
export const GET_ALL_TREE_QUESTIONNAIRES = '[ADMIN] GET_ALL_TREE_QUESTIONNAIRES'
export const QUESTIONAIRE_LOADING = '[ADMIN] QUESTIONAIRE_LOADING'
export const QUESTIONAIRE_STATE_LOADING = '[ADMIN] QUESTIONAIRE_STATE_LOADING'

export const ASSIGN_QUESTIONAIRE_LOADING = '[ADMIN] ASSIGN_QUESTIONAIRE_LOADING'
export const QUESTIONAIRE_ERROR = '[ADMIN] QUESTIONAIRE_ERROR'
export const QUESTIONAIRE_POSTED = '[ADMIN] QUESTIONAIRE_POSTED'
export const GET_QUESTIONAIRE_BY_ID = '[ADMIN] GET_QUESTIONAIRE_BY_ID'
export const GET_TREE_QUESTIONAIRE_BY_ID =
  '[ADMIN]  GET_TREE_QUESTIONAIRE_BY_ID'
export const GET_QUESTIONAIRE_BY_ID_EDIT = '[ADMIN] GET_QUESTIONAIRE_BY_ID_EDIT'
export const GET_TREE_QUESTIONAIRE_BY_ID_REPORTING =
  '[ADMIN] GET_TREE_QUESTIONAIRE_BY_ID_REPORTING'
export const GET_TREE_QUESTIONAIRE_BY_ID_EDIT =
  '[ADMIN] GET_TREE_QUESTIONAIRE_BY_ID_EDIT'
export const CLEAR_QUESTIONAIRE = '[ADMIN] CLEAR_QUESTIONAIRE'
export const GET_QUESTIONAIRE_PATIENTS = '[ADMIN] GET_QUESTIONAIRE_PATIENTS'
export const GET_TREE_QUESTIONNAIRE_PATIENTS =
  '[ADMIN] GET_TREE_QUESTIONNAIRE_PATIENTS'
export const GET_OPTION_PATIENTS = '[ADMIN] GET_OPTION_PATIENTS'
export const GET_SOLVED_QUESTIONAIRE = '[ADMIN] GET_SOLVED_QUESTIONAIRE'
export const GET_SOLVED_TREE_QUESTIONAIRE =
  '[ADMIN] GET_SOLVED_TREE_QUESTIONAIRE'

export function getAllQuestionaires (data, vendor_id) {
  console.log(data, vendor_id)
  data.page = data.page - 1
  if (data.dateRange && data.dateRange.length > 0) {
    data.dateRange = [
      moment(`${data.dateRange[0]} 00:00:00`)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss'),
      moment(`${data.dateRange[1]} 23:59:00`)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
    ]
  }
  console.log(data)
  const request = axios.post(`${Domain}/api/vendor/getQuestionnaires`, {
    ...data,
    vendor_id,
    limit: 10
  })
  return dispatch =>
    request
      .then(response => {
        console.log(response.data.questionnaire)
        return dispatch({
          type: GET_ALL_QUESTIONAIRES,
          payload: response.data.questionnaire
        })
      })
      .catch(error => {
        setQuestionairesLoading(false)
        console.log(error.response)
        return dispatch({
          type: QUESTIONAIRE_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get questionaires'
        })
      })
}
export function getAllTreeQuestionniares (data, vendor_id, employee_id) {
  console.log(data, vendor_id)
  data.page = data.page - 1

  console.log('Get All Tree Questionnaire called')
  console.log(data)
  const request = axios.post(`${Domain}/api/vendor/getTreeQuestionnaires`, {
    ...data,
    title: data._name,
    vendor_id,
    employee_id,
    limit: 10
  })
  return dispatch =>
    request
      .then(response => {
        console.log(
          'Tree questionnaire response -->',
          response.data.treeQuestionnaire
        )
        return dispatch({
          type: GET_ALL_TREE_QUESTIONNAIRES,
          payload: response.data.treeQuestionnaire
        })
      })
      .catch(error => {
        setTreeQuestionairesLoading(false)
        console.log('Tree questionnaire Error -->', error.response)
        console.log(error.response)
        return dispatch({
          type: QUESTIONAIRE_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get questionaires'
        })
      })
}
export function getOptionPatients (data, option_id) {
  data.page = data.page - 1
  const request = axios.post(`${Domain}/api/vendor/getPatients`, {
    ...data,
    option_id,
    limit: 10
  })
  return dispatch =>
    request
      .then(response => {
        return dispatch({
          type: GET_OPTION_PATIENTS,
          payload: response.data.patients
        })
      })
      .catch(error => {
        console.log(error.response)
        return dispatch({
          type: QUESTIONAIRE_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get patients'
        })
      })
}
export function getQuestionairePatients (data, questionnaire_id) {
  data.page = data.page - 1
  const request = axios.post(`${Domain}/api/vendor/getAllQuestionnairePats`, {
    ...data,
    questionnaire_id,
    limit: 10
  })
  return dispatch =>
    request
      .then(response => {
        console.log(response.data.result)
        return dispatch({
          type: GET_QUESTIONAIRE_PATIENTS,
          payload: response.data.result
        })
      })
      .catch(error => {
        console.log(error.response)
        setQuestionairesLoading(false)
        return dispatch({
          type: QUESTIONAIRE_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get patients'
        })
      })
}
// export function getTreeQuestionnairePatients(data, questionnaire_id) {
//   data.page = data.page - 1;
//   const request = axios.post(`${Domain}/api/vendor/patientTreeQuestionnaires`, {
//     ...data,
//     questionnaire_id,
//     limit: 10,
//   });
//   return (dispatch) =>
//     request
//       .then((response) => {
//         console.log(response.data.result);
//         return dispatch({
//           type: GET_TREE_QUESTIONNAIRE_PATIENTS,
//           payload: response.data.result,
//         });
//       })
//       .catch((error) => {
//         console.log(error.response);
//         setQuestionairesLoading(false);
//         return dispatch({
//           type: QUESTIONAIRE_ERROR,
//           payload:
//             error.response && error.response.data.msg
//               ? error.response.data.msg
//               : "Error! Cannot get patients",
//         });
//       });
// }
export function getTreeQuestionnairePatients (data, questionnaire_id) {
  console.log('Calling Get Tree Questionnaire Patients')
  // data.page = data.page - 1;
  const request = axios.get(`${Domain}/api/vendor/patientTreeQuestionnaires`, {
    params: {
      id: questionnaire_id,
      limit: 10,
      offset: data.page * 10
    }
  })

  console.log('Questionnaire Id ', questionnaire_id)
  console.log('Data --->', data)
  return dispatch =>
    request
      .then(response => {
        console.log('Response --->', response)
        console.log('Get Tree Questionnaire Patients', response.data.result)
        return dispatch({
          type: GET_TREE_QUESTIONNAIRE_PATIENTS,
          payload: response.data.treeQuestionnaires.rows,
          count: response.data.treeQuestionnaires.count
        })
      })
      .catch(error => {
        console.log('Error -->', error)
        console.log('Get Tree Questionnaire Patients Error', error.response)
        setQuestionairesLoading(false)
        return dispatch({
          type: QUESTIONAIRE_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get patients'
        })
      })
}

export function getQuestionaireById (id) {
  const request = axios.get(`${Domain}/api/vendor/getQuestionnaire/${id}`)
  return dispatch =>
    request
      .then(response => {
        console.log(response.data)
        return dispatch({
          type: GET_QUESTIONAIRE_BY_ID,
          payload: response.data.questionnaire
        })
      })
      .catch(error => {
        setQuestionairesLoading(false)
        console.log(error.response)
        return dispatch({
          type: QUESTIONAIRE_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get questionaire'
        })
      })
}
// export function getTreeQuestionaireById(id) {
//   console.log("Get Tree Questionnaire Called ", id);
//   const request = axios.get(
//     `${Domain}/api/vendor/getSingleTreeQuestionnaire/${id}`
//   );
//   return (dispatch) =>
//     request
//       .then((response) => {
//         console.log(response.data);
//         return dispatch({
//           type: GET_TREE_QUESTIONAIRE_BY_ID,
//           payload: response.data.questionnaire,
//         });
//       })
//       .catch((error) => {
//         setQuestionairesLoading(false);
//         console.log(error.response);
//         return dispatch({
//           type: QUESTIONAIRE_ERROR,
//           payload:
//             error.response && error.response.data.msg
//               ? error.response.data.msg
//               : "Error! Cannot get questionaire",
//         });
//       });
// }
export function getQuestionaireByIdOnEdit (id) {
  const request = axios.get(`${Domain}/api/vendor/getQuestionnaire/${id}`)
  return dispatch =>
    request
      .then(response => {
        console.log(response.data)
        return dispatch({
          type: GET_QUESTIONAIRE_BY_ID_EDIT,
          payload: response.data.questionnaire
        })
      })
      .catch(error => {
        setQuestionairesLoading(false)
        console.log(error.response)
        return dispatch({
          type: QUESTIONAIRE_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get questionaire'
        })
      })
}

export function getTreeQuestionaireForReporting (id) {
  console.log('Get Reporing Tree Questionnaire Id ---->', id)
  const request = axios.get(
    `${Domain}/api/vendor/treeQuestionnaireReport?id=${id}`
  )
  return dispatch =>
    request
      .then(response => {
        console.log('Response --->', response)
        return dispatch({
          type: GET_TREE_QUESTIONAIRE_BY_ID_REPORTING,
          payload: response.data.questionnaire
        })
      })
      .catch(error => {
        // setQuestionairesLoading(false);
        console.log('Questionnaire Error ----->', error)
        console.log(error.response)
        return dispatch({
          type: QUESTIONAIRE_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get questionaire detail'
        })
      })
}
export function getSingleTreeQuestionnaire (id) {
  const request = axios.get(
    `${Domain}/api/vendor/getSingleTreeQuestionnaire/${id}`
  )
  return dispatch =>
    request
      .then(response => {
        console.log('Get Single Tree Questionnaire -----', response)
        console.log(response.data)
        return dispatch({
          type: GET_TREE_QUESTIONAIRE_BY_ID,
          payload: response.data.questionnaire
        })
      })
      .catch(error => {
        // setQuestionairesLoading(false);
        console.log('Questionnaire Error ----->', error)
        console.log(error.response)
        return dispatch({
          type: QUESTIONAIRE_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get questionaire detail'
        })
      })
}
export function getPatientSolvedQuestionaire (patId, questionaireId) {
  console.log(
    'Get Patient Solve Questionnaire  @@@@@@@@@@@@',
    patId,
    questionaireId
  )
  const request = axios.get(
    `${Domain}/api/vendor/getSolvedQuestionnaire?questionnaire_id=${questionaireId}&patient_id=${patId}`
  )
  return dispatch =>
    request
      .then(response => {
        console.log('@@@@@@@@@@@@@')
        console.log('Patient Solved --->', response.data)
        return dispatch({
          type: GET_SOLVED_QUESTIONAIRE,
          payload: response.data.questionnaire
        })
      })
      .catch(error => {
        setQuestionairesLoading(false)
        console.log(error.response)
        return dispatch({
          type: QUESTIONAIRE_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get questionaire'
        })
      })
}
export function getPatientSolvedTreeQuestionaire (
  patId,
  solvedTreeQuestionnaireId
) {
  console.log('Get Patient Solved Tree Questionnaire Called')
  console.log('Patient Id --->', patId)
  console.log('solvedTreeQuestionnaireId -->', solvedTreeQuestionnaireId)
  const request = axios.get(`${Domain}/api/vendor/patientSolvedQuestionnaire`, {
    params: {
      patient_id: patId,
      solvedTreeQuestionnaireId: solvedTreeQuestionnaireId
    }
  })
  return dispatch =>
    request
      .then(response => {
        console.log('Response --->', response.data)
        return dispatch({
          type: GET_SOLVED_TREE_QUESTIONAIRE,
          payload: response.data.questionnaire
        })
      })
      .catch(error => {
        setQuestionairesLoading(false)
        console.log(error.response)
        return dispatch({
          type: QUESTIONAIRE_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get questionaire'
        })
      })
}

export function deleteQuestionaire (id, vendor_id) {
  const request = axios.delete(`${Domain}/api/vendor/deleteQuestionnaire/${id}`)
  return dispatch =>
    request
      .then(response => {
        NotificationManager.success('Questionaire Deleted Successfully')
        dispatch(getAllQuestionaires({}, vendor_id))
      })
      .catch(error => {
        console.log(error.response)
        dispatch(setQuestionairesLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Cannot delete questionaire'
        )
      })
}

export function deleteTreeQuestionaire (id, vendor_id, employee_id) {
  const request = axios.delete(
    `${Domain}/api/vendor/deleteTreeQuestionnaire/${id}`
  )
  return dispatch =>
    request
      .then(response => {
        NotificationManager.success('Questionaire Deleted Successfully')
        dispatch(getAllTreeQuestionniares({}, vendor_id, employee_id))
      })
      .catch(error => {
        console.log(error.response)
        dispatch(setQuestionairesLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Cannot delete questionaire'
        )
      })
}

export function addQuestionaire (data, vendor_id) {
  data.vendor_id = vendor_id

  const request = axios.post(`${Domain}/api/vendor/addQuestionnaire`, data)
  return dispatch =>
    request
      .then(response => {
        NotificationManager.success('Questionaire Added Successfully')
        dispatch({
          type: QUESTIONAIRE_POSTED,
          payload: true
        })
        dispatch(getAllQuestionaires({}, vendor_id))
        History.goBack()
      })
      .catch(error => {
        console.log(error.response)
        dispatch(setQuestionairesLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Cannot Add Questionaire'
        )
      })
}
export function addTreeQuestionaire (data, vendor_id, employee_id) {
  data.vendor_id = vendor_id
  data.employee_id = employee_id
  let tz = moment()
    .tz(momentt.tz.guess())
    .format('Z')
  data.creationTime = new Date()
  data.tz = tz
  console.log('Final Tree Q Add Data --->', data)
  const request = axios.post(`${Domain}/api/vendor/treeQuestionnaire`, data)
  return dispatch =>
    request
      .then(response => {
        NotificationManager.success('Questionnaire Added Successfully')
        dispatch({
          type: QUESTIONAIRE_POSTED,
          payload: true
        })
        dispatch(getAllTreeQuestionniares({}, vendor_id))
        History.goBack()
      })
      .catch(error => {
        console.log('Error addding tree questionnarie -->')
        console.log(error.response)
        dispatch(setQuestionairesLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Cannot Add Questionnaire'
        )
      })
}
export function updateTreeQuestionaire (
  data,
  vendor_id,
  employee_id,
  questionId
) {
  data.vendor_id = vendor_id
  data.employee_id = employee_id
  let tz = moment()
    .tz(momentt.tz.guess())
    .format('Z')
  data.creationTime = new Date()
  data.tz = tz
  // data.creationTime = new Date();
  console.log('Final Tree Q Add Data --->', data)
  const request = axios.put(
    `${Domain}/api/vendor/treeQuestionnaire/${questionId}`,
    data
  )
  return dispatch =>
    request
      .then(response => {
        NotificationManager.success('Questionnaire Updated Successfully')
        dispatch({
          type: QUESTIONAIRE_POSTED,
          payload: true
        })
        dispatch(getAllTreeQuestionniares({}, vendor_id))
        History.goBack()
      })
      .catch(error => {
        console.log('Error updating tree questionnarie -->')
        console.log(error.response)
        dispatch(setQuestionairesLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Cannot Update Questionnaire'
        )
      })
}

export function updateQuestionaire (data, quesId, vendor_id) {
  const request = axios.put(
    `${Domain}/api/vendor/updateQuestionnaire/${quesId}`,
    data
  )
  return dispatch =>
    request
      .then(response => {
        NotificationManager.success('Questionaire Updated Successfully')
        dispatch({
          type: QUESTIONAIRE_POSTED,
          payload: true
        })
        dispatch(getAllQuestionaires({}, vendor_id))
        History.goBack()
      })
      .catch(error => {
        console.log(error.response)
        dispatch(setQuestionairesLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Cannot Update Questionaire'
        )
      })
}

// export function assignQuestionaireToPatient(
//   questionnaire_id,
//   patients,
//   vendor_id
// ) {
//   const data = {
//     questionnaire_id,
//     patients,
//   };
//   const request = axios.post(`${Domain}/api/vendor/assignQuestionnaire`, data);
//   return (dispatch) =>
//     request
//       .then((response) => {
//         NotificationManager.success("Questionaire Assigned Successfully");
//         setAssignQuestionairesLoading(false);
//         dispatch(getAllQuestionaires({}, vendor_id));
//       })
//       .catch((error) => {
//         console.log(error.response);
//         dispatch(setQuestionairesLoading(false));
//         NotificationManager.error(
//           error.response && error.response.data && error.response.data.msg
//             ? error.response.data.msg
//             : "Cannot Assign Questionaire"
//         );
//       });
// }

export function assignTreeQuestionaireToPatient (
  TreeQuestionnaire_id,
  patients,
  vendor_id,
  employee_id
) {
  const data = {
    TreeQuestionnaire_id,
    patients,
    date: new Date(),
    validTill: 2
  }
  console.log('Data assign ---<', data)
  const request = axios.post(
    `${Domain}/api/vendor/assignTreeQuestionnaireToMultiplePatients`,
    data
  )
  return dispatch =>
    request
      .then(response => {
        NotificationManager.success('Questionaire Assigned Successfully')
        setAssignQuestionairesLoading(false)
        dispatch(getAllTreeQuestionniares({}, vendor_id, employee_id))
      })
      .catch(error => {
        console.log(error.response)
        dispatch(setQuestionairesLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Cannot Assign Questionaire'
        )
      })
}

export function assignQuestionaireToPatient (
  questionnaire_id,
  patients,
  vendor_id
) {
  const data = {
    questionnaire_id,
    patients
  }
  const request = axios.post(`${Domain}/api/vendor/assignQuestionnaire`, data)
  return dispatch =>
    request
      .then(response => {
        NotificationManager.success('Questionaire Assigned Successfully')
        setAssignQuestionairesLoading(false)
        dispatch(getAllQuestionaires({}, vendor_id))
      })
      .catch(error => {
        console.log(error.response)
        dispatch(setQuestionairesLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Cannot Assign Questionaire'
        )
      })
}

export function setQuestionairesLoading (val) {
  return dispatch => {
    return dispatch({
      type: QUESTIONAIRE_LOADING,
      payload: val
    })
  }
}
export function setTreeQuestionairesLoading (val) {
  return dispatch => {
    return dispatch({
      type: QUESTIONAIRE_LOADING,
      payload: val
    })
  }
}
export function setTreeQuestionairesStateLoading (val) {
  return dispatch => {
    return dispatch({
      type: QUESTIONAIRE_STATE_LOADING,
      payload: val
    })
  }
}

export function clearQuestionaire () {
  return dispatch => {
    return dispatch({
      type: CLEAR_QUESTIONAIRE,
      payload: true
    })
  }
}

export function setAssignQuestionairesLoading (val) {
  return dispatch => {
    return dispatch({
      type: ASSIGN_QUESTIONAIRE_LOADING,
      payload: val
    })
  }
}
