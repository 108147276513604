import React, { useState, useEffect } from 'react'
import { Avatar } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import * as Actions from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'

import ErrorMessageComponents from 'components/ErrorHandlers/ErrorMessageComponents'
import CircularProgressComponent from 'components/ErrorHandlers/CircularProgressComponent'

import ImageGallery from 'react-image-gallery'

const ProductDetail = props => {
  const dispatch = useDispatch()
  const [images, setImages] = useState(null)
  const [productDetail, setProductDetail] = useState(null)
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false
  })
  const storeReducer = useSelector(({ store }) => store)

  //   const classes = useStyles()
  const { t } = useTranslation()

  useEffect(() => {
    if (props.productId) {
      let project_id = props.productId
      dispatch(Actions.setStoreLoading(true))
      dispatch(Actions.getStoreProduct(project_id))
    }
  }, [props.productId])

  useEffect(() => {
    if (storeReducer) {
      if (storeReducer && storeReducer.productDetail) {
        setProductDetail(storeReducer.productDetail)
        if (storeReducer.productDetail.productImages) {
          let tempArray = []
          storeReducer.productDetail.productImages.map(img => {
            tempArray.push({
              original: img.image,
              thumbnail: img.image
            })
          })
          setImages(tempArray)
        }
      }
      if (storeReducer.error) {
        setProductDetail(null)
      }
    }
  }, [storeReducer])

  if (storeReducer && storeReducer.error) {
    return <ErrorMessageComponents message={t('cannot_fetch_product_detail')} />
  }

  if (storeReducer === null || storeReducer.loading) {
    return <CircularProgressComponent />
  }
  console.log('images --->', images)
  return (
    <div>
      <div className=' d-flex'>
        <div className=' ' style={{ width: '50%' }}>
          <ImageGallery
            items={images || []}
            lazyLoad={true}
            showPlayButton={false}
            showFullscreenButton={false}
          />
        </div>
        <div className=' p-4 ' style={{ width: '50%' }}>
          <h1 className='my-2 font-2dot5rem  '>{productDetail?.title}</h1>
          <h3 className='my-2'>{productDetail?.unit}</h3>
          <h1 className='my-4 font-1dot5rem '>{productDetail?.price}</h1>
          <div className='my-4'>
            <h2 className='mb-3 font-1dot5rem pb-2 border-2  border-dark border-bottom  d-inline-block'>
              Description
            </h2>
            <p className='font-1rem'>{productDetail?.description}</p>
          </div>
          <div className='my-4'>
            <h2 className='mb-3 font-1dot5rem pb-2 border-2 border-dark border-bottom d-inline-block'>
              Product Web Store Link
            </h2>
            <a href={productDetail?.link} className='font-1rem'>
              {productDetail?.link}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductDetail
