import DoughnutChart from "components/Charts/DoughnutChart";
import React from "react";
import { useTranslation } from "react-i18next";
import BarChart from "./barChart";

export default function Graphs(props) {
  const { t } = useTranslation();
  return (
    <div className="graph-section">
      <div className="row">
        <div className="col-md-6 mt-4">
          <h1 className="d-flex">{t("medication_actual_vs_planned")}</h1>
          <BarChart data={props.data} />
        </div>
        <div className="col-md-6 mt-4">
          <div className="pl-4">
            <h1 className="d-flex">{t("medication_type_taken")}</h1>
            <DoughnutChart data={props.data} />
          </div>
        </div>
      </div>
    </div>
  );
}
