import React from "react";
import { useTranslation } from "react-i18next";
import StatsPieChart from "../pieChart";
import StatsBarChart from "./statsBarChart";
import QuestionComp from "./questionComponent";

export default function Questionaires(props) {
    const { questionaire } = props;
    const { t } = useTranslation();

    return (
        <div>
            <div className="mt-4">
                {/* <h1 className="form-heading font-weight-bold d-flex">{t("questionnaire_details")}</h1> */}
                <div className="rct-block mg-top-40">
                    <div className="d-flex justify-content-between align-items-center border-bottom-centrify p-4">
                        <div className='d-flex flex-column w-100'>
                            <h2 className="font-weight-bold">{questionaire.title}</h2>
                            <p className='mt-5'>{questionaire.shortDescription}</p>
                        </div>
                    </div>
                </div>
                {questionaire.questions.length > 0 && <>
                    {questionaire.questions.map((ques, index) => (<>
                        {ques.questionType === 'TEXT' ? null
                            :
                            <div className="rct-block mt-5">
                                <div className="row d-flex justify-content-between p-4">
                                    <QuestionComp question={ques} index={index} />
                                    <div className="col-md-12 col-lg-4 d-flex flex-column mt-4 graph-section">
                                        {ques.questionType === 'MCQ' ?
                                            <StatsPieChart options={ques.options} />
                                            : <StatsBarChart options={ques.options} />
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                    ))}
                </>}
            </div >
        </div >
    );
}
