import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Form, FormGroup, Input, Table } from "reactstrap";
import { AiFillEye } from "react-icons/ai";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Pagination from "@material-ui/lab/Pagination";
import ConfrimDialogue from "components/Dialogue/ConfrimDialogue";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import TableLoader from "components/ErrorHandlers/TableLoader";
import { useDispatch, useSelector } from "react-redux";
import qs from "query-string";
import moment from "moment";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import { useTranslation } from "react-i18next";
import * as Actions from "store/actions";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function Medicines(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [variables, setVariables] = useState({ page: 1 });
  let [searchValue, setSearchValue] = useState(null);
  const [questionaire, setQuestionaire] = useState(null);
  const { t } = useTranslation();

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  const questionaireReducer = useSelector(({ questionaire }) => questionaire);

  useEffect(() => {
    let vendor_id = Auth.vendor_id;
    let employee_id = Auth.id;
    dispatch(Actions.setQuestionairesLoading(true));
    dispatch(
      Actions.getAllTreeQuestionniares(variables, vendor_id, employee_id)
    );
  }, [variables]);

  useEffect(() => {
    setQuestionaire(questionaireReducer);
  }, [questionaireReducer]);

  const getQuestionaireList =
    questionaire &&
    questionaire.treeQuestionnaireList &&
    questionaire.treeQuestionnaireList.length > 0 ? (
      questionaire.treeQuestionnaireList.map((item) => {
        return (
          <tr>
            <td>{item?.id}</td>
            <td>{item?.title}</td>
            <td
              className="cursor-pointer"
              onClick={() => {
                props.toggle();
                props.onQPick(item);
              }}
            >
              Pick
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colspan={7} className="text-center font-weight-bold">
          {t("nothing_to_display")}
        </td>
      </tr>
    );
  const handlePageChange = (e, page) => {
    setVariables({
      ...variables,
      page: page,
    });
  };
  const handleChipDelete = () => {
    document.getElementById("questionaire-search").value = "";
    setVariables({
      ...variables,
      _name: "",
      page: 1,
    });
  };

  const handleSearch = (e) => {
    // e.preventDefault();
    // let search = document.getElementById("questionaire-search").value;
    if (searchValue) {
      console.log("Search value --->", searchValue);
      setVariables({
        ...variables,
        _name: searchValue,
        page: 1,
      });
    }
  };
  if (questionaire && questionaire.error) {
    return (
      <div className="text-center">
        <h2>Something went wrong !</h2>
      </div>
    );
    // return <ErrorMessageComponents message="Cannot Fetch Questionnaires" />;
  }

  if (questionaire === null || !questionaire.treeQuestionnaireList) {
    return <CircularProgressComponent />;
  }

  return (
    <div>
      <div className="mt-4">
        {variables._name && (
          <Chip
            label={variables._name}
            onDelete={() => handleChipDelete()}
            className={classes.chip}
          />
        )}
        <div className="d-flex justify-content-between align-items-end table-top-bar">
          <div className="d-flex align-items-center filters">
            {/* <Form className="mt-2 mt-sm-0" onSubmit={(e) => handleSearch(e)}> */}
            <FormGroup className="d-inline-block gift-search-input">
              <Input
                type="text"
                name="value"
                id="questionaire-search"
                maxLength={10}
                placeholder={t("search")}
                style={{ paddingRight: "40px" }}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
              <button
                type="button"
                onClick={handleSearch}
                className="gift-search-icon"
              >
                <i className="zmdi zmdi-search centrify-blue-color"></i>
              </button>
            </FormGroup>
            {/* </Form> */}
          </div>
        </div>

        <div className="mt-4" style={{ position: "relative" }}>
          {questionaire.loading && <TableLoader />}
          <Table responsive className="main-table">
            <thead className="main-table-header">
              <tr>
                <th>{t("id")}</th>
                <th>
                  <span className="ml-4 mb-0 d-flex">{t("title")}</span>
                </th>
                <th onClick>{t("pick")}</th>
              </tr>
            </thead>
            <tbody className="main-table-body">{getQuestionaireList}</tbody>
          </Table>
        </div>

        {questionaire.treeQCount > 0 ? (
          <div className="mt-4 d-flex justify-content-center">
            <Pagination
              count={Math.ceil(questionaire.treeQCount / 10)}
              page={variables.page + 1}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
