import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";

const data = {
  labels: ["Aspirin", "b10", "Iron"],
  datasets: [
    {
      data: [100, 30, 40],
      backgroundColor: ["#FFB70F", "#5D92F4", "#00D0BD"],
      hoverBackgroundColor: ["#FFB70F", "#5D92F4", "#00D0BD"],
    },
  ],
};

const options = {
  legend: {
    display: true,
    labels: { fontColor: "#AAAEB3" },
    position: "left",
  },
  cutoutPercentage: 50,
};

export default class DoughnutChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      options: null,
    };
  }

  componentDidMount() {
    let _data = this.props.data;
    let labels = [];
    let dataset = [];
    if (_data && _data.length > 0) {
      _data.map((item) => {
        labels.push(item.name);
        dataset.push(item.stats.totalDone);
      });
    }
    this.setState({
      data: {
        labels: labels,
        datasets: [
          {
            data: dataset,
            backgroundColor: ["#FFB70F", "#5D92F4", "#00D0BD"],
            hoverBackgroundColor: ["#FFB70F", "#5D92F4", "#00D0BD"],
          },
        ],
      },
    });
  }

  render() {
    return <Doughnut data={this.state.data} options={options} height={100} />;
  }
}
