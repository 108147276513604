// local
// const Domain = "http://192.168.100.104:3000";
// development
// const Domain = "https://www.centrefy.biz";
//production
const Domain = "https://www.centrefy.me";
// function view_stack() {
//   if ((number = 10)) return;
//   console.log("this interval is  called");
// }

// setInterval(view_stack, 1000);

export default Domain;
