import { Avatar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Form, FormGroup, Input, Table } from "reactstrap";
import Pagination from "@material-ui/lab/Pagination";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import TableLoader from "components/ErrorHandlers/TableLoader";
import { useDispatch, useSelector } from "react-redux";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import { useTranslation } from "react-i18next";
import * as Actions from "store/actions";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function MedicineFilterModal(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [variables, setVariables] = useState({ page: 1 });
  const [medicine, setMedicine] = useState(null);
  const { t } = useTranslation();

  console.log(variables);
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  const medicineReducer = useSelector(({ medicine }) => medicine);

  useEffect(() => {
    let vendor_id = Auth.vendor_id;
    dispatch(Actions.setMedicineLoading(true));
    dispatch(Actions.getAllMedicines(variables, vendor_id));
  }, [variables]);

  useEffect(() => {
    setMedicine(medicineReducer);
  }, [medicineReducer]);

  if (medicine && medicine.error) {
    return <ErrorMessageComponents message="Cannot Fetch Employees" />;
  }

  if (medicine === null || !medicine.medicineList) {
    return <CircularProgressComponent />;
  }

  const getMedicineList =
    medicine.medicineList && medicine.medicineList.length > 0 ? (
      medicine.medicineList.map((item) => {
        return (
          <tr>
            <td>{item.id}</td>
            <td>
              <div className="ml-4 d-flex align-items-center">
                <Avatar
                  className="mr-3 medicine-pic"
                  src={
                    item.medicineimages.length > 0
                      ? item.medicineimages[0].image
                      : ""
                  }
                />{" "}
                <p className="mb-0 prominent">{item.name}</p>
              </div>
            </td>
            <td onClick={(e) => props.itemPicked(item.id, item.name)}>
              <a className="centrify-blue-color">Pick</a>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colspan={4} className="text-center font-weight-bold">
          {t("nothing_to_display")}
        </td>
      </tr>
    );
  const handlePageChange = (e, page) => {
    setVariables({
      ...variables,
      page: page,
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let search = document.getElementById("medicine-modal-search").value;
    setVariables({
      ...variables,
      _name: search,
      page: 1,
    });
  };
  const handleChipDelete = () => {
    document.getElementById("medicine-modal-search").value = "";
    setVariables({
      ...variables,
      _name: "",
      page: 1,
    });
  };
  return (
    <div>
      <div className="mt-4">
        {variables._name && (
          <Chip
            label={variables._name}
            onDelete={() => handleChipDelete()}
            className={classes.chip}
          />
        )}
        <div className="d-flex justify-content-between filters align-items-end table-top-bar">
          <div className="d-flex align-items-center">
            <Form className="mt-2 mt-sm-0" onSubmit={(e) => handleSearch(e)}>
              <FormGroup className="d-inline-block gift-search-input">
                <Input
                  type="text"
                  name="value"
                  id="medicine-modal-search"
                  maxLength={10}
                  placeholder="search query..."
                />
                <button type="submit" className="gift-search-icon">
                  <i className="zmdi zmdi-search centrify-blue-color"></i>
                </button>
              </FormGroup>
            </Form>
          </div>
          <a
            className="centrify-blue-color filter-text"
            onClick={(e) => props.pickAll()}
          >
            Pick All
          </a>
        </div>
        <div className="mt-4" style={{ position: "relative" }}>
          {medicine.loading && <TableLoader />}
          <Table responsive className="main-table">
            <thead className="main-table-header">
              <tr>
                <th>{t("id")}</th>
                <th>
                  <span className="ml-4 mb-0 d-flex">{t("name")}</span>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody className="main-table-body">{getMedicineList}</tbody>
          </Table>
        </div>
        {medicine.medicineCount > 0 ? (
          <div className="mt-4 d-flex justify-content-center">
            <Pagination
              count={Math.ceil(medicine.medicineCount / 10)}
              page={variables.page}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
