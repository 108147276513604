import History from '@history'
import React from 'react'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'

const BreadCrumbComponent = props => {
  return (
    <div>
      <Breadcrumb>
        {props.previous &&
          props.previous.map((item, index) => {
            return (
              <BreadcrumbItem key={item.name + '-' + index}>
                <a onClick={e => History.push(item.link)}>{item.name}</a>
              </BreadcrumbItem>
            )
          })}
        {props.active ? (
          <BreadcrumbItem active>{props.active}</BreadcrumbItem>
        ) : null}
      </Breadcrumb>
    </div>
  )
}

export default BreadCrumbComponent
