import React, { useState, useEffect } from "react";
import clsx from "clsx";
import moment from "moment";
import "./calendarStyles.css";

function RBCToolbar(props) {
  const { label, date, view, views, onView, onNavigate } = props;
  let [currentStartDate, setCurrentStartDate] = useState();
  let [currentEndDate, setCurrentEndDate] = useState();
  const [month, setMonth] = useState("January");
  const mMonth = moment(date).format("MMMM");
  const months = moment.months();
  // console.log("Props ---> Toolbar Component --->", props);
  useEffect(() => {
    setMonth(mMonth);
  }, [mMonth]);
  // console.log("month --->", month);
  // console.log("date -->", date);
  const onChange = (event) => {
    // console.log("OnChange Called ---@@@");
    const current = event.target.value;
    console.log("current --->", current);
    onNavigate("DATE", moment().month(current).toDate());
    setMonth(current);
  };

  const goToView = (view) => {
    onView(view);
  };

  useEffect(() => {
    let prev = moment(date, "DD-MM-YYYY").add("days", 6);
    setCurrentStartDate(date);
    setCurrentEndDate(prev);
  }, [date]);

  const goToBack = () => {
    let previousCheck = moment(currentStartDate).isAfter(
      props.dateIntervals.start,
      "day"
    );
    console.log("date --->", date);
    console.log("starting date  <-_->", props.dateIntervals.start);
    console.log("Previous check <-_->", previousCheck);
    if (previousCheck) {
      onNavigate("PREV");
    }
  };
  const goToNext = () => {
    let previousCheck = moment(currentEndDate).isBefore(
      props.dateIntervals.end,
      "day"
    );
    console.log("currentEndDate --->", currentEndDate);
    console.log("ending date --->", props.dateIntervals.end);
    console.log("Previous check <--->", previousCheck);
    if (previousCheck) {
      onNavigate("NEXT");
    }
  };

  const goToToday = () => {
    onNavigate("TODAY");
  };
  // console.log("currentStartDate --->", currentStartDate);
  return (
    <div className="rbc-toolbar  justify-content-end">
      <div className="rbc-btn-group">
        {/* <button onClick={goToToday}>Today Button</button> */}
        <button
          className={`${
            moment(currentStartDate).isAfter(props.dateIntervals?.start, "day")
              ? "active_switch"
              : "disabled_switch"
          }`}
          onClick={goToBack}
        >
          Back
        </button>
        <button
          className={`${
            moment(currentEndDate).isBefore(props.dateIntervals?.end, "day")
              ? "active_switch"
              : "disabled_switch"
          }`}
          onClick={goToNext}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default RBCToolbar;
