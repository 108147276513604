import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "store/actions";
import QuestionnaireFilterModal from "./engQuestionnaireFilterModal";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";

const ProjectModal = (props) => {
  const { t } = useTranslation();

  return (
    <div>
      <Modal isOpen={props.modal} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle}>
          {t("pick_questionnaire")}
        </ModalHeader>
        <ModalBody>
          <QuestionnaireFilterModal
            onQPick={props.onQPick}
            engagementId={props.engagementId}
            toggle={props.toggle}
            {...props}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ProjectModal;
