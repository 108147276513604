import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Actions from 'store/actions'
import ProjectFilterModal from './ProjectFilterModal'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useTranslation } from 'react-i18next'

const ProjectModal = props => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false
  })

  const pickItem = (id, name) => {
    props.itemPicked(id, name)
  }
  const pickAll = () => {
    props.allPicked()
  }

  const handleAssignToProject = () => {
    if (props.selectedProjects) {
      console.log('Selected Projects --->', props.selectedProjects)
      console.log('Props.product --->', props.product)
      dispatch(Actions.setStoreStateLoading(true))
      dispatch(
        Actions.assignStoreProductToProject(
          props.selectedProjects,
          props.product,
          Auth.vendor_id,
          closeModal
        )
      )
    }
  }

  let closeModal = () => {
    dispatch(Actions.setEngagementAssignLoading(false))
    props.toggle()
  }
  return (
    <div>
      <Modal isOpen={props.modal} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>{t('pick_project')}</ModalHeader>
        <ModalBody>
          <ProjectFilterModal
            selectedProjects={props.selectedProjects}
            setSelectedProjects={props.setSelectedProjects}
            handleAssignToProject={handleAssignToProject}
            pickItem={pickItem}
            pickAll={pickAll}
            engagementId={props.engagementId}
            {...props}
            statsScreen={true}
          />
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={closeModal}>
            {t('cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default ProjectModal
