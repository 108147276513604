import Domain from "lib/Config";
import axios from "axios";
import moment from "moment";
import { NotificationManager } from "react-notifications";

export const GET_ALL_ORDERS = "[ADMIN] GET_ALL_ORDERS";
export const GET_ALL_STORE_ORDERS = "[ADMIN] GET_ALL_STORE_ORDERS";
export const APPROVE_ORDER = "[ADMIN] APPROVE_ORDER";
export const ORDER_LOADING = "[ADMIN] ORDER_LOADING";
export const ORDER_POSTED = "[ADMIN] ORDER_POSTED";
export const ORDER_ERROR = "[ADMIN] ORDER_ERROR";
export const ORDER_APPROVE_LOADING = "[ADMIN] ORDER_APPROVE_LOADING";

export function getAllOrders(data, vendor_id, patient_id) {
  data.page = data?.page - 1;
  if (data?.dateRange && data?.dateRange?.length > 0) {
    data.dateRange = [
      moment(`${data.dateRange[0]} 00:00:00`)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss"),
      moment(`${data?.dateRange[1]} 23:59:00`)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss"),
    ];
  } else {
    delete data?.dateRange;
  }

  const request = axios.post(`${Domain}/api/vendor/orders`, {
    ...data,
    vendor_id,
    patient_id,
    limit: 10,
  });
  return (dispatch) =>
    request
      .then((response) => {
        console.log(response.data.orders);
        return dispatch({
          type: GET_ALL_ORDERS,
          payload: response.data.orders,
        });
      })
      .catch((error) => {
        setOrderLoading(false);
        console.log(error.response);
        return dispatch({
          type: ORDER_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get orders",
        });
      });
}

export function getAllStoreOrders(data, vendor_id) {
  console.log(data, vendor_id);
  let page = 0;
  if (data.page) {
    data.page = data.page - 1;
  } else {
    data.page = page;
  }

  let date = [];
  if (data.dateRange && data.dateRange.length > 0) {
    data.dateRange = JSON.stringify([
      moment(`${data.dateRange[0]} 00:00:00`).format("YYYY-MM-DD HH:mm:ss"),
      moment(`${data.dateRange[1]} 23:59:00`).format("YYYY-MM-DD HH:mm:ss"),
    ]);
  } else {
    delete data.dateRange;
  }
  console.log("data --->", data);
  const request = axios.get(`${Domain}/api/vendor/getBuyProducts`, {
    params: {
      ...data,
      vendor_id,
      // patient_id,
      limit: 10,
    },
  });
  return (dispatch) =>
    request
      .then((response) => {
        console.log("Get All Store Orders @@@@ ---->", response.data);
        console.log(response.data.products);
        return dispatch({
          type: GET_ALL_STORE_ORDERS,
          payload: response.data.products,
        });
      })
      .catch((error) => {
        setOrderLoading(false);
        console.log("Get all store orders error ---->", error);
        console.log(error.response);
        return dispatch({
          type: ORDER_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get orders",
        });
      });
}

export function updateOrderStatus(
  status,
  order_id,
  vendor_id,
  variables,
  patient_id
) {
  // console.log(status, order_id, vendor_id);
  const request = axios.put(`${Domain}/api/vendor/orderStatus/${order_id}`, {
    status,
  });
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success("Status Updated Successfully");
        dispatch({
          type: ORDER_POSTED,
          payload: true,
        });
        if (patient_id === undefined) {
          console.log(patient_id);
          dispatch(getAllOrders(variables, vendor_id));
        } else {
          dispatch(getAllOrders(variables, vendor_id, patient_id));
        }
      })
      .catch((error) => {
        console.log(error.response);
        dispatch(setOrderLoading(false));
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : "Cannot Update Status"
        );
      });
}

export function updateOrderStoreStatus(status, order_id, vendor_id, variables) {
  // console.log(status, order_id, vendor_id);
  const request = axios.put(
    `${Domain}/api/vendor/acknowledgeOrder/${order_id}`,
    {
      status,
    }
  );
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success("Status Updated Successfully");
        dispatch({
          type: ORDER_POSTED,
          payload: true,
        });
        dispatch(getAllStoreOrders(variables, vendor_id));
      })
      .catch((error) => {
        console.log(error.response);
        dispatch(setOrderLoading(false));
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : "Cannot Update Status"
        );
      });
}

export function setOrderLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: ORDER_LOADING,
      payload: val,
    });
  };
}

export function setOrderApproveLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: ORDER_APPROVE_LOADING,
      payload: val,
    });
  };
}
