import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useDispatch } from "react-redux";
import * as Actions from "store/actions";
import { AiOutlinePlayCircle, AiFillFile } from "react-icons/ai";
import { MdError, MdOutlineDownloadForOffline } from "react-icons/md";
import ConfrimDialogue from "components/Dialogue/ConfrimDialogue";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./mediaDetail.css";

const ViewMediaDetail = (props) => {
  const dispatch = useDispatch();
  const { item, open, toggle, patient_id } = props;
  const { t } = useTranslation();
  const [dialogue, setDialogue] = useState(false);

  const handleDelete = () => {
    dispatch(Actions.setPatientDocumentLoading(true));
    dispatch(Actions.deleteDocument(item.id, patient_id));
    setDialogue(!dialogue);
    toggle();
  };

  const handleClose = () => {
    setDialogue(!dialogue);
  };
  let getVideo = (item) => {
    let tempParsed = JSON.parse(item.temp);
    return (
      <div className="img-div-lg m-auto ">
        <div className="chat_video_container">
          <img
            src={tempParsed?.thumbnail}
            alt="Avatar"
            className="video_thumbnail_media_detail"
            style={{ height: "90%", width: "100%", maxWidth: "100%" }}
          />
          <div class="video_download_overlay">
            <a href={item?.url} download>
              <MdOutlineDownloadForOffline
                className="video_download_icon primary-color"
                style={{ fontSize: "36px" }}
              />
            </a>
          </div>
        </div>
      </div>
    );
  };
  console.log("Item --->", item);
  return (
    <div>
      <Modal isOpen={open} toggle={toggle} className="modal-background">
        <ModalHeader toggle={toggle}>{t("media_detail")}</ModalHeader>
        <ModalBody>
          <div className="row d-flex justify-content-center documentation-modal">
            <div className="col-md-12">
              <div className="text-capitalize w-100 mb-1">
                <div className="d-flex flex-wrap justify-content-between">
                  <p>{t("project")}</p>
                  <p className="font-weight-bold">
                    {" "}
                    {item?.patientTreeQuestionnaire?.project?.name}
                  </p>
                </div>
                <div className="d-flex flex-wrap justify-content-between">
                  <p>{t("engagement")}</p>
                  <p className="font-weight-bold">
                    {item?.patientTreeQuestionnaire?.engagement?.title}
                  </p>
                </div>
                <div className="d-flex flex-wrap justify-content-between">
                  <p>{t("questionnaire")}</p>
                  <p className="font-weight-bold">
                    {item?.treeQuestionniare?.title}
                  </p>
                </div>
                {/* <div className="d-flex flex-wrap justify-content-between">
                  <p>{t("status")}</p>
                  <p className="font-weight-bold">
                    {item?.patientTreeQuestionnaire?.status}
                  </p>
                </div> */}
                <div className="d-flex flex-wrap justify-content-between">
                  <p>{t("question_title")}</p>
                  <p className="font-weight-bold">
                    {item?.treeQuestion?.title}
                  </p>
                </div>
                <div className="d-flex flex-wrap justify-content-between">
                  <p>{t("date")}</p>
                  <p className="font-weight-bold">{item?.date}</p>
                </div>
              </div>

              <div className="row m-0 pd-top-40 w-100 m-auto text-center">
                {item?.type == "IMAGE" ? (
                  <div className=" img-div-lg m-auto">
                    <img
                      className="w-100 cursor-pointer"
                      src={item?.url}
                      alt=""
                      onClick={(e) => window.open(item?.url)}
                      style={{ height: "90%", width: "100%", maxWidth: "100%" }}
                    />
                  </div>
                ) : item?.type == "VIDEO" ? (
                  getVideo(item)
                ) : null}
              </div>

              {/* <div className="d-flex justify-content-end mg-top-40">
                <Button
                  onClick={() => setDialogue(!dialogue)}
                  color="none"
                  className="primary-background-button-table font-weight-bold"
                >
                  {" "}
                  {t("delete_media")}
                </Button>
              </div> */}
            </div>
          </div>
        </ModalBody>
      </Modal>

      <ConfrimDialogue
        open={dialogue}
        handleClose={() => handleClose()}
        action={() => handleDelete()}
        msg={t("are_you_sure_you_want_to_delete") + "?"}
      />
    </div>
  );
};

export default ViewMediaDetail;
