import Domain from "lib/Config";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import * as Actions from "store/actions";

export const GET_ALL_PATIENTS = "[ADMIN] GET_ALL_PATIENTS";
export const GET_PATIENT_INVENTORY = "[ADMIN] GET_PATIENT_INVENTORY";
export const GET_PATIENT_ENGAGEMENT = "[ADMIN] GET_PATIENT_ENGAGEMENT";
export const GET_PATIENT_PROJECT = "[ADMIN] GET_PATIENT_PROJECT";
export const GET_ALL_DOCUMENTATIONS = "[ADMIN] GET_ALL_DOCUMENTATIONS";
export const PATIENT_PROFILE = "[ADMIN] PATIENT_PROFILE";
export const GET_PATIENT_MEDIA = "[ADMIN] GET_PATIENT_MEDIA";
export const PATIENT_POSTED = "[ADMIN] PATIENT_POSTED";
export const DOCUMENTATION_POSTED = "[ADMIN] DOCUMENTATION_POSTED";
export const PATIENT_DOCUMENT_LOADING = "[ADMIN] PATIENT_DOCUMENT_LOADING";
export const PATIENT_PLACE_ORDER_LOADING =
  "[ADMIN] PATIENT_PLACE_ORDER_LOADING";
export const PATIENT_LOADING = "[ADMIN] PATIENT_LOADING";
export const PATIENT_REPORT_LOADING = "[ADMIN] PATIENT_REPORT_LOADING";
export const PATIENT_STATUS_LOADING = "[ADMIN] PATIENT_STATUS_LOADING";
export const PATIENT_MISSED_NOTI_STATUS_LOADING =
  "[ADMIN] PATIENT_MISSED_NOTI_STATUS_LOADING";
export const PATIENT_ERROR = "[ADMIN] PATIENT_ERROR";
export const GET_PATIENT_MEDICINE = "[ADMIN] GET_PATIENT_MEDICINE";

export function getAllPatients(data, vendor_id) {
  console.log("Get All Patients data--->", data);
  let page = 0;
  if (data.page) {
    page = data.page - 1;
  }

  const request = axios.post(`${Domain}/api/vendor/getAllVendorpats`, {
    ...data,
    page,
    vendor_id,
    limit: 10,
  });
  return (dispatch) =>
    request
      .then((response) => {
        console.log(response.data.result);
        return dispatch({
          type: GET_ALL_PATIENTS,
          payload: response.data.result,
        });
      })
      .catch((error) => {
        console.log(error.response);
        return dispatch({
          type: PATIENT_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get patients",
        });
      });
}

export function getPatientInventory(patient_id) {
  const request = axios.post(`${Domain}/api/vendor/getPatientinventory`, {
    patient_id,
  });
  return (dispatch) =>
    request
      .then((response) => {
        console.log(response.data.result);
        return dispatch({
          type: GET_PATIENT_INVENTORY,
          payload: response.data.result,
        });
      })
      .catch((error) => {
        console.log(error.response);
        return dispatch({
          type: PATIENT_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get inventory",
        });
      });
}

// GET_PATIENT_INVENTORY

export function getMedicineForOrder(data) {
  console.log(data);
  const request = axios.post(`${Domain}/api/vendor/medicineForOrder`, data);
  return (dispatch) =>
    request
      .then((response) => {
        console.log(response.data.result);
        return dispatch({
          type: GET_PATIENT_MEDICINE,
          payload: response.data.result,
        });
      })
      .catch((error) => {
        console.log(error.response);
        return dispatch({
          type: PATIENT_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get inventory",
        });
      });
}

export function getPatientEngagements(patient_id, data) {
  let page = 0;
  if (data && data.page) {
    page = data.page - 1;
  }
  const request = axios.get(`${Domain}/api/vendor/paitentEngagements`, {
    params: {
      ...data,
      title: data?._name,
      patient_id,
      limit: 10,
      page: page,
    },
  });
  return (dispatch) =>
    request
      .then((response) => {
        console.log("Get Patient Engagements Response -------->", response);
        return dispatch({
          type: GET_PATIENT_ENGAGEMENT,
          payload: response.data.patientEngagements,
        });
      })
      .catch((error) => {
        console.log(error.response);
        return dispatch({
          type: PATIENT_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get engagement",
        });
      });
}
export function getPatientProjects(patient_id, data) {
  let page = 0;
  if (data && data.page) {
    page = data.page - 1;
  }
  const request = axios.get(`${Domain}/api/vendor/patientProjects`, {
    params: {
      ...data,
      title: data?._name,
      patient_id,
      limit: 10,
      page: page,
      status: "active",
    },
  });
  return (dispatch) =>
    request
      .then((response) => {
        console.log("Get Patient Projects Response -------->", response);
        return dispatch({
          type: GET_PATIENT_PROJECT,
          payload: response.data.projects,
        });
      })
      .catch((error) => {
        console.log(error.response);
        return dispatch({
          type: PATIENT_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get engagement",
        });
      });
}
export function getPatientMedia(patient_id, data) {
  let page = 0;
  if (data && data.page) {
    page = data.page - 1;
  }
  console.log("Get Patient media ----------", data);
  const request = axios.get(`${Domain}/api/vendor/gallery`, {
    params: {
      ...data,
      title: data?._name,
      patient_id,
      limit: 12,
      offset: page == 0 ? 0 : page * 12,
    },
  });
  return (dispatch) =>
    request
      .then((response) => {
        console.log("Get Patient Media @@@@@ Response -------->", response);
        return dispatch({
          type: GET_PATIENT_MEDIA,
          payload: response.data.gallery,
        });
      })
      .catch((error) => {
        console.log(error.response);
        return dispatch({
          type: PATIENT_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get gallery media",
        });
      });
}

export function cancelPatientEngagement(patientEngagement_id, patient_id) {
  console.log("patientEngagement_id -->", patientEngagement_id);
  console.log("patient_id -->", patient_id);
  const request = axios.post(`${Domain}/api/vendor/cancelPatientEngagement`, {
    patientEngagement_id,
  });
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success("Engagement cancelled successfully");

        dispatch(getPatientEngagements(patient_id, {}));
      })
      .catch((error) => {
        console.log("Error Cancel ENGAMGENT --->", error.response);
        dispatch(setPatientDocumentLoading(false));
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : "Unable to cancel engagement"
        );
      });
}

export function addPatient(data) {
  const request = axios.post(`${Domain}/api/vendor/addPatient`, data);
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success("Patient Added Successfully");
        dispatch({
          type: PATIENT_POSTED,
          payload: true,
        });
        dispatch(getAllPatients({ page: 1 }, data.vendor_id));
      })
      .catch((error) => {
        console.log(error.response);
        dispatch(setPatientLoading(false));
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : "Cannot Add Patient"
        );
      });
}

export function updatePatientStatus(id, status, vendor_id) {
  const request = axios.put(`${Domain}/api/patient/updateStatus/${id}`, {
    status,
  });
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success("Status Updated Successfully");
        dispatch(getPatientProfile(id));
      })
      .catch((error) => {
        console.log(error.response);
        dispatch(setPatientStatusLoading(false));
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : "Cannot Update Status"
        );
      });
}

export function updatePatientMissedNotiStatus(id, status, vendor_id) {
  const request = axios.put(`${Domain}/api/patient/updatepatientProfile`, {
    missedActivityNotification: status,
    id,
  });
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success("Status Updated Successfully");
        dispatch(getPatientProfile(id));
      })
      .catch((error) => {
        console.log(error.response);
        dispatch(setPatientMissedNotiStatusLoading(false));
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : "Cannot Update Status"
        );
      });
}

export function addDocumentation(data) {
  const request = axios.post(`${Domain}/api/vendor/addDocumentation`, data);
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success("Documentation Added Successfully");
        dispatch({
          type: DOCUMENTATION_POSTED,
          payload: true,
        });
        dispatch(getAllDocumentations({}, data.patient_id));
      })
      .catch((error) => {
        console.log("Error --->", error);
        console.log("Error --->", error.response);
        dispatch(setPatientLoading(false));
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : "Cannot Add Documentation"
        );
      });
}
export function getAllDocumentations(data, patient_id) {
  console.log(data);
  data.page = data.page - 1;
  const request = axios.post(
    `${Domain}/api/vendor/getDocumentations?patient_id=${patient_id}&page=${
      data.page
    }&limit=${10}`
  );
  return (dispatch) =>
    request
      .then((response) => {
        console.log(response.data);
        return dispatch({
          type: GET_ALL_DOCUMENTATIONS,
          payload: response.data.docs,
        });
      })
      .catch((error) => {
        console.log(error.response);
        return dispatch({
          type: PATIENT_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get documentations",
        });
      });
}

export function deleteDocument(id, patient_id) {
  const request = axios.delete(
    `${Domain}/api/vendor/deleteDocumentation/${id}`,
    {}
  );
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success("Document Deleted Successfully");
        dispatch(getAllDocumentations({}, patient_id));
      })
      .catch((error) => {
        console.log(error.response);
        dispatch(setPatientDocumentLoading(false));
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : "Cannot Delete Document"
        );
      });
}

export function addPatientReport(data) {
  const request = axios.post(`${Domain}/api/admin/addReports`, data);
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success("Report Sent Successfully");
        dispatch(setPatientReportLoading(false));
      })
      .catch((error) => {
        console.log(error.response);
        dispatch(setPatientReportLoading(false));
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : "Cannot Sent Report"
        );
      });
}

export function assignProjectToPatient(data, variables) {
  console.log("Assign Project To Patient ------>", data);
  const request = axios.post(`${Domain}/api/vendor/addPatienttoproject`, data);
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success("Project Assigned Successfully");
        dispatch({
          type: PATIENT_POSTED,
          payload: true,
        });
        dispatch(getAllPatients(variables, data.vendor_id));
      })
      .catch((error) => {
        console.log(error.response);
        dispatch(setPatientLoading(false));
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : "Cannot Add Patient"
        );
      });
}

export function assignMedicineToPatient(data) {
  const request = axios.post(
    `${Domain}/api/vendor/assignMedicinetopatient`,
    data
  );
  return (dispatch) =>
    request
      .then((response) => {
        dispatch(setPatientLoading(false));
        NotificationManager.success("Medicine Assigned Successfully");
      })
      .catch((error) => {
        dispatch(setPatientLoading(false));
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : "Cannot Assign Medicine"
        );
      });
}

export function getPatientProfile(id) {
  const request = axios.get(`${Domain}/api/patient/patientProfile?id=${id}`);
  return (dispatch) =>
    request
      .then((response) => {
        return dispatch({
          type: PATIENT_PROFILE,
          payload: response.data.result,
        });
      })
      .catch((error) => {
        console.log(error.response);
        return dispatch({
          type: PATIENT_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get patient",
        });
      });
}
export function updatePatientProfile(data) {
  const request = axios.put(`${Domain}/api/patient/updatepatientProfile`, data);
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success("Profile Updated Successfully");
        dispatch(getPatientProfile(data.id));
      })
      .catch((error) => {
        console.log(error.response);
        NotificationManager.error("Cannot Update Profile");
        return dispatch({
          type: PATIENT_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot Update Profile",
        });
      });
}

export function placeMediOrderByAdmin(
  data,
  variables,
  vendor_id,
  patient_id,
  toggle
) {
  let dateData = {
    dateRange: [],
    page: variables?.page + 1,
  };

  console.log(dateData, "medicine datedata");
  const request = axios.post(`${Domain}/api/vendor/medicineOrder`, data);
  return (dispatch) =>
    request
      .then((response) => {
        setPlaceOrderLoading(false);
        NotificationManager.success("Ordered Successfully");
        dispatch(Actions.getAllOrders(dateData, vendor_id, patient_id));
        toggle(false);
      })
      .catch((error) => {
        console.log(error.response);
        setPlaceOrderLoading(false);
        NotificationManager.error("Cannot Process Order");
      });
}

export function updatePatientImage(data) {
  console.log(data);
  const request = axios.put(`${Domain}/api/patient/updatepatientProfile`, data);
  return (dispatch) =>
    request
      .then((response) => {
        console.log(response);
        // NotificationManager.success("Image Updated Successfully");
      })
      .catch((error) => {
        console.log(error.response);
        NotificationManager.error("Cannot Update Image");
      });
}

export function setPatientLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: PATIENT_LOADING,
      payload: val,
    });
  };
}

export function setPatientDocumentLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: PATIENT_DOCUMENT_LOADING,
      payload: val,
    });
  };
}

export function setPatientStatusLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: PATIENT_STATUS_LOADING,
      payload: val,
    });
  };
}

export function setPatientMissedNotiStatusLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: PATIENT_MISSED_NOTI_STATUS_LOADING,
      payload: val,
    });
  };
}

export function setPatientReportLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: PATIENT_REPORT_LOADING,
      payload: val,
    });
  };
}

export function setPlaceOrderLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: PATIENT_PLACE_ORDER_LOADING,
      payload: val,
    });
  };
}
