import * as Actions from '../actions'

const initialState = {
  loading: false,
  stateLoading: false,
  error: null,
  product: {},
  productAssignments: null,
  productAssignmentCount: null,
  category: {}
}

const storeReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.STORE_LOADING: {
      return {
        ...state,
        loading: action.payload
      }
    }
    case Actions.STORE_STATE_LOADING: {
      console.log('State loading reducer --->', action)
      return {
        ...state,
        stateLoading: action.payload
      }
    }
    case Actions.STORE_PRODUCT_POSTED: {
      return {
        ...state,
        product: { ...state.product, posted: action.payload }
      }
    }
    case Actions.STORE_CATEGORY_POSTED: {
      return {
        ...state,
        category: { ...state.category, posted: action.payload }
      }
    }
    case Actions.GET_STORE_PRODUCTS: {
      console.log('Get Store Products @@@@ reducer --', action)
      return {
        ...state,
        product: { ...action.payload, posted: false },
        loading: false,
        stateLoading: false,
        error: false
      }
    }
    case Actions.GET_STORE_PRODUCT_ASSIGNMENTS: {
      return {
        ...state,
        productAssignments: action.payload.rows,
        productAssignmentCount: action.payload.count,
        stateLoading: false,
        error: false
      }
    }
    case Actions.GET_STORE_PRODUCT: {
      return {
        ...state,
        productDetail: action.payload,
        loading: false
      }
    }
    case Actions.GET_STORE_CATEGORIES: {
      return {
        ...state,
        category: { ...state.category, ...action.payload, posted: null },
        loading: false
      }
    }
    case Actions.STORE_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false,
        stateLoading: false
      }
    }

    default: {
      return state
    }
  }
}
export default storeReducer
