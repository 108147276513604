import React, { useEffect, useState } from "react";
import { Button, Table } from "reactstrap";
import Pagination from "@material-ui/lab/Pagination";
import { Avatar } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import qs from "query-string";
import * as Actions from "store/actions";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import History from "@history";
import moment from "moment";
import TableLoader from "components/ErrorHandlers/TableLoader";
import ConfrimDialogue from "components/Dialogue/ConfrimDialogue";

export default function Engagements(props) {
  const dispatch = useDispatch();
  let querystr = qs.parse(props.location.search);
  let page = querystr.page ? JSON.parse(querystr.page) : 1;
  const [variables, setVariables] = useState({ ...querystr });
  const [data, setData] = useState(null);
  const [projects, setProjects] = useState(null);
  const [projectCount, setProjectCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [dialogue, setDialogue] = useState(false);
  const { t } = useTranslation();

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  const patientReducer = useSelector(({ patient }) => patient);
  console.log("patientReducer --->", patientReducer);
  console.log("projects ===>", projects);
  useEffect(() => {
    const q_str = qs.stringify(variables);
    History.push({ search: q_str });
    let patient_id = props.match.params.patientId;
    console.log("variables --->", variables);
    dispatch(Actions.setPatientDocumentLoading(true));
    dispatch(Actions.getPatientProjects(patient_id, variables));
  }, [variables]);

  useEffect(() => {
    if (patientReducer && patientReducer.patientProject) {
      setProjects(patientReducer.patientProject);
      setProjectCount(patientReducer.patientProjectCount);
    }
  }, [patientReducer]);
  if (patientReducer && patientReducer.error) {
    return <ErrorMessageComponents message="Cannot Fetch Engagment" />;
  }

  if (patientReducer === null || !patientReducer.patientProject) {
    return <CircularProgressComponent />;
  }

  const getProjectList = () => {
    if (projects && projects.length > 0) {
      return projects.map((item) => {
        return (
          <tr>
            <td>
              <div className="ml-4 d-flex align-items-center cursor-pointer">
                <p className="mb-0 prominent mr-4">
                  {item?.patientproject?.name}
                </p>
              </div>
            </td>
            <td>{item?.patientproject?.country}</td>
            <td>{item?.patientproject?.region}</td>
            <td>{item?.patientproject?.type}</td>
            <td>{item?.quantity}</td>

            <td>
              {moment
                .utc(item?.createdAt)
                .local()
                .format("h:mm a,  MMM DD, YYYY")}
            </td>
            <td>
              {item.cancelDate
                ? moment
                    .utc(item?.cancelDate)
                    .local()
                    .format("h:mm a,  MMM DD, YYYY")
                : null}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan={7} className="text-center font-weight-bold">
            {t("nothing_to_display")}
          </td>
        </tr>
      );
    }
  };
  const handleCancel = () => {
    if (props.match.params.patientId && data) {
      console.log(
        "props.match.params.patientId --->",
        props.match.params.patientId
      );
      console.log("Data --->", data);
      dispatch(Actions.setPatientDocumentLoading(true));
      dispatch(
        Actions.cancelPatientEngagement(data.id, props.match.params.patientId)
      );

      setDialogue(!dialogue);
      setData(null);
    }
  };

  const handlePageChange = (e, page) => {
    setVariables({
      ...variables,
      page: page,
    });
  };
  return (
    <div className="patient-profile">
      <div className="mt-4" style={{ position: "relative" }}>
        {patientReducer.docLoading && <TableLoader />}
        <Table responsive className="main-table">
          <thead className="main-table-header">
            <tr>
              <th>
                <span className="ml-4 mb-0 d-flex">{t("name")}</span>
              </th>
              <th>{t("country")}</th>
              <th>{t("region")}</th>
              <th>{t("type")}</th>
              <th>{t("patients")}</th>
              <th>{t("assigned_date")}</th>
              <th>{t("cancelled_date")}</th>

              <th></th>
            </tr>
          </thead>
          <tbody className="main-table-body">{getProjectList()}</tbody>
        </Table>
      </div>
      {projectCount > 0 && (
        <div className="mt-4 d-flex justify-content-center">
          <Pagination
            count={Math.ceil(projectCount / 10)}
            onChange={(e, pg) => handlePageChange(e, pg)}
            variant="outlined"
            shape="rounded"
          />
        </div>
      )}
      <ConfrimDialogue
        open={dialogue}
        handleClose={() => setDialogue(false)}
        action={() => handleCancel()}
        msg="Are you sure you want to cancel engagement?"
      />
    </div>
  );
}
