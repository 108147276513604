import React from "react";
import { useDispatch } from "react-redux";
import * as Actions from "store/actions";
import PatientFilterModal from "./PatientFilterModal";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";

const PatientModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const pickAll = () => {
    props.allPicked();
  };

  const handleAssignToPatient = () => {
    if (props.selectedPatients && props.projectId) {
      let obj = {
        patients: props.selectedPatients,
        project_id: props.projectId,
      };
      dispatch(Actions.setProjectAssignLoading(true));
      dispatch(Actions.assignPatientToProject(props.projectId, obj));
    }
  };
  let closeModal = () => {
    dispatch(Actions.setEngagementAssignLoading(false));
    props.toggle();
  };
  return (
    <div>
      <Modal isOpen={props.modal} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>{t("assign_patient")}</ModalHeader>
        <ModalBody>
          <PatientFilterModal
            selectedPatients={props.selectedPatients}
            setSelectedPatients={props.setSelectedPatients}
            handleAssignToPatient={handleAssignToPatient}
            pickAll={pickAll}
            {...props}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeModal}>
            {t("cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PatientModal;
