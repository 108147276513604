import * as Actions from "../actions";

const initialState = {
  loading: false,
  details: null,
  error: null,
};

const companyReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.COMPANY_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case Actions.COMPANY_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.COMPANY_DETAILS: {
      return {
        ...state,
        loading: false,
        error: null,
        details: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
export default companyReducer;
