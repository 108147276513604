import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import LinearProgress from "@material-ui/core/LinearProgress";
import * as AuthAction from "auth/store/actions";
import history from "@history";
import "../loginpage/loginStyle.css";
import "animate.css";
import { Button, Input, Label } from "reactstrap";

function ResetPassword() {
    const dispatch = useDispatch();
    const loading = useSelector(({ auth }) => auth.login.loading);
    const [newPasswordd, setNewPasswordd] = useState("");
    const [passError, setPassError] = useState(false);
    const Auth = useSelector(({ auth }) => {
        return auth.login.success ? auth.user : false;
    });
    let resetToken = new URLSearchParams(history.location.search).get("emToken");
    let email = decodeURIComponent(
        new URLSearchParams(history.location.search).get("e")
    );

    useEffect(() => {
        if (Auth) {
            history.push({
                pathname: "/profile",
            });
        }
    }, [Auth]);

    const handleConfirmPassword = (value) => {
        if (value !== newPasswordd) {
            setPassError(true);
        } else {
            setPassError(false);
        }
    }

    const onSubmitLogin = (e) => {
        e.preventDefault();
        let data = {
            email,
            password: e.target.password.value,
            password2: e.target.password2.value,
            resetToken,
        };
        dispatch(AuthAction.setForgetLoading(true));
        dispatch(AuthAction.forgetPassword(data));
    };
    return (
        <div>
            <div className="login-page">
                {loading && <LinearProgress />}
                <div className="row  h-100 p-4">
                    <div className="col-md-6 justify-content-center d-flex align-items-center">
                        <div>
                            <h1 className="signup-black text-uppercase font-weight-bold text-center">
                                Reset Password
              </h1>
                            <div
                                className="mt-4 w-25 mx-auto"
                                style={{ border: "1px solid rgba(157, 169, 206, 0.5)" }}
                            ></div>
                            <div className="mt-4">
                                <form onSubmit={(e) => onSubmitLogin(e)}>
                                    <div className="mt-4">
                                        <Label>Password</Label>
                                        <Input
                                            type="password"
                                            value={newPasswordd} onChange={(e) => setNewPasswordd(e.target.value)}
                                            required
                                            name="password"
                                            className="p-2"
                                        />
                                    </div>
                                    <div className="mt-4">
                                        <Label>Confirm Password</Label>
                                        <Input
                                            type="password"
                                            onChange={(e) => handleConfirmPassword(e.target.value)}
                                            required
                                            name="password2"
                                            className="p-2"
                                        />
                                        {passError && <small className='text-danger'>Passwords do not match</small>}
                                    </div>
                                    <div className="mt-4">
                                        <Button
                                            type="submit"
                                            color="none"
                                            className="primary-background-button w-100 font-weight-bold"
                                        >
                                            Reset Password
                    </Button>
                                    </div>
                                    <div className="mt-2 text-right">
                                        <Link to={`/login`} className="clink-button">
                                            Back To Login
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ResetPassword;
