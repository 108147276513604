import React, { useEffect, useState } from "react";
import { Form, FormGroup, Input, Table, Button } from "reactstrap";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Pagination from "@material-ui/lab/Pagination";
import ConfrimDialogue from "components/Dialogue/ConfrimDialogue";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import TableLoader from "components/ErrorHandlers/TableLoader";
import { useDispatch, useSelector } from "react-redux";
import qs from "query-string";
import { useTranslation } from "react-i18next";
import * as Actions from "store/actions";
import ChatQuestionModal from "./chatQuestionModal";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function ChatQuestion(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  let querystr = qs.parse(props.location.search);
  let page = querystr.page ? JSON.parse(querystr.page) : 1;
  const [variables, setVariables] = useState({ ...querystr });
  const [data, setData] = useState(null);
  const [chatQModalType, setChatQModalType] = useState("add");
  const [chatQModalState, setChatQModalState] = useState(false);

  const [chatQuestions, setChatQuestions] = useState(null);
  const [chatQuestionsCount, setChatQuestionsCount] = useState(0);

  const [dialogue, setDialogue] = useState(false);
  const { t } = useTranslation();

  const stringTruncate = (str, length) => {
    const dots = str.length > length ? "..." : "";
    return `${str.substring(0, length)}${dots}`;
  };

  const projectReducer = useSelector(({ project }) => project);

  useEffect(() => {
    if (props.match.params.id) {
      dispatch(Actions.setProjectStateLoading(true));
      dispatch(
        Actions.getProjectChatQuestions(props.match.params.id, variables)
      );
    }
  }, [props.match.params.tab, variables]);

  useEffect(() => {
    if (projectReducer) {
      if (projectReducer.posted) {
        setChatQModalState(false);
      }
      if (
        projectReducer &&
        projectReducer.tabContentList &&
        projectReducer.tabContentType == "chatQuestions"
      ) {
        setChatQuestions(projectReducer.tabContentList);
        setChatQuestionsCount(projectReducer.tabContentListCount);
      }
      if (projectReducer.error) {
        setChatQuestions([]);
        setChatQuestionsCount(0);
      }
    }
  }, [projectReducer]);

  const getChatQList = () => {
    if (chatQuestions && chatQuestions.length > 0) {
      return chatQuestions.map((item) => {
        return (
          <tr>
            <td>
              <div className="ml-4 d-flex align-items-center cursor-pointer">
                <p className="mb-0 mr-4">
                  {stringTruncate(item?.question, 20)}
                </p>
              </div>
            </td>
            <td> {stringTruncate(item?.answer, 20)}</td>

            <td>
              <div className="d-flex justify-content-start  ">
                <VisibilityIcon
                  className="centrify-grey-color cursor-pointer mr-4"
                  onClick={() => {
                    setChatQModalType("view");
                    setData(item);
                    setChatQModalState(true);
                  }}
                />

                <EditIcon
                  onClick={() => {
                    setChatQModalType("update");
                    setData(item);
                    setChatQModalState(true);
                  }}
                  className="centrify-grey-color cursor-pointer mr-4"
                />
                <DeleteIcon
                  onClick={() => {
                    setData(item);
                    setDialogue(!dialogue);
                  }}
                  className="centrify-grey-color cursor-pointer mr-2"
                />
              </div>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan={7} className="text-center font-weight-bold">
            {t("nothing_to_display")}
          </td>
        </tr>
      );
    }
  };

  const handlePageChange = (e, page) => {
    setVariables({
      ...variables,
      page: page,
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let search = document.getElementById("chatQuestion-search").value;
    setVariables({
      ...variables,
      _name: search,
      page: 1,
    });
  };
  const handleChipDelete = () => {
    document.getElementById("chatQuestion-search").value = "";
    setVariables({
      ...variables,
      _name: "",
      page: 1,
    });
  };

  const handleDelete = () => {
    if (props.match.params.id && data) {
      dispatch(Actions.setProjectStateLoading(true));
      dispatch(
        Actions.deleteProjectChatQuestion(data.id, props.match.params.id)
      );
      setDialogue(!dialogue);
      setData(null);
    }
  };
  if (projectReducer && projectReducer.error) {
    return <ErrorMessageComponents message={t("cannot_get_chat_questions")} />;
  }

  return (
    <div>
      <div className="mt-4">
        <div className="d-flex justify-content-end">
          <Button
            color="none"
            className="primary-background-button-table font-weight-bold"
            onClick={() => {
              setChatQModalType("add");
              setData(null);
              setChatQModalState(true);
            }}
          >
            {t("add_question")}
          </Button>
        </div>
        <div className="rct-block mg-top-40">
          <div className="d-flex justify-content-between align-items-center border-bottom-centrify p-4">
            <div className="d-flex flex-column w-100">
              <div className="mb-2">
                {variables._name && (
                  <Chip
                    label={variables._name}
                    onDelete={() => handleChipDelete()}
                    className={classes.chip}
                  />
                )}
              </div>
              <div className="d-flex justify-content-between align-items-end table-top-bar ">
                <div className="d-flex align-items-center filters">
                  <Form
                    className="mt-2 mt-sm-0"
                    onSubmit={(e) => handleSearch(e)}
                  >
                    <FormGroup className="d-inline-block gift-search-input">
                      <Input
                        type="text"
                        name="value"
                        id="chatQuestion-search"
                        maxLength={10}
                        placeholder={t("search")}
                        style={{ paddingRight: "40px" }}
                      />
                      <button type="submit" className="gift-search-icon">
                        <i className="zmdi zmdi-search centrify-blue-color"></i>
                      </button>
                    </FormGroup>
                  </Form>
                </div>
              </div>

              <div className="mt-4" style={{ position: "relative" }}>
                {projectReducer.stateloading && <TableLoader />}
                <Table responsive className="main-table">
                  <thead className="main-table-header ">
                    <tr>
                      <th>
                        <span className="ml-4 mb-0 d-flex">
                          {t("questions")}
                        </span>
                      </th>
                      <th>{t("answers")}</th>
                      <th>{t("actions")}</th>
                    </tr>
                  </thead>
                  <tbody className="main-table-body">{getChatQList()}</tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
        {chatQuestionsCount > 0 ? (
          <div className="mt-4 d-flex justify-content-center">
            <Pagination
              count={Math.ceil(chatQuestionsCount / 10)}
              page={page}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </div>
        ) : null}
      </div>

      <ConfrimDialogue
        open={dialogue}
        handleClose={() => setDialogue(false)}
        action={() => handleDelete()}
        msg={t("are_you_sure_you_want_to_delete") + "?"}
      />
      <ChatQuestionModal
        {...props}
        projectId={props.match.params.id}
        modal={chatQModalState}
        chatQModalType={chatQModalType}
        chatQuestion={data}
        toggle={() => {
          setChatQModalState(!chatQModalState);
        }}
      />
    </div>
  );
}
