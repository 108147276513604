import React, { useState, useCallback, useEffect } from 'react'
import { Button, Input, Label } from 'reactstrap'
import FileDrop from 'react-dropzone'
import { useSelector, useDispatch } from 'react-redux'
import * as Actions from 'store/actions'
import { Avatar, CircularProgress, LinearProgress } from '@material-ui/core'
import ErrorMessageComponents from 'components/ErrorHandlers/ErrorMessageComponents'
import CircularProgressComponent from 'components/ErrorHandlers/CircularProgressComponent'
import { useTranslation } from 'react-i18next'
import Domain from 'lib/Config'
import axios from 'axios'

const profileCdn = axios.create({})

export default function Profile () {
  const dispatch = useDispatch()
  const [image, setImage] = useState(null)
  const [imageLoading, setImageLoading] = useState(false)
  const [company, setCompany] = useState(null)
  const { t } = useTranslation()

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false
  })
  const companyReducer = useSelector(({ company }) => company)
  const employeeReducer = useSelector(({ employee }) => employee)

  const onDrop = useCallback(
    img => {
      setImageLoading(true)
      handleFileUpload(img)
    },
    [image]
  )
  let getSignedURL = (key, type) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Domain}/api/vendor/s3getsignedurl?key=${key}&type=${type}`)
        .then(data => {
          console.log('Get Signed Url --------', data)
          resolve(data)
        })
        .catch(err => {
          console.log('Get Sisnged Url Error--------', err)
          console.log(err.response)
          reject(err)
        })
    })
  }
  let handleFileUpload = data => {
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )

        return percentCompleted
      },
      header: {
        'Content-Type': data[0].type
      }
    }
    getSignedURL(data[0].name, data[0].type).then(rsp => {
      const request = profileCdn
        .put(rsp.data.result.postUrl, data[0], config)
        .then(res => {
          console.log('Image Upload success ----', res)
          setImage(rsp.data.result.getUrl)
          setImageLoading(false)
        })
        .catch(err => {
          console.log('Image Upload fail ----', err)
          console.log(err.response)
          setImageLoading(false)
        })
    })
  }

  useEffect(() => {
    if (Auth) {
      setImage(Auth.image)
    }
  }, [Auth])

  useEffect(() => {
    dispatch(Actions.setCompanyLoading(true))
    dispatch(Actions.getCompanyProfile(Auth.id))
  }, [])

  useEffect(() => {
    setCompany(companyReducer)
  }, [companyReducer])

  if (company && company.error) {
    return <ErrorMessageComponents message={t('cannot_fetch_details')} />
  }

  if (
    company === null ||
    !company.details ||
    company.loading ||
    employeeReducer.loading ||
    Auth.loading
  ) {
    return <CircularProgressComponent />
  }

  const handleSubmit = e => {
    e.preventDefault()
    let data = {
      id: company.details.id,
      firstName: e.target.firstName.value,
      lastName: e.target.lastName.value,
      phone: e.target.phone.value,
      country: e.target.country.value,
      image: image
    }
    dispatch(Actions.setEmployeeLoading(true))
    dispatch(Actions.updateProfile(data))
  }

  const handlePasswordChange = e => {
    e.preventDefault()
    let data = {
      id: company.details.id,
      oldPassword: e.target.oldPassword.value,
      password: e.target.password.value,
      password2: e.target.password2.value
    }

    dispatch(Actions.setEmployeeLoading(true))
    dispatch(Actions.updatePassword(data))
  }

  return (
    <div>
      <div className='container form-styles patient-profile'>
        <div>
          <h2 className='form-heading d-flex'>{t('profile_infomration')}</h2>
          <div className='mt-4'>
            <FileDrop
              onDrop={onDrop}
              accept='image/png, image/gif, image/jpeg, image/bmp'
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  className='d-flex  profile-container cursor-pointer m-0'
                >
                  {imageLoading ? (
                    <div className='d-flex justify-content-center align-items-center w-100'>
                      <CircularProgress />
                    </div>
                  ) : (
                    <Avatar className='profile-pic-patient' src={image} />
                  )}
                  <img
                    className='add-icon centrify-icons'
                    src={require('assets/icons/add-photo.png')}
                  />
                  <input {...getInputProps()} />
                </div>
              )}
            </FileDrop>
          </div>
          <form onSubmit={e => handleSubmit(e)}>
            <div className='row'>
              <div className='col-md-6 mt-4'>
                <Label className='d-flex'>{t('first_name')}</Label>
                <Input
                  type='text'
                  name='firstName'
                  defaultValue={company.details.firstName}
                />
              </div>
              <div className='col-md-6 mt-4'>
                <Label className='d-flex'>{t('last_name')}</Label>
                <Input
                  type='text'
                  name='lastName'
                  defaultValue={company.details.lastName}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6 mt-4'>
                <Label className='d-flex'>{t('email')}</Label>
                <Input
                  disabled
                  type='text'
                  name='email'
                  defaultValue={company.details.email}
                />
              </div>
              <div className='col-md-6 mt-4'>
                <Label className='d-flex'>{t('country')}</Label>
                <Input
                  type='text'
                  name='country'
                  defaultValue={company.details.country}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6 mt-4'>
                <Label className='d-flex'>{t('phone_number')}</Label>
                <Input
                  type='text'
                  name='phone'
                  defaultValue={company.details.phone}
                />
              </div>
            </div>
            <div className='mt-4'>
              <Button type='submit' className='form-btn' color='none'>
                {t('submit')}
              </Button>
            </div>
          </form>
        </div>
        <div className='mt-4'>
          <h2 className='form-heading d-flex'>{t('change_password')}</h2>
          <form onSubmit={handlePasswordChange}>
            <div className='row'>
              <div className='col-md-6 mt-4'>
                <Label>{t('old_password')}</Label>
                <Input type='password' name='oldPassword' />
              </div>
              <div className='col-md-6 mt-4'>
                <Label>{t('new_password')}</Label>
                <Input type='password' name='password' />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6 mt-4'>
                <Label>{t('confirm_password')}</Label>
                <Input type='password' name='password2' />
              </div>
            </div>
            <div className='mt-4'>
              <Button className='form-btn' color='none' type='submit'>
                {t('submit')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
