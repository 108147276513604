import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "store/actions";
import PatientFilterModal from "./PatientFilterModal";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";

const PatientModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });

  const pickItem = (id, name) => {
    props.itemPicked(id, name);
  };
  const pickAll = () => {
    props.allPicked();
  };

  const handleAssignToPatient = (patient_id, project_id) => {
    // View Type is required as assignToPatient action is used in Engagement Table and inside engagement detail.
    // So based on the screen different data is required once action is completed, getting all engagment in case of engagement table and getting all assigned patient in case of patient tab in detail view.

    if (patient_id && project_id) {
      let viewType = "general";
      if (props.viewType) {
        viewType = "detail_view";
      }
      dispatch(Actions.setEngagementAssignLoading(true));
      dispatch(
        Actions.assignEngagementToPatient(
          props.engagementId,
          patient_id,
          project_id,
          Auth.id,
          viewType,
          Auth.vendor_id
        )
      );
    }
  };
  let closeModal = () => {
    dispatch(Actions.setEngagementAssignLoading(false));
    props.toggle();
  };
  return (
    <div>
      <Modal isOpen={props.modal} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>
          {props.patientModalType == "patient"
            ? t("pick_patient")
            : t("pick_project")}
        </ModalHeader>
        <ModalBody>
          <PatientFilterModal
            setPatientModalType={props.setPatientModalType}
            patientModalType={props.patientModalType}
            handleAssignToPatient={handleAssignToPatient}
            pickItem={pickItem}
            pickAll={pickAll}
            questionnaireId={props.questionnaireId}
            {...props}
            statsScreen={true}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeModal}>
            {t("cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PatientModal;
