import { Avatar, LinearProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Input, Table } from "reactstrap";
import Pagination from "@material-ui/lab/Pagination";
import ConfrimDialogue from "components/Dialogue/ConfrimDialogue";
import History from "@history";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import TableLoader from "components/ErrorHandlers/TableLoader";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import * as Actions from "store/actions";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function ProjectFilterModal(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [variables, setVariables] = useState({ page: 1 });
  console.log("Variables ------>", variables);
  const [projects, setProjects] = useState(null);
  const projectReducer = useSelector(({ project }) => project);
  const { t } = useTranslation();
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });

  useEffect(() => {
    let vendor_id = Auth.vendor_id;
    dispatch(Actions.setProjectLoading(true));
    dispatch(Actions.getAllProjects(variables, vendor_id));
  }, [dispatch, variables]);

  useEffect(() => {
    setProjects(projectReducer);
  }, [projectReducer]);

  if (projects && projects.error) {
    return <ErrorMessageComponents message="Cannot Fetch Projects" />;
  }

  if (projects === null || !projects.projectList) {
    return <CircularProgressComponent />;
  }
  console.log("projects.projectList --->", projects.projectList);
  const getProjectList =
    projects.projectList && projects.projectList.length > 0 ? (
      projects.projectList.map((item) => {
        return (
          <tr>
            <td>{item.id}</td>
            <td>
              <div className="ml-4 d-flex align-items-center">
                <Avatar
                  className="mr-3 medicine-pic"
                  src={item.imageUrl ? item.imageUrl : null}
                />{" "}
                <p className="mb-0 mr-2 prominent">{item.name}</p>
              </div>
            </td>
            <td onClick={(e) => props.pickItem(item.id, item.name)}>
              <a className="centrify-blue-color">Pick</a>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colspan={6} className="text-center font-weight-bold">
          {t("nothing_to_display")}
        </td>
      </tr>
    );

  const handlePageChange = (e, page) => {
    setVariables({
      ...variables,
      page: page,
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let search = document.getElementById("project-modal-search").value;
    setVariables({
      ...variables,
      _name: search,
      page: 1,
    });
  };
  const handleChipDelete = () => {
    document.getElementById("project-modal-search").value = "";
    setVariables({
      ...variables,
      _name: "",
      page: 1,
    });
  };

  return (
    <div>
      <div className="mt-4">
        {variables._name && (
          <Chip
            label={variables._name}
            onDelete={() => handleChipDelete()}
            className={classes.chip}
          />
        )}
        <div className="d-flex justify-content-between filters align-items-end table-top-bar">
          <div className="d-flex align-items-center ">
            <Form className="mt-2 mt-sm-0" onSubmit={(e) => handleSearch(e)}>
              <FormGroup className="d-inline-block gift-search-input">
                <Input
                  type="text"
                  name="value"
                  id="project-modal-search"
                  maxLength={10}
                  placeholder="Search"
                />
                <button type="submit" className="gift-search-icon">
                  <i className="zmdi zmdi-search centrify-blue-color"></i>
                </button>
              </FormGroup>
            </Form>
          </div>
          <a
            onClick={(e) => props.pickAll()}
            className="centrify-blue-color filter-text"
          >
            Pick All
          </a>
        </div>
        <div className="mt-4" style={{ position: "relative" }}>
          {projects.loading && <TableLoader />}
          <Table responsive className="main-table">
            <thead className="main-table-header">
              <tr>
                <th>{t("id")}</th>
                <th>
                  <span className="ml-4 mb-0 d-flex">{t("name")}</span>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody className="main-table-body">{getProjectList}</tbody>
          </Table>
        </div>
        {projects.projectCount > 0 ? (
          <div className="mt-4 d-flex justify-content-center">
            <Pagination
              count={Math.ceil(projects.projectCount / 10)}
              page={variables.page}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
