import Domain from "lib/Config";
import axios from "axios";
import { NotificationManager } from "react-notifications";

export const GET_ALL_MEDICINES = "[ADMIN] GET_ALL_MEDICINES";
export const MEDICINE_POSTED = "[ADMIN] MEDICINE_POSTED";
export const MEDICINE_LOADING = "[ADMIN] MEDICINE_LOADING";
export const MEDICINE_ERROR = "[ADMIN] MEDICINE_ERROR";

export function getAllMedicines(data, vendor_id) {
  let page = 0;
  if (data.page) {
    page = data.page - 1;
  }

  const request = axios.post(`${Domain}/api/vendor/getmedicines`, {
    ...data,
    page: page,
    vendor_id,
    limit: 10,
  });
  return (dispatch) =>
    request
      .then((response) => {
        console.log(response);
        return dispatch({
          type: GET_ALL_MEDICINES,
          payload: response.data.result,
        });
      })
      .catch((error) => {
        console.log(error.response);
        return dispatch({
          type: MEDICINE_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get medicines",
        });
      });
}

export function addMedicines(data) {
  const request = axios.post(`${Domain}/api/vendor/addMedicine`, data);
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success("Medicine Added Successfully");
        dispatch({
          type: MEDICINE_POSTED,
          payload: true,
        });
        dispatch(getAllMedicines({}, data.vendor_id));
      })
      .catch((error) => {
        console.log(error.response);
        dispatch(setMedicineLoading(false));
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : "Cannot Add Medicine"
        );
      });
}

export function updateMedicine(data) {
  const request = axios.put(`${Domain}/api/vendor/updateMedicine`, data);
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success("Medicine Updated Successfully");
        dispatch({
          type: MEDICINE_POSTED,
          payload: true,
        });
        dispatch(getAllMedicines({}, data.vendor_id));
      })
      .catch((error) => {
        console.log(error.response);
        dispatch(setMedicineLoading(false));
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : "Cannot Update Medicine"
        );
      });
}

export function deleteMedicine(id, vendor_id) {
  const request = axios.delete(`${Domain}/api/vendor/deleteMedicine/`, {
    params: {
      id,
    },
  });
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success("Medicine Deleted Successfully");
        dispatch(getAllMedicines({}, vendor_id));
      })
      .catch((error) => {
        console.log(error.response);
        dispatch(setMedicineLoading(false));
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : "Cannot Update Medicine"
        );
      });
}

export function assignProjectToMedicine(data) {
  const request = axios.post(`${Domain}/api/vendor/addProjectMedicine`, data);
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success("Project assigned successfully");
        dispatch({
          type: MEDICINE_POSTED,
          payload: true,
        });
        dispatch(getAllMedicines({ page: 1 }, data.vendor_id));
      })
      .catch((error) => {
        console.log(error.response);
        dispatch(setMedicineLoading(false));
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : "Unable to assign project"
        );
      });
}
export function setMedicineLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: MEDICINE_LOADING,
      payload: val,
    });
  };
}
