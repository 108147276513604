import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Actions from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import ConfrimDialogue from "components/Dialogue/ConfrimDialogue";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import { Form, FormGroup, Input, Table } from "reactstrap";
import TableLoader from "components/ErrorHandlers/TableLoader";
import Pagination from "@material-ui/lab/Pagination";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function Activities(props) {
  const dispatch = useDispatch();
  const { engagement } = props;
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [dialogue, setDialogue] = useState(false);
  const engagementReducer = useSelector(({ engagement }) => engagement);
  const [activities, setactivities] = useState([]);
  const [activitiesCount, setActivitiesCount] = useState(0);

  const [variables, setVariables] = useState({ page: 1 });
  const { t } = useTranslation();

  useEffect(() => {
    if (engagement) {
      dispatch(Actions.setEngagementStateLoading(true));
      dispatch(
        Actions.getEngagementActivities(props.match.params.id, variables)
      );
    }
  }, [engagement, variables]);
  useEffect(() => {
    if (
      engagementReducer &&
      engagementReducer.tabContentList &&
      engagementReducer.tabContentType == "activities"
    ) {
      setactivities(engagementReducer.tabContentList);
      setActivitiesCount(engagementReducer.tabContentListCount);
    }
    if (engagementReducer.error) {
      setactivities([]);
      setActivitiesCount(0);
    }
  }, [engagementReducer]);
  const getTime = (time, tz) => {
    let splittedTime = time.split(":");
    const clientTimezoneOffset = new Date().getTimezoneOffset() / 60;

    var d = new Date();
    // Creating a date based on the time
    d.setHours(splittedTime[0], splittedTime[1]);
    // Adjusting the date according to the time zone
    d.setHours(d.getHours() - clientTimezoneOffset);

    // for local time zone...................
    // const zoneTime = moment(d).format("HH:mm");

    // for time zone provided................
    const zoneTime = moment(d).utc().utcOffset(tz).format("HH:mm");
    return zoneTime;
  };
  const getActivityList =
    activities && activities.length > 0 ? (
      activities.map((item) => {
        return (
          <tr>
            <td>
              <p className="ml-4 mb-0 prominent cursor-pointer">
                {item?.title}
              </p>
            </td>
            <td>{item?.type}</td>
            <td>{getTime(item?.time, item?.tz)}</td>
            {/* {<td>{item?.time}</td>} */}
            <td>{item?.from}</td>
            <td>{item?.to}</td>
            <td>
              <div className="  ">
                <Link
                  to={`/editEngagementActivity/${props.match.params.id}/${item.id}`}
                >
                  <EditIcon className="centrify-grey-color cursor-pointer mr-2" />
                </Link>

                <DeleteIcon
                  onClick={() => {
                    setData(item);
                    setDialogue(!dialogue);
                  }}
                  className={`cursor-pointer  mr-2 centrify-grey-color`}
                />
              </div>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colspan={7} className="text-center font-weight-bold">
          {t("nothing_to_display")}
        </td>
      </tr>
    );

  const handlePageChange = (e, page) => {
    setVariables({
      ...variables,
      page: page,
    });
  };
  const handleDelete = () => {
    if (props.match.params.id && data) {
      dispatch(Actions.setEngagementStateLoading(true));
      console.log("engagement id --->", props.match.params.id);
      console.log("activity id @@@ --->", data.id);
      dispatch(
        Actions.deleteEngagementActivity(props.match.params.id, data.id)
      );
      setDialogue(!dialogue);
      setData(null);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let search = document.getElementById("questionaire-search").value;
    console.log("Search Value -------->", search);
    setVariables({
      ...variables,
      _name: search,
      page: 1,
    });
  };
  const handleChipDelete = () => {
    document.getElementById("questionaire-search").value = "";
    setVariables({
      ...variables,
      _name: "",
      page: 1,
    });
  };
  if (engagementReducer.tabError) {
    return <ErrorMessageComponents message={t("cannot_get_activities")} />;
  }

  if (engagementReducer === null) {
    return <CircularProgressComponent />;
  }

  return (
    <div>
      <div className="mt-4">
        <div className="d-flex justify-content-end">
          <Link
            to={`/addEngagementactivity/${engagement?.id}`}
            color="none"
            className="primary-background-button-table font-weight-bold"
          >
            {" "}
            {t("add_activity")}
          </Link>
        </div>

        <div className="rct-block mg-top-40">
          <div className="d-flex justify-content-between align-items-center border-bottom-centrify p-4">
            <div className="d-flex flex-column w-100">
              <div className="mb-2">
                {variables._name && (
                  <Chip
                    label={variables._name}
                    onDelete={() => handleChipDelete()}
                    className={classes.chip}
                  />
                )}
              </div>
              <div className="d-flex justify-content-between align-items-end table-top-bar ">
                <div className="d-flex align-items-center filters">
                  <Form
                    className="mt-2 mt-sm-0"
                    onSubmit={(e) => handleSearch(e)}
                  >
                    <FormGroup className="d-inline-block gift-search-input">
                      <Input
                        type="text"
                        name="value"
                        id="questionaire-search"
                        maxLength={10}
                        placeholder={t("search")}
                        style={{ paddingRight: "40px" }}
                      />
                      <button type="submit" className="gift-search-icon">
                        <i className="zmdi zmdi-search centrify-blue-color"></i>
                      </button>
                    </FormGroup>
                  </Form>
                </div>
              </div>

              <div className="mt-4" style={{ position: "relative" }}>
                {engagementReducer.stateloading && <TableLoader />}
                <Table responsive className="main-table">
                  <thead className="main-table-header">
                    <tr>
                      <th>
                        <span className="ml-4 mb-0 d-flex">{t("title")}</span>
                      </th>
                      <th>{t("activity_type")}</th>
                      <th>{t("time")}</th>
                      <th>{t("starting_day")}</th>
                      <th>{t("ending_day")}</th>
                      <th>{t("actions")}</th>
                    </tr>
                  </thead>
                  <tbody className="main-table-body">{getActivityList}</tbody>
                </Table>
              </div>
              {activitiesCount > 0 ? (
                <div className="mt-4 d-flex justify-content-center">
                  <Pagination
                    count={Math.ceil(activitiesCount / 10)}
                    page={variables.page}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                  />
                </div>
              ) : null}
              <ConfrimDialogue
                open={dialogue}
                handleClose={() => setDialogue(false)}
                action={() => handleDelete()}
                msg="Are you sure you want to delete this activity?"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
