import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Actions from 'store/actions'
import { CircularProgress, IconButton } from '@material-ui/core'
import { Button, Modal, Label, ModalBody, Input } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import Domain from 'lib/Config'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import DeleteIcon from '@material-ui/icons/Delete'
import { NotificationManager } from 'react-notifications'
import FileDrop from 'react-dropzone'
import AddIcon from '@material-ui/icons/Add'

let productAxios = axios.create({})

delete productAxios.defaults.headers.common['Authorization']
const ProductModal = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [images, setImages] = useState([])
  const [imageLoading, setImageLoading] = useState(false)

  const [productForm, setProductForm] = useState({})
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false
  })

  const storeReducer = useSelector(({ store }) => store)

  let handleSubmit = e => {
    e.preventDefault()
    if (!images || images.length == 0) {
      NotificationManager.error(`${t('select_atleast_onImage')}`)
    } else {
      let obj = {
        ...productForm,
        category_id: e.target.category_id.value,
        unit: e.target.unit.value
      }
      let tempImg = []

      images.map(item => {
        console.log('Item ----@@@ ', item)
        if (typeof item == 'string') {
          tempImg.push(item)
        } else {
          tempImg.push(item.image)
        }
      })
      obj.images = tempImg
      obj.vendor_id = Auth.vendor_id
      console.log('temp img---->', tempImg)
      console.log('Obj @@@ --->', obj)
      dispatch(Actions.setStoreStateLoading(true))
      if (props.productModalType == 'add') {
        dispatch(Actions.addStoreProduct(obj))
      } else {
        dispatch(Actions.updateStoreProduct(obj, obj.id, Auth.vendor_id))
      }
    }
  }
  console.log('productForm --->', productForm)
  console.log('images --->', images)
  console.log(' props.productModalType -->', props.productModalType)

  const onDrop = useCallback(
    img => {
      setImageLoading(true)
      handleFileUpload(img[0])
    },
    [images]
  )

  const removeImg = id => {
    setImages(images.filter(img => img.id !== id))
  }

  const handleFileUpload = data => {
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
      },
      header: {
        'Content-Type': data.type
      }
    }
    getSignedURL(data.name, data.type).then(rsp => {
      productAxios
        .put(rsp.data.result.postUrl, data, config)
        .then(res => {
          let image = rsp.data.result.getUrl
          console.log('res ---->', rsp)
          console.log('res --->', res)
          setImages([...images, image])
          setImageLoading(false)
        })
        .catch(err => {
          setImageLoading(false)
          NotificationManager.error(`${t('error_something_went_wrong')}`)
          console.log(err.response)
        })
    })
  }

  const getSignedURL = (key, type) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Domain}/api/vendor/s3getsignedurl?key=${key}&type=${type}`)
        .then(data => {
          resolve(data)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  }

  useEffect(() => {
    if (props.productModalType == 'update') {
      setImages(props.product.productImages)
      setProductForm({ ...props.product })
    } else {
      setImages([])
      setProductForm(null)
    }
  }, [props.productModalType, props.modal, props.product])

  return (
    <div>
      <Modal
        isOpen={props.modal}
        toggle={props.toggle}
        className='modal-background'
      >
        <ModalBody>
          <div className='p-4'>
            <div className='d-flex justify-content-end'>
              <img
                onClick={() => props.toggle()}
                className='centrify-icons cursor-pointer'
                src={require('assets/icons/close.png')}
              />
            </div>
            <div className='mt-2 form-styles'>
              <h2 className='form-heading d-flex'>
                {props.productModalType == 'add'
                  ? t('create_new_product')
                  : t('update_product')}
              </h2>
              <form onSubmit={e => handleSubmit(e)}>
                <div className='mt-4'>
                  <Label className='d-flex'>{t('product_title')}</Label>
                  <Input
                    type='text'
                    name='title'
                    required
                    value={productForm?.title}
                    onChange={e => {
                      setProductForm({
                        ...productForm,
                        title: e.target.value
                      })
                    }}
                  />
                </div>
                <div className='mt-4'>
                  <Label className='d-flex'>{t('category')}</Label>
                  <Input
                    type='select'
                    name='category_id'
                    required
                    value={productForm?.category_id}
                    onChange={e => {
                      setProductForm({
                        ...productForm,
                        category_id: e.target.value
                      })
                    }}
                  >
                    {props.categories &&
                      props.categories.map(category => {
                        return (
                          <option value={category.id}>{category.title}</option>
                        )
                      })}
                  </Input>
                </div>

                <div className='mt-4'>
                  <Label className='d-flex'>{t('quantity')}</Label>
                  <Input
                    type='number'
                    min='1'
                    name='quantity'
                    required
                    value={productForm?.quantity}
                    onChange={e => {
                      setProductForm({
                        ...productForm,
                        quantity: e.target.value
                      })
                    }}
                  />
                </div>
                <div className='mt-4'>
                  <Label>{t('unit')}</Label>
                  <Input
                    type='text'
                    name='unit'
                    required
                    value={productForm?.unit}
                    onChange={e => {
                      setProductForm({
                        ...productForm,
                        unit: e.target.value
                      })
                    }}
                  />
                </div>
                <div className='mt-4'>
                  <Label className='d-flex'>{t('price')}</Label>
                  <Input
                    type='number'
                    min='0'
                    name='price'
                    required
                    value={productForm?.price}
                    onChange={e => {
                      setProductForm({
                        ...productForm,
                        price: e.target.value
                      })
                    }}
                  />
                </div>
                <div className='mt-4'>
                  <Label className='d-flex'>{t('sku')}</Label>
                  <Input
                    type='text'
                    name='sku'
                    required
                    value={productForm?.sku}
                    onChange={e => {
                      setProductForm({
                        ...productForm,
                        sku: e.target.value
                      })
                    }}
                  />
                </div>
                <div className='mt-4'>
                  <Label className='d-flex'>
                    {t('product_web_store_link')}
                  </Label>
                  <Input
                    type='text'
                    name='link'
                    value={productForm?.link}
                    onChange={e => {
                      setProductForm({
                        ...productForm,
                        link: e.target.value
                      })
                    }}
                  />
                </div>
                <div className='mt-4'>
                  <Label className='d-flex'>{t('description')}</Label>
                  <Input
                    type='textarea'
                    name='title'
                    required
                    value={productForm?.description}
                    onChange={e => {
                      setProductForm({
                        ...productForm,
                        description: e.target.value
                      })
                    }}
                    rows={4}
                    style={{
                      resize: 'none'
                    }}
                  />
                </div>
                <div className='mt-4'>
                  <div className='row'>
                    {images &&
                      images.length > 0 &&
                      images.map(img => (
                        <div
                          className='col-md-4 mb-2 animate__animated animate__fadeIn'
                          key={img.id}
                        >
                          <div className='img-div d-flex flex-column'>
                            <img
                              src={typeof img == 'string' ? img : img?.image}
                              width='100%'
                              alt='qwe'
                            />
                          </div>
                          <div className='del-img'>
                            <IconButton
                              color='inherit'
                              mini='true'
                              className='p-0'
                              onClick={() => removeImg(img.id)}
                            >
                              <DeleteIcon fontSize='small' />
                            </IconButton>
                          </div>
                        </div>
                      ))}
                    <div className='col-md-4'>
                      <FileDrop
                        disabled={imageLoading}
                        accept='image/png, image/gif, image/jpeg, image/bmp,'
                        multiple
                        onDrop={onDrop}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()} className='img-div'>
                            <IconButton
                              color='inherit'
                              mini='true'
                              aria-label='Menu'
                              className='p-0'
                            >
                              {imageLoading ? (
                                <CircularProgress
                                  style={{ height: '10px', width: '10px' }}
                                />
                              ) : (
                                <AddIcon fontSize='small' />
                              )}
                            </IconButton>
                            <input {...getInputProps()} />
                          </div>
                        )}
                      </FileDrop>
                    </div>
                  </div>
                </div>

                <div className='mt-4 d-flex justify-content-end'>
                  {storeReducer.stateLoading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      className='primary-background-button-table'
                      color='none'
                    >
                      {props.productModalType == 'add'
                        ? t('create')
                        : t('update')}
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ProductModal
