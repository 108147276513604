import Domain from 'lib/Config'
import axios from 'axios'
import moment from 'moment'
import History from '@history'
import { NotificationManager } from 'react-notifications'

export const GET_ALL_ENGAGEMENTS = '[ADMIN] GET_ALL_ENGAGEMENTS'
export const GET_ENGAGEMENT = '[ADMIN] GET_ENGAGEMENT'
export const ADD_ENGAGEMENT = '[ADMIN] ADD_ENGAGEMENT'
export const ENGAGEMENT_ERROR = '[ADMIN] ENGAGEMENT_ERROR'
export const ENGAGEMENT_TAB_ERROR = '[ADMIN] ENGAGEMENT_TAB_ERROR'
export const ENGAGEMENT_POSTED = '[ADMIN] ENAGEMENT_POSTED'
export const ENGAGEMENT_LOADING = '[ADMIN] ENGAGEMENT_LOADING'
export const ENGAGEMENT_STATE_LOADING = '[ADMIN] ENGAGEMENT_STATE_LOADING'
export const ENGAGEMENT_ASSIGN_LOADING = '[ADMIN] ENGAGEMENT_ASSIGN_LOADING'
export const CLEAR_ENGAGEMENT = '[ADMIN] CLEAR_ENGAGEMENT'
export const ASSIGN_ENGAGEMENT_LOADING = '[ADMIN] ASSIGN_ENGAGEMENT_LOADING'
export const GET_ENGAGEMENT_BY_ID = '[ADMIN] GET_ENGAGEMENT_BY_ID'
export const GET_ENGAGEMENT_TAB_DATA = '[ADMIN] GET_ENGAGEMENT_TAB_DATA'
export const GET_ENGAGEMENT_TAB_DATA_DETAIL =
  '[ADMIN] GET_ENGAGEMENT_TAB_DATA_DETAIL'
export const GET_ENGAGEMENT_TREE_QUESTIONNAIRES =
  '[ADMIN] GET_ENGAGEMENT_TREE_QUESTIONNAIRES'
export const GET_ENGAGEMENT_BY_ID_EDIT = '[ADMIN] GET_ENGAGEMENT_BY_ID_EDIT'
export const GET_ENGAGEMENT_PATIENTS = '[ADMIN] GET_ENGAGEMENT_PATIENTS'
export const TAB_CONTENT_NULL = '[ADMIN] TAB_CONTENT_NULL'

export function getAllEngagements (data, employee_id, vendor_id) {
  let page = 0
  if (data && data.page) {
    page = data.page - 1
  }
  const request = axios.get(`${Domain}/api/vendor/engagements`, {
    params: {
      ...data,
      page,
      employee_id,
      vendor_id,
      limit: 10,
      offset: data && data.page ? data.page * 10 : 0
    }
  })
  return dispatch =>
    request
      .then(response => {
        console.log('Get All Engagements --->', response)
        console.log(response.data.engagements)
        return dispatch({
          type: GET_ALL_ENGAGEMENTS,
          payload: response.data.engagements
        })
      })
      .catch(error => {
        dispatch(setEngagementLoading(false))
        console.log(error.response)
        return dispatch({
          type: ENGAGEMENT_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get engagements'
        })
      })
}
export function addEngagement (data, employee_id, vendor_id) {
  data.employee_id = employee_id
  data.vendor_id = vendor_id
  const request = axios.post(`${Domain}/api/vendor/engagement`, data)
  return dispatch =>
    request
      .then(response => {
        NotificationManager.success('Engagement added successfully')
        dispatch({
          type: ENGAGEMENT_POSTED,
          payload: true
        })
        dispatch(setEngagementLoading(true))
        dispatch(getAllEngagements({}, employee_id, vendor_id))
      })
      .catch(error => {
        console.log('Error ----->', error)
        console.log(error.response)
        dispatch(setEngagementStateLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Cannot add engagement'
        )
      })
}
export function updateEngagement (data, employee_id, engagementId, vendor_id) {
  data.employee_id = employee_id
  console.log('data --->', data)
  const request = axios.put(
    `${Domain}/api/vendor/engagement/${engagementId}`,
    data
  )
  return dispatch =>
    request
      .then(response => {
        NotificationManager.success('Engagement updated successfully')
        dispatch({
          type: ENGAGEMENT_POSTED,
          payload: true
        })
        dispatch(setEngagementLoading(true))
        dispatch(getAllEngagements({}, employee_id, vendor_id))
      })
      .catch(error => {
        console.log('Error updating Engagement -->')
        console.log(error.response)
        dispatch(setEngagementStateLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Cannot update engagement'
        )
      })
}
export function deleteEngagement (id, employee_id, vendor_id) {
  const request = axios.delete(`${Domain}/api/vendor/engagement/${id}`)
  return dispatch =>
    request
      .then(response => {
        NotificationManager.success('Engagement deleted successfully')
        dispatch(setEngagementLoading(true))
        dispatch(getAllEngagements({}, employee_id, vendor_id))
      })
      .catch(error => {
        console.log(error.response)
        dispatch(setEngagementStateLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Cannot delete engagement'
        )
      })
}

export function getEngagementDetail (id) {
  console.log('Get Engagement Detail -->', id)
  const request = axios.get(`${Domain}/api/vendor/singleEngagement/${id}`)
  return dispatch =>
    request
      .then(response => {
        console.log('Response --->', response)
        return dispatch({
          type: GET_ENGAGEMENT_BY_ID,
          payload: response.data.engagement
        })
      })
      .catch(error => {
        console.log('Error --->', error)
        console.log(error.response)
        dispatch(setEngagementLoading(false))
        return dispatch({
          type: ENGAGEMENT_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get engagement'
        })
      })
}

export function getEngagementQuestionnaire (questionnaire_id) {
  console.log('getEngagmentQuestionnaire @@@@')

  const request = axios.get(
    `${Domain}/api/vendor/singleEngagementQuestionnaire/${questionnaire_id}`
  )
  return dispatch =>
    request
      .then(response => {
        console.log('Response --->', response)
        return dispatch({
          type: GET_ENGAGEMENT_TAB_DATA_DETAIL,
          payload: response.data.questionnaire
        })
      })
      .catch(error => {
        console.log('Error --->', error)
        console.log(error.response)
        dispatch(setEngagementLoading(false))
        return dispatch({
          type: ENGAGEMENT_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get engagement'
        })
      })
}

// Getting Questionnaires that are already part of a engagement.
export function getEngagementQuestionnaires (engagement_id, data) {
  console.log('Data ----->', data)
  console.log('getEngagmentQuestionnaire @@@@')
  let page = 0
  if (data && data.page) {
    page = data.page - 1
  }
  console.log('Offset --->', data.page * 10)
  const request = axios.get(`${Domain}/api/vendor/engagementQuestionnaires`, {
    params: {
      ...data,
      title: data?._name,
      engagement_id,
      limit: 10,
      offset: data && data.page ? page * 10 : 0
    }
  })
  return dispatch =>
    request
      .then(response => {
        console.log('Response --->', response)
        return dispatch({
          type: GET_ENGAGEMENT_TAB_DATA,
          payload: {
            ...response.data.treeQuestionnaires,
            tabContentType: 'questionnaires'
          }
        })
      })
      .catch(error => {
        console.log('Error --->', error)
        console.log(error.response)
        dispatch(setEngagementStateLoading(false))
        return dispatch({
          type: ENGAGEMENT_TAB_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get engagement'
        })
      })
}
// Getting  All Vchat questionnaires, to be added to a engagement
export function getEngagementTreeQuestionniares (data, vendor_id, employee_id) {
  console.log(data, vendor_id)
  let page = 0
  if (data && data.page) {
    page = data.page - 1
  }
  console.log('Get All Tree Questionnaire called')
  console.log(data)
  const request = axios.post(`${Domain}/api/vendor/getTreeQuestionnaires`, {
    ...data,
    page,
    vendor_id,
    employee_id,
    limit: 10
  })
  return dispatch =>
    request
      .then(response => {
        console.log(
          'Tree questionnaire response -->',
          response.data.treeQuestionnaire
        )
        return dispatch({
          type: GET_ENGAGEMENT_TREE_QUESTIONNAIRES,
          payload: response.data.treeQuestionnaire
        })
      })
      .catch(error => {
        setEngagementLoading(false)
        console.log('Tree questionnaire Error -->', error.response)
        console.log(error.response)
        return dispatch({
          type: ENGAGEMENT_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get questionaires'
        })
      })
}
export function addEngagementQuestionnaire (data) {
  console.log(data)
  const request = axios.post(
    `${Domain}/api/vendor/addEngagementQuestionnaire`,
    data
  )
  return dispatch =>
    request
      .then(response => {
        dispatch({
          type: ENGAGEMENT_POSTED,
          payload: true
        })
        console.log('add engagment questionnaire response --->', response)
        let eng_id = data.engagement_id
        // Incase engagment is already assigned,
        // A copy of current engagement is created in which the newly added questionnaire and previous content of the engagment are added
        if (response.data.response.new == true) {
          eng_id = response.data.response.engagement_id
          NotificationManager.success('Questionnaire added successfully')
          NotificationManager.success('New engagement created')
        } else {
          NotificationManager.success('Questionnaire added successfully')
        }
        History.push(`/engagement/${eng_id}/0`)
        dispatch(setEngagementLoading(false))
      })
      .catch(error => {
        console.log(error)
        dispatch(setEngagementLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Cannot add questionnaire '
        )
        // }
      })
}
export function updateEngagementQuestionnaire (data, questionnaire_id) {
  console.log(data)
  const request = axios.put(
    `${Domain}/api/vendor/updateEngagementQuestionnaire/${questionnaire_id}`,
    data
  )
  return dispatch =>
    request
      .then(response => {
        dispatch({
          type: ENGAGEMENT_POSTED,
          payload: true
        })
        console.log('update engagment questionnaire response --->', response)
        let eng_id = data.engagement_id
        // Incase engagment is already assigned,
        // A copy of current engagement is created in which the updated questionnaire and previous content of the engagment are added
        if (response.data.response.new == true) {
          eng_id = response.data.response.engagement_id
          NotificationManager.success('Questionnaire updated successfully')
          NotificationManager.success('New engagement created')
        } else {
          NotificationManager.success('Questionnaire updated successfully')
        }
        History.push(`/engagement/${eng_id}/0`)
        dispatch(setEngagementLoading(false))
      })
      .catch(error => {
        console.log(error)
        dispatch(setEngagementLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Cannot update questionnaire '
        )
        // }
      })
}
export function deleteEngagementQuestionnaire (
  engagement_id,
  questionnaire_id
) {
  console.log('Engagement_id ---->', engagement_id)
  console.log('questionnaire_id --->', questionnaire_id)
  const request = axios.delete(
    `${Domain}/api/vendor/deleteEngagementQuestionnaire/${questionnaire_id}`
  )
  return dispatch =>
    request
      .then(response => {
        dispatch(getEngagementQuestionnaires(engagement_id, {}))
        NotificationManager.success('Questionnaire deleted successfully')
      })
      .catch(error => {
        console.log(error.response)
        dispatch(setEngagementStateLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Cannot delete questionnaire'
        )
      })
}
export function getEngagementActivities (engagement_id, data) {
  console.log('Data ----->', data)
  console.log('getEngagmentActivties @@@@')
  let page = 0
  if (data && data.page) {
    page = data.page - 1
  }
  console.log('Page ---->', page)
  console.log('page == 0 ? 0 :page * 10, --->', page == 0 ? 0 : page * 10)
  const request = axios.get(`${Domain}/api/vendor/engagementActivities`, {
    params: {
      ...data,
      page,
      title: data._name,
      engagement_id,
      limit: 10,
      offset: page == 0 ? 0 : page * 10
    }
  })
  return dispatch =>
    request
      .then(response => {
        console.log('Response --->', response)
        return dispatch({
          type: GET_ENGAGEMENT_TAB_DATA,
          payload: {
            ...response.data.activities,
            tabContentType: 'activities'
          }
        })
      })
      .catch(error => {
        console.log('Error --->', error)
        console.log(error.response)
        dispatch(setEngagementLoading(false))
        return dispatch({
          type: ENGAGEMENT_TAB_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get engagement'
        })
      })
}
export function getEngagementActivity (activity_id) {
  console.log('@@@@ Get Engagement Activity Called  @@@@')

  const request = axios.get(
    `${Domain}/api/vendor/singleEngagementActivity/${activity_id}`
  )
  return dispatch =>
    request
      .then(response => {
        console.log('Response --->', response)
        return dispatch({
          type: GET_ENGAGEMENT_TAB_DATA_DETAIL,
          payload: response.data.activity
        })
      })
      .catch(error => {
        console.log('Error --->', error)
        console.log(error.response)
        dispatch(setEngagementLoading(false))
        return dispatch({
          type: ENGAGEMENT_TAB_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get engagement'
        })
      })
}

export function addEngagementActivity (engagement_id, data) {
  console.log(data)
  const request = axios.post(`${Domain}/api/vendor/addEngagementActivity`, data)
  return dispatch =>
    request
      .then(response => {
        console.log('Enagement Activity Add Response --->', response)
        dispatch({
          type: ENGAGEMENT_POSTED,
          payload: true
        })
        let eng_id = engagement_id
        // Incase engagment is already assigned,
        // A copy of current engagement is created in which the newly added activty and previous activites of the engagment are added
        if (response.data.response.new == true) {
          eng_id = response.data.response.engagement_id
          NotificationManager.success('Activity added successfully')
          NotificationManager.success('New engagement created')
        } else {
          NotificationManager.success('Activity added successfully')
        }
        History.push(`/engagement/${eng_id}/1`)
        dispatch(setEngagementLoading(false))
      })
      .catch(error => {
        console.log(error)
        console.log('Error --->', error.response)
        dispatch(setEngagementLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Cannot add activity '
        )
        // }
      })
}
export function updateEngagementActivity (engagement_id, activity_id, data) {
  console.log(data)
  const request = axios.put(
    `${Domain}/api/vendor/updateEngagementActivity/${activity_id}`,
    data
  )
  return dispatch =>
    request
      .then(response => {
        console.log('Enagement Activity Update Response --->', response)
        dispatch({
          type: ENGAGEMENT_POSTED,
          payload: true
        })
        let eng_id = engagement_id
        // Incase engagment is already assigned,
        // A copy of current engagement is created in which the updated activty and previous activites of the engagment are added
        if (response.data.response.new == true) {
          eng_id = response.data.response.engagement_id
          NotificationManager.success('Activity updated successfully')
          NotificationManager.success('New engagement created')
        } else {
          NotificationManager.success('Activity updated Successfully')
        }
        History.push(`/engagement/${eng_id}/1`)
        dispatch(setEngagementLoading(false))
      })
      .catch(error => {
        console.log('@@@@@@@@')
        console.log(error)
        console.log('Error --->', error.response)
        dispatch(setEngagementLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Cannot update activity '
        )
      })
}
export function deleteEngagementActivity (engagement_id, activity_id) {
  console.log(' Engagement  Id---->', engagement_id)
  console.log('Activity Id --->', activity_id)
  const request = axios.delete(
    `${Domain}/api/vendor/deleteEngagementActivity/${activity_id}`
  )
  return dispatch =>
    request
      .then(response => {
        dispatch(getEngagementActivities(engagement_id, {}))
        NotificationManager.success('Activity deleted successfully')
      })
      .catch(error => {
        console.log(error.response)
        dispatch(setEngagementStateLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Cannot delete activity'
        )
      })
}
export function getEngagementProjects (engagement_id, data) {
  console.log('Data to get engagementprojects ----->', data)
  console.log('getEngagmentProjects @@@@')
  let page = 0
  if (data && data.page) {
    page = data.page - 1
  }
  const request = axios.get(`${Domain}/api/vendor/engagementProjectss`, {
    params: {
      ...data,
      title: data._name,
      engagement_id,
      limit: 10,
      offset: page ? page * 10 : 0
    }
  })
  return dispatch =>
    request
      .then(response => {
        console.log('Response --->', response)
        return dispatch({
          type: GET_ENGAGEMENT_TAB_DATA,
          payload: { ...response.data.engagements, tabContentType: 'projects' }
        })
      })
      .catch(error => {
        console.log('Error --->', error)
        console.log(error.response)
        dispatch(setEngagementLoading(false))
        return dispatch({
          type: ENGAGEMENT_TAB_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get engagement'
        })
      })
}
export function getEngagementPatients (engagement_id, data) {
  console.log('Data to get engagement patients ----->', data)
  console.log('getEngagmentPatients @@@@')
  let page = 0
  if (data && data.page) {
    page = data.page - 1
  }
  const request = axios.get(`${Domain}/api/vendor/engagementPatients`, {
    params: {
      ...data,

      title: data._name,
      engagement_id,
      limit: 10,
      offset: page ? page * 10 : 0
    }
  })
  return dispatch =>
    request
      .then(response => {
        console.log('Response Get Engagment Patients --->', response)
        let count = response.data.engagements.count
          ? response.data.engagements.count
          : 0
        return dispatch({
          type: GET_ENGAGEMENT_TAB_DATA,
          payload: {
            rows: response.data.engagements.rows,
            count: count,
            tabContentType: 'patients'
          }
        })
      })
      .catch(error => {
        console.log('Error --->', error)
        console.log(error.response)
        dispatch(setEngagementLoading(false))
        return dispatch({
          type: ENGAGEMENT_TAB_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get engagement'
        })
      })
}
export function assignEngagementToPatient (
  engagement_id,
  patient_id,
  project_id,
  employee_id,
  type,
  vendor_id
) {
  const data = {
    engagement_id,
    patients: [patient_id],
    project_id
  }

  console.log('Data assign engagement to patient ---<', data)
  const request = axios.post(
    `${Domain}/api/vendor/engagementToMultiplePatients`,
    data
  )
  return dispatch =>
    request
      .then(response => {
        NotificationManager.success('Engagement assigned successfully')
        if (type == 'detail_view') {
          dispatch(setEngagementStateLoading(true))
          dispatch(getEngagementPatients(engagement_id, {}))
        } else {
          dispatch(setEngagementLoading(true))
          dispatch(getAllEngagements({}, employee_id, vendor_id))
        }
        dispatch({
          type: ENGAGEMENT_POSTED,
          payload: true
        })
      })
      .catch(error => {
        console.log('Engagment add error --->', error)
        console.log(error.response)
        dispatch(setEngagementAssignLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Cannot assign Engagement'
        )
      })
}
export function assignEngagementToProject (
  engagement_id,
  projects,
  employee_id,
  type,
  vendor_id
) {
  const data = {
    engagement_id,
    projects: projects
  }
  console.log('Data assign ---<', data)
  const request = axios.post(
    `${Domain}/api/vendor/assingEngagementToMultipleProjects`,
    data
  )
  return dispatch =>
    request
      .then(response => {
        NotificationManager.success('Engagement assigned successfully')
        console.log('Type --->', type)
        if (type == 'detail_view') {
          console.log('Employee_')
          dispatch(setEngagementStateLoading(true))
          dispatch(getEngagementProjects(engagement_id, {}))
        } else {
          dispatch(setEngagementLoading(true))
          dispatch(getAllEngagements({}, employee_id, vendor_id))
        }
        dispatch({
          type: ENGAGEMENT_POSTED,
          payload: true
        })
      })
      .catch(error => {
        console.log(error.response)
        dispatch(setEngagementAssignLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Cannot assign engagement'
        )
      })
}
export function cancelPatientEngagment (patientEngagement_id, engagement_id) {
  const data = {
    patientEngagement_id
  }
  const request = axios.post(
    `${Domain}/api/vendor/cancelPatientEngagement`,
    data
  )
  return dispatch =>
    request
      .then(response => {
        NotificationManager.success('Engagement patient cancelled successfully')
        dispatch(setEngagementStateLoading(true))
        dispatch(getEngagementPatients(engagement_id, {}))
      })
      .catch(error => {
        console.log('error -->', error)
        console.log(error.response)
        dispatch(setEngagementStateLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Cannot cancel engagement patient. Try again'
        )
      })
}
export function cancelProjectEngagment (id, engagement_id) {
  const data = {
    id
  }
  const request = axios.post(
    `${Domain}/api/vendor/cancelProjectEngagement`,
    data
  )
  return dispatch =>
    request
      .then(response => {
        NotificationManager.success('Engagement project cancelled successfully')
        dispatch(setEngagementStateLoading(true))
        dispatch(getEngagementProjects(engagement_id, {}))
      })
      .catch(error => {
        console.log('error -->', error)
        console.log(error.response)
        dispatch(setEngagementStateLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Cannot cancel engagement project. Try again'
        )
      })
}

export function getEngagementChart (engagement_id, data) {
  console.log('Get Engagement chart --->', engagement_id)
  const request = axios.get(
    `${Domain}/api/vendor/engagementView/${engagement_id}`
  )
  return dispatch =>
    request
      .then(response => {
        console.log('Response Get Engagment Chart --->', response)

        return dispatch({
          type: GET_ENGAGEMENT_TAB_DATA,
          payload: {
            rows: response.data.response,
            count: null,
            tabContentType: 'chart'
          }
        })
      })
      .catch(error => {
        console.log('Error --->', error)
        console.log(error.response)
        dispatch(setEngagementLoading(false))
        return dispatch({
          type: ENGAGEMENT_TAB_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get engagement'
        })
      })
}

export function setEngagementLoading (val) {
  return dispatch => {
    return dispatch({
      type: ENGAGEMENT_LOADING,
      payload: val
    })
  }
}

export function setEngagementStateLoading (val) {
  return dispatch => {
    return dispatch({
      type: ENGAGEMENT_STATE_LOADING,
      payload: val
    })
  }
}
export function setEngagementAssignLoading (val) {
  console.log('Set enggement assign loading ')
  return dispatch => {
    return dispatch({
      type: ENGAGEMENT_ASSIGN_LOADING,
      payload: val
    })
  }
}
export function setTabContentNull () {
  return dispatch => {
    return dispatch({
      type: TAB_CONTENT_NULL
    })
  }
}
