import React, { useState } from "react";
import MarkdownIt from "markdown-it";
import { Button } from "reactstrap";
import ViewDocumentation from "./viewDocumentation";
import MdEditor from "react-markdown-editor-lite";
import { useTranslation } from "react-i18next";
import "react-markdown-editor-lite/lib/index.css";

const mdParser = new MarkdownIt();
const plugins = [
    "header",
    "fonts",
    "table",
    "link",
    "clear",
    "logger",
    "mode-toggle",
    "full-screen",
];

const DocumentationItem = (props) => {
    const [openView, setOpenView] = useState(false);
    const { item, patient_id } = props;
    const { t } = useTranslation();

    const stringTruncate = (str, length) => {
        const dots = str.length > length ? '...' : '';
        return `${str.substring(0, length)}${dots}`;
    };


    return (
        <>
            <div className="col-md-5 mr-4 mg-top-40 documentation-card">
                <div className='d-flex mt-5 mb-2 border-bottom bottom-muted align-items-center justify-content-between'>
                    <div className='my-0 h5 font-12 font-weight-bolder text-capitalize font-weight-bold text-truncate'>
                        <span>{item ?.title}</span>
                    </div>
                    {/* alert alert-success  */}
                    <span className='font-weight-normal p-0 px-1 m-0 text-truncate h5 font-12'>
                        <span className='font-weight-bold'>{item ?.doctor ?.firstName} {item ?.doctor ?.lastName}</span>
                    </span>
                </div>
                <div className='text-capitalize d-flex mb-1'>
                    <div className='d-flex flex-column'>
                        <div></div>
                        <h6 className='text-muted mb-2 font-10'>
                            {/* {date.getDate(createdAt)} */}
                            Posted On: <span className=''>{item.date}</span>
                        </h6>
                    </div>
                </div>
                <div className='col-sm-12 col-md-12 m-0 p-0'>
                    <p className='mb-1 mt-4 font-12'>
                        {stringTruncate(item.description, 200)}
                    </p>
                </div>
                <div className='d-flex justify-content-end mt-5'>
                    <Button
                        onClick={() => setOpenView(!openView)}
                        color="none"
                        className="primary-background-button-table font-weight-bold"
                    >
                        {" "}
                        {t("view_documentation")}
                    </Button>
                </div>
            </div>
            <ViewDocumentation open={openView} item={item} toggle={() => setOpenView(!openView)} patient_id={patient_id} />
        </>
    );
};
export default DocumentationItem;

{/* <div className='row m-0 pd-top-40'>
                {JSON.parse(item.files) && (JSON.parse(item.files).length) > 0 ? <>
                  {JSON.parse(item.files).map((el, index) => (<>
                    {index < 3 ?
                      <div className='col-sm-6 col-md-4 img-div'>
                        <img className='w-100 cursor-pointer' src={el.File} alt='' onClick={(e) => window.open(el.File)} />
                      </div> : null}
                  </>))}
                </> : null}
              </div> */}
