import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Form, FormGroup, Input, Table } from 'reactstrap'
import AssignmentIcon from '@material-ui/icons/Assignment'
import Pagination from '@material-ui/lab/Pagination'
import ConfrimDialogue from 'components/Dialogue/ConfrimDialogue'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

import History from '@history'
import ErrorMessageComponents from 'components/ErrorHandlers/ErrorMessageComponents'
import TableLoader from 'components/ErrorHandlers/TableLoader'
import { useDispatch, useSelector } from 'react-redux'
import qs from 'query-string'
import moment from 'moment'
import CircularProgressComponent from 'components/ErrorHandlers/CircularProgressComponent'
import { useTranslation } from 'react-i18next'
import * as Actions from 'store/actions'
import AssignToPatientModal from './patientModal'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5)
  }
}))

export default function Medicines (props) {
  const dispatch = useDispatch()
  const classes = useStyles()
  let querystr = qs.parse(props.location.search)
  let page = querystr.page ? JSON.parse(querystr.page) : 1
  const [variables, setVariables] = useState({ ...querystr })
  const [data, setData] = useState(null)
  const [patOpen, setPatOpen] = useState(null)

  const [treeQuestionnaireId, setTreeQuestionnaireId] = useState(null)
  const [selectedPatient, setSelectedPatient] = useState(null)

  const [questionaire, setQuestionaire] = useState(null)

  const [dialogue, setDialogue] = useState(false)
  const { t } = useTranslation()

  const stringTruncate = (str, length) => {
    const dots = str.length > length ? '...' : ''
    return `${str.substring(0, length)}${dots}`
  }

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false
  })
  const questionaireReducer = useSelector(({ questionaire }) => questionaire)

  useEffect(() => {
    const q_str = qs.stringify(variables)
    History.push({ search: q_str })
    let vendor_id = Auth.vendor_id
    let employee_id = Auth.id

    dispatch(Actions.setQuestionairesLoading(true))
    dispatch(
      Actions.getAllTreeQuestionniares(variables, vendor_id, employee_id)
    )
  }, [variables])

  useEffect(() => {
    setQuestionaire(questionaireReducer)
    if (questionaireReducer.posted) {
      setPatOpen(false)
      setSelectedPatient([])
    }
  }, [questionaireReducer])

  if (questionaire && questionaire.error) {
    return <ErrorMessageComponents message={t('cannot_fetch_questionnaires')} />
  }

  if (questionaire === null || !questionaire.treeQuestionnaireList) {
    return <CircularProgressComponent />
  }

  const getQuestionaireList =
    questionaire.treeQuestionnaireList &&
    questionaire.treeQuestionnaireList.length > 0 ? (
      questionaire.treeQuestionnaireList.map(item => {
        return (
          <tr>
            <td onClick={() => History.push(`/treeQuestionnaire/${item.id}`)}>
              <p className='ml-4 mb-0 prominent cursor-pointer'>{item.title}</p>
            </td>
            <td>
              {item.creationTime
                ? moment.utc(item?.creationTime).format('LT')
                : null}
            </td>
            <td>
              {item.creationTime
                ? moment.utc(item?.creationTime).format('MMM DD, YYYY')
                : null}
            </td>
            <td className=''>{item?.questionCount}</td>
            <td>{item?.patientQuestionnaire}</td>
            <td>{item?.solvedTreeQuestionnaire}</td>
            {/* <td>
              <AssignmentIcon
                onClick={() => {
                  setSelectedPatient(item.patients);
                  setTreeQuestionnaireId(item.id);
                  setPatOpen(true);
                }}
                className={`cursor-pointer mt-5  centrify-grey-color`}
              />
            </td> */}
            <td>
              <Link to={`/edittreequestionnaire/${item.id}`}>
                <EditIcon className='centrify-grey-color cursor-pointer' />
              </Link>
            </td>
            <td
              onClick={() => {
                if (item?.patientQuestionnaire == 0) {
                  setData(item)
                  setDialogue(!dialogue)
                }
              }}
            >
              <DeleteIcon
                className={`cursor-pointer mt-5  ${
                  item?.patientQuestionnaire == 0
                    ? 'centrify-grey-color '
                    : 'centrify-light-grey-color'
                }`}
              />
            </td>
          </tr>
        )
      })
    ) : (
      <tr>
        <td colspan={8} className='text-center font-weight-bold'>
          {t('nothing_to_display')}
        </td>
      </tr>
    )
  const handlePageChange = (e, page) => {
    setVariables({
      ...variables,
      page: page
    })
  }

  const handleSearch = e => {
    e.preventDefault()
    let search = document.getElementById('questionaire-search').value
    setVariables({
      ...variables,
      page: 1,
      _name: search
    })
  }

  const handleDelete = () => {
    dispatch(Actions.setQuestionairesLoading(true))
    dispatch(Actions.deleteTreeQuestionaire(data.id, Auth.vendor_id, Auth.id))
    setDialogue(!dialogue)
    setData(null)
  }
  const handleChipDelete = () => {
    document.getElementById('questionaire-search').value = ''
    setVariables({
      ...variables,
      _name: '',
      page: 1
    })
  }

  return (
    <div>
      <div className='mt-4'>
        <div>
          {variables._name && (
            <Chip
              label={variables._name}
              onDelete={() => handleChipDelete()}
              className={classes.chip}
            />
          )}
        </div>
        <div className='d-flex justify-content-between align-items-end table-top-bar'>
          <div className='d-flex align-items-center filters'>
            <Form className='mt-2 mt-sm-0' onSubmit={e => handleSearch(e)}>
              <FormGroup className='d-inline-block gift-search-input'>
                <Input
                  type='text'
                  name='value'
                  id='questionaire-search'
                  maxLength={10}
                  placeholder={t('search')}
                  style={{ paddingRight: '40px' }}
                />
                <button type='submit' className='gift-search-icon'>
                  <i className='zmdi zmdi-search centrify-blue-color'></i>
                </button>
              </FormGroup>
            </Form>
          </div>
          <Link
            to={'/addtreequestionnaire'}
            color='none'
            className='primary-background-button-table font-weight-bold'
          >
            {t('create_questionnaire')}
          </Link>
        </div>

        <div className='mt-4' style={{ position: 'relative' }}>
          {questionaire.loading && <TableLoader />}
          <Table responsive className='main-table'>
            <thead className='main-table-header'>
              <tr>
                <th>
                  <span className='ml-4 mb-0 d-flex'>{t('title')}</span>
                </th>
                <th>{t('created_time')}</th>
                <th>{t('created_at')}</th>
                <th>{t('total_questions')}</th>
                <th>{t('total_allotments')}</th>
                <th>{t('total_attempts')}</th>
                {/* <th>{t("assign")}</th> */}
                <th>{t('update')}</th>
                <th>{t('delete')}</th>
              </tr>
            </thead>
            <tbody className='main-table-body'>{getQuestionaireList}</tbody>
          </Table>
        </div>

        {questionaire.treeQCount > 0 ? (
          <div className='mt-4 d-flex justify-content-center'>
            <Pagination
              count={Math.ceil(questionaire.treeQCount / 10)}
              page={page}
              onChange={handlePageChange}
              variant='outlined'
              shape='rounded'
            />
          </div>
        ) : null}
      </div>
      <AssignToPatientModal
        {...props}
        treeQuestionnaireId={treeQuestionnaireId}
        modal={patOpen}
        patients={selectedPatient}
        toggle={() => {
          setPatOpen(!patOpen)
          setSelectedPatient([])
        }}
      />
      <ConfrimDialogue
        open={dialogue}
        handleClose={() => setDialogue(false)}
        action={() => handleDelete()}
        msg={t('are_you_sure_you_want_to_delete') + ' ?'}
      />
    </div>
  )
}
