import React from "react";

function EventComponent(props) {
  return (
    <span>
      <strong
        className=""
        onClick={() => {
          props.setInfoModal(!props.infoModal);
          props.setInfoModalData(props.event);
        }}
      >
        {props.event.title}
      </strong>
    </span>
  );
}

export default EventComponent;
