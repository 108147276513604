import React, { useState, useEffect } from 'react'
import { Button, Input } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import BreadCrumbComponent from 'components/Breadcrumbs'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import ErrorMessageComponents from 'components/ErrorHandlers/ErrorMessageComponents'
import CircularProgressComponent from 'components/ErrorHandlers/CircularProgressComponent'
import AddQuestion from './addQuesForm'
import EditQuestion from './editQuesForm'
import * as Actions from 'store/actions'

export default function EditQuestionaires (props) {
  const [questionsArray, setQuestionsArray] = useState([])
  const [deletedQuestions, setDeletedQuestions] = useState([])
  const [deletedOptions, setDeletedOptions] = useState([])
  const [questionaire, setQuestionaire] = useState(null)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false
  })

  const questionaireReducer = useSelector(({ questionaire }) => questionaire)

  useEffect(() => {
    dispatch(Actions.setQuestionairesLoading(true))
    dispatch(Actions.getQuestionaireByIdOnEdit(props.match.params.id))
  }, [props.match.params.id])

  useEffect(() => {
    setQuestionaire(questionaireReducer?.editQuestionaire)
    setQuestionsArray(questionaireReducer?.editQuestionaire?.questions)
  }, [questionaireReducer?.editQuestionaire])

  if (questionaire && questionaire.error) {
    return <ErrorMessageComponents message='Cannot get questionnaire details' />
  }

  if (
    !questionaire ||
    questionaireReducer.loading ||
    questionaire.id !== Number(props.match.params.id)
  ) {
    return <CircularProgressComponent />
  }

  const handleSubmit = e => {
    e.preventDefault()
    let obj = {}
    const formData = new FormData(e.target)
    for (let [key, value] of formData.entries()) {
      obj[key] = value
    }
    obj.questions = questionsArray
    obj.id = props.match.params.id
    obj.status = 'done'
    obj.deleteOptions = deletedOptions
    obj.deleteQuestions = deletedQuestions
    console.log(obj)
    dispatch(Actions.setQuestionairesLoading(true))
    dispatch(
      Actions.updateQuestionaire(obj, props.match.params.id, Auth.vendor_id)
    )
  }
  const handleRemoveOption = idsArray => {
    console.log(idsArray)
    setDeletedOptions([...deletedOptions, ...idsArray])
  }

  const handleRemoveQuestion = (id, index) => {
    setDeletedQuestions([...deletedQuestions, id])
    let newData = questionsArray
    newData.splice(index, 1)
    setQuestionsArray([...newData])
  }

  return (
    <div className='notifications'>
      <div className='mt-4'>
        <form onSubmit={e => handleSubmit(e)} className=''>
          <div className='d-flex justify-content-between'>
            <BreadCrumbComponent
              active={t('update_questionnaire')}
              previous={[
                { name: t('all_questionnaires'), link: '/questionnaires' }
                // { name: t("details"), link: `/questionnaire/${props.match.params.id}` },
              ]}
            />
            <div className='text-right mb-5'>
              {questionaireReducer.loading ? (
                <CircularProgress />
              ) : (
                <Button
                  color='none'
                  className='primary-background-button-table font-weight-bold'
                >
                  {t('update_questionnaire')}
                </Button>
              )}
            </div>
          </div>
          <div className='rct-block pd-top-80'>
            <div className='d-flex justify-content-between align-items-center border-bottom-centrify p-4'>
              <div className='d-flex flex-column w-100'>
                <input
                  type='text'
                  name='title'
                  placeholder='Enter Title'
                  defaultValue={questionaire?.title}
                  required
                  className='w-100 border-0 bg-transparent questionaire-title font-weight-bold'
                  required
                />
                <input
                  type='textarea'
                  name='shortDescription'
                  defaultValue={questionaire?.shortDescription}
                  placeholder='Short Description (optional)'
                  className='w-100 border-0 bg-transparent mt-5'
                  rows={4}
                />
              </div>
            </div>
          </div>
        </form>
        <div className='row p-3'>
          <AddQuestion
            questionsArray={questionsArray}
            setQuestionsArray={setQuestionsArray}
          />
        </div>
        {questionsArray.length > 0 && (
          <>
            {questionsArray.map((ques, index) => (
              <EditQuestion
                questionsArray={questionsArray}
                setQuestionsArray={setQuestionsArray}
                ques={ques}
                index={index}
                handleRemoveOption={handleRemoveOption}
                handleRemoveQuestion={handleRemoveQuestion}
              />
              //         <div className="rct-block mt-5">
              //             <div className="d-flex flex-column p-4">
              //                 <div className="d-flex justify-content-between">
              //                     <h3 className="main-heading font-weight-bold d-flex">Q{index + 1}:{'  '}{ques.title}</h3>
              //                     <img
              //                         src={require("assets/icons/remove.png")}
              //                         alt=""
              //                         className="centrify-table-icons cursor-pointer"
              //                         onClick={() => handleRemoveQuestion(index)}
              //                     />
              //                 </div>
              //                 {ques.questionType === 'TEXT' ? null
              //                     // <p className='font-14 mt-4 lead'>
              //                     //     {/* {ques ?.answer} */}
              //                     // </p>
              //                     :
              //                     <div className='d-flex flex-column mt-4'>
              //                         {ques.options.map(op => (
              //                             <div className='mx-2'>
              //                                 <input type='checkbox' disabled name='type' style={{ transform: "scale(1.5)" }} id={op.text} value={op.text} defaultChecked={op.status} />
              //                                 <label for='item' className='ml-4 font-14 lead'>
              //                                     {op.option}
              //                                 </label>
              //                             </div>
              //                         ))}
              //                     </div>
              //                 }
              //             </div>
              //         </div>
            ))}
          </>
        )}
      </div>
    </div>
  )
}
