import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import BreadCrumbComponent from "components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { AiFillEye } from "react-icons/ai";
import SingleQuestion from "./singleQuestionForm";
import TreeDialog from "../treeDialog.js";
import { NotificationManager } from "react-notifications";
import * as Actions from "store/actions";
import "./index.css";
import { v4 as uuidv4 } from "uuid";
import RecommendModal from "./recommendModal/recommendModal";
import ProductDetailModal from "./recommendDetailModal";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";

export default function Questionaires(props) {
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // Questionnaire Header Values ---> Title,description,greeting message
  const [questionnaireHeaderVals, setQuestionnaireHeaderVals] = useState(null);
  // Recommend Modal
  const [recommendModal, setRecommendModal] = useState(false);
  const [recommendDetailModal, setRecommendDetailModal] = useState({
    state: false,
    type: "product",
    id: null,
  });

  const [selectedQuesOptIndexes, setSelectedQuesOptIndexes] = useState({
    qIndex: 0,
    optIndex: 0,
  });

  // Question Add Delete sidebar
  const [activeActionsTabIndex, setActiveActionsTabIndex] = useState(0);
  const [questions, setQuestions] = useState([
    {
      UUID: uuidv4(),
      title: "",
      questionType: "MCQ",
      answerUniqueId: uuidv4(),
      options: [
        {
          option: "",
          value: false,
          important: false,
          answerUniqueId: uuidv4(),
          recommendModalType: "product",
        },
        {
          option: "",
          value: false,
          important: false,
          answerUniqueId: uuidv4(),
          recommendModalType: "product",
        },
      ],
    },
  ]);
  const [treeDialog, setTreeDialog] = useState(false);
  const [pageType, setPageType] = useState("add");

  useEffect(() => {
    if (props.match.params.id) {
      dispatch(Actions.setTreeQuestionairesLoading(true));
      dispatch(Actions.getSingleTreeQuestionnaire(props.match.params.id));
    }
  }, [props.match.params.id]);

  const questionaireReducer = useSelector(({ questionaire }) => questionaire);

  useEffect(() => {
    let treeQuestionnaire = questionaireReducer.treeQuestionnaire;
    // Update Questionnaire Case
    if (props.match.params.id) {
      setPageType("update");
    }
    if (treeQuestionnaire && props.match.params.id) {
      if (treeQuestionnaire.headerVals) {
        setQuestionnaireHeaderVals(treeQuestionnaire.headerVals);
      }
      // QuestionsArray is adjusted in respective reducer
      setQuestions([...treeQuestionnaire.questions]);
    }
  }, [questionaireReducer]);
  // Question linktype checking as the question could be of three types
  const linkedTypeCheckerQuestion = (obj) => {
    console.log("Obj ---->", obj);
    let linkType = 3;
    if (obj.answer) {
      linkType = 0;
    }
    if (obj.linkedToQuestion && obj.linkedToQuestion != "null") {
      linkType = 1;
    }
    if (obj.answer && obj.linkedToQuestion && obj.linkedToQuestion != "null") {
      linkType = 2;
    }
    return linkType;
  };
  // Option LinkType check
  const linkedTypeCheckerOption = (obj) => {
    let linkType = 3;
    if (obj.answer) {
      linkType = 0;
    }
    if (obj.linkedTo && obj.linkedTo != "null") {
      linkType = 1;
    }
    if (obj.answer && obj.linkedTo && obj.linkedTo != "null") {
      linkType = 2;
    }
    return linkType;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let obj = {};
    const formData = new FormData(e.target);
    for (let [key, value] of formData.entries()) {
      obj[key] = value;
    }

    if (questions.length == 1 && questions[0].title.length == "0") {
      NotificationManager.error(t("please_add_at_least_one_question"));
    } else {
      let finalQuestions = questions.map((question) => {
        if (question.options && question.options.length > 0) {
          question.options.map((option) => {
            let type = linkedTypeCheckerOption(option);
            option.linkedType = type;
            // Linked to no question has the value null in string as linkedTo picker requires a string value --> setting to actual null
            option.linkedTo =
              option.linkedTo == "null" ? null : option.linkedTo;
            return option;
          });
        } else {
          let type = linkedTypeCheckerQuestion(question);
          question.linkedToQuestion =
            question.linkedToQuestion == "null"
              ? null
              : question.linkedToQuestion;
          question.linkedType = type;
        }
        return question;
      });

      obj.questions = finalQuestions;
      obj.completionStatus = "done";

      if (pageType == "add") {
        dispatch(Actions.setQuestionairesLoading(true));
        dispatch(Actions.addTreeQuestionaire(obj, Auth.vendor_id, Auth.id));
      } else {
        if (obj.title.includes("Version")) {
          let version = obj.title.split("-").pop();
          let dashIndex = obj.title.lastIndexOf("-");
          let subString = obj.title.substring(0, dashIndex);
          let versionUpdated = parseInt(version) + 1;
          obj.title = subString + "-" + versionUpdated;
        } else {
          obj.title += " Version-1";
        }
        let questions = [...obj.questions];
        // Changing all UUID's to new in update as required by backend
        let updatedQuestions = questions.map((question, mainIndex) => {
          let questionId = question.UUID;
          let updatedQuestionId = uuidv4();
          let updatedAnswerUniqueId = uuidv4();
          questions.map((subQuestion, index) => {
            if (mainIndex != index) {
              if (subQuestion.questionType == "MCQ") {
                subQuestion.options.map((option) => {
                  if (option.linkedTo == questionId) {
                    option.linkedTo = updatedQuestionId;
                  }
                  option.answerUniqueId = uuidv4();
                  delete option.id;
                });
              } else {
                if (subQuestion.linkedToQuestion == questionId) {
                  subQuestion.linkedToQuestion = updatedQuestionId;
                }
              }
            } else {
              if (subQuestion.questionType == "MCQ") {
                subQuestion.options.map((option) => {
                  option.answerUniqueId = uuidv4();
                  delete option.id;
                  if (option.product && option.product.length > 0) {
                    option.product[0].UUID = uuidv4();
                  }
                });
              }
            }
            subQuestion.answerUniqueId = uuidv4();
            return subQuestion;
          });
          question.UUID = updatedQuestionId;
          question.answerUniqueId = updatedAnswerUniqueId;
          delete question.id;
          return question;
        });

        obj.questions = updatedQuestions;
        dispatch(Actions.setQuestionairesLoading(true));
        dispatch(Actions.addTreeQuestionaire(obj, Auth.vendor_id, Auth.id));
      }
    }
  };

  const handleRemoveQuestion = (index) => {
    if (index) {
      let removedElement = questions.splice(index, 1);
      let removedElementUUID = removedElement[0].UUID;

      // Setting question linkedTo value to null which were linked to the question which is removed.
      // So the option dropdown get back to default state.
      if (questions) {
        let updatedquestionArray = questions.map((question, index) => {
          if (question.linkedToQuestion == removedElementUUID) {
            question.linkedToQuestion = null;
          }
          // Checking if any of the option is linked to the removed question --- setting it to null
          if (question.options) {
            question.options.map((option, index) => {
              if (option.linkedTo == removedElementUUID) {
                option.linkedTo = null;
              }
              return option;
            });
          }
          return question;
        });
        if (updatedquestionArray.length == 1) {
          setActiveActionsTabIndex(0);
        }
        setQuestions([...updatedquestionArray]);
      }
    }
  };

  const recommendModalToggle = () => {
    setRecommendModal(!recommendModal);
  };

  const recommentDetailModalToggle = (id, modalType) => {
    setRecommendDetailModal({
      ...recommendDetailModal,
      id: id ? id : recommendDetailModal.id,
      state: !recommendDetailModal.state,
      type: modalType ? modalType : recommendDetailModal.type,
    });
  };

  if (questionaireReducer && questionaireReducer.error) {
    return (
      <ErrorMessageComponents
        message={t("cannot_fetch_questionnaire_detail")}
      />
    );
  }

  if (questionaireReducer === null || questionaireReducer.loading) {
    return <CircularProgressComponent />;
  }

  return (
    <div className="notifications ">
      <div className="mt-4">
        <form onSubmit={(e) => handleSubmit(e)} className="">
          <div className="d-flex justify-content-between">
            <BreadCrumbComponent
              active={
                pageType == "add"
                  ? t("add_questionnaire")
                  : t("update_questionnaire")
              }
              previous={[
                { name: t("all_questionnaires"), link: "/treeQuestionnaires" },
              ]}
            />
            <div className="text-right mb-5">
              {questionaireReducer.loading ? (
                <CircularProgress />
              ) : (
                <>
                  <Button
                    type="button"
                    color="none"
                    className="primary-background-button-table font-weight-bold mb-5 mr-4 p-2 px-4"
                    onClick={() => {
                      setTreeDialog(true);
                    }}
                  >
                    <AiFillEye style={{ fontSize: "18px" }} />
                  </Button>
                  <Button
                    color="none"
                    className="primary-background-button-table font-weight-bold mb-5"
                    type="submit"
                  >
                    {pageType == "add"
                      ? t("create_questionnaire")
                      : t("update_questionnaire")}
                  </Button>
                  <TreeDialog
                    dialogState={treeDialog}
                    questions={questions}
                    toggle={() => {
                      setTreeDialog(!treeDialog);
                    }}
                  />
                </>
              )}
            </div>
          </div>

          <div className="rct-block pd-top-80">
            <div className="d-flex justify-content-between align-items-center border-bottom-centrify p-4">
              <div className="d-flex flex-column w-100">
                <input
                  type="text"
                  name="title"
                  placeholder={t("enter_title")}
                  required
                  className="w-100 border-0 bg-transparent questionaire-title font-weight-bold"
                  value={questionnaireHeaderVals?.title || null}
                  onChange={(e) => {
                    setQuestionnaireHeaderVals({
                      ...questionnaireHeaderVals,
                      title: e.target.value,
                    });
                  }}
                />
                <input
                  type="textarea"
                  name="shortDescription"
                  placeholder={t("short_description")}
                  className="w-100 border-0 bg-transparent mt-5"
                  rows={4}
                  required
                  value={questionnaireHeaderVals?.description || null}
                  onChange={(e) => {
                    setQuestionnaireHeaderVals({
                      ...questionnaireHeaderVals,
                      description: e.target.value,
                    });
                  }}
                />
                <input
                  type="textarea"
                  name="greetingMsg"
                  placeholder={t("greeting_message")}
                  className="w-100 border-0 bg-transparent mt-5"
                  rows={4}
                  required
                  value={questionnaireHeaderVals?.greetingMsg || null}
                  onChange={(e) => {
                    setQuestionnaireHeaderVals({
                      ...questionnaireHeaderVals,
                      greetingMsg: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </form>

        {questions.length > 0 && (
          <>
            {questions.map((question, index) => (
              <SingleQuestion
                quesIndex={index}
                questions={questions}
                question={question}
                setQuestions={setQuestions}
                handleRemoveQuestion={handleRemoveQuestion}
                activeActionsTabIndex={activeActionsTabIndex}
                setActiveActionsTabIndex={setActiveActionsTabIndex}
                recommendModalToggle={recommendModalToggle}
                recommentDetailModalToggle={recommentDetailModalToggle}
                setSelectedQuesOptIndexes={setSelectedQuesOptIndexes}
              />
            ))}
          </>
        )}
      </div>
      <RecommendModal
        {...props}
        modal={recommendModal}
        toggle={recommendModalToggle}
        selectedQuesOptIndexes={selectedQuesOptIndexes}
        setQuestions={setQuestions}
        questions={questions}
      />
      <ProductDetailModal
        {...props}
        recommendDetailModal={recommendDetailModal}
        id={recommendDetailModal.id}
        type={recommendDetailModal.type}
        modal={recommendDetailModal.state}
        toggle={recommentDetailModalToggle}
        selectedQuesOptIndexes={selectedQuesOptIndexes}
        setQuestions={setQuestions}
        questions={questions}
      />
    </div>
  );
}
