import React from "react";
import { useDispatch } from "react-redux";

import { Modal, ModalBody } from "reactstrap";
import { useTranslation } from "react-i18next";

const InfoModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let getModalInfo = () => {
    if (props.infoModalData) {
      return (
        <>
          <h1 className="mb-4">Activity Info</h1>
          <h4>Activity Name : {props.infoModalData.title}</h4>
        </>
      );
    }
  };
  return (
    <div>
      <Modal
        isOpen={props.modal}
        toggle={props.toggle}
        className="modal-background"
      >
        <ModalBody>
          <div className="p-4">
            <div className="d-flex justify-content-end">
              <img
                onClick={() => props.toggle()}
                className="centrify-icons cursor-pointer"
                src={require("assets/icons/close.png")}
              />
            </div>
            {getModalInfo()}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default InfoModal;
