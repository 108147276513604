import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Button } from "reactstrap";

const AddQuestion = ({ questionsArray, setQuestionsArray }) => {
    const [type, setType] = useState("MCQ");
    const [options, setOptions] = useState([{ option: "", value: false, important: false }, { option: "", value: false, important: false }]);
    const { t } = useTranslation();
    const handleSubmit = (e) => {
        e.preventDefault();
        let obj = {};
        const formData = new FormData(e.target);
        for (let [key, value] of formData.entries()) {
            obj[key] = value;
        }
        obj.options = options;
        // questionsArray.push(obj);
        let newData = questionsArray;
        delete obj.option;
        newData.push(obj);
        setQuestionsArray([...newData]);
        document.getElementById("ques-title").value = "";
        if (obj.questionType !== 'TEXT') {
            options.map((el, index) => {
                document.getElementById(`option${index}`).value = "";
            });
            setOptions([{ option: "", value: false, important: false }, { option: "", value: false, important: false }]);
        }
        console.log(obj);
    };

    const handleRemoveOption = (index) => {
        let newOptions = options;
        newOptions.splice(index, 1);
        setOptions([...newOptions]);
    }



    return (
        <form onSubmit={(e) => handleSubmit(e)} className="w-100 rct-block p-3">
            <div className='row d-flex justify-content-center'>
                <div className="col-sm-12 col-md-9 d-flex justify-content-start align-items-center">
                    <h4 className="font-weight-bold text-black p-0 m-0">{`Q${questionsArray.length + 1}`}:</h4>
                    <input id="ques-title" type="textarea" name="title" className="w-100 border-0 bg-transparent questionaire-title font-weight-bold" placeholder={`Question ${questionsArray.length + 1} starts here...`} rows={1} required />
                </div>
                <div className="col-sm-12 col-md-3">
                    <Input type="select" name="questionType" defaultValue="MCQ" onChange={(e) => setType(e.target.value)} required>
                        <option value="MCQ">Mcq</option>
                        <option value="TEXT">Text</option>
                        <option value="MultiSelect">MultiSelect</option>
                    </Input>
                </div>
                {type === 'MCQ' || type === 'MultiSelect' ? <>
                    <div className="col-sm-12 mt-4 d-flex flex-column align-items-start">
                        {options.map((el, index) => (
                            <div className="d-flex w-75 flex-row justify-content-center align-items-center">
                                <div className="p-0 mt-3">{index < 9 ? <>{`0${index + 1}`}</> : <>{index + 1}</>}-</div>
                                <input
                                    type="text"
                                    className="w-100 border-0 bg-transparent mt-3 ml-2"
                                    placeholder={`option ${index + 1}`}
                                    name="option"
                                    id={`option${index}`}
                                    value={`${el.option}`}
                                    required
                                    onChange={(e) => {
                                        options[index].option = e.target.value;
                                        setOptions([...options]);
                                    }}
                                />
                                {index > 1 ?
                                    <img
                                        src={require("assets/icons/close.png")}
                                        onClick={() => handleRemoveOption(index)}
                                        alt=""
                                        className="ques-option-icon cursor-pointer p-0 mt-3"
                                    /> : null}
                                <div className="p-0 m-0" id={`TooltipExample`}>
                                    {options[index].important ?
                                        <img
                                            src={require("assets/icons/filled-star.png")}
                                            onClick={(e) => {
                                                options[index].important = !(options[index].important);
                                                setOptions([...options]);
                                            }}
                                            alt=""
                                            className="ques-option-icon cursor-pointer p-0 mt-3"
                                        /> : <img
                                            src={require("assets/icons/Unfilled-star.png")}
                                            onClick={(e) => {
                                                options[index].important = !(options[index].important);
                                                setOptions([...options]);
                                            }}
                                            alt=""
                                            className="ques-option-icon cursor-pointer p-0 mt-3"
                                        />}
                                    {/* <Tooltip placement="right" isOpen={tooltipOpen} target={`TooltipExample${el.id}`} toggle={() => setTooltipOpen(!tooltipOpen)}>

                                        {options[index].important ? <> Remove Important!</> : <>Make Important</>}
                                    </Tooltip> */}
                                </div>
                            </div>
                        ))}
                        <div className='text-underlined mt-3 link-button cursor-pointer' onClick={() => setOptions([...options, { option: "", value: false, important: false }])}><u>add another option</u></div>
                    </div>
                </> : null}
                <div className="col-sm-12 mt-4 d-flex justify-content-end">
                    <Button
                        color="none"
                        className="primary-background-button-table font-weight-bold"
                    >
                        {t("")}Add
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default AddQuestion;
