/**
 * AsyncComponent
 * Code Splitting Component / Server Side Rendering
 */
import React from "react";
import Loadable from "react-loadable";

// rct page loader
import RctPageLoader from "components/RctPageLoader/RctPageLoader";
import PreloadTable from "components/PreloadLayout/PreloadTable";

// ecommerce dashboard
const AsyncEcommerceDashboardComponent = Loadable({
  loader: () => import("routes/dashboard"),
  loading: () => <RctPageLoader />,
});
const AsyncProjectsListComponent = Loadable({
  loader: () => import("routes/projects"),
  loading: () => <PreloadTable />,
});
const AsyncManageProjectComponent = Loadable({
  loader: () => import("routes/projects/manageProjects"),
  loading: () => <PreloadTable />,
});
const AsyncEmployeesList = Loadable({
  loader: () => import("routes/employees"),
  loading: () => <PreloadTable />,
});
const AsyncPatientsList = Loadable({
  loader: () => import("routes/patients"),
  loading: () => <PreloadTable />,
});
const AsyncMedicineList = Loadable({
	loader: () => import("routes/medicines"),
	loading: () => <PreloadTable/>
})
export {
  AsyncEcommerceDashboardComponent,
  AsyncProjectsListComponent,
  AsyncManageProjectComponent,
  AsyncEmployeesList,
  AsyncPatientsList,
  AsyncMedicineList
};
