import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Actions from 'store/actions'
import { CircularProgress, IconButton } from '@material-ui/core'
import { Button, Modal, Label, ModalBody, Input } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import Domain from 'lib/Config'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import DeleteIcon from '@material-ui/icons/Delete'
import NotificationManager from 'react-notifications'
import FileDrop from 'react-dropzone'
import AddIcon from '@material-ui/icons/Add'

let categoryAxios = axios.create({})

delete categoryAxios.defaults.headers.common['Authorization']
const CategoryModal = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [image, setImage] = useState(null)
  const [imageLoading, setImageLoading] = useState(false)

  const [categoryForm, setCategoryForm] = useState({})
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false
  })

  const storeReducer = useSelector(({ store }) => store)

  useEffect(() => {
    dispatch(Actions.getStoreCategories(null, Auth.vendor_id))
  }, [])

  let handleSubmit = e => {
    e.preventDefault()
    let obj = {
      ...categoryForm
    }
    obj.image = image

    obj.vendor_id = Auth.vendor_id
    console.log('Obj @@@ --->', obj)
    dispatch(Actions.setStoreStateLoading(true))
    if (props.categoryModalType == 'add') {
      dispatch(Actions.addStoreCategory(obj))
    } else {
      dispatch(Actions.updateStoreCategory(obj, obj.id, Auth.vendor_id))
    }
  }
  console.log('categoryForm --->', categoryForm)
  console.log('image --->', image)
  console.log(' props.categoryModalType -->', props.categoryModalType)

  const onDrop = useCallback(
    img => {
      setImageLoading(true)
      handleFileUpload(img[0])
    },
    [image]
  )

  const removeImg = id => {
    setImage(null)
  }

  const handleFileUpload = data => {
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
      },
      header: {
        'Content-Type': data.type
      }
    }
    getSignedURL(data.name, data.type).then(rsp => {
      categoryAxios
        .put(rsp.data.result.postUrl, data, config)
        .then(res => {
          //   let image = {
          //     id: uuidv4(),
          //     File: rsp.data.result.getUrl
          //   }
          console.log('res ---->', rsp)
          console.log('res --->', res)
          setImage(rsp.data.result.getUrl)
          setImageLoading(false)
        })
        .catch(err => {
          setImageLoading(false)
          NotificationManager.error(`${t('error_something_went_wrong')}`)
          console.log(err.response)
        })
    })
  }

  const getSignedURL = (key, type) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Domain}/api/vendor/s3getsignedurl?key=${key}&type=${type}`)
        .then(data => {
          resolve(data)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  }

  useEffect(() => {
    if (props.categoryModalType == 'update') {
      setImage(props.category?.image)
      setCategoryForm({ ...props.category })
    } else {
      setCategoryForm(null)
    }
  }, [props.categoryModalType, props.modal, props.category])

  return (
    <div>
      <Modal
        isOpen={props.modal}
        toggle={props.toggle}
        className='modal-background'
      >
        <ModalBody>
          <div className='p-4'>
            <div className='d-flex justify-content-end'>
              <img
                onClick={() => props.toggle()}
                className='centrify-icons cursor-pointer'
                src={require('assets/icons/close.png')}
              />
            </div>
            <div className='mt-2 form-styles'>
              <h2 className='form-heading d-flex'>
                {props.categoryModalType == 'add'
                  ? t('create_new_category')
                  : t('update_category')}
              </h2>
              <form onSubmit={e => handleSubmit(e)}>
                <div className='mt-4'>
                  <Label className='d-flex'>{t('category_title')}</Label>
                  <Input
                    type='text'
                    name='title'
                    required
                    value={categoryForm?.title}
                    onChange={e => {
                      setCategoryForm({
                        ...categoryForm,
                        title: e.target.value
                      })
                    }}
                  />
                </div>

                <div className='mt-4'>
                  <Label className='d-flex'>{t('ID')}</Label>
                  <Input
                    type='text'
                    name='id'
                    required
                    value={categoryForm?.Category_Id}
                    onChange={e => {
                      setCategoryForm({
                        ...categoryForm,
                        Category_Id: e.target.value
                      })
                    }}
                  />
                </div>

                <div className='mt-4'>
                  <Label className='d-flex'>{t('description')}</Label>
                  <Input
                    type='textarea'
                    name='title'
                    value={categoryForm?.description}
                    onChange={e => {
                      setCategoryForm({
                        ...categoryForm,
                        description: e.target.value
                      })
                    }}
                    rows={4}
                    style={{
                      resize: 'none'
                    }}
                  />
                </div>
                <div className='mt-4'>
                  <div className='row'>
                    {image ? (
                      <div
                        className='col-md-4 mb-2 animate__animated animate__fadeIn'
                        key={image.id}
                      >
                        <div className='img-div d-flex flex-column'>
                          <img src={image} width='100%' alt='qwe' />
                        </div>
                        <div className='del-img'>
                          <IconButton
                            color='inherit'
                            mini='true'
                            className='p-0'
                            onClick={() => removeImg(image)}
                          >
                            <DeleteIcon fontSize='small' />
                          </IconButton>
                        </div>
                      </div>
                    ) : (
                      <div className='col-md-4'>
                        <FileDrop
                          disabled={imageLoading}
                          accept='image/png, image/gif, image/jpeg, image/bmp,'
                          multiple
                          onDrop={onDrop}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className='img-div'>
                              <IconButton
                                color='inherit'
                                mini='true'
                                aria-label='Menu'
                                className='p-0'
                              >
                                {imageLoading ? (
                                  <CircularProgress
                                    style={{ height: '10px', width: '10px' }}
                                  />
                                ) : (
                                  <AddIcon fontSize='small' />
                                )}
                              </IconButton>
                              <input {...getInputProps()} />
                            </div>
                          )}
                        </FileDrop>
                      </div>
                    )}
                  </div>
                </div>

                <div className='mt-4 d-flex justify-content-end'>
                  {storeReducer.stateLoading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      className='primary-background-button-table'
                      color='none'
                    >
                      {props.categoryModalType == 'add'
                        ? t('create')
                        : t('update')}
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default CategoryModal
