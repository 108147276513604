import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "store/actions";
import { CircularProgress } from "@material-ui/core";
import { Button, Modal, Label, ModalBody, Input } from "reactstrap";
import { useTranslation } from "react-i18next";

const EngagementModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const engagementReducer = useSelector(({ engagement }) => engagement);
  const [engagementForm, setEngagementForm] = useState({});
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });

  let handleSubmit = (e) => {
    e.preventDefault();
    let obj = {
      title: engagementForm.title,
      duration: engagementForm.duration,
    };
    dispatch(Actions.setEngagementStateLoading(true));
    if (props.engagementModalType == "add") {
      dispatch(Actions.addEngagement(obj, Auth.id, Auth.vendor_id));
    } else {
      let id = props.engagement.id;
      dispatch(Actions.updateEngagement(obj, Auth.id, id, Auth.vendor_id));
    }
    setEngagementForm({ title: null, duration: null });
  };
  useEffect(() => {
    if (props.engagementModalType == "update") {
      setEngagementForm({
        title: props.engagement?.title,
        duration: props.engagement?.duration,
      });
    } else {
      setEngagementForm({ title: null, duration: null });
    }
  }, [props.engagementModalType, props.engagement]);

  return (
    <div>
      <Modal
        isOpen={props.modal}
        toggle={props.toggle}
        className="modal-background"
      >
        <ModalBody>
          <div className="p-4">
            <div className="d-flex justify-content-end">
              <img
                onClick={() => props.toggle()}
                className="centrify-icons cursor-pointer"
                src={require("assets/icons/close.png")}
              />
            </div>
            <div className="mt-2 form-styles">
              <h2 className="form-heading d-flex">
                {props.engagementModalType == "add"
                  ? t("create_new_engagement")
                  : t("update_engagement")}
              </h2>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="mt-4">
                  <Label className="d-flex">{t("name")}</Label>
                  <Input
                    type="text"
                    name="title"
                    required
                    value={engagementForm?.title}
                    onChange={(e) => {
                      setEngagementForm({
                        ...engagementForm,
                        title: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="mt-4">
                  <Label className="d-flex">{t("no_of_days")}</Label>
                  <Input
                    type="number"
                    min="1"
                    max="365"
                    name="duration"
                    required
                    value={engagementForm?.duration}
                    onChange={(e) => {
                      setEngagementForm({
                        ...engagementForm,
                        duration: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="mt-4 d-flex justify-content-end">
                  {engagementReducer.stateloading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      className="primary-background-button-table"
                      color="none"
                    >
                      {props.engagementModalType == "add"
                        ? t("create")
                        : t("update")}
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default EngagementModal;
