import Domain from "lib/Config";
import axios from "axios";
import { NotificationManager } from "react-notifications";

export const GET_PATIENT_INVENTORY = "[ADMIN] GET_PATIENT_INVENTORY";
export const INVENTORY_LOADING = "[ADMIN] INVENTORY_LOADING";
export const INVENTORY_ERROR = "[ADMIN] INVENTORY_ERROR";

export function getPatientInventory(data, patient_id) {
  data.page = data.page - 1;
  const request = axios.post(`${Domain}/api/patient/getPatientinventory`, {
    ...data,
    patient_id,
    limit: 10,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return dispatch({
          type: GET_PATIENT_INVENTORY,
          payload: response.data.result,
        });
      })
      .catch((error) => {
        console.log(error.response);
        dispatch(setInventoryLoading(false));
        return dispatch({
          type: INVENTORY_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get inventory",
        });
      });
}

export function setInventoryLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: INVENTORY_LOADING,
      payload: val,
    });
  };
}
