import React, { Component } from "react";
import jwt from "jsonwebtoken";
import { connect } from "react-redux";
import History from "@history";
import moment from "moment";
import * as Actions from "store/actions";
import qs from "query-string";
import MeetingsToday from "./meetingsToday";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";

class JitsiComponent extends Component {
  querystr = qs.parse(this.props.location.search);
  domain = "centrefy.co";
  api = {};

  constructor(props) {
    super(props);
    this.state = {
      showComp: false,
      room: this.props.match?.params?.activityId,
      user: {
        name: "Dr",
      },
      isAudioMuted: false,
      isVideoMuted: false,
    };
  }

  authToken = async () => {
    const tokenn = await jwt.sign(
      {
        aud: "438D0",
        iss: "438D0",
        sub: "infinitybitsapp.xyz",
        room: this.props.match?.params?.activityId,
      },
      "411776869D75586569571D23A976C7A6",
      { algorithm: "HS256", expiresIn: "300 days" }
    );
    if (tokenn) {
      this.setState({ ...this.state, token: tokenn });
    }
  };

  startMeet = async () => {
    console.log(
      "this.props.match?.params?.activityId --->",
      this.props.match?.params?.activityId
    );
    const tokenn = await jwt.sign(
      {
        aud: "438D0",
        iss: "438D0",
        sub: "infinitybitsapp.xyz",
        room: this.props.match?.params?.activityId,
      },
      "411776869D75586569571D23A976C7A6",
      { algorithm: "HS256", expiresIn: "300 days" }
    );
    const myOverwrite = {
      TOOLBAR_BUTTONS: [
        "microphone",
        "camera",
        // "closedcaptions",
        "desktop",
        "fullscreen",
        // "fodeviceselection",
        "hangup",
        // "profile",
        // "recording",
        // "livestreaming",
        // "etherpad",
        // "sharedvideo",
        "settings",
        // "raisehand",
        // "videoquality",
        "filmstrip",
        // "invite",
        // "feedback",
        // "stats",
        // "shortcuts",
        // "tileview",
        "videobackgroundblur",
        "download",
        // "help",
        // "mute-everyone",
        // "security",
      ],
    };
    const options = {
      roomName: this.state.room,
      width: "100%",
      height: 500,
      configOverwrite: {
        prejoinPageEnabled: false,
        remoteVideoMenu: {
          disableKick: true,
        },
      },
      jwt: tokenn,
      interfaceConfigOverwrite: myOverwrite,
      // interfaceConfigOverwrite: {
      //   // overwrite interface properties
      // },
      parentNode: document.querySelector("#jitsi-iframe"),
      userInfo: {
        displayName:
          this.props.auth.user.firstName + " " + this.props.auth.user.lastName,
        email: this.props.auth.user.email,
        avatarUrl: this.props.auth.user.image,
        avatarURL: this.props.auth.user.image,
        avatar: this.props.auth.user.image,
        image: this.props.auth.user.image,
      },
    };
    this.api = new window.JitsiMeetExternalAPI(this.domain, options);

    this.api.addEventListeners({
      readyToClose: this.handleClose,
      participantLeft: this.handleParticipantLeft,
      participantJoined: this.handleParticipantJoined,
      videoConferenceJoined: this.handleVideoConferenceJoined,
      videoConferenceLeft: this.handleVideoConferenceLeft,
      audioMuteStatusChanged: this.handleMuteStatus,
      videoMuteStatusChanged: this.handleVideoStatus,
    });
  };

  handleClose = () => {
    console.log("handleClose closed ");
    console.log("handleClose called @@@@@@@@@@@@@@@ ------ ");
    this.handleEndMeeting();
  };

  handleEndMeeting = () => {
    const dataa = {
      activity_id: this.props.match.params.activityId,
      patient_id: this.props.match.params.patientId,
      datePerformed: moment(new Date()).utc().format("YYYY-MM-DD HH:mm:ss"),
      tz: this.querystr.tz,
      inventory_id: null,
    };
    console.log("Handle end meeting ------- ");
    console.log("this.props.auth", this.props.auth);
    this.props.setActivityLoading(true);
    this.props.meetingActivityAcknowledgement(dataa, this.props.auth.user.id);

    // this.props.dispatch(Actions.setActivityLoading(true));
    // this.props.dispatch(Actions.meetingActivityAcknowledgement(dataa, Auth.id));
    History.push("/meeting");
  };

  handleParticipantLeft = async (participant) => {
    console.log("handleParticipantLeft", participant);
    const data = await this.getParticipants();
  };

  handleParticipantJoined = async (participant) => {
    console.log("handleParticipantJoined", participant);
    const data = await this.getParticipants();
  };

  handleVideoConferenceJoined = async (participant) => {
    console.log("handleVideoConferenceJoined", participant);
    const data = await this.getParticipants();
  };

  handleVideoConferenceLeft = () => {
    console.log("handleVideoConferenceLeft @@@@@@");

    // return this.props.history.push("/meeting");
  };

  handleMuteStatus = (audio) => {
    console.log("handleMuteStatus", audio); // { muted: true }
  };

  handleVideoStatus = (video) => {
    console.log("handleVideoStatus", video); // { muted: true }
  };

  getParticipants() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.api.getParticipantsInfo()); // get all participants
      }, 500);
    });
  }

  // custom events
  executeCommand(command) {
    this.api.executeCommand(command);
    if (command == "hangup") {
      return this.props.history.push("/thank-you");
    }

    if (command == "toggleAudio") {
      this.setState({ isAudioMuted: !this.state.isAudioMuted });
    }

    if (command == "toggleVideo") {
      this.setState({ isVideoMuted: !this.state.isVideoMuted });
    }
  }

  componentDidMount() {
    if (window.JitsiMeetExternalAPI) {
      this.startMeet();
      this.authToken();
      console.log("this.auth ---->", this.props.auth);
    } else {
      alert("JitsiMeetExternalAPI not loaded");
    }
  }

  render() {
    const { isAudioMuted, isVideoMuted } = this.state;
    return (
      <>
        <>
          <div id="jitsi-iframe"></div>
          <div className="col-12 col-lg-4 px-4 justify-content-center align-items-center text-center">
            <MeetingsToday />
          </div>
        </>

        {/* <div class="item-center">
          <span>Custom Controls</span>
        </div>
        <div class="item-center">
          <span>&nbsp;&nbsp;</span>
          <i
            onClick={() => this.executeCommand("toggleAudio")}
            className={`fas fa-2x grey-color ${
              isAudioMuted ? "fa-microphone-slash" : "fa-microphone"
            }`}
            aria-hidden="true"
            title="Mute / Unmute"
          ></i>
          <i
            onClick={() => this.executeCommand("hangup")}
            className="fas fa-phone-slash fa-2x red-color"
            aria-hidden="true"
            title="Leave"
          ></i>
          <button
            onClick={() => this.executeCommand("toggleVideo")}
            className={`fas fa-2x text-primary ${
              isVideoMuted ? "fa-video-slash" : "fa-video"
            }`}
            aria-hidden="true"
            title="Start / Stop camera"
          >
            Test{" "}
          </button>
          <i
            onClick={() => this.executeCommand("toggleShareScreen")}
            className="fas fa-film fa-2x grey-color"
            aria-hidden="true"
            title="Share your screen"
          ></i>
        </div> */}
      </>
    );
  }
}

function mapStateToProps(state) {
  const auth = state.auth;
  return {
    auth,
  };
}

function mapDispatchToEvents(dispatch) {
  return {
    setActivityLoading: (value) => {
      dispatch(Actions.setActivityLoading(value));
    },
    meetingActivityAcknowledgement: (data, id) => {
      dispatch(Actions.meetingActivityAcknowledgement(data, id));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToEvents)(JitsiComponent);
