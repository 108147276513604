import { CircularProgress } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody, Label, Input } from "reactstrap";
import * as Actions from "store/actions";

const SelectProject = (props) => {
  const dispatch = useDispatch();
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });

  // const projectReducer = useSelector(({ project }) => project);
  const patientReducer = useSelector(({ patient }) => patient);
  const { t } = useTranslation();

  useEffect(() => {
    if (props.open && props.patientId) {
      dispatch(Actions.setPatientDocumentLoading(true));
      dispatch(Actions.getPatientProjects(props.patientId, {}));
    }
  }, [props]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let obj = {};
    const formData = new FormData(e.target);
    for (let [key, value] of formData.entries()) {
      obj[key] = value;
    }
    props.submitWithProjectId(obj.project_id);
    props.toggle();
  };

  const getPatientProjectList =
    patientReducer.patientProject &&
    patientReducer.patientProject.map((item) => {
      return (
        <option
          value={item.project_id}
          id={item.project_id}
          key={item.project_id}
        >
          {item.patientproject?.name}
        </option>
      );
    });

  return (
    <div>
      <Modal
        isOpen={props.open}
        toggle={props.toggle}
        className="modal-background"
      >
        <ModalBody>
          <div className="p-4">
            <div className="d-flex justify-content-end">
              <img
                onClick={() => props.toggle()}
                className="centrify-icons cursor-pointer"
                src={require("assets/icons/close.png")}
              />
            </div>
            <div className="mt-2 form-styles">
              <h2 className="form-heading d-flex">{t("assign")}</h2>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="mt-4">
                  <Label className="d-flex">{t("project")}</Label>
                  <Input type="select" name="project_id" required>
                    {getPatientProjectList}
                  </Input>
                </div>
                <div className="mt-4 d-flex justify-content-end">
                  {patientReducer.docLoading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      className="primary-background-button-table"
                      color="none"
                    >
                      {t("select") + " " + t("project")}
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SelectProject;
