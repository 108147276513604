import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "store/actions";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import History from "@history";
import "./notiStyles.css";

const NotificationBox = ({ notification, handleClose, setNotification }) => {
  const dispatch = useDispatch();
  let frmt2 = "YYYY-MM-DD HH:mm:ss";
  let dateN = new Date();
  // dateN.setMinutes(dateN.getMinutes() + 1); // timestamp
  // dateN = new Date(dateN); // Date object
  let utcNow = moment(dateN).utc().format(frmt2);
  //convert date Range to utc before api call
  const [variables, setVariables] = useState({
    dateRange: [moment().format(`YYYY-MM-DD`), moment().format("YYYY-MM-DD")],
    dateToday: utcNow,
  });
  const [notificationData, setNotificationData] = useState(null);
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  useEffect(() => {
    const vendor_id = Auth.id;
    dispatch(Actions.getUpcomingMeetingActivitesForAlert(variables, vendor_id));
  }, []);

  setTimeout(() => {
    setNotification({
      open: false,
      time: "",
      name: "",
      title: "",
    });
  }, 8000);

  useEffect(() => {
    setNotificationData(notification);
    console.log("inide effect", notification);
  }, [notification]);
  const { t } = useTranslation();
  const handleOpenWebsite = (link) => {
    window.open(link, "_blank");
  };

  // const requiredIndex = tasks.findIndex(el => el.id !== id);

  return (
    notificationData !== null && (
      <div className="login-box py-1 px-0 shadow">
        <div className="w-100 text-right px-2">
          <img
            src={require("assets/icons/close-notification.svg")}
            className="img-fluid cursor-pointer"
            alt="site-logo"
            onClick={() => handleClose()}
            width="24"
            height="24"
          />
        </div>
        <div
          className="row px-4 pb-3 pt-2"
          style={{ borderBottom: "1px solid #F4F7FA" }}
        >
          <div className="col-2 d-flex justify-content-center align-items-start mt-2">
            <img
              src={require("assets/icons/yellow-bell4x.png")}
              className="img-fluid"
              alt="site-logo"
              width="40"
              height="40"
            />
          </div>
          <div className="col-10 d-flex flex-column justify-content-start align-items-start mt-2 w-100">
            <div className="d-flex flex-row justify-content-between align-items-center w-100">
              <h4 className="font-weight-bold text-black">Doctor Meeting</h4>
              <h4 className="font-weight-bold clr-yelloww">
                {notificationData.time}
              </h4>
            </div>
            <div
              className="text-left mt-1 text-muted"
              style={{ fontSize: "0.8rem" }}
            >
              Hi {notificationData.name}. Now is the time to join your scheduled
              meeting named {notificationData.title}.
            </div>
          </div>
          {/* <div className="col-3 py-3 mt-2">
          <Link
            to="/dashboard"
            className="logo-normal mt-1 "
            style={{ width: "100%" }}
          >
            <img
              src={require("assets/img/Logo.png")}
              className="img-fluid"
              alt="site-logo"
              width="106"
            />
          </Link>
        </div> */}
          {/* <h3 className="font-weight-bold">
          {time}
        </h3> */}
        </div>
        <div className="row pt-3 m-0">
          <div className="col-12 d-flex justify-content-end m-0 pb-2 px-4">
            <h6 className="p-0 m-0 text-left">
              <Link
                className="text-black font-weight-bold pointer-cursor p-0 m-0"
                onClick={(e) => {
                  History.push(`/meeting`);
                  handleClose();
                }}
              >
                Meetings Section
              </Link>
            </h6>
            <h6 className="p-0 m-0 text-left">
              <Link
                className="text-black font-weight-bold pointer-cursor p-0 ml-4"
                onClick={(e) => {
                  History.push(`/meeting`);
                  handleClose();
                }}
              >
                Join
              </Link>
            </h6>
          </div>
          {/* <a
          className="centrify-blue-color ml-2"
        // onClick={(e) => History.push(`meeting/${item.id}/${token}`)}
        >
          {t("join_meeting")}
        </a> */}
        </div>
        {/* <div className="schedule-header">
        <h2 >
          {header && header}
        </h2>
        <p
          className="mg-txt"
        >
          {content.split("\n").map((line, index) => {
            return (
              <span key={index}>
                {line}
                <br />
              </span>
            );
          })}
        </p>
        <button className="close" type="button" onClick={handleClose}>
          close
          </button>
      </div>

      {btns &&
        btns.length > 0 &&
        btns.map((btn) => {
          const { url, id, content } = btn;
          return (
            <div key={id} className="pop-content data-table">
              <div className="btn-hall">
                <button
                  type="button"
                  onClick={() => {
                    handleOpenWebsite(url);
                  }}
                >
                  {content}
                </button>
              </div>
            </div>
          );

        })} */}
      </div>
    )
  );
};

export default NotificationBox;
