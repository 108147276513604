import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

const StatsGenderChart = (props) => {
  const [data, setData] = useState({
    labels: ["Male", "Female"],
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: "#FE870B",
        borderColor: "#FE870B",
        borderWidth: 1,
        //stack: 1,
        hoverBackgroundColor: "#FE870B",
        hoverBorderColor: "#FE870B",
        data: [65, 59, 80, 81, 56],
        radius: 1,
      },
    ],
  });
  useEffect(() => {
    let _data = props.data;
    if (_data && _data.gender) {
      let gender = _data.gender;
      let labels = [];
      let data = [];
      for (const property in gender) {
        labels.push(property);
        data.push(gender[property]);
      }
      setData({
        labels,
        datasets: [
          {
            label: "Count: ",
            backgroundColor: "#FE870B",
            borderColor: "#FE870B",
            borderWidth: 1,
            //stack: 1,
            hoverBackgroundColor: "#FE870B",
            hoverBorderColor: "#FE870B",
            data,
            radius: 1,
          },
        ],
      });
    }
  }, [props]);

  const options = {
    responsive: true,
    legend: {
      display: false,
    },
    type: "bar",
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          }
        },
      ],
      yAxes: [
        {
          ticks: { min: 0 }
        },
        // {
        //   gridLines: {
        //     display: false
        //   }
        // }
      ],
    },
  };
  return <Bar data={data} width={null} height={null} options={options} />;
};

export default StatsGenderChart;
