import { NotificationManager } from "react-notifications";
import axios from 'axios';
import Domain from "lib/Config";
import History from "@history";

export const FORGET_ERROR = "FORGET_ERROR";
export const FORGET_SUCCESS = "FORGET_SUCCESS";
export const FORGET_LOADING = "FORGET_LOADING";

export function forgetEmail(email) {
    const request = axios.post(`${Domain}/api/vendor/forgetPassword`, { email });
    return (dispatch) =>
        request
            .then((response) => {
                NotificationManager.success("Email Sent Successfully");
                setForgetLoading(false);
            })
            .catch((error) => {
                console.log(error.response);
                setForgetLoading(false);
                NotificationManager.error(
                    error.response && error.response.data && error.response.data.msg
                        ? error.response.data.msg
                        : "Cannot Sent Email"
                );
            });
}

export function forgetPassword(data) {
    const request = axios.post(`${Domain}/api/vendor/resetPassword`, data);
    return (dispatch) =>
        request
            .then((response) => {
                NotificationManager.success("Reset Password Successfully");
                setForgetLoading(false);
                History.push('/login');
            })
            .catch((error) => {
                console.log(error.response);
                setForgetLoading(false);
                NotificationManager.error(
                    error.response && error.response.data && error.response.data.msg
                        ? error.response.data.msg
                        : "Cannot Reset Password"
                );
            });
}

export function setForgetLoading(val) {
    return (dispatch) => {
        return dispatch({
            type: FORGET_LOADING,
            payload: val,
        });
    };
}
