import React from "react";

function HeaderCellComponent(props) {
  const { date } = props;

  const dayOfWeek = date.getDay();
  console.log("dayOfWeek --->", dayOfWeek);
  console.log("Props Header Cell Componnet --->", props);
  //   const className =

  return (
    <div component="span" className="border border-dark">
      {props.label}
    </div>
  );
}

export default HeaderCellComponent;
