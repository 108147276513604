import * as Actions from "../actions";

const initialState = {
  loading: false,
  statusLoading: false,
  details: null,
  error: null,
  posted: false,
  employeeList: null,
  employeeDetails: null,
  employeeCount: null,
};

const employeeReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.EMPLOYEE_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case Actions.EMPLOYEE_STATUS_LOADING: {
      return {
        ...state,
        statusLoading: action.payload,
      };
    }
    case Actions.EMPLOYEE_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.EMPLOYEE_POSTED: {
      return {
        ...state,
        posted: action.payload,
      };
    }
    case Actions.GET_ALL_EMPLOYEES: {
      return {
        ...state,
        loading: false,
        error: null,
        employeeCount: action.payload.count,
        employeeList: action.payload.rows,
      };
    }
    case Actions.EMPLOYEE_DETAILS: {
      return {
        ...state,
        loading: false,
        statusLoading: false,
        error: null,
        employeeDetails: action.payload,
      };
    }
    case Actions.UPDATE_EMPLOYEE_PROFILE: {
      return {
        ...state,
        loading: false,
        error: null,
        employeeDetails: action.payload,
      };
    }
    case Actions.COMPANY_DETAILS: {
      return {
        ...state,
        loading: false,
        error: null,
        details: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
export default employeeReducer;
