import React, { useState, useEffect } from 'react'
import { Avatar } from '@material-ui/core'

import { useTranslation } from 'react-i18next'
import * as Actions from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'

import ConfrimDialogue from 'components/Dialogue/ConfrimDialogue'
import ErrorMessageComponents from 'components/ErrorHandlers/ErrorMessageComponents'
import CircularProgressComponent from 'components/ErrorHandlers/CircularProgressComponent'
import { Form, FormGroup, Input, Table } from 'reactstrap'
import TableLoader from 'components/ErrorHandlers/TableLoader'
import Pagination from '@material-ui/lab/Pagination'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5)
  }
}))

export default function Medicine (props) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [data, setData] = useState(null)
  const [dialogue, setDialogue] = useState(false)

  const [medicines, setMedicines] = useState([])
  const [medicineCount, setMedicineCount] = useState(0)

  const projectReducer = useSelector(({ project }) => project)

  const [variables, setVariables] = useState({ page: 1 })
  const { t } = useTranslation()

  useEffect(() => {
    if (props.match.params.id) {
      dispatch(Actions.setProjectStateLoading(true))
      dispatch(Actions.getProjectMedicines(props.match.params.id, variables))
    }
  }, [props.match.params.tab, variables])

  useEffect(() => {
    if (projectReducer) {
      if (
        projectReducer &&
        projectReducer.tabContentList &&
        projectReducer.tabContentType == 'medicines'
      ) {
        setMedicines(projectReducer.tabContentList)
        setMedicineCount(projectReducer.tabContentListCount)
      }
      if (projectReducer.error) {
        setMedicines([])
        setMedicineCount(0)
      }
    }
  }, [projectReducer])

  const getMedicineList = () => {
    if (medicines && medicines.length > 0) {
      return medicines.map(item => {
        return (
          <tr>
            <td>
              <div className='ml-4 d-flex align-items-center'>
                <Avatar
                  className='mr-3 medicine-pic'
                  src={
                    item.medicines && item.medicines.image
                      ? item.medicineimages[0].image
                      : ''
                  }
                />{' '}
                <p className='mb-0 prominent'>{item?.medicines?.name}</p>
              </div>
            </td>
            <td>{item?.medicines?.unit}</td>
            <td>{item?.medicines?.guidance}</td>
            <td>{item?.medicines?.info}</td>
            <td>{item?.medicines?.amount}</td>
            <td>{item?.medicines?.quantity}</td>
            <td
              onClick={() => {
                setData(item)
                setDialogue(!dialogue)
              }}
            >
              <img
                src={require('assets/icons/remove.png')}
                alt=''
                className='centrify-table-icons cursor-pointer'
              />
            </td>
          </tr>
        )
      })
    } else {
      return (
        <tr>
          <td colspan={7} className='text-center font-weight-bold'>
            {t('nothing_to_display')}
          </td>
        </tr>
      )
    }
  }

  const handlePageChange = (e, page) => {
    setVariables({
      ...variables,
      page: page
    })
  }
  const handleCancel = () => {
    if (props.projectId && data) {
      dispatch(Actions.setProjectStateLoading(true))
      dispatch(Actions.deleteProjectMedicine(data.id, props.projectId))
      setDialogue(!dialogue)
      setData(null)
    }
  }

  const handleSearch = e => {
    e.preventDefault()
    let search = document.getElementById('medicine-search').value
    setVariables({
      ...variables,
      _name: search,
      page: 1
    })
  }
  const handleChipDelete = () => {
    document.getElementById('medicine-search').value = ''
    setVariables({
      ...variables,
      _name: '',
      page: 1
    })
  }

  if (projectReducer.tabError) {
    return <ErrorMessageComponents message={t('cannot_get_medicines')} />
  }

  if (projectReducer === null) {
    return <CircularProgressComponent />
  }

  return (
    <div>
      <div className='mt-4'>
        <div className='d-flex justify-content-end'></div>

        <div className='rct-block mg-top-40'>
          <div className='d-flex justify-content-between align-items-center border-bottom-centrify p-4'>
            <div className='d-flex flex-column w-100'>
              <div className='mb-2'>
                {variables._name && (
                  <Chip
                    label={variables._name}
                    onDelete={() => handleChipDelete()}
                    className={classes.chip}
                  />
                )}
              </div>
              <div className='d-flex justify-content-between align-items-end table-top-bar '>
                <div className='d-flex align-items-center filters'>
                  <Form
                    className='mt-2 mt-sm-0'
                    onSubmit={e => handleSearch(e)}
                  >
                    <FormGroup className='d-inline-block gift-search-input'>
                      <Input
                        type='text'
                        name='value'
                        id='medicine-search'
                        maxLength={10}
                        placeholder={t('search')}
                        style={{ paddingRight: '40px' }}
                      />
                      <button type='submit' className='gift-search-icon'>
                        <i className='zmdi zmdi-search centrify-blue-color'></i>
                      </button>
                    </FormGroup>
                  </Form>
                </div>
              </div>

              <div className='mt-4' style={{ position: 'relative' }}>
                {projectReducer.stateloading && <TableLoader />}
                <Table responsive className='main-table'>
                  <thead className='main-table-header'>
                    <tr>
                      <th>
                        <span className='ml-4 mb-0 d-flex'>{t('name')}</span>
                      </th>
                      <th>{t('unit')}</th>
                      <th>{t('guidance')}</th>
                      <th>{t('description')}</th>
                      <th>{t('box/bottle')}</th>
                      <th>{t('qty_per_box')}</th>
                      <th>{t('actions')}</th>
                    </tr>
                  </thead>
                  <tbody className='main-table-body'>{getMedicineList()}</tbody>
                </Table>
              </div>

              {medicineCount > 0 ? (
                <div className='mt-4 d-flex justify-content-center'>
                  <Pagination
                    count={Math.ceil(medicineCount / 10)}
                    page={variables.page + 1}
                    onChange={handlePageChange}
                    variant='outlined'
                    shape='rounded'
                  />
                </div>
              ) : null}

              <ConfrimDialogue
                open={dialogue}
                handleClose={() => setDialogue(false)}
                action={() => handleCancel()}
                msg={t('are_you_sure_you_want_to_delete') + '?'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
