import React, { useEffect, useState } from "react";
import { Form, FormGroup, Input, Table, Button } from "reactstrap";
import { Avatar } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import History from "@history";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import TableLoader from "components/ErrorHandlers/TableLoader";
import { useDispatch, useSelector } from "react-redux";
import qs from "query-string";
import moment from "moment";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import { useTranslation } from "react-i18next";
import * as Actions from "store/actions";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function ImportantOptions(props) {
  const dispatch = useDispatch();
  let querystr = qs.parse();
  const classes = useStyles();
  let page = querystr.page ? JSON.parse(querystr.page) : 1;
  querystr.tab = 1;
  const [list, setList] = useState(null);
  const { t } = useTranslation();
  let tFrmt = "HH:mm:ss";
  let frmt2 = "YYYY-MM-DD HH:mm:ss";
  let dateN = new Date();
  let utcNow = moment(dateN).utc().format(frmt2);
  //convert date Range to utc before api call
  const [variables, setVariables] = useState({
    ...querystr,
    page,
    dateRange: [moment().format(`YYYY-MM-DD`), moment().format("YYYY-MM-DD")],
  });
  const [startDate, setStartDate] = useState(
    variables.dateRange
      ? moment(variables.dateRange[0]).format(`YYYY-MM-DD`)
      : moment().format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    variables.dateRange
      ? moment(variables.dateRange[1]).format(`YYYY-MM-DD`)
      : moment().format("YYYY-MM-DD")
  );

  const stringTruncate = (str, length) => {
    const dots = str.length > length ? "..." : "";
    return `${str.substring(0, length)}${dots}`;
  };

  const reportsReducer = useSelector(({ reports }) => reports);

  useEffect(() => {
    const q_str = qs.stringify(variables);
    History.push({ search: q_str });
    dispatch(Actions.setReportsImpLoading(true));
    dispatch(Actions.getImportantOptions(variables, props.vendorId));
  }, [variables]);

  useEffect(() => {
    setList(reportsReducer);
  }, [reportsReducer]);

  if (list && list.error) {
    return <ErrorMessageComponents message="Cannot Fetch List" />;
  }

  if (list === null || !list.importantList) {
    return <CircularProgressComponent />;
  }

  const getImportantList =
    list.importantList && list.importantList.length > 0 ? (
      list.importantList.map((item) => {
        return (
          <tr>
            <td>
              <div
                onClick={() =>
                  History.push(
                    `/patient-profile/${
                      item.patient.firstName + " " + item.patient.lastName
                    }/${item.patient.id}`
                  )
                }
                className="ml-4 d-flex align-items-center cursor-pointer"
              >
                <Avatar
                  className="mr-3 medicine-pic"
                  src={item?.patient?.image}
                />{" "}
                <p className="mb-0 prominent mr-4">
                  {item?.patient?.firstName}
                </p>
              </div>
            </td>
            <td
              onClick={() =>
                History.push(
                  `/survey/solved/${item?.patient?.id}/${item?.solvedQuestionnaire?.questionnaire?.id}`
                )
              }
            >
              <p className="mb-0 cursor-pointer">
                {stringTruncate(
                  item?.solvedQuestionnaire?.questionnaire?.title,
                  50
                )}
              </p>
            </td>
            <td>{item?.questionNumber}</td>
            <td>{item?.answer}</td>
            <td>
              {moment
                .utc(item?.solvedQuestionnaire?.createdAt)
                .format("MMM DD, YYYY")}
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colspan={6} className="text-center font-weight-bold">
          {t("nothing_to_display")}
        </td>
      </tr>
    );
  const handlePageChange = (e, page) => {
    let dates = [];
    if (variables.dateRange && variables.dateRange.length > 0) {
      dates = [startDate, endDate];
    }
    setVariables({
      ...variables,
      dateRange: dates,
      page: page,
    });
  };
  const handleFilter = () => {
    if (startDate && endDate)
      setVariables({ ...variables, dateRange: [startDate, endDate], page: 1 });
  };

  const removeFilter = () => {
    setStartDate(moment().format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));
    setVariables({
      ...variables,
      dateRange: [
        moment().format(`YYYY-MM-DD `),
        moment().format("YYYY-MM-DD"),
      ],
      page: 1,
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let search = document.getElementById("important-search").value;
    let dates = [];
    if (variables.dateRange && variables.dateRange.length > 0) {
      dates = [startDate, endDate];
    }
    setVariables({
      ...variables,
      search,
      page: 1,
      dateRange: dates,
    });
  };
  const handleDelete = () => {
    document.getElementById("important-search").value = "";
    let dates = [];
    if (variables.dateRange && variables.dateRange.length > 0) {
      dates = [startDate, endDate];
    }
    setVariables({
      ...variables,
      search: "",
      page: 1,
      dateRange: dates,
    });
  };

  return (
    <div>
      <div className="mt-4">
        <div>
          {variables.search && (
            <Chip
              label={variables.search}
              onDelete={() => handleDelete()}
              className={classes.chip}
            />
          )}
        </div>
        <div className="d-flex justify-content-between align-items-end table-top-bar">
          <div className="d-flex align-items-center filters">
            <Form className="mt-2 mt-sm-0" onSubmit={(e) => handleSearch(e)}>
              <FormGroup className="d-inline-block gift-search-input">
                <Input
                  type="text"
                  name="value"
                  id="important-search"
                  maxLength={10}
                  placeholder={t("search")}
                  style={{ paddingRight: "40px" }}
                />
                <button type="submit" className="gift-search-icon">
                  <i className="zmdi zmdi-search centrify-blue-color"></i>
                </button>
              </FormGroup>
            </Form>
          </div>
        </div>
        <div className="mt-4 patient-profile" style={{ position: "relative" }}>
          <div className="rct-block">
            <div className="rct-block-content p-0">
              <div className="d-flex flex-wrap justify-content-end date-header p-4">
                <div className="d-flex justify-content-end align-items-center flex-wrap date-header">
                  <div className="d-flex flex-wrap align-items-center date-range">
                    <p className="mb-0">{t("from")}</p>
                    <Input
                      type="date"
                      className="bg-transparent border-0"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                    <p className="mb-0">{t("to")}</p>
                    <Input
                      type="date"
                      className="bg-transparent border-0"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                    <Button
                      color="primary"
                      className="mr-2"
                      onClick={handleFilter}
                    >
                      {t("apply_filters")}
                    </Button>
                    <Button
                      color="danger"
                      className="mr-2"
                      onClick={removeFilter}
                    >
                      {t("remove_filters")}
                    </Button>
                  </div>
                </div>
              </div>
              {reportsReducer.impLoading && <TableLoader />}
              <Table responsive className="main-table">
                <thead className="main-table-header">
                  <tr>
                    <th>
                      <span className="ml-4 mb-0 d-flex">
                        {t("patient_name")}
                      </span>
                    </th>
                    <th>{t("questionnaire_title")}</th>
                    <th>{t("question_number")}</th>
                    <th>{t("selected_option")}</th>
                    <th>{t("attempted_at")}</th>
                  </tr>
                </thead>
                <tbody className="main-table-body">{getImportantList}</tbody>
              </Table>
            </div>
          </div>
        </div>
        {reportsReducer.importantCount > 0 ? (
          <div className="mt-4 d-flex justify-content-center">
            <Pagination
              count={Math.ceil(reportsReducer.importantCount / 10)}
              page={page}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
