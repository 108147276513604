import React from "react";
const CommingSoon = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "600px" }}
    >
      <h2 className="font-weight-bold">Coming Soon</h2>
    </div>
  );
};
export default CommingSoon;
