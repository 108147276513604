import BreadCrumbComponent from "components/Breadcrumbs";
import React, { useEffect, useState } from "react";
import Employee from "./employee";
import Patient from "./patient";
import Medicine from "./medicine";
import Engagement from "./engagement";
import ChatQuestions from "./chatQuestions";

import { useTranslation } from "react-i18next";

import History from "@history";

export default function ProjectDetail(props) {
  const [value, setValue] = useState(0);
  const [projectId, setProjectId] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (props.match.params.id) {
      setProjectId(props.match.params.id);
    }
  }, [props.match.params.id]);

  useEffect(() => {
    if (props.match.params.tab) {
      setValue(props.match.params.tab);
    }
  }, [props.match.params.tab]);

  let tabs = [
    t("patients"),
    t("employees"),
    t("medicines"),
    t("engagements"),
    t("chat_questions"),
  ];

  return (
    <div>
      <div className="">
        <BreadCrumbComponent
          active={props.match.params.projectName}
          previous={[{ name: t("projects"), link: "/projects" }]}
        />
      </div>

      <div className=" d-flex flex-wrap align-items-center tabs">
        {tabs.map((item, index) => {
          return (
            <p
              onClick={(e) => {
                History.push(
                  `/project/${props.match.params.projectName}/${props.match.params.id}/${index}`
                );
              }}
              className={`${
                index == value ? "active-tab" : ""
              } mb-0 margin-tabs mt-2 cursor-pointer`}
            >
              {item}
            </p>
          );
        })}
      </div>
      <div className="mt-4">
        {value == 0 ? (
          <div className="">
            <Patient projectId={projectId} {...props} />
          </div>
        ) : value == 1 ? (
          <div className="">
            <Employee projectId={projectId} {...props} />
          </div>
        ) : value == 2 ? (
          <div className="">
            <Medicine projectId={projectId} {...props} />
          </div>
        ) : value == 3 ? (
          <div className="">
            <Engagement projectId={projectId} {...props} />
          </div>
        ) : (
          <div className="">
            <ChatQuestions projectId={projectId} {...props} />
          </div>
        )}
      </div>
    </div>
  );
}
