import React, { useEffect, useState } from 'react'
import MarkdownIt from 'markdown-it'
import { Button } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import Pagination from '@material-ui/lab/Pagination'
import * as Actions from 'store/actions'
import History from '@history'
import AddDocumentation from './addDocumentation'
import ViewDocumentation from './viewDocumentation'
import DocumentationItem from './documentationItem'
import MdEditor from 'react-markdown-editor-lite'
import { useTranslation } from 'react-i18next'
import ErrorMessageComponents from 'components/ErrorHandlers/ErrorMessageComponents'
import qs from 'query-string'
import CircularProgressComponent from 'components/ErrorHandlers/CircularProgressComponent'
import 'react-markdown-editor-lite/lib/index.css'

const mdParser = new MarkdownIt()
const plugins = [
  'header',
  'fonts',
  'table',
  'link',
  'clear',
  'logger',
  'mode-toggle',
  'full-screen'
]

const DocumentationPatient = props => {
  const dispatch = useDispatch()
  let querystr = qs.parse(props.location.search)
  let page = querystr.page ? JSON.parse(querystr.page) : 1
  const [open, setOpen] = useState(false)
  const [text, setText] = useState('')
  const [html, setHtml] = useState(null)
  const [editorData, setEditorData] = useState(null)
  const [variables, setVariables] = useState({
    ...querystr
  })
  let patient_id = props.match.params.patientId
  const { t } = useTranslation()

  // const Auth = useSelector(({ auth }) => {
  //   return auth.login.success ? auth.user : false;
  // });
  const patientReducer = useSelector(({ patient }) => patient)

  useEffect(() => {
    const q_str = qs.stringify(variables)
    History.push({ search: q_str })
    let patient_id = props.match.params.patientId
    dispatch(Actions.setPatientDocumentLoading(true))
    dispatch(Actions.getAllDocumentations(variables, patient_id))
  }, [])

  useEffect(() => {
    // if (patientReducer ?.docList ?.files !== null) {
    //   console.log(patientReducer);
    //   setDocFiles(JSON.parse(patientReducer ?.docList ?.files));
    // }
    // console.log(JSON.parse(patientReducer.docList.files));
    if (patientReducer.docPosted) {
      setOpen(false)
    }
  }, [patientReducer])

  const handlePageChange = (e, page) => {
    setVariables({
      ...variables,
      page: page
    })
    const q_str = qs.stringify({ page })
    History.push({ search: q_str })
    dispatch(Actions.setPatientDocumentLoading(true))
    dispatch(Actions.getAllDocumentations({ page }, patient_id))
  }

  if (patientReducer.docList && patientReducer.error) {
    return <ErrorMessageComponents message='Cannot Fetch Documentations' />
  }

  const onEditorStateChange = e => {
    setHtml(e.html)
    setText(e.text)
  }

  if (patientReducer === null || !patientReducer.docList) {
    return <CircularProgressComponent />
  }

  return (
    <div className='container patient-profile'>
      <div className='d-flex justify-content-between py-5'>
        {/* <h1 className="main-heading">{props.match.params.patientName}</h1> */}
        <h1 className='main-heading'>{t('documentation')}</h1>
        <Button
          onClick={() => setOpen(!open)}
          color='none'
          className='primary-background-button-table font-weight-bold'
        >
          {' '}
          {t('add_documentation')}
        </Button>
      </div>
      {patientReducer.docList && patientReducer.docList.length > 0 ? (
        <>
          <div className='row d-flex justify-content-center'>
            {patientReducer.docList.map(item => (
              <DocumentationItem item={item} patient_id={patient_id} />
            ))}
            ;
          </div>

          {patientReducer?.docCount > 0 ? (
            <div className='mt-4 d-flex justify-content-center'>
              <Pagination
                count={Math.ceil(patientReducer?.docCount / 10)}
                page={page}
                onChange={handlePageChange}
                variant='outlined'
                shape='rounded'
              />
            </div>
          ) : null}
        </>
      ) : null}
      <AddDocumentation
        open={open}
        toggle={() => setOpen(!open)}
        patient_id={patient_id}
      />
    </div>
  )
}
export default DocumentationPatient
