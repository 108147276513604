import Domain from "lib/Config";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { getCompanyProfile } from "./company.actions";
import jwtService from "services/jwtService";
import { logoutUser, setUserData } from "auth/store/actions";
import History from "@history";

export const GET_ALL_EMPLOYEES = "[ADMIN] GET_ALL_EMPLOYEES";
export const EMPLOYEE_DETAILS = "[ADMIN] EMPLOYEE_DETAILS";
export const EMPLOYEE_POSTED = "[ADMIN] EMPLOYEE_POSTED";
export const EMPLOYEE_LOADING = "[ADMIN] EMPLOYEE_LOADING";
export const EMPLOYEE_STATUS_LOADING = "[ADMIN] EMPLOYEE_STATUS_LOADING";
export const EMPLOYEE_ERROR = "[ADMIN] EMPLOYEE_ERROR";
export const UPDATE_EMPLOYEE_PROFILE = "[ADMIN] UPDATE_EMPLOYEE_PROFILE";

export function getAllEmployees(data, vendor_id) {
  console.log("Get All Employees data--->", data);
  let page = 0;
  if (data.page) {
    page = data.page - 1;
  }

  const request = axios.post(`${Domain}/api/vendor/getAllVendoremps`, {
    ...data,
    page,
    vendor_id,
    limit: 10,
  });
  return (dispatch) =>
    request
      .then((response) => {
        console.log("response.data  all employee---->", response.data);
        return dispatch({
          type: GET_ALL_EMPLOYEES,
          payload: response.data.result,
        });
      })
      .catch((error) => {
        console.log(error.response);
        return dispatch({
          type: EMPLOYEE_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get details",
        });
      });
}

export function addEmployee(data) {
  const request = axios.post(`${Domain}/api/vendor/addEmployee`, data);
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success("Employee Added Successfully");
        dispatch({
          type: EMPLOYEE_POSTED,
          payload: true,
        });
        dispatch(getAllEmployees({ page: 1 }, data.vendor_id));
      })
      .catch((error) => {
        console.log(error.response);
        dispatch(setEmployeeLoading(false));
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : "Cannot Add Employee"
        );
      });
}

export function assignProject(data) {
  const request = axios.post(`${Domain}/api/vendor/addemptoProject`, data);
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success("Project Assigned Successfully");
        dispatch({
          type: EMPLOYEE_POSTED,
          payload: true,
        });
        dispatch(getAllEmployees({ page: 1 }, data.vendor_id));
      })
      .catch((error) => {
        console.log(error.response);
        dispatch(setEmployeeLoading(false));
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : "Cannot Assign Project"
        );
      });
}

export function getEmployeeProfile(id) {
  const request = axios.get(`${Domain}/api/vendor/employeeProfile?id=${id}`);
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: EMPLOYEE_DETAILS,
          payload: response.data.result,
        });
      })
      .catch((error) => {
        console.log(error.response);
        dispatch(setEmployeeLoading(false));
        dispatch({
          type: EMPLOYEE_ERROR,
          payload: "Cannot get employee",
        });
      });
}

export function updateEmployeeStatus(id, status) {
  const request = axios.put(`${Domain}/api/vendor/updateStatus/${id}`, {
    status,
  });
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success("Status Updated Successfully");
        dispatch(getEmployeeProfile(id));
      })
      .catch((error) => {
        console.log(error.response);
        dispatch(setEmployeeStatusLoading(false));
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : "Cannot Update Status"
        );
      });
}

export function updateProfileFcmToken(data) {
  const request = axios.put(`${Domain}/api/vendor/updateEmpProfile`, data);
  return (dispatch) =>
    request
      .then((response) => {
        console.log("Profile Updated Successfully");
        // NotificationManager.success("Profile Updated Successfully");
      })
      .catch((error) => {
        console.log(error.response);
        NotificationManager.error("Cannot Update Profile");
        return dispatch({
          type: EMPLOYEE_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot Update Profile",
        });
      });
}

export function updateEmployeeProfile(data) {
  const request = axios.put(`${Domain}/api/vendor/updateEmpProfile`, data);
  return (dispatch) =>
    request
      .then((response) => {
        console.log(response);
        NotificationManager.success("Employee Updated Successfully");
        History.goBack();
      })
      .catch((error) => {
        console.log(error.response);
        NotificationManager.error("Cannot Update Employee");
        return dispatch({
          type: EMPLOYEE_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot Update Profile",
        });
      });
}

export function updateProfile(data) {
  const request = axios.put(`${Domain}/api/vendor/updateEmpProfile`, data);
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success("Profile Updated Successfully");
        // dispatch(setEmployeeLoading(false));
        dispatch(getCompanyProfile(data.id));
        jwtService
          .signInWithToken()
          .then((user) => {
            dispatch(setUserData(user));
          })
          .catch((error) => {
            dispatch(logoutUser());
          });
      })
      .catch((error) => {
        console.log(error.response);
        NotificationManager.error("Cannot Update Profile");
        return dispatch({
          type: EMPLOYEE_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot Update Profile",
        });
      });
}

export function updateEmployeeImage(data) {
  const request = axios.put(`${Domain}/api/vendor/updateEmpProfile`, data);
  return (dispatch) =>
    request
      .then((response) => {
        // NotificationManager.success("Profile Updated Successfully");
        jwtService
          .signInWithToken()
          .then((user) => {
            dispatch(setUserData(user));
          })
          .catch((error) => {
            dispatch(logoutUser());
          });
      })
      .catch((error) => {
        console.log(error.response);
        NotificationManager.error("Cannot Update Image");
      });
}

export function updatePassword(data) {
  const request = axios.put(`${Domain}/api/vendor/updateempPassword`, data);
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success("Password Updated Successfully");
        dispatch(setEmployeeLoading(false));
      })
      .catch((error) => {
        console.log(error.response);
        NotificationManager.error("Cannot Update Password");
        dispatch(setEmployeeLoading(false));
      });
}
export function setEmployeeLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: EMPLOYEE_LOADING,
      payload: val,
    });
  };
}

export function setEmployeeStatusLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: EMPLOYEE_STATUS_LOADING,
      payload: val,
    });
  };
}
