import DoughnutChart from "components/Charts/DoughnutChart";
import React, { useEffect, useState } from "react";
import { Button, Input } from "reactstrap";
import ProjectsModal from "./projectsModal";
import StatsBarChart from "./statsBarChart";
import StatsGenderChart from "./statsGenderChart";
import FilterListIcon from "@material-ui/icons/FilterList";
import History from "@history";
import qs from "query-string";
import MedicineModal from "./medicineModal";
import PatientModal from "./patientModal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "store/actions";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import DatePicker from "components/DatePicker/DatePicker";
import moment from "moment";
import StatsAgeChart from "./statsAgeDoughnutChart";
import StatsAgePieChart from "./statsAgePieChart";

export default function Stats(props) {
  const dispatch = useDispatch();
  const projectReducer = useSelector(({ project }) => project);
  let querystr = qs.parse(props.location.search);
  const [open, setOpen] = useState(false);
  const [medOpen, setMedOpen] = useState(false);
  const [patOpen, setPatOpen] = useState(false);
  let frmt2 = "YYYY-MM-DD HH:mm:ss";
  let dateN = new Date();
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  let utcNow = moment(dateN).utc().format(frmt2);
  const [variables, setVariables] = useState({
    ...querystr,
    dateRange: [moment().format(`YYYY-MM-DD`), moment().format("YYYY-MM-DD")],
    dateToday: utcNow
  });
  const [startDate, setStartDate] = useState(
    variables.dateRange
      ? moment(new Date()).format(`YYYY-MM-DD`)
      : moment().format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    variables.dateRange
      ? moment(new Date()).format(`YYYY-MM-DD`)
      : moment().format("YYYY-MM-DD")
  );
  const [dateRange, setDateRange] = useState("");
  const { t } = useTranslation();

  const itemPicked = (id, name) => {
    setVariables({ ...variables, project_id: id, project_name: name, dateRange: [startDate, endDate] });
    setOpen(!open);
  };

  const allPicked = () => {
    setVariables({ ...variables, project_id: null, project_name: null, dateRange: [startDate, endDate] });
    setOpen(!open);
  };

  const medItemPicked = (id, name) => {
    setVariables({ ...variables, medicine_id: id, medicine_name: name, dateRange: [startDate, endDate] });
    setMedOpen(!medOpen);
  };

  const allMedPicked = () => {
    setVariables({ ...variables, medicine_id: null, medicine_name: null, dateRange: [startDate, endDate] });
    setMedOpen(!medOpen);
  };
  const patItemPicked = (id, name) => {
    setVariables({ ...variables, patient_id: id, patient_name: name, dateRange: [startDate, endDate] });
    setPatOpen(!patOpen);
  };

  const allPatPicked = () => {
    setVariables({ ...variables, patient_id: null, patient_name: null, dateRange: [startDate, endDate] });
    setPatOpen(!patOpen);
  };

  const handleFilter = () => {
    if (startDate && endDate)
      setVariables({ ...variables, dateRange: [startDate, endDate] });
  };

  const removeFilter = () => {
    setStartDate(moment().format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));
    setVariables({
      ...variables,
      dateRange: [
        moment().format(`YYYY-MM-DD `),
        moment().format("YYYY-MM-DD"),
      ],
    });
  };

  // const handleDateRange = (e) => {
  //   if (!e) {
  //     setDateRange("");
  //     return;
  //   }
  //   const formatedDate = [
  //     moment(e[0]).format("YYYY-MM-DD"),
  //     moment(e[1]).format("YYYY-MM-DD"),
  //   ];
  //   setVariables({ ...variables, dateRange: formatedDate });
  // };

  const handleGenderChange = (e) => {
    setVariables({
      ...variables,
      gender: e.target.value,
      dateRange: [startDate, endDate]
    });
  };

  const handleLocationChange = (e) => {
    setVariables({
      ...variables,
      region: e.target.value,
      dateRange: [startDate, endDate]
    });
  };

  const handleAgeChange = (e) => {
    setVariables({
      ...variables,
      age: e.target.value,
      dateRange: [startDate, endDate]
    });
  };

  useEffect(() => {
    const q_str = qs.stringify(variables);
    let vendor_id = Auth.vendor_id;
    History.push({ search: q_str });
    dispatch(Actions.setPatStatsLoading(variables));
    dispatch(Actions.getPatStats(variables, vendor_id));
    dispatch(Actions.setProjectLoading(true));
    dispatch(Actions.getStats(variables, vendor_id));
  }, [variables]);

  if (projectReducer && projectReducer.error) {
    return <ErrorMessageComponents message="Cannor get reports" />;
  }

  if (
    projectReducer == null ||
    !projectReducer.otherStats ||
    !projectReducer.otherDoseTaken
  ) {
    return <CircularProgressComponent />;
  }
  return (
    <div>
      <div className="d-flex flex-wrap align-items-center">
        <div className="d-flex align-items-center filters mt-4 mr-4">
          <p className="filter-text mb-0 mr-2">{t("filter_by_project")}</p>
          {variables && variables.project_name ? (
            <div className="mr-2">
              <span className="filter-text primary-color font-weight-bold">
                {variables.project_name}
              </span>
            </div>
          ) : (
              <div className="mr-2">
                <span className="filter-text primary-color">All</span>
              </div>
            )}
          <FilterListIcon
            className="cursor-pointer mr-2"
            onClick={() => setOpen(!open)}
            style={{ fontSize: "0.9rem", marginTop: "4px" }}
          />
        </div>
        <div className="d-flex align-items-center filters mt-4 mr-4">
          <p className="filter-text mb-0 mr-2">
            {t("filter_by_type_of_medication")}
          </p>
          {variables && variables.medicine_id && variables.medicine_name ? (
            <div className="mr-2">
              <span className="filter-text primary-color font-weight-bold">
                {variables.medicine_name}
              </span>
            </div>
          ) : (
              <div className="mr-2">
                <span className="filter-text primary-color">All</span>
              </div>
            )}
          <FilterListIcon
            className="cursor-pointer mr-2"
            onClick={() => setMedOpen(!medOpen)}
            style={{ fontSize: "0.9rem", marginTop: "4px" }}
          />
        </div>
        <div className="d-flex align-items-center filters mt-4 mr-4">
          <p className="filter-text mb-0 mr-2">{t("filter_by_patient")}</p>
          {variables && variables.patient_id && variables.patient_name ? (
            <div className="mr-2">
              <span className="filter-text primary-color font-weight-bold">
                {variables.patient_name}
              </span>
            </div>
          ) : (
              <div className="mr-2">
                <span className="filter-text primary-color">All</span>
              </div>
            )}
          <FilterListIcon
            className="cursor-pointer mr-2"
            onClick={() => setPatOpen(!patOpen)}
            style={{ fontSize: "0.9rem", marginTop: "4px" }}
          />
        </div>
        <div className="d-flex align-items-center filters mt-4 mr-4">
          <p className="filter-text mb-0 mr-2">{t("filter_by_location")}</p>
          <select
            className="mr-2"
            style={{ maxWidth: "100px" }}
            onChange={(e) => handleLocationChange(e)}
            defaultValue={variables.region}
          >
            <option value="">All</option>
            <option value="EMEA">
              EMEA (Europe, the Middle East and Africa)
            </option>
            <option value="NA">
              NA (North America), LATAM (Latin America){" "}
            </option>
            <option value="APAC">APAC (Asia-Pacific)</option>
          </select>
        </div>
        <div className="d-flex align-items-center filters mt-4 mr-4">
          <p className="filter-text mb-0 mr-2">{t("filter_by_age")}</p>
          <select
            className="mr-2"
            onChange={(e) => handleAgeChange(e)}
            defaultValue={variables.age}
          >
            <option value="">All</option>
            <option value="<50"> &#60; 50 </option>
            <option value="50-60"> 50-60 </option>
            <option value="60-70"> 60-70 </option>
            <option value="70-80"> 70-80 </option>
            <option value="80-90"> 80-90 </option>
            <option value="90-100"> 90-100 </option>
            <option value=">100"> &#62; 100 </option>
          </select>
        </div>
        <div className="d-flex align-items-center filters mt-4 mr-4">
          <p className="filter-text mb-0 mr-2">{t("filter_by_gender")}</p>
          <select
            className="mr-2"
            onChange={(e) => handleGenderChange(e)}
            defaultValue={variables.gender}
          >
            <option value="">All</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div>
      </div>
      <div className="mt-4 patient-profile">
        <div className="rct-block">
          <div className="rct-block-content">
            <div className="d-flex flex-wrap justify-content-between date-header">
              <h1 className="main-heading d-flex">{t("statistics")}</h1>
              {/* <div className="d-flex align-items-center date-range"> */}
              {/* <DatePicker
                  className="w-100"
                  value={variables.dateRange}
                  handleChange={handleDateRange}
                /> */}
              {/* </div> */}

              <div className="d-flex justify-content-end align-items-center flex-wrap date-header">
                <div className="d-flex flex-wrap align-items-center date-range">
                  {/* <DatePicker
              className="w-100"
              value={variables.dateRange}
              handleChange={handleDateRange}
            /> */}
                  <p className="mb-0">{t("from")}</p>
                  <Input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                  <p className="mb-0">{t("to")}</p>
                  <Input
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                  <Button color="primary" className="mr-2" onClick={handleFilter}>
                    {t("apply_filters")}
                  </Button>
                  <Button color="danger" className="mr-2" onClick={removeFilter}>
                    {t("remove_filters")}
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <div className="row report-card">
                <div className="col-md-4 d-flex flex-column mt-4">
                  <div className="dose-taken p-4 ">
                    <h1 className="main-heading d-flex">{t("doses_taken")}</h1>
                    <div>
                      <h1 className="dose-score">{`${projectReducer.otherDoseTaken.totalDone}/${projectReducer.otherDoseTaken.totalCount}`}</h1>
                      <h1 className="main-heading mt-4 d-flex">
                        {t("which_is")}
                        {projectReducer.otherDoseTaken.totalCount == 0
                          ? ` ${0}%`
                          : ` ${(
                            (projectReducer.otherDoseTaken.totalDone /
                              projectReducer.otherDoseTaken.totalCount) *
                            100
                          ).toFixed(2)}%`}
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 d-flex flex-column mt-4 graph-section">
                  <div className="w-75">
                    <h1 className="d-flex">
                      {t("medication_actual_vs_planned")}
                    </h1>
                    <StatsBarChart data={projectReducer.otherStats} />
                  </div>
                </div>
                <div className="col-md-4 d-flex flex-column mt-4 graph-section">
                  <h1 className="d-flex">{t("patient_age")}</h1>
                  <StatsAgePieChart data={projectReducer.patStats} />
                  <div className="mt-4">
                    <h1 className="d-flex">{t("gender")}</h1>
                    <StatsGenderChart data={projectReducer.patStats} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProjectsModal
        {...props}
        modal={open}
        toggle={() => setOpen(!open)}
        itemPicked={itemPicked}
        allPicked={allPicked}
      />
      <MedicineModal
        {...props}
        modal={medOpen}
        toggle={() => setMedOpen(!medOpen)}
        itemPicked={medItemPicked}
        allPicked={allMedPicked}
      />
      <PatientModal
        {...props}
        modal={patOpen}
        toggle={() => setPatOpen(!patOpen)}
        itemPicked={patItemPicked}
        allPicked={allPatPicked}
      />
    </div>
  );
}
