import { CircularProgress, IconButton } from "@material-ui/core";
import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody, Label, Input } from "reactstrap";
import FileDrop from "react-dropzone";
import AddIcon from "@material-ui/icons/Add";
import * as Actions from "store/actions";
import Domain from "lib/Config";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import DeleteIcon from "@material-ui/icons/Delete";

let documentAxios = axios.create({});

delete documentAxios.defaults.headers.common["Authorization"];
const AddDocumentation = (props) => {
  const [images, setImages] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const dispatch = useDispatch();
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  const patientReducer = useSelector(({ patient }) => patient);
  const { t } = useTranslation();
  const handleSubmit = (e) => {
    e.preventDefault();
    let obj = {};
    const formData = new FormData(e.target);
    for (let [key, value] of formData.entries()) {
      obj[key] = value;
    }
    obj.files = images;
    obj.patient_id = props.patient_id;
    obj.date = new Date();
    // images.map((item) => {
    //   let img = {
    //     image: item.File,
    //   };
    //   obj.images.push(img);
    // });
    // obj.vendor_id = Auth.vendor_id;
    obj.employee_id = Auth.id;
    dispatch(Actions.setPatientDocumentLoading(true));
    dispatch(Actions.addDocumentation(obj));
  };

  const onDrop = useCallback(
    (img) => {
      setImageLoading(true);
      handleFileUpload(img[0]);
    },
    [images]
  );

  const removeImg = (id) => {
    setImages(images.filter((img) => img.id !== id));
  };

  const handleFileUpload = (data) => {
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      },
      header: {
        "Content-Type": data.type,
      },
    };
    getSignedURL(data.name, data.type).then((rsp) => {
      documentAxios
        .put(rsp.data.result.postUrl, data, config)
        .then((res) => {
          let image = {
            id: uuidv4(),
            type: data.type,
            File: rsp.data.result.getUrl,
          };
          setImages([...images, image]);
          setImageLoading(false);
        })
        .catch((err) => {
          setImageLoading(false);
          console.log(err.response);
        });
    });
  };

  const getSignedURL = (key, type) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Domain}/api/vendor/s3getsignedurl?key=${key}&type=${type}`)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          console.log(err.response);
          reject(err);
        });
    });
  };
  return (
    <div>
      <Modal
        isOpen={props.open}
        toggle={props.toggle}
        className="modal-background"
      >
        <ModalBody>
          <div className="p-4">
            <div className="d-flex justify-content-end">
              <img
                onClick={() => props.toggle()}
                className="centrify-icons cursor-pointer"
                src={require("assets/icons/close.png")}
              />
            </div>
            <div className="mt-2 form-styles">
              <h2 className="form-heading d-flex">{t("add_documentation")}</h2>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="mt-4">
                  <Label>{t("title")}</Label>
                  <Input type="text" name="title" required />
                </div>
                <div className="mt-4">
                  <Label>{t("text")}</Label>
                  <Input type="textarea" rows={4} name="description" minlength="200" required />
                </div>
                <div className="mt-4">
                  <div className="row">
                    {images &&
                      images.length > 0 &&
                      images.map((img) => (
                        <div
                          className="col-md-4 mb-2 animate__animated animate__fadeIn"
                          key={img.id}
                        >
                          <div className="img-div d-flex flex-column">
                            <img src={img.File} width="100%" alt="qwe" />
                          </div>
                          <div className="del-img">
                            <IconButton
                              color="inherit"
                              mini="true"
                              className="p-0"
                              onClick={() => removeImg(img.id)}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </div>
                        </div>
                      ))}
                    <div className="col-md-4">
                      <FileDrop
                        disabled={imageLoading}
                        accept="image/png, image/gif, image/jpeg, image/bmp, video/mp4, video/mpeg, video/mpe, video/mpv, video/ogg, video/m4p, video/avi, video/wmv, pdf, word, excel"
                        multiple
                        onDrop={onDrop}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()} className="img-div">
                            <IconButton
                              color="inherit"
                              mini="true"
                              aria-label="Menu"
                              className="p-0"
                            >
                              {imageLoading ? (
                                <CircularProgress
                                  style={{ height: "10px", width: "10px" }}
                                />
                              ) : (
                                  <AddIcon fontSize="small" />
                                )}
                            </IconButton>
                            <input {...getInputProps()} />
                          </div>
                        )}
                      </FileDrop>
                    </div>
                  </div>
                </div>
                <div className="mt-4 d-flex justify-content-end">
                  {patientReducer.loading ? (
                    <CircularProgress />
                  ) : (
                      <Button
                        type="submit"
                        className="primary-background-button-table"
                        color="none"
                      >
                        {t("add_documentation")}
                      </Button>
                    )}
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddDocumentation;
