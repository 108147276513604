import { CircularProgress, IconButton } from "@material-ui/core";
import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody, Label, Input } from "reactstrap";
import FileDrop from "react-dropzone";
import AddIcon from "@material-ui/icons/Add";
import * as Actions from "store/actions";
import Domain from "lib/Config";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "axios";
import * as countriesJson from "countries.json";
import NotificationManager from "react-notifications/lib/NotificationManager";
let medicineAxios = axios.create({});

delete medicineAxios.defaults.headers.common["Authorization"];
const AddProject = (props) => {
  let countries = countriesJson.default;
  const [images, setImages] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const dispatch = useDispatch();
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  const projectReducer = useSelector(({ project }) => project);
  const { t } = useTranslation();
  const handleSubmit = (e) => {
    e.preventDefault();
    let obj = {};
    const formData = new FormData(e.target);
    for (let [key, value] of formData.entries()) {
      obj[key] = value;
    }
    if (images.length > 0) {
      obj.imageUrl = images[0];
    }
    obj.vendor_id = Auth.vendor_id;
    dispatch(Actions.setProjectLoading(true));
    dispatch(Actions.addProject(obj));
  };

  const onDrop = useCallback(
    (img) => {
      setImageLoading(true);
      handleFileUpload(img[0]);
    },
    [images]
  );

  const handleFileUpload = (data) => {
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      },
      header: {
        "Content-Type": data.type,
      },
    };
    getSignedURL(data.name, data.type).then((rsp) => {
      medicineAxios
        .put(rsp.data.result.postUrl, data, config)
        .then((res) => {
          setImages([]);
          let imageUrl = rsp.data.result.getUrl;
          setImages([imageUrl]);
          setImageLoading(false);
        })
        .catch((err) => {
          setImageLoading(false);
          NotificationManager.error(t("error_something_went_wrong"));
        });
    });
  };

  const getSignedURL = (key, type) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Domain}/api/vendor/s3getsignedurl?key=${key}&type=${type}`)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  return (
    <div>
      <Modal
        isOpen={props.open}
        toggle={props.toggle}
        className="modal-background"
      >
        <ModalBody>
          <div className="p-4">
            <div className="d-flex justify-content-end">
              <img
                onClick={() => props.toggle()}
                className="centrify-icons cursor-pointer"
                src={require("assets/icons/close.png")}
              />
            </div>
            <div className="mt-2 form-styles">
              <h2 className="form-heading d-flex">{t("create_new_project")}</h2>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="mt-4">
                  <Label className="d-flex">{t("project_name")}</Label>
                  <Input type="text" name="name" required />
                </div>
                <div className="mt-4">
                  <Label className="d-flex">{t("country")}</Label>
                  <Input type="select" name="country" required>
                    {countries.map((country) => {
                      return (
                        <option value={country.name}>{country.name}</option>
                      );
                    })}
                  </Input>
                </div>
                <div className="mt-4">
                  <Label className="d-flex">{t("region")}</Label>
                  <Input type="select" name="region" required>
                    <option value="EMEA">
                      EMEA (Europe, the Middle East and Africa)
                    </option>
                    <option value="NA">
                      NA (North America), LATAM (Latin America){" "}
                    </option>
                    <option value="APAC">APAC (Asia-Pacific)</option>
                  </Input>
                </div>
                <div className="mt-4">
                  <Label className="d-flex">{t("type")}</Label>
                  <Input type="text" name="type" required />
                </div>
                <div className="mt-4">
                  <div className="row">
                    {images &&
                      images.length > 0 &&
                      images.map((img) => (
                        <div
                          className="col-md-4 mb-2 animate__animated animate__fadeIn"
                          key={img}
                        >
                          <div className="img-div d-flex flex-column">
                            <img src={img} width="100%" alt="qwe" />
                          </div>
                          <div className="del-img">
                            <IconButton
                              color="inherit"
                              mini="true"
                              className="p-0"
                              onClick={() => setImages([])}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </div>
                        </div>
                      ))}
                    <div className="col-md-4">
                      <FileDrop
                        disabled={imageLoading}
                        accept="image/png, image/gif, image/jpeg, image/bmp, video/mp4, video/mpeg, video/mpe, video/mpv, video/ogg, video/m4p, video/avi, video/wmv"
                        multiple
                        onDrop={onDrop}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()} className="img-div">
                            <IconButton
                              color="inherit"
                              mini="true"
                              aria-label="Menu"
                              className="p-0"
                            >
                              {imageLoading ? (
                                <CircularProgress
                                  style={{ height: "10px", width: "10px" }}
                                />
                              ) : (
                                <AddIcon fontSize="small" />
                              )}
                            </IconButton>
                            <input {...getInputProps()} />
                          </div>
                        )}
                      </FileDrop>
                    </div>
                  </div>
                </div>
                <div className="mt-4 d-flex justify-content-end">
                  {projectReducer.loading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      className="primary-background-button-table"
                      color="none"
                      disabled={projectReducer.loading}
                    >
                      {t("create_project")}
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddProject;
