import BreadCrumbComponent from "components/Breadcrumbs";
import React, { useEffect, useState } from "react";
import Details from "./detail";
import Patient from "./patient";
import Stats from "./stats/stats";
import CommingSoon from "components/pages/CommingSoon";
import { useTranslation } from "react-i18next";
import * as Actions from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import History from "@history";

export default function QuestionaireDetails(props) {
    const dispatch = useDispatch();
    const [value, setValue] = useState(0);
    const [questionaire, setQuestionaire] = useState(null);
    const { t } = useTranslation();
    const questionaireReducer = useSelector(({ questionaire }) => questionaire);

    useEffect(() => {
        dispatch(Actions.setQuestionairesLoading(true));
        dispatch(Actions.getQuestionaireById(props.match.params.id));
    }, [props.match.params.id]);

    useEffect(() => {
        setQuestionaire(questionaireReducer.questionaire);
    }, [questionaireReducer ?.questionaire]);

    if (questionaire && questionaire.error) {
        return <ErrorMessageComponents message="Cannot get questionnaire details" />;
    }

    if (!questionaire || questionaireReducer.loading) {
        return <CircularProgressComponent />;
    }

    let tabs = [
        t("view"),
        t("patients"),
        t("report"),
    ];

    return (
        <div>
            <div className="">
                <BreadCrumbComponent
                    active={questionaireReducer.questionaire.title}
                    previous={[
                        { name: t("all_questionnaires"), link: "/questionnaires" },
                        // { name: t("details"), link: `/questionnaire/${props.match.params.id}` },
                    ]}
                />
            </div>
            <div className=" d-flex flex-wrap align-items-center tabs">
                {tabs.map((item, index) => {
                    return (
                        <p
                            onClick={(e) => {
                                History.push({ search: null });
                                setValue(index);
                            }}
                            className={`${
                                index == value ? "active-tab" : ""
                                } mb-0 margin-tabs mt-2 cursor-pointer`}
                        >
                            {item}
                        </p>
                    );
                })}
            </div>
            <div className="mt-4">
                {
                    value == 0 ? (
                        <div className="">
                            <Details
                                questionaire={questionaire}
                            />
                        </div>
                    ) :
                        value == 1 ? (
                            <div className="">
                                <Patient questionaireId={props.match.params.id} />
                            </div>
                        ) :
                            value == 2 ? (
                                <div className="">
                                    <Stats questionaire={questionaire} />
                                </div>
                            ) : (
                                    ""
                                )}
            </div>
        </div>
    );
}
