import React, { useEffect, useState } from 'react'
import { Table } from 'reactstrap'

import { Avatar } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import qs from 'query-string'
import * as Actions from 'store/actions'
import ErrorMessageComponents from 'components/ErrorHandlers/ErrorMessageComponents'
import CircularProgressComponent from 'components/ErrorHandlers/CircularProgressComponent'
import History from '@history'

export default function Inventory (props) {
  const dispatch = useDispatch()
  let querystr = qs.parse(props.location.search)

  const [variables, setVariables] = useState({ ...querystr })
  const [inventory, setInventory] = useState(null)

  const { t } = useTranslation()

  const inventoryReducer = useSelector(({ inventory }) => inventory)

  useEffect(() => {
    const q_str = qs.stringify(variables)
    History.push({ search: q_str })
    let patient_id = props.match.params.patientId
    dispatch(Actions.setInventoryLoading(true))
    dispatch(Actions.getPatientInventory(patient_id))
  }, [variables])

  useEffect(() => {
    setInventory(inventoryReducer)
  }, [inventoryReducer])
  if (inventory && inventory.error) {
    return <ErrorMessageComponents message={t('cannot_fetch_inventory')} />
  }

  if (inventory === null || !inventory.inventoryList) {
    return <CircularProgressComponent />
  }

  const getInventoryList =
    inventory.inventoryList && inventory.inventoryList.length > 0 ? (
      inventory.inventoryList.map(item => {
        return (
          <tr>
            <td>
              <div className='ml-4 d-flex align-items-center'>
                <Avatar
                  src={
                    item.inventoryimages && item.inventoryimages.length > 0
                      ? `${item.inventoryimages[0].image}`
                      : ''
                  }
                  className='mr-3 medicine-pic'
                />{' '}
                <p className='mb-0 mr-2 prominent'>{item.name}</p>
              </div>
            </td>
            <td>{item.amount}</td>
            <td>
              {item.quantity > 0 ? Math.ceil(item.amount / item.quantity) : 0}
            </td>
            <td>{item.quantity}</td>
            <td>{item.type}</td>
          </tr>
        )
      })
    ) : (
      <tr>
        <td colspan={4} className='text-center font-weight-bold'>
          {t('nothing_to_display')}
        </td>
      </tr>
    )
  const handlePageChange = (e, page) => {
    setVariables({
      ...variables,
      page: page
    })
  }
  return (
    <div className='patient-profile'>
      <h1 className='main-heading'> {props.match.params.patientName}</h1>
      <div className='mt-4' style={{ position: 'relative' }}>
        {/* <TableLoader /> */}
        <Table responsive className='main-table'>
          <thead className='main-table-header'>
            <tr>
              <th>
                <span className='d-flex ml-4 mb-0'>{t('name')}</span>
              </th>
              <th>{t('remaining_pills')}</th>
              <th>{t('remaining_boxes')}</th>
              <th>{t('qty_per_box')}</th>
              <th>{t('type')}</th>
            </tr>
          </thead>
          <tbody className='main-table-body'>{getInventoryList}</tbody>
        </Table>
      </div>
      {/*inventory.inventoryCount > 0 && (
        <div className="mt-4 d-flex justify-content-center">
          <Pagination
            count={Math.ceil(inventory.inventoryCount / 2)}
            onChange={(e, pg) => handlePageChange(e, pg)}
            variant="outlined"
            shape="rounded"
          />
        </div>
    )*/}
    </div>
  )
}
