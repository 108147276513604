import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Actions from 'store/actions'
import { CircularProgress } from '@material-ui/core'
import { Button, Modal, Label, ModalBody } from 'reactstrap'
import { useTranslation } from 'react-i18next'

const GeneralQuestionModal = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const dashboardReducer = useSelector(({ dashboard }) => dashboard)

  let handleSubmit = e => {
    e.preventDefault()

    let questionTypeCheck = {
      IMAGE: 'GeneralImage',
      VIDEO: 'GeneralVideo',
      FILE: 'GeneralFile',
      TEXT: 'GeneralText'
    }
    let obj = {
      question: e.target?.question?.value,
      answer: e.target?.answer?.value,
      answerType: e.target?.answerType?.value,
      questionType: questionTypeCheck[e.target?.answerType?.value],
      vendor_id: props.vendor_id
    }

    dispatch(Actions.setDashboardQuestionStateLoading(true))
    if (props.modalType == 'add') {
      dispatch(Actions.addGeneralQuestion(obj))
    } else {
      let questionId = props.question.id
      dispatch(Actions.updateGeneralQuestion(obj, questionId))
    }
  }

  let getQuestionHeader = type => {
    let obj = {
      add: t('create_new_question'),
      update: t('update_question'),
      view: t('question_detail')
    }
    return obj[type]
  }

  return (
    <div>
      <Modal
        isOpen={props.modal}
        toggle={props.toggle}
        className='modal-background'
      >
        <ModalBody>
          <div className='p-4'>
            <div className='d-flex justify-content-end'>
              <img
                onClick={() => props.toggle()}
                className='centrify-icons cursor-pointer'
                src={require('assets/icons/close.png')}
              />
            </div>
            <div className='mt-2 form-styles'>
              <h2 className='form-heading d-flex mb-'>
                {getQuestionHeader(props.modalType)}
              </h2>
              <form onSubmit={e => handleSubmit(e)}>
                <div className='mt-4'>
                  <Label className='d-flex mb-3'>{t('question')}</Label>
                  <textarea
                    type='text'
                    name='question'
                    required
                    disabled={props.modalType == 'view' ? true : false}
                    defaultValue={props.question && props.question.question}
                    rows={2}
                    style={{
                      width: '100%',
                      resize: 'none',
                      border: '1px solid #d3d8dd',
                      borderRadius: '7px',
                      padding: '5px 5px'
                    }}
                  />
                </div>
                <div className='mt-4'>
                  <Label className='d-flex mb-3'>{t('answer')}</Label>
                  <textarea
                    type='text'
                    name='answer'
                    required
                    disabled={props.modalType == 'view' ? true : false}
                    defaultValue={props.question && props.question.answer}
                    rows={2}
                    style={{
                      width: '100%',
                      resize: 'none',
                      border: '1px solid #d3d8dd',
                      borderRadius: '7px',
                      padding: '5px 5px'
                    }}
                  />
                </div>
                <div className='mt-4'>
                  <Label className='d-flex mb-3'>{t('answer_type')}</Label>
                  <select
                    name='answerType'
                    className='mr-2'
                    disabled={props.modalType == 'view' ? true : false}
                    style={{
                      width: '100%',
                      resize: 'none',
                      border: '1px solid #d3d8dd',
                      borderRadius: '7px',
                      padding: '5px 5px'
                    }}
                    defaultValue={props.question && props.question.answerType}
                  >
                    <option value={'FILE'}>{t('file')}</option>
                    <option value={'IMAGE'}>{t('image')}</option>
                    <option value={'VIDEO'}>{t('video')}</option>
                    <option value={'TEXT'}>{t('text')}</option>
                  </select>
                </div>
                {props.modalType == 'view' ? null : (
                  <div className='mt-4 d-flex justify-content-end'>
                    {dashboardReducer.questionStateLoading ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        className='primary-background-button-table'
                        color='none'
                      >
                        {props.modalType == 'add' ? t('create') : t('update')}
                      </Button>
                    )}
                  </div>
                )}
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default GeneralQuestionModal
