import Domain from 'lib/Config'
import axios from 'axios'
import moment from 'moment'
import { NotificationManager } from 'react-notifications'

export const GET_ALL_PROJECTS = '[ADMIN] GET_ALL_PROJECTS'
export const GET_PROJECT_PATIENTS = '[ADMIN] GET_PROJECT_PATIENTS '
export const GET_PROJECT_STATS = '[ADMIN] GET_PROJECT_STATS '
export const GET_PROJECT_STATS_ADDITIONAL =
  '[ADMIN] GET_PROJECT_STATS_ADDITIONAL'
export const GET_PAT_STATS = '[ADMIN] GET_PAT_STATS '
export const GET_STATS = '[ADMIN] GET_STATS '
export const PROJECT_POSTED = '[ADMIN] PROJECT_POSTED'
export const PROJECT_ERROR = '[ADMIN] PROJECT_ERROR'
export const GET_PROJECT_TAB_DATA = '[ADMIN] GET_ENGAGEMENT_TAB_DATA'
export const ENGAGEMENT_TAB_ERROR = '[ADMIN] ENGAGEMENT_TAB_ERROR'
export const PROJECT_LOADING = '[ADMIN] PROJECT_LOADING'
export const PROJECT_ASSIGN_LOADING = '[ADMIN] PROJECT_ASSIGN_LOADING'
export const PROJECT_STATE_LOADING = '[ADMIN] PROJECT_STATE_LOADING'
export const PAT_STATS_LOADING = '[ADMIN] PAT_STATS_LOADING'

export function getAllProjects (data, vendor_id) {
  let page = 0
  if (data.page) {
    page = data.page - 1
  }
  const request = axios.post(`${Domain}/api/vendor/getallprojects`, {
    ...data,
    page: page,
    vendor_id,
    limit: 10
  })
  return dispatch =>
    request
      .then(response => {
        console.log('GET ALL PROJECTS response --->', response)
        return dispatch({
          type: GET_ALL_PROJECTS,
          payload: response.data.result.rows,
          count: response.data.result.count
        })
      })
      .catch(error => {
        console.log(error.response)
        return dispatch({
          type: PROJECT_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get projects'
        })
      })
}

export function addProject (data) {
  const request = axios.post(`${Domain}/api/vendor/addproject`, data)
  return dispatch =>
    request
      .then(response => {
        NotificationManager.success('Project Added Successfully')
        dispatch({
          type: PROJECT_POSTED,
          payload: true
        })
        dispatch(getAllProjects({ page: 1 }, data.vendor_id))
      })
      .catch(error => {
        console.log(error.response)
        dispatch(setProjectLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Cannot Add Project'
        )
      })
}

export function deleteProject (id, vendor_id) {
  console.log('Data to delete project --->', id)
  const request = axios.delete(`${Domain}/api/vendor/deleteProject/${id}`)
  return dispatch => {
    request
      .then(response => {
        NotificationManager.success('Project Deleted Successfully')
        dispatch({
          type: PROJECT_POSTED,
          payload: true
        })
        dispatch(getAllProjects({ page: 1 }, vendor_id))
      })
      .catch(error => {
        console.log(error.response)
        dispatch(setProjectLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Unable to delete project'
        )
      })
  }
}

export function updateProject (data) {
  const request = axios.put(`${Domain}/api/vendor/updateProject`, data)
  return dispatch =>
    request
      .then(response => {
        NotificationManager.success('Project Updated Successfully')
        dispatch(setProjectLoading(false))
      })
      .catch(error => {
        console.log(error.response)
        NotificationManager.error('Cannot Update Profile')
        return dispatch({
          type: PROJECT_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot Update Project'
        })
      })
}

export function getProjectPatients (project_id, data) {
  console.log('Data -->', data)
  if (data && data.page) {
    data.page = data.page - 1
  }
  data.from = moment(`${data.from} 00:00:00`)
    .utc()
    .format('YYYY-MM-DD HH:mm:ss')
  data.to = moment(`${data.to} 23:59:00`)
    .utc()
    .format('YYYY-MM-DD HH:mm:ss')
  data.dateToday = moment(`${data.dateToday} 00:00:00`)
    .utc()
    .format('YYYY-MM-DD HH:mm:ss')

  const request = axios.get(`${Domain}/api/vendor/getProjectPatientsNew`, {
    params: {
      ...data,
      project_id,
      limit: 10,
      page: data.page
    }
  })
  return dispatch =>
    request
      .then(response => {
        console.log('Response --->', response)
        console.log(response.data.projectPatients)
        return dispatch({
          type: GET_PROJECT_TAB_DATA,
          payload: {
            ...response.data.projectPatients,
            tabContentType: 'patients'
          }
        })
      })
      .catch(error => {
        console.log(error.response)
        return dispatch({
          type: ENGAGEMENT_TAB_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get patients'
        })
      })
}

export function assignPatientToProject (project_id, data) {
  console.log('Data assign patient to project ---<', data)
  const request = axios.post(
    `${Domain}/api/vendor/assignedProjectToMultiplePatients`,
    data
  )
  return dispatch =>
    request
      .then(response => {
        NotificationManager.success('Patient assigned successfully')
        dispatch(setProjectAssignLoading(false))
        dispatch(setProjectStateLoading(true))
        dispatch(getProjectPatients(project_id, {}))

        dispatch({
          type: PROJECT_POSTED,
          payload: true
        })
      })
      .catch(error => {
        console.log(error.response)
        dispatch(setProjectAssignLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Cannot assign patient'
        )
      })
}
export function unAssignPatientProject (project_id, data) {
  console.log('Data unassign patient to project ---<', data)
  const request = axios.post(
    `${Domain}/api/vendor/unAssignedPatientProject`,
    data
  )
  return dispatch =>
    request
      .then(response => {
        NotificationManager.success('Patient unassigned successfully')
        dispatch(getProjectPatients(project_id, {}))
        dispatch({
          type: PROJECT_POSTED,
          payload: true
        })
      })
      .catch(error => {
        console.log(error.response)
        dispatch(setProjectStateLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Cannot unassign patient'
        )
      })
}
export function getProjectEmployees (project_id, data) {
  console.log('Data -->', data)
  if (data && data.page) {
    data.page = data.page - 1
  }

  const request = axios.get(`${Domain}/api/vendor/getProjectEmployees`, {
    params: {
      ...data,
      project_id,
      limit: 10,
      page: data.page
    }
  })
  return dispatch =>
    request
      .then(response => {
        console.log('Response --->', response)
        console.log(response.data.employeeProject)
        return dispatch({
          type: GET_PROJECT_TAB_DATA,
          payload: {
            ...response.data.employeeProject,
            tabContentType: 'employees'
          }
        })
      })
      .catch(error => {
        console.log(error.response)
        return dispatch({
          type: ENGAGEMENT_TAB_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get employees'
        })
      })
}
export function assignEmployeeToProject (project_id, data) {
  console.log('Data assign employee to project ---<', data)
  const request = axios.post(
    `${Domain}/api/vendor/assignedProjectToMultipleEmployees`,
    data
  )
  return dispatch =>
    request
      .then(response => {
        NotificationManager.success('Employee assigned successfully')
        dispatch(setProjectAssignLoading(false))
        dispatch(setProjectStateLoading(true))
        dispatch(getProjectEmployees(project_id, {}))
        dispatch({
          type: PROJECT_POSTED,
          payload: true
        })
      })
      .catch(error => {
        console.log(error.response)
        dispatch(setProjectAssignLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Cannot assign employee'
        )
      })
}
export function getProjectMedicines (project_id, data) {
  console.log('Project Medicine Data -->', data)
  if (data && data.page) {
    data.page = data.page - 1
  }

  const request = axios.get(`${Domain}/api/vendor/getProjectMedicines`, {
    params: {
      ...data,
      project_id,
      limit: 10,
      page: data.page
    }
  })
  return dispatch =>
    request
      .then(response => {
        console.log('Response --->', response)
        console.log(response.data.projectMedicines)
        return dispatch({
          type: GET_PROJECT_TAB_DATA,
          payload: {
            ...response.data.projectMedicines,
            tabContentType: 'medicines'
          }
        })
      })
      .catch(error => {
        console.log(error.response)
        return dispatch({
          type: ENGAGEMENT_TAB_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get medicines'
        })
      })
}
export function getProjectEngagements (project_id, data) {
  if (data && data.page) {
    data.page = data.page - 1
  }

  const request = axios.get(`${Domain}/api/vendor/getProjectEngagements`, {
    params: {
      ...data,
      project_id,
      limit: 10,
      page: data.page
    }
  })
  return dispatch =>
    request
      .then(response => {
        console.log('Get Project Engagements Response --->', response)
        console.log(response.data.engagementProjects)
        return dispatch({
          type: GET_PROJECT_TAB_DATA,
          payload: {
            ...response.data.engagementProjects,
            tabContentType: 'engagements'
          }
        })
      })
      .catch(error => {
        console.log(error.response)
        return dispatch({
          type: ENGAGEMENT_TAB_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get engagements'
        })
      })
}
export function getProjectChatQuestions (project_id, data) {
  if (data && data.page) {
    data.page = data.page - 1
  }
  console.log('data get project chat question --->', data)
  const request = axios.get(`${Domain}/api/vendor/messages`, {
    params: {
      ...data,
      project_id,
      limit: 10,
      page: data.page
    }
  })
  return dispatch =>
    request
      .then(response => {
        console.log('Get Project chatQuestions Response --->', response)
        console.log(response.data.projectMessages)
        return dispatch({
          type: GET_PROJECT_TAB_DATA,
          payload: {
            ...response.data.projectMessages,
            tabContentType: 'chatQuestions'
          }
        })
      })
      .catch(error => {
        console.log(error.response)
        return dispatch({
          type: ENGAGEMENT_TAB_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get chat questions'
        })
      })
}
export function addProjectChatQuestion (project_id, data) {
  console.log('addProjectChatQuestion data --->', data)
  const request = axios.post(`${Domain}/api/vendor/addMessages`, data)
  return dispatch =>
    request
      .then(response => {
        NotificationManager.success('Chat question added successfully')
        dispatch(setProjectAssignLoading(false))
        dispatch(setProjectStateLoading(true))
        dispatch(getProjectChatQuestions(project_id, {}))
        dispatch({
          type: PROJECT_POSTED,
          payload: true
        })
      })
      .catch(error => {
        console.log('project chat question add error --->', error)
        console.log(error.response)
        dispatch(setProjectAssignLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Unable to add chat question'
        )
      })
}
export function updateProjectChatQuestion (project_id, data, message_id) {
  console.log('addProjectChatQuestion data --->', data)
  const request = axios.put(
    `${Domain}/api/vendor/updateMessages/${message_id}`,
    data
  )
  return dispatch =>
    request
      .then(response => {
        NotificationManager.success('Chat question updated successfully')
        dispatch(setProjectAssignLoading(false))
        dispatch(setProjectStateLoading(true))
        dispatch(getProjectChatQuestions(project_id, {}))
        dispatch({
          type: PROJECT_POSTED,
          payload: true
        })
      })
      .catch(error => {
        console.log('project chat question update error --->', error)
        console.log(error.response)
        dispatch(setProjectAssignLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Unable to update chat question'
        )
      })
}
export function deleteProjectChatQuestion (chatQuestionId, project_id) {
  const request = axios.delete(
    `${Domain}/api/vendor/deleteMessages/${chatQuestionId}`
  )
  return dispatch =>
    request
      .then(response => {
        NotificationManager.success('Chat question deleted successfully')
        dispatch(getProjectChatQuestions(project_id, {}))
      })
      .catch(error => {
        console.log(error.response)
        dispatch(setProjectStateLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Unable to delete chat question'
        )
      })
}

export function deleteProjectMedicine (medicine_id, project_id) {
  const request = axios.delete(
    `${Domain}/api/vendor/projectMedicine/${medicine_id}`
  )
  return dispatch =>
    request
      .then(response => {
        NotificationManager.success('Medicine Deleted Successfully')
        dispatch(getProjectMedicines(project_id, {}))
      })
      .catch(error => {
        console.log(error.response)
        dispatch(setProjectStateLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Unable to delete medicine'
        )
      })
}

export function cancelProjectEngagement (engagement_id, project_id) {
  console.log('engagement_id -->', engagement_id)
  console.log('project_id -->', project_id)
  const request = axios.post(`${Domain}/api/vendor/cancelProjectEngagement`, {
    id: engagement_id
  })
  return dispatch =>
    request
      .then(response => {
        NotificationManager.success('Engagement cancelled successfully')
        dispatch(getProjectEngagements(project_id, {}))
      })
      .catch(error => {
        console.log('Error Cancel ENGAMGENT --->', error.response)
        dispatch(setProjectStateLoading(false))
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : 'Unable to cancel engagement'
        )
      })
}

// export function assignProjectToPatient(data, project_id) {
//   // const data = {
//   //   project_id,
//   //   patients: patients,
//   // };
//   console.log(" ---assignProjectToPatient --->");
//   console.log("Data assign ---<", data);
//   const request = axios.post(`${Domain}/api/vendor/addPatienttoproject`, data);
//   return (dispatch) =>
//     request
//       .then((response) => {
//         NotificationManager.success("Patient assigned successfully");
//         dispatch(setProjectStateLoading(true));
//         dispatch(getProjectPatients(project_id, {}));
//         dispatch({
//           type: PROJECT_POSTED,
//           payload: true,
//         });
//       })
//       .catch((error) => {
//         console.log("patient add error --->", error);
//         console.log(error.response);
//         dispatch(setProjectAssignLoading(false));
//         NotificationManager.error(
//           error.response && error.response.data && error.response.data.msg
//             ? error.response.data.msg
//             : "Cannot assign project"
//         );
//       });
// }

export function getProjectStats (data, patient_id, project_id, medicine_id) {
  if (data.dateRange && data.dateRange.length > 0) {
    data.dateRange = [
      moment(`${data.dateRange[0]} 00:00:00`)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss'),
      moment(`${data.dateRange[1]} 23:59:00`)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
    ]
  }
  const request = axios.post(`${Domain}/api/vendor/actstats`, {
    ...data,
    patient_id
  })

  return dispatch =>
    request
      .then(response => {
        return dispatch({
          type: GET_PROJECT_STATS,
          payload: response.data.result
        })
      })
      .catch(error => {
        console.log(error.response)
        return dispatch({
          type: PROJECT_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get patients'
        })
      })
}
export function getStats (data, vendor_id) {
  console.log('act stats', data)
  data.vendor_id = vendor_id
  if (data.dateRange && data.dateRange.length > 0) {
    data.dateRange = [
      moment(`${data.dateRange[0]} 00:00:00`)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss'),
      moment(`${data.dateRange[1]} 23:59:00`)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
    ]
  }
  const request = axios.post(`${Domain}/api/vendor/actstats`, data)
  return dispatch =>
    request
      .then(response => {
        console.log('actStats', response.data.result)
        return dispatch({
          type: GET_STATS,
          payload: response.data.result
        })
      })
      .catch(error => {
        console.log(error.response)
        return dispatch({
          type: PROJECT_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get patients'
        })
      })
}

export function getProjectStatsInReport (
  data,
  patient_id,
  project_id,
  medicine_id,
  vendor_id
) {
  if (data.dateRange && data.dateRange.length > 0) {
    data.dateRange = [
      moment(`${data.dateRange[0]} 00:00:00`)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss'),
      moment(`${data.dateRange[1]} 23:59:00`)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
    ]
  }
  const request = axios.post(`${Domain}/api/vendor/actstat`, {
    ...data,
    patient_id
  })

  return dispatch =>
    request
      .then(response => {
        return dispatch({
          type: GET_PROJECT_STATS,
          payload: response.data.result
        })
      })
      .catch(error => {
        console.log('Get project stats in reports')
        console.log(error.response)
        return dispatch({
          type: PROJECT_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get project stats'
        })
      })
}

export function getProjectAdditionalStatsInReport (patient_id) {
  const request = axios.post(`${Domain}/api/vendor/patientReports`, {
    patient_id
  })

  return dispatch =>
    request
      .then(response => {
        return dispatch({
          type: GET_PROJECT_STATS_ADDITIONAL,
          payload: response.data.result
        })
      })
      .catch(error => {
        console.log('GET pROJECT ADDITIONAL RECORDS ERROR @@')
        console.log(error.response)
        return dispatch({
          type: PROJECT_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get project stats'
        })
      })
}

export function getPatStats (data, vendor_id) {
  console.log('patstats', data)
  const {
    medicine_id,
    patient_id,
    project_id,
    dateRange,
    age,
    gender,
    region
  } = data
  const dataa = {
    medicine_id,
    patient_id,
    project_id,
    vendor_id,
    age,
    gender,
    region
  }
  if (dateRange !== undefined && dateRange.length > 0) {
    dataa.startDate = dateRange[0]
    dataa.endDate = dateRange[1]
  }
  const request = axios.post(`${Domain}/api/vendor/patstats`, dataa)
  return dispatch =>
    request
      .then(response => {
        console.log('petStats', response.data.result)
        return dispatch({
          type: GET_PAT_STATS,
          payload: response.data.result
        })
      })
      .catch(error => {
        console.log(error.response)
        return dispatch({
          type: PROJECT_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get patients'
        })
      })
}
export function setProjectLoading (val) {
  return dispatch => {
    return dispatch({
      type: PROJECT_LOADING,
      payload: val
    })
  }
}
export function setProjectStateLoading (val) {
  return dispatch => {
    return dispatch({
      type: PROJECT_STATE_LOADING,
      payload: val
    })
  }
}
export function setProjectAssignLoading (val) {
  console.log('Set enggement assign loading ')
  return dispatch => {
    return dispatch({
      type: PROJECT_ASSIGN_LOADING,
      payload: val
    })
  }
}
export function setPatStatsLoading (val) {
  return dispatch => {
    return dispatch({
      type: PAT_STATS_LOADING,
      payload: val
    })
  }
}
