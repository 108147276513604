/**
 * App Header
 */
import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import MenuIcon from "@material-ui/icons/Menu";
import screenfull from "screenfull";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "store/actions";
import { Badge } from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { Notification } from "components/Notification";
import LanguageSelect from "components/LanguageSelect";
import "./Header.css";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import notificationReducer from "store/reducers/notifications.reducer";

function Header() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [meetingData, setMeetingData] = useState([]);
  const [notiCount, setNotiCount] = useState(0);
  const [menu, setMenu] = useState(false);
  const [singleNotification, setSingleNotification] = useState(null);
  // function to change the state of collapsed sidebar
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  const sidebar = useSelector(({ appReducer }) => appReducer.Sidebar);

  const notificationsReducer = useSelector(
    ({ notifications }) => notifications
  );

  const onToggleNavCollapsed = () => {
    dispatch(Actions.collapsedSidebarAction());
  };
  const toggleScreenFull = () => {
    screenfull.toggle();
  };

  useEffect(() => {
    let vendor_id = Auth.vendor_id;
    dispatch(Actions.setNotificationLoading(true));
    dispatch(Actions.getAllNotifications(vendor_id));
  }, []);
  const dataa = [
    {
      body: "User just checked the important option from the Questionnaire.",
      createdAt: "2021-08-27T07:51:26.000Z",
      isRead: false,
    },
    {
      body: "User just checked the important option from the Questionnaire.",
      createdAt: "2021-08-27T07:51:26.000Z",
      isRead: false,
    },
    {
      body: "User just checked the important option from the Questionnaire.",
      createdAt: "2021-08-27T07:51:26.000Z",
      isRead: true,
    },
    {
      body: "User just checked the important option from the Questionnaire.",
      createdAt: "2021-08-27T07:51:26.000Z",
      isRead: false,
    },
    {
      body: "User just checked the important option from the Questionnaire.",
      createdAt: "2021-08-27T07:51:26.000Z",
      isRead: true,
    },
    {
      body: "User just checked the important option from the Questionnaire.",
      createdAt: "2021-08-27T07:51:26.000Z",
      isRead: true,
    },
  ];

  useEffect(() => {
    console.log(notificationsReducer);
    if (
      notificationsReducer.notificationList !== null &&
      notificationsReducer.notificationList.length > 0
    ) {
      setData(notificationsReducer.notificationList);
      setMeetingData(notificationsReducer.meetingNotificationList);
      const unReadNoti = notificationsReducer.notificationList.filter(
        (el) => el.isRead !== true
      );
      setNotiCount(unReadNoti.length);
    }
  }, [notificationsReducer]);

  useEffect(() => {
    if (notificationsReducer.singleNotification) {
      setSingleNotification(notificationsReducer.singleNotification);
    }
  }, [notificationsReducer.singleNotification]);

  useEffect(() => {
    if (singleNotification) {
      if (singleNotification.translate) {
        singleNotification.msgType == "success"
          ? NotificationManager.success(t(singleNotification.msg))
          : NotificationManager.error(t(singleNotification.msg));
      } else {
        singleNotification.msgType == "success"
          ? NotificationManager.success(singleNotification.msg)
          : NotificationManager.error(singleNotification.msg);
      }
    }
  }, [singleNotification]);

  return (
    <AppBar position="static" className="rct-header">
      <Toolbar className="d-flex justify-content-between w-100 pl-0">
        <div className="d-inline-flex align-items-center">
          <ul className="list-inline mb-0 navbar-left d-flex align-items-center">
            <li
              className="list-inline-item"
              onClick={(e) => onToggleNavCollapsed(e)}
            >
              <Tooltip title="Sidebar Toggle" placement="bottom">
                <IconButton
                  color="inherit"
                  mini="true"
                  aria-label="Menu"
                  className="humburger p-0"
                >
                  <MenuIcon />
                </IconButton>
              </Tooltip>
            </li>
          </ul>
        </div>
        <ul className="navbar-right list-inline mb-0">
          <li className="list-inline-item d-flex">
            {/* <Tooltip title="Full Screen" placement="bottom"> */}
            <IconButton
              aria-label="settings"
              className="p-0"
              onClick={() => {
                dispatch(Actions.readAllNotifications(Auth.vendor_id));
                setNotiCount(0);
                setMenu(!menu);
              }}
            >
              <Badge badgeContent={notiCount} color="primary">
                <NotificationsIcon />
                <Notification
                  className={` ${menu ? "d-block" : "d-none"}`}
                  onClose={() => setMenu(!menu)}
                  open={menu}
                  data={data}
                  meetingData={meetingData}
                  totalNotifications={data.length}
                />
                {/* {data.length > 0 && !notificationsReducer.loading ?
              <Notifications
                data={
                  data.map((el, i) => (
                    {
                      // image: require("assets/img/report-bg.png"),
                      message: notificationsReducer.notificationList[i].message,
                    }
                  ))
                }
                header={{
                  title: 'Notifications',
                  option: { text: 'View All', onClick: () => console.log('Clicked') },
                }}
                // classNamePrefix="notification-header1"
                icon={require("assets/icons/bell.png")}
              />
              :
              null
            } */}
              </Badge>
            </IconButton>
            {/* </Tooltip> */}
            <LanguageSelect />
          </li>
        </ul>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
