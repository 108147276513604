import React, { useEffect, useState } from 'react'
import { Button, Form, FormGroup, Input, Table } from 'reactstrap'
import moment from 'moment'
import Pagination from '@material-ui/lab/Pagination'
import StoreOrderStatusDialogue from 'components/Dialogue/storeOrderStatusDialogue'
import History from '@history'
import ErrorMessageComponents from 'components/ErrorHandlers/ErrorMessageComponents'
import TableLoader from 'components/ErrorHandlers/TableLoader'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as Actions from 'store/actions'
import qs from 'query-string'
import AssignmentIcon from '@material-ui/icons/Assignment'
import CircularProgressComponent from 'components/ErrorHandlers/CircularProgressComponent'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5)
  }
}))

export default function Patients (props) {
  const dispatch = useDispatch()
  const classes = useStyles()
  let querystr = qs.parse(props.location.search)
  let page = querystr.page ? JSON.parse(querystr.page) : 1
  let frmt2 = 'YYYY-MM-DD HH:mm:ss'
  let dateN = new Date()
  let utcNow = moment(dateN)
    .utc()
    .format(frmt2)
  const [variables, setVariables] = useState({
    ...querystr,
    dateRange: [],
    dateToday: utcNow
  })
  const [categories, setCategories] = useState(null)
  const [orders, setOrders] = useState(null)
  const [open, setOpen] = useState(false)
  const [orderId, setOrderId] = useState(null)
  const [dialogue, setDialogue] = useState(false)
  const [startDate, setStartDate] = useState(
    variables.dateRange
      ? moment(new Date()).format(`YYYY-MM-DD`)
      : moment().format('YYYY-MM-DD')
  )
  const [endDate, setEndDate] = useState(
    variables.dateRange
      ? moment(new Date()).format(`YYYY-MM-DD`)
      : moment().format('YYYY-MM-DD')
  )
  const { t } = useTranslation()

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false
  })
  const storeReducer = useSelector(({ store }) => store)
  const orderReducer = useSelector(({ order }) => order)
  console.log('storeReducer ---->', storeReducer)
  console.log('orderReducer --->', orderReducer)
  useEffect(() => {
    const q_str = qs.stringify(variables)
    History.push({ search: q_str })
    let vendor_id = Auth.vendor_id
    dispatch(Actions.setOrderLoading(true))
    dispatch(Actions.getAllStoreOrders(variables, vendor_id))
  }, [variables])

  useEffect(() => {
    setOrders(orderReducer)
    if (orderReducer.posted) {
      setOrderId(null)
      setDialogue(false)
    }
  }, [orderReducer])

  useEffect(() => {
    if (storeReducer && storeReducer.category && storeReducer.category.rows) {
      setCategories(storeReducer.category.rows)
    }
  }, [storeReducer])
  console.log('categories --->', categories)
  useEffect(() => {
    dispatch(Actions.getStoreCategories(null, Auth.vendor_id))
  }, [])

  if (orders && orders.error) {
    return <ErrorMessageComponents message={t('cannot_fetch_orders')} />
  }

  const handleOrderStatus = status => {
    console.log('Order Id --->', orderId)
    console.log('Status ---->', status)
    if (orderId !== null && status !== null) {
      let dates = []
      if (variables.dateRange && variables.dateRange.length > 0) {
        dates = [startDate, endDate]
      }
      let data = { ...variables, dateRange: dates, page: 1 }
      dispatch(Actions.setOrderLoading(true))
      dispatch(
        Actions.updateOrderStoreStatus(status, orderId, Auth.vendor_id, data)
      )
    }
  }

  const handleFilter = () => {
    if (startDate && endDate)
      setVariables({ ...variables, dateRange: [startDate, endDate], page: 1 })
  }

  const removeFilter = () => {
    setStartDate(moment().format('YYYY-MM-DD'))
    setEndDate(moment().format('YYYY-MM-DD'))
    setVariables({
      ...variables,
      dateRange: [],
      page: 1
    })
  }

  if (orders === null || !orders.storeOrderList) {
    return <CircularProgressComponent />
  }

  const getStoreOrderList =
    orders.storeOrderList && orders.storeOrderList.length > 0 ? (
      orders.storeOrderList.map(item => {
        return (
          <tr>
            <td>
              <div className='ml-4 d-flex align-items-center prominent'>{`${item?.patient?.firstName} ${item?.patient?.lastName}`}</div>
            </td>
            <td>{`${item?.product?.title}`}</td>
            <td>{item?.product?.category?.title}</td>
            <td>{moment.utc(item?.createdAt).format('MMM DD, YYYY')}</td>
            <td>
              {item.status === 'pending' ? (
                <AssignmentIcon
                  onClick={() => {
                    setDialogue(!dialogue)
                    setOrderId(item.id)
                  }}
                  className='centrify-grey-color mt-2 cursor-pointer'
                />
              ) : item.status === 'acknowledged' ? (
                <img
                  src={require('assets/icons/green-tick.png')}
                  alt=''
                  className='centrify-table-icons'
                />
              ) : (
                <img
                  src={require('assets/icons/red-cross.png')}
                  alt=''
                  className='centrify-table-icons'
                />
              )}
            </td>
          </tr>
        )
      })
    ) : (
      <tr>
        <td colspan={7} className='text-center font-weight-bold'>
          {t('nothing_to_display')}
        </td>
      </tr>
    )

  const handlePageChange = (e, page) => {
    let dates = []
    if (variables.dateRange && variables.dateRange.length > 0) {
      dates = [startDate, endDate]
    }
    setVariables({
      ...variables,
      dateRange: dates,
      page: page
    })
  }

  const handleStatusChange = e => {
    let dates = []
    if (variables.dateRange && variables.dateRange.length > 0) {
      dates = [startDate, endDate]
    }
    setVariables({
      ...variables,
      status: e.target.value,
      page: 1,
      dateRange: dates
    })
  }

  const handleSearch = e => {
    e.preventDefault()
    let search = document.getElementById('order-search').value
    let dates = []
    if (variables.dateRange && variables.dateRange.length > 0) {
      dates = [startDate, endDate]
    }
    setVariables({
      ...variables,
      name: search,
      page: 1,
      dateRange: dates
    })
  }
  const handleDelete = () => {
    document.getElementById('order-search').value = ''
    setVariables({
      ...variables,
      name: '',
      page: 1
    })
  }
  return (
    <div>
      <div>
        {variables.name && (
          <Chip
            label={variables.name}
            onDelete={() => handleDelete()}
            className={classes.chip}
          />
        )}
      </div>
      <div className='d-flex flex-wrap align-items-center justify-content-between'>
        {/* <div className="d-flex align-items-center filters mt-4 mr-4"> */}
        <div className='d-flex align-items-center filters'>
          <Form className='mt-2 mt-sm-0' onSubmit={e => handleSearch(e)}>
            <FormGroup className='d-inline-block gift-search-input mr-4'>
              <Input
                type='text'
                name='name'
                id='order-search'
                maxLength={10}
                placeholder={t('search')}
                style={{ paddingRight: '40px' }}
              />
              <button type='submit' className='gift-search-icon'>
                <i className='zmdi zmdi-search centrify-blue-color'></i>
              </button>
            </FormGroup>
            <FormGroup className='d-inline-block gift-search-input'>
              <Input
                type='select'
                name='category_id'
                required
                value={variables?.category_id}
                onChange={e => {
                  if (e.target.value == 'all') {
                    delete variables.category_id
                    setVariables({ ...variables })
                  } else {
                    setVariables({
                      ...variables,
                      category_id: e.target.value
                    })
                  }
                }}
              >
                <option value={'all'}>All</option>
                {categories &&
                  categories.map(category => {
                    return <option value={category.id}>{category.title}</option>
                  })}
              </Input>
            </FormGroup>
          </Form>
        </div>
        <div className='d-flex align-items-center mt-3'>
          <div className='mr-4 d-flex align-items-center filters'>
            <p className='mb-0 mr-2'>{t('status')}</p>
            <Input
              type='select'
              className='mr-2'
              style={{ maxWidth: '120px' }}
              onChange={e => handleStatusChange(e)}
              defaultValue=''
            >
              <option value=''>{t('all')}</option>
              <option value='acknowledged'>{t('acknowledged')}</option>
              <option value='pending'>{t('pending')}</option>
            </Input>
          </div>
        </div>
      </div>

      {!open && (
        <div className='mt-4 patient-profile orders'>
          <div className='rct-block'>
            <div className='rct-block-content p-0'>
              <div className='d-flex flex-wrap justify-content-end date-header p-4'>
                {/* <h1 className="main-heading d-flex">{t("orders")}</h1> */}
                <div className='d-flex justify-content-end align-items-center flex-wrap date-header'>
                  <div className='d-flex flex-wrap align-items-center date-range'>
                    {/* <div className="d-flex align-items-center filters mt-4 mr-4 date-header"> */}
                    <p className='mb-0'>{t('from')}</p>
                    <Input
                      type='date'
                      className='bg-transparent border-0'
                      value={startDate}
                      onChange={e => setStartDate(e.target.value)}
                    />
                    <p className='mb-0'>{t('to')}</p>
                    <Input
                      type='date'
                      className='bg-transparent border-0'
                      value={endDate}
                      onChange={e => setEndDate(e.target.value)}
                    />
                    <Button
                      color='primary'
                      className='mr-2'
                      onClick={handleFilter}
                    >
                      {t('apply_filters')}
                    </Button>
                    <Button
                      color='danger'
                      className='mr-2'
                      onClick={removeFilter}
                    >
                      {t('remove_filters')}
                    </Button>
                  </div>
                </div>
              </div>
              {orders.loading && <TableLoader />}
              <Table responsive className='main-table'>
                <thead className='main-table-header'>
                  <tr>
                    <th>
                      <span className='ml-4 mb-0 d-flex'>
                        {t('patient_name')}
                      </span>
                    </th>
                    <th>{t('product')}</th>
                    <th>{t('category')}</th>
                    <th className=''>{t('allotment_date')}</th>
                    <th className=''>{t('status')}</th>
                  </tr>
                </thead>
                <tbody className='main-table-body'>{getStoreOrderList}</tbody>
              </Table>
            </div>
          </div>
        </div>
      )}
      {orders?.storeOrderCount > 0 ? (
        <div className='mt-4 d-flex justify-content-center'>
          <Pagination
            count={Math.ceil(orders.storeOrderCount / 10)}
            page={page}
            onChange={handlePageChange}
            variant='outlined'
            shape='rounded'
          />
        </div>
      ) : null}
      <StoreOrderStatusDialogue
        open={dialogue}
        handleClose={() => setDialogue(false)}
        action={handleOrderStatus}
        loading={orders.approveLoading}
        msg={t('are_you_sure_you_want_to_change_status') + '?'}
      />
    </div>
  )
}
