import Domain from "lib/Config";
import axios from "axios";
import moment from "moment";

export const GET_MISSED_ACTIVITIES = "[ADMIN] GET_MISSED_ACTIVITIES";
export const GET_IMPORTANT_SELECTED = "[ADMIN] GET_IMPORTANT_SELECTED";
export const GET_IMPORTANT_TREE_SELECTED =
  "[ADMIN] GET_IMPORTANT_TREE_SELECTED";
export const REPORTS_LOADING = "[ADMIN] REPORTS_LOADING";
export const REPORTS_IMP_LOADING = "[ADMIN] REPORTS_IMP_LOADING";
export const REPORTS_ERROR = "[ADMIN] REPORTS_ERROR";

export function getMissedActivites(data, employee_id) {
  console.log("Calling Missed Activities ---->", data, employee_id);
  if (data.dateRange && data.dateRange.length > 0) {
    data.dateRange = [
      moment(`${data.dateRange[0]} 00:00:00`)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss"),
      moment(`${data.dateRange[1]} 23:59:00`)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss"),
    ];
  } else {
    delete data.dateRange;
  }
  data.page = data.page - 1;
  const request = axios.post(`${Domain}/api/vendor/missedActivities`, {
    ...data,
    limit: 10,
    employee_id,
  });
  return (dispatch) =>
    request
      .then((response) => {
        // console.log(JSON.parse(response.data.result.doseFiles));
        console.log(response);
        return dispatch({
          type: GET_MISSED_ACTIVITIES,
          payload: response.data.result,
        });
      })
      .catch((error) => {
        console.log(error.response);
        return dispatch({
          type: REPORTS_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get activity",
        });
      });
}

export function getImportantOptions(data, vendor_id) {
  if (data.dateRange && data.dateRange.length > 0) {
    data.dateRange = [
      moment(`${data.dateRange[0]} 00:00:00`)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss"),
      moment(`${data.dateRange[1]} 23:59:00`)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss"),
    ];
  } else {
    delete data.dateRange;
  }
  if (data.page === null || data.page === undefined) {
    data.page = 0;
  } else {
    data.page = data.page - 1;
  }
  const request = axios.post(`${Domain}/api/vendor/important`, {
    ...data,
    limit: 10,
    vendor_id,
  });
  return (dispatch) =>
    request
      .then((response) => {
        // console.log(JSON.parse(response.data.result.doseFiles));
        console.log(response);
        return dispatch({
          type: GET_IMPORTANT_SELECTED,
          payload: response.data.answer,
        });
      })
      .catch((error) => {
        console.log(error.response);
        return dispatch({
          type: REPORTS_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot Fetch List",
        });
      });
}
export function getImportantTreeOptions(data, vendor_id) {
  console.log("Get Important tree options called ---->", data, vendor_id);
  if (data.dateRange && data.dateRange.length > 0) {
    data.dateRange = [
      moment(`${data.dateRange[0]} 00:00:00`)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss"),
      moment(`${data.dateRange[1]} 23:59:00`)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss"),
    ];
  } else {
    delete data.dateRange;
  }
  if (data.page === null || data.page === undefined) {
    data.page = 0;
  } else {
    data.page = data.page - 1;
  }
  const request = axios.post(
    `${Domain}/api/vendor/importantTreeQuestionnaire`,
    {
      ...data,
      limit: 10,
      vendor_id,
    }
  );
  return (dispatch) =>
    request
      .then((response) => {
        console.log("Response get important tree questionniare ====", response);
        // console.log(JSON.parse(response.data.result.doseFiles));
        console.log(response);
        return dispatch({
          type: GET_IMPORTANT_TREE_SELECTED,
          payload: response.data.answer,
        });
      })
      .catch((error) => {
        console.log(error.response);
        return dispatch({
          type: REPORTS_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot Fetch List",
        });
      });
}

export function setReportsLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: REPORTS_LOADING,
      payload: val,
    });
  };
}

export function setReportsImpLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: REPORTS_IMP_LOADING,
      payload: val,
    });
  };
}
