import { CircularProgress, IconButton } from '@material-ui/core'
import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Actions from 'store/actions'
import { Button, Modal, Label, ModalBody } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import axios from 'axios'
import { Document, Page, pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
const uploadAxios = axios.create({})
delete uploadAxios.defaults.headers.common['Authorization']

const ArticleModal = props => {
  const { t } = useTranslation()

  const [articleType, setArticleType] = useState('webLink')

  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1) //setting 1 to show fisrt page

  function onDocumentLoadSuccess ({ numPages }) {
    setNumPages(numPages)
    setPageNumber(1)
  }

  useEffect(() => {
    if (props.data.type) {
      setArticleType(props.data.type)
    }
  }, [props.data])

  const renderPdfElement = () => {
    return (
      <div className='mt-4'>
        <p className='mb-4'>{numPages}</p>
        <Document
          error={t('failed_to_load_pdf')}
          file={props.data.webLink}
          options={{ workerSrc: '/pdf.worker.js' }}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={
            <div className='text-center'>
              <CircularProgress />
            </div>
          }
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              loading={<div></div>}
            />
          ))}
        </Document>
        {/* <div className='text-center'>
          {numPages ? (
            <div>
              <p>
                Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
              </p>
              <Button
                className='primary-background-button-table mr-2 fw-bold'
                color='none'
                onClick={previousPage}
              >
                {t('previous')}
              </Button>
              <Button
                className='primary-background-button-table ml-2 fw-bold'
                color='none'
                onClick={nextPage}
              >
                {t('next')}
              </Button>
            </div>
          ) : null}
        </div> */}
      </div>
    )
  }

  return (
    <div>
      <Modal
        isOpen={props.modal}
        toggle={() => {
          setArticleType('webLink')
          props.toggle()
        }}
        size='lg'
        className='modal-background'
      >
        <ModalBody>
          <div className='p-4'>
            <div className='d-flex justify-content-end'>
              <img
                onClick={() => {
                  setArticleType('webLink')
                  props.toggle()
                }}
                className='centrify-icons cursor-pointer'
                src={require('assets/icons/close.png')}
              />
            </div>
            <div className='mt-2 form-styles'>
              <h2 className='form-heading d-flex mb-3'>{t('view_article')}</h2>

              {articleType == 'webLink' ? (
                <div className='mt-4'>
                  <embed
                    src='https://ide.geeksforgeeks.org'
                    width='400'
                    height='400'
                  />
                  {/* <iframe
                    src='https://www.google.com'
                    width='100%'
                    height='300'
                    style={{ border: '1px solid black' }}
                  ></iframe> */}
                  {/* <Label className='d-flex mb-3'>{t('web_link')}</Label>
                  <input
                    type='text'
                    name='webLink'
                    required
                    //   disabled={props.data.modalType == 'view' ? true : false}
                    defaultValue={props.data && props.data.webLink}
                    style={{
                      width: '100%',
                      resize: 'none',
                      border: '1px solid #d3d8dd',
                      borderRadius: '7px',
                      padding: '5px 5px'
                    }}
                  /> */}
                </div>
              ) : (
                renderPdfElement()
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ArticleModal
