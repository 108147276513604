import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Form, FormGroup, Input, Table } from "reactstrap";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Pagination from "@material-ui/lab/Pagination";
import ConfrimDialogue from "components/Dialogue/ConfrimDialogue";
import EditIcon from "@material-ui/icons/Edit";
import History from "@history";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import TableLoader from "components/ErrorHandlers/TableLoader";
import { useDispatch, useSelector } from "react-redux";
import qs from "query-string";
import moment from "moment";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import { useTranslation } from "react-i18next";
import * as Actions from "store/actions";
import AssignToPatientModal from "./patientModal";

export default function Medicines(props) {
  const dispatch = useDispatch();
  let querystr = qs.parse(props.location.search);
  let page = querystr.page ? JSON.parse(querystr.page) : 1;
  const [data, setData] = useState(null);
  const [patOpen, setPatOpen] = useState(null);
  const [questionnaireIdd, setQuestionnaireIdd] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [variables, setVariables] = useState({ ...querystr });
  const [questionaire, setQuestionaire] = useState(null);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [dialogue, setDialogue] = useState(false);
  const { t } = useTranslation();

  const stringTruncate = (str, length) => {
    const dots = str.length > length ? "..." : "";
    return `${str.substring(0, length)}${dots}`;
  };

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  const questionaireReducer = useSelector(({ questionaire }) => questionaire);

  useEffect(() => {
    const q_str = qs.stringify(variables);
    History.push({ search: q_str });
    let vendor_id = Auth.vendor_id;
    dispatch(Actions.setQuestionairesLoading(true));
    dispatch(Actions.getAllQuestionaires(variables, vendor_id));
  }, [variables]);

  useEffect(() => {
    setQuestionaire(questionaireReducer);
    if (questionaireReducer.posted) {
      setPatOpen(false);
      setSelectedPatient([]);
    }
  }, [questionaireReducer]);

  if (questionaire && questionaire.error) {
    return <ErrorMessageComponents message="Cannot Fetch Questionaires" />;
  }

  if (questionaire === null || !questionaire.questionairesList) {
    return <CircularProgressComponent />;
  }

  const getQuestionaireList =
    questionaire.questionairesList &&
    questionaire.questionairesList.length > 0 ? (
      questionaire.questionairesList.map((item) => {
        return (
          <tr>
            <td onClick={() => History.push(`/questionnaire/${item.id}`)}>
              <p className="ml-4 mb-0 prominent cursor-pointer">{item.title}</p>
            </td>
            <td>
              {moment.utc(item?.createdAt).local().format("MMM DD, YYYY")}
            </td>
            <td className="">{item?.questions[0]?.questionCount}</td>
            <td>{item?.patients?.length}</td>
            <td>{item?.solved}</td>
            <td>
              <AssignmentIcon
                onClick={() => {
                  setSelectedPatient(item?.patients);
                  setQuestionnaireIdd(item.id);
                  setPatOpen(true);
                }}
                className="centrify-grey-color cursor-pointer mt-5"
              />
            </td>
            <td>
              <Link to={`/survey/edit/${item.id}`}>
                <EditIcon className="centrify-grey-color cursor-pointer" />
              </Link>
            </td>
            <td
              onClick={() => {
                setData(item);
                setDialogue(!dialogue);
              }}
            >
              <img
                src={require("assets/icons/remove.png")}
                alt=""
                className="centrify-table-icons cursor-pointer"
              />
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colspan={6} className="text-center font-weight-bold">
          {t("nothing_to_display")}
        </td>
      </tr>
    );
  const handlePageChange = (e, page) => {
    setVariables({
      ...variables,
      page: page,
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let search = document.getElementById("questionaire-search").value;
    setVariables({
      ...variables,
      title: search,
      page: 1,
    });
  };

  const handleDelete = () => {
    dispatch(Actions.setQuestionairesLoading(true));
    dispatch(Actions.deleteQuestionaire(data.id, Auth.vendor_id));
    setDialogue(!dialogue);
    setData(null);
  };

  const handleClose = () => {
    // setDialogue(!dialogue);
    // setData(null);
  };

  return (
    <div>
      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-end table-top-bar">
          <div className="d-flex align-items-center filters">
            <Form className="mt-2 mt-sm-0" onSubmit={(e) => handleSearch(e)}>
              <FormGroup className="d-inline-block gift-search-input">
                <Input
                  type="text"
                  name="value"
                  id="questionaire-search"
                  maxLength={10}
                  placeholder={t("search")}
                  style={{ paddingRight: "40px" }}
                />
                <button type="submit" className="gift-search-icon">
                  <i className="zmdi zmdi-search centrify-blue-color"></i>
                </button>
              </FormGroup>
            </Form>
          </div>
          <Link
            to={"/survey/add"}
            color="none"
            className="primary-background-button-table font-weight-bold"
          >
            {" "}
            {t("create_questionnaire")}
          </Link>
        </div>
        {!open && (
          <div className="mt-4" style={{ position: "relative" }}>
            {questionaire.loading && <TableLoader />}
            <Table responsive className="main-table">
              <thead className="main-table-header">
                <tr>
                  <th>
                    <span className="ml-4 mb-0 d-flex">{t("title")}</span>
                  </th>

                  <th>{t("created_at")}</th>
                  <th>{t("total_questions")}</th>
                  <th>{t("total_allotments")}</th>
                  <th>{t("total_attempts")}</th>
                  <th>{t("assign")}</th>
                  <th>{t("update")}</th>
                  <th>{t("delete")}</th>
                </tr>
              </thead>
              <tbody className="main-table-body">{getQuestionaireList}</tbody>
            </Table>
          </div>
        )}
        {questionaire.count > 0 ? (
          <div className="mt-4 d-flex justify-content-center">
            <Pagination
              count={Math.ceil(questionaire.count / 10)}
              page={page}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </div>
        ) : null}
      </div>
      <AssignToPatientModal
        {...props}
        questionnaireId={questionnaireIdd}
        modal={patOpen}
        patients={selectedPatient}
        toggle={() => {
          setPatOpen(!patOpen);
          setSelectedPatient([]);
        }}
      />
      <ConfrimDialogue
        open={dialogue}
        handleClose={() => setDialogue(false)}
        action={() => handleDelete()}
        msg="Are you sure you want to delete this questionnaire?"
      />
    </div>
  );
}
