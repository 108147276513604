import * as Actions from '../actions'

const initialState = {
  loading: false,
  assignLoading: false,

  error: null,
  posted: false,
  questionairesList: null,
  treeQuestionnaireList: null,
  questionaire: null,
  treeQuestionnaire: null,
  editTreeQuestionnaire: null,
  reportingQuestionaire: null,
  editQuestionaire: null,
  patientList: null,
  opPatientList: null,
  treePatientList: null,
  treePatientCount: null,
  solvedQuestionaire: null,
  solvedTreeQuestionaire: null,
  patientCount: 0,
  opPatientCount: 0,
  treeQCount: 0
}

const questionaireReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.QUESTIONAIRE_LOADING: {
      return {
        ...state,
        loading: action.payload
      }
    }

    case Actions.ASSIGN_QUESTIONAIRE_LOADING: {
      return {
        ...state,
        assignLoading: action.payload
      }
    }
    case Actions.GET_QUESTIONAIRE_BY_ID: {
      return {
        ...state,
        questionaire: action.payload,
        loading: false
      }
    }
    case Actions.GET_TREE_QUESTIONAIRE_BY_ID: {
      // Creating Objects as per required in component
      action.payload.TQQuestions = action.payload.TQQuestions.map(
        (question, index) => {
          question.options = question.TQOptions
          delete question.TQOptions
          return question
        }
      )
      console.log('Action .payload --->', action.payload)
      return {
        ...state,
        treeQuestionnaire: {
          headerVals: {
            title: action.payload.title,
            description: action.payload.shortDescription,
            greetingMsg: action.payload.greeting
          },
          questions: action.payload.TQQuestions
        },
        loading: false
      }
    }

    case Actions.GET_QUESTIONAIRE_BY_ID_EDIT: {
      return {
        ...state,
        editQuestionaire: action.payload,
        loading: false
      }
    }
    case Actions.GET_TREE_QUESTIONAIRE_BY_ID_REPORTING: {
      let tempQuestionsArray = {}
      let tempLinkToOptions = []
      const payloadCopy = JSON.parse(JSON.stringify(action.payload))
      tempQuestionsArray = payloadCopy.TQQuestions.map((question, index) => {
        question.options = question.TQOptions
        delete question.TQOptions
        tempLinkToOptions.push({
          question: index + 1,
          questionUUID: question.UUID
        })
        return question
      })
      console.log('Action .payload --->', action.payload)
      return {
        ...state,
        reportingQuestionaire: action.payload,
        questionsArray: tempQuestionsArray,
        linkToOptions: tempLinkToOptions,
        loading: false
      }
    }
    case Actions.GET_QUESTIONAIRE_PATIENTS: {
      return {
        ...state,
        patientList: action.payload.rows,
        patientCount: action.payload.count,
        loading: false
      }
    }
    case Actions.GET_TREE_QUESTIONNAIRE_PATIENTS: {
      console.log('Action payload patient --->', action.payload)
      return {
        ...state,
        treePatientList: action.payload,
        treePatientCount: action.count,
        loading: false
      }
    }
    case Actions.GET_OPTION_PATIENTS: {
      return {
        ...state,
        opPatientList: action.payload.rows,
        opPatientCount: action.payload.count,
        loading: false
      }
    }
    case Actions.GET_SOLVED_QUESTIONAIRE: {
      return {
        ...state,
        solvedQuestionaire: action.payload,
        loading: false
      }
    }
    case Actions.GET_SOLVED_TREE_QUESTIONAIRE: {
      return {
        ...state,
        solvedTreeQuestionaire: action.payload,
        loading: false
      }
    }
    case Actions.QUESTIONAIRE_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    }
    case Actions.CLEAR_QUESTIONAIRE: {
      return {
        ...state,
        questionaire: null,
        reportingQuestionaire: null
      }
    }
    case Actions.QUESTIONAIRE_POSTED: {
      return {
        ...state,
        posted: action.payload
      }
    }
    case Actions.GET_ALL_QUESTIONAIRES: {
      return {
        ...state,
        loading: false,
        error: null,
        questionairesList: action.payload.rows,
        count: action.payload.count
      }
    }
    case Actions.GET_ALL_TREE_QUESTIONNAIRES: {
      return {
        ...state,
        loading: false,
        error: null,
        treeQuestionnaireList: action.payload.rows,
        treeQCount: action.payload.count
      }
    }
    default: {
      return state
    }
  }
}
export default questionaireReducer
