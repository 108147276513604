import { Avatar, FormGroup } from "@material-ui/core";
import BreadCrumbComponent from "components/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { Button, Form, Input, Table } from "reactstrap";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import ConfrimDialogue from "components/Dialogue/ConfrimDialogue";
import History from "@history";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import TableLoader from "components/ErrorHandlers/TableLoader";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import qs from "query-string";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import ViewMediaDetail from "./viewMediaDetail";
import * as Actions from "store/actions";
import "./mediaDetail.css";

import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import moment from "moment";

function PatientMedia(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let querystr = qs.parse(props.location.search);
  let page = querystr.page ? JSON.parse(querystr.page) : 1;

  const [variables, setVariables] = useState({ ...querystr });
  let patient_id = props.match.params?.patientId;
  const [viewMedia, setViewMedia] = useState(null);
  const [openView, setOpenView] = useState(false);
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  useEffect(() => {
    const q_str = qs.stringify(variables);
    History.push({ search: q_str });
    dispatch(Actions.setPatientDocumentLoading(true));
    dispatch(Actions.getPatientMedia(props.match.params?.patientId, variables));
  }, [dispatch, variables]);

  const patientReducer = useSelector(({ patient }) => patient);
  console.log("patientReducer @@@ ---> ", patientReducer);
  let getVideo = (media) => {
    // console.log("Media ---", media);
    let tempParsed = JSON.parse(media.temp);
    // console.log("TempParsed ---->", tempParsed);
    return (
      <div
        className="border media-container"
        onClick={() => {
          setViewMedia(media);
          setOpenView(true);
        }}
      >
        <img className="media-container-img" src={tempParsed.thumbnail}></img>
        <div className="media-container-info media-container-info-top-left  px-2 py-1">
          <p className="mb-0 ">{moment(media.date).format("LT")}</p>
        </div>
        <div className="media-container-info media-container-info-top-right  px-2 py-1">
          <p className="mb-0 ">{moment(media.date).format("DD-MM-YYYY")}</p>
        </div>
        <div className="media-container-info media-container-info-bottom  px-2 py-1">
          <p className="mb-0 ">{media?.treeQuestion?.title}</p>
        </div>
      </div>
    );
  };
  let getImage = (media) => {
    // console.log("Get Media iMage ---->", media);
    return (
      <div
        className="border media-container"
        onClick={() => {
          setViewMedia(media);
          setOpenView(true);
        }}
      >
        <img className="media-container-img" src={media.message}></img>
        <div className="media-container-info media-container-info-top-left  px-2 py-1">
          <p className="mb-0 ">{moment(media.date).format("LT")}</p>
        </div>
        <div className="media-container-info media-container-info-top-right  px-2 py-1">
          <p className="mb-0 ">{moment(media.date).format("DD-MM-YYYY")}</p>
        </div>
        <div className="media-container-info media-container-info-bottom  px-2 py-1">
          <p className="mb-0 ">{media?.treeQuestion?.title}</p>
        </div>
      </div>
    );
  };
  let getPatientMedia = () => {
    if (
      patientReducer &&
      patientReducer.patientMedia &&
      patientReducer.patientMedia.length > 0
    ) {
      return patientReducer.patientMedia.map((media) => {
        return (
          <div className=" p-0">
            {media.type == "VIDEO" ? getVideo(media) : null}
            {media.type == "IMAGE" ? getImage(media) : null}
          </div>
        );
      });
    } else {
      return (
        <div className=" col-12  p-0 w-100 m-auto mt-40 mb-40">
          <div className=" w-100 text-center w-100 ">
            <h2>{t("no_media")}</h2>
          </div>
        </div>
      );
    }
  };
  const handlePageChange = (e, page) => {
    setVariables({
      ...variables,
      page: page,
    });
  };

  if (patientReducer && patientReducer.tabError) {
    return <ErrorMessageComponents message={t("cannot_fetch_patient")} />;
  }

  if (patientReducer === null || patientReducer.docLoading) {
    return <CircularProgressComponent />;
  }
  return (
    <div>
      <BreadCrumbComponent
        active={props.match.params?.patientName}
        previous={[
          {
            name: t("patient_profile"),
            link: `/patient-profile/${props.match.params?.patientName}/${props.match.params?.patientId}`,
          },
        ]}
      />
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="form-heading d-flex">{t("patient_media")}</h1>
      </div>
      <div className="rct-block mt-1">
        <div className="rct-block-content ">
          <div className="media-grid-container ">{getPatientMedia()}</div>
          {patientReducer.docCount > 0 ? (
            <div className="mt-4 d-flex justify-content-center">
              <Pagination
                count={Math.ceil(patientReducer.docCount / 12)}
                page={page}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
              />
            </div>
          ) : null}
        </div>
      </div>
      <ViewMediaDetail
        open={openView}
        item={viewMedia}
        toggle={() => setOpenView(!openView)}
        patient_id={patient_id}
      />
    </div>
  );
}

export default PatientMedia;
