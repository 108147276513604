import * as Actions from "../actions";
import moment from "moment";

const initialState = {
  loading: false,
  statusLoading: false,
  details: null,
  error: null,
  posted: false,
  activityList: null,
  activityCount: null,
  upcomingMeetingList: null,
  upcomingMeetingAlerts: [],
  subscriptionStatus: null,
  singleActivityForBulk: null,
  singleActivityForBulkError: null,
  getActivityForNotifications: [],
};

const activityReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.ACTIVITY_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case Actions.ACTIVITY_STATUS_LOADING: {
      return {
        ...state,
        statusLoading: action.payload,
      };
    }
    case Actions.ACTIVITY_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.ACTIVITY_POSTED: {
      return {
        ...state,
        posted: action.payload,
      };
    }
    case Actions.GET_ALL_ACTIVITIES: {
      if (action.payload.result) {
        let dataa = action.payload.result;
        let arr = [];
        let tFrmt = "HH:mm:ss";

        dataa.map((item, index) => {
          if (item.status == "current") {
            item.order = 1;
          } else if (item.status == "pending") {
            item.order = 2;
          } else if (item.status == "missed") {
            item.order = 3;
          } else if (item.status == "done") {
            item.order = 4;
          }
          let keyCheck = arr.find(
            (x) =>
              moment(x.date)
                // .utc(true)
                .local()
                .format("YYYY-MM-DD") ==
              moment(item.date)
                // .utc(true)
                .local()
                .format("YYYY-MM-DD")
          );

          if (keyCheck) {
            keyCheck.activities = [...keyCheck.activities, item].sort(
              (a, b) => {
                if (a.order > b.order) return 1;
                if (b.order > a.order) return -1;

                return 0;
              }
            );
          } else {
            arr.push({
              date: item.date,
              activities: [item].sort((a, b) => {
                if (a.order > b.order) return 1;
                if (b.order > a.order) return -1;

                return 0;
              }),
            });
          }

          arr.sort(
            (a, b) =>
              moment(a.date).utc(true).local().format("YYYY-MM-DD") -
              moment(b.date).utc(true).local().format("YYYY-MM-DD")
          );

          let time = item.time;
          if (time) {
            item.time = time.split(":")[0] + ":" + time.split(":")[1];
          }

          let timeNow = new Date();
          timeNow.setHours(timeNow.getHours() - 2);
        });

        // console.log('third data cconsole', arr)

        arr.map((el) => {
          el.activities.map((act) => {
            act.forSortTime = moment(act.time, tFrmt)
              .utc(true)
              .local()
              .format("hh:mm A");
          });
          el.activities.sort(function (a, b) {
            // get time time from string
            // then get am or pm from string and append
            // both can be done using slice method
            return (
              Date.parse(
                "1970/01/01 " +
                  a.forSortTime.slice(0, -2) +
                  " " +
                  a.forSortTime.slice(-2)
              ) -
              Date.parse(
                "1970/01/01 " +
                  b.forSortTime.slice(0, -2) +
                  " " +
                  b.forSortTime.slice(-2)
              )
            );
          });

          el.activities.sort((a, b) => {
            if (a.order > b.order) return 1;
            if (b.order > a.order) return -1;

            return 0;
          });
        });
        arr.sort(
          (a, b) =>
            new moment(a.date).format("YYYYMMDD") -
            new moment(b.date).format("YYYYMMDD")
        );
        return {
          ...state,
          loading: false,
          error: null,
          activityList: arr,
          subscriptionStatus: action.payload.accessData.meetingSubscription,
        };
      }
    }
    case Actions.GET_UPCOMING_MEETING_ACTIVITIES: {
      if (action.payload) {
        let dataa = action.payload;
        let arr = [];

        // sorting by time
        let tFrmt = "HH:mm:ss";
        dataa.map((act) => {
          act.forSortTime = moment(act.time, tFrmt)
            .utcOffset(act.tz)

            .local()
            .format("hh:mm A");
        });
        let data = dataa.sort(function (a, b) {
          // get time time from string
          // then get am or pm from string and append
          // both can be done using slice method
          return (
            Date.parse(
              "1970/01/01 " +
                a.forSortTime.slice(0, -2) +
                " " +
                a.forSortTime.slice(-2)
            ) -
            Date.parse(
              "1970/01/01 " +
                b.forSortTime.slice(0, -2) +
                " " +
                b.forSortTime.slice(-2)
            )
          );
        });
        data.map((item, index) => {
          if (item.status == "current") {
            item.order = 1;
          } else if (item.status == "pending") {
            item.order = 2;
          } else if (item.status == "missed") {
            item.order = 3;
          } else if (item.status == "done") {
            item.order = 4;
          }
          let keyCheck = arr.find(
            (x) =>
              moment(x.date).local().format("YYYY-DD-MM") ==
              moment(item.date).local().format("YYYY-DD-MM")
          );
          // console.log(`index>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>${index}`);
          // console.log(`arr>>>>>>>>${arr}`);
          // console.log(`keyCheck>>>>>>>>${keyCheck}`);
          if (keyCheck) {
            keyCheck.activities = [...keyCheck.activities, item].sort(
              (a, b) => {
                if (a.order > b.order) return 1;
                if (b.order > a.order) return -1;

                return 0;
              }
            );
            // console.log(`keyCheck For activities>>>>>>>>${keyCheck}`);
            // console.log(`arr at the end of IFFFFFFFFFF>>>>>>>>${arr}`);
          } else {
            arr.push({
              date: item.date,
              activities: [item].sort((a, b) => {
                if (a.order > b.order) return 1;
                if (b.order > a.order) return -1;

                return 0;
              }),
            });

            // console.log(`arr in else condition >>>>>>>>>>>>>>>>>${arr}`);
          }
          arr.sort(
            (a, b) =>
              new moment(a.date).format("YYYYMMDD") -
              new moment(b.date).format("YYYYMMDD")
          );
          console.log(`arr after conditions below sort >>>>>>>>>>>>>>>>>`, arr);
          let time = item.time;
          if (time) {
            item.time = time.split(":")[0] + ":" + time.split(":")[1];
          }
          // console.log(`time and item.time >>>>>>>>>>>>>>>>>${time, item.time}`);
          let timeNow = new Date();
          timeNow.setHours(timeNow.getHours() - 2);
          if (
            Date.parse(timeNow) >
            Date.parse(
              `${moment(item.createdAt).format("YYYY-MM-DD")}T${item.time}`
            )
          ) {
          } else {
          }
        });
        // let tFrmt = "HH:mm:ss";
        // arr.map((el) => {
        //   el.activities.map(act => {
        //     act.forSortTime = moment(act.time, tFrmt)
        //       .utcOffset(act.tz)
        //       .utc(true)
        //       .local()
        //       .format("hh:mm A");
        //   });
        //   el.activities.sort(function (a, b) {
        //     // get time time from string
        //     // then get am or pm from string and append
        //     // both can be done using slice method
        //     return Date.parse('1970/01/01 ' + a.forSortTime.slice(0, -2) + ' ' + a.forSortTime.slice(-2)) - Date.parse('1970/01/01 ' + b.forSortTime.slice(0, -2) + ' ' + b.forSortTime.slice(-2))
        //   });
        // });
        return {
          ...state,
          loading: false,
          error: null,
          upcomingMeetingList: arr,
        };
      }
    }
    case Actions.GET_UPCOMING_MEETING_ALERTS: {
      return {
        ...state,
        upcomingMeetingAlerts: action.payload,
      };
    }
    // for bulk activities start................
    case Actions.GET_SINGLE_ACTIVITIE_FOR_BULK: {
      return {
        ...state,
        singleActivityForBulk: action.payload,
      };
    }

    case Actions.GET_SINGLE_ACTIVITIE_FOR_BULK_ERROR: {
      return {
        ...state,
        singleActivityForBulkError: action.payload,
      };
    }

    case Actions.GET_UPCOMING_MEETING_NOTIFICATION: {
      return {
        ...state,
        getActivityForNotifications: action.payload,
      };
    }

    case Actions.ACTIVITY_EMPTY: {
      return {
        ...state,
        singleActivityForBulk: null,
      };
    }

    // for bulk activities end................

    default: {
      return state;
    }
  }
};
export default activityReducer;
