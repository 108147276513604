import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import MessageLoader from 'react-loader-spinner'
import { useTranslation } from 'react-i18next'
import * as Actions from 'store/actions'
import { AiFillCheckCircle } from 'react-icons/ai'
import { FaRobot } from 'react-icons/fa'
import { Avatar, FormGroup } from '@material-ui/core'
import VictorIcon from 'assets/icons/victor-bot.svg'

import { MdError, MdOutlineDownloadForOffline } from 'react-icons/md'
import moment from 'moment'
import momentt from 'moment-timezone'
import './chatHistory.css'
// import DownloadIcon from "@material-ui/icons/DownloadIcon";
// import ErrorOutlineIcon from "@material-ui/icons/ErrorOutlineIcon ";
// import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
// import ErrorIcon from '@mui/icons-material/Error';
// import ErrorIcon from "@material-ui/icons/ErrorIcon";
// import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutlineIcon ";

function ChatHistory (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  let [chatContent, setChatContent] = useState([])
  const [variables, setVariables] = useState({ limit: 50, offset: 0 })
  const chatReducer = useSelector(({ chat }) => chat)
  const patientDetail = useSelector(({ patient }) => patient.details)
  let patient_id = props.match.params.patientId

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false
  })

  let fetchMoreData = () => {
    setVariables({ ...variables, offset: variables.offset + 50 })
  }

  useEffect(() => {
    //   Calling to clear the chat and setting date and avatar position to null which is stored in redux
    //   Reason why these two values are used is to manage the date inserted into chat array.
    //   Not clearing it raises conflict if chat of a different user is viewed
    return () => {
      dispatch(Actions.setChatNull())
    }
  }, [])
  useEffect(() => {
    if (chatReducer.history) {
      console.log('chatReducer.history received ---> ', chatReducer.history)
      console.log('chatContent --->', chatContent)
      let updatedChat = chatContent.concat(chatReducer.history)
      chatContent.concat(chatReducer.history)
      console.log('Updated Chat <---->', updatedChat)
      setChatContent(updatedChat)
    }
  }, [chatReducer.history])

  useEffect(() => {
    const vendor_id = Auth.vendor_id
    // Offset is 0 meaning no message has been fetched yet.
    // Set main chat loader to true.
    if (variables.offset == 0) {
      dispatch(Actions.setChatLoading(true))
    }
    console.log('variables -...->', variables)
    dispatch(Actions.getChatHistory(variables, patient_id))
  }, [variables])

  let getAvatar = chatItem => {
    return (
      <div className='d-flex justify-content-start p-0 m-0 '>
        <p className='mb-1  '>
          <Avatar
            className={
              chatItem.user == 'Victor'
                ? 'mr-2 d-inline-block  primary-background-color p-1 mb-0'
                : 'mr-2 mb-1'
            }
            src={chatItem.user == 'Victor' ? VictorIcon : patientDetail.image}
            style={{
              height: '24px',
              width: '24px'
            }}
          />
        </p>
        {chatItem.user == 'Victor' ? (
          <p className='fw-bold mb-0 align-self-center'>{chatItem.user}</p>
        ) : (
          <p className='fw-bold mb-0 align-self-center'>
            {patientDetail?.firstName + ' ' + patientDetail?.lastName}
          </p>
        )}
      </div>
    )
  }
  let getMsgDate = (date, type) => {
    if (date != null) {
      var zone_name = momentt.tz.guess()
      let zone_adjusted_time = moment(date)
        .tz(zone_name)
        .format('hh:mm A')
      return <p className='mb-0'>{zone_adjusted_time}</p>
    }
  }

  let getVideo = chatItem => {
    if (chatItem.position == 'RIGHT') {
      let msg = JSON.parse(chatItem.temp)
      return (
        <div>
          <div className='chat_video_container'>
            {chatItem.showUserAvatar == true ? getAvatar(chatItem) : null}
            <img src={msg.thumbnail} alt='Avatar' className='video_thumbnail' />
            <div class='video_download_overlay'>
              <a href={chatItem.message} download>
                <MdOutlineDownloadForOffline
                  className='video_download_icon primary-color'
                  style={{ fontSize: '36px' }}
                />
              </a>
            </div>
          </div>
          <div className={`d-flex justify-content-end mt-2`}>
            {getMsgDate(msg.createdAt)}
          </div>
        </div>
      )
    } else {
      let msg = JSON.parse(chatItem.message)
      return (
        <div>
          {chatItem.showUserAvatar == true ? getAvatar(chatItem) : null}
          <h4 className='p-3 chat_element_left'>{msg.title}</h4>
          <div className={`d-flex justify-content-end mt-2`}>
            {getMsgDate(msg.createdAt)}
          </div>
        </div>
      )
    }
  }
  let getImage = chatItem => {
    let msg = JSON.parse(chatItem.temp)
    if (chatItem.position == 'RIGHT') {
      return (
        <div>
          {chatItem.showUserAvatar == true ? getAvatar(chatItem) : null}
          <div>
            <img src={msg?.file} className='chat_image'></img>
          </div>

          <div className={`d-flex justify-content-end mt-2`}>
            {getMsgDate(msg?.createdAt)}
          </div>
        </div>
      )
    } else {
      let msg = JSON.parse(chatItem?.message)
      return (
        <div classname='max_width_60'>
          {chatItem.showUserAvatar == true ? getAvatar(chatItem) : null}
          <h4 className='p-3 chat_element_left'>{msg?.title}</h4>
          <div className={`d-flex justify-content-end `}>
            {getMsgDate(msg?.createdAt)}
          </div>
        </div>
      )
    }
  }
  let getProduct = chatItem => {
    console.log('Get Product ---------- @@@@@@', chatItem)

    let msg = JSON.parse(chatItem.message)
    console.log('msg ---->', msg)
    return (
      <div>
        {chatItem.showUserAvatar == true ? getAvatar(chatItem) : null}
        <div className='py-1 px-2 text-center border chat_element_left'>
          <img
            src={msg[0]?.productImages[0]?.image}
            className='chat_image mb-1'
          ></img>
          <h3>{msg[0]?.title}</h3>
          <div className='d-flex justify-content-between  m-0 p-0  '>
            <div className='mb-0 align-self-center d-flex justify-content-between align-items-center w-100 '>
              <p>{msg[0]?.unit}</p>
              <p> {msg[0]?.price} $</p>
            </div>
          </div>
          <p className='mb-0 text-right '>{getMsgDate(chatItem.date)}</p>
        </div>
      </div>
    )
  }

  let getByText = chatItem => {
    return (
      <div className='max_width_60'>
        {chatItem.showUserAvatar == true ? getAvatar(chatItem) : null}
        <h4
          className={` p-3   ${
            chatItem.position == 'RIGHT'
              ? 'primary-background-color chat_element_right text-white'
              : 'chat_element_left'
          }`}
        >
          {chatItem.message}
        </h4>
        <div className={`d-flex justify-content-end `}>
          {getMsgDate(chatItem.date, 'start')}
        </div>
      </div>
    )
  }
  let getStartQuestionnaire = chatItem => {
    let msg = JSON.parse(chatItem.message)
    console.log('Get Start questionnaire --->', chatItem)
    console.log('msg --->', msg)
    console.log('chatItem.date --->', chatItem?.date)

    return (
      <div className='max_width_60'>
        {chatItem.showUserAvatar == true ? getAvatar(chatItem) : null}
        <h4 className='  p-3 chat_element_left '>{msg.greeting}</h4>
        <div className={`d-flex justify-content-end `}>
          {getMsgDate(chatItem?.assignDate)}
        </div>
      </div>
    )
  }
  let getMcq = chatItem => {
    return (
      <div className='max_width_60'>
        {chatItem.showUserAvatar == true ? getAvatar(chatItem) : null}
        <h4
          className=' p-3 fw-bold
        chat_element_left'
        >
          {chatItem.message.title}
        </h4>
        <div className={`d-flex justify-content-end mt-2`}>
          <p>{getMsgDate(chatItem.createdAt)}</p>
        </div>
      </div>
    )
  }

  let getDate = (chatItem, index) => {
    var zone_name = momentt.tz.guess()
    let zone_adjusted_time = moment(chatItem.date)
      .tz(zone_name)
      .format('Do MMMM YYYY')

    return (
      <div className='py-2 px-3 chat_date'>
        <h3 className='fw-bold'>{zone_adjusted_time}</h3>
      </div>
    )
  }
  let getMissedActivity = chatItem => {
    let msg = JSON.parse(chatItem.message)
    return (
      <div className=' py-2 px-4 text-center activity missedActivity'>
        <div>
          <h3>{msg.type[0].toUpperCase() + msg.type.slice(1)}</h3>
          <p>{msg.title}</p>
        </div>
        <div className='d-flex justify-content-between  m-0 p-0 missedActivty_icon_date '>
          <div className='mb-0 align-self-center d-flex justify-content-between align-items-center '>
            <p className='align-self-end'>
              <MdError className='mr-2 ' />
            </p>
            <p>Missed Activity</p>
          </div>
          <p className='mb-0 '>{getMsgDate(chatItem.date)}</p>
        </div>
      </div>
    )
  }
  let getMissedQuestionnaire = chatItem => {
    let msg = JSON.parse(chatItem.message)
    return (
      <div className=' py-2 px-4 text-center activity missedActivity'>
        <div>
          <h3>Questionnaire</h3>
          <p>{msg.title}</p>
        </div>
        <div className='d-flex justify-content-between  m-0 p-0 missedActivty_icon_date '>
          <div className='mb-0 align-self-center d-flex justify-content-between align-items-center '>
            <p className='align-self-end'>
              <MdError className='mr-2 ' />
            </p>
            <p>Missed </p>
          </div>
          <p
            className='mb-0 
          '
          >
            {getMsgDate(chatItem.date)}
          </p>
        </div>
      </div>
    )
  }
  let getDoneActivity = chatItem => {
    let msg = JSON.parse(chatItem.message)
    return (
      <div className=' py-2 px-4 text-center activity takenActivity'>
        <div>
          <h3>{msg.type[0].toUpperCase() + msg.type.slice(1)}</h3>
          <p>{msg.title}</p>
        </div>
        <div className='d-flex justify-content-between  m-0 p-0 takenActivity_icon_date'>
          <div className='mb-0 align-self-center d-flex justify-content-between align-items-center '>
            <p className='align-self-end'>
              <AiFillCheckCircle className='mr-2 ' />
            </p>
            <p>Taken Activity</p>
          </div>
          <p className='mb-0'>{getMsgDate(chatItem.date)}</p>
        </div>
      </div>
    )
  }

  let getChatContent = () => {
    // console.log("Get Chat Content");
    if (chatContent) {
      return chatContent.map((chatItem, index) => {
        return (
          <div
            className={`d-flex mt-4 ${
              chatItem.position == 'RIGHT'
                ? 'justify-content-end'
                : chatItem.position == 'LEFT'
                ? 'justify-content-start '
                : 'justify-content-center'
            }`}
          >
            {chatItem.type == 'VIDEO'
              ? getVideo(chatItem)
              : chatItem.type == 'IMAGE'
              ? getImage(chatItem)
              : chatItem.type == 'PRODUCT'
              ? getProduct(chatItem)
              : chatItem.type == 'TEXT' || chatItem.type == 'QUERY'
              ? getByText(chatItem)
              : chatItem.type == 'StartQuestionnaire'
              ? getStartQuestionnaire(chatItem)
              : chatItem.type == 'MissedQuestionnaire'
              ? getMissedQuestionnaire(chatItem)
              : chatItem.type == 'MCQ'
              ? getMcq(chatItem)
              : chatItem.type == 'DATE'
              ? getDate(chatItem, index)
              : chatItem.type == 'MissedActivity'
              ? getMissedActivity(chatItem)
              : chatItem.type == 'DoneActivity'
              ? getDoneActivity(chatItem)
              : null}
          </div>
        )
      })
    }
  }
  console.log(' chatReducer --->', chatReducer)
  console.log(
    'chatReducer.count > variables.offset',
    chatReducer.count > variables.offset
  )
  console.log('chatreducer .count ---->', chatReducer.count)
  console.log('variables.offset --->', variables.offset)
  return (
    <div
      className='rct-block mt-1 chat_wrapper'
      style={{ height: '85vh', position: 'relative' }}
    >
      <div
        id='scrollableDiv'
        className='rct-block-content'
        style={{
          height: '100%',
          overflow: 'auto',
          flexDirection: 'column-reverse',
          display: 'flex'
        }}
      >
        <InfiniteScroll
          dataLength={chatContent.length}
          next={fetchMoreData}
          hasMore={chatReducer.history && chatReducer.history.length > 0}
          loader={
            <div style={{ display: 'grid', placeItems: 'center' }}>
              <MessageLoader
                type='TailSpin'
                color='#111'
                height={50}
                width={50}
              />
            </div>
          }
          scrollableTarget='scrollableDiv'
          style={{ display: 'flex', flexDirection: 'column-reverse' }}
          inverse={true}
        >
          {chatReducer == null || chatReducer.loading == true ? (
            <div>
              <h1 className='fw-bold no_chat_text'>{t('loading')}...</h1>
            </div>
          ) : chatReducer.loading == false && chatContent.length == 0 ? (
            <div>
              <h1 className='fw-bold no_chat_text'>
                {t('no_conversation_yet')}
              </h1>
            </div>
          ) : chatReducer != null && chatReducer.error ? (
            <div>
              <h1 className='fw-bold no_chat_text'>
                {t('something_went_wrong')}
              </h1>
            </div>
          ) : (
            getChatContent()
          )}
        </InfiniteScroll>
      </div>
    </div>
  )
}

export default ChatHistory
