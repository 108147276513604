import React from "react";

import RecommendModalBody from "./recommendModalBody";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";

const RecommendModal = (props) => {
  const { t } = useTranslation();

  var type =
    props.questions[props.selectedQuesOptIndexes.qIndex].options?.[
      props.selectedQuesOptIndexes.optIndex
    ].recommendModalType;

  return (
    <div>
      <Modal isOpen={props.modal} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle}>
          {type === "article" ? t("pick_article") : t("pick_product")}
        </ModalHeader>
        <ModalBody>
          <RecommendModalBody
            {...props}
            isOpen={props.modal}
            selectedQuesOptIndexes={props.selectedQuesOptIndexes}
            toggle={props.toggle}
            type={type}
            questionsArray={props.questionsArray}
            questions={props.questions}
            setQuestions={props.setQuestions}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.toggle}>
            {t("cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RecommendModal;
