import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DialogContent } from "@material-ui/core";
import { Input } from "reactstrap";
import { useTranslation } from "react-i18next";

function ConfrimDialogue(props) {
  const [single, setSingle] = useState("single");
  const { t } = useTranslation();

  const handleChange = (e) => {
    console.log(e.target.value, "single delete");
    setSingle(e.target.value);
    props.handleDelOptions(e.target.value);
  };
  return (
    <Dialog open={props.open} onClose={() => props.handleClose()}>
      <DialogTitle id="alert-dialog-title">{props.msg}</DialogTitle>
      <DialogContent>
        {props.delOptions && (
          <div>
            <div className="d-flex ml-4">
              <Input
                checked={single == "single" ? true : false}
                value={"single"}
                onChange={(e) => handleChange(e)}
                type="radio"
                name="delete"
              />
              <p>Delete Single Activity</p>
            </div>

            <div className="d-flex ml-4">
              <Input
                checked={single == "multiple" ? true : false}
                value={"multiple"}
                onChange={(e) => handleChange(e)}
                type="radio"
                name="delete"
              />
              <p>Delete Multiple Activities</p>
            </div>
          </div>
        )}
        {props.subMsg && <p className="mt-0  ">{props.subMsg}</p>}
      </DialogContent>
      <DialogActions style={{ padding: "05px 20px 20px 0px" }}>
        <Button
          variant="contained"
          onClick={() => props.handleClose()}
          className="btn-danger text-white"
        >
          {t("disagree")}
        </Button>
        <Button
          variant="contained"
          onClick={() => props.action()}
          color="primary"
          className="text-white"
          autoFocus
        >
          {t("agree")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfrimDialogue;
