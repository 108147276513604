import React, { useState, useEffect } from "react";
import { Avatar } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Actions from "store/actions";
import { useDispatch, useSelector } from "react-redux";

import ConfrimDialogue from "components/Dialogue/ConfrimDialogue";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import { Form, FormGroup, Input, Table } from "reactstrap";
import TableLoader from "components/ErrorHandlers/TableLoader";
import Pagination from "@material-ui/lab/Pagination";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import AssignToProjectModal from "../projectModal";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function Projects(props) {
  const dispatch = useDispatch();
  const { engagement } = props;
  const classes = useStyles();

  const [data, setData] = useState(null);
  const [dialogue, setDialogue] = useState(false);
  const engagementReducer = useSelector(({ engagement }) => engagement);
  const [projectModalState, setProjectModalState] = useState(false);
  const [variables, setVariables] = useState({ page: 1 });
  const [projects, setProjects] = useState([]);
  const [projectCount, setProjectCount] = useState(0);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    return () => {
      dispatch(Actions.setTabContentNull());
    };
  }, []);
  useEffect(() => {
    if (props.match.params.id) {
      dispatch(Actions.setEngagementStateLoading(true));
      dispatch(Actions.getEngagementProjects(props.match.params.id, variables));
    }
  }, [props.match.params.tab, variables]);
  useEffect(() => {
    if (engagementReducer.posted) {
      setProjectModalState(false);
    }
    if (
      engagementReducer &&
      engagementReducer.tabContentList &&
      engagementReducer.tabContentType == "projects"
    ) {
      setProjects(engagementReducer.tabContentList);
      setProjectCount(engagementReducer.tabContentListCount);
    }
    if (engagementReducer.error) {
      setProjects([]);
      setProjectCount(0);
    }
  }, [engagementReducer]);

  useEffect(() => {
    if (projectModalState == false) {
      setSelectedProjects([]);
    }
  }, [projectModalState]);

  const getProjectList = () => {
    if (projects && projects.length > 0) {
      return projects.map((item) => {
        return (
          <tr>
            <td>
              <div className="ml-4 d-flex align-items-center">
                <Avatar
                  className="mr-3 medicine-pic"
                  src={item?.project?.imageUrl}
                />
                <p className="mb-0 mr-2 prominent">{item?.project?.name}</p>
              </div>
            </td>
            <td>{item?.project?.country}</td>
            <td>{item?.project?.region}</td>
            <td>{item?.project?.type}</td>

            <td>{moment(item?.assignDate).local().format("DD-MM-YYYY")}</td>
            <td>
              {item.cancelledDate
                ? moment(item?.cancelledDate).local().format("DD-MM-YYYY")
                : null}
            </td>
            <td>
              {item?.status == "active"
                ? t("active")
                : item?.status == "cancelled"
                ? t("cancelled")
                : t("inActive")}
            </td>
            <td
              className={`cursor-pointer ${
                item?.status == "active" ? "centrify-blue-color" : null
              }  `}
              onClick={() => {
                if (item?.status == "active") {
                  setData(item);
                  setDialogue(true);
                }
              }}
            >
              {t("cancel")}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan={8} className="text-center font-weight-bold">
            {t("nothing_to_display")}
          </td>
        </tr>
      );
    }
  };

  const handlePageChange = (e, page) => {
    setVariables({
      ...variables,
      page: page,
    });
  };
  const handleCancel = () => {
    if (engagement.id && data) {
      console.log("Handle Cancel Called -----", data);
      dispatch(Actions.setEngagementStateLoading(true));
      dispatch(Actions.cancelProjectEngagment(data.id, engagement.id));
      setDialogue(!dialogue);
      setData(null);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let search = document.getElementById("project-search").value;
    console.log("Search Value -------->", search);
    setVariables({
      ...variables,
      _name: search,
      page: 1,
    });
  };
  const handleChipDelete = () => {
    document.getElementById("project-search").value = "";
    setVariables({
      ...variables,
      _name: "",
      page: 1,
    });
  };
  const filterChange = (e) => {
    console.log("Value --->", e.target.value);
    setVariables({
      ...variables,
      status: e.target.value,
    });
  };
  if (engagementReducer.tabError) {
    return <ErrorMessageComponents message={t("cannot_get_projects")} />;
  }

  if (engagementReducer === null) {
    return <CircularProgressComponent />;
  }
  return (
    <div>
      <div className="mt-4">
        <div className="d-flex justify-content-end">
          <div
            className="primary-background-button-table font-weight-bold"
            onClick={() => {
              setProjectModalState(true);
            }}
          >
            {t("add_project")}
          </div>
        </div>

        <div className="rct-block mg-top-40">
          <div className="d-flex justify-content-between align-items-center border-bottom-centrify p-4">
            <div className="d-flex flex-column w-100">
              <div className="mb-2">
                {variables._name && (
                  <Chip
                    label={variables._name}
                    onDelete={() => handleChipDelete()}
                    className={classes.chip}
                  />
                )}
              </div>
              <div className="d-flex justify-content-between align-items-end table-top-bar ">
                <div className="d-flex align-items-center filters">
                  <Form
                    className="mt-2 mt-sm-0"
                    onSubmit={(e) => handleSearch(e)}
                  >
                    <FormGroup className="d-inline-block gift-search-input">
                      <Input
                        type="text"
                        name="value"
                        id="project-search"
                        maxLength={10}
                        placeholder={t("search")}
                        style={{ paddingRight: "40px" }}
                      />
                      <button type="submit" className="gift-search-icon">
                        <i className="zmdi zmdi-search centrify-blue-color"></i>
                      </button>
                    </FormGroup>
                  </Form>
                  <FormGroup className="d-inline-block gift-search-input ml-3">
                    <Input
                      name="filter"
                      type="select"
                      required
                      className="border_centrify_blue_color"
                      onChange={filterChange}
                    >
                      <option value={"all"}>All</option>
                      <option value={"active"}>Active</option>
                      <option value={"inactive"}>InActive</option>
                      <option value={"cancelled"}>Cancelled</option>
                    </Input>
                  </FormGroup>
                </div>
              </div>

              <div className="mt-4" style={{ position: "relative" }}>
                {engagementReducer.stateloading && <TableLoader />}
                <Table responsive className="main-table">
                  <thead className="main-table-header">
                    <tr>
                      <th>
                        <span className="ml-4 mb-0 d-flex">{t("name")}</span>
                      </th>
                      <th>{t("country")}</th>
                      <th>{t("region")}</th>
                      <th>{t("type")}</th>

                      <th>{t("date")}</th>
                      <th>{t("cancelled_date")}</th>
                      <th>{t("status")}</th>
                      <th>{t("actions")}</th>
                    </tr>
                  </thead>
                  <tbody className="main-table-body">{getProjectList()}</tbody>
                </Table>
              </div>

              {projectCount > 0 ? (
                <div className="mt-4 d-flex justify-content-center">
                  <Pagination
                    count={Math.ceil(projectCount / 10)}
                    page={variables.page}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                  />
                </div>
              ) : null}
              <AssignToProjectModal
                {...props}
                engagementId={props.match.params.id}
                modal={projectModalState}
                projects={null}
                toggle={() => {
                  setProjectModalState(!projectModalState);
                }}
                setSelectedProjects={setSelectedProjects}
                selectedProjects={selectedProjects}
                viewType={"detail_view"}
              />
              <ConfrimDialogue
                open={dialogue}
                handleClose={() => setDialogue(false)}
                action={() => handleCancel()}
                msg="Are you sure you want to cancel engagement project ?"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
