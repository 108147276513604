import { Avatar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BreadCrumbComponent from "components/Breadcrumbs";
import { Button, Form, FormGroup, Input, Table } from "reactstrap";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import History from "@history";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import TableLoader from "components/ErrorHandlers/TableLoader";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as Actions from "store/actions";
import qs from "query-string";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";

export default function Patients(props) {
    const dispatch = useDispatch();
    let querystr = qs.parse();
    let page = querystr.page ? JSON.parse(querystr.page) : 1;
    const [variables, setVariables] = useState({ ...querystr });
    const [patient, setPatient] = useState(null);
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    // const Auth = useSelector(({ auth }) => {
    //     return auth.login.success ? auth.user : false;
    // });
    const questionaireReducer = useSelector(({ questionaire }) => questionaire);

    useEffect(() => {
        console.log('inside effect');
        const q_str = qs.stringify(variables);
        History.push({ search: q_str });
        dispatch(Actions.setQuestionairesLoading(true));
        dispatch(Actions.getOptionPatients(variables, props.match.params.opId));
    }, [variables]);

    useEffect(() => {
        setPatient(questionaireReducer);
    }, [questionaireReducer]);
    if (patient && patient.error) {
        return <ErrorMessageComponents message="Cannot Fetch Patients" />;
    }

    if (patient && patient ?.opPatientList === null) {
        return <CircularProgressComponent />;
    }

    const getPatientList =
        patient && patient ?.opPatientList !== null && patient.opPatientList.length > 0 ? (
            patient.opPatientList.map((item) => {
                return (
                    <tr>
                        <td>
                            <div
                                onClick={() => History.push(`/patient-profile/${item.id}`)}
                                className="ml-4 d-flex align-items-center cursor-pointer"
                            >
                                <Avatar className="mr-3 medicine-pic" src={item.image} />{" "}
                                <p className="mb-0 prominent mr-4">{item.firstName}</p>
                            </div>
                        </td>
                        <td>{item.email}</td>
                        <td>{item.type}</td>
                    </tr>
                );
            })
        ) : (
                <tr>
                    <td colspan={7} className="text-center font-weight-bold">
                        {t("nothing_to_display")}
                    </td>
                </tr>
            );

    const handlePageChange = (e, page) => {
        setVariables({
            ...variables,
            page: page,
        });
    };

    const handleSearch = (e) => {
        e.preventDefault();
        let search = document.getElementById("op-patient-search").value;
        setVariables({
            ...variables,
            _firstName: search,
            page: 1,
        });
    };
    return (
        <div>
            <div className="mt-4">
                <BreadCrumbComponent
                    active={"Patients"}
                    previous={[
                        { name: t("all_questionnaires"), link: "/questionnaires" },
                        // { name: t("details"), link: `/questionnaire/${props.match.params.id}` },
                    ]}
                />
                <div className="d-flex justify-content-between align-items-end table-top-bar">
                    <div className="d-flex align-items-center filters">
                        <Form className="mt-2 mt-sm-0" onSubmit={(e) => handleSearch(e)}>
                            <FormGroup className="d-inline-block gift-search-input">
                                <Input
                                    type="text"
                                    name="value"
                                    id="op-patient-search"
                                    maxLength={10}
                                    placeholder={t("search")}
                                    style={{ paddingRight: "40px" }}
                                />
                                <button type="submit" className="gift-search-icon">
                                    <i className="zmdi zmdi-search centrify-blue-color"></i>
                                </button>
                            </FormGroup>
                        </Form>
                    </div>
                </div>
                {!open && (
                    <div className="mt-4" style={{ position: "relative" }}>
                        {questionaireReducer.loading && <TableLoader />}
                        <Table responsive className="main-table">
                            <thead className="main-table-header">
                                <tr>
                                    <th>
                                        <span className="ml-4 mb-0 d-flex">{t("name")}</span>
                                    </th>
                                    <th>{t("email")}</th>
                                    <th>{t("type")}</th>
                                </tr>
                            </thead>
                            <tbody className="main-table-body">{getPatientList}</tbody>
                        </Table>
                    </div>
                )}
                {questionaireReducer.opPatientCount > 0 ? (
                    <div className="mt-4 d-flex justify-content-center">
                        <Pagination
                            count={Math.ceil(questionaireReducer.opPatientCount / 10)}
                            page={page}
                            onChange={handlePageChange}
                            variant="outlined"
                            shape="rounded"
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
}
