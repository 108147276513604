import { Avatar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Input, Table } from "reactstrap";
import Pagination from "@material-ui/lab/Pagination";
import { CircularProgress } from "@material-ui/core";
import TableLoader from "components/ErrorHandlers/TableLoader";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as Actions from "store/actions";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function PatientFilterModal(props) {
  const dispatch = useDispatch();

  const classes = useStyles();
  const [variables, setVariables] = useState({ page: 1 });

  const { t } = useTranslation();

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  const patientReducer = useSelector(({ patient }) => patient);
  const projectReducer = useSelector(({ project }) => project);

  useEffect(() => {
    let vendor_id = Auth.vendor_id;
    dispatch(Actions.setPatientLoading(true));
    dispatch(Actions.getAllPatients(variables, vendor_id));
  }, [variables]);

  const handleSelectPatient = (e, id) => {
    if (props.selectedPatients) {
      const findIndex = props.selectedPatients.indexOf(id);
      if (findIndex > -1) {
        props.selectedPatients.splice(findIndex, 1);
      } else {
        props.selectedPatients.push(id);
      }
      props.setSelectedPatients([...props.selectedPatients]);
    }
  };

  const getPatientList = () => {
    if (patientReducer?.loading == false) {
      return patientReducer &&
        patientReducer.patientList &&
        patientReducer.patientList.length > 0 ? (
        patientReducer.patientList.map((item) => {
          return (
            <tr>
              <td>
                <input
                  type="checkbox"
                  style={{ transform: "scale(1.5)" }}
                  id={item.id}
                  onChange={(e) => handleSelectPatient(e, item.id)}
                  defaultChecked={
                    (props.selectedPatients &&
                      props.selectedPatients.includes(item.id)) ||
                    (props.patientsList && props.patientsList.includes(item.id))
                  }
                  checked={
                    (props.selectedPatients &&
                      props.selectedPatients.includes(item.id)) ||
                    (props.patientsList && props.patientsList.includes(item.id))
                  }
                />
              </td>
              <td>
                <div className="ml-4 d-flex align-items-center">{item.id}</div>
              </td>
              <td>
                <div className="ml-4 d-flex align-items-center">
                  <Avatar className="mr-3 medicine-pic" src={item.image} />
                  <p className="mb-0 mr-4">
                    {item.firstName + " " + item.lastName}
                  </p>
                </div>
              </td>
              <td>
                <div className="ml-4 d-flex align-items-center">
                  <p className="mb-0 mr-4">{item.email}</p>
                </div>
              </td>
            </tr>
          );
        })
      ) : (
        <tr>
          <td colspan={5} className="text-center font-weight-bold">
            {t("nothing_to_display")}
          </td>
        </tr>
      );
    } else {
      return <tr></tr>;
    }
  };

  const handlePageChange = (e, pagee) => {
    setVariables({
      ...variables,
      page: pagee,
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    props.setSelectedPatients([]);
    let search = document.getElementById("patient-searchh").value;
    setVariables({
      ...variables,
      _firstName: search,
      page: 1,
    });
  };
  const handleChipDelete = () => {
    document.getElementById("patient-searchh").value = "";
    setVariables({
      ...variables,
      _firstName: "",
      page: 1,
    });
  };

  return (
    <div className="">
      <div className="mt-4">
        {variables._firstName && (
          <Chip
            label={variables._firstName}
            onDelete={() => handleChipDelete()}
            className={classes.chip}
          />
        )}
        <div className="d-flex justify-content-between align-items-end table-top-bar">
          <div className="d-flex align-items-center filters">
            <Form className="mt-2 mt-sm-0" onSubmit={(e) => handleSearch(e)}>
              <FormGroup className="d-inline-block gift-search-input">
                <Input
                  type="text"
                  name="value"
                  id="patient-searchh"
                  maxLength={10}
                  placeholder={t("search")}
                />
                <button type="submit" className="gift-search-icon">
                  <i className="zmdi zmdi-search centrify-blue-color"></i>
                </button>
              </FormGroup>
            </Form>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              color="none"
              onClick={() => props.handleAssignToPatient()}
              disabled={
                patientReducer?.loading ||
                patientReducer?.error ||
                props.selectedPatients.length == 0
                  ? true
                  : false
              }
              className="primary-background-button-table font-weight-bold d-inline "
            >
              {projectReducer.assignLoading ? (
                <CircularProgress size={18} />
              ) : (
                t("assign")
              )}
            </Button>
          </div>
        </div>
        <div className="mt-4" style={{ position: "relative" }}>
          {patientReducer?.loading && <TableLoader />}
          {patientReducer?.error ? (
            <div className="text-center">
              <h2>{t("something_went_wrong")}!</h2>
            </div>
          ) : (
            <Table responsive className="main-table">
              <thead className="main-table-header">
                <tr>
                  <th>{t("pick")}</th>
                  <th>
                    <span className="ml-4 mb-0 d-flex">{t("id")}</span>
                  </th>
                  <th>
                    <span className="ml-4 mb-0 d-flex">{t("name")}</span>
                  </th>
                  <th>
                    <span className="ml-4 mb-0 d-flex">{t("email")}</span>
                  </th>
                </tr>
              </thead>
              <tbody className="main-table-body">{getPatientList()}</tbody>
            </Table>
          )}
        </div>
      </div>

      {patientReducer.patientCount > 0 && patientReducer.error == null ? (
        <div className="mt-4 d-flex justify-content-center">
          <Pagination
            count={Math.ceil(patientReducer?.patientCount / 10)}
            page={variables.page}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
          />
        </div>
      ) : null}
    </div>
  );
}
