import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Toggle from "react-toggle";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumbComponent from "components/Breadcrumbs";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import * as Actions from "store/actions";

export default function Questionaires(props) {
  const [questionaire, setQuestionaire] = useState(null);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });

  const questionaireReducer = useSelector(({ questionaire }) => questionaire);

  useEffect(() => {
    dispatch(Actions.setQuestionairesLoading(true));
    dispatch(
      Actions.getPatientSolvedQuestionaire(
        props.match.params.pId,
        props.match.params.qId
      )
    );
  }, [props.match.params.pId, props.match.params.qId]);

  useEffect(() => {
    setQuestionaire(questionaireReducer?.solvedQuestionaire);
  }, [questionaireReducer]);

  if (questionaireReducer && questionaireReducer.error) {
    return <ErrorMessageComponents message="Questionnaire Not Attempted" />;
  }

  if (
    questionaire === null ||
    questionaireReducer.loading ||
    questionaire?.id !== Number(props.match.params.qId) ||
    questionaire?.solvedQuestionnaire[0]?.patient_id !==
      Number(props.match.params.pId)
  ) {
    return <CircularProgressComponent />;
  }

  return (
    <div>
      <div className="mt-4">
        <BreadCrumbComponent
          active={"Solved Questionnaire"}
          previous={[
            { name: t("all_questionnaires"), link: "/questionnaires" },
            // { name: t("details"), link: `/questionnaire/${props.match.params.id}` },
          ]}
        />
        {/* <h1 className="form-heading font-weight-bold d-flex">{t("questionnaire_details")}</h1> */}
        <div className="d-flex justify-content-end align-items-center">
          <div className="font-weight-bold">Show Score:</div>
          <Toggle
            className="ml-2"
            checked={show}
            onChange={() => setShow(!show)}
          />
        </div>
        <div className="rct-block mg-top-40">
          <div className="d-flex justify-content-between align-items-center border-bottom-centrify p-4">
            <div className="d-flex flex-column w-100">
              <div className="d-flex justify-content-between align-items-center">
                <h2 className="font-weight-bold">{questionaire.title}</h2>
                {show && (
                  <h3 className="font-weight-bold clr-yelloww">
                    {" "}
                    Score: {questionaire?.solvedQuestionnaire[0]?.score}
                  </h3>
                )}
              </div>
              <p className="mt-5">{questionaire.shortDescription}</p>
            </div>
          </div>
        </div>
        {questionaire.questions.length > 0 && (
          <>
            {questionaire.questions.map((ques, index) => (
              <div className="rct-block mt-5">
                <div className="d-flex flex-column p-4">
                  <div className="d-flex justify-content-between">
                    <h3 className="main-heading font-weight-bold d-flex">
                      Q{index + 1}:{"  "}
                      {ques.title}
                    </h3>
                    {show && ques?.questionType === "MCQ" && (
                      <h3 className="font-weight-bold clr-yelloww">
                        {" "}
                        Score: {ques?.answers[0]?.score}
                      </h3>
                    )}
                  </div>
                  {ques.questionType === "TEXT" ? (
                    <p className="">{ques.answer}</p>
                  ) : (
                    <div className="d-flex flex-column mt-4">
                      {ques.options.map((op) => (
                        <div className="mx-2">
                          <input
                            type="checkbox"
                            name="type"
                            style={{ transform: "scale(1.5)" }}
                            id={op.text}
                            value={op.text}
                            defaultChecked={op.answer}
                            disabled
                          />
                          <label for="item" className="ml-4 font-14 lead">
                            {op.option}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}
