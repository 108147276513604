import { Avatar, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "store/actions";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import moment from "moment";
import { Skeleton } from "@material-ui/lab";

export default function MeetingsToday(props) {
  const dispatch = useDispatch();
  let tFrmt = "HH:mm:ss";
  let frmt2 = "YYYY-MM-DD HH:mm:ss";
  let dateN = new Date();
  const { t } = useTranslation();
  let utcNow = moment(dateN).utc().format(frmt2);
  //convert date Range to utc before api call
  const [variables, setVariables] = useState({
    dateRange: [moment().format(`YYYY-MM-DD`), moment().format("YYYY-MM-DD")],
    dateToday: utcNow,
  });
  const [activity, setActivity] = useState(null);
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  const activityReducer = useSelector(({ activity }) => activity);

  useEffect(() => {
    const vendor_id = Auth.vendor_id;
    dispatch(Actions.setActivityLoading(true));
    dispatch(
      Actions.getUpcomingMeetingActivitesOnMeeting(variables, vendor_id)
    );
  }, [variables]);

  useEffect(() => {
    setActivity(activityReducer);
  }, [activityReducer]);

  if (activity && activity.error) {
    return <ErrorMessageComponents message="Cannot Fetch Activities" />;
  }

  if (activity === null || !activity.upcomingMeetingList) {
    return <CircularProgressComponent />;
  }

  const getUpcomingMeetingList = activity.loading ? (
    <div className="col-12 mt-2" style={{ height: "200px" }}>
      <Skeleton style={{ transform: "none" }} height={120} />
    </div>
  ) : activity.upcomingMeetingList &&
    activity.upcomingMeetingList.length > 0 ? (
    activity.upcomingMeetingList.map((item) => {
      return (
        <div className="">
          <h1 className="main-heading d-flex mb-2">
            {t("meeting_activities")}
          </h1>
          {item.activities.map((item) => {
            return (
              <div
                className="w-100 my-2 d-flex flex-row justify-content-between bg-white shadow"
                style={{
                  borderRadius: "20px",
                  paddingTop: "30px",
                  paddingBottom: "30px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                }}
              >
                <div className="font-weight-bold text-capitalize text-black">
                  {item.title}
                </div>
                <div className="meeting-today-time-badge clr-yelloww font-12 font-weight-bold">
                  {" "}
                  {moment(item.time, tFrmt)
                    .utcOffset(item.tz)
                    .utc(true)
                    .local()
                    .format("hh:mm A")}
                </div>
              </div>
            );
          })}
        </div>
      );
    })
  ) : (
    <div
      className="col-12 align-items-center justify-content-center"
      style={{ height: "200px" }}
    >
      <p className="text-center mt-4 font-weight-bold">No Activities Found</p>
    </div>
  );

  return (
    // <div className="row pd-top-40">
    <div className="">{getUpcomingMeetingList}</div>
  );
}
