import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Graphs from './graphs'
import Stats from './stats'
import { useDispatch, useSelector } from 'react-redux'
import * as Actions from 'store/actions'
import ErrorMessageComponents from 'components/ErrorHandlers/ErrorMessageComponents'
import CircularProgressComponent from 'components/ErrorHandlers/CircularProgressComponent'
import moment from 'moment'
import qs from 'query-string'
import History from '@history'
import { Button, Input } from 'reactstrap'
import axios from 'axios'
import Domain from 'lib/Config'
import { v4 as uuidv4 } from 'uuid'

const s3Cdn = axios.create({})

export default function Report (props) {
  const querystr = qs.parse(props.location.search)
  const dispatch = useDispatch()
  const ProjectReducer = useSelector(({ project }) => project)
  const [project, setProject] = useState(null)
  let frmt2 = 'YYYY-MM-DD HH:mm:ss'
  let dateN = new Date()
  let utcNow = moment(dateN)
    .utc()
    .format(frmt2)
  const [variables, setVariables] = useState({
    ...querystr,
    dateRange: [moment().format(`YYYY-MM-DD`), moment().format('YYYY-MM-DD')],
    dateToday: utcNow
  })
  const [images, setImages] = useState([])
  const [imageLoading, setImageLoading] = useState(false)
  const [comment, setComment] = useState('')
  const [commentError, setCommentError] = useState(false)
  const [dateRange, setDateRange] = useState('')
  const [files, setFiles] = useState([])
  const [fileLoading, setFileLoading] = useState(false)
  const [startDate, setStartDate] = useState(
    variables.dateRange
      ? moment(new Date()).format(`YYYY-MM-DD`)
      : moment().format('YYYY-MM-DD')
  )
  const [endDate, setEndDate] = useState(
    variables.dateRange
      ? moment(new Date()).format(`YYYY-MM-DD`)
      : moment().format('YYYY-MM-DD')
  )
  const { t } = useTranslation()

  useEffect(() => {
    let patient_id = props.match.params.patientId
    const q_str = qs.stringify(variables)
    History.push({ search: q_str })
    if (patient_id) {
      dispatch(Actions.setProjectLoading(true))
      dispatch(Actions.getProjectStatsInReport(variables, patient_id))
      dispatch(Actions.getProjectAdditionalStatsInReport(patient_id))
    }
  }, [dispatch, variables])

  useEffect(() => {
    setProject(ProjectReducer)
  }, [ProjectReducer])

  console.log(' ProjectReducer --->', ProjectReducer)

  const onDrop = useCallback(
    img => {
      setImageLoading(true)
      handleFileUpload(img[0])
    },
    [images]
  )

  const onDropFile = useCallback(
    file => {
      setFileLoading(true)
      handleFileUpload(file[0])
    },
    [files]
  )
  const removeImg = id => {
    setImages(images.filter(img => img.id !== id))
  }

  const removeFile = id => {
    setFiles(files.filter(file => file.id !== id))
  }
  const handleFileUpload = data => {
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
      },
      header: {
        'Content-Type': data.type
      }
    }
    getSignedURL(data.name, data.type).then(rsp => {
      console.log(rsp.data)
      const request = s3Cdn
        .put(rsp.data.result.postUrl, data, config)
        .then(res => {
          let type = data.type
          let _type = type.split('/')[0]
          let name = data.name
          if (_type == 'application') {
            let file = {
              id: uuidv4(),
              File: rsp.data.result.getUrl,
              type: _type,
              name: name
            }
            setFiles([...files, file])
            setFileLoading(false)
          } else {
            let image = {
              id: uuidv4(),
              File: rsp.data.result.getUrl,
              type: _type
            }
            setImages([...images, image])
            setImageLoading(false)
          }
        })
        .catch(err => {
          console.log(err.response)
        })
    })
  }
  const handleFilter = () => {
    if (startDate && endDate)
      setVariables({ ...variables, dateRange: [startDate, endDate], page: 1 })
  }
  const removeFilter = () => {
    setStartDate(moment().format('YYYY-MM-DD'))
    setEndDate(moment().format('YYYY-MM-DD'))
    setVariables({
      ...variables,
      dateRange: [moment().format(`YYYY-MM-DD `), moment().format('YYYY-MM-DD')]
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    let patient_id = props.match.params.patientId
    if (comment === '') {
      setCommentError(true)
    } else {
      setCommentError(false)
      let obj = {}
      obj.comment = comment
      obj.images = images
      obj.files = files
      if (variables.dataRange === '' || variables.dateRange === undefined) {
        console.log('not slected', variables.dateRange)
        obj.startDate = new Date()
        obj.endDate = new Date()
      } else {
        console.log(variables.dateRange)
        obj.startDate = new Date(variables.dateRange[0])
        obj.endDate = new Date(variables.dateRange[1])
      }
      obj.doses = ProjectReducer.projectStats
      obj.totalVisits = 23
      let lastVisit = moment('23-12-2020', 'DD-MM-YYYY')
      obj.lastVisit = new Date(lastVisit.format('YYYY-MM-DD'))
      obj.totalTime = 203
      obj.reportSent = 33
      obj.patient_id = patient_id
      obj.images = images
      obj.files = files
      dispatch(Actions.setPatientReportLoading(true))
      dispatch(Actions.addPatientReport(obj))
    }
  }

  const getSignedURL = (key, type) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Domain}/api/vendor/s3getsignedurl?key=${key}&type=${type}`)

        .then(data => {
          resolve(data)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  }
  if (project && project.error) {
    return <ErrorMessageComponents message='Cannot get reports' />
  }

  if (project == null || !project.projectStats || project.loading) {
    return <CircularProgressComponent />
  }

  return (
    <div className='patient-profile report-card'>
      <div className='rct-block'>
        <div className='rct-block-content'>
          <div className='border-bottom-centrify d-flex justify-content-between flex-wrap pb-2'>
            <h1 className='main-heading d-flex'>{t('report')}</h1>

            <div className='d-flex justify-content-end align-items-center flex-wrap date-header'>
              <div className='d-flex flex-wrap align-items-center date-range'>
                <p className='mb-0'>{t('from')}</p>
                <Input
                  type='date'
                  value={startDate}
                  onChange={e => setStartDate(e.target.value)}
                />
                <p className='mb-0'>{t('to')}</p>
                <Input
                  type='date'
                  value={endDate}
                  onChange={e => setEndDate(e.target.value)}
                />
                <Button color='primary' className='mr-2' onClick={handleFilter}>
                  {t('apply_filters')}
                </Button>
                <Button color='danger' className='mr-2' onClick={removeFilter}>
                  {t('remove_filters')}
                </Button>
              </div>
            </div>
          </div>
          <div className='row '>
            <div className='col-md-8'>
              <Stats
                data={ProjectReducer.projectDoseStats}
                additionalData={ProjectReducer.projectAdditionalStats}
              />
            </div>
          </div>
          <div className='row' style={{ marginTop: '2rem' }}>
            <div className='col-md-8'>
              <Graphs
                data={ProjectReducer.projectStats}
                additionalData={ProjectReducer.projectAdditionalStats}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
