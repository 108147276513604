import adminRoles from "lib/adminRoles";

// nav links
const navlinks = [
  {
    id: 0,
    menu_title: "dashboard",
    menu_icon: "dashboard",
    path: "/dashboard",
    child_routes: null,
    auth: adminRoles.dashboard,
  },
  {
    id: 1,
    menu_title: "manage_projects",
    menu_icon: "zmdi zmdi-view-dashboard",
    path: "/projects",
    child_routes: null,
    auth: adminRoles.projects,
  },
  {
    id: 2,
    menu_title: "employees",
    menu_icon: "zmdi zmdi-accounts",
    path: "/employees",
    child_routes: null,
    auth: adminRoles.employees,
  },
  {
    id: 3,
    menu_title: "patients",
    menu_icon: "zmdi zmdi-accounts",
    path: "/patients",
    child_routes: null,
    auth: adminRoles.patients,
  },
  {
    id: 4,
    menu_title: "medicines",
    menu_icon: "zmdi zmdi-plus-square",
    path: "/medicines",
    child_routes: null,
    auth: adminRoles.medicines,
  },
  {
    id: 5,
    menu_title: "statistics_insights",
    menu_icon: "zmdi zmdi-chart",
    path: "/stats",
    child_routes: null,
    auth: adminRoles.stats,
  },
  {
    id: 6,
    menu_title: "meeeting_room",
    menu_icon: "zmdi zmdi-comments",
    path: "/meeting",
    child_routes: null,
    auth: adminRoles.notifications,
  },
  {
    id: 7,
    menu_title: "recommendations",
    menu_icon: "zmdi zmdi-info",
    path: "/notifications",
    child_routes: null,
    auth: adminRoles.notifications,
  },
  {
    id: 8,
    menu_title: "stores",
    menu_icon: "zmdi zmdi-store",
    child_routes: [
      { path: "/products", menu_title: "products" },
      { path: "/categories", menu_title: "categories" },
    ],
    auth: adminRoles.notifications,
  },
  {
    id: 9,
    menu_title: "orders",
    menu_icon: "zmdi zmdi-card-travel",
    path: "/orders/0",
    child_routes: null,
    auth: adminRoles.orders,
  },
  {
    id: 10,
    menu_title: "articles",
    menu_icon: "zmdi zmdi-card-travel",
    path: "/articles",
    child_routes: null,
    auth: adminRoles.orders,
  },
  {
    id: 11,
    menu_title: "engagements",
    menu_icon: "zmdi zmdi-file",
    path: "/engagements",
    child_routes: null,
    auth: adminRoles.questionaires,
  },
  {
    id: 12,
    menu_title: "vchat",
    menu_icon: "zmdi zmdi-android-alt",
    path: "/treeQuestionnaires",
    child_routes: null,
    auth: adminRoles.questionaires,
  },
  {
    id: 13,
    menu_title: "questionaires",
    menu_icon: "zmdi zmdi-help-outline",
    path: "/questionnaires",
    child_routes: null,
    auth: adminRoles.questionaires,
  },

  {
    id: 14,
    menu_title: "reports",
    menu_icon: "zmdi zmdi-file-text",
    path: `/reports?tab=${0}`,
    child_routes: null,
    auth: adminRoles.reports,
  },
  {
    id: 15,
    menu_title: "settings",
    menu_icon: "zmdi zmdi-settings",
    path: "/settings",
    className: "menu-item-border mt-2 ",
    child_routes: null,
    auth: adminRoles.notifications,
  },
];

export default navlinks;
