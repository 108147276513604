import BreadCrumbComponent from "components/Breadcrumbs";
import React, { useEffect } from "react";
import Profile from "./profile";
import Activity from "./activity";
import Report from "./report/index";
import Inventory from "./inventory";
import Order from "./orders";
import Engagement from "./engagements";
import Project from "./projects";
import ChatHistory from "./chatHistory.js";
import DocumentationPatient from "./documentation/documentation";

import { useTranslation } from "react-i18next";
import * as Actions from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import History from "@history";

export default function PatientProject(props) {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(1);
  const [patient, setPatient] = React.useState(null);
  const { t } = useTranslation();
  const patientReducer = useSelector(({ patient }) => patient);
  console.log(History);

  useEffect(() => {
    dispatch(Actions.setPatientLoading(true));
    dispatch(Actions.getPatientProfile(props.match.params?.patientId));
  }, []);

  useEffect(() => {
    setPatient(patientReducer);
  }, [patientReducer]);

  if (patient && patient.error) {
    return <ErrorMessageComponents message={t("cannot_get_patient_details")} />;
  }

  if (!patient || !patient.details || patient.loading) {
    return <CircularProgressComponent />;
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const updateProfile = (data) => {
    dispatch(Actions.setPatientLoading(true));
    dispatch(Actions.updatePatientProfile(data));
  };

  let tabs = [
    t("information"),
    t("activity"),
    t("inventory"),
    t("documentation"),
    t("report"),
    t("order"),
    t("engagements"),
    t("projects"),
    t("chatHistory"),
  ];

  let getBreadCrumbComponent = () => {
    return (
      <BreadCrumbComponent
        active={props.match.params?.patientName}
        previous={
          props.location.pathname.includes("patient-profile")
            ? [
                {
                  name: t("patients"),
                  link: `/patients`,
                },
              ]
            : [
                { name: t("manage_projects"), link: "/projects" },
                {
                  name: t("patients"),
                  link: `/project/${props.match.params?.projectName}/${props.match.params?.projectId}/0`,
                },
              ]
        }
      />
    );
  };
  return (
    <div>
      <div className="">{getBreadCrumbComponent()}</div>
      <div className=" d-flex flex-wrap align-items-center tabs">
        {tabs.map((item, index) => {
          return (
            <p
              onClick={(e) => {
                History.push({ search: null });
                setValue(index);
              }}
              className={`${
                index == value ? "active-tab" : ""
              } mb-0 margin-tabs mt-2 cursor-pointer`}
            >
              {item}
            </p>
          );
        })}
      </div>
      <div className="mt-4">
        {value == 0 ? (
          <div className="">
            <Profile
              updateProfile={updateProfile}
              patientData={patientReducer.details}
              statusLoading={patientReducer.statusLoading}
            />
          </div>
        ) : value == 1 ? (
          <div className="">
            <Activity {...props} />
          </div>
        ) : value == 2 ? (
          <div className="">
            <Inventory {...props} />
          </div>
        ) : value == 3 ? (
          <div className="">
            <DocumentationPatient {...props} />
          </div>
        ) : value == 4 ? (
          <div>
            <Report {...props} />
          </div>
        ) : value == 5 ? (
          <div>
            <Order {...props} />
          </div>
        ) : value == 6 ? (
          <div>
            <Engagement {...props} />
          </div>
        ) : value == 7 ? (
          <div>
            <Project {...props} />
          </div>
        ) : value == 8 ? (
          <div>
            <ChatHistory {...props} />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
