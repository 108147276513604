import React, { useState, useEffect } from 'react'
import { Avatar } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import * as Actions from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'
import History from '@history'
import ConfrimDialogue from 'components/Dialogue/ConfrimDialogue'
import ErrorMessageComponents from 'components/ErrorHandlers/ErrorMessageComponents'
import CircularProgressComponent from 'components/ErrorHandlers/CircularProgressComponent'
import { Form, FormGroup, Input, Table } from 'reactstrap'
import TableLoader from 'components/ErrorHandlers/TableLoader'
import Pagination from '@material-ui/lab/Pagination'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'
import AssignToPatientModal from './patientModal'

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5)
  }
}))

export default function Patients (props) {
  const dispatch = useDispatch()

  const classes = useStyles()
  const { t } = useTranslation()
  const projectReducer = useSelector(({ project }) => project)

  const [dialogue, setDialogue] = useState(false)
  const [patientModalState, setPatientModalState] = useState(false)
  const [patient, setPatient] = useState(null)
  const [patients, setPatients] = useState([])
  const [patientCount, setPatientCount] = useState(0)
  const [selectedPatients, setSelectedPatients] = useState([])
  const [variables, setVariables] = useState({ page: 1 })

  useEffect(() => {
    if (props.match.params.id) {
      let project_id = props.match.params.id
      dispatch(Actions.setProjectStateLoading(true))
      dispatch(Actions.getProjectPatients(project_id, variables))
    }
  }, [props.match.params.tab, variables])
  useEffect(() => {
    if (projectReducer) {
      if (projectReducer.posted) {
        setPatientModalState(false)
      }
      if (
        projectReducer &&
        projectReducer.tabContentList &&
        projectReducer.tabContentType == 'patients'
      ) {
        setPatients(projectReducer.tabContentList)
        setPatientCount(projectReducer.tabContentListCount)
      }
      if (projectReducer.error) {
        setPatients([])
        setPatientCount(0)
      }
    }
  }, [projectReducer])
  useEffect(() => {
    if (patientModalState == false) {
      setSelectedPatients([])
    }
  }, [patientModalState])
  useEffect(() => {
    if (projectReducer.posted) {
      setPatientModalState(false)
    }
  }, [projectReducer])

  const getProjectPatientList = () => {
    if (projectReducer.stateloading == false) {
      return patients && patients.length > 0 ? (
        patients.map(item => {
          return (
            <tr>
              <td
                style={{ maxWidth: '250px' }}
                className='cursor-pointer'
                onClick={() => {
                  History.push(
                    `/patientProject/${props.match.params.projectName}/${item
                      .projectpatient.firstName +
                      ' ' +
                      item.projectpatient.lastName}/${item.patient_id}/${
                      props.match.params.id
                    }`
                  )
                }}
              >
                <div className='ml-4 d-flex align-items-center'>
                  <Avatar
                    className='mr-3 medicine-pic'
                    src={
                      item?.projectpatient?.image
                        ? item?.projectpatient?.image
                        : null
                    }
                  />{' '}
                  <p className='mr-2 mb-0 prominent'>
                    {item?.projectpatient?.firstName +
                      ' ' +
                      item?.projectpatient?.lastName}
                  </p>
                </div>
              </td>
              <td>{item?.projectpatient?.email}</td>
              <td>{item?.projectpatient?.dob}</td>
              <td>{item?.projectpatient?.phone}</td>
              <td style={{ width: '140px' }}>
                {item?.projectpatient?.address}
              </td>
              <td>{item?.adherenceScore}</td>
              <td
                onClick={() => {
                  setDialogue(!dialogue)
                  setPatient(item)
                }}
              >
                <p className=' mt-2 cursor-pointer centrify-blue-color'>
                  {t('unAssign')}
                </p>
              </td>
            </tr>
          )
        })
      ) : (
        <tr>
          <td colspan={9} className='text-center font-weight-bold'>
            {t('nothing_to_display')}
          </td>
        </tr>
      )
    } else {
      return <tr></tr>
    }
  }

  const handlePageChange = (e, page) => {
    setVariables({
      ...variables,
      page: page
    })
  }

  const handleSearch = e => {
    e.preventDefault()
    let search = document.getElementById('patient-search').value
    setVariables({
      ...variables,
      _name: search,
      page: 1
    })
  }
  const handleChipDelete = () => {
    document.getElementById('patient-search').value = ''
    setVariables({
      ...variables,
      _name: '',
      page: 1
    })
  }

  const handleUnAssign = () => {
    let projectId = props.match.params.id
    if (patient && projectId) {
      dispatch(Actions.setProjectStateLoading(true))
      dispatch(
        Actions.unAssignPatientProject(projectId, {
          project_id: patient.project_id,
          patient_id: patient.patient_id,
          id: patient.id
        })
      )
      setDialogue(!dialogue)
    }
  }

  if (projectReducer && projectReducer.tabError) {
    return <ErrorMessageComponents message={t('cannot_fetch_patients')} />
  }

  if (projectReducer === null || projectReducer.loading) {
    return <CircularProgressComponent />
  }

  return (
    <div>
      <div className='mt-4'>
        <div className='d-flex justify-content-end'>
          <div
            className='primary-background-button-table font-weight-bold'
            onClick={() => {
              setPatientModalState(true)
            }}
          >
            {t('assign_patient')}
          </div>
        </div>

        <div className='rct-block mg-top-40'>
          <div className='d-flex justify-content-between align-items-center border-bottom-centrify p-4'>
            <div className='d-flex flex-column w-100'>
              <div className='mb-2'>
                {variables._name && (
                  <Chip
                    label={variables._name}
                    onDelete={() => handleChipDelete()}
                    className={classes.chip}
                  />
                )}
              </div>
              <div className='d-flex justify-content-between align-items-end table-top-bar '>
                <div className='d-flex align-items-center filters'>
                  <Form
                    className='mt-2 mt-sm-0'
                    onSubmit={e => handleSearch(e)}
                  >
                    <FormGroup className='d-inline-block gift-search-input'>
                      <Input
                        type='text'
                        name='value'
                        id='patient-search'
                        maxLength={10}
                        placeholder={t('search')}
                        style={{ paddingRight: '40px' }}
                      />
                      <button type='submit' className='gift-search-icon'>
                        <i className='zmdi zmdi-search centrify-blue-color'></i>
                      </button>
                    </FormGroup>
                  </Form>
                </div>
              </div>

              <div className='mt-4' style={{ position: 'relative' }}>
                {projectReducer.stateloading && <TableLoader />}
                <Table responsive className='main-table'>
                  <thead className='main-table-header'>
                    <tr>
                      <th>
                        <span className='d-flex ml-4 mb-0'>{t('name')}</span>
                      </th>
                      <th>{t('email')}</th>
                      <th>{t('date_of_birth')}</th>
                      <th>{t('phone')}</th>
                      <th>{t('address')}</th>
                      <th>{t('7_day_adherence_score')}</th>
                      <th>{t('unAssign')}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className='main-table-body'>
                    {getProjectPatientList()}
                  </tbody>
                </Table>
              </div>
              {patientCount && patientCount > 0 ? (
                <div className='mt-4 d-flex justify-content-center'>
                  <Pagination
                    count={Math.ceil(patientCount / 10)}
                    page={variables.page + 1}
                    onChange={handlePageChange}
                    variant='outlined'
                    shape='rounded'
                  />
                </div>
              ) : null}
              <AssignToPatientModal
                {...props}
                projectId={props.match.params.id}
                modal={patientModalState}
                toggle={() => {
                  setPatientModalState(!patientModalState)
                }}
                selectedPatients={selectedPatients}
                setSelectedPatients={setSelectedPatients}
              />
              <ConfrimDialogue
                open={dialogue}
                handleClose={() => {
                  setDialogue(false)
                  setPatient(null)
                }}
                action={() => handleUnAssign()}
                msg={t('are_you_sure') + '?'}
                subMsg={t('all_the_future_activities_will_be_removed')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
