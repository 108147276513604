import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Actions from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import TableLoader from "components/ErrorHandlers/TableLoader";
import Calendar from "./calendar";
import moment from "moment";

export default function EngagementChart(props) {
  const dispatch = useDispatch();

  const [chartData, setChartData] = useState([]);
  const [eventsInterval, setEventsInterval] = useState({});

  const engagementReducer = useSelector(({ engagement }) => engagement);
  console.log("engagementReducer --->", engagementReducer);
  console.log("chartData --->", chartData);

  const [variables, setVariables] = useState({ page: 1 });
  const { t } = useTranslation();

  const getZoneAdjustedDateTime = (date) => {
    var tz = moment.tz.guess();
    return moment(date).tz(tz);
  };
  useEffect(() => {
    if (props.match.params.id) {
      dispatch(Actions.setEngagementStateLoading(true));
      dispatch(Actions.getEngagementChart(props.match.params.id, variables));
    }
  }, [props.match.params.tab, variables]);

  useEffect(() => {
    if (engagementReducer) {
      if (
        engagementReducer &&
        engagementReducer.tabContentList &&
        engagementReducer.tabContentType == "chart"
      ) {
        let totalEvents = engagementReducer.tabContentList.structuredArr.map(
          (event, index) => {
            let startingDate = getZoneAdjustedDateTime(event.startDate);
            event.id = index;
            event.end = startingDate._d;
            event.start = startingDate._d;
            return event;
          }
        );
        let startDate = moment(engagementReducer.tabContentList.startDate).set({
          hour: 0,
          minute: 0,
          second: 0,
        });
        let endDate = moment(engagementReducer.tabContentList.endDate).set({
          hour: 0,
          minute: 0,
          second: 0,
        });
        console.log("Start Date --->", startDate);
        console.log("End Date---->", endDate);
        setEventsInterval({ start: startDate._d, end: endDate._d });
        setChartData(totalEvents);
      }
      if (engagementReducer.error) {
        setChartData([]);
      }
    }
  }, [engagementReducer]);

  if (engagementReducer.tabError) {
    return (
      <ErrorMessageComponents message={t("cannot_get_engagement_chart")} />
    );
  }

  if (engagementReducer === null) {
    return <CircularProgressComponent />;
  }

  return (
    <div>
      <div className="mt-4">
        <div className="rct-block mg-top-40">
          <div className="d-flex justify-content-between align-items-center border-bottom-centrify p-4">
            <div className="d-flex flex-column w-100">
              <div className="mt-4" style={{ position: "relative" }}>
                {engagementReducer.stateloading ? (
                  <TableLoader />
                ) : (
                  <Calendar
                    chartData={chartData}
                    eventsInterval={eventsInterval}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
