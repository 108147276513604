import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import * as AuthAction from "auth/store/actions";
import history from "@history";
import "./loginStyle.css";
import "animate.css";
import PharmaIcon from "assets/icons/pharma-icon.png";
import CareGiverIcon from "assets/icons/care-giver-icon.png";
import HospitalIcon from "assets/icons/hospital-icon.png";
import { Button, Input, Label } from "reactstrap";
import History from "@history";

function AdminLogin() {
  const dispatch = useDispatch();
  const [active, setActive] = useState(0);
  const loading = useSelector(({ auth }) => auth.login.loading);
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  let userType = [
    {
      name: "PharmaCompany",
      id: 0,
      icon: PharmaIcon,
    },
    {
      name: "CaregiverCompany",
      id: 1,
      icon: CareGiverIcon,
    },
    {
      name: "Hospital",
      id: 3,
      icon: HospitalIcon,
    },
  ];

  useEffect(() => {
    if (Auth) {
      history.push({
        pathname: "/profile",
      });
    }
  }, [Auth]);

  const onSubmitLogin = (e) => {
    e.preventDefault();
    let data = {
      email: e.target.email.value,
      password: e.target.password.value,
    };
    dispatch(AuthAction.setUserLoading(true));
    dispatch(AuthAction.loginAdmin(data));
  };
  console.log("loading --->", loading);
  return (
    <div>
      <div className="login-page">
        {loading && <LinearProgress />}
        <div className="row  h-100 p-4">
          <div className="col-md-6 justify-content-center d-flex align-items-center">
            <div>
              <h1 className="signup-black text-uppercase font-weight-bold text-center">
                Sign In
              </h1>
              {/* <div className="mt-4 user-type-controls">
                <p className="mb-0">Select your company type</p>
                <div className="d-flex flex-wrap w-100">
                  {userType.map((item) => {
                    return (
                      <div
                        className={`user-type-button p-2 d-flex align-items-center mr-4 cursor-pointer mt-4 ${
                          active == item.id ? "user-type-button-active" : ""
                        }`}
                        onClick={(e) => setActive(item.id)}
                      >
                        <img
                          className={`${active == item.id && "mr-2"}`}
                          src={item.icon}
                        />
                        {active == item.id && (
                          <p className="mb-0">{item.name}</p>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div> */}
              <div
                className="mt-4 w-25 mx-auto"
                style={{ border: "1px solid rgba(157, 169, 206, 0.5)" }}
              ></div>
              <div className="mt-4">
                <form onSubmit={(e) => onSubmitLogin(e)}>
                  <div>
                    <Label>Email</Label>
                    <Input
                      type="email"
                      required
                      name="email"
                      className="p-2"
                      style={{ width: "300px" }}
                    />
                  </div>
                  <div className="mt-4">
                    <Label>Password</Label>
                    <Input
                      type="password"
                      required
                      name="password"
                      className="p-2"
                    />
                  </div>
                  <div className="mt-2 text-right">
                    <Link to={`/forget-password`} className="clink-button">
                      Forgot Password?
                    </Link>
                  </div>
                  <div className="mt-4">
                    <Button
                      type="submit"
                      color="none"
                      className="primary-background-button w-100 font-weight-bold"
                    >
                      Sign In
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AdminLogin;
