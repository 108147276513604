import Domain from "lib/Config";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import History from "@history";
import moment from "moment";

export const GET_ALL_ACTIVITIES = "[ADMIN] GET_ALL_ACTIVITIES";
export const GET_UPCOMING_MEETING_ACTIVITIES =
  "[ADMIN] GET_UPCOMING_MEETING_ACTIVITIES";
export const GET_UPCOMING_MEETING_ALERTS =
  "[ADMIN] GET_UPCOMING_MEETING_ALERTS";
export const ACTIVITY_POSTED = "[ADMIN] ACTIVITY_POSTED";
export const ACTIVITY_LOADING = "[ADMIN] ACTIVITY_LOADING";
export const ACTIVITY_STATUS_LOADING = "[ADMIN] ACTIVITY_STATUS_LOADING";
export const ACTIVITY_ERROR = "[ADMIN] ACTIVITY_ERROR";
export const GET_SINGLE_ACTIVITIE_FOR_BULK =
  "[ADMIN] GET_SINGLE_ACTIVITIE_FOR_BULK";
export const GET_SINGLE_ACTIVITIE_FOR_BULK_ERROR =
  "[ADMIN] GET_SINGLE_ACTIVITIE_FOR_BULK_ERROR";
export const ACTIVITY_EMPTY = "[ADMIN] ACTIVITY_EMPTY";
export const GET_UPCOMING_MEETING_NOTIFICATION =
  "[ADMIN] GET_UPCOMING_MEETING_NOTIFICATION";

export function getAllActivites(data, patient_id, employee_id) {
  data.dateRange = [
    moment(`${data.dateRange[0]} 00:00:00`).utc().format("YYYY-MM-DD HH:mm:ss"),
    moment(`${data.dateRange[1]} 23:59:00`).utc().format("YYYY-MM-DD HH:mm:ss"),
  ];
  const request = axios.post(`${Domain}/api/vendor/fetchactivities`, {
    ...data,
    patient_id,
  });
  return (dispatch) =>
    request
      .then((response) => {
        // response.data.result.map();
        // console.log(JSON.parse(response.data.result.doseFiles));
        console.log(response);
        dispatch(getUpcomingMeetingActivitesForNotificatins(employee_id));
        return dispatch({
          type: GET_ALL_ACTIVITIES,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error.response);
        return dispatch({
          type: ACTIVITY_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get activity",
        });
      });
}

export function getUpcomingMeetingActivitesForAlert(data, vendor_id) {
  data.dateRange = [
    moment(`${data.dateRange[0]} 00:00:00`).utc().format("YYYY-MM-DD HH:mm:ss"),
    moment(`${data.dateRange[1]} 23:59:00`).utc().format("YYYY-MM-DD HH:mm:ss"),
  ];
  data.status = "pending";
  console.log(data, "get activity api call");
  const request = axios.post(`${Domain}/api/vendor/fetchMettingActivities`, {
    ...data,
    employee_id: vendor_id,
  });
  return (dispatch) =>
    request
      .then((response) => {
        // response.data.result.map();
        // console.log(JSON.parse(response.data.result.doseFiles));
        console.log(response.data.result, "called api");
        dispatch({
          type: GET_UPCOMING_MEETING_ALERTS,
          payload: response.data.result,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
}

export function getUpcomingMeetingActivitesOnMeeting(data, vendor_id) {
  data.dateRange = [
    data.dateToday,
    moment(`${data.dateRange[1]} 23:59:00`).utc().format("YYYY-MM-DD HH:mm:ss"),
  ];
  const request = axios.post(`${Domain}/api/vendor/fetchMettingActivities`, {
    ...data,
    employee_id: vendor_id,
  });
  return (dispatch) =>
    request
      .then((response) => {
        // response.data.result.map();
        // console.log(JSON.parse(response.data.result.doseFiles));

        return dispatch({
          type: GET_UPCOMING_MEETING_ACTIVITIES,
          payload: response.data.result,
        });
      })
      .catch((error) => {
        console.log(error.response);
        return dispatch({
          type: ACTIVITY_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get activity",
        });
      });
}

export function getUpcomingMeetingActivites(data, vendor_id) {
  console.log("Calling Upcoming meeting activities ---->", data);
  console.log("Vendor id --->", vendor_id);
  data.dateRange = [
    moment(`${data.dateRange[0]} 00:00:00`).utc().format("YYYY-MM-DD HH:mm:ss"),
    moment(`${data.dateRange[1]} 23:59:00`).utc().format("YYYY-MM-DD HH:mm:ss"),
  ];
  const request = axios.post(`${Domain}/api/vendor/fetchMettingActivities`, {
    ...data,
    employee_id: vendor_id,
  });
  return (dispatch) =>
    request
      .then((response) => {
        // response.data.result.map();
        // console.log(JSON.parse(response.data.result.doseFiles));
        console.log(response, "called");
        return dispatch({
          type: GET_UPCOMING_MEETING_ACTIVITIES,
          payload: response.data.result,
        });
      })
      .catch((error) => {
        console.log(error.response);
        return dispatch({
          type: ACTIVITY_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get activity",
        });
      });
}

export function addActivity(data) {
  console.log(data);
  const request = axios.post(`${Domain}/api/vendor/addActivity`, data);
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success("Activity Added Successfully");
        dispatch({
          type: ACTIVITY_POSTED,
          payload: true,
        });
        // let frmt2 = "YYYY-MM-DD HH:mm:ss";
        // let dateN = new Date();
        // let utcNow = moment(dateN).utc().format(frmt2);
        // const variables = {
        //   dateRange: [moment().format(`YYYY-MM-DD`), moment().format("YYYY-MM-DD")],
        //   dateToday: utcNow,
        // };
        // dispatch(getUpcomingMeetingActivitesForAlert(variables, data.employee_id));
        // dispatch(getAllActivites({}, data.patient_id));
        History.goBack();
      })
      .catch((error) => {
        // if (data.when > 1) {
        //   History.goBack();
        // } else {
        console.log("Error Adding Activity ---->", error.response);
        console.log(error);
        dispatch(setActivityLoading(false));
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : "Cannot Add Activity"
        );
        // }
      });
}

export function updateActivity(data, variables) {
  const request = axios.post(`${Domain}/api/vendor/updateActivity`, data);
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success("Activity Updated Successfully");
        dispatch({
          type: ACTIVITY_POSTED,
          payload: true,
        });
        dispatch(setActivityLoading(false));
        // dispatch(getAllActivites(variables, data.patient_id));
        History.goBack();
        // let frmt2 = "YYYY-MM-DD HH:mm:ss";
        // let dateN = new Date();
        // let utcNow = moment(dateN).utc().format(frmt2);
        // const variabless = {
        //   dateRange: [moment().format(`YYYY-MM-DD`), moment().format("YYYY-MM-DD")],
        //   dateToday: utcNow,
        // };
        // dispatch(getUpcomingMeetingActivitesForAlert(variabless, data.employee_id));
      })
      .catch((error) => {
        console.log(error.response, "this is error");

        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : "Cannot Update"
        );
        dispatch(setActivityLoading(false));
      });
}

export function meetingActivityAcknowledgement(data, employee_id) {
  const request = axios.post(`${Domain}/api/patient/acknowledgeAct`, data);
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success("Session Acknowledged Successfully");
        dispatch({
          type: ACTIVITY_POSTED,
          payload: true,
        });
        let frmt2 = "YYYY-MM-DD HH:mm:ss";
        let dateN = new Date();
        let utcNow = moment(dateN).utc().format(frmt2);
        const dataa = {
          dateRange: [
            moment().format(`YYYY-MM-DD`),
            moment().format("YYYY-MM-DD"),
          ],
          dateToday: utcNow,
        };
        dispatch(getUpcomingMeetingActivites(dataa, employee_id));
        // let frmt2 = "YYYY-MM-DD HH:mm:ss";
        // let dateN = new Date();
        // let utcNow = moment(dateN).utc().format(frmt2);
        // const variabless = {
        //   dateRange: [moment().format(`YYYY-MM-DD`), moment().format("YYYY-MM-DD")],
        //   dateToday: utcNow,
        // };
        // dispatch(getUpcomingMeetingActivitesForAlert(variabless, data.employee_id));
      })
      .catch((error) => {
        console.log(error.response, "error");
        dispatch(setActivityLoading(false));
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : "Cannot Update"
        );
      });
}

export function deleteActivity(data, variables, patient_id, employee_id) {
  const request = axios.post(`${Domain}/api/vendor/deletesingleact`, {
    id: data.id,
    date: data.date,
    patient_id,
  });
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success("Activity Deleted Successfully");
        dispatch({
          type: ACTIVITY_POSTED,
          payload: true,
        });
        dispatch(getAllActivites(variables, patient_id, employee_id));
        // let frmt2 = "YYYY-MM-DD HH:mm:ss";
        // let dateN = new Date();
        // let utcNow = moment(dateN).utc().format(frmt2);
        // const variabless = {
        //   dateRange: [moment().format(`YYYY-MM-DD`), moment().format("YYYY-MM-DD")],
        //   dateToday: utcNow,
        // };
        // dispatch(getUpcomingMeetingActivitesForAlert(variabless, employee_id));
      })
      .catch((error) => {
        console.log("error ---->", error);
        console.log(error.response, "error.response");
        dispatch(setActivityLoading(false));
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : "Cannot Delete Activity"
        );
      });
}
export function bulkDelete(data, variables, patient_id, employee_id) {
  const request = axios.post(`${Domain}/api/vendor/deletebulkact`, data);
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success("Activity Deleted Successfully");
        dispatch({
          type: ACTIVITY_POSTED,
          payload: true,
        });
        History.goBack();
        // let frmt2 = "YYYY-MM-DD HH:mm:ss";
        // let dateN = new Date();
        // let utcNow = moment(dateN).utc().format(frmt2);
        // const variabless = {
        //   dateRange: [moment().format(`YYYY-MM-DD`), moment().format("YYYY-MM-DD")],
        //   dateToday: utcNow,
        // };
        dispatch(getAllActivites(variables, patient_id, employee_id));
      })
      .catch((error) => {
        console.log(error.response, "error");
        dispatch(setActivityLoading(false));
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : "Cannot Delete Activity"
        );
      });
}

// get sigle activity for bulk add........
export function getSigleActiviteForBulk(id) {
  const request = axios.get(`${Domain}/api/vendor/singleActivity/${id}`);
  return (dispatch) =>
    request
      .then((response) => {
        return dispatch({
          type: GET_SINGLE_ACTIVITIE_FOR_BULK,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_SINGLE_ACTIVITIE_FOR_BULK_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get activity",
        });
      });
}

export function editBulkActivity(data) {
  console.log(data);
  const request = axios.post(`${Domain}/api/vendor/updateBulkActivity`, data);
  return (dispatch) =>
    request
      .then((response) => {
        dispatch(setActivityLoading(false));
        NotificationManager.success("Activity Updated Successfully");
        // dispatch({
        //   type: ACTIVITY_POSTED,
        //   payload: true,
        // });
        // let frmt2 = "YYYY-MM-DD HH:mm:ss";
        // let dateN = new Date();
        // let utcNow = moment(dateN).utc().format(frmt2);
        // const variables = {
        //   dateRange: [moment().format(`YYYY-MM-DD`), moment().format("YYYY-MM-DD")],
        //   dateToday: utcNow,
        // };
        // dispatch(getUpcomingMeetingActivitesForAlert(variables, data.employee_id));
        // dispatch(getAllActivites({}, data.patient_id));
        History.goBack();
      })
      .catch((error) => {
        // if (data.when > 1) {
        //   History.goBack();
        // } else {
        dispatch(setActivityLoading(false));
        console.log("Error Update Activity ---->", error.response);
        console.log(error);
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : "Cannot Update Activity"
        );
        // }
      });
}

export function setActivityLoading(val) {
  console.log(val, "this is val");
  return (dispatch) => {
    return dispatch({
      type: ACTIVITY_LOADING,
      payload: val,
    });
  };
}

// only for meeting notifications
export function getUpcomingMeetingActivitesForNotificatins(vendor_id) {
  let data = {};
  let frmt2 = "YYYY-MM-DD HH:mm:ss";
  let dateN = new Date();
  let utcNow = moment(dateN).utc().format(frmt2);
  data.dateRange = [
    moment(moment().format(`YYYY-MM-DD 00:00:00`))
      .utc()
      .format("YYYY-MM-DD HH:mm:ss"),
    moment(moment().format(`YYYY-MM-DD 23:59:00`))
      .utc()
      .format("YYYY-MM-DD HH:mm:ss"),
  ];
  data.status = "pending";
  data.dateToday = utcNow;
  const request = axios.post(`${Domain}/api/vendor/fetchMettingActivitiesNew`, {
    ...data,
    employee_id: vendor_id,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GET_UPCOMING_MEETING_NOTIFICATION,
          payload: response.data.result,
        });
        let todayDate = new Date();
        localStorage.setItem("notidicationDate", todayDate);
      })
      .catch((error) => {
        console.log(error.response, "activityReducer");
      });
}

export function emptyActivityState(val) {
  console.log(val, "this is val");
  return (dispatch) => {
    return dispatch({
      type: ACTIVITY_EMPTY,
      payload: val,
    });
  };
}

export function setActivityStatusLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: ACTIVITY_STATUS_LOADING,
      payload: val,
    });
  };
}
