import Domain from "lib/Config";
import axios from "axios";

export const CHAT_HISTORY = "[ADMIN] CHAT_HISTORY";
export const CHAT_LOADING = "[ADMIN] CHAT_LOADING";
export const CHAT_ERROR = "[ADMIN] CHAT_ERROR";
export const CHAT_NULL = "[ADMIN] CHAT_NULL";

export function getChatHistory(variables, patient_id) {
  console.log("Variables  in getChat History---->", variables);
  console.log("Patient id -->", patient_id);
  const request = axios.get(`${Domain}/api/vendor/chat`, {
    params: { patient_id, ...variables },
  });
  return (dispatch) =>
    request
      .then((response) => {
        console.log("Response of chat--->", response);
        let responseObj = { ...response };
        return dispatch({
          type: CHAT_HISTORY,
          payload: responseObj.data.chat.rows,
          count: responseObj.data.chat.count,
        });
      })
      .catch((error) => {
        console.log("Error ---");
        console.log("error", error);
        console.log(error.response);
        return dispatch({
          type: CHAT_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get details",
        });
      });
}
export function setChatLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: CHAT_LOADING,
      payload: val,
    });
  };
}
export function setChatNull(val) {
  return (dispatch) => {
    return dispatch({
      type: CHAT_NULL,
    });
  };
}
