import React, { useRef, useEffect } from "react";
import { Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import { MdAddCircle } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";
import McqTypeQuestion from "./questionType/mcqType";
import MediaTypeQuestion from "./questionType/mediaType";

const SingleQuestion = ({
  quesIndex,
  question,
  questions,
  setQuestions,
  activeActionsTabIndex,
  setActiveActionsTabIndex,
  handleRemoveQuestion,
  recommendModalToggle,
  recommentDetailModalToggle,
  setSelectedQuesOptIndexes,
}) => {
  const { t } = useTranslation();

  const lastRef = useRef(null);
  console.log("Questions --->", questions);
  useEffect(() => {
    // Focus on question as a new question gets added or removed
    if (lastRef.current) {
      lastRef.current.focus();
      setActiveActionsTabIndex(lastRef.current.id);
    }
  }, [questions]);
  const addQuestion = (e, quesIndex) => {
    e.preventDefault();
    questions.splice(quesIndex + 1, 0, {
      UUID: uuidv4(),
      title: "",
      questionType: "MCQ",
      answerUniqueId: uuidv4(),
      options: [
        {
          option: "",
          value: false,
          important: false,
          linkedTo: null,
          answerUniqueId: uuidv4(),
          recommendModalType: "product",
        },
        {
          option: "",
          value: false,
          important: false,
          linkedTo: null,
          answerUniqueId: uuidv4(),
          recommendModalType: "product",
        },
      ],
    });
    setQuestions([...questions]);
  };
  const handleRemoveOption = (quesIndex, optionIndex) => {
    let newOptions = question.options;
    newOptions.splice(optionIndex, 1);
    question.options = [...newOptions];
    setQuestions([...questions]);
  };

  let changeActiveActionsTab = (id) => {
    if (activeActionsTabIndex != id) {
      setActiveActionsTabIndex(id);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        addQuestion(e, quesIndex);
      }}
      className="w-100"
    >
      <div className="row w-100 m-auto">
        <div
          id={quesIndex}
          onClick={(e) => changeActiveActionsTab(quesIndex)}
          className=" col-sm-11 m-0"
        >
          <div className="row d-flex justify-content-center rct-block p-3">
            <div className="col-sm-12 col-md-9 d-flex justify-content-start align-items-center">
              <h4 className="font-weight-bold text-black p-0 m-0">
                {`Q${quesIndex + 1}`}:
              </h4>
              <input
                id={quesIndex}
                type="textarea"
                name="title"
                placeholder={`${t("question")} ${quesIndex + 1} ${t(
                  "starts_here"
                )}...`}
                ref={
                  quesIndex === questions && questions.length - 1
                    ? lastRef
                    : undefined
                }
                value={question.title}
                onChange={(e) => {
                  question.title = e.target.value;
                  setQuestions([...questions]);
                }}
                className="w-100 border-0 bg-transparent questionaire-title
              font-weight-bold"
                rows={1}
                required
              />
            </div>

            <div className="col-sm-12 col-md-3 ">
              <Input
                type="select"
                name="questionType"
                value={
                  question.questionType == "IMAGE" ||
                  question.questionType == "VIDEO" ||
                  question.questionType == "MEDIA"
                    ? "MEDIA"
                    : "MCQ"
                }
                onChange={(e) => {
                  questions[quesIndex] = {
                    ...question,
                    questionType: e.target.value,
                    answer: null,
                    linkedToQuestion: null,
                    options:
                      e.target.value == "MCQ"
                        ? [
                            {
                              option: "",
                              value: false,
                              important: false,
                              answerUniqueId: uuidv4(),
                            },
                            {
                              option: "",
                              value: false,
                              important: false,
                              answerUniqueId: uuidv4(),
                            },
                          ]
                        : null,
                  };

                  setQuestions([...questions]);
                }}
                required
              >
                <option value="MCQ">{t("MCQ's")}</option>
                <option value="MEDIA">{t("media_type")}</option>
              </Input>
            </div>
            {questions && question.questionType === "MCQ" ? (
              <>
                <McqTypeQuestion
                  quesIndex={quesIndex}
                  question={question}
                  questions={questions}
                  setQuestions={setQuestions}
                  handleRemoveOption={handleRemoveOption}
                  setSelectedQuesOptIndexes={setSelectedQuesOptIndexes}
                  recommendModalToggle={recommendModalToggle}
                  recommentDetailModalToggle={recommentDetailModalToggle}
                />
              </>
            ) : (
              <MediaTypeQuestion
                question={question}
                quesIndex={quesIndex}
                questions={questions}
                setQuestions={setQuestions}
              />
            )}
          </div>
        </div>
        <div
          className={` col-sm-1 p-0   ${
            activeActionsTabIndex == quesIndex ? "d-block" : "d-none"
          }`}
        >
          <div className="pl-4">
            <div
              className={`m-auto rct-block py-4  d-flex flex-column justify-content-center   w-100 `}
            >
              <button
                className="p-0 bg-transparent border-0 mb-4"
                onClick={() => handleRemoveQuestion(quesIndex)}
                disabled={questions && questions.length > 1 ? false : true}
                type="button"
              >
                <AiFillDelete className="iconMd" />
              </button>
              <button
                className="p-0 bg-transparent border-0 mb-4"
                type="submit"
              >
                <MdAddCircle className="iconMd" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SingleQuestion;
