import React, { useState, useCallback, useEffect } from "react";
import { Button, Input, Label } from "reactstrap";
import FileDrop from "react-dropzone";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "store/actions";
import { Avatar, CircularProgress, LinearProgress } from "@material-ui/core";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import { useTranslation } from "react-i18next";
import Domain from "lib/Config";
import axios from "axios";
import GeneralQuestions from "./generalQuestions";
import { NotificationManager } from "react-notifications";

const profileCdn = axios.create({});

export default function Profile(props) {
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [vendorDetail, setVendorDetail] = useState(null);
  const { t } = useTranslation();

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });

  const dashboardReducer = useSelector(({ dashboard }) => dashboard);

  const onDrop = useCallback(
    (img) => {
      setImageLoading(true);

      handleFileUpload(img);
    },
    [image]
  );
  let handleFileUpload = (data) => {
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      },
      header: {
        "Content-Type": data[0].type,
      },
    };
    getSignedURL(data[0].name, data[0].type).then((rsp) => {
      const request = profileCdn
        .put(rsp.data.result.postUrl, data[0], config)
        .then((res) => {
          setImage(rsp.data.result.getUrl);
          setImageLoading(false);
        })
        .catch((err) => {
          NotificationManager.error(t("error_something_went_wrong"));
          setImageLoading(false);
        });
    });
  };
  let getSignedURL = (key, type) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Domain}/api/vendor/s3getsignedurl?key=${key}&type=${type}`)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  useEffect(() => {
    if (Auth && Auth.vendor_id) {
      dispatch(Actions.setDashboardLoading(true));
      dispatch(Actions.getVendorDetail(Auth.vendor_id));
    }
  }, []);

  useEffect(() => {
    if (dashboardReducer.vendorDetail) {
      setVendorDetail(dashboardReducer.vendorDetail);
      setImage(dashboardReducer.vendorDetail.image);
    }
  }, [dashboardReducer, dashboardReducer.vendorDetail]);

  if (dashboardReducer && dashboardReducer.venderDetailError) {
    return (
      <ErrorMessageComponents message={t("unable_to_fetch_vendor_detail")} />
    );
  }

  if (dashboardReducer === null || dashboardReducer.loading) {
    return <CircularProgressComponent />;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      ...vendorDetail,
      phone: e.target.phone.value,
      city: e.target.city.value,
      address: e.target.address.value,
      country: e.target.country.value,
      billingEmail: e.target.billingEmail.value,
      zipCode: e.target.zipCode.value,
      type: e.target.type.value,
      image: image,
    };

    dispatch(Actions.setDashboardLoading(true));
    dispatch(Actions.updateVendorProfile(Auth.vendor_id, data));
  };

  let onFormValChange = (e) => {
    setVendorDetail({
      ...vendorDetail,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <div className="container form-styles patient-profile">
        <div>
          <h2 className="form-heading d-flex">{t("vendor_information")}</h2>
          <div className="mt-4">
            <FileDrop
              onDrop={onDrop}
              accept="image/png, image/gif, image/jpeg, image/bmp,"
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  className="d-flex  profile-container cursor-pointer m-0"
                >
                  {imageLoading ? (
                    <div className="d-flex justify-content-center align-items-center w-100">
                      <CircularProgress />
                    </div>
                  ) : (
                    <Avatar className="profile-pic-patient" src={image} />
                  )}
                  <img
                    className="add-icon centrify-icons"
                    src={require("assets/icons/add-photo.png")}
                  />
                  <input {...getInputProps()} />
                </div>
              )}
            </FileDrop>
          </div>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="row">
              <div className="col-md-4 mt-4">
                <Label className="d-flex">{t("company_name")}</Label>
                <Input
                  disabled
                  type="text"
                  name="companyName"
                  value={vendorDetail && vendorDetail.companyName}
                />
              </div>
              <div className="col-md-4 mt-4">
                <Label className="d-flex">{t("admin_first_name")}</Label>
                <Input
                  disabled
                  type="text"
                  name="adminFirstName"
                  value={
                    vendorDetail && vendorDetail.vendoremployes[0].firstName
                  }
                />
              </div>
              <div className="col-md-4 mt-4">
                <Label className="d-flex">{t("admin_last_name")}</Label>
                <Input
                  disabled
                  type="text"
                  name="adminLastName"
                  value={
                    vendorDetail && vendorDetail.vendoremployes[0].lastName
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mt-4">
                <Label className="d-flex">{t("email")}</Label>
                <Input
                  disabled
                  type="text"
                  name="email"
                  value={vendorDetail && vendorDetail.primaryEmail}
                />
              </div>
              <div className="col-md-4 mt-4">
                <Label className="d-flex">{t("phone")}</Label>
                <Input
                  type="text"
                  name="phone"
                  value={vendorDetail && vendorDetail.phone}
                  onChange={(e) => {
                    onFormValChange(e);
                  }}
                />
              </div>
              <div className="col-md-4 mt-4">
                <Label className="d-flex">{t("city")}</Label>
                <Input
                  // disabled
                  type="text"
                  name="city"
                  value={vendorDetail && vendorDetail.city}
                  onChange={(e) => {
                    onFormValChange(e);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mt-4">
                <Label className="d-flex">{t("address")}</Label>
                <Input
                  type="text"
                  name="address"
                  value={vendorDetail && vendorDetail.address}
                  onChange={(e) => {
                    onFormValChange(e);
                  }}
                />
              </div>
              <div className="col-md-4 mt-4">
                <Label className="d-flex">{t("country")}</Label>
                <Input
                  type="text"
                  name="country"
                  value={vendorDetail && vendorDetail.country}
                  onChange={(e) => {
                    onFormValChange(e);
                  }}
                />
              </div>
              <div className="col-md-4 mt-4">
                <Label className="d-flex">{t("billing_email")}</Label>
                <Input
                  // disabled
                  type="text"
                  name="billingEmail"
                  value={vendorDetail && vendorDetail.billingEmail}
                  onChange={(e) => {
                    onFormValChange(e);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mt-4">
                <Label className="d-flex">{t("zip_code")}</Label>
                <Input
                  type="text"
                  name="zipCode"
                  value={vendorDetail && vendorDetail.zipCode}
                  onChange={(e) => {
                    onFormValChange(e);
                  }}
                />
              </div>
              <div className="col-md-4 mt-4">
                <Label className="d-flex">{t("type")}</Label>
                <Input
                  type="text"
                  name="type"
                  defaultValue={vendorDetail && vendorDetail.type}
                  value={vendorDetail && vendorDetail.type}
                  onChange={(e) => {
                    onFormValChange(e);
                  }}
                />
              </div>
            </div>
            <div className="mt-4">
              <Button
                type="submit"
                className="form-btn"
                color="none"
                disabled={imageLoading ? true : false}
              >
                {t("submit")}
              </Button>
            </div>
          </form>
        </div>
        <div className="mt-4">
          <GeneralQuestions {...props} />
        </div>
      </div>
    </div>
  );
}
