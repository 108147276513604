import React, { useEffect, useState, useCallback } from "react";
import History from "@history";
import { Avatar, CircularProgress } from "@material-ui/core";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import Toggle from "react-toggle";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import FileDrop from "react-dropzone";
import { Input, Label, Button } from "reactstrap";
import axios from "axios";
import Domain from "lib/Config";
import * as Actions from "store/actions";
import * as countriesJson from "countries.json";
import NotificationManager from "react-notifications/lib/NotificationManager";

const profileCdn = axios.create({});
const EmployeeProfile = (props) => {
  const dispatch = useDispatch();
  let countries = countriesJson.default;
  const employeeReducer = useSelector(({ employee }) => employee);
  const { t } = useTranslation();
  const [employee, setEmployee] = useState(null);
  const [image, setImage] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  useEffect(() => {
    if (props.match.params.id) {
      let id = props.match.params.id;
      dispatch(Actions.setEmployeeLoading(true));
      dispatch(Actions.getEmployeeProfile(id));
    }
  }, [props]);

  useEffect(() => {
    setEmployee(employeeReducer);
    setImage(employeeReducer?.employeeDetails?.image);
  }, [employeeReducer]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let obj = {};
    const formData = new FormData(e.target);
    for (let [key, value] of formData.entries()) {
      obj[key] = value;
    }
    obj.id = employee?.employeeDetails?.id;
    dispatch(Actions.setEmployeeLoading(true));
    dispatch(Actions.updateEmployeeProfile(obj));
  };

  const onDrop = useCallback(
    (img) => {
      handleFileUpload(img);
      setImageLoading(true);
    },
    [image]
  );

  const handleFileUpload = (data) => {
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      },
      header: {
        "Content-Type": data[0].type,
      },
    };
    getSignedURL(data[0].name, data[0].type).then((rsp) => {
      let obj = {};
      obj.image = rsp.data.result.getUrl;
      obj.id = props.match.params.id;
      dispatch(Actions.updateEmployeeImage(obj));
      const request = profileCdn
        .put(rsp.data.result.postUrl, data[0], config)
        .then((res) => {
          setImage(rsp.data.result.getUrl);
          setImageLoading(false);
        })
        .catch((err) => {
          NotificationManager.error(t("error_something_went_wrong"));
          setImageLoading(false);
        });
    });
  };

  const getSignedURL = (key, type) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Domain}/api/vendor/s3getsignedurl?key=${key}&type=${type}`)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const handleDeactivate = (status) => {
    dispatch(Actions.setEmployeeStatusLoading(true));
    dispatch(
      Actions.updateEmployeeStatus(employeeReducer?.employeeDetails?.id, status)
    );
  };

  if (employee && employee.error) {
    return (
      <ErrorMessageComponents message={t("cannot_get_employee_details")} />
    );
  }

  if (!employee || !employee.employeeDetails || employee.loading) {
    return <CircularProgressComponent />;
  }

  return (
    <div className="patient-profile">
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="form-heading d-flex">{t("personal_information")}</h1>
      </div>
      <div className="rct-block">
        <div className="rct-block-content">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              {Auth?.role === "manager" ? (
                <div className="mr-4">
                  <FileDrop
                    accept="image/png, image/gif, image/jpeg, image/bmp,"
                    onDrop={onDrop}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps()}
                        className="d-flex profile-container cursor-pointer"
                      >
                        {imageLoading ? (
                          <div className="d-flex justify-content-center align-items-center w-100">
                            <CircularProgress />
                          </div>
                        ) : (
                          <Avatar className="profile-pic-patient" src={image} />
                        )}
                        {/* <AddCircleRoundedIcon
                fontSize="medium"
                className="add-icon primary-color cursor-pointer"
              /> */}
                        <img
                          className="add-icon centrify-icons"
                          src={require("assets/icons/add-photo.png")}
                        />
                        <input {...getInputProps()} />
                      </div>
                    )}
                  </FileDrop>
                </div>
              ) : (
                <>
                  <Avatar
                    className="profile-pic-patient mr-4"
                    src={employee.employeeDetails.image}
                  />
                  <div>
                    <p className="patient-name mb-0">
                      {employee.employeeDetails.firstName +
                        " " +
                        employee.employeeDetails.lastName}
                    </p>
                    <p className="patient-detail mt-1 mb-0">
                      {employee.employeeDetails.role}
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className="d-flex flex-column justify-content-start align-items-center">
              <a
                onClick={() =>
                  History.push(
                    `/projects?filter_by=employee&employee_name=${
                      employee.employeeDetails.firstName +
                      " " +
                      employee.employeeDetails.lastName
                    }&employee_id=${employee.employeeDetails.id}`
                  )
                }
                className="centrify-blue-color cursor-pointer"
              >
                {t("view_projects")}
              </a>
              <div className="d-flex flex-row justify-content-center align-items-center mt-5">
                <div className="font-weight-bold">{t("status")}:</div>
                {employeeReducer?.statusLoading ? (
                  <div
                    class="spinner-border text-dark"
                    style={{ height: "1rem", width: "1rem" }}
                    role="status"
                  >
                    <span class="sr-only">{t("loading")}...</span>
                  </div>
                ) : (
                  <Toggle
                    className="ml-2"
                    checked={employeeReducer?.employeeDetails?.status}
                    onChange={() =>
                      handleDeactivate(
                        !employeeReducer?.employeeDetails?.status
                      )
                    }
                  />
                )}
              </div>
            </div>
          </div>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="row ">
              <div className="col-md-4 mt-4">
                <Label className="mb-1 d-flex">{t("first_name")}</Label>
                <Input
                  type="text"
                  name="firstName"
                  required
                  className="w-75"
                  defaultValue={
                    employee.employeeDetails.firstName &&
                    employee.employeeDetails.firstName
                  }
                />
              </div>
              <div className="col-md-4 mt-4">
                <Label className="mb-1 d-flex">{t("last_name")}</Label>
                <Input
                  type="text"
                  name="lastName"
                  required
                  className="w-75"
                  defaultValue={
                    employee.employeeDetails.lastName &&
                    employee.employeeDetails.lastName
                  }
                />
              </div>
              <div className="col-md-4 mt-4">
                <Label className="mb-1 d-flex">{t("country")}</Label>
                <Input
                  type="select"
                  name="country"
                  defaultValue={
                    employee.employeeDetails.country &&
                    employee.employeeDetails.country
                  }
                  required
                >
                  {countries.map((country) => {
                    return <option value={country.name}>{country.name}</option>;
                  })}
                </Input>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-4 mt-4">
                <Label className="mb-1 d-flex">{t("email")}</Label>
                <Input
                  type="email"
                  className="w-75"
                  disabled
                  defaultValue={
                    employee.employeeDetails.email &&
                    employee.employeeDetails.email
                  }
                />
              </div>
              <div className="col-md-4 mt-4">
                <Label className="mb-1 d-flex">{t("phone")}</Label>
                <Input
                  type="text"
                  name="phone"
                  required
                  className="w-75"
                  defaultValue={
                    employee.employeeDetails.phone &&
                    employee.employeeDetails.phone
                  }
                />
              </div>
              <div className="col-md-4 mt-4">
                <Label className="mb-1 d-flex">{t("role")}</Label>
                <Input
                  type="select"
                  name="role"
                  required
                  className="w-100"
                  defaultValue={employee.employeeDetails.role}
                  disabled
                >
                  <option value="manager">{t("manager")}</option>
                  <option value="admin">{t("admin")}</option>
                  <option value="doctor">{t("doctor")}</option>
                  <option value="nurse">{t("nurse")}</option>
                  <option value="analyst">{t("analyst")}</option>
                </Input>
              </div>
            </div>
            <div className="row mt-4 d-flex justify-content-end">
              {employee.loading ? (
                <CircularProgress />
              ) : (
                <Button
                  className="primary-background-button-table"
                  color="none"
                  type="submit"
                  disabled={employee.loading}
                >
                  {t("update_employee")}
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default EmployeeProfile;
