import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'reactstrap'

const ArticleDetailCard = ({ articleDetail }) => {
  const { t } = useTranslation()
  console.log('articleDetail --->', articleDetail)
  return (
    <div>
      <img
        className='mb-4'
        src={articleDetail && articleDetail.image}
        style={{ width: '50%', maxWidth: '150px' }}
      />
      <h2 className='mb-4'>{articleDetail && articleDetail.title}</h2>
      {/* <h3 className='mb-'>{t('article_description')}</h3> */}
      <p className='mb-4'>{articleDetail && articleDetail.shortDescription}</p>
      <div className='mb-2'>
        <h4 className='mb-4'>
          {articleDetail && articleDetail.type === 'pdf'
            ? t('pdf_link')
            : t('web_link')}
        </h4>
        <a href={articleDetail && articleDetail.webLink} className='text-white'>
          <Button
            className='primary-background-button-table font-weight-bold mb-5 mr-4 p-2 px-4'
            color='none'
          >
            {t('download')}
          </Button>
        </a>
      </div>
    </div>
  )
}

export default ArticleDetailCard
