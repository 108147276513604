import { Avatar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Input, Table } from "reactstrap";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import History from "@history";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import TableLoader from "components/ErrorHandlers/TableLoader";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as Actions from "store/actions";
import qs from "query-string";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import AssignToPatientModal from "../patientModal";

export default function Patients(props) {
  const dispatch = useDispatch();
  let querystr = qs.parse();

  const [variables, setVariables] = useState({ page: 1 });
  let [pageLoading, setPageLoading] = useState(false);
  const [patient, setPatient] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [patopen, setPatOpen] = useState(false);
  const { t } = useTranslation();

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  const questionaireReducer = useSelector(({ questionaire }) => questionaire);

  useEffect(() => {
    const q_str = qs.stringify(variables);
    if (props.questionaireId) {
      setPageLoading(true);
      dispatch(
        Actions.getTreeQuestionnairePatients(variables, props.questionaireId)
      );
    }
  }, [props.questionaireId, variables]);
  useEffect(() => {
    if (
      questionaireReducer.error ||
      questionaireReducer.treePatientList != null
    ) {
      setPageLoading(false);
    }
  }, [questionaireReducer]);
  useEffect(() => {
    if (questionaireReducer.treePatientList) {
      setPatient(questionaireReducer);
    }
  }, [questionaireReducer.treePatientList]);

  if (
    questionaireReducer.error &&
    questionaireReducer?.treePatientList === null
  ) {
    return <ErrorMessageComponents message="Cannot Fetch Patients" />;
  }

  if (patient === null || pageLoading) {
    return <CircularProgressComponent />;
  }

  const getPatientList =
    patient.treePatientList && patient.treePatientList.length > 0 ? (
      patient.treePatientList.map((item) => {
        return (
          <tr>
            <td>
              <div
                onClick={() =>
                  History.push(`/patient-profile/${item.patient.id}`)
                }
                className="ml-4 d-flex align-items-center cursor-pointer"
              >
                <Avatar
                  className="mr-3 medicine-pic"
                  src={item.patient.image}
                />{" "}
                <p className="mb-0 prominent mr-4">
                  {item.patient.firstName + " " + item.patient.lastName}
                </p>
              </div>
            </td>
            <td>{item.patient.email}</td>
            <td>{item.patient.type}</td>
            <td>{item.score || 0}</td>
            <td>
              {item.status == "done" ? (
                <Link
                  to={`/surveyQuestionnaire/solved/${item.patient.id}/${item.id}`}
                  className="filter-text centrify-blue-color"
                >
                  {t("view")}
                </Link>
              ) : (
                <>{t("not_attempted")}</>
              )}
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colspan={7} className="text-center font-weight-bold">
          {t("nothing_to_display")}
        </td>
      </tr>
    );

  const handlePageChange = (e, page) => {
    setVariables({
      ...variables,
      page: page,
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let search = document.getElementById("patient-search").value;
    setVariables({
      ...variables,
      _firstName: search,
      page: 1,
    });
  };

  return (
    <div>
      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-end table-top-bar">
          <div className="d-flex align-items-center filters">
            <Form className="mt-2 mt-sm-0" onSubmit={(e) => handleSearch(e)}>
              <FormGroup className="d-inline-block gift-search-input">
                <Input
                  type="text"
                  name="value"
                  id="patient-search"
                  maxLength={10}
                  placeholder={t("search")}
                  style={{ paddingRight: "40px" }}
                />
                <button type="submit" className="gift-search-icon">
                  <i className="zmdi zmdi-search centrify-blue-color"></i>
                </button>
              </FormGroup>
            </Form>
          </div>
        </div>
        <div className="mt-4" style={{ position: "relative" }}>
          {patient.loading && <TableLoader />}
          <Table responsive className="main-table">
            <thead className="main-table-header">
              <tr>
                <th>
                  <span className="ml-4 mb-0 d-flex">{t("name")}</span>
                </th>
                <th>{t("email")}</th>
                <th>{t("type")}</th>
                <th>{t("score")}</th>
                <th>{t("view")}</th>
              </tr>
            </thead>
            <tbody className="main-table-body">{getPatientList}</tbody>
          </Table>
        </div>
      </div>

      {questionaireReducer?.treePatientCount > 0 ? (
        <div className="mt-4 d-flex justify-content-center">
          <Pagination
            count={Math.ceil(questionaireReducer?.treePatientCount / 10)}
            page={variables.page}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
          />
        </div>
      ) : null}
      <AssignToPatientModal
        {...props}
        treeQuestionnaireId={props.questionaireId}
        modal={patopen}
        patients={selectedPatient}
        toggle={() => {
          setPatOpen(!patopen);
          setSelectedPatient([]);
        }}
      />
    </div>
  );
}
