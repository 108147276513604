import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Actions from "store/actions";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import History from "@history";
import qs from "query-string";
import MissedAct from "./missedActivities";
import ImportantOptions from "./importantOptions";
import VchatImportantOptions from "./importantVchatOptions";

export default function ReportsDetails(props) {
  const dispatch = useDispatch();
  let querystr = qs.parse(props.location.search);
  let page = querystr.page ? JSON.parse(querystr.page) : 1;
  console.log(querystr);
  querystr.page = page;
  const [value, setValue] = useState(querystr.tab ? querystr.tab : 0);
  console.log(value);
  const { t } = useTranslation();
  let tFrmt = "HH:mm:ss";
  let frmt2 = "YYYY-MM-DD HH:mm:ss";
  let dateN = new Date();
  let utcNow = moment(dateN).utc().format(frmt2);
  //convert date Range to utc before api call
  const [variables, setVariables] = useState({
    ...querystr,
    page,
    dateRange: [moment().format(`YYYY-MM-DD`), moment().format("YYYY-MM-DD")],
  });
  const [startDate, setStartDate] = useState(
    variables.dateRange
      ? moment(variables.dateRange[0]).format(`YYYY-MM-DD`)
      : moment().format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    variables.dateRange
      ? moment(variables.dateRange[1]).format(`YYYY-MM-DD`)
      : moment().format("YYYY-MM-DD")
  );

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  const reportsReducer = useSelector(({ reports }) => reports);

  useEffect(() => {
    const q_str = qs.stringify(variables);
    History.push({ search: q_str });
    dispatch(Actions.setReportsLoading(true));
    dispatch(Actions.getMissedActivites(variables, Auth.vendor_id));
  }, [variables, Auth.vendor_id]);

  if (reportsReducer && reportsReducer.error) {
    return <ErrorMessageComponents message="Cannot get activities" />;
  }

  if (!reportsReducer || reportsReducer?.loading) {
    return <CircularProgressComponent />;
  }

  let tabs = [
    t("missed_activities"),
    t("important_options"),
    t("vchat_important_options"),
  ];

  return (
    <div>
      <div className=" d-flex flex-wrap align-items-center tabs">
        {tabs.map((item, index) => {
          return (
            <p
              onClick={(e) => {
                const q_str = qs.stringify({
                  page: 1,
                  dateRange: [
                    moment().format(`YYYY-MM-DD`),
                    moment().format("YYYY-MM-DD"),
                  ],
                  search: null,
                });
                History.push({ search: q_str });

                setValue(index);
              }}
              className={`${
                index == value ? "active-tab" : ""
              } mb-0 margin-tabs mt-2 cursor-pointer`}
            >
              {item}
            </p>
          );
        })}
      </div>
      <div className="mt-4">
        {value == 0 ? (
          <div className="">
            <MissedAct
              reports={reportsReducer}
              variables={variables}
              tFrmt={tFrmt}
              setVariables={setVariables}
              page={page}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </div>
        ) : value == 1 ? (
          <div className="">
            <ImportantOptions vendorId={Auth.vendor_id} {...props} />
          </div>
        ) : value == 2 ? (
          <div>
            <VchatImportantOptions vendorId={Auth.vendor_id} {...props} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
