import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "store/actions";
import ProjectFilterModal from "./ProjectFilterModal";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";

const ProjectModal = (props) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });

  const pickItem = (id, name) => {
    props.itemPicked(id, name);
  };
  const pickAll = () => {
    props.allPicked();
  };

  const handleAssignToProject = () => {
    console.log("projectsList  --->", props.selectedProjects);
    console.log("Auth.vendor_id -->", Auth.vendor_id);
    console.log("props.engagementId -->", props.engagementId);
    // View Type is required as assignToProject action is used in Engagement Table and inside engagement detail.
    // So based on the screen different data is required once action is completed, getting all engagment in case of engagement table and getting all assigned projects in case of project tab in detail view.
    let viewType = "general";
    if (props.viewType) {
      viewType = "detail_view";
    }
    if (props.selectedProjects) {
      dispatch(Actions.setEngagementAssignLoading(true));
      dispatch(
        Actions.assignEngagementToProject(
          props.engagementId,
          props.selectedProjects,
          Auth.id,
          viewType,
          Auth.vendor_id
        )
      );
    }
  };

  let closeModal = () => {
    dispatch(Actions.setEngagementAssignLoading(false));
    props.toggle();
  };
  return (
    <div>
      <Modal isOpen={props.modal} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>{t("pick_project")}</ModalHeader>
        <ModalBody>
          <ProjectFilterModal
            selectedProjects={props.selectedProjects}
            setSelectedProjects={props.setSelectedProjects}
            handleAssignToProject={handleAssignToProject}
            pickItem={pickItem}
            pickAll={pickAll}
            engagementId={props.engagementId}
            {...props}
            statsScreen={true}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ProjectModal;
