import React from "react";
import { Link } from "react-router-dom";

export default function Questionaires(props) {
  const { question, index } = props;
  console.log("Question --->", question);
  console.log(question.questionType, question.questionCount);
  let opTotalCount = 0;
  question.TQOptions.map((o) => {
    opTotalCount += o.optionCount;
  });

  return (
    <div className="col-md- col-lg-8 d-flex flex-column">
      <h3 className="main-heading font-weight-bold d-flex">
        Q{index + 1}:{"  "}
        {question.title}
      </h3>
      <div className="d-flex flex-column mt-4">
        {question.TQOptions.map((op) => (
          <div className="d-flex flex-row justify-content-start align-items-center">
            <div className="mx-2 p-0">
              <input
                type="checkbox"
                name="type"
                style={{ transform: "scale(1.5)" }}
                id={op.text}
                value={op.text}
                disabled
              />
              <label for="item" className="ml-3 font-14 lead p-0">
                {op.option}
              </label>
            </div>
            {question.questionType === "MCQ" ? (
              <Link
                to={`/survey/options/patients/stats/${op.id}`}
                style={{ paddingBottom: "0.3rem" }}
                className="font-14 ml-5 cursor-pointer clr-yelloww"
              >
                {opTotalCount > 0
                  ? Math.round((op.optionCount / opTotalCount) * 100)
                  : 0}
                %
              </Link>
            ) : (
              <Link
                to={`/survey/options/patients/stats/${op.id}`}
                style={{ paddingBottom: "0.3rem" }}
                className="font-14 ml-5 cursor-pointer clr-yelloww"
              >
                {op.optionCount > 0
                  ? Math.round((op.optionCount / question.questionCount) * 100)
                  : 0}
                %
              </Link>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
