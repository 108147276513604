import { Avatar, LinearProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Input, Table } from "reactstrap";
import Pagination from "@material-ui/lab/Pagination";
import ConfrimDialogue from "components/Dialogue/ConfrimDialogue";
import History from "@history";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import TableLoader from "components/ErrorHandlers/TableLoader";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as Actions from "store/actions";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function PatientFilterModal(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [variables, setVariables] = useState({ page: 1 });
  const [patient, setPatient] = useState(null);
  const [patient_id, setPatientId] = useState(null);
  const [dialogue, setDialogue] = useState(false);
  const { t } = useTranslation();

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  const patientReducer = useSelector(({ patient }) => patient);

  useEffect(() => {
    let vendor_id = Auth.vendor_id;
    dispatch(Actions.setPatientLoading(true));
    dispatch(Actions.getAllPatients(variables, vendor_id));
  }, [variables]);

  useEffect(() => {
    setPatient(patientReducer);
  }, [patientReducer]);

  if (patient && patient.error) {
    return <ErrorMessageComponents message="Cannot Fetch Employees" />;
  }

  if (patient === null || !patient.patientList) {
    return <CircularProgressComponent />;
  }

  const getPatientList =
    patient.patientList && patient.patientList.length > 0 ? (
      patient.patientList.map((item) => {
        return (
          <tr>
            <td>{item.id}</td>
            <td>
              <div className="ml-4 d-flex align-items-center">
                <Avatar
                  className="mr-3 medicine-pic"
                  src={item.image ? item.image : null}
                />{" "}
                <p className="mb-0 prominent mr-4">
                  {item.firstName + " " + item.lastName}
                </p>
              </div>
            </td>
            <td
              onClick={(e) =>
                props.pickItem(item.id, item.firstName + " " + item.lastName)
              }
            >
              <a className="centrify-blue-color">Pick</a>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colspan={5} className="text-center font-weight-bold">
          {t("nothing_to_display")}
        </td>
      </tr>
    );

  const handlePageChange = (e, page) => {
    setVariables({
      ...variables,
      page: page,
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let search = document.getElementById("patient-search").value;
    setVariables({
      ...variables,
      _firstName: search,
      page: 1,
    });
  };
  const handleChipDelete = () => {
    document.getElementById("patient-search").value = "";
    setVariables({
      ...variables,
      _firstName: "",
      page: 1,
    });
  };
  return (
    <div>
      <div className="mt-4">
        {variables._firstName && (
          <Chip
            label={variables._firstName}
            onDelete={() => handleChipDelete()}
            className={classes.chip}
          />
        )}
        <div className="d-flex justify-content-between align-items-end table-top-bar">
          <div className="d-flex align-items-center filters">
            <Form className="mt-2 mt-sm-0" onSubmit={(e) => handleSearch(e)}>
              <FormGroup className="d-inline-block gift-search-input">
                <Input
                  type="text"
                  name="value"
                  id="patient-search"
                  maxLength={10}
                  placeholder="search query..."
                />
                <button type="submit" className="gift-search-icon">
                  <i className="zmdi zmdi-search centrify-blue-color"></i>
                </button>
              </FormGroup>
            </Form>
          </div>
          <a
            onClick={(e) => props.allPicked()}
            className="filter-text centrify-blue-color"
          >
            Pick All
          </a>
        </div>
        <div className="mt-4" style={{ position: "relative" }}>
          {patient.loading && <TableLoader />}
          <Table responsive className="main-table">
            <thead className="main-table-header">
              <tr>
                <th>{t("id")}</th>
                <th>
                  <span className="ml-4 mb-0 d-flex">{t("name")}</span>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody className="main-table-body">{getPatientList}</tbody>
          </Table>
        </div>
        {patient.patientCount > 0 ? (
          <div className="mt-4 d-flex justify-content-center">
            <Pagination
              count={Math.ceil(patient.patientCount / 10)}
              page={variables.page}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
