import { Avatar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Input, Table } from "reactstrap";
import Pagination from "@material-ui/lab/Pagination";
// import ConfrimDialogue from "components/Dialogue/ConfrimDialogue";
// import History from "@history";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import { CircularProgress } from "@material-ui/core";
import TableLoader from "components/ErrorHandlers/TableLoader";
import { useDispatch, useSelector } from "react-redux";
import qs from "query-string";
import { useTranslation } from "react-i18next";
import * as Actions from "store/actions";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function ProjectFilterModal(props) {
  const dispatch = useDispatch();
  let querystr = qs.parse(props.location.search);
  const classes = useStyles();
  const [variables, setVariables] = useState({ page: 1 });
  const [project, setProject] = useState(null);
  const { t } = useTranslation();

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  const projectReducer = useSelector(({ project }) => project);
  const engagementReducer = useSelector(({ engagement }) => engagement);

  useEffect(() => {
    let vendor_id = Auth.vendor_id;
    dispatch(Actions.setProjectLoading(true));
    dispatch(Actions.getAllProjects(variables, vendor_id));
  }, [variables]);

  useEffect(() => {
    setProject(projectReducer);
  }, [projectReducer]);

  const handleSelectProject = (e, id) => {
    console.log(e.target.value);
    if (props.selectedProjects) {
      const findIndex = props.selectedProjects.indexOf(id);
      if (findIndex > -1) {
        props.selectedProjects.splice(findIndex, 1);
      } else {
        props.selectedProjects.push(id);
      }
      props.setSelectedProjects([...props.selectedProjects]);
    }
  };

  if (project && project.error) {
    return (
      <div className="text-center">
        <h2>{t("something_went_wrong")}</h2>
      </div>
    );
    // return <ErrorMessageComponents message="Cannot Fetch Projects" />;
  }

  if (project === null || !project.projectList) {
    return <CircularProgressComponent />;
  }

  const getProjectList =
    project.projectList && project.projectList.length > 0 ? (
      project.projectList.map((item) => {
        return (
          <tr>
            <td>
              <input
                type="checkbox"
                style={{ transform: "scale(1.5)" }}
                id={item.id}
                onChange={(e) => handleSelectProject(e, item.id)}
                checked={
                  (props.selectedProjects &&
                    props.selectedProjects.includes(item.id)) ||
                  (props.projectsList && props.projectsList.includes(item.id))
                }
              />
            </td>
            <td>
              <div className="ml-4 d-flex align-items-center">{item.id}</div>
            </td>
            <td>
              <div className="ml-4 d-flex align-items-center">
                <Avatar className="mr-3 medicine-pic" src={item.imageUrl} />
                <p className="mb-0 mr-4">{item.name}</p>
              </div>
            </td>
            <td>
              <div className="ml-4 d-flex align-items-center">
                <p className="mb-0 mr-4">{item.country}</p>
              </div>
            </td>
            {/* {patients.includes(item.id) ?
              <td>
                <a className="text-muted">
                  Added
              </a>
              </td> : <td onClick={() => setPatients([...patients, item.id])}>
                <a className="centrify-blue-color cursor-pointer">
                  Add To List
              </a>
              </td>
            } */}
          </tr>
        );
      })
    ) : (
      <tr>
        <td colspan={5} className="text-center font-weight-bold">
          {t("nothing_to_display")}
        </td>
      </tr>
    );

  const handlePageChange = (e, pagee) => {
    setVariables({
      ...variables,
      page: pagee,
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    props.setSelectedProjects([]);
    let search = document.getElementById("project-searchh").value;
    setVariables({
      ...variables,
      _name: search,
      page: 1,
    });
  };
  const handleChipDelete = () => {
    document.getElementById("project-searchh").value = "";
    setVariables({
      ...variables,
      _name: "",
      page: 1,
    });
  };
  return (
    <div className="">
      <div className="mt-4">
        {variables._name && (
          <Chip
            label={variables._name}
            onDelete={() => handleChipDelete()}
            className={classes.chip}
          />
        )}
        <div className="d-flex justify-content-between align-items-end table-top-bar">
          <div className="d-flex align-items-center filters">
            <Form className="mt-2 mt-sm-0" onSubmit={(e) => handleSearch(e)}>
              <FormGroup className="d-inline-block gift-search-input">
                <Input
                  type="text"
                  name="value"
                  id="project-searchh"
                  maxLength={10}
                  placeholder={t("search")}
                />
                <button type="submit" className="gift-search-icon">
                  <i className="zmdi zmdi-search centrify-blue-color"></i>
                </button>
              </FormGroup>
            </Form>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              color="none"
              onClick={() => props.handleAssignToProject()}
              type="submit"
              className="primary-background-button-table font-weight-bold d-inline "
            >
              {" "}
              {engagementReducer.assignLoading ? (
                <CircularProgress size={18} />
              ) : (
                t("assign")
              )}
            </Button>
          </div>
        </div>
        <div className="mt-4" style={{ position: "relative" }}>
          {project.loading && <TableLoader />}
          <Table responsive className="main-table">
            <thead className="main-table-header">
              <tr>
                <th>{t("pick")}</th>
                <th>
                  <span className="ml-4 mb-0 d-flex">{t("id")}</span>
                </th>
                <th>
                  <span className="ml-4 mb-0 d-flex">{t("name")}</span>
                </th>
                <th>
                  <span className="ml-4 mb-0 d-flex">{t("country")}</span>
                </th>
                {/* <th></th> */}
              </tr>
            </thead>
            <tbody className="main-table-body">{getProjectList}</tbody>
          </Table>
        </div>
      </div>

      {project?.projectCount > 0 ? (
        <div className="mt-4 d-flex justify-content-center">
          <Pagination
            count={Math.ceil(project?.projectCount / 10)}
            page={variables.page}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
          />
        </div>
      ) : null}
    </div>
  );
}
