import { CircularProgress } from "@material-ui/core";
import React from "react";

export default function TableLoader() {
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "rgba(0, 0, 0, 0.075)",
      }}
    >
      <CircularProgress />
    </div>
  );
}
