import React, { useEffect, useState } from "react";
import { HorizontalBar } from "react-chartjs-2";

const StatsBarChart = (props) => {
  const [data, setData] = useState({
    labels: ["Augmentin", "Iron", "Aspirin", "B12", "Other"],
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: "#FE870B",
        borderColor: "#FE870B",
        borderWidth: 1,
        //stack: 1,
        hoverBackgroundColor: "#FE870B",
        hoverBorderColor: "#FE870B",
        data: [65, 59, 80, 81, 56],
        radius: 1,
      },

      {
        label: "My second dataset",
        backgroundColor: "#9DA9CE",
        borderColor: "#9DA9CE",
        borderWidth: 2,
        //stack: 1,
        hoverBackgroundColor: "#9DA9CE",
        hoverBorderColor: "#9DA9CE",
        data: [45, 79, 50, 41, 16],
      },
    ],
  });

  useEffect(() => {
    let _data = props.options;
    let labels = [];
    let totalCount = [];
    let totalDone = [];
    _data.map((item) => {
      labels.push(item.option);
      // totalCount.push([100]);
      totalCount.push(item.optionCount);
    });
    // console.log(labels, totalCount, totalDone);
    setData({
      labels: labels,
      datasets: [
        {
          label: "Total Count",
          backgroundColor: "#FE870B",
          borderColor: "#FE870B",
          borderWidth: 1,
          //stack: 1,
          hoverBackgroundColor: "#FE870B",
          hoverBorderColor: "#FE870B",
          data: totalCount,
          barThickness: 9,
          radius: 1,
        },

        {
          label: " Actual",
          backgroundColor: "#9DA9CE",
          borderColor: "#9DA9CE",
          borderWidth: 1,
          //stack: 1,
          hoverBackgroundColor: "#9DA9CE",
          hoverBorderColor: "#9DA9CE",
          barThickness: 9,
          data: totalDone,
        },
      ],
    });
  }, [props]);

  const options = {
    responsive: true,
    legend: {
      display: false,
    },
    type: "horizontalBar",
    scales: {
      xAxes: [
        {
          ticks: { min: 0 }
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          }
        },
      ]
    }
  };
  return (
    <HorizontalBar data={data} width={null} height={null} options={options} />
  );
};

export default StatsBarChart;
