import { Avatar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Input, Table } from "reactstrap";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import History from "@history";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import TableLoader from "components/ErrorHandlers/TableLoader";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as Actions from "store/actions";
import qs from "query-string";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";

export default function Patients(props) {
  const dispatch = useDispatch();
  let querystr = qs.parse();
  let page = querystr.page ? JSON.parse(querystr.page) : 1;
  const [variables, setVariables] = useState({ ...querystr });
  const [patient, setPatient] = useState(null);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  const questionaireReducer = useSelector(({ questionaire }) => questionaire);

  useEffect(() => {
    const q_str = qs.stringify(variables);
    History.push({ search: q_str });
    dispatch(Actions.setPatientLoading(true));

    dispatch(Actions.getQuestionairePatients(variables, props.questionaireId));
  }, [variables, props.questionaireId]);

  useEffect(() => {
    setPatient(questionaireReducer);
  }, [questionaireReducer]);

  if (questionaireReducer.error && questionaireReducer?.patientList === null) {
    return <ErrorMessageComponents message="Cannot Fetch Patients" />;
  }

  if (patient === null || questionaireReducer.loading) {
    return <CircularProgressComponent />;
  }

  const getPatientList =
    patient.patientList && patient.patientList.length > 0 ? (
      patient.patientList.map((item) => {
        return (
          <tr>
            <td>
              <div
                onClick={() => History.push(`/patient-profile/${item.id}`)}
                className="ml-4 d-flex align-items-center cursor-pointer"
              >
                <Avatar className="mr-3 medicine-pic" src={item.image} />{" "}
                <p className="mb-0 prominent mr-4">{item.firstName}</p>
              </div>
            </td>
            <td>{item.email}</td>
            <td>{item.type}</td>
            <td>
              {item?.solvedQuestionnaire?.length > 0
                ? item?.solvedQuestionnaire[0].score
                : 0}
            </td>
            <td>
              {item?.solvedQuestionnaire?.length > 0 ? (
                <Link
                  to={`/survey/solved/${item.id}/${props.questionaireId}`}
                  className="filter-text centrify-blue-color"
                >
                  View
                </Link>
              ) : (
                <>Not Attempted</>
              )}
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colspan={7} className="text-center font-weight-bold">
          {t("nothing_to_display")}
        </td>
      </tr>
    );

  const handlePageChange = (e, page) => {
    setVariables({
      ...variables,
      page: page,
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let search = document.getElementById("patient-search").value;
    setVariables({
      ...variables,
      _firstName: search,
      page: 1,
    });
  };

  return (
    <div>
      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-end table-top-bar">
          <div className="d-flex align-items-center filters">
            <Form className="mt-2 mt-sm-0" onSubmit={(e) => handleSearch(e)}>
              <FormGroup className="d-inline-block gift-search-input">
                <Input
                  type="text"
                  name="value"
                  id="patient-search"
                  maxLength={10}
                  placeholder={t("search")}
                  style={{ paddingRight: "40px" }}
                />
                <button type="submit" className="gift-search-icon">
                  <i className="zmdi zmdi-search centrify-blue-color"></i>
                </button>
              </FormGroup>
            </Form>
          </div>
        </div>
        {!open && (
          <div className="mt-4" style={{ position: "relative" }}>
            {patient.loading && <TableLoader />}
            <Table responsive className="main-table">
              <thead className="main-table-header">
                <tr>
                  <th>
                    <span className="ml-4 mb-0 d-flex">{t("name")}</span>
                  </th>
                  <th>{t("email")}</th>
                  <th>{t("type")}</th>
                  <th>{t("score")}</th>
                  <th>{t("view")}</th>
                </tr>
              </thead>
              <tbody className="main-table-body">{getPatientList}</tbody>
            </Table>
          </div>
        )}
      </div>

      {questionaireReducer?.patientCount > 0 ? (
        <div className="mt-4 d-flex justify-content-center">
          <Pagination
            count={Math.ceil(questionaireReducer?.patientCount / 10)}
            page={page}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
          />
        </div>
      ) : null}
    </div>
  );
}
