import React, { useEffect, useState } from 'react'
import { Button, Form, FormGroup, Input } from 'reactstrap'

import Pagination from '@material-ui/lab/Pagination'
import ArticleCard from './articleCard'
import ArticleAddEditModal from './articleAddEditModal'
import ArticleViewModal from './articleViewModal'

import ConfrimDialogue from 'components/Dialogue/ConfrimDialogue'

import ErrorMessageComponents from 'components/ErrorHandlers/ErrorMessageComponents'
import { useDispatch, useSelector } from 'react-redux'
import qs from 'query-string'
import CircularProgressComponent from 'components/ErrorHandlers/CircularProgressComponent'
import { useTranslation } from 'react-i18next'
import * as Actions from 'store/actions'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import History from '@history'

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5)
  }
}))

const Articles = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const classes = useStyles()

  let querystr = qs.parse(props.location.search)
  let page = querystr.page ? JSON.parse(querystr.page) : 1
  const [variables, setVariables] = useState({ ...querystr })
  const [articleViewModal, setArticleViewModal] = useState({
    state: false,
    data: {}
  })
  const [articleModalState, setArticleModalState] = useState({
    data: {},
    modalType: 'add',
    state: false
  })

  const [dialogue, setDialogue] = useState(false)

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false
  })
  const articleReducer = useSelector(({ articles }) => articles)

  useEffect(() => {
    const q_str = qs.stringify(variables)
    History.push({ search: q_str })
    dispatch(Actions.setArticleLoading(true))
    dispatch(Actions.getArticles(variables, Auth.vendor_id))
  }, [variables])

  useEffect(() => {
    if (articleReducer.posted) {
      setArticleModalState({
        ...articleModalState,
        state: false
      })
    }
  }, [articleReducer])

  const handleSearch = e => {
    e.preventDefault()
    let search = document.getElementById('article-search').value
    setVariables({
      ...variables,
      title: search,
      page: 1
    })
  }

  const handleDelete = () => {
    document.getElementById('article-search').value = ''
    setVariables({
      ...variables,
      title: '',
      page: 1
    })
  }

  if (articleReducer && articleReducer.error) {
    return <ErrorMessageComponents message={t('cannot_fetch_articles')} />
  }

  const getArticles = () => {
    if (articleReducer && articleReducer.loading) {
      return <CircularProgressComponent />
    } else {
      return (
        articleReducer &&
        articleReducer.articlesList &&
        articleReducer.articlesList.map(article => (
          <ArticleCard
            article={article}
            articleModalState={articleModalState}
            setArticleModalState={setArticleModalState}
            articleViewModal={articleViewModal}
            setArticleViewModal={setArticleViewModal}
            setDialogue={setDialogue}
          />
        ))
      )
    }
  }

  const handleArticleDelete = () => {
    setDialogue(false)
    dispatch(Actions.setArticleLoading(true))
    dispatch(
      Actions.deleteArticle(
        articleModalState.data.id,
        variables,
        Auth.vendor_id
      )
    )
  }

  const handlePageChange = (e, page) => {
    setVariables({
      ...variables,
      page: page
    })
  }
  return (
    <div>
      <div className='mt-4'>
        <div>
          {variables.title && (
            <Chip
              label={variables.title}
              onDelete={() => handleDelete()}
              className={classes.chip}
            />
          )}
        </div>
        <div className='d-flex justify-content-between align-items-end table-top-bar'>
          <div className='d-flex align-items-center filters'>
            <Form className='mt-2 mt-sm-0' onSubmit={e => handleSearch(e)}>
              <FormGroup className='d-inline-block gift-search-input'>
                <Input
                  type='text'
                  name='value'
                  id='article-search'
                  maxLength={10}
                  placeholder={t('search')}
                  style={{ paddingRight: '40px' }}
                />
                <button type='submit' className='gift-search-icon'>
                  <i className='zmdi zmdi-search centrify-blue-color'></i>
                </button>
              </FormGroup>
            </Form>
          </div>

          <Button
            onClick={() =>
              setArticleModalState({
                state: !articleModalState.state,
                data: {},
                modalType: 'add'
              })
            }
            color='none'
            className='primary-background-button-table font-weight-bold'
          >
            {t('add_article')}
          </Button>
        </div>
        <div>
          <div className='row d-flex justify-content-center'>
            {getArticles()}
          </div>

          {articleReducer?.articleCount > 0 ? (
            <div className='mt-4 d-flex justify-content-center'>
              <Pagination
                count={Math.ceil(articleReducer?.articleCount / 10)}
                page={page}
                onChange={handlePageChange}
                variant='outlined'
                shape='rounded'
              />
            </div>
          ) : null}
        </div>
        <ArticleAddEditModal
          modal={articleModalState.state}
          data={articleModalState.data}
          modalType={articleModalState.modalType}
          vendor_id={Auth.vendor_id}
          toggle={() => {
            setArticleModalState({ state: !articleModalState.state, data: {} })
          }}
        />
        <ArticleViewModal
          modal={articleViewModal.state}
          data={articleViewModal.data}
          toggle={() => {
            setArticleViewModal({
              state: !articleViewModal.state,
              data: {}
            })
          }}
        />
        <ConfrimDialogue
          open={dialogue}
          handleClose={() => setDialogue(false)}
          action={() => handleArticleDelete()}
          msg={t('are_you_sure_you_want_to_delete') + '?'}
        />
      </div>
    </div>
  )
}

export default Articles
