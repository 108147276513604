import React from 'react'
import { CircularProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'

function OrderStatusDialogue (props) {
  return (
    <Dialog open={props.open} onClose={() => props.handleClose()}>
      <DialogTitle id='alert-dialog-title'>{props.msg}</DialogTitle>
      <DialogActions style={{ padding: '05px 20px 20px 0px' }}>
        <div className='mt-4 d-flex justify-content-end'>
          <Button
            variant='contained'
            onClick={() => props.handleClose()}
            type='submit'
            className='btn-danger text-white'
            color='none'
          >
            Cancel
          </Button>
        </div>
        <div className='mt-4 d-flex justify-content-end'>
          <Button
            variant='contained'
            onClick={() => props.action('acknowledged')}
            type='submit'
            color='primary'
            className='text-white'
          >
            Confirm
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default OrderStatusDialogue
