import { CircularProgress, IconButton } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody, Label, Input } from "reactstrap";
import FileDrop from "react-dropzone";
import AddIcon from "@material-ui/icons/Add";
import PublishIcon from "@material-ui/icons/Publish";
import { v4 as uuidv4 } from "uuid";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import * as Actions from "store/actions";
import useRecorder from "lib/useRecorder";
import StopIcon from "@material-ui/icons/Stop";
import DeleteIcon from "@material-ui/icons/Delete";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import axios from "axios";
import Domain from "lib/Config";
import DatePicker from "components/DatePicker/DatePicker";
import { timeZoneList as timeZone } from "lib/timeZone";

const s3Cdn = axios.create({});

const AddActivity = (props) => {
  const [images, setImages] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const [recordLoading, setRecordLoading] = useState(false);
  const [recordingFile, setRecordingFile] = useState(null);
  const [recordingUrl, setRecordingUrl] = useState(null);
  const [dateRange, setDateRange] = useState("");
  const [actualDateRange, setActualDateRange] = useState(null);
  const [when, setWhen] = useState("everyday");

  let [audioURL, isRecording, startRecording, stopRecording, deleteRecording] =
    useRecorder();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2014-08-18T21:11:54")
  );
  const [type, setType] = React.useState("medicine");

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  const activityReducer = useSelector(({ activity }) => activity);
  const medicineReducer = useSelector(({ medicine }) => medicine);

  useEffect(() => {
    if (props.open) {
      dispatch(Actions.setMedicineLoading(true));
      dispatch(Actions.getAllMedicines({}, Auth.vendor_id));
    }
  }, [props]);

  useEffect(() => {
    if (audioURL !== "") {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", audioURL, true);
      xhr.responseType = "blob";
      xhr.onload = function (e) {
        if (this.status == 200) {
          var myBlob = this.response;
          var file = new File(
            [myBlob],
            `${Date.now()}-audio-file-activity.webm`,
            {
              lastModified: Date.now(),
              type: myBlob.type,
            }
          );
          setRecordingFile(file);
          // myBlob is now the blob that the object URL pointed to.
        }
      };
      xhr.send();
    }
  }, [audioURL]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let obj = {};
    const formData = new FormData(e.target);
    for (let [key, value] of formData.entries()) {
      obj[key] = value;
    }
    obj.createdBy = "employee";
    obj.patient_id = props.patient_id;
    obj.employee_id = props.employee_id;
    obj.time = moment(selectedDate).utc().format("HH:mm");
    obj.status = "pending";
    obj.files = [];
    if (recordingUrl) {
      obj.files.push({
        file: recordingUrl,
        type: "audio",
      });
    }
    if (images.length > 0) {
      images.map((item) => {
        obj.files.push({
          file: item.File,
          type: item.type,
        });
      });
    }
    console.log(actualDateRange);
    if (actualDateRange) {
      obj.from = actualDateRange[0];
      obj.to = actualDateRange[1];
    }
    obj.project_id = props.project_id;
    delete obj.daterange_from;
    delete obj.daterange_to;
    delete obj.day;
    delete obj.month;
    delete obj.year;
    dispatch(Actions.setActivityLoading(true));
    dispatch(Actions.addActivity(obj));
  };

  const handleDateChange = (date) => {
    console.log(date);
    setSelectedDate(date);
  };

  const onDrop = useCallback(
    (img) => {
      setImageLoading(true);
      handleFileUpload(img[0]);
    },
    [images]
  );

  const removeImg = (id) => {
    setImages(images.filter((img) => img.id !== id));
  };

  const handleFileUpload = (data) => {
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      },
      header: {
        "Content-Type": data.type,
      },
    };
    getSignedURL(data.name, data.type).then((rsp) => {
      const request = s3Cdn
        .put(rsp.data.result.postUrl, data, config)
        .then((res) => {
          let type = data.type;
          let _type = type.split("/")[0];
          if (_type == "audio") {
            setRecordingUrl(rsp.data.result.getUrl);
            setRecordLoading(false);
          } else {
            let image = {
              id: uuidv4(),
              File: rsp.data.result.getUrl,
              type: _type,
            };
            setImages([...images, image]);
            setImageLoading(false);
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    });
  };

  const getSignedURL = (key, type) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Domain}/api/vendor/s3getsignedurl?key=${key}&type=${type}`)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          console.log(err.response);
          reject(err);
        });
    });
  };

  const uploadRecording = () => {
    setRecordLoading(true);
    handleFileUpload(recordingFile);
  };

  const handleDateRange = (e) => {
    if (!e) {
      setDateRange("");
      return;
    }
    console.log(e);
    const formatedDate = [
      moment(e[0]).format("YYYY-MM-DD"),
      moment(e[1]).format("YYYY-MM-DD"),
    ];
    console.log(e);
    setActualDateRange(e);
    setDateRange(formatedDate);
  };

  return (
    <div>
      <Modal
        isOpen={props.open}
        toggle={props.toggle}
        className="modal-background"
      >
        <ModalBody>
          <div className="p-4">
            <div className="d-flex justify-content-end">
              <img
                onClick={(e) => props.toggle()}
                className="centrify-icons cursor-pointer"
                src={require("assets/icons/close.png")}
              />
            </div>
            <div className="mt-2 form-styles">
              <h2 className="form-heading d-flex">{t("add_activity")}</h2>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="mt-4">
                  <Label className="d-flex">{t("title")}</Label>
                  <Input type="text" name="title" required />
                </div>
                <div className="mt-4">
                  <Label className="d-flex">{t("type")}</Label>
                  <Input
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    type="select"
                    name="type"
                    required
                  >
                    <option value="medicine">{t("medication")}</option>
                    <option value="meeting">{t("meeting")}</option>
                  </Input>
                </div>
                {type == "medicine" && (
                  <div>
                    <div className="mt-4">
                      <Label className="d-flex">{t("medicine")}</Label>
                      <Input
                        type="select"
                        name="medicine_id"
                        required
                        disabled={medicineReducer.loading}
                      >
                        {medicineReducer.medicineList &&
                          medicineReducer.medicineList.map((item) => {
                            return (
                              <option
                                id={item.id}
                                key={item.id}
                                value={item.id}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                      </Input>
                    </div>
                    <div className="mt-4">
                      <Label className="d-flex">{t("dose")}</Label>
                      <Input type="text" name="dose" required min="1" />
                    </div>
                    <div className="mt-4">
                      <Label className="d-flex">{t("when")}</Label>
                      <Input
                        type="select"
                        name="when"
                        required
                        value={when}
                        onChange={(e) => setWhen(e.target.value)}
                      >
                        <option value="everyday">{t("everyday")}</option>
                        <option value="twice">{t("twice_a_week")}</option>
                      </Input>
                    </div>
                    <div className="mt-4">
                      <Label className="d-flex">{t("take_it")}</Label>
                      <Input type="select" name="takeIt" required>
                        <option value="before meal">{t("before_meal")}l</option>
                        <option value="after meal">{t("after_meal")}</option>
                      </Input>
                    </div>
                  </div>
                )}
                <div className="mt-4">
                  <Label className="d-flex">{t("select_date_range")}</Label>
                  <DatePicker
                    className="w-100"
                    value={dateRange}
                    handleChange={handleDateRange}
                  />
                </div>
                <div className="mt-4">
                  <Label className="d-flex">{t("information")}</Label>
                  <Input type="textarea" name="info" required />
                </div>
                <div className="mt-4">
                  <Label className="d-flex">{t("time")}</Label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      className="w-100"
                      margin="normal"
                      id="time-picker"
                      value={selectedDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className="mt-4">
                  <Label className="d-flex">{t("time_zone")}</Label>
                  <Input type="select" name="timezone" required>
                    {timeZone.map((item) => {
                      return <option value={item.value}>{item.key}</option>;
                    })}
                  </Input>
                </div>
                <div className="mt-4">
                  <audio style={{ width: "100%" }} src={audioURL} controls />
                </div>
                <div className="mt-2 d-flex justify-content-center">
                  <IconButton
                    disabled={isRecording}
                    onClick={startRecording}
                    className="mr-2"
                  >
                    <FiberManualRecordIcon className="text-danger" />
                  </IconButton>
                  <IconButton
                    disabled={!isRecording}
                    onClick={stopRecording}
                    className="mr-2"
                  >
                    <StopIcon />
                  </IconButton>
                  <IconButton
                    disabled={isRecording}
                    onClick={deleteRecording}
                    className="mr-2"
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    disabled={isRecording || audioURL == ""}
                    onClick={uploadRecording}
                    className="mr-2"
                  >
                    <PublishIcon />
                  </IconButton>
                </div>
                <div className="text-center" style={{ height: "20px" }}>
                  {isRecording ? (
                    <p>{t("recording")}...</p>
                  ) : recordLoading ? (
                    <p>{t("uploading")}...</p>
                  ) : null}
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="victor"
                    name="victor"
                    value="Bike"
                  />
                  <label for="victor">Victor Feature</label>
                </div>
                <Label className="d-flex">{t("upload_files")}</Label>
                <div>
                  <div className="row">
                    {images &&
                      images.length > 0 &&
                      images.map((img) => (
                        <div
                          className="col-md-2 mb-2 animate__animated animate__fadeIn"
                          key={img.id}
                        >
                          <div className="img-div d-flex flex-column">
                            {img.type == "image" ? (
                              <img src={img.File} width="100%" alt="qwe" />
                            ) : img.type == "video" ? (
                              <video>
                                <source src={img.File} />
                              </video>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="del-img">
                            <IconButton
                              color="inherit"
                              mini="true"
                              className="p-0"
                              onClick={(e) => removeImg(img.id)}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </div>
                        </div>
                      ))}
                    <div className="col-md-2">
                      <FileDrop
                        disabled={imageLoading}
                        accept="image/png, image/gif, image/jpeg, image/bmp, video/mp4, video/mpeg, video/mpe, video/mpv, video/ogg, video/m4p, video/avi, video/wmv"
                        multiple
                        onDrop={onDrop}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()} className="img-div">
                            <IconButton
                              color="inherit"
                              mini="true"
                              aria-label="Menu"
                              className="p-0"
                            >
                              {imageLoading ? (
                                <CircularProgress
                                  style={{ height: "10px", width: "10px" }}
                                />
                              ) : (
                                <AddIcon fontSize="small" />
                              )}
                            </IconButton>
                            <input {...getInputProps()} />
                          </div>
                        )}
                      </FileDrop>
                    </div>
                  </div>
                </div>
                <div className="mt-4 d-flex justify-content-end">
                  {activityReducer.loading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      type="submit"
                      className="primary-background-button-table"
                      color="none"
                    >
                      {t("add_activity")}
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddActivity;
