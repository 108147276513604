import React, { Component } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const ArticleEditor = () => {
  return (
    <div>
      <h2>Using CKEditor 5 build in React</h2>
    </div>
  );
};

export default ArticleEditor;
