import React from "react";
import { Typography } from "@material-ui/core";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";

const NotFound = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="text-center" style={{ paddingTop: "45px" }}>
        {/* <i class="zmdi zmdi-lock-open" style={{ fontSize: "42px" }}></i> */}
        <ReportProblemIcon style={{ fontSize: "98px", marginBottom: "10px" }} />
        <Typography variant="h3" color="textPrimary" className="mb-4">
          {t("permission_denied")}
        </Typography>

        <Typography variant="h6" color="textSecondary" className="mb-3">
          {t(
            "looks_like_you_are_trying_to_access_a_page_that_you_do_not_have_permission_to_view"
          )}
        </Typography>
        <Typography
          variant="h7"
          color="textSecondary"
          display="block"
          className="mb-3"
        >
          {t("please_contact_your_administrator_if_you_think_this_is_an_error")}
        </Typography>
        <Typography
          variant="h7"
          color="textSecondary"
          display="block"
          className="mb-3"
        >
          {t("mean_white_let_us_take_you_back_safely")}
        </Typography>
        <Button color="primary" onClick={props.history.goBack}>
          {t("go_back")}
        </Button>
      </div>
    </div>
  );
};
export default NotFound;
