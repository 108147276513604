import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  removeElements,
  isNode,
} from "react-flow-renderer";
import dagre from "dagre";
import { v4 as uuidv4 } from "uuid";
import { Dialog, AppBar, Toolbar, IconButton, Slide } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ toggle, questions, dialogState }) {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 400;
  const nodeHeight = 150;
  const getLayoutedElements = (elements, direction = "LR") => {
    const isHorizontal = direction === "LR";
    dagreGraph.setGraph({ rankdir: direction });
    elements.forEach((el) => {
      if (isNode(el)) {
        dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
      } else {
        dagreGraph.setEdge(el.source, el.target);
      }
    });

    dagre.layout(dagreGraph);
    return elements.map((el) => {
      if (isNode(el)) {
        const nodeWithPosition = dagreGraph.node(el.id);
        el.targetPosition = isHorizontal ? "left" : "top";
        el.sourcePosition = isHorizontal ? "right" : "bottom";

        // unfortunately we need this little hack to pass a slightly different position
        // to notify react flow about the change. Moreover we are shifting the dagre node position
        // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
        el.position = {
          x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
          y: nodeWithPosition.y - nodeHeight / 2,
        };
      }

      return el;
    });
  };
  const position = { x: 0, y: 0 };
  let questionStyle = {
    background: "#FFE0A0",
    color: "#111",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
    borderRadius: "7px",
    padding: "3px",
    height: "90px",
    width: "225px",
  };
  let answerStyle = {
    background: "#D0FFD0",
    color: "#111",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
    borderRadius: "7px",
    padding: "3px",
    height: "90px",
    width: "225px",
  };
  const edgeType = "smoothstep";
  const stringTruncate = (str, length) => {
    const dots = str.length > length ? "....." : "";
    return `${str.substring(0, length)}${dots}`;
  };

  let createConnection = (graphArray, sourceId, targetId, label) => {
    graphArray.push({
      id: uuidv4(),
      source: sourceId,
      target: targetId,
      type: edgeType,
      animated: true,
      label: label,
    });
  };
  useEffect(() => {
    if (dialogState) {
      let graphArray = [];
      // Creating Nodes and Connection for Graph
      questions.map((question, index) => {
        graphArray.push({
          id: question.UUID,
          data: {
            label: (
              <div>
                <h3>Question {index + 1}</h3>
                <p>{stringTruncate(question.title, 65)}</p>
              </div>
            ),
          },
          position,
          style: questionStyle,
        });
        // Creating Answer Nodes in case of Media Type
        if (question.answer) {
          graphArray.push({
            id: question.answerUniqueId,
            data: {
              label: (
                <div>
                  <h3>Answer</h3>
                  <p>{stringTruncate(question.answer, 65)}</p>
                </div>
              ),
            },
            position,
            style: answerStyle,
          });
        }
        // Creating Nodes in case of MCQ Type
        if (question.options) {
          question.options.map((questionOption, index) => {
            if (questionOption.answer) {
              graphArray.push({
                id: questionOption.answerUniqueId,
                data: {
                  label: (
                    <div>
                      <h3>Answer</h3>
                      <p>{stringTruncate(questionOption.answer, 70)}</p>
                    </div>
                  ),
                },
                position,
                style: answerStyle,
              });
            }
            console.log("questionOption --->", questionOption);
            if (questionOption.product && questionOption.product.length > 0) {
              graphArray.push({
                id: questionOption.product[0].UUID,
                data: {
                  label: (
                    <div>
                      <h3>Product</h3>
                      <p>
                        {stringTruncate(questionOption.product[0].name, 70)}
                      </p>
                    </div>
                  ),
                },
                position,
                style: answerStyle,
              });
            } else if (
              questionOption.article &&
              questionOption.article.length > 0
            ) {
              let uuid = uuidv4();
              questionOption.article[0].UUID = uuid;
              graphArray.push({
                id: uuid,
                data: {
                  label: (
                    <div>
                      <h3>Article</h3>
                      <p>
                        {stringTruncate(questionOption.article[0].name, 70)}
                      </p>
                    </div>
                  ),
                },
                position,
                style: answerStyle,
              });
            }
          });
        }
      });
      // Creating Connections
      questions.map((question, index) => {
        // Question to Question
        if (question.linkedToQuestion) {
          createConnection(
            graphArray,
            question.UUID,
            question.linkedToQuestion
          );
        }
        // Question to Answer
        if (question.answer) {
          createConnection(graphArray, question.UUID, question.answerUniqueId);
        }
        if (question.options && question.options.length > 0) {
          // Variables in case multiple options are linking to same question
          let linkedToId = null;
          let optionLabel = null;
          question.options.map((questionOption, index) => {
            let productExist =
              questionOption.product && questionOption.product.length > 0;
            let product = productExist && questionOption.product[0];
            let articleExist =
              questionOption.article && questionOption.article.length > 0;
            let article = articleExist && questionOption.article[0];
            // Linked to question and answer case
            if (questionOption.linkedTo && questionOption.answer) {
              let productConnection = false;
              let articleConnection = false;
              createConnection(
                graphArray,
                question.UUID,
                questionOption.answerUniqueId,
                questionOption.option
              );
              // If product exist create a connection with product
              if (questionOption.product && questionOption.product.length > 0) {
                productConnection = true;
              }
              // If Article exist create a connection with product
              if (questionOption.article && questionOption.article.length > 0) {
                articleConnection = true;
              }

              let target = productConnection
                ? product.UUID
                : articleConnection
                ? article.UUID
                : questionOption.linkedTo;
              createConnection(
                graphArray,
                questionOption.answerUniqueId,
                target,
                productConnection || articleConnection
                  ? null
                  : questionOption.option
              );
              if (questionOption.product && questionOption.product.length > 0) {
                createConnection(
                  graphArray,
                  product.UUID,
                  questionOption.linkedTo
                );
              } else if (
                questionOption.article &&
                questionOption.article.length > 0
              ) {
                createConnection(
                  graphArray,
                  article.UUID,
                  questionOption.linkedTo
                );
              }
            }
            // Linked to question but no answer
            if (questionOption.linkedTo && !questionOption.answer) {
              let label = null;
              let productConnection = false;
              let articleConnection = false;
              if (linkedToId == questionOption?.linkedTo) {
                label = optionLabel + " / " + questionOption.option;
              } else {
                label = questionOption.option;
              }
              // Checking if product exist create connection
              if (productExist) {
                productConnection = true;
                createConnection(
                  graphArray,
                  question?.UUID,
                  product.UUID,
                  label
                );
              }
              // checking if article exist create connection
              else if (articleExist) {
                articleConnection = true;
                createConnection(
                  graphArray,
                  question?.UUID,
                  article.UUID,
                  label
                );
              }
              createConnection(
                graphArray,
                productConnection
                  ? product.UUID
                  : articleConnection
                  ? article.UUID
                  : question?.UUID,
                questionOption?.linkedTo,
                productConnection ? null : label
              );
              linkedToId = productConnection
                ? product.UUID
                : questionOption?.linkedTo;

              optionLabel = questionOption?.option;
            }
            // Not linked but have answer
            if (
              !questionOption.linkedTo &&
              questionOption.answer &&
              questionOption.answer.length > 0
            ) {
              createConnection(
                graphArray,
                question.UUID,
                questionOption?.answerUniqueId,
                questionOption.option
              );
              // Creating a connnection with product
              if (productExist) {
                createConnection(
                  graphArray,
                  questionOption?.answerUniqueId,
                  product.UUID,
                  null
                );
              }
              // Creating a connection with article
              else if (articleExist) {
                createConnection(
                  graphArray,
                  questionOption?.answerUniqueId,
                  questionOption.article[0].UUID,
                  null
                );
              }
            }
            // Not Linked and No Answer but have product or have article
            if (
              !questionOption.linkedTo &&
              !questionOption.answer &&
              (productExist || articleExist)
            ) {
              createConnection(
                graphArray,
                question?.UUID,
                questionOption.article_id ? article.UUID : product.UUID,
                questionOption.option
              );
            }
          });
        }
      });

      const layoutedElements = getLayoutedElements(graphArray);
      setElements(layoutedElements);
    }
  }, [dialogState]);

  const [elements, setElements] = useState([]);
  const onConnect = (params) =>
    setElements((els) =>
      addEdge({ ...params, type: "smoothstep", animated: true }, els)
    );
  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els));

  const onLayout = useCallback(
    (direction) => {
      const layoutedElements = getLayoutedElements(elements, direction);
      setElements(layoutedElements);
    },
    [elements]
  );
  const graphStyles = {
    width: "100%",
    // height: "80vh",
    // top: "10vh",
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={dialogState}
        onClose={toggle}
        TransitionComponent={Transition}
      >
        <AppBar className="bg-primary">
          <Toolbar
            style={{
              background: "#FFAF2A",
              color: "white",
            }}
          >
            <IconButton color="inherit" onClick={toggle} aria-label="Close">
              <CloseIcon />
            </IconButton>

            <h5 className="w-100 mb-0 ml-2 fw-bold">Questionnaire Tree</h5>
          </Toolbar>
        </AppBar>
        <div
          className=""
          style={{
            position: "absolute",
            bottom: "0",
            right: "0",
            left: "0",
            border: "1px solid black",
            width: "100%",
            margin: "auto",
            padding: "10px",
            height: "93%",
          }}
        >
          <ReactFlowProvider>
            <ReactFlow
              elements={elements}
              onConnect={onConnect}
              onElementsRemove={onElementsRemove}
              connectionLineType="smoothstep"
              style={graphStyles}
            />
          </ReactFlowProvider>
        </div>
      </Dialog>
    </div>
  );
}
