import React from 'react'
import { Input } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { FaImages } from 'react-icons/fa'
import { MdOutlineVideoLibrary } from 'react-icons/md'

const MediaTypeQuestion = ({
  question,
  quesIndex,
  questions,
  setQuestions
}) => {
  const { t } = useTranslation()
  return (
    <div className='w-100'>
      <div className='row p-0 w-100 mt-4 '>
        <div className='col-md-5 d-flex align-items-start mb-4'>
          <div
            className={`d-inline mr-4 p-4 text-center ${
              questions[quesIndex].questionType == 'VIDEO'
                ? 'inputImageWrapperSelected'
                : 'inputImageWrapper'
            } `}
            onClick={e => {
              questions[quesIndex] = {
                ...questions[quesIndex],
                questionType: 'VIDEO',
                options: null
              }
              setQuestions([...questions])
            }}
          >
            <MdOutlineVideoLibrary className='d-block mb-2 mx-auto  inputImage' />
            <span>{t('get_video')}</span>
          </div>
          <div
            className={`d-inline mr-4 p-4 text-center ${
              question.questionType == 'IMAGE'
                ? 'inputImageWrapperSelected'
                : 'inputImageWrapper'
            } `}
            onClick={e => {
              questions[quesIndex] = {
                ...questions[quesIndex],
                options: null,
                questionType: 'IMAGE'
              }
              setQuestions([...questions])
            }}
          >
            <FaImages className='d-block mb-2 mx-auto inputImage' />
            <span>{t('get_image')}</span>
          </div>
        </div>
        <div className='col-sm-12 col-md-4 align-items-start mb-4 w-100'>
          <input
            className='formAnswerInput p-2 '
            placeholder={t('answer')}
            value={questions[quesIndex].answer || null}
            onChange={e => {
              questions[quesIndex].answer = e.target.value
              setQuestions([...questions])
            }}
          ></input>
        </div>
        <div className='col-sm-12 col-md-3 pr-0 align-items-start mb-4'>
          <Input
            type='select'
            name='questionType'
            id={quesIndex}
            className='border-0'
            value={
              question.linkedToQuestion ? question.linkedToQuestion : 'null'
            }
            onChange={e => {
              questions[quesIndex].linkedToQuestion = e.target.value
              setQuestions([...questions])
            }}
          >
            <option value={'null'} selected>
              {t('link_to_no_question')}
            </option>
            {questions && questions.length > 0
              ? questions.map((question, index) => {
                  if (index > quesIndex) {
                    return (
                      <option id={index} value={question.UUID}>
                        {t('link_to_question')} {index + 1}{' '}
                      </option>
                    )
                  }
                })
              : null}
          </Input>
        </div>
      </div>
    </div>
  )
}

export default MediaTypeQuestion
