// async component
import {
  AsyncManageProjectComponent,
  AsyncProjectsListComponent,
  AsyncEmployeesList,
  AsyncPatientsList,
  AsyncMedicineList,
} from "components/AsyncComponent/AsyncComponent";
import NotFound from "components/pages/404";
import CommingSoon from "components/pages/CommingSoon";
import adminRoles from "lib/adminRoles";
import EmployeeProfile from "routes/employees/employeeProfile";
import Notifications from "routes/notifications";

import Articles from "routes/articles/index";
import ArticleEditor from "routes/articles/articleEditor";
import Dashboard from "routes/dashboard/index";
import Order from "routes/orders";
import ProjectDetail from "routes/projects/projectTabs/index";
import TreeQuestionnaire from "routes/vChat";
import AddTreeQuestionnaire from "routes/vChat/addQuestionnaire";
import EditTreeQuestionnaire from "routes/vChat/addQuestionnaire";
import Questionaire from "routes/questionaires";
import Reports from "routes/reports";

import QuestionaireDetails from "routes/questionaires/questionaireTabs";
import TreeQuestionnaireDetails from "routes/vChat/questionaireTabs";
import AddQuestionaire from "routes/questionaires/addQuestionaire";
import EditQuestionaire from "routes/questionaires/editQuestionaire";
import SolvedQuestionaire from "routes/questionaires/patientQuestionaires/solved";
import SolvedTreeQuestionaire from "routes/vChat/patientQuestionaires/solved";

import OptionPatients from "routes/questionaires/selectOptionPatients/patient";
import Profile from "routes/profile";
import Meeting from "routes/meetingg/meeting2";
import MeetingList from "routes/meetingg/activities";
import PatientProfile from "routes/projects/patient";
import PatientMedia from "routes/projects/patient/patientMedia/patientMedia";
import AddActivity from "routes/projects/patient/activityLogs/addActivity";

import Engagement from "routes/engagements/index";
import EngagementDetail from "routes/engagements/engagementTabs/index";
import AddEngagementQuestionnaire from "routes/engagements/engagementTabs/questionnaire/addEngagementQuestionnarie";
import EditEngagementQuestionnaire from "routes/engagements/engagementTabs/questionnaire/editEngagementQuestionnaire";
import AddEngagmentActivity from "routes/engagements/engagementTabs/activities/addEngagementActivity";
import EditEngagmentActivity from "routes/engagements/engagementTabs/activities/editEngagementActivity";

import StoreProducts from "routes/store/products";
import StoreProduct from "routes/store/products/productDetail";
import StoreCategories from "routes/store/categories/index";
import Stats from "routes/stats";
import { AlertScheduler } from "components/NotificationAlert/schedulerComp";
import EditBulkActivity from "routes/projects/patient/activityLogs/editBulkActivity";

export default [
  {
    path: "dashboard",
    component: Dashboard,
    auth: adminRoles.dashboard,
  },
  {
    path: "projects",
    component: AsyncProjectsListComponent,
    auth: adminRoles.projects,
  },
  {
    path: "project/:projectName/:id/:tab",
    component: ProjectDetail,
    auth: adminRoles.projects,
  },
  {
    path: "manageProjects/:id",
    component: AsyncManageProjectComponent,
    auth: adminRoles.manageProjects,
  },
  {
    path: "patientProject/:projectName/:patientName/:patientId/:projectId",
    component: PatientProfile,
    auth: adminRoles.patientProject,
  },
  {
    path: "profile",
    component: Profile,
    auth: adminRoles.profile,
  },
  {
    path: "employees",
    component: AsyncEmployeesList,
    auth: adminRoles.employees,
  },

  {
    path: "employee-profile/:id",
    component: EmployeeProfile,
    auth: adminRoles.employeeProfile,
  },
  {
    path: "medicines",
    component: AsyncMedicineList,
    auth: adminRoles.medicines,
  },
  {
    path: "patients",
    component: AsyncPatientsList,
    auth: adminRoles.patients,
  },
  {
    path: "patient-profile/:patientName/:patientId",
    component: PatientProfile,
    auth: adminRoles.patientProfile,
  },
  {
    path: "patient-media/:patientName/:patientId",
    component: PatientMedia,
    auth: adminRoles.patientProfile,
  },
  {
    path: "stats",
    component: Stats,
    auth: adminRoles.stats,
  },
  {
    path: "reports",
    component: Reports,
    auth: adminRoles.reports,
  },
  {
    path: "notifications",
    component: Notifications,
    auth: adminRoles.notifications,
  },
  {
    path: "add-activity/:id/:projectId/:status",
    component: AddActivity,
    auth: adminRoles.addActivity,
  },
  {
    path: "edit-activity/:id/:edittype",
    component: EditBulkActivity,
    auth: adminRoles.addActivity,
  },
  {
    path: "page-error",
    component: NotFound,
    auth: adminRoles.pageError,
  },
  {
    path: "meeting/:activityId/:patientId",
    component: Meeting,
    auth: adminRoles.orders,
  },
  {
    path: "scheduler",
    component: AlertScheduler,
    auth: adminRoles.orders,
  },
  {
    path: "meeting",
    component: MeetingList,
    auth: adminRoles.orders,
  },
  {
    path: "orders/:tab",
    component: Order,
    auth: adminRoles.orders,
  },
  {
    path: "articles",
    component: Articles,
    auth: adminRoles.orders,
  },
  {
    path: "article/editor",
    component: ArticleEditor,
    auth: adminRoles.orders,
  },
  {
    path: "stores",
    component: CommingSoon,
    auth: adminRoles.stores,
  },
  {
    path: "settings",
    component: CommingSoon,
    auth: adminRoles.settings,
  },
  {
    path: "treeQuestionnaires",
    component: TreeQuestionnaire,
    auth: adminRoles.vChat,
  },
  {
    path: "treeQuestionnaire/:id",
    component: TreeQuestionnaireDetails,
    auth: adminRoles.vChat,
  },
  {
    path: "addtreequestionnaire",
    component: AddTreeQuestionnaire,
    auth: adminRoles.vChat,
  },
  {
    path: "edittreequestionnaire/:id",
    component: EditTreeQuestionnaire,
    auth: adminRoles.vChat,
  },

  {
    path: "questionnaires",
    component: Questionaire,
    auth: adminRoles.questionaires,
  },
  {
    path: "questionnaire/:id",
    component: QuestionaireDetails,
    auth: adminRoles.questionaires,
  },
  {
    path: "survey/add",
    component: AddQuestionaire,
    auth: adminRoles.addQuestionaires,
  },
  {
    path: "survey/edit/:id",
    component: EditQuestionaire,
    auth: adminRoles.addQuestionaires,
  },
  {
    path: "survey/solved/:pId/:qId",
    component: SolvedQuestionaire,
    auth: adminRoles.addQuestionaires,
  },
  {
    path: "surveyQuestionnaire/solved/:pId/:qId",
    component: SolvedTreeQuestionaire,
    auth: adminRoles.addQuestionaires,
  },
  {
    path: "survey/options/patients/stats/:opId",
    component: OptionPatients,
    auth: adminRoles.addQuestionaires,
  },
  {
    path: "engagements",
    component: Engagement,
    auth: adminRoles.engagements,
  },
  {
    path: "engagement/:id/:tab",
    component: EngagementDetail,
    auth: adminRoles.engagements,
  },
  {
    path: "addEngagementQuestionnarie/:id",
    component: AddEngagementQuestionnaire,
    auth: adminRoles.engagements,
  },
  {
    path: "editEngagementQuestionnarie/:eId/:qId",
    component: EditEngagementQuestionnaire,
    auth: adminRoles.engagements,
  },
  {
    path: "addEngagementactivity/:id",
    component: AddEngagmentActivity,
    auth: adminRoles.engagements,
  },
  {
    path: "editEngagementactivity/:eId/:aId",
    component: EditEngagmentActivity,
    auth: adminRoles.engagements,
  },
  {
    path: "products",
    component: StoreProducts,
    auth: adminRoles.addQuestionaires,
  },
  {
    path: "product/:productName/:productId/:tab",
    component: StoreProduct,
    auth: adminRoles.addQuestionaires,
  },
  {
    path: "categories",
    component: StoreCategories,
    auth: adminRoles.addQuestionaires,
  },
];
