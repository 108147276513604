import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Form, FormGroup, Input, Table, Button } from "reactstrap";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Pagination from "@material-ui/lab/Pagination";
import ConfrimDialogue from "components/Dialogue/ConfrimDialogue";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import momentt from "moment-timezone";
import History from "@history";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import TableLoader from "components/ErrorHandlers/TableLoader";
import { useDispatch, useSelector } from "react-redux";
import qs from "query-string";
import moment from "moment";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import { useTranslation } from "react-i18next";
import * as Actions from "store/actions";
import AssignToPatientModal from "./patientModal";
import AssignToProjectModal from "./projectModal";
import CreateEngagementModal from "./engagementModal";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function Engagement(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  let querystr = qs.parse(props.location.search);
  let page = querystr.page ? JSON.parse(querystr.page) : 0;
  const [variables, setVariables] = useState({ ...querystr });
  const [data, setData] = useState(null);
  const [engagementId, setEngagementId] = useState(null);
  const [engagementModalType, setEngagmentModalType] = useState("add");
  const [createEngageModalState, setCreateEngageModalState] = useState(false);
  const [patientModalState, setPatientModalState] = useState(null);
  const [patientModalType, setPatientModalType] = useState("patient");
  const [projectModalState, setProjectModalState] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [engagement, setEngagement] = useState(null);
  const [dialogue, setDialogue] = useState(false);
  const { t } = useTranslation();

  const stringTruncate = (str, length) => {
    if (str) {
      const dots = str.length > length ? "..." : "";
      return `${str.substring(0, length)}${dots}`;
    } else {
      return null;
    }
  };

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  const engagementReducer = useSelector(({ engagement }) => engagement);

  useEffect(() => {
    const q_str = qs.stringify(variables);
    History.push({ search: q_str });
    let employee_id = Auth.id;
    dispatch(Actions.setEngagementLoading(true));
    dispatch(Actions.getAllEngagements(variables, employee_id, Auth.vendor_id));
  }, [variables]);

  useEffect(() => {
    setEngagement(engagementReducer);
    if (engagementReducer.posted) {
      setPatientModalState(false);
      setProjectModalState(false);
      setCreateEngageModalState(false);
      setSelectedProjects([]);
      setPatientModalType("patient");
    }
  }, [engagementReducer]);

  let getDateTime = (date) => {
    if (date) {
      var zone_name = momentt.tz.guess();
      let zone_adjusted_time = moment(date)
        .tz(zone_name)
        .format("h:mm a,  MMM DD, YYYY ");
      return zone_adjusted_time;
    }
  };

  const getEngagementList =
    engagement &&
    engagement.engagementList &&
    engagement.engagementList.length > 0 ? (
      engagement.engagementList.map((item) => {
        return (
          <tr>
            <td>
              <Link to={`/engagement/${item.id}/0`}>
                <p className="ml-4 mb-0 prominent cursor-pointer">
                  {stringTruncate(item.title, 50)}
                </p>
              </Link>
            </td>

            <td>{item?.totalQuestionnaires}</td>
            <td className="">{item?.totalActivities}</td>
            <td>{item?.duration}</td>
            <td>
              <div className="d-flex ">
                <div className="mr-4">{item?.assignedProjects}</div>
                <AssignmentIcon
                  onClick={() => {
                    setEngagementId(item.id);
                    setProjectModalState(true);
                  }}
                  className={`cursor-pointer   centrify-grey-color`}
                />
              </div>
            </td>
            <td>
              <div className="d-flex ">
                <div className="mr-4">{item?.assignedTotalEngagements}</div>
                <AssignmentIcon
                  onClick={() => {
                    setEngagementId(item.id);
                    setPatientModalState(true);
                  }}
                  className={`cursor-pointer   centrify-grey-color`}
                />
              </div>
            </td>
            <td>{getDateTime(item?.createdAt)}</td>
            <td>
              <div className="d-flex justify-content-between  ">
                <Link to={`/engagement/${item.id}/0`}>
                  <VisibilityIcon className="centrify-grey-color cursor-pointer mr-2" />
                </Link>

                <EditIcon
                  onClick={() => {
                    setEngagmentModalType("update");
                    setData(item);
                    setCreateEngageModalState(true);
                  }}
                  className="centrify-grey-color cursor-pointer mr-2"
                />
                <DeleteIcon
                  onClick={() => {
                    if (
                      item.assignedProjects == 0 &&
                      item.assignedTotalEngagements == 0
                    ) {
                      setData(item);
                      setDialogue(!dialogue);
                    }
                  }}
                  className={`cursor-pointer  mr-2 ${
                    item.assignedProjects == 0 &&
                    item.assignedTotalEngagements == 0
                      ? "centrify-grey-color  "
                      : "centrify-light-grey-color"
                  }`}
                />
              </div>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colspan={7} className="text-center font-weight-bold">
          {t("nothing_to_display")}
        </td>
      </tr>
    );
  const handlePageChange = (e, page) => {
    setVariables({
      ...variables,
      page: page - 1,
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let search = document.getElementById("engagement-search").value;
    setVariables({
      ...variables,
      title: search,
      page: 1,
    });
  };
  const handleChipDelete = () => {
    document.getElementById("engagement-search").value = "";
    setVariables({
      ...variables,
      title: "",
      page: 1,
    });
  };

  const handleDelete = () => {
    dispatch(Actions.setEngagementLoading(true));
    dispatch(Actions.deleteEngagement(data.id, Auth.id, Auth.vendor_id));
    setDialogue(!dialogue);
    setData(null);
  };
  if (engagement && engagement.error) {
    return <ErrorMessageComponents message={t("cannot_get_engagements")} />;
  }

  if (engagementReducer === null || !engagementReducer.engagementList) {
    return <CircularProgressComponent />;
  }

  return (
    <div>
      <div className="mt-4">
        {variables.title && (
          <Chip
            label={variables.title}
            onDelete={() => handleChipDelete()}
            className={classes.chip}
          />
        )}
        <div className="d-flex justify-content-between align-items-end table-top-bar">
          <div className="d-flex align-items-center filters">
            <Form className="mt-2 mt-sm-0" onSubmit={(e) => handleSearch(e)}>
              <FormGroup className="d-inline-block gift-search-input">
                <Input
                  type="text"
                  name="value"
                  id="engagement-search"
                  maxLength={10}
                  placeholder={t("search")}
                  style={{ paddingRight: "40px" }}
                />
                <button type="submit" className="gift-search-icon">
                  <i className="zmdi zmdi-search centrify-blue-color"></i>
                </button>
              </FormGroup>
            </Form>
          </div>
          <Button
            color="none"
            className="primary-background-button-table font-weight-bold"
            onClick={() => {
              setEngagmentModalType("add");
              setData(null);
              setCreateEngageModalState(true);
            }}
          >
            {t("create_engagement")}
          </Button>
        </div>

        <div className="mt-4" style={{ position: "relative" }}>
          {engagementReducer.loading && <TableLoader />}
          <Table responsive className="main-table">
            <thead className="main-table-header ">
              <tr>
                <th>
                  <span className="ml-4 mb-0 d-flex">{t("title")}</span>
                </th>
                <th>{t("total_questionnaire")}</th>
                <th>{t("total_activites")}</th>
                <th>{t("no_of_days")}</th>
                <th>{t("assigned_to_projects")}</th>
                <th>{t("assigned_to_patients")}</th>
                <th>{t("created_date")}</th>
                <th>{t("actions")}</th>
              </tr>
            </thead>
            <tbody className="main-table-body">{getEngagementList}</tbody>
          </Table>
        </div>

        {engagementReducer.count > 0 ? (
          <div className="mt-4 d-flex justify-content-center">
            <Pagination
              count={Math.ceil(engagementReducer.count / 10)}
              page={page + 1}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </div>
        ) : null}
      </div>
      <AssignToPatientModal
        {...props}
        engagementId={engagementId}
        modal={patientModalState}
        setPatientModalType={setPatientModalType}
        patientModalType={patientModalType}
        patients={null}
        toggle={() => {
          setPatientModalType("patient");
          setPatientModalState(!patientModalState);
        }}
      />
      <AssignToProjectModal
        {...props}
        engagementId={engagementId}
        modal={projectModalState}
        projects={null}
        toggle={() => {
          setProjectModalState(!projectModalState);
        }}
        setSelectedProjects={setSelectedProjects}
        selectedProjects={selectedProjects}
      />

      <ConfrimDialogue
        open={dialogue}
        handleClose={() => setDialogue(false)}
        action={() => handleDelete()}
        msg={t("are_you_sure_you_want_to_delete") + "?"}
      />
      <CreateEngagementModal
        {...props}
        modal={createEngageModalState}
        engagementModalType={engagementModalType}
        engagement={data}
        toggle={() => {
          setCreateEngageModalState(!createEngageModalState);
        }}
      />
    </div>
  );
}
