import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import HeaderComponent from "./headerComponent.js";
import HeaderCellComponent from "./headerCellComponent.js";
import EventComponent from "./eventComponent";
import Toolbar from "./toolbar";
import moment from "moment";
import InfoModal from "./infoModal";
// Styles
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./calendarStyles.css";

const localizer = momentLocalizer(moment);

function CalendarBig(props) {
  let [events, setEvents] = useState([]);
  let [dateInterval, setDateInterval] = useState();
  let [infoModal, setInfoModal] = useState(false);
  let [infoModalData, setInfoModalData] = useState(null);

  useEffect(() => {
    if (props.chartData) {
      setDateInterval(props.eventsInterval);
      setEvents(props.chartData);
    }
  }, [props.chartData]);

  const formats = {
    eventTimeRangeFormat: () => {
      return "";
    },
  };

  return (
    <div className="basic-calendar">
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 700 }}
        defaultView="week"
        views={["week"]}
        defaultDate={new Date(2023, 0)}
        formats={formats}
        components={{
          toolbar: (props) => (
            <Toolbar {...props} dateIntervals={dateInterval} />
          ),
          week: {
            header: (props) => (
              <HeaderComponent
                key={props?.title}
                data={props}
                dateInterval={dateInterval}
              />
            ),
            dateHeader: (props) => <HeaderCellComponent {...props} />,
            event: (props) => (
              <EventComponent
                infoModal={infoModal}
                setInfoModal={setInfoModal}
                setInfoModalData={setInfoModalData}
                {...props}
              />
            ),
          },
        }}
      />
      <InfoModal
        modal={infoModal}
        toggle={() => {
          setInfoModal(!infoModal);
        }}
        infoModalData={infoModalData}
        {...props}
      />
    </div>
  );
}

export default CalendarBig;

//   console.log("Starting Date --->", startingDate);
//   console.log("Ending Date --->", endingDate);
//   let formats = {
//     dateFormat: "dd",

//     dayFormat: (date, culture, localizer) =>
//       localizer.format(date, "DDD", culture),

//     dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
//       localizer.format(start, { date: "short" }, culture) +
//       " — " +
//       localizer.format(end, { date: "short" }, culture),
//   };
//   const monthFormat = (date, culture, localizer) =>
//     localizer.format(date, "D MMMM YYYY", culture);
