import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FaImages } from "react-icons/fa";
import { MdOutlineVideoLibrary } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import TreeDialog from "../treeDialog";
import { Button, Input } from "reactstrap";
import { Avatar } from "@material-ui/core";

export default function Questionaires(props) {
  const { questionnaire } = props;
  let [treeDialog, setTreeDialog] = useState(false);
  const { t } = useTranslation();

  let findQuestionForAnswer = (linkedTo) => {
    let questionIndex = null;
    for (var i = 0; i < questionnaire.questions.length; i++) {
      if (questionnaire.questions[i].UUID == linkedTo) {
        questionIndex = i + 1;
        break;
      }
    }
    return questionIndex;
  };

  const stringTruncate = (str, length) => {
    const dots = str.length > length ? "..." : "";
    return `${str.substring(0, length)}${dots}`;
  };
  const getRecommendElement = (option) => {
    const recommendationRef = option.article_id
      ? option.article[0]
      : option.product[0];
    if (recommendationRef) {
      return (
        <div className="col-sm-3 col-md-2">
          <div className="d-flex align-items-center justify-content-between">
            <Avatar
              src={recommendationRef.image ? recommendationRef?.image[0] : null}
              style={{ height: "24px", width: "24px" }}
              className="mr-1"
            />
            <p className="m-auto cursor-pointer">
              {stringTruncate(recommendationRef.name, 10)}
            </p>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <div className="mt-4">
        {/* <h1 className="form-heading font-weight-bold d-flex">{t("questionnaire_details")}</h1> */}
        <div className="rct-block mg-top-40">
          <div className="d-flex justify-content-between align-items-center border-bottom-centrify p-4">
            <div className="d-flex flex-column w-100">
              <div className="d-flex justify-content-between">
                <h2 className="font-weight-bold mb-3">
                  {questionnaire?.headerVals?.title}
                </h2>
                <Button
                  type="button"
                  color="none"
                  className="primary-background-button-table font-weight-bold mb-5 mr-4 p-2 px-4"
                  onClick={() => {
                    setTreeDialog(true);
                  }}
                >
                  <AiFillEye style={{ fontSize: "18px" }} />
                </Button>
              </div>
              <p className="mb-2">{questionnaire?.headerVals?.description}</p>
              <p className="mb-2">{questionnaire?.headerVals?.greetingMsg}</p>
            </div>
          </div>
        </div>
        {questionnaire && questionnaire.questions.length > 0 && (
          <>
            {questionnaire.questions.map((ques, index) => (
              <div className="rct-block mt-5">
                <div className="d-flex flex-column p-4">
                  <div className="d-flex justify-content-between">
                    <h3 className="main-heading font-weight-bold d-flex">
                      Q{index + 1}:{"  "}
                      {ques?.title}
                    </h3>
                  </div>
                  {ques.questionType === "MCQ" ? (
                    <div className="d-flex flex-column mt-4 w-100">
                      {ques.options.map((opt) => (
                        <div className="my-2 d-flex flex-row w-100 justify-content-start align-items-center  ">
                          <div className="col-sm-12 col-md-3  d-flex flex-row justify-content-start">
                            <input
                              type="checkbox"
                              name="type"
                              style={{ transform: "scale(1.5)" }}
                              id={opt.id}
                              value={opt.id}
                              disabled
                            />
                            <label
                              for="item"
                              style={{ marginTop: "-3px" }}
                              className="ml-4 mb-2 font-14 lead text-truncate w-100"
                            >
                              {opt.option}
                            </label>
                          </div>
                          <div className="col-md-2">
                            {opt.important ? (
                              <img
                                src={require("assets/icons/filled-star.png")}
                                alt=""
                                className="ques-option-icon cursor-pointer pl-5 mb-2 mr-4"
                              />
                            ) : (
                              <img
                                src={require("assets/icons/Unfilled-star.png")}
                                alt=""
                                className="ques-option-icon cursor-pointer pl-5 mb-2 mr-4"
                              />
                            )}
                          </div>

                          <div className="col-sm-12 col-md-3">
                            <input
                              className="formAnswerInput p-2 "
                              placeholder={t("answer")}
                              defaultValue={opt.answer || t("no_answer")}
                              value={opt.answer || t("no_answer")}
                              disabled
                            ></input>
                          </div>
                          <div className="col-sm-12 col-md-2">
                            {findQuestionForAnswer(opt.linkedTo) != null ? (
                              <h4>
                                {t("linked_to_question")}:
                                {findQuestionForAnswer(opt.linkedTo)}
                              </h4>
                            ) : (
                              <h4>{t("linked_to_no_question")}</h4>
                            )}
                          </div>
                          {getRecommendElement(opt)}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="d-flex flex-column mt-4 w-100">
                      <div className="my-2 d-flex flex-row w-100 justify-content-start align-items-center  ">
                        <div className="col-md-5 d-flex align-items-start mb-4">
                          <div
                            className={`d-inline mr-4 p-4 text-center ${
                              ques && ques.questionType == "VIDEO"
                                ? "inputImageWrapperSelected"
                                : "inputImageWrapper"
                            } `}
                          >
                            <MdOutlineVideoLibrary className="d-block mb-2 mx-auto  inputImage" />
                            <span>{t("get_video")}</span>
                          </div>
                          <div
                            className={`d-inline mr-4 p-4 text-center ${
                              ques && ques.questionType == "IMAGE"
                                ? "inputImageWrapperSelected"
                                : "inputImageWrapper"
                            } `}
                          >
                            <FaImages className="d-block mb-2 mx-auto inputImage" />
                            <span>{t("get_image")}</span>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-4">
                          <input
                            className="formAnswerInput p-2 "
                            placeholder={t("answer")}
                            value={ques.answer ? ques.answer : t("no_answer")}
                            disabled
                          ></input>
                        </div>
                        <div className="col-sm-12 col-md-3">
                          {findQuestionForAnswer(ques.linkedToQuestion) !=
                          null ? (
                            <h4>
                              {t("linked_to_question")}:
                              {findQuestionForAnswer(ques.linkedToQuestion)}
                            </h4>
                          ) : (
                            <h4>{t("linked_to_no_question")}</h4>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <TreeDialog
                  dialogState={treeDialog}
                  questions={
                    questionnaire.questions ? questionnaire.questions : null
                  }
                  toggle={() => {
                    setTreeDialog(!treeDialog);
                  }}
                />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}
