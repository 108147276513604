import { CircularProgress, IconButton } from "@material-ui/core";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Label, Input } from "reactstrap";
import FileDrop from "react-dropzone";
import AddIcon from "@material-ui/icons/Add";
import PublishIcon from "@material-ui/icons/Publish";
import { v4 as uuidv4 } from "uuid";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import * as Actions from "store/actions";
import useRecorder from "lib/useRecorder";
import StopIcon from "@material-ui/icons/Stop";
import DeleteIcon from "@material-ui/icons/Delete";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import axios from "axios";
import Domain from "lib/Config";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import { NotificationManager } from "react-notifications";

const s3Cdn = axios.create({});
const weekDays = [
  { title: "Monday", value: 1 },
  { title: "Tuesday", value: 2 },
  { title: "Wednesday", value: 3 },
  { title: "Thursday", value: 4 },
  { title: "Friday", value: 5 },
  { title: "Saturday", value: 6 },
  { title: "Sunday", value: 0 },
];

const EditActivity = (props) => {
  const [imageLoading, setImageLoading] = useState(false);
  const [recordLoading, setRecordLoading] = useState(false);
  const [recordingFile, setRecordingFile] = useState(null);
  let [activity, setActivity] = useState(null);
  let [pageLoading, setPageLoading] = useState(true);
  // State containing all form values
  let [formValues, setFormValues] = useState({
    type: "medicine",
    selectedDate: null,
    dosage: [],
    days: "everyday",
    daysArr: [],
    medicine: null,
    images: [],
    audioSrc: null,
    when: null,
    places: null,
  });
  const audioRef = useRef();

  let [audioURL, isRecording, startRecording, stopRecording, deleteRecording] =
    useRecorder();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });

  const engagementReducer = useSelector(({ engagement }) => engagement);
  const medicineReducer = useSelector(({ medicine }) => medicine);

  useEffect(() => {
    dispatch(Actions.getEngagementDetail(props.match.params.eId));
    dispatch(Actions.getAllMedicines({}, Auth.vendor_id));
    dispatch(Actions.getEngagementActivity(props.match.params.aId));
    // Clearning it up so when we get back to this component, the previous data from redux donot set loading to false
    return () => {
      dispatch(Actions.setTabContentNull());
    };
  }, []);

  useEffect(() => {
    if (engagementReducer.tabContentDetail && engagementReducer.engagement) {
      let dateArray = engagementReducer?.tabContentDetail?.time.split(":");
      let daysArr = JSON.parse(engagementReducer?.tabContentDetail?.days);
      let days = daysArr && daysArr.length == 7 ? "everyday" : "fixed-days";
      let images = [];
      let audioSrc = null;
      let dosage = [];
      let selectedDate = new Date();
      let medicine = null;
      let type = "medicine";
      let when = 1;
      let places = null;

      let files = JSON.parse(engagementReducer.tabContentDetail.files);
      if (files) {
        files.map((file) => {
          if (file.type != "audio") {
            images.push(file);
          }
          if (file.type == "audio") {
            audioSrc = file.file;
          }
        });
      }
      let schedule = JSON.parse(engagementReducer.tabContentDetail.schedule);
      if (schedule) {
        schedule.map((obj, idx) => {
          dosage.push({ ...obj, id: idx });
        });
      }
      let date = new Date();
      const clientTimezoneOffset = new Date().getTimezoneOffset() / 60;
      date.setHours(dateArray[0], dateArray[1], dateArray[2]);
      date.setHours(date.getHours() - clientTimezoneOffset);
      selectedDate = date;
      medicine = engagementReducer.tabContentDetail?.medicine_id;
      type = engagementReducer.tabContentDetail?.type;
      when = engagementReducer.tabContentDetail?.when;
      places = engagementReducer.tabContentDetail?.places;
      let victor = engagementReducer.tabContentDetail?.victor;

      setFormValues({
        ...formValues,
        daysArr,
        days,
        images,
        audioSrc,
        dosage,
        selectedDate,
        medicine,
        type,
        when,
        places,
        victor,
      });
      setPageLoading(false);
      setActivity(engagementReducer.tabContentDetail);
    }
    if (engagementReducer.error || medicineReducer.error) {
      setActivity(null);
      setPageLoading(false);
    }
  }, [engagementReducer]);

  useEffect(() => {
    if (audioURL !== "") {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", audioURL, true);
      xhr.responseType = "blob";
      xhr.onload = function (e) {
        if (this.status == 200) {
          var myBlob = this.response;
          var file = new File(
            [myBlob],
            `${Date.now()}-audio-file-activity.webm`,
            {
              lastModified: Date.now(),
              type: myBlob.type,
            }
          );
          setRecordingFile(file);
          // myBlob is now the blob that the object URL pointed to.
        }
      };
      xhr.send();
    }
  }, [audioURL]);

  const handleWhenChange = (value, dateProvided) => {
    let num = value;
    let _num = 24 / num;
    var clientTimezoneOffset = new Date().getTimezoneOffset() / 60;
    let tempDosage = [];
    for (let i = 0; i < num; i++) {
      const time = moment(dateProvided || formValues.selectedDate)
        .add(_num * i, "h")
        .format("HH:mm");
      let timeSplited = time.split(":");
      let currentDate = new Date();
      currentDate.setHours(timeSplited[0], timeSplited[1], 0);
      currentDate.setHours(currentDate.getHours() + clientTimezoneOffset);
      let dateFormatted = moment(currentDate).format("HH:mm");
      var d = new Date(dateProvided || formValues.selectedDate);
      d.setHours(d.getHours() + clientTimezoneOffset);

      const zoneTime = moment(d).format("YYYY-MM-DD HH:mm");

      if (i < formValues.dosage.length) {
        tempDosage[i] = { ...formValues.dosage[i], time: dateFormatted };
      } else {
        tempDosage[i] = {
          id: i,
          time: dateFormatted,
          dose: null,
        };
      }
    }
    setFormValues((formValues) => {
      return {
        ...formValues,
        dosage: tempDosage,
        when: value,
        selectedDate: dateProvided || formValues.selectedDate,
      };
    });
    return tempDosage;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let obj = {};
    const formData = new FormData(e.target);
    for (let [key, value] of formData.entries()) {
      obj[key] = value;
    }

    if (Number(engagementReducer.engagement.duration) < Number(obj.to)) {
      NotificationManager.error(
        "Ending day must be less than engagement duration"
      );
    } else if (
      Number(engagementReducer.engagement.duration) < Number(obj.from)
    ) {
      NotificationManager.error(
        "Starting day must be less than engagement duration"
      );
    } else if (Number(obj.from) > Number(obj.to)) {
      NotificationManager.error("Starting day must be less than ending day");
    } else {
      obj.createdBy = "employee";
      obj.employee_id = Auth.id;

      const clientTimezoneOffset = new Date().getTimezoneOffset() / 60;
      let d = new Date(formValues.selectedDate);
      d.setHours(d.getHours() + clientTimezoneOffset);
      obj.time = moment(d).format("HH:mm");

      obj.status = "pending";
      obj.days =
        formValues.daysArr.length > 0
          ? formValues.daysArr
          : [0, 1, 2, 3, 4, 5, 6];
      obj.schedule = [];
      obj.schedule = formValues.dosage;
      if (formValues.type == "test") {
        obj.days = [0, 1, 2, 3, 4, 5, 6];
      }

      obj.files = [];
      if (formValues.audioSrc) {
        obj.files.push({
          file: formValues.audioSrc,
          type: "audio",
        });
      }
      if (formValues.images.length > 0) {
        formValues.images.map((item) => {
          obj.files.push({
            file: item.file,
            type: item.type,
            id: item.id,
          });
        });
      }

      if (formValues.type === "meeting" || formValues.type === "other") {
        obj.takeIt = null;
        obj.medicine_id = null;
      }
      if (formValues.type != "test") {
        obj.places = null;
      }
      if (!formValues.info) {
        obj.info = "";
      }
      delete obj.daterange_from;
      delete obj.daterange_to;
      delete obj.day;
      delete obj.month;
      delete obj.year;
      obj.engagement_id = props.match.params.eId;
      obj.victor = e.target.victor.checked;
      // Getting TimeZone
      var offset = new Date().getTimezoneOffset();
      var o = Math.abs(offset);
      let timeZone =
        (offset < 0 ? "+" : "-") +
        ("00" + Math.floor(o / 60)).slice(-2) +
        ":" +
        ("00" + (o % 60)).slice(-2);
      obj.tz = timeZone;
      console.log("FINAL OBJ -->", obj);
      dispatch(Actions.setEngagementLoading(true));
      dispatch(
        Actions.updateEngagementActivity(
          props.match.params.eId,
          props.match.params.aId,
          obj
        )
      );
    }
  };

  const handleDateChange = (date) => {
    handleWhenChange(formValues.when, date);
  };

  // useEffect(() => {
  //   if (formValues.selectedDate != null && formValues.when != null)
  //     handleWhenChange(formValues.when);
  // }, [formValues.selectedDate]);

  const onDrop = useCallback(
    (img) => {
      setImageLoading(true);
      handleFileUpload(img[0]);
    },
    [formValues.images]
  );

  const removeImg = (id) => {
    let images = formValues.images.filter((img) => img.id !== id);
    setFormValues((formValues) => {
      return { ...formValues, images };
    });
  };

  const handleFileUpload = (data) => {
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      },
      header: {
        "Content-Type": data.type,
      },
    };
    getSignedURL(data.name, data.type).then((rsp) => {
      const request = s3Cdn
        .put(rsp.data.result.postUrl, data, config)
        .then((res) => {
          let type = data.type;
          let _type = type.split("/")[0];
          if (_type == "audio") {
            setFormValues((formValues) => {
              return {
                ...formValues,
                audioSrc: rsp.data.result.getUrl,
              };
            });
            setRecordLoading(false);
          } else {
            let image = {
              id: uuidv4(),
              file: rsp.data.result.getUrl,
              type: _type,
            };
            setFormValues((formValues) => {
              return {
                ...formValues,
                images: [...formValues.images, image],
              };
            });

            setImageLoading(false);
          }
        })
        .catch((err) => {
          NotificationManager.error("Error uploading media");
          console.log(err.response);
          setImageLoading(false);
        });
    });
  };

  const getSignedURL = (key, type) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Domain}/api/vendor/s3getsignedurl?key=${key}&type=${type}`)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          console.log(err.response);
          reject(err);
        });
    });
  };

  const uploadRecording = () => {
    setRecordLoading(true);
    handleFileUpload(recordingFile);
  };

  const handleCheck = (e) => {
    if (e.target.checked && e.target.value) {
      let daysArr = [...formValues.daysArr, parseInt(e.target.value)];
      setFormValues((formValues) => {
        return {
          ...formValues,
          daysArr: daysArr,
        };
      });
    } else {
      let daysFiltered = formValues.daysArr.filter(
        (item) => item !== parseInt(e.target.value)
      );
      setFormValues((formValues) => {
        return {
          ...formValues,
          daysArr: daysFiltered,
        };
      });
    }
  };
  let tickedCheck = (item) => {
    return formValues.daysArr.some((day) => day == item.value);
  };

  if (engagementReducer.error || medicineReducer.error) {
    return <ErrorMessageComponents message={t("something_went_wrong")} />;
  }

  if (
    engagementReducer == null ||
    engagementReducer.loading ||
    pageLoading ||
    activity == null
  ) {
    return <CircularProgressComponent />;
  }

  return (
    <div className="container">
      <div>
        <div className="my-2 form-styles">
          <h2 className="form-heading d-flex">{t("update_activity")}</h2>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="row">
              <div className="col-md-6 mt-4">
                <Label className="d-flex">{t("title")}</Label>
                <Input
                  defaultValue={activity?.title}
                  type="text"
                  name="title"
                  required
                />
              </div>
              <div className="col-md-6 mt-4">
                <Label className="d-flex">{t("type")}</Label>
                <Input
                  defaultValue={activity?.type}
                  value={formValues.type}
                  onChange={(e) => {
                    if (e.target.value) {
                      let value = e.target.value;
                      setFormValues((formValues) => {
                        return {
                          ...formValues,
                          type: value,
                        };
                      });
                    }
                  }}
                  type="select"
                  name="type"
                  required
                >
                  <option value="medicine">Medication</option>
                  <option value="supplements">Supplements</option>
                  {/* {props.match.params.status === 'true' && (
                    <option value='meeting'>Doctor Meeting</option>
                  )} */}
                  <option value="meeting">Doctor Meeting</option>
                  <option value="face-to-face">
                    Doctor Meeting(Face-To-Face)
                  </option>
                  <option value="test">Test</option>
                  <option value="other">Other</option>
                </Input>
              </div>
            </div>

            {(formValues.type == "medicine" ||
              formValues.type == "supplements" ||
              formValues.type == "test") && (
              <div className="row">
                {formValues.type === "meeting" ||
                formValues.type === "other" ||
                formValues.type === "face-to-face" ||
                formValues.type == "test" ? null : (
                  <div className="col-md-6 mt-4">
                    <Label className="d-flex">{t("medicine")}</Label>
                    <Input
                      type="select"
                      name="medicine_id"
                      required
                      disabled={medicineReducer?.loading}
                      value={formValues.medicine}
                      onChange={(e) => {
                        setFormValues((formValues) => {
                          return {
                            ...formValues,
                            medicine: e.target.value,
                          };
                        });
                      }}
                    >
                      {medicineReducer &&
                        medicineReducer.medicineList &&
                        medicineReducer.medicineList.map((item) => {
                          return (
                            <option id={item.id} key={item.id} value={item.id}>
                              {`${item.name} - ${item.unit}`}
                            </option>
                          );
                        })}
                    </Input>
                  </div>
                )}
                {formValues.type == "test" ? null : (
                  <div className="mt-4 col-md-6">
                    <Label className="d-flex">{t("days")}</Label>
                    <Input
                      type="select"
                      name="when"
                      required
                      value={formValues.days}
                      onChange={(e) => {
                        if (e.target.value) {
                          let daysArr = formValues.daysArr;
                          if (e.target.value == "fixed-days") {
                            daysArr = [];
                          }
                          let days = e.target.value;
                          setFormValues((formValues) => {
                            return {
                              ...formValues,
                              days,
                              daysArr,
                            };
                          });
                        }
                      }}
                    >
                      <option value="everyday">Everyday</option>
                      <option value="fixed-days">Set Days</option>
                    </Input>
                  </div>
                )}
                {formValues.type != "test" && formValues.days == "fixed-days" && (
                  <div className="col-md-12 mt-4 ">
                    <Label className="d-flex">{t("select_days")}</Label>
                    <div className="d-flex justify-content-between">
                      {weekDays &&
                        weekDays.map((item) => {
                          return (
                            <div className="d-flex align-items-center ml-4">
                              <Input
                                checked={tickedCheck(item)}
                                onChange={(e) => handleCheck(e)}
                                type="checkbox"
                                value={item.value}
                              />
                              <p
                                className="mb-0 mt-1"
                                style={{ fontSize: "0.8rem" }}
                              >
                                {item.title}
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
                {formValues.type === "meeting" ||
                formValues.type === "other" ||
                formValues.type === "face-to-face" ? null : (
                  <div className="mt-4 col-md-6">
                    <Label className="d-flex">{t("take_it")}</Label>
                    <Input
                      type="select"
                      name="takeIt"
                      required
                      defaultValue={activity?.takeIt}
                    >
                      <option value="before meal">Before Meal</option>
                      <option value="after meal">After Meal</option>
                      <option value="during meal">During Meal</option>
                      <option value="not important">Not Important</option>
                    </Input>
                  </div>
                )}
                <div className="col-md-6 mt-4">
                  <Label className="d-flex">{t("when")}</Label>
                  <Input
                    onChange={(e) => {
                      handleWhenChange(e.target.value);
                    }}
                    type="select"
                    name="when"
                    defaultValue={
                      formValues.dosage && formValues.dosage.length
                        ? formValues.dosage.length
                        : 1
                    }
                  >
                    <option value={1}>Once a day</option>
                    <option value={2}>Twice a day</option>
                    <option value={3}>Thrice a day</option>
                    <option value={4}>4 times a day</option>
                    <option value={5}>5 times a day</option>
                    <option value={6}>6 times a day</option>
                    <option value={7}>7 times a day</option>
                    <option value={8}>8 times a day</option>
                    <option value={9}>9 times a day</option>
                    <option value={10}>10 times a day</option>
                    <option value={11}>11 times a day</option>
                    <option value={12}>12 times a day</option>
                  </Input>
                </div>
              </div>
            )}
            <div className="row">
              {formValues.type !== "meeting" &&
              formValues.type !== "other" &&
              formValues.type !== "face-to-face" &&
              formValues.type !== "test" ? (
                <>
                  {formValues.dosage &&
                    formValues.dosage.length > 0 &&
                    formValues.dosage.map((x, idx) => (
                      <div className="col-md-6 mt-4">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex justify-content-start align-items-center">
                            <p className="mr-2 mb-0 font-weight-bold">
                              {idx + 1}
                            </p>
                            <Input
                              required
                              placeholder="Dose"
                              value={x.dose}
                              onChange={(e) => {
                                formValues.dosage[idx] = {
                                  ...formValues.dosage[idx],
                                  dose: e.target.value,
                                };
                                let dosage = [...formValues.dosage];
                                setFormValues((formValues) => {
                                  return {
                                    ...formValues,
                                    dosage,
                                  };
                                });
                              }}
                              className="mr-2"
                              type="number"
                              name={`day-${idx}`}
                              min="1"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              ) : null}
            </div>

            <div className="row">
              <div className="col-md-6 mt-4 d-flex flex-column">
                <Label className="d-flex">{t("starting_day")}</Label>
                <Input
                  name="from"
                  type="number"
                  max="365"
                  min="1"
                  defaultValue={activity?.from}
                  required
                ></Input>
              </div>
              <div className="col-md-6 mt-4 d-flex flex-column">
                <Label className="d-flex">{t("ending_day")}</Label>
                <Input
                  name="to"
                  type="number"
                  max="365"
                  min="1"
                  defaultValue={activity?.to}
                  required
                ></Input>
              </div>
            </div>
            <div className="row">
              <div className="mt-4 col-md-6">
                <Label className="d-flex">{t("time")}</Label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    className="w-100"
                    margin="normal"
                    id="time-picker"
                    value={formValues.selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              {formValues.type !== "medicine" &&
              formValues.type !== "supplements" &&
              formValues.type != "test" ? (
                <>
                  <div className="col-md-6 mt-4">
                    <Label className="d-flex">{t("when")}</Label>
                    <Input
                      onChange={(e) => {
                        handleWhenChange(e.target.value);
                      }}
                      type="select"
                      name="when"
                      defaultValue={
                        formValues.dosage && formValues.dosage.length
                          ? formValues.dosage.length
                          : 1
                      }
                    >
                      <option value={1}>Once a day</option>
                      <option value={2}>Twice a day</option>
                      <option value={3}>Thrice a day</option>
                      <option value={4}>4 times a day</option>
                      <option value={5}>5 times a day</option>
                      <option value={6}>6 times a day</option>
                      <option value={7}>7 times a day</option>
                      <option value={8}>8 times a day</option>
                      <option value={9}>9 times a day</option>
                      <option value={10}>10 times a day</option>
                      <option value={11}>11 times a day</option>
                      <option value={12}>12 times a day</option>
                    </Input>
                  </div>
                </>
              ) : null}
              {formValues.type == "test" ? (
                <div className="col-md-6 mt-4 d-flex flex-column">
                  <Label className="d-flex">{t("place")}</Label>
                  <Input
                    name="places"
                    defaultValue={formValues?.places}
                    required
                    className="mt-3"
                  ></Input>
                </div>
              ) : null}
            </div>
            <div className="row">
              <div className="col-md-12 mt-4">
                <Label className="d-flex">{t("information")}</Label>
                <Input
                  type="textarea"
                  name="info"
                  defaultValue={activity?.info}
                />
              </div>
            </div>
            <div className="mt-4">
              <audio
                style={{ width: "100%" }}
                ref={audioRef}
                src={audioURL || formValues.audioSrc}
                controls
              />
            </div>
            <div className="mt-2 d-flex justify-content-center">
              <IconButton
                disabled={isRecording}
                onClick={startRecording}
                className="mr-2"
              >
                <FiberManualRecordIcon className="text-danger" />
              </IconButton>
              <IconButton
                disabled={!isRecording}
                onClick={stopRecording}
                className="mr-2"
              >
                <StopIcon />
              </IconButton>
              <IconButton
                disabled={isRecording}
                onClick={() => {
                  deleteRecording();

                  setFormValues((formValues) => {
                    return {
                      ...formValues,
                      audioSrc: null,
                    };
                  });
                  if (audioRef.current) {
                    audioRef.current.pause();
                    audioRef.current.load();
                  }
                }}
                className="mr-2"
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                disabled={isRecording || audioURL == ""}
                onClick={uploadRecording}
                className="mr-2"
              >
                <PublishIcon />
              </IconButton>
            </div>
            <div className="text-center" style={{ height: "20px" }}>
              {isRecording ? (
                <p>{t("recording")}...</p>
              ) : recordLoading ? (
                <p>{t("uploading")}...</p>
              ) : null}
            </div>
            <Label className="d-flex">{t("upload_files")}</Label>
            <div>
              <div className="row">
                {formValues.images &&
                  formValues.images.length > 0 &&
                  formValues.images.map((img) => (
                    <div
                      className="col-md-2 mb-2 mr-2 animate__animated animate__fadeIn"
                      key={img.id}
                    >
                      <div className="img-div d-flex flex-column">
                        {img.type == "image" ? (
                          <img src={img.file} width="100%" alt="activity " />
                        ) : img.type == "video" ? (
                          <video>
                            <source src={img.file} />
                          </video>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="del-img">
                        <IconButton
                          color="inherit"
                          mini="true"
                          className="p-0"
                          onClick={(e) => removeImg(img.id)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </div>
                    </div>
                  ))}
                <div className="col-md-2">
                  <FileDrop
                    disabled={imageLoading}
                    accept="image/png, image/gif, image/jpeg, image/bmp, video/mp4, video/mpeg, video/mpe, video/mpv, video/ogg, video/m4p, video/avi, video/wmv"
                    multiple
                    onDrop={onDrop}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()} className="img-div">
                        <IconButton
                          color="inherit"
                          mini="true"
                          aria-label="Menu"
                          className="p-0"
                        >
                          {imageLoading ? (
                            <CircularProgress
                              style={{ height: "10px", width: "10px" }}
                            />
                          ) : (
                            <AddIcon fontSize="small" />
                          )}
                        </IconButton>
                        <input {...getInputProps()} />
                      </div>
                    )}
                  </FileDrop>
                </div>
              </div>
            </div>
            <div className="my-4">
              <input
                type="checkbox"
                id="victor"
                name="victor"
                defaultChecked={formValues?.victor}
                style={{ transform: "scale(1.2)" }}
              />
              <label
                for="victor"
                className="ml-2 "
                style={{ fontSize: "1rem" }}
              >
                {t("allow_victor_call")}
              </label>
            </div>
            <div className="mt-4 d-flex justify-content-end">
              <Button
                disabled={engagementReducer.loading}
                type="submit"
                className="primary-background-button-table d-flex align-items-center"
                color="none"
              >
                <span className="font-weight-bold">{t("update_activity")}</span>
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default EditActivity;
