import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Actions from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import ConfrimDialogue from "components/Dialogue/ConfrimDialogue";

import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import { Form, FormGroup, Input, Table } from "reactstrap";
import TableLoader from "components/ErrorHandlers/TableLoader";
import Pagination from "@material-ui/lab/Pagination";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import momentt from "moment-timezone";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function Medicine(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [data, setData] = useState(null);
  const [dialogue, setDialogue] = useState(false);

  const [engagements, setEngagements] = useState([]);
  const [engagementCount, setEngagementCount] = useState(0);

  const projectReducer = useSelector(({ project }) => project);

  const [variables, setVariables] = useState({ page: 1 });
  const { t } = useTranslation();

  useEffect(() => {
    if (props.match.params.id) {
      dispatch(Actions.setProjectStateLoading(true));
      dispatch(Actions.getProjectEngagements(props.match.params.id, variables));
    }
  }, [props.match.params.tab, variables]);

  useEffect(() => {
    if (projectReducer) {
      if (
        projectReducer &&
        projectReducer.tabContentList &&
        projectReducer.tabContentType == "engagements"
      ) {
        setEngagements(projectReducer.tabContentList);
        setEngagementCount(projectReducer.tabContentListCount);
      }
      if (projectReducer.error) {
        setEngagements([]);
        setEngagementCount(0);
      }
    }
  }, [projectReducer]);

  let getLocalAdjustedDate = (date) => {
    if (date) {
      var zone_name = momentt.tz.guess();
      let zone_adjusted_time = moment(date)
        .tz(zone_name)
        .format("h:mm a,  MMM DD, YYYY");
      return zone_adjusted_time;
    }
  };

  const getEngagementList = () => {
    if (engagements && engagements.length > 0) {
      return engagements.map((item) => {
        return (
          <tr>
            <td>
              <div className="ml-4 d-flex align-items-center cursor-pointer">
                <p className="mb-0 prominent mr-4">
                  {item?.assignedEngagement?.engagement?.title}
                </p>
              </div>
            </td>
            <td>{item?.totalEngagementQuestionnaires}</td>
            <td>{item?.totalEngagementActivities}</td>
            <td>{item?.assignedEngagement?.engagement?.duration}</td>
            <td>
              {getLocalAdjustedDate(
                item?.assignedEngagement?.engagement?.createdAt
              )}
            </td>
            <td>
              {item.status == "cancelled"
                ? getLocalAdjustedDate(item?.cancelledDate)
                : null}
            </td>
            <td>{item?.status == "active" ? t("active") : t("cancelled")}</td>
            <td
              className={`cursor-pointer ${
                item?.status == "active" ? "centrify-blue-color" : null
              }  `}
              onClick={() => {
                if (item?.status == "active") {
                  setData(item);
                  setDialogue(true);
                }
              }}
            >
              {t("cancel")}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan={7} className="text-center font-weight-bold">
            {t("nothing_to_display")}
          </td>
        </tr>
      );
    }
  };

  const handlePageChange = (e, page) => {
    setVariables({
      ...variables,
      page: page,
    });
  };
  const handleCancel = () => {
    if (props.projectId && data) {
      dispatch(Actions.setProjectStateLoading(true));
      dispatch(
        Actions.cancelProjectEngagement(
          data.assignedEngagement_id,
          props.projectId
        )
      );
      setDialogue(!dialogue);
      setData(null);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let search = document.getElementById("medicine-search").value;
    setVariables({
      ...variables,
      _name: search,
      page: 1,
    });
  };
  const handleChipDelete = () => {
    document.getElementById("medicine-search").value = "";
    setVariables({
      ...variables,
      _name: "",
      page: 1,
    });
  };

  if (projectReducer.tabError) {
    return <ErrorMessageComponents message={t("cannot_get_medicine")} />;
  }

  if (projectReducer === null) {
    return <CircularProgressComponent />;
  }

  return (
    <div>
      <div className="mt-4">
        <div className="rct-block mg-top-40">
          <div className="d-flex justify-content-between align-items-center border-bottom-centrify p-4">
            <div className="d-flex flex-column w-100">
              <div className="mb-2">
                {variables._name && (
                  <Chip
                    label={variables._name}
                    onDelete={() => handleChipDelete()}
                    className={classes.chip}
                  />
                )}
              </div>
              <div className="d-flex justify-content-between align-items-end table-top-bar ">
                <div className="d-flex align-items-center filters">
                  <Form
                    className="mt-2 mt-sm-0"
                    onSubmit={(e) => handleSearch(e)}
                  >
                    <FormGroup className="d-inline-block gift-search-input">
                      <Input
                        type="text"
                        name="value"
                        id="medicine-search"
                        maxLength={10}
                        placeholder={t("search")}
                        style={{ paddingRight: "40px" }}
                      />
                      <button type="submit" className="gift-search-icon">
                        <i className="zmdi zmdi-search centrify-blue-color"></i>
                      </button>
                    </FormGroup>
                  </Form>
                </div>
              </div>

              <div className="mt-4" style={{ position: "relative" }}>
                {projectReducer.stateloading && <TableLoader />}
                <Table responsive className="main-table">
                  <thead className="main-table-header">
                    <tr>
                      <th>
                        <span className="ml-4 mb-0 d-flex">{t("title")}</span>
                      </th>
                      <th>{t("total_questionnaire")}</th>
                      <th>{t("total_activites")}</th>
                      <th>{t("no_of_days")}</th>
                      <th>{t("created_date")}</th>
                      <th>{t("cancelled_date")}</th>
                      <th>{t("status")}</th>
                      <th>{t("actions")}</th>
                    </tr>
                  </thead>
                  <tbody className="main-table-body">
                    {getEngagementList()}
                  </tbody>
                </Table>
              </div>

              {engagementCount > 0 ? (
                <div className="mt-4 d-flex justify-content-center">
                  <Pagination
                    count={Math.ceil(engagementCount / 10)}
                    page={variables.page + 1}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                  />
                </div>
              ) : null}

              <ConfrimDialogue
                open={dialogue}
                handleClose={() => setDialogue(false)}
                action={() => handleCancel()}
                msg={t("are_you_sure_you_want_to_cancel") + "?"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
