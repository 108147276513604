import React from "react";
import ReactDOM from "react-dom";
import { HorizontalBar } from "react-chartjs-2";

class BarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        labels: ["Augmentin", "Iron", "Aspirin", "B12", "Other"],
        datasets: [
          {
            label: "My First dataset",
            backgroundColor: "#FE870B",
            borderColor: "#FE870B",
            borderWidth: 1,
            //stack: 1,
            hoverBackgroundColor: "#FE870B",
            hoverBorderColor: "#FE870B",
            data: [65, 59, 80, 81, 56],
            radius: 1,
          },

          {
            label: "My second dataset",
            backgroundColor: "#9DA9CE",
            borderColor: "#9DA9CE",
            borderWidth: 1,
            //stack: 1,
            hoverBackgroundColor: "#9DA9CE",
            hoverBorderColor: "#9DA9CE",
            data: [45, 79, 50, 41, 16],
          },
        ],
      },
    };
  }

  componentDidMount = () => {
    if (this.props.data) {
      let _data = this.props.data;
      let labels = [];
      let totalCount = [];
      let totalDone = [];
      _data.map((item) => {
        labels.push(item.name);
        totalCount.push(item.stats.totalCount);
        totalDone.push(item.stats.totalDone);
      });
      this.setState({
        data: {
          labels: labels,
          datasets: [
            {
              label: "Planned",
              backgroundColor: "#FE870B",
              borderColor: "#FE870B",
              borderWidth: 1,
              barThickness: 9,
              //stack: 1,
              hoverBackgroundColor: "#FE870B",
              hoverBorderColor: "#FE870B",
              data: totalCount,
              radius: 1,
            },

            {
              label: " Actual",
              backgroundColor: "#9DA9CE",
              borderColor: "#9DA9CE",
              borderWidth: 1,
              barThickness: 9,
              //stack: 1,
              hoverBackgroundColor: "#9DA9CE",
              hoverBorderColor: "#9DA9CE",
              data: totalDone,
            },
          ],
        },
      });
    }
  };

  render() {
    const options = {
      responsive: true,
      legend: {
        display: false,
      },
      type: "horizontalBar",
      scales: {
        xAxes: [
          //   {
          //   gridLines: {
          //     // color: "rgba(0, 0, 0, 0)",
          //     display: false
          //   }
          // },
          {
            ticks: { min: 0 }
          }
        ],
        yAxes: [{
          gridLines: {
            display: false
          }
        },
        ]
      }
    };
    return (
      <HorizontalBar
        data={this.state.data}
        width={null}
        height={null}
        options={options}
      />
    );
  }
}

export default BarChart;
