import React, { useState, useEffect } from 'react'
import { Avatar } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import * as Actions from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'

import ErrorMessageComponents from 'components/ErrorHandlers/ErrorMessageComponents'
import CircularProgressComponent from 'components/ErrorHandlers/CircularProgressComponent'
import { Form, FormGroup, Input, Table } from 'reactstrap'
import TableLoader from 'components/ErrorHandlers/TableLoader'
import Pagination from '@material-ui/lab/Pagination'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'
import AddEmployeeModal from './employeeModal'

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5)
  }
}))

export default function Employees (props) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()

  const [employeeModalState, setEmployeeModalState] = useState(false)
  const [variables, setVariables] = useState({ page: 1 })
  const [employees, setEmployees] = useState([])
  const [employeeCount, setEmployeeCount] = useState(0)
  const [selectedEmployees, setSelectedEmployees] = useState([])

  const projectReducer = useSelector(({ project }) => project)

  useEffect(() => {
    if (props.match.params.id) {
      dispatch(Actions.setProjectStateLoading(true))
      dispatch(Actions.getProjectEmployees(props.match.params.id, variables))
    }
  }, [props.match.params.tab, variables])

  useEffect(() => {
    if (projectReducer.posted) {
      setEmployeeModalState(false)
    }
    if (
      projectReducer &&
      projectReducer.tabContentList &&
      projectReducer.tabContentType == 'employees'
    ) {
      setEmployees(projectReducer.tabContentList)
      setEmployeeCount(projectReducer.tabContentListCount)
    }
    if (projectReducer.error) {
      setEmployees([])
      setEmployeeCount(0)
    }
  }, [projectReducer])

  useEffect(() => {
    if (employeeModalState == false) {
      setSelectedEmployees([])
    }
  }, [employeeModalState])

  const getEmployeeList = () => {
    if (projectReducer.stateloading == false) {
      if (employees && employees.length > 0) {
        return employees.map(item => {
          return (
            <tr>
              <td>
                <div className='ml-4 d-flex align-items-center'>
                  <Avatar
                    className='mr-3 medicine-pic'
                    src={item?.projectemployee?.image}
                  />
                  <p className='mb-0 mr-2 prominent'>
                    {item?.projectemployee?.firstName +
                      ' ' +
                      item?.projectemployee?.lastName}
                  </p>
                </div>
              </td>
              <td>{item?.projectemployee?.country}</td>
              <td>{item?.projectemployee?.role}</td>
              <td>
                {item?.projectemployee?.status ? t('active') : t('cancelled')}
              </td>
            </tr>
          )
        })
      } else {
        return (
          <tr>
            <td colspan={8} className='text-center font-weight-bold'>
              {t('nothing_to_display')}
            </td>
          </tr>
        )
      }
    } else {
      return <tr></tr>
    }
  }

  const handlePageChange = (e, page) => {
    setVariables({
      ...variables,
      page: page
    })
  }

  const handleSearch = e => {
    e.preventDefault()
    let search = document.getElementById('employee-search').value
    setVariables({
      ...variables,
      _name: search,
      page: 1
    })
  }
  const handleChipDelete = () => {
    document.getElementById('employee-search').value = ''
    setVariables({
      ...variables,
      _name: '',
      page: 1
    })
  }
  if (projectReducer.tabError) {
    return <ErrorMessageComponents message={t('cannot_get_employees')} />
  }

  if (projectReducer === null || projectReducer.loading) {
    return <CircularProgressComponent />
  }
  return (
    <div>
      <div className='mt-4'>
        <div className='d-flex justify-content-end'>
          <div
            className='primary-background-button-table font-weight-bold'
            onClick={() => {
              setEmployeeModalState(true)
            }}
          >
            {t('assign_employee')}
          </div>
        </div>
        <div className='rct-block mg-top-40'>
          <div className='d-flex justify-content-between align-items-center border-bottom-centrify p-4'>
            <div className='d-flex flex-column w-100'>
              <div>
                <div>
                  <div className='mb-2'>
                    {variables._name && (
                      <Chip
                        label={variables._name}
                        onDelete={() => handleChipDelete()}
                        className={classes.chip}
                      />
                    )}
                  </div>
                  <div className='d-flex justify-content-between align-items-end table-top-bar '>
                    <div className='d-flex align-items-center filters'>
                      <Form
                        className='mt-2 mt-sm-0'
                        onSubmit={e => handleSearch(e)}
                      >
                        <FormGroup className='d-inline-block gift-search-input'>
                          <Input
                            type='text'
                            name='value'
                            id='employee-search'
                            maxLength={10}
                            placeholder={t('search')}
                            style={{ paddingRight: '40px' }}
                          />
                          <button type='submit' className='gift-search-icon'>
                            <i className='zmdi zmdi-search centrify-blue-color'></i>
                          </button>
                        </FormGroup>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>

              <div className='mt-4' style={{ position: 'relative' }}>
                {projectReducer.stateloading && <TableLoader />}
                <Table responsive className='main-table'>
                  <thead className='main-table-header'>
                    <tr>
                      <th>
                        <span className='ml-4 mb-0 d-flex'>{t('name')}</span>
                      </th>
                      <th>{t('country')}</th>
                      <th>{t('role')}</th>
                      <th>{t('status')}</th>
                    </tr>
                  </thead>
                  <tbody className='main-table-body'>{getEmployeeList()}</tbody>
                </Table>
              </div>

              {employeeCount > 0 ? (
                <div className='mt-4 d-flex justify-content-center'>
                  <Pagination
                    count={Math.ceil(employeeCount / 10)}
                    page={variables.page + 1}
                    onChange={handlePageChange}
                    variant='outlined'
                    shape='rounded'
                  />
                </div>
              ) : null}
              <AddEmployeeModal
                {...props}
                engagementId={props.match.params.id}
                modal={employeeModalState}
                toggle={() => {
                  setEmployeeModalState(!employeeModalState)
                }}
                setSelectedEmployees={setSelectedEmployees}
                selectedEmployees={selectedEmployees}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
