import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

export default function Stats (props) {
  const { t } = useTranslation()
  console.log('T ---_>', props.additionalData)
  let getLocalAdjustedDate = date => {
    if (date) {
      return moment
        .utc(`${date} `)
        .local()
        .format('hh:mm A, MMM DD YYYY')
    } else {
      return t('no_visit_yet')
    }
  }
  return (
    <div className='row'>
      <div className='col-md-6 mt-4'>
        <div className='dose-taken p-4 '>
          <h1 className='main-heading d-flex'>{t('doses_taken')}</h1>
          <div>
            <h1 className='dose-score'>{`${props.data.totalDone}/${props.data.totalCount}`}</h1>
            <h1 className='main-heading mt-4 d-flex'>
              {t('which_is')}
              {props.data.totalCount == 0
                ? ` ${0}%`
                : ` ${(
                    (props.data.totalDone / props.data.totalCount) *
                    100
                  ).toFixed(2)}%`}
            </h1>
          </div>
        </div>
      </div>
      <div className='col-md-6'>
        <div className='row'>
          <div className='col-md-6 mt-4 text-center'>
            <div className='last-visit px-2 py-2'>
              <p className=''>{t('last_visit')}</p>
              <h1 className='mb-0'>
                {getLocalAdjustedDate(props.additionalData?.lastVisit?.date)}
              </h1>
            </div>
          </div>
          <div className='col-md-6 mt-4 text-center'>
            <div className='total-visits px-2 py-2'>
              <p className=''>{t('total_visits')}</p>
              <h1 className='mb-0'>{props.additionalData?.totalVisits}</h1>
            </div>
          </div>
          <div className='col-md-6 mt-4'>
            {/* <div className="total-time p-2 pl-4">
              <p className="d-flex">{t("total_time")}</p>
              <h1 className="mb-0">{`${
                Math.floor(Math.random() * 200) + 20
              }h`}</h1>
            </div> */}
          </div>
          <div className='col-md-6 mt-4'>
            {/* <div className="reports-sent p-2 pl-4">
              <p>{t("reports_sent")}</p>
              <h1 className="mb-0">120</h1>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}
