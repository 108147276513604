import BreadCrumbComponent from "components/Breadcrumbs";
import React, { useEffect, useState } from "react";
import MedicineOrders from "./medicineOrders";
import StoreOrders from "./storeOrders";

import { useTranslation } from "react-i18next";

import History from "@history";

export default function Detail(props) {
  const [value, setValue] = useState(0);
  const [productId, setProductId] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (props.match.params.productId) {
      setProductId(props.match.params.productId);
    }
  }, [props.match.params.productId]);

  useEffect(() => {
    if (props.match.params.tab) {
      setValue(props.match.params.tab);
    }
  }, [props.match.params.tab]);

  let tabs = [t("medicine"), t("store")];
  //   'product/:productName/:productId/:tab',

  return (
    <div>
      <div className="">
        <BreadCrumbComponent
          // active={t('Test')}
          previous={[{ name: t("Orders"), link: "/orders" }]}
        />
      </div>

      <div className=" d-flex flex-wrap align-items-center tabs">
        {tabs.map((item, index) => {
          return (
            <p
              onClick={(e) => {
                History.push(`/orders/${index}`);
              }}
              className={`${
                index == value ? "active-tab" : ""
              } mb-0 margin-tabs mt-2 cursor-pointer`}
            >
              {item}
            </p>
          );
        })}
      </div>
      <div className="mt-4">
        {value == 0 ? (
          <div className="">
            <MedicineOrders {...props} />
          </div>
        ) : value == 1 ? (
          <div className="">
            <StoreOrders {...props} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
