import Domain from "lib/Config";
import axios from "axios";
import { NotificationManager } from "react-notifications";

export const GET_ALL_ARTICLES = "[ADMIN] GET_ALL_ARTICLES";
export const GET_ARTICLE_DETAIL = "[ADMIN] GET_ARTICLE_DETAIL";
export const ARTICLE_POSTED = "[ADMIN] ARTICLE_POSTED";
export const ARTICLE_STATE_LOADING = "[ADMIN] ARTICLE_STATE_LOADING";
export const ARTICLE_LOADING = "[ADMIN] ARTICLE_LOADING";
export const ARTICLE_ERROR = "[ADMIN] ARTICLE_ERROR";

export function getArticles(data, vendor_id) {
  let page = 0;
  if (data.page) {
    page = data.page - 1;
  }
  console.log("Data ---", data);
  console.log("page --->", page);
  console.log(
    "data && data.page ? page * 10 : 0--->",
    data && data.page ? page * 10 : 0
  );
  console.log("vendor_id --->", vendor_id);
  const request = axios.get(`${Domain}/api/vendor/articles`, {
    params: {
      ...data,
      page: page,
      // offset: data && data.page ? page * 10 : 0,
      vendor_id,
      limit: 10,
    },
  });
  return (dispatch) =>
    request
      .then((response) => {
        console.log("response @@@@ --->", response);
        return dispatch({
          type: GET_ALL_ARTICLES,
          payload: response.data.categories,
        });
      })
      .catch((error) => {
        console.log(error.response);
        return dispatch({
          type: ARTICLE_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get articles",
        });
      });
}
export function getArticleDetail(id) {
  const request = axios.get(`${Domain}/api/vendor/article/${id}`);
  return (dispatch) =>
    request
      .then((response) => {
        console.log("response @@@@ --->", response);
        return dispatch({
          type: GET_ARTICLE_DETAIL,
          payload: response.data.article,
        });
      })
      .catch((error) => {
        console.log(error.response);
        return dispatch({
          type: ARTICLE_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get articles",
        });
      });
}

export function addArticle(data) {
  const request = axios.post(`${Domain}/api/vendor/article`, data);
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success("Article Added Successfully");
        dispatch({
          type: ARTICLE_POSTED,
          payload: true,
        });
        dispatch(setArticleStateLoading(false));
        dispatch(setArticleLoading(true));
        dispatch(getArticles({}, data.vendor_id));
      })
      .catch((error) => {
        console.log(error.response);

        dispatch(setArticleStateLoading(false));
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : "Cannot Add Article"
        );
      });
}

export function updateArticle(data, articleId) {
  const request = axios.put(`${Domain}/api/vendor/article/${articleId}`, data);
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success("Article Updated Successfully");
        dispatch({
          type: ARTICLE_POSTED,
          payload: true,
        });
        dispatch(setArticleStateLoading(false));

        dispatch(setArticleLoading(true));
        dispatch(getArticles({}, data.vendor_id));
      })
      .catch((error) => {
        console.log(error.response);
        dispatch(setArticleStateLoading(false));
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : "Cannot Update Article"
        );
      });
}

export function deleteArticle(id, variables, vendor_id) {
  const request = axios.delete(`${Domain}/api/vendor/article/${id}`);
  return (dispatch) =>
    request
      .then((response) => {
        console.log("response --->", response);
        NotificationManager.success("Article Deleted Successfully");
        dispatch(getArticles(variables, vendor_id));
      })
      .catch((error) => {
        console.log("Error --->", error);
        console.log(error.response);
        dispatch(setArticleLoading(false));
        NotificationManager.error(
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : "Cannot Delete Article"
        );
      });
}

export function setArticleLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: ARTICLE_LOADING,
      payload: val,
    });
  };
}

export function setArticleStateLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: ARTICLE_STATE_LOADING,
      payload: val,
    });
  };
}
