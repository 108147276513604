import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Toggle from "react-toggle";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumbComponent from "components/Breadcrumbs";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import * as Actions from "store/actions";
import { Player, BigPlayButton } from "video-react";

import "./index.css";

export default function Questionaires(props) {
  const [questionaire, setQuestionaire] = useState(null);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });

  const questionaireReducer = useSelector(({ questionaire }) => questionaire);

  useEffect(() => {
    dispatch(Actions.setQuestionairesLoading(true));
    dispatch(
      Actions.getPatientSolvedTreeQuestionaire(
        props.match.params.pId,
        props.match.params.qId
      )
    );
  }, [props.match.params.pId, props.match.params.qId]);

  useEffect(() => {
    setQuestionaire(questionaireReducer?.solvedTreeQuestionaire);
  }, [questionaireReducer]);

  if (questionaireReducer && questionaireReducer.error) {
    return <ErrorMessageComponents message="Questionnaire Not Attempted" />;
  }
  console.log("Questionniare Reducer --->", questionaireReducer);

  if (questionaire === null || questionaireReducer.loading) {
    return <CircularProgressComponent />;
  }
  console.log("questionaire --->", questionaire);
  return (
    <div>
      <div className="mt-4">
        <BreadCrumbComponent
          active={"Solved Questionnaire"}
          previous={[
            { name: t("all_questionnaires"), link: "/treeQuestionnaires" },
            // { name: t("details"), link: `/questionnaire/${props.match.params.id}` },
          ]}
        />
        {/* <h1 className="form-heading font-weight-bold d-flex">{t("questionnaire_details")}</h1> */}
        <div className="d-flex justify-content-end align-items-center">
          <div className="font-weight-bold">Show Score:</div>
          <Toggle
            className="ml-2"
            checked={show}
            onChange={() => setShow(!show)}
          />
        </div>
        <div className="rct-block mg-top-40">
          <div className="d-flex justify-content-between align-items-center border-bottom-centrify p-4">
            <div className="d-flex flex-column w-100">
              <div className="d-flex justify-content-between align-items-center">
                <h2 className="font-weight-bold">
                  {questionaire.TreeQuestionnaire.title}
                </h2>
                {show && (
                  <h3 className="font-weight-bold clr-yelloww">
                    {" "}
                    Score: {questionaire?.score}
                  </h3>
                )}
              </div>
              <p className="mt-5">
                {questionaire?.TreeQuestionnaire?.shortDescription}
              </p>
            </div>
          </div>
        </div>
        {questionaire?.TreeQuestionnaire?.TQQuestions &&
          questionaire?.TreeQuestionnaire?.TQQuestions.length > 0 && (
            <>
              {questionaire?.TreeQuestionnaire?.TQQuestions.map(
                (ques, index) => (
                  <div className="rct-block mt-5">
                    <div className="d-flex flex-column p-4">
                      <div className="d-flex justify-content-between mb-3">
                        <h3 className="main-heading font-weight-bold d-flex">
                          Q{index + 1}:{"  "}
                          {ques.title}
                        </h3>
                        {show && ques?.questionType === "MCQ" && (
                          <h3 className="font-weight-bold clr-yelloww">
                            {" "}
                            Score: {questionaire?.answers[index]?.score}
                          </h3>
                        )}
                      </div>
                      {ques.questionType === "IMAGE" ? (
                        <img
                          src={ques.answers[0].files[0].file}
                          className="solvedQuestionaireImage"
                        ></img>
                      ) : ques.questionType === "VIDEO" ? (
                        <div>
                          <div className="solvedQuestionaireVideo">
                            <Player
                              fluid={false}
                              height="100%"
                              width="100%"
                              src={ques.answers[0].files[0].file}
                            >
                              <BigPlayButton position="center" />
                            </Player>
                          </div>
                        </div>
                      ) : ques.questionType === "MCQ" ? (
                        <div className="d-flex flex-column mt-4">
                          {ques.TQOptions.map((op) => (
                            <div className="mx-2">
                              <input
                                type="checkbox"
                                name="type"
                                style={{ transform: "scale(1.5)" }}
                                id={op.text}
                                value={
                                  op.optionSelected && op.optionSelected == true
                                    ? true
                                    : false
                                }
                                defaultChecked={
                                  op.optionSelected && op.optionSelected == true
                                    ? true
                                    : false
                                }
                                disabled
                              />
                              <label for="item" className="ml-4 font-14 lead">
                                {op.option}
                              </label>
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  </div>
                )
              )}
            </>
          )}
      </div>
    </div>
  );
}
