import Domain from 'lib/Config'
import axios from 'axios'
import * as Actions from './index'

export const VENDOR_DETAIL = '[ADMIN] VENDOR_DETAIL'
export const VENDOR_DETAIL_ERROR = '[ADMIN] VENDOR_DETAIL_ERROR'
export const DASHBOARD_DETAIL = '[ADMIN] DASHBOARD_DETAIL'
export const DASHBOARD_LOADING = '[ADMIN] DASHBOARD_LOADING'
export const DASHBOARD_QUESTION_STATE_LOADING =
  '[ADMIN] DASHBOARD_QUESTION_STATE_LOADING'
export const DASHBOARD_QUESTIONS = '[ADMIN] DASHBOARD_QUESTIONS'
export const DASHBOARD_QUESTION_LOADING = '[ADMIN] DASHBOARD_QUESTION_LOADING'
export const DASHBOARD_QUESTION_POSTED = '[ADMIN] DASHBOARD_QUESTION_POSTED'
export const DASHBOARD_QUESTION_ERROR = '[ADMIN] DASHBOARD_ERROR'
export const DASHBOARD_ERROR = '[ADMIN] DASHBOARD_ERROR'

export function getVendorDetail (vendor_id) {
  const request = axios.get(`${Domain}/api/vendor/vendorDetails`, {
    params: {
      id: vendor_id
    }
  })
  return dispatch =>
    request
      .then(response => {
        return dispatch({
          type: VENDOR_DETAIL,
          payload: {
            ...response.data.result
          }
        })
      })
      .catch(error => {
        console.log(error.response)
        return dispatch({
          type: VENDOR_DETAIL_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get vendor detail'
        })
      })
}

export function updateVendorProfile (vendor_id, data) {
  console.log('Update Vendor Profile Called')
  const request = axios.post(`${Domain}/api/vendor/updateVendor`, data)
  return dispatch =>
    request
      .then(response => {
        dispatch(
          Actions.apiResponseNotification({
            msgType: 'success',
            translate: true,
            msg: 'vendor_updated_successfully'
          })
        )
        dispatch(getVendorDetail(vendor_id, {}))
      })
      .catch(error => {
        console.log(error.response)
        dispatch(
          Actions.apiResponseNotification({
            msgType: 'error',
            translate: true,
            msg: 'unable_to_update_vendor'
          })
        )

        dispatch(setDashboardLoading(false))
      })
}

export function getGeneralQuestions (vendor_id, data) {
  let page = 0
  if (data && data.page) {
    page = data.page - 1
  }
  const request = axios.get(`${Domain}/api/vendor/generalMessages`, {
    params: {
      ...data,
      vendor_id,
      limit: 5,
      page: page
    }
  })
  return dispatch =>
    request
      .then(response => {
        return dispatch({
          type: DASHBOARD_QUESTIONS,
          payload: {
            ...response?.data?.messages
          }
        })
      })
      .catch(error => {
        console.log(error.response)
        return dispatch({
          type: DASHBOARD_QUESTION_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : 'Error! Cannot get general questions'
        })
      })
}
export function addGeneralQuestion (data) {
  const request = axios.post(`${Domain}/api/vendor/message`, data)
  return dispatch =>
    request
      .then(response => {
        dispatch(
          Actions.apiResponseNotification({
            msgType: 'success',
            translate: true,
            msg: 'general_question_added_successfully'
          })
        )
        dispatch(setDashboardQuestionLoading(true))
        dispatch(getGeneralQuestions(data.vendor_id, {}))
        dispatch({
          type: DASHBOARD_QUESTION_POSTED,
          payload: true
        })
      })
      .catch(error => {
        console.log('project chat question add error --->', error)
        console.log(error.response)
        dispatch(setDashboardQuestionLoading(false))
        let errMsg =
          error.response && error.response.data && error.response.data.msg
        dispatch(
          Actions.apiResponseNotification({
            msgType: 'error',
            translate: errMsg ? false : true,
            msg: errMsg ? errMsg : 'unable_to_add_general_question'
          })
        )
      })
}
export function updateGeneralQuestion (data, question_id) {
  const request = axios.put(`${Domain}/api/vendor/message/${question_id}`, data)
  return dispatch =>
    request
      .then(response => {
        dispatch(
          Actions.apiResponseNotification({
            msgType: 'success',
            translate: true,
            msg: 'general_question_updated_successfully'
          })
        )
        dispatch(setDashboardQuestionLoading(true))
        dispatch(getGeneralQuestions(data.vendor_id, {}))
        dispatch({
          type: DASHBOARD_QUESTION_POSTED,
          payload: true
        })
      })
      .catch(error => {
        console.log('general chat question update error --->', error)
        console.log(error.response)
        let errMsg =
          error.response && error.response.data && error.response.data.msg
        dispatch(
          Actions.apiResponseNotification({
            msgType: 'error',
            translate: errMsg ? false : true,
            msg: errMsg ? errMsg : 'unable_to_update_general_question'
          })
        )
      })
}
export function deleteGeneralQuestion (question_id, vendor_id) {
  const request = axios.delete(`${Domain}/api/vendor/message/${question_id}`)
  return dispatch =>
    request
      .then(response => {
        dispatch(
          Actions.apiResponseNotification({
            msgType: 'success',
            translate: true,
            msg: 'general_question_deleted_successfully'
          })
        )
        dispatch(getGeneralQuestions(vendor_id, {}))
      })
      .catch(error => {
        console.log(error.response)
        dispatch(setDashboardQuestionLoading(false))
        let errMsg =
          error.response && error.response.data && error.response.data.msg
        dispatch(
          Actions.apiResponseNotification({
            msgType: 'error',
            translate: errMsg ? false : true,
            msg: errMsg ? errMsg : 'unable_to_delete_general_question'
          })
        )
      })
}

export function setDashboardLoading (val) {
  return dispatch => {
    return dispatch({
      type: DASHBOARD_LOADING,
      payload: val
    })
  }
}

export function setDashboardQuestionLoading (val) {
  return dispatch => {
    return dispatch({
      type: DASHBOARD_QUESTION_LOADING,
      payload: val
    })
  }
}

export function setDashboardQuestionStateLoading (val) {
  return dispatch => {
    return dispatch({
      type: DASHBOARD_QUESTION_STATE_LOADING,
      payload: val
    })
  }
}
