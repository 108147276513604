import { Avatar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input } from "reactstrap";
import AddActivity from "./addActivity";
import qs from "query-string";
import * as Actions from "store/actions";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import History from "@history";
import moment from "moment";
import momentt from "moment-timezone";
import { Skeleton } from "@material-ui/lab";
import ViewActivity from "./viewActivity";
import { FaHospitalUser } from "react-icons/fa";
import { IoMdCall } from "react-icons/io";
import UpdateActivity from "./updateActivity";
import ConfrimDialogue from "components/Dialogue/ConfrimDialogue";
import PillDetectedImg from "assets/icons/pill_detected.svg";
import PillDetectionFailImg from "assets/icons/pill_detection_fail.svg";
import FaceDetectedImg from "assets/icons/face_detected.svg";
import FaceDetectionFailImg from "assets/icons/face_detection_fail.svg";

export default function Activity(props) {
  const dispatch = useDispatch();
  let querystr = qs.parse(props.location.search);
  let page = querystr.page ? JSON.parse(querystr.page) : 1;
  let tFrmt = "HH:mm:ss";
  let frmt2 = "YYYY-MM-DD HH:mm:ss";
  let dateN = new Date();
  let utcNow = moment(dateN).utc().format(frmt2);
  const [singleDel, setSingleDelete] = useState(true);
  //convert date Range to utc before api call
  const [variables, setVariables] = useState({
    ...querystr,
    dateRange: [moment().format(`YYYY-MM-DD`), moment().format("YYYY-MM-DD")],
    dateToday: utcNow,
  });
  const [startDate, setStartDate] = useState(
    variables.dateRange
      ? moment(variables.dateRange[0]).format(`YYYY-MM-DD`)
      : moment().format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    variables.dateRange
      ? moment(variables.dateRange[1]).format(`YYYY-MM-DD`)
      : moment().format("YYYY-MM-DD")
  );
  const [dialogue, setDialogue] = useState(false);
  const [activity, setActivity] = useState(null);
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [data, setData] = useState(null);
  const { t } = useTranslation();
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  const activityReducer = useSelector(({ activity }) => activity);
  let patient_id = props.match.params.patientId;

  useEffect(() => {
    const q_str = qs.stringify(variables);
    History.push({ search: q_str });
    const vendor_id = Auth.vendor_id;
    dispatch(Actions.setActivityLoading(true));
    dispatch(
      Actions.getAllActivites(variables, patient_id, vendor_id, Auth.id)
    );
  }, [variables]);

  useEffect(() => {
    setActivity(activityReducer);
    if (activityReducer.posted) {
      setOpen(false);
      setEditOpen(false);
      setViewOpen(false);
    }
  }, [activityReducer]);

  if (activity && activity.error) {
    return <ErrorMessageComponents message={t("cannot_fetch_activities'")} />;
  }

  if (activity === null || !activity.activityList) {
    return <CircularProgressComponent />;
  }
  const getTimeZoneDifference = (tz) => {
    // Calculating Time zone difference between activity timezone and our current timezone

    var currentTimezone = momentt().tz(momentt.tz.guess()).format("Z");

    let currentActivityHour = tz.split(":")[0];
    let currentActivityMinutes = currentTimezone.split(":")[1];
    let currentTimezoneHour = currentTimezone.split(":")[0];
    let currentTimezoneMinutes = tz.split(":")[1];

    let formatedHourDiff = null;
    let hourDiff = null;
    let currentActivityHourWithoutSign = currentActivityHour.slice(1);

    // Reason why negative sign and ± is seperated is the picker from which the timezone value  is selected in addActivity.js which results in NaN when calculating difference without seperating

    if (currentActivityHour[0] == "−") {
      hourDiff =
        Number(currentActivityHourWithoutSign * -1) -
        Number(currentTimezoneHour);
    } else if (currentActivityHour[0] == "±") {
      hourDiff =
        Number(currentActivityHourWithoutSign) - Number(currentTimezoneHour);
    } else {
      hourDiff = Number(currentActivityHour) - Number(currentTimezoneHour);
    }

    let minuteDiff =
      Number(currentTimezoneMinutes) - Number(currentActivityMinutes);

    if (hourDiff >= 0 && minuteDiff >= 0) {
      formatedHourDiff = `(T +${("0" + Math.abs(hourDiff)).slice(-2)}:${(
        "0" + Math.abs(minuteDiff)
      ).slice(-2)})`;
    } else {
      formatedHourDiff = `(T -${("0" + Math.abs(hourDiff)).slice(-2)}:${(
        "0" + Math.abs(minuteDiff)
      ).slice(-2)})`;
    }
    return formatedHourDiff;
  };

  const getActivityList = activity.loading ? (
    <div className="col-md-4 mt-2" style={{ height: "200px" }}>
      <Skeleton style={{ transform: "none" }} height={120} />
    </div>
  ) : activity.activityList && activity.activityList.length > 0 ? (
    activity.activityList.map((item) => {
      return (
        <div className="row">
          <div className="col-md-12 mt-2 date-header">
            <p className="selected-date">
              {moment(item.date).local().format("Do MMMM, YYYY")}
            </p>
          </div>
          {item.activities.map((item) => {
            var formatedHourDiff = getTimeZoneDifference(item.tz);

            return (
              <div className="col-md-4 mt-2 activity-block cursor-pointer">
                <div className="rct-block">
                  <div className="rct-block-content">
                    <div className="activity-header d-flex justify-content-between mb-2 ">
                      <div className="">
                        <p className="mb-0 text-capitalize">{item.title}</p>
                        <span>
                          {item.inventoryactivity
                            ? item.inventoryactivity.medicineinventory
                              ? item.inventoryactivity.medicineinventory.name
                              : item.inventoryactivity.name
                            : ""}
                        </span>
                      </div>
                      <div className="text-right">
                        <p className="mb-0">
                          {moment(item.time, tFrmt)
                            .utc(true)
                            .local()
                            .format("hh:mm A")}
                        </p>
                        <span>{formatedHourDiff}</span>
                      </div>
                    </div>
                    <div className="activity-status d-flex justify-content-between align-items-center mt-2">
                      <div className="d-flex align-items-center">
                        <Avatar
                          src={
                            item.employeeactivity && item.employeeactivity.image
                          }
                          className="profile-pic mr-2"
                        />
                        <p className="mb-0 mr-2">
                          {item.createdBy == "employee" && item.employeeactivity
                            ? item.employeeactivity.firstName +
                              " " +
                              item.employeeactivity.lastName
                            : item.createdBy == "patient" &&
                              item.patientactivity
                            ? item.patientactivity.firstName +
                              " " +
                              item.patientactivity.lastName
                            : ""}
                        </p>
                        {item.createdBy == "employee" && (
                          <div className="added-by-employee mr-2 mt-1">
                            <FaHospitalUser />
                          </div>
                        )}
                        {item.victor && item.victor == true && (
                          <div className="added-by-employee mr-2 mt-1">
                            <IoMdCall />
                          </div>
                        )}
                      </div>
                      {item.status == "done" ? (
                        <div className="taken">
                          <p className="mb-0">{t("taken")}</p>
                        </div>
                      ) : item.status == "missed" ? (
                        <div className="missed">
                          <p className="mb-0">{t("missed")}</p>
                        </div>
                      ) : item.status == "pending" ? (
                        <div className="in-time">
                          <p className="mb-0">{t("pending")}</p>
                        </div>
                      ) : item.status == "current" ? (
                        <div className="current">
                          <p className="mb-0 ">{t("in_time")}</p>
                        </div>
                      ) : null}
                    </div>
                    <div
                      className="d-flex align-items-center  justify-content-between  mt-4"
                      style={{ fontSize: "0.8rem" }}
                    >
                      <div>
                        <a
                          className="centrify-blue-color mr-2"
                          onClick={(e) => handleView(item)}
                        >
                          {t("view")}
                        </a>
                        {item.createdBy == "employee" &&
                          item.status != "done" &&
                          item.status != "missed" && (
                            <a
                              className="centrify-blue-color mr-2"
                              onClick={(e) =>
                                History.push(
                                  `/edit-activity/${item?.id}/single`
                                )
                              }
                            >
                              {t("edit")}
                            </a>
                          )}

                        {item.createdBy == "employee" &&
                          item.status != "done" &&
                          item.status != "missed" && (
                            <a
                              className="centrify-blue-color mr-2"
                              onClick={(e) =>
                                History.push(`/edit-activity/${item?.id}/bulk`)
                              }
                            >
                              Bulk-Edit
                            </a>
                          )}

                        {item.status != "missed" && item.status != "done" ? (
                          <a
                            onClick={(e) => handleDelete(item)}
                            className="centrify-blue-color mr-2"
                          >
                            {t("delete")}
                          </a>
                        ) : null}
                      </div>
                      {(item.victor == true &&
                        item.status == "done" &&
                        item.type == "medicine") ||
                      (item.status == "done" && item.type == "supplements") ? (
                        <div className="taken d-flex">
                          <div className="mr-4">
                            <img
                              src={
                                item.doseFiles &&
                                item.doseFiles.length > 0 &&
                                item.doseFiles[0].faceDetected == true
                                  ? FaceDetectedImg
                                  : FaceDetectionFailImg
                              }
                              className="centrify-icons "
                              style={{
                                height: "28px",
                              }}
                            />
                          </div>
                          <div>
                            <img
                              src={
                                item.doseFiles &&
                                item.doseFiles.length > 0 &&
                                item.doseFiles[0].pillDetected == true
                                  ? PillDetectedImg
                                  : PillDetectionFailImg
                              }
                              className="centrify-icons "
                              style={{
                                height: "28px",
                              }}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    })
  ) : (
    <div
      className="col-md-12 align-items-center justify-content-center"
      style={{ height: "200px" }}
    >
      <p className="text-center mt-4 font-weight-bold">
        {t("no_activities_found")}
      </p>
    </div>
  );

  const handleDelete = (item) => {
    setData(item);
    setDialogue(true);
  };

  const handleClose = () => {
    setData(null);
    setDialogue(!dialogue);
  };

  const handleAction = () => {
    let _data = {
      uuid: data.uuid,
    };
    let _variables = {
      dateRange: [
        moment(startDate).format(`YYYY-MM-DD`),
        moment(endDate).format("YYYY-MM-DD"),
      ],
      dateToday: utcNow,
    };
    dispatch(Actions.setActivityLoading(true));
    if (singleDel) {
      dispatch(Actions.deleteActivity(data, _variables, patient_id, Auth.id));
    } else {
      dispatch(Actions.bulkDelete(_data, _variables, patient_id, Auth.id));
    }
    setDialogue(!dialogue);
  };

  const handleFilter = () => {
    if (startDate && endDate)
      setVariables({ ...variables, dateRange: [startDate, endDate], page: 1 });
  };

  const removeFilter = () => {
    setStartDate(moment().format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));
    setVariables({
      ...variables,
      dateRange: [
        moment().format(`YYYY-MM-DD `),
        moment().format("YYYY-MM-DD"),
      ],
      page: 1,
    });
  };

  const handleView = (item) => {
    setData(item);
    setViewOpen(!viewOpen);
  };

  const handleDelOptions = (value) => {
    if (value == "multiple") setSingleDelete(false);
    else if (value == "single") setSingleDelete(true);
  };

  const getDateInText =
    startDate == endDate
      ? moment(startDate).format("DD MMMM, dddd")
      : moment(startDate).format("DD MMMM, dddd") +
        " - " +
        moment(endDate).format("DD MMMM, dddd");

  return (
    <div className="patient-profile ">
      <h1 className="main-heading d-flex">{t("activity")}</h1>
      <div className="d-flex justify-content-end align-items-center flex-wrap date-header">
        <div className="d-flex flex-wrap align-items-center date-range">
          <p className="mb-0">{t("from")}</p>
          <Input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <p className="mb-0">{t("to")}</p>
          <Input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />

          <Button color="primary" className="mr-2" onClick={handleFilter}>
            {t("apply_filters")}
          </Button>
          <Button color="danger" className="mr-2" onClick={removeFilter}>
            {t("remove_filters")}
          </Button>
          <Button
            onClick={() => {
              if (props.match.params.projectId) {
                History.push(
                  `/add-activity/${props.match.params.patientId}/${props.match.params.projectId}/${activity.subscriptionStatus}`
                );
              } else {
                History.push(
                  `/add-activity/${props.match.params.patientId}/null/${activity.subscriptionStatus}`
                );
              }
            }}
            color="primary-background-color"
            className="primary-background-color  text-white"
          >
            {t("add_activity")}
          </Button>
        </div>
      </div>
      <div className=" activity-cards">
        <div>{getActivityList}</div>
        <div className="d-flex justify-content-end mr-4"></div>
      </div>
      <AddActivity
        open={open}
        toggle={(e) => setOpen(!open)}
        project_id={props.match.params.project_id}
        patient_id={props.match.params.patientId}
        employee_id={Auth.id}
      />
      <UpdateActivity
        open={editOpen}
        toggle={(e) => setEditOpen(!editOpen)}
        patient_id={props.match.params.patientId}
        employee_id={Auth.id}
        data={data}
        startDate={startDate}
        endDate={endDate}
      />
      <ViewActivity
        data={data}
        modal={viewOpen}
        toggle={() => setViewOpen(!viewOpen)}
      />
      <ConfrimDialogue
        delOptions={true}
        handleDelOptions={handleDelOptions}
        open={dialogue}
        handleClose={(e) => handleClose()}
        action={(e) => handleAction()}
        msg={t("are_you_sure_you_want_to_delete_this_activity") + "?"}
      />
    </div>
  );
}
