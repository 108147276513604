import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import BreadCrumbComponent from "components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddQuestion from "./addQuesForm";
import EditQuestion from "./editQuesForm";
import { NotificationManager } from "react-notifications";
import * as Actions from "store/actions";

export default function Questionaires(props) {
  const { type } = props;
  //   Default Values for first question
  const [questionsArray, setQuestionsArray] = useState([
    {
      title: "",
      questionType: "MCQ",
      options: [
        { option: "", value: false, important: false },
        { option: "", value: false, important: false },
      ],
    },
  ]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });

  const questionaireReducer = useSelector(({ questionaire }) => questionaire);
  console.log("questionsArray ---->", questionsArray);
  const handleSubmit = (e) => {
    e.preventDefault();
    let obj = {};
    const formData = new FormData(e.target);
    for (let [key, value] of formData.entries()) {
      obj[key] = value;
    }
    if (questionsArray.length == 1 && questionsArray[0].title.length == "0") {
      NotificationManager.error("Please type at lease one question");
    }
    obj.questions = questionsArray;
    obj.status = "done";
    console.log("Obj ---->", obj);
    dispatch(Actions.setQuestionairesLoading(true));
    dispatch(Actions.addQuestionaire(obj, Auth.vendor_id));
  };

  const handleRemoveQuestion = (index) => {
    console.log("Remove Question Called --->", index);
    console.log("Current Questions ARRAY --->", questionsArray);
    if (index) {
      let newData = questionsArray;
      newData.splice(index, 1);
      setQuestionsArray([...newData]);
      console.log(newData);
    }
  };

  return (
    <div className="notifications">
      <div className="mt-4">
        <form onSubmit={(e) => handleSubmit(e)} className="">
          <div className="d-flex justify-content-between">
            <BreadCrumbComponent
              active={t("add_questionnaire")}
              previous={[
                { name: t("all_questionnaires"), link: "/questionnaires" },
                // { name: t("details"), link: `/questionnaire/${props.match.params.id}` },
              ]}
            />
            <div className="text-right mb-5">
              {questionaireReducer.loading ? (
                <CircularProgress />
              ) : (
                <Button
                  color="none"
                  className="primary-background-button-table font-weight-bold mb-5"
                >
                  {t("create_questionnaire")}
                </Button>
              )}
            </div>
          </div>
          {/* <div className='text-right mb-5'>
                        {questionaireReducer.loading ? (
                            <CircularProgress />
                        ) : (
                                <Button
                                    color="none"
                                    className="primary-background-button-table font-weight-bold mb-5"
                                >
                                    {t("create_questionnaire")}
                                </Button>
                            )}
                    </div> */}
          <div className="rct-block pd-top-80">
            <div className="d-flex justify-content-between align-items-center border-bottom-centrify p-4">
              <div className="d-flex flex-column w-100">
                <input
                  type="text"
                  name="title"
                  placeholder="Enter Title"
                  required
                  className="w-100 border-0 bg-transparent questionaire-title font-weight-bold"
                  required
                />
                <input
                  type="textarea"
                  name="shortDescription"
                  placeholder="Short Description (optional)"
                  className="w-100 border-0 bg-transparent mt-5"
                  rows={4}
                />
              </div>
            </div>
          </div>
        </form>
        <div className="row p-3">
          <AddQuestion
            questionsArray={questionsArray}
            setQuestionsArray={setQuestionsArray}
          />
        </div>
        {questionsArray.length > 0 && (
          <>
            {questionsArray.map(
              (ques, index) =>
                index > 0 ? (
                  <EditQuestion
                    questionsArray={questionsArray}
                    handleRemoveQuestion={handleRemoveQuestion}
                    setQuestionsArray={setQuestionsArray}
                    ques={ques}
                    index={index}
                  />
                ) : null
              // <div className="rct-block mt-5">
              //     <div className="d-flex flex-column p-4">
              //         <div className="d-flex justify-content-between">
              //             <h3 className="main-heading font-weight-bold d-flex">Q{index + 1}:{'  '}{ques.title}</h3>
              //             <div className='d-flex'>
              //                 <EditIcon className="centrify-grey-color cursor-pointer" />
              //                 <img
              //                     src={require("assets/icons/remove.png")}
              //                     alt=""
              //                     className="centrify-table-icons cursor-pointer ml-5"
              //                     onClick={() => handleRemoveQuestion(index)}
              //                 />
              //             </div>
              //         </div>
              //         {ques.questionType === 'TEXT' ? null
              //             :
              //             <div className='d-flex flex-column mt-4'>
              //                 {ques.options.map(op => (
              //                     <div className='mx-2'>
              //                         <input type='checkbox' disabled name='type' style={{ transform: "scale(1.5)" }} id={op.text} value={op.text} defaultChecked={op.status} />
              //                         <label for='item' className='ml-4 font-14 lead'>
              //                             {op.option}
              //                         </label>
              //                     </div>
              //                 ))}
              //             </div>
              //         }
              //         {/* <div className='mt-4 text-right'>
              //                 <Button
              //                     color="none"
              //                     className="primary-background-button-table font-weight-bold"
              //                 >
              //                     {t("delete")}
              //                 </Button>
              //             </div> */}
              //     </div>
              // </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
