import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Actions from 'store/actions'
import { CircularProgress, IconButton } from '@material-ui/core'
import { Button, Modal, Label, ModalBody, Input } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import Domain from 'lib/Config'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import DeleteIcon from '@material-ui/icons/Delete'
import NotificationManager from 'react-notifications'
import FileDrop from 'react-dropzone'
import AddIcon from '@material-ui/icons/Add'
import { AiOutlineCloudUpload } from 'react-icons/ai'

const CsvModal = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [file, setFile] = useState()
  const storeReducer = useSelector(({ store }) => store)
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false
  })

  useEffect(() => {
    setFile(null)
  }, [props.modal])

  let handleSubmit = e => {
    e.preventDefault()
    var formData = new FormData()
    console.log('file --->', file)
    formData.append('file', file)
    console.log('Form Data --->', formData.get('file'))
    formData.append('vendor_id', Auth.vendor_id)
    dispatch(Actions.setStoreStateLoading(true))
    dispatch(
      Actions.uploadStoreProductCsv(formData, Auth.vendor_id, props.toggle)
    )
  }
  const onDrop = useCallback(img => {
    console.log('img[0] -->', img[0])
    setFile(img[0])
  }, [])
  console.log('file ---->', file)
  return (
    <div>
      <Modal
        isOpen={props.modal}
        toggle={props.toggle}
        className='modal-background'
      >
        <ModalBody>
          <div className='p-4'>
            <div className='d-flex justify-content-end'>
              <img
                onClick={() => props.toggle()}
                className='centrify-icons cursor-pointer'
                src={require('assets/icons/close.png')}
              />
            </div>
            <div className='mt-2 form-styles'>
              <h2 className='form-heading d-flex mb-'>{t('import_csv')}</h2>
              <form onSubmit={e => handleSubmit(e)}>
                <div className='mt-4'>
                  <Label className='d-flex mb-3'>{t('')}</Label>
                  <div className='m-auto'>
                    {file ? (
                      <div
                        className=' m-auto text-center dotted-border px-4 py-4'
                        key={file.id}
                        style={{ width: '100%' }}
                      >
                        <div className='d-flex flex-column'>
                          <p>{file.name}</p>
                        </div>
                        <div className='del-img'>
                          <IconButton
                            color='inherit'
                            mini='true'
                            className='p-0'
                            onClick={() => setFile(null)}
                          >
                            <DeleteIcon fontSize='small' />
                          </IconButton>
                        </div>
                      </div>
                    ) : (
                      <FileDrop
                        disabled={null}
                        accept='text/csv'
                        multiple
                        onDrop={onDrop}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps()}
                            className=' m-auto text-center dotted-border px-4 py-2'
                            style={{ width: '100%' }}
                          >
                            <div className='mx-4'>
                              <AiOutlineCloudUpload
                                style={{ fontSize: '8rem' }}
                              />
                              <p style={{ fontSize: '16px' }}>Drop file</p>
                            </div>
                            <div className='mx-4'>
                              <p style={{ fontSize: '16px' }}> OR</p>
                            </div>
                            <div className='mx-4'>
                              <p
                                className='p-2 px-4 border border-rounded border-dark d-inline-block'
                                style={{ fontSize: '16px', cursor: 'pointer' }}
                              >
                                Select file
                              </p>{' '}
                            </div>
                            <input {...getInputProps()} accept='.csv' />
                          </div>
                        )}
                      </FileDrop>
                    )}
                  </div>
                </div>

                <div className='mt-4 d-flex justify-content-end'>
                  {storeReducer.stateLoading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      className='primary-background-button-table'
                      color='none'
                      type='submit'
                    >
                      {t('upload')}
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default CsvModal
