import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "store/actions";
import PatientFilterModal from "./PatientFilterModal";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";

const PatientModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [patientsList, setPatientsList] = useState([]);

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });

  const pickItem = (id, name) => {
    props.itemPicked(id, name);
  };
  const pickAll = () => {
    props.allPicked();
  };

  const handleAssignToPatient = () => {
    dispatch(
      Actions.assignTreeQuestionaireToPatient(
        props.treeQuestionnaireId,
        patientsList,
        Auth.vendor_id,
        Auth.id
      )
    );
    setPatientsList([]);
    props.toggle();
  };

  return (
    <div>
      <Modal isOpen={props.modal} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle}>{t("pick_patient")}</ModalHeader>
        <ModalBody>
          <PatientFilterModal
            selectedPatients={props.patients}
            patientsList={patientsList}
            setPatientsList={setPatientsList}
            handleAssignToPatient={handleAssignToPatient}
            pickItem={pickItem}
            pickAll={pickAll}
            questionnaireId={props.questionnaireId}
            {...props}
            statsScreen={true}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.toggle}>
            {t("cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PatientModal;
