import React, { useState } from "react";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import { Link } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@material-ui/core";

export const Notification = ({
  open,
  className,
  onClose,
  data = [],
  meetingData = [],
  totalNotifications = 0,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const notificationData2 = [
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ];

  let tabs = [
    t(""),
    // t("Options"),
    // t("Alerts"),
  ];

  return (
    <>
      {/* Desktop Start */}
      <div className="position-relative d-none d-lg-block">
        <div
          className={`notification-container-1 bg-white shadow ${className}`}
        >
          <div className="row d-flex flex-column bg-yelloww noti-header-border mx-0 text-black-50 px-2 pt-3">
            <div className="col-md-12 d-flex flex-row justify-content-between align-items-center">
              <h6 className="mb-0 text-capitalize h5 text-white">
                Your Notifications
              </h6>
              <h6 className="mb-0">
                <div
                  variant="outlined"
                  size="small"
                  className="noti-clear-btn d-flex justify-content-center align-items-center"
                  style={{
                    borderRadius: "50%",
                    background: "white",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                >
                  <b>{data.length}</b>
                </div>
              </h6>
              {/* <img
                    src={require("assets/icons/close-black2x.png")}
                    alt=""
                    width="8px"
                    height="8px"
                    className="cursor-pointer text-center text-black"
                  /> */}
              {/* <span className="">
                    Clear All
                  </span>
                </Button>
              </h6> */}
            </div>
            <div className="col-md-12 d-flex flex-wrap align-items-end tabs pd-top-10 px-0">
              {tabs.map((item, index) => {
                return (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setValue(index);
                    }}
                    className={`${
                      index === value ? "font-weight-bold " : ""
                    }col-sm-4 d-flex justify-content-start text-white mb-0 font-12 mt-2 cursor-pointer`}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="noti-scrollbar" style={{ height: 450 }}>
            {/* <Grid xs={12} container justify="flex-end">
              <Button
                variant="outlined"
                size="small"
              >
                Mark as read
              </Button>
            </Grid> */}
            <Grid xs={12} container>
              {/* <small className="text-black small">New</small> */}
            </Grid>
            {value === 0 ? (
              <>
                {data.length > 0 ? (
                  <>
                    {data?.map((data, i) => (
                      <Link
                        className={`${
                          data.isRead ? "" : "bg-noti-li "
                        }border-top d-flex h6 text-black-50 mb-0 p-2 text-decoration-none mt-5 ${className}`}
                        to={
                          data.type === "important"
                            ? "/reports?tab=1"
                            : data.type === "meeting"
                            ? "/meeting"
                            : data.type === "missedActivities"
                            ? "/reports?tab=0"
                            : data.type === "order"
                            ? "/orders"
                            : ""
                        }
                      >
                        <div className="pl-2 small d-flex align-items-top">
                          <img
                            src={require("assets/icons/yellow-bell4x.png")}
                            alt=""
                            width="auto"
                            height="30px"
                            className="cursor-pointer"
                          />
                        </div>
                        <div className="small pl-3 text-left">
                          <div className="pt-1">
                            <b className="text-capitalize mr-1">{data.body}</b>
                          </div>

                          <div className="text-black-50 small pd-top-10">
                            <img
                              src={require("assets/icons/noti-time-clock1x.png")}
                              alt=""
                              width="auto"
                              height="11px"
                              className="cursor-pointer text-center text-black"
                            />{" "}
                            <span className="ml-1">
                              {moment(data.createdAt).fromNow()}
                            </span>
                          </div>
                        </div>
                      </Link>
                    ))}{" "}
                  </>
                ) : (
                  <small>No New Notifications</small>
                )}
              </>
            ) : value === 1 ? (
              <>
                {/* {meetingData.length > 0 ? <>
                {meetingData ?.map((data, i) => (
                  <Link
                    className={`${data.isRead ? "" : "bg-noti-li "}border-top d-flex h6 text-black-50 mb-0 p-2 text-decoration-none mt-5 ${className}`}
                    to={"/meeting"}
                  >
                    <div className="pl-2 small d-flex align-items-top">
                      <img
                        src={require("assets/icons/yellow-bell4x.png")}
                        alt=""
                        width="auto"
                        height="30px"
                        className="cursor-pointer"
                      />
                    </div>
                    <div className="small pl-3 text-left">
                      <div className="pt-1">
                        <b className="text-capitalize mr-1">{data.body}</b>
                      </div>

                      <div className="text-black-50 small pd-top-10">
                        <img
                          src={require("assets/icons/noti-time-clock1x.png")}
                          alt=""
                          width="auto"
                          height="11px"
                          className="cursor-pointer text-center text-black"
                        /> <span className="ml-1">{moment(data.time).fromNow()}</span>
                      </div>
                    </div>
                  </Link>
                ))} </> : <small className="font-14">No New Meetings</small>} */}
              </>
            ) : null}
          </div>
        </div>
      </div>
      {/* Desktop End */}
      {/* Mobile Start */}
      <div className="position-relative d-lg-none">
        <div className={`notification-container-2 ${className}`}>
          <div>
            <div className="row justify-content-between mx-0 bg-white px-2 py-3">
              <h6 className="mb-0 text-capitalize">Your Notifications</h6>
              <h6 className="mb-0">
                <small className="font-weight-bold">17</small>
              </h6>
              <button
                onClick={onClose}
                type="button"
                className="btn btn-primary rounded-circle border-0 m-2 fixed-bottom vibrate-1"
                style={{ left: "unset" }}
              >
                <span className="h5">&times;</span>
              </button>
            </div>
          </div>
          <div className="scroll-box px-2" style={{ height: "95%" }}>
            {/* {notificationData2 ?.map((i, data) => (
              <Link
                to={data.link}
                className={`border-top d-flex h6 text-black-50 mb-0 p-2 text-decoration-none ${className}`}
              >
                <div className="border-right small pr-3 d-flex align-items-center">
                  <MailOutlineOutlinedIcon />
                </div>
                <div className="small pl-3 text-left">
                  <div>
                    <b className="text-capitalize mr-1">new requested date</b>
                    on
                  </div>
                  <div className="text-primary my-1">Training 985</div>
                  <div className="text-black-50 small">1 hour age</div>
                </div>
              </Link>
            ))} */}
            {data.length > 0 ? (
              <>
                {data?.map((data, i) => (
                  <Link
                    className={`${
                      data.isRead ? "" : "bg-noti-li "
                    }border-top d-flex h6 text-black-50 mb-0 p-2 text-decoration-none mt-5 ${className}`}
                  >
                    <div className="pl-2 small d-flex align-items-top">
                      <img
                        src={require("assets/icons/yellow-bell4x.png")}
                        alt=""
                        width="auto"
                        height="30px"
                        className="cursor-pointer"
                      />
                    </div>
                    <div className="small pl-3 text-left">
                      <div className="pt-1">
                        <b className="text-capitalize mr-1">{data.body}</b>
                      </div>

                      <div className="text-black-50 small pd-top-10">
                        <img
                          src={require("assets/icons/noti-time-clock1x.png")}
                          alt=""
                          width="auto"
                          height="11px"
                          className="cursor-pointer text-center text-black"
                        />{" "}
                        <span className="ml-1">
                          {moment(data.createdAt).fromNow()}
                        </span>
                      </div>
                    </div>
                  </Link>
                ))}{" "}
              </>
            ) : (
              <small>No New Notifications</small>
            )}
          </div>
        </div>
      </div>
      {/* Mobile End */}
    </>
  );
};
