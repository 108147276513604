import { combineReducers } from 'redux'
import auth from '../../auth/store/reducers'
import appReducer from './app.reducer'
import articles from './article.reducer'
import chat from './chat.reducer'
import company from './company.reducer'
import dashboard from './dashboard.reducer'
import employee from './employee.reducer'
import project from './project.reducer'
import patient from './patient.reducer'
import activity from './activity.reducer'
import medicine from './medicine.reducer'
import inventory from './inventory.reducer'
import order from './order.reducer'
import questionaire from './questionaires.reducer'
import engagement from './engagement.reducer'
import reports from './reports.reducer'
import notifications from './notifications.reducer'
import store from './store.reducer'

const createReducer = asyncReducers =>
  combineReducers({
    auth,
    articles,
    appReducer,
    company,
    dashboard,
    employee,
    project,
    patient,
    activity,
    medicine,
    inventory,
    order,
    reports,
    questionaire,
    engagement,
    notifications,
    chat,
    store,
    ...asyncReducers
  })

export default createReducer
