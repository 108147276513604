import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function Questionaires(props) {
    const { questionaire } = props;
    const [questionsArray, setQuestionsArray] = useState([]);
    const { t } = useTranslation();


    const handleRemoveQuestion = (indexx) => {
        let newData = questionsArray;
        const index = newData.indexOf(indexx);
        if (index > -1) {
            newData.splice(indexx, 1);
        }
        setQuestionsArray([...newData]);
        console.log(newData);
    }

    return (
        <div>
            <div className="mt-4">
                {/* <h1 className="form-heading font-weight-bold d-flex">{t("questionnaire_details")}</h1> */}
                <div className="rct-block mg-top-40">
                    <div className="d-flex justify-content-between align-items-center border-bottom-centrify p-4">
                        <div className='d-flex flex-column w-100'>
                            <h2 className="font-weight-bold">{questionaire.title}</h2>
                            <p className='mt-5'>{questionaire.shortDescription}</p>
                        </div>
                    </div>
                </div>
                {questionaire.questions.length > 0 && <>
                    {questionaire.questions.map((ques, index) => (
                        <div className="rct-block mt-5">
                            <div className="d-flex flex-column p-4">
                                <div className="d-flex justify-content-between">
                                    <h3 className="main-heading font-weight-bold d-flex">Q{index + 1}:{'  '}{ques.title}</h3>
                                    {/* <img
                                        src={require("assets/icons/remove.png")}
                                        alt=""
                                        className="centrify-table-icons cursor-pointer"
                                    // onClick={() => handleRemoveQuestion(index)}
                                    /> */}
                                </div>
                                {ques.questionType === 'TEXT' ? null
                                    :
                                    <div className='d-flex flex-column mt-4 w-100'>
                                        {ques.options.map(op => (
                                            <div className="d-flex flex-row w-100 justify-content-start align-items-center">
                                                <div className='mx-2 w-75 d-flex flex-row justify-content-start'>
                                                    <input type='checkbox' name='type' style={{ transform: "scale(1.5)" }} id={op.text} value={op.text} disabled />
                                                    <label for='item' style={{ marginTop: "-3px" }} className='ml-4 mb-2 font-14 lead text-truncate w-100'>
                                                        {op.option}
                                                    </label>
                                                </div>
                                                {op.important ?
                                                    <img
                                                        src={require("assets/icons/filled-star.png")}
                                                        alt=""
                                                        className="ques-option-icon cursor-pointer pl-5 mb-2"
                                                    /> : <img
                                                        src={require("assets/icons/Unfilled-star.png")}
                                                        alt=""
                                                        className="ques-option-icon cursor-pointer pl-5 mb-2"
                                                    />}
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                        </div>
                    ))}
                </>}
            </div >
        </div >
    );
}
