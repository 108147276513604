import * as Actions from "../actions";

const initialState = {
  vendorDetail: null,
  venderDetailError: null,
  questions: null,
  questionCount: null,
  questionLoading: null,
  questionStateLoading: null,
  questionError: null,
  posted: null,
};

const dashboardReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.VENDOR_DETAIL: {
      return {
        ...state,
        vendorDetail: action.payload,
        venderDetailError: null,
        loading: false,
      };
    }
    case Actions.VENDOR_DETAIL_ERROR: {
      return {
        ...state,
        venderDetailError: action.payload,
      };
    }
    case Actions.DASHBOARD_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case Actions.DASHBOARD_QUESTION_LOADING: {
      return {
        ...state,
        questionLoading: action.payload,
      };
    }
    case Actions.DASHBOARD_QUESTION_STATE_LOADING: {
      return {
        ...state,
        questionStateLoading: action.payload,
      };
    }
    case Actions.DASHBOARD_QUESTION_ERROR: {
      return {
        ...state,
        questionError: action.payload,
        questionLoading: false,
        questionStateLoading: false,
      };
    }
    case Actions.DASHBOARD_QUESTIONS: {
      return {
        ...state,
        questions: action.payload.rows,
        questionCount: action.payload.count,
        questionLoading: false,
        questionStateLoading: false,
        questionError: null,
        posted: null,
      };
    }
    case Actions.DASHBOARD_QUESTION_POSTED: {
      return {
        ...state,
        posted: true,
        questionStateLoading: false,
      };
    }
    case Actions.DASHBOARD_QUESTION_STATE_LOADING: {
      return {
        ...state,
        questionStateLoading: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
export default dashboardReducer;
