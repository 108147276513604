import { Avatar, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input } from "reactstrap";
import qs from "query-string";
import * as Actions from "store/actions";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import History from "@history";
import moment from "moment";
import { Skeleton } from "@material-ui/lab";
import ViewActivity from "./viewActivity";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

export default function Activity() {
  const dispatch = useDispatch();
  let tFrmt = "HH:mm:ss";
  let frmt2 = "YYYY-MM-DD HH:mm:ss";
  let dateN = new Date();
  let utcNow = moment(dateN).utc().format(frmt2);
  //convert date Range to utc before api call
  const [variables, setVariables] = useState({
    dateRange: [moment().format(`YYYY-MM-DD`), moment().format("YYYY-MM-DD")],
    dateToday: utcNow,
  });
  const [startDate, setStartDate] = useState(
    variables.dateRange
      ? moment(variables.dateRange[0]).format(`YYYY-MM-DD`)
      : moment().format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    variables.dateRange
      ? moment(variables.dateRange[1]).format(`YYYY-MM-DD`)
      : moment().format("YYYY-MM-DD")
  );
  const [dialogue, setDialogue] = useState(false);
  const [activity, setActivity] = useState(null);
  const [viewOpen, setViewOpen] = useState(false);
  const [data, setData] = useState(null);
  const { t } = useTranslation();
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });

  const activityReducer = useSelector(({ activity }) => activity);

  useEffect(() => {
    const q_str = qs.stringify(variables);
    History.push({ search: q_str });
    const vendor_id = Auth.id;
    console.log("Activities use effect ");
    console.log("Auth --->", Auth);
    console.log("variables --->", variables);
    console.log("vendor_id ---->", vendor_id);
    if (vendor_id) {
      dispatch(Actions.setActivityLoading(true));
      dispatch(Actions.getUpcomingMeetingActivites(variables, vendor_id));
    }
  }, [variables, Auth]);

  useEffect(() => {
    setActivity(activityReducer);
  }, [activityReducer]);

  if (activity && activity.error) {
    return <ErrorMessageComponents message="Cannot Fetch Activities" />;
  }

  if (activity === null || !activity.upcomingMeetingList) {
    return <CircularProgressComponent />;
  }

  const getUpcomingMeetingList = activity.loading ? (
    <div className="col-md-4 mt-2" style={{ height: "200px" }}>
      <Skeleton style={{ transform: "none" }} height={120} />
    </div>
  ) : activity.upcomingMeetingList &&
    activity.upcomingMeetingList.length > 0 ? (
    activity.upcomingMeetingList.map((item) => {
      return (
        <div className="row">
          <div className="col-md-12 mt-2 date-header">
            <p className="selected-date">
              {moment(item.date).utc(true).local().format("Do MMMM, YYYY")}
            </p>
          </div>
          {item.activities.map((item) => {
            return (
              <div className="col-md-4 mt-2 activity-block cursor-pointer">
                <div className="rct-block">
                  <div className="rct-block-content">
                    <div className="activity-header d-flex justify-content-between">
                      <div>
                        <p className="mb-0 text-capitalize">{item.title}</p>
                        <span>
                          {item.inventoryactivity
                            ? item.inventoryactivity.medicineinventory
                              ? item.inventoryactivity.medicineinventory.name
                              : item.inventoryactivity.name
                            : ""}
                        </span>
                      </div>
                      <p>
                        {/* {moment(item.time, tFrmt)
                                                    .utcOffset(item.tz)
                                                    .utc(true)
                                                    .local()
                                                    .format("hh:mm A")} */}
                        {moment(item.time, tFrmt)
                          .utc(true)
                          .local()
                          .format("hh:mm A")}
                      </p>
                    </div>
                    <div className="activity-status d-flex justify-content-between align-items-center mt-2">
                      <div
                        className="d-flex align-items-center"
                        onClick={() =>
                          History.push(
                            `/patient-profile/${
                              item.patientactivity.firstName +
                              " " +
                              item.patientactivity.lastName
                            }/${item.patientactivity.id}`
                          )
                        }
                      >
                        <Avatar src="" className="profile-pic mr-2" />
                        <p className="mb-0 mr-2">
                          {item.patientactivity
                            ? item.patientactivity.firstName +
                              " " +
                              item.patientactivity.lastName
                            : ""}
                        </p>
                        {/* {item.createdBy == "employee" && (
                                                    <div className="added-by-employee mr-2 mt-1">
                                                        <CheckCircleIcon className="text-success" />
                                                    </div>
                                                )} */}
                      </div>
                      {item.status == "done" ? (
                        <div className="taken">
                          <p className="mb-0">{t("taken")}</p>
                        </div>
                      ) : item.status == "missed" ? (
                        <div className="missed">
                          <p className="mb-0">{t("missed")}</p>
                        </div>
                      ) : item.status == "pending" ? (
                        <div className="in-time">
                          <p className="mb-0">{t("pending")}</p>
                        </div>
                      ) : item.status == "current" ? (
                        <div className="current">
                          <p className="mb-0 ">{t("in_time")}</p>
                        </div>
                      ) : null}
                    </div>
                    <div className="d-flex mt-4" style={{ fontSize: "0.8rem" }}>
                      <a
                        className="centrify-blue-color mr-2"
                        onClick={(e) => handleView(item)}
                      >
                        {t("view")}
                      </a>
                      {item.status === "current" && (
                        <a
                          className="centrify-blue-color mr-2"
                          onClick={(e) =>
                            History.push(
                              `meeting/${item.id}/${item.patientactivity.id}?tz=${item.tz}`
                            )
                          }
                        >
                          {t("join_meeting")}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    })
  ) : (
    <div
      className="col-md-12 align-items-center justify-content-center"
      style={{ height: "200px" }}
    >
      <p className="text-center mt-4 font-weight-bold">No Activities Found</p>
    </div>
  );

  const handleDelete = (item) => {
    setData(item);
    setDialogue(true);
  };

  const handleClose = () => {
    setData(null);
    setDialogue(!dialogue);
  };

  const handleFilter = () => {
    if (startDate && endDate)
      setVariables({ ...variables, dateRange: [startDate, endDate], page: 1 });
  };

  const removeFilter = () => {
    setStartDate(moment().format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));
    setVariables({
      ...variables,
      dateRange: [
        moment().format(`YYYY-MM-DD `),
        moment().format("YYYY-MM-DD"),
      ],
      page: 1,
    });
  };

  const handleView = (item) => {
    setData(item);
    setViewOpen(!viewOpen);
  };

  const getDateInText =
    startDate == endDate
      ? moment(startDate).format("DD MMMM, dddd")
      : moment(startDate).format("DD MMMM, dddd") +
        " - " +
        moment(endDate).format("DD MMMM, dddd");

  return (
    <div className="patient-profile ">
      <h1 className="main-heading d-flex">{t("meeting_activities")}</h1>
      <div className="d-flex justify-content-end align-items-center flex-wrap date-header">
        <div className="d-flex flex-wrap align-items-center date-range">
          <p className="mb-0">{t("from")}</p>
          <Input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <p className="mb-0">{t("to")}</p>
          <Input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />

          <Button color="primary" className="mr-2" onClick={handleFilter}>
            {t("apply_filters")}
          </Button>
          <Button color="danger" className="mr-2" onClick={removeFilter}>
            {t("remove_filters")}
          </Button>
        </div>
      </div>
      <div className=" activity-cards">
        <div>{getUpcomingMeetingList}</div>
      </div>
      <ViewActivity
        data={data}
        modal={viewOpen}
        toggle={() => setViewOpen(!viewOpen)}
      />
    </div>
  );
}
