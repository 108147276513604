import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { Button, Input } from "reactstrap";

export default function Notifications() {
  const top100Films = [
    { title: "The ", year: 1994 },
    { title: "The Godfather", year: 1972 },
    { title: "The Godfather: Part II", year: 1974 },
  ];

  let pictures = [
    "https://medlineplus.gov/images/Medicines.jpg",
    "https://images.theconversation.com/files/334114/original/file-20200511-49558-palxym.jpg?ixlib=rb-1.1.0&q=45&auto=format&w=1200&h=1200.0&fit=crop",
    "https://static.toiimg.com/photo/msid-74824961/74824961.jpg?219207",
    "https://static.toiimg.com/photo/msid-74824961/74824961.jpg?219207",
    "https://static.toiimg.com/photo/msid-74824961/74824961.jpg?219207",
    "https://static.toiimg.com/photo/msid-74824961/74824961.jpg?219207",
    "https://static.toiimg.com/photo/msid-74824961/74824961.jpg?219207",
    "https://static.toiimg.com/photo/msid-74824961/74824961.jpg?219207",
    "https://static.toiimg.com/photo/msid-74824961/74824961.jpg?219207",
  ];

  let chips = [
    "Healthcare Agency",
    "Care Agency",
    "Help Agency",
    "Help Agency",
  ];

  return (
    <div className="notifications">
      <div className="mt-4">
        <div className="rct-block">
          <div className="d-flex flex-wrap align-items-center border-bottom-centrify p-4">
            <p className="filter-type mb-0 mr-4">Type of Notification: </p>
            <div className="d-flex align-items-center mr-4">
              <input type="checkbox" className="mr-2" />
              <p className="mb-0 filter-type-text">email</p>
            </div>
            <div className="d-flex align-items-center mr-4">
              <input type="checkbox" className="mr-2" />
              <p className="mb-0 filter-type-text">sms</p>
            </div>
            <div className="d-flex align-items-center mr-4">
              <input type="checkbox" className="mr-2" />
              <p className="mb-0 filter-type-text">mobile app</p>
            </div>
          </div>
          <div className="p-4">
            <div className="row">
              <div className="col-md-8 mt-2">
                <div className="notification-box">
                  <div className="noti-header px-4 py-3">
                    <h1 className="mb-0">New Notification</h1>
                  </div>
                  <div className="mt-4 px-4 pb-4">
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      options={top100Films}
                      getOptionLabel={(option) => option.title}
                      defaultValue={[top100Films[0]]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="To"
                          className="multi-input"
                        />
                      )}
                    />
                    <Input type="text" className="mt-4" placeholder="Subject" />
                    <Input
                      className="mt-4"
                      type="textarea"
                      placeholder="Your message"
                    />
                    <div className="mt-4 d-flex justify-content-between">
                      <div></div>
                      <div className="d-flex align-items-center">
                        <Button
                          color="none"
                          className="primary-background-button-outline font-weight-bold mr-4"
                        >
                          {" "}
                          Schedule Send
                        </Button>
                        <Button
                          color="none"
                          className="primary-background-button-table font-weight-bold"
                        >
                          {" "}
                          Send Notification
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-1"></div> */}
              <div className="col-md-4 mt-2">
                <div className="recent-notifications">
                  <h1>Recently Sent Notifications</h1>
                  <div className="notification-box mt-4 px-4 py-3">
                    <div className="d-flex justify-content-between align-items-center flex-wrap border-bottom-centrify pb-2">
                      <p className="mb-0 noti-heading">
                        Recommendation about new pills
                      </p>
                      <p className="time mb-0">11:00</p>
                    </div>
                    <div className="mt-2">
                      <p className="content mb-0">
                        Although there has been substantial improvement in
                        atherosclerotic cardiovascular disease (ASCVD)...
                      </p>
                    </div>
                    <div className="row graph-section">
                      {pictures.map((item) => {
                        return (
                          <div className="col-md-2 col-3 mt-2 ">
                            <div className="pic-section p-1">
                              <img src={item} />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="d-flex justify-content-between flex-wrap">
                      {chips.map((item) => {
                        return (
                          <div className="mt-2 px-1 noti-chips">
                            <p className="mb-0">{item}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="notification-box mt-4 px-4 py-3">
                    <div className="d-flex justify-content-between align-items-center flex-wrap border-bottom-centrify pb-2">
                      <p className="mb-0 noti-heading">
                        Recommendation about new pills
                      </p>
                      <p className="time mb-0">11:00</p>
                    </div>
                    <div className="mt-2">
                      <p className="content mb-0">
                        Although there has been substantial improvement in
                        atherosclerotic cardiovascular disease (ASCVD)...
                      </p>
                    </div>
                    <div className="row graph-section">
                      {pictures.map((item) => {
                        return (
                          <div className="col-md-2 col-3 mt-2 ">
                            <div className="pic-section p-1">
                              <img src={item} />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="d-flex justify-content-between flex-wrap">
                      {chips.map((item) => {
                        return (
                          <div className="mt-2 px-1 noti-chips">
                            <p className="mb-0">{item}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
