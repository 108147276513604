import React from "react";
import moment from "moment";
function HeaderComponent(props) {
  let dataToShow = null;
  if (
    moment(props?.data.date).isSameOrAfter(props.dateInterval?.start, "day") &&
    moment(props?.data.date).isSameOrBefore(props.dateInterval?.end, "day")
  ) {
    var a = moment(props.dateInterval?.start);
    var b = moment(props?.data.date);
    let diff = b.diff(a, "days");

    dataToShow = `Day ${diff + 1}`;
  }

  return (
    <div
      onClick={(e) => {
        e.preventDefault();

        e.stopPropagation();
      }}
    >
      {dataToShow}
    </div>
  );
}

export default HeaderComponent;
