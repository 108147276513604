import * as Actions from '../actions'

const initialState = {
  loading: false,
  stateLoading: false,
  articleDetail: null,
  error: null,
  posted: false,
  articleList: null,
  articleCount: null
}

const articleReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.ARTICLE_LOADING: {
      return {
        ...state,
        loading: action.payload
      }
    }
    case Actions.ARTICLE_STATE_LOADING: {
      return {
        ...state,
        stateLoading: action.payload
      }
    }
    case Actions.ARTICLE_ERROR: {
      return {
        ...state,
        error: action.payload
      }
    }
    case Actions.ARTICLE_POSTED: {
      return {
        ...state,
        posted: true
      }
    }

    case Actions.GET_ALL_ARTICLES: {
      return {
        ...state,
        loading: false,
        error: null,
        posted: false,
        articleCount: action.payload.count,
        articlesList: action.payload.rows
      }
    }
    case Actions.GET_ARTICLE_DETAIL: {
      return {
        ...state,
        loading: false,
        error: false,
        articleDetail: action.payload
      }
    }
    default: {
      return state
    }
  }
}
export default articleReducer
