import * as Actions from "../actions";

const initialState = {
  loading: false,
  notificationList: [],
  meetingNotificationList: [],
  singleNotification: null,
  error: null,
};

const notificationReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.ADD_NOTIFICATION: {
      return {
        ...state,
        notificationList: [action.payload, ...state.notificationList],
        loading: false,
      };
    }
    case Actions.ADD_MEETING_NOTIFICATION: {
      return {
        ...state,
        meetingNotificationList: [...action.payload],
        loading: false,
      };
    }
    case Actions.GET_ALL_NOTIFICATIONS: {
      return {
        ...state,
        notificationList: action.payload.rows,
        loading: false,
      };
    }
    case Actions.API_RESPONSE_NOTIFICATION: {
      console.log("api response notification reducer @@@2", action.payload);
      return {
        ...state,
        singleNotification: action.payload,
      };
    }
    case Actions.NOTIFICATION_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
export default notificationReducer;
