import { Avatar } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Form, FormGroup, Input, Table } from 'reactstrap'
import Pagination from '@material-ui/lab/Pagination'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as Actions from 'store/actions'
import { v4 as uuidv4 } from 'uuid'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5)
  }
}))

export default function RecommendModalBody ({
  type,
  isOpen,
  questions,
  selectedQuesOptIndexes,
  setQuestions,
  toggle
}) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [variables, setVariables] = useState({ page: 1 })

  const { t } = useTranslation()

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false
  })
  const storeReducer = useSelector(({ store }) => store)
  const articleReducer = useSelector(({ articles }) => articles)

  useEffect(() => {
    let vendor_id = Auth.vendor_id
    if (isOpen) {
      if (type === 'product') {
        dispatch(Actions.setStoreLoading(true))
        dispatch(Actions.getStoreProducts(variables, vendor_id))
      } else if (type === 'article') {
        dispatch(Actions.setArticleLoading(true))
        dispatch(Actions.getArticles(variables, vendor_id))
      }
    }
  }, [variables])

  const handleProductSelect = item => {
    let qIndex = selectedQuesOptIndexes.qIndex
    let optIndex = selectedQuesOptIndexes.optIndex

    questions[qIndex].options[optIndex] = {
      ...questions[qIndex].options[optIndex],
      product: [
        {
          UUID: uuidv4(),
          product_id: item.id,
          name: item.title,
          image: item.productImages ? [item.productImages[0].image] : null
        }
      ]
    }
    setQuestions([...questions])
    toggle()
  }

  const handleArticleSelect = item => {
    let qIndex = selectedQuesOptIndexes.qIndex
    let optIndex = selectedQuesOptIndexes.optIndex
    questions[qIndex].options[optIndex] = {
      ...questions[qIndex].options[optIndex],
      article_id: item.id,
      article: [
        {
          UUID: uuidv4(),
          article_id: item.id,
          name: item.title,
          image: [item.image]
        }
      ]
    }
    setQuestions([...questions])
    toggle()
  }

  const getProductList = () => {
    if (!storeReducer || storeReducer?.loading) {
      return (
        <tr>
          <td colspan={3} className='text-center font-weight-bold'>
            <CircularProgress />
          </td>
        </tr>
      )
    } else if (storeReducer.error) {
      return (
        <tr>
          <td colspan={3} className='text-center font-weight-bold'>
            <div className='ml-4 d-flex align-items-center'>
              {t('cannot_fetch_products')}
            </div>
          </td>
        </tr>
      )
    } else if (!storeReducer?.loading) {
      if (storeReducer.product.rows && storeReducer.product.rows.length > 0) {
        return storeReducer.product.rows.map(item => {
          return (
            <tr>
              <td>
                <div
                  className='ml-2 d-flex align-items-center'
                  onClick={e => handleProductSelect(item)}
                >
                  <button className=' btn btn-link'>{t('pick')}</button>
                </div>
              </td>

              <td>
                <div className='ml-4 d-flex align-items-center'>{item.id}</div>
              </td>
              <td>
                <div className='ml-4 d-flex align-items-center'>
                  <Avatar
                    className='mr-3 medicine-pic'
                    src={
                      item.productImages ? item.productImages[0]?.image : null
                    }
                  />{' '}
                  <p className='mb-0 mr-4'>{item.title}</p>
                </div>
              </td>
            </tr>
          )
        })
      } else if (
        storeReducer.product.rows &&
        storeReducer.product.rows.length === 0
      ) {
        return (
          <tr>
            <td colspan={3} className='text-center font-weight-bold'>
              {t('nothing_to_display')}
            </td>
          </tr>
        )
      }
    }
  }

  const getArticleList = () => {
    if (!articleReducer || articleReducer?.loading) {
      return (
        <tr>
          <td colspan={3} className='text-center font-weight-bold'>
            <CircularProgress />
          </td>
        </tr>
      )
    } else if (articleReducer.error) {
      return (
        <tr>
          <td colspan={3} className='text-center font-weight-bold'>
            <div className='ml-4 d-flex align-items-center'>
              {t('cannot_fetch_articles')}
            </div>
          </td>
        </tr>
      )
    } else if (!articleReducer.loading) {
      if (articleReducer.articlesList && articleReducer.articleCount > 0) {
        return articleReducer.articlesList.map(article => {
          return (
            <tr>
              <td>
                <div
                  className='ml-2 d-flex align-items-center'
                  onClick={e => handleArticleSelect(article)}
                >
                  <button className=' btn btn-link'>{t('pick')}</button>
                </div>
              </td>
              <td>
                <div className='ml-4 d-flex align-items-center'>
                  {article.id}
                </div>
              </td>
              <td>
                <div className='ml-4 d-flex align-items-center'>
                  {article.title}
                </div>
              </td>
            </tr>
          )
        })
      } else if (articleReducer.articleCount === 0) {
        return (
          <tr>
            <td colspan={3} className='text-center font-weight-bold'>
              {t('nothing_to_display')}
            </td>
          </tr>
        )
      }
    }
  }

  const getPagination = () => {
    let reducerRef = type === 'article' ? articleReducer : storeReducer
    let countRef =
      type === 'article'
        ? articleReducer.articleCount
        : storeReducer.product.count
    return reducerRef && countRef > 0 ? (
      <div className='mt-4 d-flex justify-content-center'>
        <Pagination
          count={Math.ceil(countRef / 10)}
          page={variables.page}
          onChange={handlePageChange}
          variant='outlined'
          shape='rounded'
        />
      </div>
    ) : null
  }

  const handlePageChange = (e, pagee) => {
    setVariables({
      ...variables,
      page: pagee
    })
  }

  const handleSearch = e => {
    e.preventDefault()
    let search = document.getElementById('searchh').value
    setVariables({
      ...variables,
      title: search,
      page: 1
    })
  }

  const handleChipDelete = () => {
    document.getElementById('searchh').value = ''
    setVariables({
      ...variables,
      title: '',
      page: 1
    })
  }
  return (
    <div className=''>
      <div className='mt-4'>
        {variables.title && (
          <Chip
            label={variables.title}
            onDelete={() => handleChipDelete()}
            className={classes.chip}
          />
        )}
        <div className='d-flex justify-content-between align-items-end table-top-bar'>
          <div className='d-flex align-items-center filters'>
            <Form className='mt-2 mt-sm-0' onSubmit={e => handleSearch(e)}>
              <FormGroup className='d-inline-block gift-search-input'>
                <Input
                  type='text'
                  name='value'
                  id='searchh'
                  maxLength={10}
                  placeholder='search'
                />
                <button type='submit' className='gift-search-icon'>
                  <i className='zmdi zmdi-search centrify-blue-color'></i>
                </button>
              </FormGroup>
            </Form>
          </div>
        </div>
        <div className='mt-4' style={{ position: 'relative' }}>
          <Table responsive className='main-table'>
            <thead className='main-table-header'>
              <tr>
                <th>
                  <span className='ml-4 mb-0 d-flex'> {t('pick')}</span>
                </th>
                <th>
                  <span className='ml-4 mb-0 d-flex'>{t('id')}</span>
                </th>
                <th>
                  <span className='ml-4 mb-0 d-flex'>{t('name')}</span>
                </th>
              </tr>
            </thead>
            <tbody className='main-table-body'>
              {type === 'product' ? getProductList() : getArticleList()}
            </tbody>
          </Table>
        </div>
      </div>
      {getPagination()}
    </div>
  )
}
