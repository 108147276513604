import React from "react";
import { Input, Button } from "reactstrap";
import { useTranslation } from "react-i18next";

const EditQuestion = ({ questionsArray, setQuestionsArray, ques, index, handleRemoveQuestion, handleRemoveOption }) => {
    const { t } = useTranslation();

    return (
        <div className="rct-block p-3">
            <div className='row d-flex justify-content-center'>
                <div className="col-sm-12 col-md-9 d-flex justify-content-start align-items-center">
                    <h4 className="font-weight-bold text-black p-0 m-0">{`Q${index + 1}`}:</h4>
                    <input id="ques-title" type="textarea" name="title" defaultValue={ques.title}
                        onChange={(e) => {
                            questionsArray[index].title = e.target.value;
                            setQuestionsArray([...questionsArray]);
                        }}
                        className="w-100 border-0 bg-transparent questionaire-title font-weight-bold" rows={1} required />
                </div>
                <div className="col-sm-12 col-md-3 d-flex">
                    <Input
                        type="select"
                        name="questionType"
                        defaultValue={ques.questionType}
                        onChange={(e) => {
                            let opIdsArray = [];
                            if (e.target.value === 'TEXT') {
                                questionsArray[index].options.map(op => opIdsArray.push(op.id));
                                handleRemoveOption(opIdsArray);
                                questionsArray[index].options = [];
                            }
                            if (ques.questionType === 'TEXT' && e.target.value !== 'TEXT') {
                                const newOptions = [{ option: "new option", value: false }, { option: "new option", value: false }];
                                questionsArray[index].options = [...newOptions];
                            }
                            questionsArray[index].questionType = e.target.value;
                            setQuestionsArray([...questionsArray]);
                        }}
                        required
                    >
                        <option value="MCQ">Mcq</option>
                        <option value="TEXT">Text</option>
                        <option value="MultiSelect">MultiSelect</option>
                    </Input>
                    {/* <img
                        src={require("assets/icons/remove.png")}
                        alt=""
                        className="centrify-table-icons cursor-pointer mt-5"
                        onClick={() => handleRemoveQuestion(ques.id, index)}
                    /> */}
                </div>
                {ques.questionType === 'MCQ' || ques.questionType === 'MultiSelect' ? <>
                    <div className="col-sm-12 mt-4 d-flex flex-column align-items-start">
                        {questionsArray[index].options.length > 0 && questionsArray[index].options.map((el, i) => (
                            <div className="d-flex w-75 flex-row justify-content-center align-items-center">
                                <div className="text-black p-0 mt-3">{`${i + 1}`}-</div>
                                <input
                                    type="text"
                                    className="w-100 border-0 bg-transparent mt-3 ml-2"
                                    placeholder={`option ${i + 1}`}
                                    name="option"
                                    id={`option${i}`}
                                    value={`${questionsArray[index].options[i].option}`}
                                    defaultValue={el.option}
                                    required
                                    onChange={(e) => {
                                        questionsArray[index].options[i].option = e.target.value;
                                        setQuestionsArray([...questionsArray]);
                                    }}
                                />
                                {i > 1 ?
                                    <img
                                        src={require("assets/icons/close.png")}
                                        onClick={() => {
                                            if (questionsArray[index].options[i].id) {
                                                handleRemoveOption([questionsArray[index].options[i].id]);
                                            }
                                            questionsArray[index].options.splice(i, 1);
                                            setQuestionsArray([...questionsArray]);
                                        }}
                                        alt=""
                                        className="ques-option-icon cursor-pointer pl-3 mt-3"
                                    /> : null}
                                <div className="p-0 m-0" id={`TooltipExample${el.id}`}>
                                    {questionsArray[index].options[i].important ?
                                        <img
                                            src={require("assets/icons/filled-star.png")}
                                            onClick={(e) => {
                                                questionsArray[index].options[i].important = !(questionsArray[index].options[i].important);
                                                setQuestionsArray([...questionsArray]);
                                            }}
                                            alt=""
                                            className="ques-option-icon cursor-pointer p-0 mt-3"
                                        /> : <img
                                            src={require("assets/icons/Unfilled-star.png")}
                                            onClick={(e) => {
                                                questionsArray[index].options[i].important = !(questionsArray[index].options[i].important);
                                                setQuestionsArray([...questionsArray]);
                                            }}
                                            alt=""
                                            className="ques-option-icon cursor-pointer p-0 mt-3"
                                        />}
                                    {/* <Tooltip placement="right" isOpen={tooltipOpen} target={`TooltipExample${el.id}`} toggle={() => setTooltipOpen(!tooltipOpen)}>

                                        {options[index].important ? <> Remove Important!</> : <>Make Important</>}
                                    </Tooltip> */}
                                </div>
                            </div>
                        ))}
                        <div className='text-underlined mt-3 link-button cursor-pointer' onClick={() => {
                            questionsArray[index].options.push({ option: "new option", value: false });
                            // = ([...questionsArray[index].options[i].option, { option: "new option", value: false }]);
                            setQuestionsArray([...questionsArray]);
                        }}><u>add another option</u></div>
                    </div>
                </> : null}
                <div className="col-sm-12 mt-4 d-flex justify-content-end">
                    <Button
                        onClick={() => handleRemoveQuestion(ques.id, index)}
                        color="none"
                        className="primary-background-button-table font-weight-bold"
                    >
                        {t("delete")}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default EditQuestion;
