import * as Actions from "../actions";

const initialState = {
  loading: false,
  assignLoading: false,
  stateloading: false,
  error: null,
  tabError: null,
  posted: false,
  engagementList: null,
  engagement: null,
  treeQuestionnaire: null,
  treeQuestionnaireList: null,
  treeQCount: 0,
  tabContentListCount: 0,
  tabContentList: 0,
  tabContentType: null,
};

const engagementReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.ENGAGEMENT_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case Actions.ENGAGEMENT_STATE_LOADING: {
      return {
        ...state,
        stateloading: action.payload,
      };
    }

    case Actions.ENGAGEMENT_ASSIGN_LOADING: {
      return {
        ...state,
        assignLoading: action.payload,
      };
    }
    case Actions.GET_ENGAGEMENT_BY_ID: {
      return {
        ...state,
        loading: false,
        error: null,
        tabError: null,
        engagement: action.payload,
      };
    }
    case Actions.GET_ENGAGEMENT_BY_ID_EDIT: {
      return {
        ...state,
        editEngagement: action.payload,
        loading: false,
      };
    }
    case Actions.GET_ENGAGEMENT_TREE_QUESTIONNAIRES: {
      return {
        ...state,
        treeQuestionnaireList: action.payload.rows,
        treeQCount: action.payload.count,
      };
    }
    case Actions.GET_ENGAGEMENT_PATIENTS: {
      return {
        ...state,
        patientList: action.payload.rows,
        patientCount: action.payload.count,
        loading: false,
        tabError: null,
      };
    }

    case Actions.ENGAGEMENT_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false,
        tabContentList: null,
        tabContentList: null,
      };
    }
    case Actions.ENGAGEMENT_TAB_ERROR: {
      return {
        ...state,
        tabError: action.payload,
        stateloading: false,
        loading: false,
        tabContentList: null,
        tabContentList: null,
      };
    }
    case Actions.CLEAR_ENGAGEMENT: {
      return {
        ...state,
        engagement: null,
      };
    }
    case Actions.ENGAGEMENT_POSTED: {
      return {
        ...state,
        assignLoading: false,
        stateloading: false,

        posted: action.payload,
      };
    }
    case Actions.GET_ALL_ENGAGEMENTS: {
      return {
        ...state,
        loading: false,
        error: null,
        tabError: null,
        engagementList: action.payload.rows,
        count: action.payload.count,
      };
    }
    case Actions.GET_ENGAGEMENT_TAB_DATA: {
      return {
        ...state,
        loading: false,
        stateloading: false,
        tabError: null,
        error: null,
        tabContentList: action.payload.rows,
        tabContentListCount: action.payload.count,
        tabContentType: action.payload.tabContentType,
      };
    }
    case Actions.GET_ENGAGEMENT_TAB_DATA_DETAIL: {
      return {
        ...state,
        tabContentDetail: action.payload,
        loading: false,
        stateloading: false,
      };
    }
    case Actions.TAB_CONTENT_NULL: {
      return {
        ...state,
        tabContentList: null,
        tabContentListCount: null,
        tabContentDetail: null,
      };
    }

    default: {
      return state;
    }
  }
};
export default engagementReducer;
