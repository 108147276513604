import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, ModalBody, Button } from 'reactstrap'
import { useDispatch } from 'react-redux'
import * as Actions from 'store/actions'
import { AiOutlinePlayCircle, AiFillFile } from 'react-icons/ai'
import ConfrimDialogue from 'components/Dialogue/ConfrimDialogue'

const ViewDocumentation = props => {
  const dispatch = useDispatch()
  const { item, open, toggle, patient_id } = props
  const { t } = useTranslation()
  const [dialogue, setDialogue] = useState(false)

  const handleDelete = () => {
    dispatch(Actions.setPatientDocumentLoading(true))
    dispatch(Actions.deleteDocument(item.id, patient_id))
    setDialogue(!dialogue)
    toggle()
  }

  const handleClose = () => {
    setDialogue(!dialogue)
  }

  return (
    <div>
      <Modal isOpen={open} toggle={toggle} className='modal-background'>
        <ModalBody>
          <div className='row d-flex justify-content-center documentation-modal'>
            <div className='col-md-12'>
              <div className='d-flex mt-5 mb-2 border-bottom bottom-muted align-items-center justify-content-between'>
                <div className='my-0 h5 font-14 font-weight-bolder text-capitalize font-weight-bold text-truncate'>
                  <span>{item?.title}</span>
                </div>
                <span className='alert alert-success font-weight-normal p-0 px-1 m-0 text-truncate h5 font-14'>
                  <span className='font-weight-bold'>
                    {item?.doctor?.firstName} {item?.doctor?.lastName}
                  </span>
                </span>
              </div>
              <div className='text-capitalize d-flex mb-1'>
                <div className='d-flex flex-column'>
                  <div></div>
                  <h6 className='text-muted mb-2 font-10'>
                    {/* {date.getDate(createdAt)} */}
                    {t('posted_on')}: <span className=''>{item.date}</span>
                  </h6>
                </div>
              </div>
              <div className='col-sm-12 col-md-12 m-0 p-0'>
                <p className='mb-1 mt-4 font-14'>{item.description}</p>
              </div>{' '}
              <div className='row m-0 pd-top-40'>
                {JSON.parse(item.files) && JSON.parse(item.files).length > 0 ? (
                  <>
                    {JSON.parse(item.files).map((el, index) =>
                      el.type == 'image' ? (
                        <div className='col-sm-6 col-md-4 img-div'>
                          <img
                            className='w-100 cursor-pointer'
                            src={el.File}
                            alt=''
                            onClick={e => window.open(el.File)}
                          />
                        </div>
                      ) : el.type == 'video' ? (
                        <div className='col-sm-6 col-md-4 img-div'>
                          <a href={`${el.File}`} download>
                            <AiOutlinePlayCircle style={{ fontSize: '32px' }} />
                          </a>
                        </div>
                      ) : el.type == 'file' ? (
                        <div className='col-sm-6 col-md-4 img-div'>
                          <a href={`${el.File}`} download>
                            <AiFillFile style={{ fontSize: '32px' }} />
                          </a>
                        </div>
                      ) : null
                    )}
                  </>
                ) : null}
              </div>
              <div className='d-flex justify-content-end mg-top-40'>
                <Button
                  onClick={() => setDialogue(!dialogue)}
                  color='none'
                  className='primary-background-button-table font-weight-bold'
                >
                  {' '}
                  {t('delete_documentation')}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <ConfrimDialogue
        open={dialogue}
        handleClose={() => handleClose()}
        action={() => handleDelete()}
        msg='Are you sure you want to delete this Document?'
      />
    </div>
  )
}

export default ViewDocumentation
