import React, { useEffect, useState } from "react";
import { Button, Table, Input, Label } from "reactstrap";
import Pagination from "@material-ui/lab/Pagination";
import { Avatar } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "store/actions";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";

export default function InventoryFilterModel(props) {
  const dispatch = useDispatch();
  const [inventory, setInventory] = useState(null);
  const [medicineName, setMedicineName] = useState(null);
  const [dialogue, setDialogue] = useState(false);
  const [inventoryId, setInventoryId] = useState(null);
  const [medicineId, setMecicineId] = useState(null);
  const { t } = useTranslation();

  const medicineReducer = useSelector(({ medicine }) => medicine);

  let medicineData = medicineReducer?.getMedicineForOrder?.rows;

  useEffect(() => {
    let patient_id = props.patient_id;
    let vendor_id = props.vendor_id;
    let data = {
      patient_id: patient_id,
      vendor_id: vendor_id,
    };
    dispatch(Actions.setInventoryLoading(true));
    dispatch(Actions.getMedicineForOrder(data));
  }, []);

  useEffect(() => {
    setInventory(medicineReducer);
  }, [medicineReducer]);

  if (inventory && inventory?.error) {
    return <ErrorMessageComponents message="Cannot Fetch Inventory" />;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let obj = {};
    const formData = new FormData(e.target);
    for (let [key, value] of formData.entries()) {
      obj[key] = value;
    }
    obj.patient_id = Number(props.patient_id);
    obj.amount = Number(obj.amount);
    obj.inventory_id = inventoryId;
    obj.medicine_id = medicineId;

    dispatch(Actions.setPlaceOrderLoading(true));
    dispatch(
      Actions.placeMediOrderByAdmin(
        obj,
        props?.variables,
        props?.vendor_id,
        props?.patient_id,
        props.toggle
      )
    );
    setDialogue(false);
  };

  if (inventory === null || !inventory.getMedicineForOrder) {
    return <CircularProgressComponent />;
  }

  const getInventoryList =
    medicineData?.length > 0 ? (
      medicineData?.map((item) => {
        return (
          <tr>
            <td>
              <div className="ml-2 d-flex align-items-center">
                <Avatar
                  src={
                    item.inventoryimages && item.inventoryimages.length > 0
                      ? `${item.inventoryimages[0].image}`
                      : ""
                  }
                  className="mr-3 medicine-pic"
                />{" "}
                <p className="mb-0 mr-2 prominent">{item.name}</p>
              </div>
            </td>
            {/* medicineinventory */}
            <td>
              {item?.medicineinventory ? (
                <img
                  src={require("assets/icons/green-tick.png")}
                  alt=""
                  className="centrify-table-icons"
                />
              ) : (
                <img
                  src={require("assets/icons/red-cross.png")}
                  alt=""
                  className="centrify-table-icons"
                />
              )}
            </td>
            <td>
              {item?.medicineinventory ? item?.medicineinventory?.amount : 0}
            </td>

            <td>
              {item?.medicineinventory
                ? item?.medicineinventory.quantity > 0
                  ? Math.ceil(
                      item?.medicineinventory.amount /
                        item?.medicineinventory.quantity
                    )
                  : 0
                : 0}
            </td>
            <td>
              {item?.medicineinventory ? item?.medicineinventory.quantity : 0}
            </td>
            <td>
              <Button
                onClick={() => {
                  setMedicineName(item.name);
                  setInventoryId(item?.medicineinventory?.id);
                  setMecicineId(item.id);
                  setDialogue(!dialogue);
                }}
                color="link"
              >
                {" "}
                {t("order")}
              </Button>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colspan={3} className="text-center font-weight-bold">
          {t("nothing_to_display")}
        </td>
      </tr>
    );
  // const handlePageChange = (e, page) => {
  //     setVariables({
  //         ...variables,
  //         page: page,
  //     });
  // };
  return (
    <div className="patient-profile">
      {dialogue ? (
        <div className="p-4">
          <div className="d-flex justify-content-start">
            <img
              onClick={() => setDialogue(false)}
              className="centrify-icons cursor-pointer"
              src={require("assets/icons/back.png")}
            />
          </div>
          <div className="mt-2 form-styles pd-top-40">
            <h2 className="form-heading d-flex">{medicineName}</h2>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="mt-4">
                <Label>{t("amount")}</Label>
                <Input
                  type="number"
                  name="amount"
                  min="0"
                  oninput="validity.valid||(value='');"
                  required
                />
              </div>
              <div className="mt-4 d-flex justify-content-end">
                <Button
                  type="submit"
                  className="primary-background-button-table"
                  color="none"
                >
                  {t("place_order")}
                </Button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="mt-4" style={{ position: "relative" }}>
          {/* <TableLoader /> */}
          <Table
            responsive
            style={{ boxShadow: "0px" }}
            className="main-table"
            className="border-0"
          >
            <thead className="main-table-header" className="border-0">
              <tr className="border-0">
                <th>
                  <span className="d-flex ml-4 mb-0">{t("name")}</span>
                </th>
                <th>{t("inventory")}</th>
                <th>{t("remaining_pills")}</th>
                <th>{t("remaining_boxes")}</th>
                <th>{t("qty_per_box")}</th>
                <th className="">{t("")}</th>
              </tr>
            </thead>
            <tbody className="main-table-body border-0">
              {getInventoryList}
            </tbody>
          </Table>
        </div>
      )}
      {/*inventory.inventoryCount > 0 && (
        <div className="mt-4 d-flex justify-content-center">
          <Pagination
            count={Math.ceil(inventory.inventoryCount / 2)}
            onChange={(e, pg) => handlePageChange(e, pg)}
            variant="outlined"
            shape="rounded"
          />
        </div>
    )*/}
      {/* <PlaceOrderModal
                open={dialogue}
                toggle={() => {
                    setInventoryId(null);
                    setDialogue(!dialogue);
                }}
                patient_id={props.patient_id}
                inventory_id={inventoryId}
            /> */}
    </div>
  );
}
