import BreadCrumbComponent from 'components/Breadcrumbs'
import React, { useEffect, useState } from 'react'
import Details from './detail'
import Patient from './patient'
import Stats from './stats/stats'
import { useTranslation } from 'react-i18next'
import * as Actions from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'
import ErrorMessageComponents from 'components/ErrorHandlers/ErrorMessageComponents'
import CircularProgressComponent from 'components/ErrorHandlers/CircularProgressComponent'
import History from '@history'

export default function QuestionaireDetails (props) {
  const dispatch = useDispatch()
  const [value, setValue] = useState(0)
  const [viewQuestionnaire, setViewQuestionnaire] = useState(null)
  const { t } = useTranslation()
  const questionaireReducer = useSelector(({ questionaire }) => questionaire)

  useEffect(() => {
    if (props.match.params.id) {
      dispatch(Actions.setQuestionairesLoading(true))
      dispatch(Actions.getSingleTreeQuestionnaire(props.match.params.id))
    }
    // Clearing up questionaire detail
    return () => {
      dispatch(Actions.clearQuestionaire())
    }
  }, [props.match.params.id])
  console.log('viewQuestionnaire --->', viewQuestionnaire)
  useEffect(() => {
    if (questionaireReducer.treeQuestionnaire) {
      setViewQuestionnaire(questionaireReducer.treeQuestionnaire)
    }
  }, [questionaireReducer.treeQuestionnaire])

  if (questionaireReducer.error) {
    return (
      <ErrorMessageComponents message={t('cannot_get_questionnaire_details')} />
    )
  }

  if (questionaireReducer === null || questionaireReducer.loading) {
    return <CircularProgressComponent />
  }

  let tabs = [t('view'), t('patients'), t('report')]

  return (
    <div>
      <div className=''>
        <BreadCrumbComponent
          active={questionaireReducer?.treeQuestionnaire?.title}
          previous={[
            { name: t('all_questionnaires'), link: '/treeQuestionnaires' }
          ]}
        />
      </div>
      <div className=' d-flex flex-wrap align-items-center tabs'>
        {tabs.map((item, index) => {
          return (
            <p
              onClick={e => {
                History.push({ search: null })
                setValue(index)
              }}
              className={`${
                index == value ? 'active-tab' : ''
              } mb-0 margin-tabs mt-2 cursor-pointer`}
            >
              {item}
            </p>
          )
        })}
      </div>
      <div className='mt-4'>
        {value == 0 ? (
          <div className=''>
            <Details questionnaire={viewQuestionnaire} />
          </div>
        ) : value == 1 ? (
          <div className=''>
            <Patient questionaireId={props.match.params.id} {...props} />
          </div>
        ) : value == 2 ? (
          <div className=''>
            <Stats questionaireId={props.match.params.id} />
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}
