import * as Actions from "../actions";

const initialState = {
  loading: false,
  impLoading: false,
  error: null,
  missedActivities: null,
  importantList: null,
  importantCount: 0,
  missedActCount: 0,
  importantTreeList: 0,
  importantTreeCount: 0,
};

const reportsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.REPORTS_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case Actions.REPORTS_IMP_LOADING: {
      return {
        ...state,
        impLoading: action.payload,
      };
    }
    case Actions.REPORTS_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.GET_MISSED_ACTIVITIES: {
      return {
        ...state,
        loading: false,
        error: null,
        missedActivities: action.payload.rows,
        missedActCount: action.payload.count,
      };
    }
    case Actions.GET_IMPORTANT_SELECTED: {
      return {
        ...state,
        impLoading: false,
        error: null,
        importantList: action.payload.rows,
        importantCount: action.payload.count,
      };
    }
    case Actions.GET_IMPORTANT_TREE_SELECTED: {
      return {
        ...state,
        impLoading: false,
        error: null,
        importantTreeList: action.payload.rows,
        importantTreeCount: action.payload.count,
      };
    }
    default: {
      return state;
    }
  }
};
export default reportsReducer;
