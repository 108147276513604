import React, { useState, useEffect } from "react";
import { Avatar } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Actions from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import History from "@history";
import ConfrimDialogue from "components/Dialogue/ConfrimDialogue";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import { Form, FormGroup, Input, Table } from "reactstrap";
import TableLoader from "components/ErrorHandlers/TableLoader";
import Pagination from "@material-ui/lab/Pagination";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import AssignToPatientModal from "../patientModal";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function Patients(props) {
  const dispatch = useDispatch();
  const { engagement } = props;
  const classes = useStyles();

  const [data, setData] = useState(null);
  const [dialogue, setDialogue] = useState(false);
  const [patientModalState, setPatientModalState] = useState(false);
  const [patientModalType, setPatientModalType] = useState("patient");
  const [patients, setPatients] = useState([]);
  const [patientCount, setPatientCount] = useState(0);

  const engagementReducer = useSelector(({ engagement }) => engagement);

  console.log("Patients --->", patients);
  const [variables, setVariables] = useState({ page: 1 });
  const { t } = useTranslation();

  useEffect(() => {
    if (props.match.params.id) {
      dispatch(Actions.setEngagementStateLoading(true));
      dispatch(Actions.getEngagementPatients(props.match.params.id, variables));
    }
    return () => {
      dispatch(Actions.setTabContentNull());
    };
  }, [props.match.params.tab, variables]);

  useEffect(() => {
    if (engagementReducer) {
      if (engagementReducer.posted) {
        setPatientModalState(false);
        setPatientModalType("patient");
      }
      if (
        engagementReducer &&
        engagementReducer.tabContentList &&
        engagementReducer.tabContentType == "patients"
      ) {
        setPatients(engagementReducer.tabContentList);
        setPatientCount(engagementReducer.tabContentListCount);
      }
      if (engagementReducer.error) {
        setPatients([]);
        setPatientCount(0);
      }
    }
  }, [engagementReducer]);
  useEffect(() => {
    if (patientModalState == false) {
      setPatientModalType("patient");
    }
  }, [patientModalState]);

  const getPatientList = () => {
    if (patients && patients.length > 0) {
      return patients.map((item) => {
        return (
          <tr>
            <td>
              <div
                onClick={() =>
                  History.push(
                    `/patient-profile/${
                      item?.patient?.firstName + " " + item?.patient?.lastName
                    }/${item?.patient?.id}`
                  )
                }
                className="ml-4 d-flex align-items-center cursor-pointer"
              >
                <Avatar
                  className="mr-3 medicine-pic"
                  src={item?.patient?.image}
                />{" "}
                <p className="mb-0 prominent mr-4">
                  {item?.patient?.firstName + " " + item?.patient?.lastName}
                </p>
              </div>
            </td>
            <td>{item?.patient?.type}</td>
            <td>{moment(item?.assignDate).local().format("DD-MM-YYYY")}</td>
            <td>
              {item.cancelledDate
                ? moment(item?.cancelledDate).local().format("DD-MM-YYYY")
                : null}
            </td>
            <td>
              {item.status == "active"
                ? t("active")
                : item.status == "cancelled"
                ? t("cancelled")
                : t("inActive")}
            </td>
            <td
              className={`cursor-pointer ${
                item?.status == "active" ? "centrify-blue-color" : null
              }  `}
              onClick={() => {
                if (item?.status == "active") {
                  setData(item);
                  setDialogue(true);
                }
              }}
            >
              {t("cancel")}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan={7} className="text-center font-weight-bold">
            {t("nothing_to_display")}
          </td>
        </tr>
      );
    }
  };

  const handlePageChange = (e, page) => {
    setVariables({
      ...variables,
      page: page,
    });
  };
  const handleCancel = () => {
    if (engagement.id && data) {
      dispatch(Actions.setEngagementStateLoading(true));
      dispatch(Actions.cancelPatientEngagment(data.id, engagement.id));
      setDialogue(!dialogue);
      setData(null);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let search = document.getElementById("project-search").value;
    setVariables({
      ...variables,
      _name: search,
      page: 1,
    });
  };
  const handleChipDelete = () => {
    document.getElementById("project-search").value = "";
    setVariables({
      ...variables,
      _name: "",
      page: 1,
    });
  };
  const filterChange = (e) => {
    console.log("Value --->", e.target.value);
    setVariables({
      ...variables,
      status: e.target.value,
    });
  };

  if (engagementReducer.tabError) {
    return <ErrorMessageComponents message={t("cannot_get_patients")} />;
  }

  if (engagementReducer === null) {
    return <CircularProgressComponent />;
  }

  return (
    <div>
      <div className="mt-4">
        <div className="d-flex justify-content-end">
          <div
            className="primary-background-button-table font-weight-bold"
            onClick={() => {
              setPatientModalState(true);
            }}
          >
            {t("add_patient")}
          </div>
        </div>

        <div className="rct-block mg-top-40">
          <div className="d-flex justify-content-between align-items-center border-bottom-centrify p-4">
            <div className="d-flex flex-column w-100">
              <div className="mb-2">
                {variables._name && (
                  <Chip
                    label={variables._name}
                    onDelete={() => handleChipDelete()}
                    className={classes.chip}
                  />
                )}
              </div>
              <div className="d-flex justify-content-between align-items-end table-top-bar ">
                <div className="d-flex align-items-center filters">
                  <Form
                    className="mt-2 mt-sm-0"
                    onSubmit={(e) => handleSearch(e)}
                  >
                    <FormGroup className="d-inline-block gift-search-input">
                      <Input
                        type="text"
                        name="value"
                        id="project-search"
                        maxLength={10}
                        placeholder={t("search")}
                        style={{ paddingRight: "40px" }}
                      />
                      <button type="submit" className="gift-search-icon">
                        <i className="zmdi zmdi-search centrify-blue-color"></i>
                      </button>
                    </FormGroup>
                  </Form>
                  <FormGroup className="d-inline-block gift-search-input ml-3">
                    <Input
                      name="filter"
                      type="select"
                      required
                      className="border_centrify_blue_color"
                      onChange={filterChange}
                    >
                      <option value={"all"}>All</option>
                      <option value={"active"}>Active</option>
                      <option value={"inactive"}>InActive</option>
                      <option value={"cancelled"}>Cancelled</option>
                    </Input>
                  </FormGroup>
                </div>
              </div>

              <div className="mt-4" style={{ position: "relative" }}>
                {engagementReducer.stateloading && <TableLoader />}
                <Table responsive className="main-table">
                  <thead className="main-table-header">
                    <tr>
                      <th>
                        <span className="ml-4 mb-0 d-flex">{t("name")}</span>
                      </th>

                      <th>{t("type")}</th>
                      <th>{t("date")}</th>
                      <th>{t("cancelled_date")}</th>
                      <th>{t("status")}</th>
                      <th>{t("actions")}</th>
                    </tr>
                  </thead>
                  <tbody className="main-table-body">{getPatientList()}</tbody>
                </Table>
              </div>

              {patientCount > 0 ? (
                <div className="mt-4 d-flex justify-content-center">
                  <Pagination
                    count={Math.ceil(patientCount / 10)}
                    page={variables.page}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <AssignToPatientModal
        {...props}
        engagementId={props.match.params.id}
        modal={patientModalState}
        setPatientModalType={setPatientModalType}
        patientModalType={patientModalType}
        patients={null}
        toggle={() => {
          setPatientModalType("patient");
          setPatientModalState(!patientModalState);
        }}
        viewType={"detail_view"}
      />
      <ConfrimDialogue
        open={dialogue}
        handleClose={() => setDialogue(false)}
        action={() => handleCancel()}
        msg="Are you sure you want to cancel engagement patient ?"
      />
    </div>
  );
}
