import * as Actions from "../actions";

const initialState = {
  loading: false,
  reportLoading: false,
  statusLoading: false,
  missedNotiStatusLoading: false,
  placeOrderLoading: false,
  details: null,
  error: null,
  posted: false,
  docPosted: false,
  docCount: 0,
  docLoading: false,
  docList: false,
  patientList: null,
  patientCount: null,
  patientInventory: null,
  patientEngagement: null,
  patientEngagementCount: 0,
};

const patientReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.PATIENT_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case Actions.PATIENT_DOCUMENT_LOADING: {
      return {
        ...state,
        docLoading: action.payload,
      };
    }
    case Actions.PATIENT_PLACE_ORDER_LOADING: {
      return {
        ...state,
        placeOrderLoading: action.payload,
      };
    }
    case Actions.PATIENT_STATUS_LOADING: {
      return {
        ...state,
        statusLoading: action.payload,
      };
    }
    case Actions.PATIENT_MISSED_NOTI_STATUS_LOADING: {
      return {
        ...state,
        missedNotiStatusLoading: action.payload,
      };
    }
    case Actions.DOCUMENTATION_POSTED: {
      return {
        ...state,
        docPosted: action.payload,
      };
    }
    case Actions.PATIENT_REPORT_LOADING: {
      return {
        ...state,
        reportLoading: action.payload,
      };
    }
    case Actions.PATIENT_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }
    case Actions.GET_PATIENT_INVENTORY: {
      return {
        ...state,
        patientInventory: action.payload.rows,
        docLoading: false,
      };
    }
    case Actions.GET_PATIENT_ENGAGEMENT: {
      return {
        ...state,
        patientEngagement: action.payload.rows,
        patientEngagementCount: action.payload.count,
        docLoading: false,
      };
    }
    case Actions.GET_PATIENT_PROJECT: {
      return {
        ...state,
        patientProject: action.payload.rows,
        patientProjectCount: action.payload.count,
        docLoading: false,
      };
    }
    case Actions.GET_PATIENT_MEDIA: {
      return {
        ...state,
        docLoading: false,
        patientMedia: action.payload.rows,
        docCount: action.payload.count,
      };
    }
    case Actions.PATIENT_POSTED: {
      return {
        ...state,
        posted: action.payload,
      };
    }
    case Actions.GET_ALL_PATIENTS: {
      return {
        ...state,
        loading: false,
        error: null,
        patientCount: action.payload.count,
        patientList: action.payload.rows,
      };
    }
    case Actions.GET_ALL_DOCUMENTATIONS: {
      return {
        ...state,
        docLoading: false,
        error: null,
        docCount: action.payload.count,
        docList: action.payload.rows,
      };
    }
    case Actions.PATIENT_PROFILE: {
      return {
        ...state,
        loading: false,
        statusLoading: false,
        missedNotiStatusLoading: false,
        error: null,
        details: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
export default patientReducer;
