import React, { useState } from "react";
import { Button, Form, FormGroup, Input, Table } from "reactstrap";
import { Avatar } from "@material-ui/core";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import TableLoader from "components/ErrorHandlers/TableLoader";
import { useTranslation } from "react-i18next";
import AssignmentIcon from "@material-ui/icons/Assignment";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import ViewActivity from "../projects/patient/viewActivity";
import History from "@history";
import ProjectsModal from "../stats/projectsModal";
import FilterListIcon from "@material-ui/icons/FilterList";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function Patients(props) {
  const {
    variables,
    setVariables,
    reports,
    page,
    tFrmt,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  } = props;

  const [viewOpen, setViewOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const classes = useStyles();

  const { t } = useTranslation();

  if (reports?.missedActivities && reports.error) {
    return <ErrorMessageComponents message="Cannot Fetch Reports" />;
  }

  if (reports?.missedActivities === null || reports.loading) {
    return <CircularProgressComponent />;
  }

  const getReportsList =
    reports.missedActivities && reports.missedActivities.length > 0 ? (
      reports.missedActivities.map((item) => {
        return (
          <tr>
            <td>
              {item.project_id === null ? (
                <div
                  onClick={() =>
                    History.push(
                      `/patient-profile/${item?.patientactivity?.id}`
                    )
                  }
                  className="ml-4 d-flex align-items-center cursor-pointer"
                >
                  <Avatar
                    className="mr-3 medicine-pic"
                    src={item?.patientactivity?.image}
                  />{" "}
                  <p className="mb-0 prominent mr-4">
                    {item?.patientactivity?.firstName}
                  </p>
                </div>
              ) : (
                <div
                  onClick={() =>
                    History.push(
                      `/patientProject/${
                        item?.patientactivity?.firstName +
                        " " +
                        item?.patientactivity?.lastName
                      }/${item?.patientactivity?.id}/${item?.project_id}`
                    )
                  }
                  className="ml-4 d-flex align-items-center cursor-pointer"
                >
                  <Avatar
                    className="mr-3 medicine-pic"
                    src={item?.patientactivity?.image}
                  />{" "}
                  <p className="mb-0 prominent mr-4">
                    {item?.patientactivity?.firstName}
                  </p>
                </div>
              )}
            </td>
            <td>{`${item?.title}`}</td>
            <td>
              {item?.type === "medicine" ? (
                <>Medication</>
              ) : item?.type === "meeting" ? (
                <>Doctor Meeting</>
              ) : item?.type === "supplements" ? (
                <>Supplements</>
              ) : item?.type === "face-to-face" ? (
                <>Face To Face</>
              ) : (
                <>Other</>
              )}
            </td>
            <td>{`${item?.employeeactivity?.firstName}`}</td>
            <td>
              {moment(item.time, tFrmt).utc(true).local().format("hh:mm A")}
              {/* {moment.utc(item ?.createdAt).format('MMM DD, YYYY')} */}
            </td>
            <td>
              {moment(item.date, "YYYY-MM-DD HH:mm:ss")
                .utcOffset(item.tz)
                .utc(true)
                .local()
                .format("YYYY-MM-DD")}
            </td>
            <td>
              <a onClick={(e) => handleView(item)}>View</a>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colspan={7} className="text-center font-weight-bold">
          {t("nothing_to_display")}
        </td>
      </tr>
    );

  const handlePageChange = (e, page) => {
    let dates = [];
    if (variables.dateRange && variables.dateRange.length > 0) {
      dates = [startDate, endDate];
    }
    setVariables({
      ...variables,
      dateRange: dates,
      page: page,
    });
  };

  const itemPicked = (id) => {
    let dates = [];
    if (variables.dateRange && variables.dateRange.length > 0) {
      dates = [startDate, endDate];
    }
    setVariables({ ...variables, project_id: id, dateRange: dates, page: 1 });
    setOpen(!open);
  };

  const allPicked = () => {
    let dates = [];
    if (variables.dateRange && variables.dateRange.length > 0) {
      dates = [startDate, endDate];
    }
    setVariables({ ...variables, project_id: null, dateRange: dates, page: 1 });
    setOpen(!open);
  };

  const handleFilter = () => {
    if (startDate && endDate)
      setVariables({ ...variables, dateRange: [startDate, endDate], page: 1 });
  };

  const removeFilter = () => {
    setStartDate(moment().format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));
    setVariables({
      ...variables,
      dateRange: [
        // moment().format(`YYYY-MM-DD `),
        // moment().format("YYYY-MM-DD"),
      ],
      page: 1,
    });
  };

  const handleView = (item) => {
    setData(item);
    setViewOpen(!viewOpen);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let search = document.getElementById("report-search").value;
    let dates = [];
    if (variables.dateRange && variables.dateRange.length > 0) {
      dates = [startDate, endDate];
    }
    setVariables({
      ...variables,
      search,
      page: 1,
      dateRange: dates,
    });
  };
  const handleDelete = () => {
    document.getElementById("report-search").value = "";
    let dates = [];
    if (variables.dateRange && variables.dateRange.length > 0) {
      dates = [startDate, endDate];
    }
    setVariables({
      ...variables,
      search: "",
      page: 1,
      dateRange: dates,
    });
  };
  return (
    <div>
      <div>
        {variables.search && (
          <Chip
            label={variables.search}
            onDelete={() => handleDelete()}
            className={classes.chip}
          />
        )}
      </div>
      <div className="d-flex flex-wrap align-items-center justify-content-between">
        <div className="d-flex align-items-center filters">
          <Form className="mt-2 mt-sm-0" onSubmit={(e) => handleSearch(e)}>
            <FormGroup className="d-inline-block gift-search-input">
              <Input
                type="text"
                name="title"
                id="report-search"
                defaultValue={variables.search}
                maxLength={10}
                placeholder={t("search")}
                style={{ paddingRight: "40px" }}
              />
              <button type="submit" className="gift-search-icon">
                <i className="zmdi zmdi-search centrify-blue-color"></i>
              </button>
            </FormGroup>
          </Form>
        </div>
        <div className="d-flex align-items-center filters mt-4 mr-4">
          <p className="filter-text mb-0 mr-2">{t("filter_by_project")}</p>
          {variables && variables.project_id ? (
            <div className="mr-2">
              <span className="filter-text primary-color font-weight-bold">
                {variables.project_id}
              </span>
            </div>
          ) : (
            <div className="mr-2">
              <span className="filter-text primary-color">All</span>
            </div>
          )}
          <FilterListIcon
            className="cursor-pointer mr-2"
            onClick={() => setOpen(!open)}
            style={{ fontSize: "0.9rem", marginTop: "4px" }}
          />
        </div>
      </div>
      <div className="mt-4 patient-profile orders">
        <div className="rct-block">
          <div className="rct-block-content p-0">
            <div className="d-flex flex-wrap justify-content-end date-header p-4">
              <div className="d-flex justify-content-end align-items-center flex-wrap date-header">
                <div className="d-flex flex-wrap align-items-center date-range">
                  <p className="mb-0">{t("from")}</p>
                  <Input
                    type="date"
                    className="bg-transparent border-0"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                  <p className="mb-0">{t("to")}</p>
                  <Input
                    type="date"
                    className="bg-transparent border-0"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                  <Button
                    color="primary"
                    className="mr-2"
                    onClick={handleFilter}
                  >
                    {t("apply_filters")}
                  </Button>
                  <Button
                    color="danger"
                    className="mr-2"
                    onClick={removeFilter}
                  >
                    {t("remove_filters")}
                  </Button>
                </div>
              </div>
            </div>
            {reports.loading && <TableLoader />}
            <Table responsive className="main-table">
              <thead className="main-table-header">
                <tr>
                  <th>
                    <span className="ml-4 mb-0 d-flex">
                      {t("patient_name")}
                    </span>
                  </th>
                  <th>{t("activity_title")}</th>
                  <th>{t("type")}</th>
                  <th>{t("assigned_by")}</th>
                  <th>{t("time")}</th>
                  <th>{t("date")}</th>
                  <th>{t("view")}</th>
                  {/* <th className=''>{t("date")}</th>
                                    <th className=''>{t("status")}</th> */}
                </tr>
              </thead>
              <tbody className="main-table-body">{getReportsList}</tbody>
            </Table>
          </div>
        </div>
      </div>
      {reports?.missedActCount > 0 ? (
        <div className="mt-4 d-flex justify-content-center">
          <Pagination
            count={Math.ceil(reports?.missedActCount / 10)}
            page={page}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
          />
        </div>
      ) : null}

      <ProjectsModal
        {...props}
        modal={open}
        toggle={() => setOpen(!open)}
        itemPicked={itemPicked}
        allPicked={allPicked}
      />
      <ViewActivity
        data={data}
        modal={viewOpen}
        toggle={() => setViewOpen(!viewOpen)}
      />
    </div>
  );
}
