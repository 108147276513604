import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import InventoryFilterModal from "components/FilterModals/InventoryFilterModal";

const InventoryModal = (props) => {
  console.log(props);
  return (
    <div>
      <Modal
        style={{ maxWidth: "750px" }}
        isOpen={props.open}
        toggle={props.toggle}
      >
        <ModalHeader
          style={{ borderBottom: "0px", paddingBottom: "0px" }}
          toggle={props.toggle}
        >
          <h1>Place Order</h1>
        </ModalHeader>
        <ModalBody className="p-0">
          <InventoryFilterModal
            patient_id={props.patient_id}
            toggle={props.toggle}
            variables={props?.variables}
            vendor_id={props?.vendor_id}
          />
        </ModalBody>
        <ModalFooter>
          {/* <Button color="secondary" onClick={props.toggle}>
                        Cancel
          </Button> */}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default InventoryModal;
