import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";

const PieChart = (props) => {
  console.log("Pie Chart props --->", props);
  const [data, setData] = useState({
    labels: ["totalSolved", "totalSelected"],
    datasets: [
      {
        data: [100, 30],
        backgroundColor: ["#FFB70F", "#5D92F4"],
        hoverBackgroundColor: ["#FFB70F", "#5D92F4"],
      },
    ],
  });

  useEffect(() => {
    let countObject = {};
    props.options.map((op) => {
      countObject[`${op.option}`] = op.optionCount;
    });
    let labels = [];
    let data = [];
    let object = countObject;
    for (const property in object) {
      labels.push(property);
      data.push(object[property]);
    }
    setData({
      labels,
      datasets: [
        {
          data,
          backgroundColor: [
            "#FFB70F",
            "#5D92F4",
            "#00D0BD",
            "black",
            "red",
            "pink",
            "gray",
            "#71ade9",
            "#828b94",
            "#2b3035",
            "#47745f",
            "#79764c",
          ],
          hoverBackgroundColor: ["#FFB70F", "#5D92F4", "#00D0BD"],
        },
      ],
    });
  }, [props]);
  const options = {
    legend: {
      display: true,
      labels: { fontColor: "#AAAEB3" },
      position: "left",
    },
    tooltips: {
      enabled: true,
      callbacks: {
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var total = dataset.data.reduce(function (
            previousValue,
            currentValue,
            currentIndex,
            array
          ) {
            return previousValue + currentValue;
          });
          var currentValue = dataset.data[tooltipItem.index];
          var percentage = Math.floor((currentValue / total) * 100 + 0.5);
          return percentage + "%";
        },
        title: function (tooltipItem, data) {
          return data.labels[tooltipItem[0].index];
        },
      },
    },
  };
  return <Pie data={data} options={options} />;
};

export default PieChart;
