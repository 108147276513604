export const timeZoneList = [
  { label: "Australian Central Daylight Saving Time", value: "+10:00" },
  { label: "Australian Central Standard Time", value: "+09:00" },
  { label: "Acre Time", value: "−05:00" },
  {
    label: "Australian Central Western Standard Time (unofficial)",
    value: "+08:00",
  },
  { label: "Atlantic Daylight Time", value: "−03:00" },
  { label: "Australian Eastern Daylight Saving Time", value: "+11:00" },
  { label: "Australian Eastern Standard Time", value: "+10:00" },
  { label: "Afghanistan Time", value: "+04:00" },
  { label: "Alaska Daylight Time", value: "−08:00" },
  { label: "Alaska Standard Time", value: "−09:00" },
  { label: "Alma-Ata Time[1]", value: "+06:00" },
  { label: "Amazon Summer Time (Brazil)[2]", value: "−03:00" },
  { label: "Amazon Time (Brazil)[3]", value: "−04:00" },
  { label: "Armenia Time", value: "+04:00" },
  { label: "Anadyr Time[4]", value: "+12:00" },
  { label: "Aqtobe Time[5]", value: "+05:00" },
  { label: "Argentina Time", value: "−03:00" },
  { label: "Arabia Standard Time", value: "+03:00" },
  { label: "Atlantic Standard Time", value: "−04:00" },
  { label: "Australian Western Standard Time", value: "+08:00" },
  { label: "Azores Summer Time", value: "±00:00" },
  { label: "Azores Standard Time", value: "−01:00" },
  { label: "Azerbaijan Time", value: "+04:00" },
  { label: "Brunei Time", value: "+08:00" },
  { label: "British Indian Ocean Time", value: "+06:00" },
  { label: "Baker Island Time", value: "−12:00" },
  { label: "Bolivia Time", value: "−04:00" },
  { label: "Brasília Summer Time", value: "−02:00" },
  { label: "Brasília Time", value: "−03:00" },
  { label: "Bangladesh Standard Time", value: "+06:00" },
  { label: "Bougainville Standard Time[6]", value: "+11:00" },
  {
    label:
      "British Summer Time (British Standard Time from Feb 1968 to Oct 1971)",
    value: "+01:00",
  },
  { label: "Bhutan Time", value: "+06:00" },
  { label: "Central Africa Time", value: "+02:00" },
  { label: "Cocos Islands Time", value: "+06:00" },
  { label: "Central Daylight Time (North America)", value: "−05:00" },
  { label: "Cuba Daylight Time[7]", value: "−04:00" },
  { label: "Central European Summer Time (Cf. HAEC)", value: "+02:00" },
  { label: "Central European Time", value: "+01:00" },
  { label: "Chatham Daylight Time", value: "+13:00" },
  { label: "Chatham Standard Time", value: "+12:00" },
  { label: "Choibalsan Standard Time", value: "+08:00" },
  { label: "Choibalsan Summer Time", value: "+09:00" },
  { label: "Chamorro Standard Time", value: "+10:00" },
  { label: "Chuuk Time", value: "+10:00" },
  { label: "Clipperton Island Standard Time", value: "−08:00" },
  { label: "Cook Island Time", value: "−10:00" },
  { label: "Chile Summer Time", value: "−03:00" },
  { label: "Chile Standard Time", value: "−04:00" },
  { label: "Colombia Summer Time", value: "−04:00" },
  { label: "Colombia Time", value: "−05:00" },
  { label: "Central Standard Time (North America)", value: "−06:00" },
  { label: "China Standard Time", value: "+08:00" },
  { label: "Cuba Standard Time", value: "−05:00" },
  { label: "Cape Verde Time", value: "−01:00" },
  {
    label: "Central Western Standard Time (Australia) unofficial",
    value: "+08:00",
  },
  { label: "Christmas Island Time", value: "+07:00" },
  { label: "Davis Time", value: "+07:00" },
  { label: "Dumont d'Urville Time", value: "+10:00" },
  {
    label: "AIX-specific equivalent of Central European Time[NB 1]",
    value: "+01:00",
  },
  { label: "Easter Island Summer Time", value: "−05:00" },
  { label: "Easter Island Standard Time", value: "−06:00" },
  { label: "East Africa Time", value: "+03:00" },
  { label: "Eastern Caribbean Time (does not recognise DST)", value: "−04:00" },
  { label: "Ecuador Time", value: "−05:00" },
  { label: "Eastern Daylight Time (North America)", value: "−04:00" },
  { label: "Eastern European Summer Time", value: "+03:00" },
  { label: "Eastern European Time", value: "+02:00" },
  { label: "Eastern Greenland Summer Time", value: "±00:00" },
  { label: "Eastern Greenland Time", value: "−01:00" },
  { label: "Eastern Standard Time (North America)", value: "−05:00" },
  { label: "Further-eastern European Time", value: "+03:00" },
  { label: "Fiji Time", value: "+12:00" },
  { label: "Falkland Islands Summer Time", value: "−03:00" },
  { label: "Falkland Islands Time", value: "−04:00" },
  { label: "Fernando de Noronha Time", value: "−02:00" },
  { label: "Galápagos Time", value: "−06:00" },
  { label: "Gambier Islands Time", value: "−09:00" },
  { label: "Georgia Standard Time", value: "+04:00" },
  { label: "French Guiana Time", value: "−03:00" },
  { label: "Gilbert Island Time", value: "+12:00" },
  { label: "Gambier Island Time", value: "−09:00" },
  { label: "Greenwich Mean Time", value: "±00:00" },
  {
    label: "South Georgia and the South Sandwich Islands Time",
    value: "−02:00",
  },
  { label: "Gulf Standard Time", value: "+04:00" },
  { label: "Guyana Time", value: "−04:00" },
  { label: "Hawaii–Aleutian Daylight Time", value: "−09:00" },
  {
    label: "Heure Avancée d'Europe Centrale French-language name for CEST",
    value: "+02:00",
  },
  { label: "Hawaii–Aleutian Standard Time", value: "−10:00" },
  { label: "Hong Kong Time", value: "+08:00" },
  { label: "Heard and McDonald Islands Time", value: "+05:00" },
  { label: "Hovd Summer Time (not used from 2017-present)", value: "+08:00" },
  { label: "Hovd Time", value: "+07:00" },
  { label: "Indochina Time", value: "+07:00" },
  { label: "International Day Line West time zone", value: "−12:00" },
  { label: "Israel Daylight Time", value: "+03:00" },
  { label: "Indian Ocean Time", value: "+03:00" },
  { label: "Iran Daylight Time", value: "+04:00" },
  { label: "Irkutsk Time", value: "+08:00" },
  { label: "Iran Standard Time", value: "+03:00" },
  { label: "Indian Standard Time", value: "+05:00" },
  { label: "Irish Standard Time[8]", value: "+01:00" },
  { label: "Israel Standard Time", value: "+02:00" },
  { label: "Japan Standard Time", value: "+09:00" },
  { label: "Kaliningrad Time", value: "+02:00" },
  { label: "Kyrgyzstan Time", value: "+06:00" },
  { label: "Kosrae Time", value: "+11:00" },
  { label: "Krasnoyarsk Time", value: "+07:00" },
  { label: "Korea Standard Time", value: "+09:00" },
  { label: "Lord Howe Standard Time", value: "+10:00" },
  { label: "Lord Howe Summer Time", value: "+11:00" },
  { label: "Line Islands Time", value: "+14:00" },
  { label: "Magadan Time", value: "+12:00" },
  { label: "Marquesas Islands Time", value: "−09:00" },
  { label: "Mawson Station Time", value: "+05:00" },
  { label: "Mountain Daylight Time (North America)", value: "−06:00" },
  { label: "Middle European Time (same zone as CET)", value: "+01:00" },
  { label: "Middle European Summer Time (same zone as CEST)", value: "+02:00" },
  { label: "Marshall Islands Time", value: "+12:00" },
  { label: "Macquarie Island Station Time", value: "+11:00" },
  { label: "Marquesas Islands Time", value: "−09:00" },
  { label: "Myanmar Standard Time", value: "+06:00" },
  { label: "Moscow Time", value: "+03:00" },
  { label: "Malaysia Standard Time", value: "+08:00" },
  { label: "Mountain Standard Time (North America)", value: "−07:00" },
  { label: "Mauritius Time", value: "+04:00" },
  { label: "Maldives Time", value: "+05:00" },
  { label: "Malaysia Time", value: "+08:00" },
  { label: "New Caledonia Time", value: "+11:00" },
  { label: "Newfoundland Daylight Time", value: "−02:00" },
  { label: "Norfolk Island Time", value: "+11:00" },
  { label: "Novosibirsk Time [9]", value: "+07:00" },
  { label: "Nepal Time", value: "+05:00" },
  { label: "Newfoundland Standard Time", value: "−03:00" },
  { label: "Newfoundland Time", value: "−03:00" },
  { label: "Niue Time", value: "−11:00" },
  { label: "New Zealand Daylight Time", value: "+13:00" },
  { label: "New Zealand Standard Time", value: "+12:00" },
  { label: "Omsk Time", value: "+06:00" },
  { label: "Oral Time", value: "+05:00" },
  { label: "Pacific Daylight Time (North America)", value: "−07:00" },
  { label: "Peru Time", value: "−05:00" },
  { label: "Kamchatka Time", value: "+12:00" },
  { label: "Papua New Guinea Time", value: "+10:00" },
  { label: "Phoenix Island Time", value: "+13:00" },
  { label: "Philippine Time", value: "+08:00" },
  { label: "Pakistan Standard Time", value: "+05:00" },
  { label: "Saint Pierre and Miquelon Daylight Time", value: "−02:00" },
  { label: "Saint Pierre and Miquelon Standard Time", value: "−03:00" },
  { label: "Pohnpei Standard Time", value: "+11:00" },
  { label: "Pacific Standard Time (North America)", value: "−08:00" },
  { label: "Philippine Standard Time", value: "+08:00" },
  { label: "Palau Time[10]", value: "+09:00" },
  { label: "Paraguay Summer Time[11]", value: "−03:00" },
  { label: "Paraguay Time[12]", value: "−04:00" },
  { label: "Réunion Time", value: "+04:00" },
  { label: "Rothera Research Station Time", value: "−03:00" },
  { label: "Sakhalin Island Time", value: "+11:00" },
  { label: "Samara Time", value: "+04:00" },
  { label: "South African Standard Time", value: "+02:00" },
  { label: "Solomon Islands Time", value: "+11:00" },
  { label: "Seychelles Time", value: "+04:00" },
  { label: "Samoa Daylight Time", value: "−10:00" },
  { label: "Singapore Time", value: "+08:00" },
  { label: "Sri Lanka Standard Time", value: "+05:00" },
  { label: "Srednekolymsk Time", value: "+11:00" },
  { label: "Suriname Time", value: "−03:00" },
  { label: "Samoa Standard Time", value: "−11:00" },
  { label: "Singapore Standard Time", value: "+08:00" },
  { label: "Showa Station Time", value: "+03:00" },
  { label: "Tahiti Time", value: "−10:00" },
  { label: "Thailand Standard Time", value: "+07:00" },
  { label: "French Southern and Antarctic Time[13]", value: "+05:00" },
  { label: "Tajikistan Time", value: "+05:00" },
  { label: "Tokelau Time", value: "+13:00" },
  { label: "Timor Leste Time", value: "+09:00" },
  { label: "Turkmenistan Time", value: "+05:00" },
  { label: "Turkey Time", value: "+03:00" },
  { label: "Tonga Time", value: "+13:00" },
  { label: "Tuvalu Time", value: "+12:00" },
  { label: "Ulaanbaatar Summer Time", value: "+09:00" },
  { label: "Ulaanbaatar Standard Time", value: "+08:00" },
  { label: "Coordinated Universal Time", value: "±00:00" },
  { label: "Uruguay Summer Time", value: "−02:00" },
  { label: "Uruguay Standard Time", value: "−03:00" },
  { label: "Uzbekistan Time", value: "+05:00" },
  { label: "Venezuelan Standard Time", value: "−04:00" },
  { label: "Vladivostok Time", value: "+10:00" },
  { label: "Volgograd Time", value: "+04:00" },
  { label: "Vostok Station Time", value: "+06:00" },
  { label: "Vanuatu Time", value: "+11:00" },
  { label: "Wake Island Time", value: "+12:00" },
  { label: "West Africa Summer Time", value: "+02:00" },
  { label: "West Africa Time", value: "+01:00" },
  { label: "Western European Summer Time", value: "+01:00" },
  { label: "Western European Time", value: "±00:00" },
  { label: "Western Indonesian Time", value: "+07:00" },
  { label: "Eastern Indonesian Time", value: "+09:00" },
  { label: "Central Indonesia Time", value: "+08:00" },
  { label: "West Greenland Summer Time[14]", value: "−02:00" },
  { label: "West Greenland Time[15]", value: "−03:00" },
  { label: "Western Standard Time", value: "+08:00" },
  { label: "Yakutsk Time", value: "+09:00" },
  { label: "Yekaterinburg Time", value: "+05:00" },
];
