import BreadCrumbComponent from 'components/Breadcrumbs'
import React, { useEffect, useState } from 'react'
import ProductDetail from './productDetail'
import ProductAssignment from './productAssignments'

import { useTranslation } from 'react-i18next'

import History from '@history'

export default function Detail (props) {
  const [value, setValue] = useState(0)
  const [productId, setProductId] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    if (props.match.params.productId) {
      setProductId(props.match.params.productId)
    }
  }, [props.match.params.productId])

  useEffect(() => {
    if (props.match.params.tab) {
      setValue(props.match.params.tab)
    }
  }, [props.match.params.tab])

  let tabs = [t('detail'), t('assignments')]
  //   'product/:productName/:productId/:tab',
  return (
    <div>
      <div className=''>
        <BreadCrumbComponent
          active={props.match.params.productName}
          previous={[{ name: t('products'), link: '/products' }]}
        />
      </div>

      <div className=' d-flex flex-wrap align-items-center tabs'>
        {tabs.map((item, index) => {
          return (
            <p
              onClick={e => {
                History.push(
                  `/product/${props.match.params.productName}/${props.match.params.productId}/${index}`
                )
              }}
              className={`${
                index == value ? 'active-tab' : ''
              } mb-0 margin-tabs mt-2 cursor-pointer`}
            >
              {item}
            </p>
          )
        })}
      </div>
      <div className='mt-4'>
        {value == 0 ? (
          <div className=''>
            <ProductDetail productId={productId} {...props} />
          </div>
        ) : value == 1 ? (
          <div className=''>
            <ProductAssignment productId={productId} {...props} />
          </div>
        ) : null}
      </div>
    </div>
  )
}
