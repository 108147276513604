import { Avatar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Lightbox from "react-image-lightbox";
import { useTranslation } from "react-i18next";
import "react-image-lightbox/style.css";
import moment from "moment";
import History from "@history";

const ViewActivity = (props) => {
  let tFrmt = "HH:mm:ss";
  const [data, setData] = useState(null);
  const [ackFiles, setAckFiles] = useState(null);
  const [open, setOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const { t } = useTranslation();
  const images = [
    "//placekitten.com/1500/500",
    "//placekitten.com/4000/3000",
    "//placekitten.com/800/1200",
    "//placekitten.com/1500/1500",
  ];

  useEffect(() => {
    if (props.data) {
      console.log("viewactivity", props.data);
      if (props.data.doseFiles !== null && props.data.doseFiles.length > 0) {
        // console.log("inside condition", JSON.parse(props.data.doseFiles[0].files.videoUrl));
        // const newData = JSON.parse(props.data.doseFiles[0].files);
        // console.log(newData);
        // console.log(JSON.parse(newData));
        setAckFiles(JSON.parse(props.data.doseFiles[0].files));
        // setAckFiles(JSON.parse(newData));
      } else {
        setAckFiles(null);
      }
      setData(props.data);
    } else {
      setData(null);
    }
  }, [props]);

  return (
    <div>
      <Modal isOpen={props.modal} toggle={props.toggle}>
        <ModalBody>
          <div className="mt-2 activity-cards px-4">
            <div className="activity-header d-flex justify-content-between">
              <div>
                <p className="mb-0 text-capitalize">{data && data.title}</p>
                <span>
                  {data && data.inventoryactivity
                    ? data.inventoryactivity.medicineinventory
                      ? data.inventoryactivity.medicineinventory.name
                      : data.inventoryactivity.name
                    : ""}
                </span>
              </div>
              <p>
                {data &&
                  data.time &&
                  moment(data.time, tFrmt).utc(true).local().format("hh:mm A")}
              </p>
            </div>
            <div className="activity-status d-flex justify-content-between align-items-center mt-2">
              <div
                className="d-flex align-items-center"
                onClick={() =>
                  History.push(
                    `/patient-profile/${
                      data.patientactivity.firstName +
                      " " +
                      data.patientactivity.lastName
                    }/${data.patientactivity.id}`
                  )
                }
              >
                <Avatar src="" className="profile-pic mr-2" />
                <p className="mb-0 mr-2">
                  {data && data.patientactivity
                    ? data.patientactivity.firstName +
                      " " +
                      data.patientactivity.lastName
                    : ""}
                </p>
              </div>
              {data && data.status == "done" ? (
                <div className="taken">
                  {/* <img
                    src={require("assets/icons/circle-tick.png")}
                    alt=""
                    className="mr-1"
                    style={{ height: "16px" }}
                  /> */}
                  <p className="mb-0">{t("taken")}</p>
                </div>
              ) : data && data.status == "missed" ? (
                <div className="missed">
                  <p className="mb-0">{t("missed")}</p>
                </div>
              ) : data && data.status == "pending" ? (
                <div className="in-time">
                  <p className="mb-0">{t("pending")}</p>
                </div>
              ) : data && data.status == "current" ? (
                <div className="current">
                  <p className="mb-0 ">In Time</p>
                </div>
              ) : null}
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <p className="font-weight-bold d-flex">{t("information")}</p>
                {data &&
                  data.type !== "meeting" &&
                  data.type !== "other" &&
                  data.dose && (
                    <div className="d-flex flex-wrap justify-content-between">
                      <p>{t("dose")}</p>
                      <p className="font-weight-bold">
                        {data && data.dose && data.dose}
                      </p>
                    </div>
                  )}
                {data && data.takeIt && (
                  <div className="d-flex flex-wrap justify-content-between">
                    <p>{t("take_it")}</p>
                    <p className="font-weight-bold text-capitalize">
                      {data && data.takeIt && data.takeIt}
                    </p>
                  </div>
                )}
                {data && data.time && (
                  <div className="d-flex justify-content-between">
                    <p>{t("time")}</p>
                    <p className="font-weight-bold text-capitalize">
                      {data &&
                        data.time &&
                        moment(data.time, tFrmt)
                          .utc(true)
                          .local()
                          .format("hh:mm A")}
                    </p>
                  </div>
                )}
                {data && data.date && (
                  <div className="d-flex flex-wrap justify-content-between">
                    <p>{t("date")}</p>
                    <p className="font-weight-bold">
                      {data &&
                        data.date &&
                        moment(data.date, "YYYY-MM-DD HH:mm:ss")
                          .utc(true)
                          .local()
                          .format("YYYY-MM-DD")}
                    </p>
                  </div>
                )}
                {data && data.info && (
                  <div className="d-flex justify-content-between">
                    <p>{t("information")}</p>
                    <p className="font-weight-bold text-capitalize">
                      {data && data.info && data.info}
                    </p>
                  </div>
                )}
                {data && data.project && (
                  <div className="d-flex justify-content-between">
                    <p>{t("project")}</p>
                    <p className="font-weight-bold text-capitalize">
                      {data?.project?.name}
                    </p>
                  </div>
                )}
              </div>
            </div>
            {data &&
              data.createdBy &&
              data.createdBy == "employee" &&
              data.status == "done" &&
              data.type !== "meeting" && (
                <div className="row mt-4">
                  <div className="col-md-12">
                    <p className="font-weight-bold">Verification</p>
                    <div className="d-flex justify-content-between">
                      <p>Person Identified</p>
                      <div className="added-by-employee mr-2 mt-1">
                        <img
                          src={require("assets/icons/green-tick.png")}
                          alt=""
                          className="centrify-table-icons"
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p>Pills Taken</p>
                      <div className="added-by-employee mr-2 mt-1">
                        <img
                          src={require("assets/icons/green-tick.png")}
                          alt=""
                          className="centrify-table-icons"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            <div className="row mt-4">
              {data &&
                data.activityfiles &&
                data.activityfiles.length > 0 &&
                data.activityfiles.map((item) => {
                  if (item.type == "audio")
                    return (
                      <div className="col-md-12">
                        <p className="font-weight-bold">
                          {t("audio_recording")}
                        </p>
                        <audio controls className="w-100">
                          <source src={item.file} />
                        </audio>
                      </div>
                    );
                })}
            </div>
            {data && ackFiles !== null && ackFiles !== undefined ? (
              <div className="row mt-4">
                <div className="col-md-12">
                  <p className="font-weight-bold">
                    {t("Acknowledgement_Media")}
                  </p>
                </div>
                {ackFiles.images.map((img) => (
                  <>
                    <div
                      className="col-md-6 d-flex justify-content-center row cursor-pointer mb-2 animate__animated animate__fadeIn p-0 mx-0"
                      key={img.id}
                    >
                      <div className="h5 w-100 font-weight-bold text-center">
                        {t(`Dose_${img.dose}`)}
                      </div>
                      <div className="d-flex flex-row">
                        <div className="col-md-6 img-div">
                          <img
                            onClick={(e) => window.open(img.image1)}
                            src={img.image1}
                            width="100%"
                            alt="qwe"
                          />
                        </div>
                        <div className="col-md-6 img-div">
                          <img
                            onClick={(e) => window.open(img.image2)}
                            src={img.image2}
                            width="100%"
                            alt="qwe"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ))}
                {ackFiles.videoUrl !== undefined ? (
                  <>
                    <div className="col-md-12 h5 mt-4 font-weight-bold text-center">
                      {t(`Video`)}
                    </div>
                    <div
                      className="col-md-12 d-flex justify-content-center cursor-pointer mb-2 animate__animated animate__fadeIn"
                      key={"8798"}
                    >
                      <video width="100%" height="200" controls>
                        <source src={ackFiles.videoUrl} type="video/mp4" />
                      </video>
                    </div>
                  </>
                ) : null}
              </div>
            ) : null}
            <div className="row mt-4">
              <div className="col-md-12">
                <p className="font-weight-bold">{t("activity_files")}</p>
              </div>
              {data && data.activityfiles && data.activityfiles.length > 0
                ? data.activityfiles.map(
                    (img) =>
                      img.type !== "audio" && (
                        <div
                          className="col-md-3 cursor-pointer mb-2 animate__animated animate__fadeIn"
                          key={img.id}
                        >
                          <div className="img-div d-flex flex-column">
                            {img.type == "image" ? (
                              <img
                                onClick={(e) => window.open(img.file)}
                                src={img.file}
                                width="100%"
                                alt="qwe"
                              />
                            ) : img.type == "video" ? (
                              <video onClick={(e) => window.open(img.file)}>
                                <source src={img.file} />
                              </video>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      )
                  )
                : null}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="none"
            className="primary-background-button-table font-weight-bold"
            onClick={props.toggle}
          >
            {t("close")}
          </Button>
        </ModalFooter>
      </Modal>
      {open && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[photoIndex + 1]}
          prevSrc={images[photoIndex - 1]}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() => setPhotoIndex(photoIndex - 1)}
          onMoveNextRequest={() => setPhotoIndex(photoIndex + 1)}
        />
      )}
    </div>
  );
};

export default ViewActivity;
