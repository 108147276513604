import React from 'react'
import { Avatar } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { AiFillEye, AiFillEdit, AiFillDelete } from 'react-icons/ai'
import moment from 'moment'

const DocumentationItem = props => {
  const {
    article,
    articleModalState,
    setArticleModalState,
    setDialogue,
    setArticleViewModal
  } = props
  const { t } = useTranslation()

  const stringTruncate = (str, length) => {
    if (str) {
      const dots = str.length > length ? '...' : ''
      return `${str.substring(0, length)}${dots}`
    }
  }

  return (
    <>
      <div className='col-md-5 mr-4 mg-top-40 documentation-card'>
        <div className='d-flex justify-content-end'>
          <AiFillEye
            className='mr-2 cursor-pointer'
            onClick={() => {
              if (article.type === 'webLink') {
                window.open(article.webLink)
              } else {
                setArticleViewModal({
                  data: { ...article },
                  state: true
                })
              }
            }}
          />
          <AiFillEdit
            className='mr-2 cursor-pointer'
            onClick={() => {
              setArticleModalState({
                ...articleModalState,
                modalType: 'update',
                data: { ...article },
                state: true
              })
            }}
          />
          <AiFillDelete
            className='cursor-pointer'
            onClick={() => {
              setDialogue(true)
              setArticleModalState({
                ...articleModalState,
                modalType: 'view',
                data: { ...article },
                state: false
              })
            }}
          />
        </div>
        <div className=' mt-5 mb-2 py-2 border-top top-muted border-bottom bottom-muted '>
          <div className='my-0 h5 font-12 font-weight-bolder text-capitalize font-weight-bold text-truncate'>
            <div className='d-flex align-items-center '>
              <Avatar className='mr-3' src={article?.image} />
              <p className=''>{article?.title}</p>
            </div>
          </div>
        </div>
        <div className='text-capitalize d-flex mb-1'>
          <div className='d-flex flex-column'>
            <div></div>
            <h6 className='text-muted mb-2 font-10'>
              {t('posted_on')}:{' '}
              <span className=''>
                {moment(article.createdAt).format('YYYY-MM-DD')}
              </span>
            </h6>
          </div>
        </div>
        <div className='col-sm-12 col-md-12 m-0 p-0'>
          <p className='mb-1 mt-4 font-12'>
            {stringTruncate(article?.shortDescription, 200)}
          </p>
        </div>
      </div>
    </>
  )
}
export default DocumentationItem
