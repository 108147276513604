import Domain from "lib/Config";
import axios from "axios";

export const COMPANY_DETAILS = "[ADMIN] COMPANY_DETAILS";
export const COMPANY_LOADING = "[ADMIN] COMPANY_LOADING";
export const COMPANY_ERROR = "[ADMIN] COMPANY_ERROR";

export function getCompanyProfile(id) {
  const request = axios.get(`${Domain}/api/vendor/employeeProfile?id=${id}`);
  return (dispatch) =>
    request
      .then((response) => {
        return dispatch({
          type: COMPANY_DETAILS,
          payload: response.data.result,
        });
      })
      .catch((error) => {
        console.log(error.response);
        return dispatch({
          type: COMPANY_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get details",
        });
      });
}
export function setCompanyLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: COMPANY_LOADING,
      payload: val,
    });
  };
}
