import { CircularProgress, IconButton } from '@material-ui/core'
import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Actions from 'store/actions'
import { Button, Modal, Label, ModalBody } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { NotificationManager } from 'react-notifications'
import Domain from 'lib/Config'
import DeleteIcon from '@material-ui/icons/Delete'

import FileDrop from 'react-dropzone'
import AddIcon from '@material-ui/icons/Add'

import axios from 'axios'
const uploadAxios = axios.create({})
delete uploadAxios.defaults.headers.common['Authorization']

const ArticleModal = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const articleReducer = useSelector(({ articles }) => articles)
  const [articleType, setArticleType] = useState('webLink')
  const [pdfImage, setPdfImage] = useState(null)
  const [pdfFile, setPdfFile] = useState(null)
  const [imageLoading, setImageLoading] = useState(null)
  const [pdfLoading, setPdfLoading] = useState(false)

  useEffect(() => {
    if (props.data) {
      if (props.data.type) {
        setArticleType(props.data.type)
      }
      if (props.data.type == 'pdf') {
        setPdfFile(props.data.webLink)
      }
      if (props.data.image) {
        setPdfImage(props.data.image)
      }
    }
  }, [props.data])

  let handleSubmit = e => {
    e.preventDefault()

    if (e.target.type.value === 'pdf' && !pdfFile) {
      NotificationManager.error(`${t('pdf_file_is_required')}`)
    } else if (pdfImage === null) {
      NotificationManager.error(`${t('article_image_is_required')}`)
    } else {
      let obj = {
        title: e.target?.title?.value,
        shortDescription: e.target?.shortDescription?.value,
        type: e.target.type.value,
        webLink:
          e.target.type.value === 'pdf' ? pdfFile : e.target.webLink.value,
        image: pdfImage,
        longDescription: null,
        vendor_id: props.vendor_id
      }

      dispatch(Actions.setArticleStateLoading(true))
      if (props.modalType == 'add') {
        dispatch(Actions.addArticle(obj))
      } else {
        let articleId = props.data.id
        dispatch(Actions.updateArticle(obj, articleId))
      }
    }
  }

  const removePdf = () => {
    setPdfFile(null)
  }
  const removeImg = () => {
    setPdfImage(null)
  }

  const handleFileUpload = (data, uploadSuccess) => {
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
      },
      header: {
        'Content-Type': data?.type,
        mode: 'cors'
      }
    }

    getSignedURL(data.name, data.type, uploadSuccess).then(rsp => {
      uploadAxios
        .put(rsp.data.result.postUrl, data, config)
        .then(res => {
          let fileUrl = rsp.data.result.getUrl
          uploadSuccess(fileUrl)
        })
        .catch(err => {
          setPdfFile(false)
          setImageLoading(false)
          console.log('Error ---->', err)
          NotificationManager.error('Unable to upload try again')
          document.getElementById('pdfURL').value = null
        })
    })
  }
  const getSignedURL = (key, type) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Domain}/api/vendor/s3getSignedUrl?key=${key}&type=${type}`)
        .then(data => {
          resolve(data)
        })
        .catch(err => {
          setPdfFile(false)
          setImageLoading(false)
          console.log(err.response)
          reject(err)
        })
    })
  }

  const onFileSelect = useCallback(file => {
    setPdfLoading(true)
    handleFileUpload(file[0], fileUrl => {
      setPdfFile(fileUrl)
      setPdfLoading(false)
    })
  }, [])
  const onImageSelect = useCallback(img => {
    setImageLoading(true)
    handleFileUpload(img[0], fileUrl => {
      setImageLoading(false)
      setPdfImage(fileUrl)
    })
  }, [])
  const getArticleHeader = type => {
    let obj = {
      add: t('create_article'),
      update: t('update_article')
    }
    return obj[type]
  }
  const renderPdfElement = () => {
    return (
      <div className='mt-4 '>
        <Label className='d-flex mb-3'>{t('pdf_file')}</Label>
        {pdfFile != null ? (
          <div
            className='col-md-4 mb-2 animate__animated animate__fadeIn '
            key={pdfFile}
          >
            <div className='img-div d-flex flex-column'>
              <a href={pdfFile} width='100%' alt='qwe'>
                {t('view_pdf')}
              </a>
            </div>
            {props.modalType !== 'view' ? (
              <div className='del-img'>
                <IconButton
                  color='inherit'
                  mini='true'
                  className='p-0'
                  onClick={() => removePdf()}
                >
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </div>
            ) : null}
          </div>
        ) : (
          <div className=''>
            <FileDrop
              disabled={pdfLoading}
              accept='.pdf'
              multiple
              onDrop={onFileSelect}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className='img-div'>
                  <IconButton
                    color='inherit'
                    mini='true'
                    aria-label='Menu'
                    className='p-0'
                  >
                    {pdfLoading ? (
                      <CircularProgress
                        style={{ height: '10px', width: '10px' }}
                      />
                    ) : (
                      <AddIcon fontSize='small' />
                    )}
                  </IconButton>
                  <input {...getInputProps()} accept='.pdf' />
                </div>
              )}
            </FileDrop>
          </div>
        )}
      </div>
    )
  }

  return (
    <div>
      <Modal
        isOpen={props.modal}
        toggle={() => {
          removePdf()
          removeImg()
          setArticleType('webLink')
          props.toggle()
        }}
        className='modal-background'
      >
        <ModalBody>
          <div className='p-4'>
            <div className='d-flex justify-content-end'>
              <img
                onClick={() => {
                  removePdf()
                  removeImg()
                  setArticleType('webLink')
                  props.toggle()
                }}
                className='centrify-icons cursor-pointer'
                src={require('assets/icons/close.png')}
              />
            </div>
            <div className='mt-2 form-styles'>
              <h2 className='form-heading d-flex mb-3'>
                {getArticleHeader(props.modalType)}
              </h2>
              <form onSubmit={e => handleSubmit(e)}>
                <div className='mt-4'>
                  <Label className='d-flex mb-3'>{t('article_title')}</Label>
                  <textarea
                    type='text'
                    name='title'
                    required
                    // disabled={props.data.modalType == 'view' ? true : false}
                    defaultValue={props.data && props.data.title}
                    className='modal_input'
                    rows={2}
                  />
                </div>
                <div className='mt-4'>
                  <Label className='d-flex mb-3'>
                    {t('short_description')}
                  </Label>
                  <textarea
                    type='text'
                    name='shortDescription'
                    required
                    // disabled={props.data.modalType == 'view' ? true : false}
                    defaultValue={props.data && props.data.shortDescription}
                    rows={4}
                    className='modal_input'
                  />
                </div>
                <div className='mt-4'>
                  <Label className='d-flex mb-3'>{t('type')}</Label>
                  <select
                    name='type'
                    className='mr-2 modal_input'
                    disabled={props.modalType == 'view' ? true : false}
                    value={articleType}
                    onChange={e => {
                      setArticleType(e.target.value)
                    }}
                    // defaultValue={props.article && props.article.type}
                  >
                    <option value={'webLink'}>{t('web_link')}</option>
                    <option value={'pdf'}>{t('pdf')}</option>
                    {/* <option value={'editor'}>{t('editor')}</option> */}
                  </select>
                </div>
                <div className='d-flex '>
                  <div className='mt-4 mr-4'>
                    <Label className='d-flex mb-3'>{t('article_image')}</Label>
                    <div className='row'>
                      {pdfImage ? (
                        <div
                          className='col-md-4 mb-2 animate__animated animate__fadeIn'
                          key={pdfImage}
                        >
                          <div className='img-div d-flex flex-column'>
                            <img src={pdfImage} width='100%' alt='qwe' />
                          </div>
                          {props.modalType !== 'view' ? (
                            <div className='del-img'>
                              <IconButton
                                color='inherit'
                                mini='true'
                                className='p-0'
                                onClick={() => removeImg()}
                              >
                                <DeleteIcon fontSize='small' />
                              </IconButton>
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <div className='col-md-4'>
                          <FileDrop
                            disabled={imageLoading}
                            accept='image/png, image/gif, image/jpeg, image/bmp,'
                            multiple
                            onDrop={onImageSelect}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps()} className='img-div'>
                                <IconButton
                                  color='inherit'
                                  mini='true'
                                  aria-label='Menu'
                                  className='p-0'
                                >
                                  {imageLoading ? (
                                    <CircularProgress
                                      style={{ height: '10px', width: '10px' }}
                                    />
                                  ) : (
                                    <AddIcon fontSize='small' />
                                  )}
                                </IconButton>
                                <input {...getInputProps()} />
                              </div>
                            )}
                          </FileDrop>
                        </div>
                      )}
                    </div>
                  </div>
                  {articleType !== 'webLink' ? renderPdfElement() : null}
                </div>
                {articleType == 'webLink' ? (
                  <div className='mt-4'>
                    <Label className='d-flex mb-3'>{t('web_link')}</Label>
                    <input
                      type='text'
                      name='webLink'
                      required
                      //   disabled={props.data.modalType == 'view' ? true : false}
                      defaultValue={props.data && props.data.webLink}
                      className='modal_input'
                    />
                  </div>
                ) : null}

                {props.modalType === 'view' ? null : (
                  <div className='mt-4 d-flex justify-content-end'>
                    {articleReducer && articleReducer.stateLoading ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        className='primary-background-button-table'
                        color='none'
                      >
                        {props.modalType == 'add' ? t('create') : t('update')}
                      </Button>
                    )}
                  </div>
                )}
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ArticleModal
