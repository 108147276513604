import * as Actions from '../actions'

const initialState = {
  loading: false,
  approveLoading: false,
  details: null,
  error: null,
  posted: false,
  orderList: null,
  orderCount: null,
  storeOrderList: null,
  storeOrderCount: null
}

const orderReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.ORDER_LOADING: {
      return {
        ...state,
        loading: action.payload
      }
    }
    case Actions.ORDER_POSTED: {
      return {
        ...state,
        posted: action.payload
      }
    }
    case Actions.ORDER_APPROVE_LOADING: {
      return {
        ...state,
        approveLoading: action.payload
      }
    }
    case Actions.ORDER_ERROR: {
      return {
        ...state,
        error: action.payload
      }
    }
    case Actions.GET_ALL_ORDERS: {
      return {
        ...state,
        loading: false,
        error: null,
        orderCount: action.payload.count,
        orderList: action.payload.rows
      }
    }
    case Actions.GET_ALL_STORE_ORDERS: {
      return {
        ...state,
        loading: false,
        error: null,
        storeOrderCount: action.payload.count,
        storeOrderList: action.payload.rows
      }
    }
    default: {
      return state
    }
  }
}
export default orderReducer
