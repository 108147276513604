import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import StatsPieChart from "../pieChart";
import StatsBarChart from "./statsBarChart";
import QuestionComp from "./questionComponent";
import * as Actions from "store/actions";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";

export default function Questionaires(props) {
  console.log("PROPS --->", props);
  const { questionaireId } = props;
  const { t } = useTranslation();
  console.log("questionaireId --->", questionaireId);

  const dispatch = useDispatch();

  let [pageLoading, setPageLoading] = useState(true);
  const [questionnaireReport, setQuestionnaireReport] = useState(null);

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  const questionaireReducer = useSelector(({ questionaire }) => questionaire);
  console.log("questionaireReducer ---->", questionaireReducer);
  useEffect(() => {
    if (questionaireId) {
      console.log("Calling get Tree questionnaire", questionaireId);
      setPageLoading(true);
      dispatch(Actions.getTreeQuestionaireForReporting(questionaireId));
    }
  }, [questionaireId]);
  useEffect(() => {
    if (
      questionaireReducer.error ||
      questionaireReducer.reportingQuestionaire != null
    ) {
      setPageLoading(false);
    }
  }, [questionaireReducer]);
  useEffect(() => {
    if (questionaireReducer.reportingQuestionaire) {
      setQuestionnaireReport(questionaireReducer.reportingQuestionaire);
    }
  }, [questionaireReducer.reportingQuestionaire]);

  if (
    questionaireReducer.error &&
    questionaireReducer?.reportingQuestionaire === null
  ) {
    return (
      <ErrorMessageComponents
        message={t("cannot_fetch_questionnaire_report")}
      />
    );
  }

  if (questionaireReducer == null || pageLoading) {
    return <CircularProgressComponent />;
  }
  return (
    <div>
      <div className="mt-4">
        {/* <h1 className="form-heading font-weight-bold d-flex">{t("questionnaire_details")}</h1> */}
        <div className="rct-block mg-top-40">
          <div className="d-flex justify-content-between align-items-center border-bottom-centrify p-4">
            <div className="d-flex flex-column w-100">
              <h2 className="font-weight-bold">{questionnaireReport.title}</h2>
              <p className="mt-5">{questionnaireReport.shortDescription}</p>
            </div>
          </div>
        </div>
        {questionnaireReport.TQQuestions.length > 0 && (
          <>
            {questionnaireReport.TQQuestions.map((ques, index) => (
              <>
                {ques.questionType === "MCQ" ? (
                  <div className="rct-block mt-5">
                    <div className="row d-flex justify-content-between p-4">
                      <QuestionComp question={ques} index={index} />
                      <div className="col-md-12 col-lg-4 d-flex flex-column mt-4 graph-section">
                        {ques.questionType === "MCQ" ? (
                          <StatsPieChart TQOptions={ques.TQOptions} />
                        ) : (
                          <StatsBarChart TQOptions={ques.TQOptions} />
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
              </>
            ))}
          </>
        )}
      </div>
    </div>
  );
}
