import Domain from "lib/Config";
import axios from "axios";

export const API_RESPONSE_NOTIFICATION = "[ADMIN] API_RESPONSE_NOTIFICATION";
export const ADD_NOTIFICATION = "[ADMIN] ADD_NOTIFICATION";
export const NOTIFICATION_LOADING = "[ADMIN] NOTIFICATION_LOADING";
export const NOTIFICATION_ERROR = "[ADMIN] NOTIFICATION_ERROR";
export const GET_ALL_NOTIFICATIONS = "[ADMIN] GET_ALL_NOTIFICATIONS";
export const ADD_MEETING_NOTIFICATION = "[ADMIN] ADD_MEETING_NOTIFICATION";

export function getAllNotifications(vendor_id) {
  const request = axios.get(`${Domain}/api/vendor/notification/${vendor_id}`);
  return (dispatch) =>
    request
      .then((response) => {
        return dispatch({
          type: GET_ALL_NOTIFICATIONS,
          payload: response.data.notifications,
        });
      })
      .catch((error) => {
        console.log(error.response);
        return dispatch({
          type: NOTIFICATION_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get notifications",
        });
      });
}

export function apiResponseNotification(msgObj) {
  console.log("Api response notification called  @@@@", msgObj);
  return (dispatch) => {
    return dispatch({
      type: API_RESPONSE_NOTIFICATION,
      payload: msgObj,
    });
  };
}

export function readAllNotifications(vendor_id) {
  const request = axios.put(`${Domain}/api/vendor/allRead/${vendor_id}`);
  return (dispatch) =>
    request
      .then((response) => {
        console.log("read");
        getAllNotifications(vendor_id);
      })
      .catch((error) => {
        console.log(error.response);
      });
}

export function addToNotifications(data) {
  console.log("action noti", data);
  return (dispatch) => {
    return dispatch({
      type: ADD_NOTIFICATION,
      payload: data,
    });
  };
}

export function addToMeetingNotifications(data) {
  console.log("action meeting noti", data);
  return (dispatch) => {
    return dispatch({
      type: ADD_MEETING_NOTIFICATION,
      payload: data,
    });
  };
}

export function setNotificationLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: NOTIFICATION_LOADING,
      payload: val,
    });
  };
}
