import * as Actions from "../actions";

const initialState = {
  loading: false,
  details: null,
  error: null,
  posted: false,
  medicineList: null,
  medicineCount: null,
  getMedicineForOrder: [],
};

const medicineReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.MEDICINE_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case Actions.MEDICINE_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.MEDICINE_POSTED: {
      return {
        ...state,
        posted: action.payload,
      };
    }
    case Actions.GET_ALL_MEDICINES: {
      return {
        ...state,
        loading: false,
        error: null,
        medicineCount: action.payload.count,
        medicineList: action.payload.rows,
      };
    }

    case Actions.GET_PATIENT_MEDICINE: {
      return {
        ...state,
        loading: false,
        error: null,
        getMedicineForOrder: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
export default medicineReducer;
