import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Chip,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import React, { useState, useCallback, useEffect } from "react";
import FileDrop from "react-dropzone";
import Toggle from "react-toggle";
import { Button, Input, Label } from "reactstrap";
import { Autocomplete } from "@material-ui/lab";
import ExpandMoreIcon from "assets/icons/expand-icon.png";
import AddMoreIcon from "assets/icons/add-member.svg";
import { useTranslation } from "react-i18next";
import * as Actions from "store/actions";
import { useDispatch } from "react-redux";
import axios from "axios";
import Domain from "lib/Config";
import History from "@history";
import { timeZoneList as timeZone } from "lib/timeZone";

const profileCdn = axios.create({});

export default function Profile(props) {
  const dispatch = useDispatch();
  const patientData = props.patientData;
  let _allergies = patientData.allergies
    ? JSON.parse(patientData.allergies)
    : [];
  let _medicationList = patientData.medicationList
    ? JSON.parse(patientData.medicationList)
    : [];
  const [allergies, setAllergies] = useState(_allergies);
  const [medicationList, setMedicationList] = useState(_medicationList);
  const [medicalInfo, setMedicalInfo] = useState(
    patientData.medicalInformation
  );
  const [relativesList, setRelativesList] = useState(patientData.childs);
  const [deletedRelatives, setDeletedRelatives] = useState([]);
  const [image, setImage] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [expanded, setExpanded] = useState();
  const [firstNamee, setFirstNamee] = useState("");
  const [lastNamee, setLastNamee] = useState("");
  const [emaill, setEmaill] = useState("");
  const [relationn, setRelationn] = useState("son");
  const [timeZoneStat, setTimeZoneStat] = useState("+10:00");
  const { t } = useTranslation();

  useEffect(() => {
    if (patientData) setImage(patientData.image);
    if (patientData?.tz) {
      setTimeZoneStat(patientData?.tz);
    }
  }, [patientData]);

  console.log(patientData, "this is tz");

  const onDrop = useCallback(
    (img) => {
      handleFileUpload(img);
      setImageLoading(true);
    },
    [image]
  );

  const handleAllergyChange = (arr) => {
    setAllergies(arr);
  };

  const handleAddRelative = () => {
    if (
      firstNamee !== "" &&
      lastNamee !== "" &&
      relationn !== "" &&
      emaill !== ""
    ) {
      relativesList.push({
        firstName: firstNamee,
        lastName: lastNamee,
        relation: relationn,
        email: emaill,
      });
      setRelativesList([...relativesList]);
    }
  };

  const handleMedicationChange = (arr) => {
    setMedicationList(arr);
  };

  const handleSubmit = (event) => {
    let obj = {
      medicalInformation: medicalInfo,
    };
    const formData = new FormData(event.target);
    event.preventDefault();
    for (let [key, value] of formData.entries()) {
      obj[key] = value;
    }

    obj.id = patientData.id;
    obj.allergies = JSON.stringify(allergies);
    obj.medicationList = JSON.stringify(medicationList);
    obj.relatives = relativesList;
    obj.deletedRelatives = deletedRelatives;
    obj.tz = timeZoneStat;
    props.updateProfile(obj);
  };

  const handleFileUpload = (data) => {
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      },
      header: {
        "Content-Type": data[0].type,
      },
    };
    getSignedURL(data[0].name, data[0].type).then((rsp) => {
      let obj = {};
      obj.image = rsp.data.result.getUrl;
      obj.id = patientData.id;
      dispatch(Actions.updatePatientImage(obj));
      const request = profileCdn
        .put(rsp.data.result.postUrl, data[0], config)
        .then((res) => {
          setImage(rsp.data.result.getUrl);
          setImageLoading(false);
        })
        .catch((err) => {
          console.log(err.response);
          setImageLoading(false);
        });
    });
  };

  const handleDeactivate = (status) => {
    console.log(status);
    dispatch(Actions.setPatientStatusLoading(true));
    dispatch(Actions.updatePatientStatus(patientData?.id, status));
  };

  const handleMissedNotiStatus = (status) => {
    console.log(status);
    dispatch(Actions.setPatientMissedNotiStatusLoading(true));
    dispatch(Actions.updatePatientMissedNotiStatus(patientData?.id, status));
  };

  const getSignedURL = (key, type) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Domain}/api/vendor/s3getsignedurl?key=${key}&type=${type}`)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          console.log(err.response);
          reject(err);
        });
    });
  };
  return (
    <div className="patient-profile form-styles">
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="form-heading d-flex">{t("personal_information")}</h1>
        </div>

        <div className="rct-block mt-1">
          <div className="rct-block-content">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center flex-wrap mb-4">
                <div className="mr-4">
                  <FileDrop onDrop={onDrop}>
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps()}
                        className="d-flex profile-container cursor-pointer"
                      >
                        {imageLoading ? (
                          <div className="d-flex justify-content-center align-items-center w-100">
                            <CircularProgress />
                          </div>
                        ) : (
                          <Avatar className="profile-pic-patient" src={image} />
                        )}

                        <img
                          className="add-icon centrify-icons"
                          src={require("assets/icons/add-photo.png")}
                        />
                        <input {...getInputProps()} />
                      </div>
                    )}
                  </FileDrop>
                </div>
                <div>
                  <p className="patient-name mb-0">
                    {patientData.firstName + " " + patientData.lastName}
                  </p>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-start align-items-end">
                <div className="d-flex">
                  <a
                    onClick={() =>
                      History.push(
                        `/patient-media/${
                          patientData.firstName + " " + patientData.lastName
                        }/${patientData.id}`
                      )
                    }
                    className="centrify-blue-color cursor-pointer mr-4"
                  >
                    {t("view_media")}
                  </a>
                  <a
                    onClick={() =>
                      History.push(
                        `/projects?filter_by=patient&patient_name=${
                          patientData.firstName + " " + patientData.lastName
                        }&patient_id=${patientData.id}`
                      )
                    }
                    className="centrify-blue-color cursor-pointer"
                  >
                    {t("view_projects")}
                  </a>
                </div>

                <div className="d-flex flex-row align-items-center mt-5">
                  <div className="font-weight-bold"> {t("status")}: </div>
                  {props.statusLoading ? (
                    <div
                      class="spinner-border text-dark"
                      style={{ height: "1rem", width: "1rem" }}
                      role="status"
                    >
                      <span class="sr-only">{t("loading")}</span>
                    </div>
                  ) : (
                    <Toggle
                      className="ml-2"
                      checked={patientData?.status}
                      onChange={() => handleDeactivate(!patientData?.status)}
                    />
                  )}
                </div>
                <div className="d-flex flex-row align-items-center pd-top-10">
                  <div className="font-weight-bold">
                    {t("missed_notification_status")}:{" "}
                  </div>
                  {props.missedNotiStatusLoading ? (
                    <div
                      class="spinner-border text-dark"
                      style={{ height: "1rem", width: "1rem" }}
                      role="status"
                    >
                      <span class="sr-only">{t("loading")}</span>
                    </div>
                  ) : (
                    <Toggle
                      className="ml-2"
                      checked={patientData?.missedActivityNotification}
                      onChange={() =>
                        handleMissedNotiStatus(
                          !patientData?.missedActivityNotification
                        )
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            <div>
              <div className="row ">
                <div className="col-md-4 mt-4">
                  <Label className="mb-1 d-flex">{t("first_name")}</Label>
                  <Input
                    type="text"
                    name="firstName"
                    required
                    className="w-75"
                    defaultValue={
                      patientData.firstName && patientData.firstName
                    }
                  />
                </div>
                <div className="col-md-4 mt-4">
                  <Label className="mb-1 d-flex">{t("last_name")}</Label>
                  <Input
                    type="text"
                    name="lastName"
                    required
                    className="w-75"
                    defaultValue={patientData.lastName && patientData.lastName}
                  />
                </div>
                <div className="col-md-4 mt-4">
                  <Label className="mb-1 d-flex">{t("address")}</Label>
                  <Input
                    type="text"
                    name="address"
                    required
                    className="w-75"
                    defaultValue={patientData.address && patientData.address}
                  />
                </div>
              </div>
              <div className="row ">
                <div className="col-md-4 mt-4">
                  <Label className="mb-1 d-flex">{t("date")}</Label>
                  <Input
                    type="date"
                    name="dob"
                    required
                    className="w-75"
                    defaultValue={patientData.dob && patientData.dob}
                  />
                </div>
                <div className="col-md-4 mt-4">
                  <Label className="mb-1 d-flex">{t("email")}</Label>
                  <Input
                    type="email"
                    className="w-75"
                    disabled
                    defaultValue={patientData.email && patientData.email}
                  />
                </div>
                <div className="col-md-4 mt-4">
                  <Label className="mb-1 d-flex">{t("phone")}</Label>
                  <Input
                    type="text"
                    name="phone"
                    required
                    className="w-75"
                    defaultValue={patientData.phone && patientData.phone}
                  />
                </div>

                <div className="mt-4 col-md-4">
                  <Label className="d-flex">{t("time_zone")}</Label>
                  <Input
                    disabled
                    type="select"
                    value={timeZoneStat}
                    //   defaultValue={bulkActivityData?.tz}
                    name="tz"
                    onChange={(e) => setTimeZoneStat(e.target.value)}
                    required
                  >
                    {timeZone.map((item) => {
                      return (
                        <option value={item.value}>
                          {item.label} (GMT{item.value})
                        </option>
                      );
                    })}
                  </Input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 d-flex">
            <div className="w-100">
              <h1 className="form-heading d-flex">
                {t("medical_information")}
              </h1>
              <div className="rct-block mt-1">
                <div className="rct-block-content">
                  <div className="row ">
                    <div className="col-md-6 mt-2">
                      <Label className="mb-1 d-flex">{t("blood_type")}</Label>
                      <Input
                        defaultValue={
                          patientData.bloodType && patientData.bloodType
                        }
                        type="select"
                        name="bloodType"
                      >
                        <option value="">{"select_blood_type"}</option>
                        <option value="A+">A+</option>
                        <option value="A-">A-</option>
                        <option value="B+">B+</option>
                        <option value="B-">B-</option>
                        <option value="O+">O+</option>
                        <option value="O-">O-</option>
                        <option value="AB+">AB+</option>
                        <option value="AB-">AB-</option>
                      </Input>
                    </div>
                    <div className="col-md-6 mt-2">
                      <Label className="mb-1 d-flex">{t("allergies")}</Label>
                      {/* {allergies && ( */}
                      <Autocomplete
                        multiple
                        open={false}
                        onChange={(e, arr) => handleAllergyChange(arr)}
                        limitTags={2}
                        id="tags-filled"
                        options={
                          allergies ? allergies.map((option) => option) : []
                        }
                        getOptionDisabled={(option) => true}
                        defaultValue={
                          allergies ? allergies.map((option) => option) : []
                        }
                        freeSolo
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant="outlined"
                              label={option}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField {...params} variant="filled" />
                        )}
                      />
                      {/* )} */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-4">
                      <Label className="mb-1 d-flex">
                        {t("medication_list")}
                      </Label>

                      <Autocomplete
                        multiple
                        open={false}
                        onChange={(e, arr) => handleMedicationChange(arr)}
                        limitTags={2}
                        id="tags-filled"
                        options={
                          medicationList
                            ? medicationList.map((option) => option)
                            : []
                        }
                        defaultValue={
                          medicationList
                            ? medicationList.map((option) => option)
                            : []
                        }
                        freeSolo
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant="outlined"
                              label={option}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField {...params} variant="filled" />
                        )}
                      />
                      {/* )} */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-4">
                      <Label className="d-flex">
                        {t("medical_information")}
                      </Label>
                      <Input
                        type="textarea"
                        value={medicalInfo}
                        onChange={(e) => setMedicalInfo(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <h1
                className="form-heading d-flex"
                style={{ marginBottom: "0.75rem" }}
              >
                {t("family_members")}
              </h1>
              {/* <Accordion
                className="mt-0 rct-block"
                square
                expanded={expanded === "panel1"}
                onChange={(e) => setExpanded("panel1")}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  expandIcon={
                    <img
                      src={ExpandMoreIcon}
                      className="centrify-icons"
                      style={{ height: "10px" }}
                    />
                  }
                >
                  <p className="family-member-name mb-0">
                    Syed Irtaza, Caretaker
                  </p>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="row w-100">
                    <div className="col-md-4 mt-2">
                      <Label className="d-flex">{t("family_members")}</Label>
                      <Input type="select">
                        <option>Daughter</option>
                        <option>Son</option>
                      </Input>
                    </div>
                    <div className="col-md-4 mt-2">
                      <Label className="d-flex">{t("name")}</Label>
                      <Input type="text" />
                    </div>
                    <div className="col-md-4 mt-2">
                      <Label className="d-flex">{t("phone_number")}</Label>
                      <Input type="text" />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion> */}
              {relativesList.map((el, index) => (
                <Accordion
                  className="mt-0 rct-block"
                  square
                  expanded={expanded === index}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    expandIcon={
                      <div>
                        <img
                          src={ExpandMoreIcon}
                          className="centrify-icons ml-5"
                          style={{ height: "10px" }}
                          onClick={() => {
                            expanded === index
                              ? setExpanded(null)
                              : setExpanded(index);
                          }}
                        />
                      </div>
                    }
                  >
                    <p className="family-member-name mb-0 text-capitalize">
                      {el.firstName} {el.lastName}
                    </p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="row w-100">
                      <div className="col-md-3 mt-2">
                        <Label className="d-flex">{t("role")}</Label>
                        <Input
                          type="select"
                          id={`relation${index}`}
                          value={el.relation}
                          defaultValue={el.relation}
                          onChange={(e) => {
                            relativesList[index].relation = e.target.value;
                          }}
                          required
                        >
                          <option value="daughter">{t("daughter")}</option>
                          <option value="son">{t("son")}</option>
                          <option value="careTaker">{t("careTaker")}</option>
                          <option value="brother">{"brother"}</option>
                          <option value="sister">{t("sister")}</option>
                        </Input>
                        {el.relation === "" && (
                          <small className="text-danger">
                            {t("required")}*
                          </small>
                        )}
                      </div>
                      <div className="col-md-3 mt-2">
                        <Label className="d-flex">{t("firstname")}</Label>
                        <Input
                          type="text"
                          id={`firstName${index}`}
                          value={el.firstName}
                          defaultValue={el.firstName}
                          onChange={(e) => {
                            relativesList[index].firstName = e.target.value;
                          }}
                        />
                        {el.firstName === "" && (
                          <small className="text-danger">
                            {t("required")}*
                          </small>
                        )}
                      </div>
                      <div className="col-md-3 mt-2">
                        <Label className="d-flex">{t("lastname")}</Label>
                        <Input
                          type="text"
                          id={`lastName${index}`}
                          value={el.lastName}
                          defaultValue={el.lastName}
                          onChange={(e) => {
                            relativesList[index].lastName = e.target.value;
                          }}
                          required
                        />
                        {el.lastName === "" && (
                          <small className="text-danger">
                            {t("required")}*
                          </small>
                        )}
                      </div>
                      <div className="col-md-3 mt-2">
                        <Label>{t("email")}</Label>
                        <Input
                          type="text"
                          id={`email${index}`}
                          value={el.email}
                          defaultValue={el.email}
                          onChange={(e) => {
                            relativesList[index].email = e.target.value;
                          }}
                          required
                        />
                        {el.email === "" && (
                          <small className="text-danger">
                            {t("required")}*
                          </small>
                        )}
                      </div>
                      <div className="row d-flex w-100 justify-content-end mt-2">
                        <a
                          className="centrify-blue-color ml-5"
                          onClick={() => {
                            setDeletedRelatives([...deletedRelatives, el.id]);
                            let newData = relativesList;
                            newData.splice(index, 1);
                            setRelativesList([...newData]);
                            console.log(newData);
                          }}
                        >
                          {t("remove")}
                        </a>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
              <Accordion className="mt-0 rct-block" square expanded={true}>
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  expandIcon={
                    <img
                      src={AddMoreIcon}
                      className="centrify-icons"
                      style={{ height: "40px", transform: "rotate(180deg)" }}
                      onClick={handleAddRelative}
                    />
                  }
                >
                  <p className="family-member-name mb-0">
                    {t("family_relatives")}
                  </p>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="row w-100">
                    <div className="col-md-3 mt-2">
                      <Label className="d-flex">{t("role")}</Label>
                      <Input
                        type="select"
                        value={relationn}
                        onChange={(e) => setRelationn(e.target.value)}
                      >
                        <option value="daughter">{t("daughter")}</option>
                        <option value="son">{t("son")}</option>
                        <option value="careTaker">{t("careTaker")}</option>
                        <option value="brother">{t("brother")}</option>
                        <option value="sister">{t("sister")}</option>
                      </Input>
                      {relationn === "" && (
                        <small className="text-danger">{t("required")}*</small>
                      )}
                    </div>
                    <div className="col-md-3 mt-2">
                      <Label className="d-flex">{t("firstname")}</Label>
                      <Input
                        type="text"
                        value={firstNamee}
                        onChange={(e) => setFirstNamee(e.target.value)}
                      />
                      {firstNamee === "" && (
                        <small className="text-danger">{t("required")}*</small>
                      )}
                    </div>
                    <div className="col-md-3 mt-2">
                      <Label className="d-flex">{t("lastname")}</Label>
                      <Input
                        type="text"
                        value={lastNamee}
                        onChange={(e) => setLastNamee(e.target.value)}
                      />
                      {lastNamee === "" && (
                        <small className="text-danger">Required*</small>
                      )}
                    </div>
                    <div className="col-md-3 mt-2">
                      <Label>{t("email")}</Label>
                      <Input
                        type="email"
                        value={emaill}
                        onChange={(e) => setEmaill(e.target.value)}
                      />
                      {emaill === "" && (
                        <small className="text-danger">{t("required")}*</small>
                      )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
        <Button
          color="none"
          className="primary-background-button-table"
          type="submit"
        >
          {t("update_information")}
        </Button>
      </form>
    </div>
  );
}
