import { CircularProgress, IconButton } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody, Label, Input } from "reactstrap";
import FileDrop from "react-dropzone";
import AddIcon from "@material-ui/icons/Add";
import PublishIcon from "@material-ui/icons/Publish";
import { v4 as uuidv4 } from "uuid";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import * as Actions from "store/actions";
import useRecorder from "lib/useRecorder";
import StopIcon from "@material-ui/icons/Stop";
import DeleteIcon from "@material-ui/icons/Delete";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import axios from "axios";
import Domain from "lib/Config";
import DatePicker from "components/DatePicker/DatePicker";
import { timeZoneList as timeZone } from "lib/timeZone";

const s3Cdn = axios.create({});

const UpdateActivity = (props) => {
  const [data, setData] = useState(null);
  const [images, setImages] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const [recordLoading, setRecordLoading] = useState(false);
  const [recordingFile, setRecordingFile] = useState(null);
  const [recordingUrl, setRecordingUrl] = useState(null);
  const [dateRange, setDateRange] = useState("");
  const [actualDateRange, setActualDateRange] = useState(null);
  const [when, setWhen] = useState("everyday");
  const [daysArr, setDaysArr] = useState([]);
  const [weekDays, setWeekDays] = useState([
    { title: "Monday", value: 1 },
    { title: "Tuesday", value: 2 },
    { title: "Wednesday", value: 3 },
    { title: "Thursday", value: 4 },
    { title: "Friday", value: 5 },
    { title: "Saturday", value: 6 },
    { title: "Sunday", value: 0 },
  ]);
  const [timeZoneStat, setTimeZoneStat] = useState("+10:00");

  let [audioURL, isRecording, startRecording, stopRecording, deleteRecording] =
    useRecorder();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2014-08-18T21:11:54")
  );

  const [type, setType] = React.useState("medicine");

  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  const activityReducer = useSelector(({ activity }) => activity);

  let oldDate = moment(props.data?.date).format("YYYY-MM-DD");
  let newTime = moment(selectedDate).format("HH:mm:ss");
  let dateTime = moment(oldDate + " " + newTime).format();

  console.log(dateTime, "..........................");

  useEffect(() => {
    if (props.open) {
      dispatch(Actions.setMedicineLoading(true));
      dispatch(Actions.getAllMedicines({}, Auth.vendor_id));
    }
    if (props.data) {
      setData(props.data);
      if (props.data.time) {
        setSelectedDate(
          moment(`${props.data.time} +00:00`, "HH:mm Z")
            .local()
            .format("YYYY-MM-DDTHH:mm")
        );
      }
      if (props.data.to && props.data.from) {
        setDateRange([props.data.from, props.data.to]);
      }
      if (props.data.activityfiles) {
        let arr = [];
        props.data.activityfiles.map((item) => {
          if (item.type == "image" || item.type == "video") {
            let obj = {
              File: item.file,
              type: item.type,
            };
            arr.push(obj);
          } else if (item.type == "audio") {
            setRecordingUrl(item.file);
          }
          setImages(arr);
        });
      }
    }
  }, [props]);

  useEffect(() => {
    if (audioURL !== "") {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", audioURL, true);
      xhr.responseType = "blob";
      xhr.onload = function (e) {
        if (this.status == 200) {
          var myBlob = this.response;
          var file = new File(
            [myBlob],
            `${Date.now()}-audio-file-activity.webm`,
            {
              lastModified: Date.now(),
              type: myBlob.type,
            }
          );
          setRecordingFile(file);
          // myBlob is now the blob that the object URL pointed to.
        }
      };
      xhr.send();
    }
  }, [audioURL]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let obj = {};
    const formData = new FormData(e.target);
    for (let [key, value] of formData.entries()) {
      obj[key] = value;
    }
    obj.tz = timeZoneStat;
    obj.id = props.data.id;
    obj.type = props.data.type;
    obj.createdBy = "employee";
    obj.patient_id = props.patient_id;
    obj.employee_id = props.employee_id;
    obj.time = moment(dateTime).utc().format();
    obj.status = "pending";
    obj.files = [];
    if (recordingUrl) {
      obj.files.push({
        file: recordingUrl,
        type: "audio",
      });
    }
    if (images.length > 0) {
      images.map((item) => {
        obj.files.push({
          file: item.File,
          type: item.type,
        });
      });
    }
    if (actualDateRange) {
      obj.from = actualDateRange[0];
      obj.to = actualDateRange[1];
    }
    let frmt2 = "YYYY-MM-DD HH:mm:ss";
    let dateN = new Date();
    let utcNow = moment(dateN).utc().format(frmt2);
    let _variables = {
      dateRange: [
        moment(props.startDate).format(`YYYY-MM-DD`),
        moment(props.endDate).format("YYYY-MM-DD"),
      ],
      dateToday: utcNow,
    };

    console.log(obj);
    dispatch(Actions.setActivityLoading(true));
    dispatch(Actions.updateActivity(obj, _variables));
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const onDrop = useCallback(
    (img) => {
      setImageLoading(true);
      handleFileUpload(img[0]);
    },
    [images]
  );

  const removeImg = (id) => {
    setImages(images.filter((img) => img.id !== id));
  };

  const handleFileUpload = (data) => {
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      },
      header: {
        "Content-Type": data.type,
      },
    };
    getSignedURL(data.name, data.type).then((rsp) => {
      const request = s3Cdn
        .put(rsp.data.result.postUrl, data, config)
        .then((res) => {
          let type = data.type;
          let _type = type.split("/")[0];
          if (_type == "audio") {
            setRecordingUrl(rsp.data.result.getUrl);
            setRecordLoading(false);
          } else {
            let image = {
              id: uuidv4(),
              File: rsp.data.result.getUrl,
              type: _type,
            };
            setImages([...images, image]);
            setImageLoading(false);
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    });
  };

  const getSignedURL = (key, type) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Domain}/api/vendor/s3getsignedurl?key=${key}&type=${type}`)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          console.log(err.response);
          reject(err);
        });
    });
  };

  const uploadRecording = () => {
    setRecordLoading(true);
    handleFileUpload(recordingFile);
  };

  const handleDateRange = (e) => {
    if (!e) {
      setDateRange("");
      return;
    }
    const formatedDate = [
      moment(e[0]).format("YYYY-MM-DD"),
      moment(e[1]).format("YYYY-MM-DD"),
    ];
    setActualDateRange(e);
    setDateRange(formatedDate);
  };

  const handleCheck = (e) => {
    if (e.target.checked) {
      setDaysArr([...daysArr, parseInt(e.target.value)]);
    } else {
      setDaysArr(daysArr.filter((item) => item !== parseInt(e.target.value)));
    }
  };
  return (
    <div>
      <Modal
        isOpen={props.open}
        toggle={props.toggle}
        className="modal-background"
      >
        <ModalBody>
          <div className="p-4">
            <div className="d-flex justify-content-end">
              <img
                onClick={(e) => props.toggle()}
                className="centrify-icons cursor-pointer"
                src={require("assets/icons/close.png")}
              />
            </div>
            <div className="mt-2 form-styles">
              <h2 className="form-heading d-flex">{t("update_activity")}</h2>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="mt-4">
                  <Label className="d-flex">{t("title")}</Label>
                  <Input
                    defaultValue={data && data.title && data.title}
                    type="text"
                    name="title"
                    required
                  />
                </div>
                {data && data.type && data.type == "medicine" && (
                  <div>
                    <div className="mt-4">
                      <Label className="d-flex">{t("dose")}</Label>
                      <Input
                        defaultValue={data && data.dose && data.dose}
                        type="number"
                        name="dose"
                        required
                      />
                    </div>
                    {/* <div className="mt-4">
                      <Label className="d-flex">{t("select_date_range")}</Label>
                      <DatePicker
                        pastDate={false}
                        minDate={data.from}
                        className="w-100"
                        value={dateRange}
                        handleChange={handleDateRange}
                      />
                    </div> */}
                    {/**
                    <div className="mt-4">
                      <Label className="d-flex">{t("days")}</Label>
                      <div className="d-flex justify-content-between">
                        {weekDays.map((item) => {
                          return (
                            <div className="d-flex flex-wrap align-items-center ml-4">
                              <Input
                                onChange={(e) => handleCheck(e)}
                                type="checkbox"
                                value={item.value}
                              />
                              <p
                                className="mb-0 mt-1"
                                style={{ fontSize: "0.8rem" }}
                              >
                                {item.title}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    **/}
                    <div className="mt-4">
                      <Label className="d-flex">{t("take_it")}</Label>
                      <Input
                        defaultValue={data && data.takeIt && data.takeIt}
                        type="select"
                        name="takeIt"
                        required
                      >
                        <option value="before meal">{t("before_meal")}</option>
                        <option value="after meal">{t("after_meal")}</option>
                        <option value="during meal">{t("during_meal")}</option>
                        <option value="not important">
                          {t("not_important")}
                        </option>
                      </Input>
                    </div>
                  </div>
                )}
                <div className="mt-4">
                  <Label className="d-flex">{t("information")}</Label>
                  <Input
                    defaultValue={data && data.info && data.info}
                    type="textarea"
                    name="info"
                  />
                </div>
                <div className="mt-4">
                  <Label className="d-flex">{t("time")}</Label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      className="w-100"
                      margin="normal"
                      id="time-picker"
                      value={selectedDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div className="d-flex justify-content-start align-items-center">
                  <p
                    className="mb-0 font-weight-bold font-12"
                    // style={{ width: "100px" }}
                  >
                    LocalTime: {moment(selectedDate).format("hh:mm A")}
                  </p>
                  <p
                    className="mb-0 font-weight-bold text-muted font-12 ml-5"
                    // style={{ width: "100px" }}
                  >
                    PatientTime:{" "}
                    {moment(selectedDate)
                      .utc()
                      .add(timeZoneStat)
                      .format("hh:mm A")}
                  </p>
                  {console.log(data)}
                </div>

                <div className="mt-4">
                  <Label className="d-flex">{t("time_zone")}</Label>
                  <Input
                    defaultValue={data?.tz}
                    onChange={(e) => setTimeZoneStat(e.target.value)}
                    type="select"
                    name="timezone"
                    required
                  >
                    {timeZone.map((item) => {
                      return (
                        <option value={item.value}>
                          {item.label} (GMT{item.value})
                        </option>
                      );
                    })}
                  </Input>
                </div>
                <div className="mt-4">
                  <audio
                    style={{ width: "100%" }}
                    src={recordingUrl}
                    controls
                  />
                </div>
                <div className="mt-2 d-flex justify-content-center">
                  <IconButton
                    disabled={isRecording}
                    onClick={startRecording}
                    className="mr-2"
                  >
                    <FiberManualRecordIcon className="text-danger" />
                  </IconButton>
                  <IconButton
                    disabled={!isRecording}
                    onClick={stopRecording}
                    className="mr-2"
                  >
                    <StopIcon />
                  </IconButton>
                  <IconButton
                    disabled={isRecording}
                    onClick={deleteRecording}
                    className="mr-2"
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    disabled={isRecording || audioURL == ""}
                    onClick={uploadRecording}
                    className="mr-2"
                  >
                    <PublishIcon />
                  </IconButton>
                </div>
                <div className="text-center" style={{ height: "20px" }}>
                  {isRecording ? (
                    <p>{t("recording")}...</p>
                  ) : recordLoading ? (
                    <p>{t("uploading")}...</p>
                  ) : null}
                </div>
                <Label className="d-flex">{t("upload_files")}</Label>
                <div>
                  <div className="row">
                    {images &&
                      images.length > 0 &&
                      images.map((img) => (
                        <div
                          className="col-md-2 mb-2 animate__animated animate__fadeIn"
                          key={img.id}
                        >
                          <div className="img-div d-flex flex-column">
                            {img.type == "image" ? (
                              <img src={img.File} width="100%" alt="qwe" />
                            ) : img.type == "video" ? (
                              <video>
                                <source src={img.File} />
                              </video>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="del-img">
                            <IconButton
                              color="inherit"
                              mini="true"
                              className="p-0"
                              onClick={(e) => removeImg(img.id)}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </div>
                        </div>
                      ))}
                    <div className="col-md-2">
                      <FileDrop
                        disabled={imageLoading}
                        accept="image/png, image/gif, image/jpeg, image/bmp, video/mp4, video/mpeg, video/mpe, video/mpv, video/ogg, video/m4p, video/avi, video/wmv"
                        multiple
                        onDrop={onDrop}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()} className="img-div">
                            <IconButton
                              color="inherit"
                              mini="true"
                              aria-label="Menu"
                              className="p-0"
                            >
                              {imageLoading ? (
                                <CircularProgress
                                  style={{ height: "10px", width: "10px" }}
                                />
                              ) : (
                                <AddIcon fontSize="small" />
                              )}
                            </IconButton>
                            <input {...getInputProps()} />
                          </div>
                        )}
                      </FileDrop>
                    </div>
                  </div>
                </div>
                <div className="mt-4 d-flex justify-content-end">
                  {activityReducer.loading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      type="submit"
                      className="primary-background-button-table"
                      color="none"
                    >
                      {t("update_activity")}
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default UpdateActivity;
